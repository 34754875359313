import { AnimatePresence, motion } from 'framer-motion';
import React, { lazy, Suspense } from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { MinimalLayout } from './layout-blueprints';

const NotfoundPage = lazy(() => import('./view/404Page/index'));
const HomePage = lazy(() => import('./view/HomePage/index'));
const OTPPage = lazy(() => import('./view/otpPage/index'));
const InputOTP = lazy(() => import('./view/otpPage/otp'));
const InputPage = lazy(() => import('./view/InputPage/index'));
const ResultPage = lazy(() => import('./view/InputPage/result'));
const ScanPage = lazy(() => import('./view/ScanPage/otpPage'));
const DetailPage = lazy(() => import('./view/DetailPage/index'));
const ShareQRCode = lazy(() => import('./view/ScanPage/ShareQRCode.js'));
const DetailPageForQrCodeShare = lazy(() => import('./view/DetailPage/DetailPageForQRCodeShare.js'));
const InvalidPage = lazy(() => import('./view/404Page/InvalidPage.js'));
const HistoryListPage = lazy(() => import('./view/HistoryListPage'));
// const CheckPage = lazy(() => import('./view/SecurityPage/CheckPage'));

const Routes = (props) => {
  const userInfo = props.userInfo;
  const [userRole, setRole] = React.useState("ROLE_ADMIN");

  const location = useLocation();
  const pageVariants = {
    initial: {

    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };


  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            {/* <img src={ImageLoading} alt="loadingImage" style={{ maxHeight: "200px" }} /> */}
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Đang xử lý
            <span className="font-size-lg d-block text-dark">
              Vui lòng chờ trong giây lát ...
            </span>
          </div>
        </div>
      </>
    );
  };

  React.useEffect(() => {
    if (userInfo.data) {
      setRole(userInfo.data.roles && userInfo.data.roles[0])
    }
  }, [userInfo])

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Route
            path={[
              `/404Page`,
              `/home`,
              `/otp`,
              `/verify`,
              `/input`,
              `/result`,
              '/info/:id',
              '/detail/:id',
              '/shareQRCode/:id',
              '/covidPass/:id',
              '/invalidPage',
              '/history',
              // '/verifyContent'
              // '/list'
            ]}>
            <MinimalLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route
                    path={`/home`}
                    component={HomePage}
                  />
                  <Route
                    path={`/404Page`}
                    component={NotfoundPage}
                  />
                  <Route
                    path={`/verify`}
                    component={OTPPage}
                  />
                  <Route
                    path={`/otp`}
                    component={InputOTP}
                  />
                  <Route
                    path={`/input`}
                    component={InputPage}
                  />
                  <Route
                    path={`/result`}
                    component={ResultPage}
                  />
                  <Route
                    path={`/info/:id`}
                    component={ScanPage}
                  />
                  <Route
                    path={`/detail/:id`}
                    component={DetailPage}
                  />
                  <Route
                    path={`/shareQRCode/:id`}
                    component={ShareQRCode}
                  />
                  <Route
                    path={`/covidPass/:id`}
                    component={DetailPageForQrCodeShare}
                  />
                  <Route
                    path={`/invalidPage`}
                    component={InvalidPage}
                  />
                  <Route
                    path={`/history`}
                    component={HistoryListPage}
                  />
                  {/* <Route
                    path={`/verifyContent`}
                    component={CheckPage}
                  /> */}
                  {/* <Route
                    path={`/list`}
                    component={ListPage}
                  /> */}
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>
          <Route component={HomePage} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};


const mapStateToProps = state => ({
  userInfo: state.userInfo,
})
export default connect(mapStateToProps, null)(Routes);
