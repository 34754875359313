const provincesList = [
   {
      "id":"000",
      "tinhthanh":" "
   },
   {
      "id":"805",
      "tinhthanh":"An Giang"
   },
   {
      "id":"717",
      "tinhthanh":"Bà Rịa - Vũng Tàu"
   },
   {
      "id":"221",
      "tinhthanh":"Bắc Giang"
   },
   {
      "id":"207",
      "tinhthanh":"Bắc Kạn"
   },
   {
      "id":"821",
      "tinhthanh":"Bạc Liêu"
   },
   {
      "id":"223",
      "tinhthanh":"Bắc Ninh"
   },
   {
      "id":"811",
      "tinhthanh":"Bến Tre"
   },
   {
      "id":"507",
      "tinhthanh":"Bình Định"
   },
   {
      "id":"711",
      "tinhthanh":"Bình Dương"
   },
   {
      "id":"707",
      "tinhthanh":"Bình Phước"
   },
   {
      "id":"715",
      "tinhthanh":"Bình Thuận"
   },
   {
      "id":"823",
      "tinhthanh":"Cà Mau"
   },
   {
      "id":"815",
      "tinhthanh":"Cần Thơ"
   },
   {
      "id":"203",
      "tinhthanh":"Cao Bằng"
   },
   {
      "id":"501",
      "tinhthanh":"Đà Nẵng"
   },
   {
      "id":"605",
      "tinhthanh":"Đắk Lắk"
   },
   {
      "id":"607",
      "tinhthanh":"Đắk Nông"
   },
   {
      "id":"307",
      "tinhthanh":"Điện Biên"
   },
   {
      "id":"713",
      "tinhthanh":"Đồng Nai"
   },
   {
      "id":"803",
      "tinhthanh":"Đồng Tháp"
   },
   {
      "id":"603",
      "tinhthanh":"Gia Lai"
   },
   {
      "id":"201",
      "tinhthanh":"Hà Giang"
   },
   {
      "id":"111",
      "tinhthanh":"Hà Nam"
   },
   {
      "id":"101",
      "tinhthanh":"Hà Nội"
   },
   {
      "id":"105",
      "tinhthanh":"Hà Tây"
   },
   {
      "id":"405",
      "tinhthanh":"Hà Tĩnh"
   },
   {
      "id":"107",
      "tinhthanh":"Hải Dương"
   },
   {
      "id":"103",
      "tinhthanh":"Hải Phòng"
   },
   {
      "id":"825",
      "tinhthanh":"Hậu Giang"
   },
   {
      "id":"701",
      "tinhthanh":"Hồ Chí Minh"
   },
   {
      "id":"305",
      "tinhthanh":"Hòa Bình"
   },
   {
      "id":"109",
      "tinhthanh":"Hưng Yên"
   },
   {
      "id":"511",
      "tinhthanh":"Khánh Hòa"
   },
   {
      "id":"813",
      "tinhthanh":"Kiên Giang"
   },
   {
      "id":"601",
      "tinhthanh":"Kon Tum"
   },
   {
      "id":"301",
      "tinhthanh":"Lai Châu"
   },
   {
      "id":"703",
      "tinhthanh":"Lâm Đồng"
   },
   {
      "id":"209",
      "tinhthanh":"Lạng Sơn"
   },
   {
      "id":"205",
      "tinhthanh":"Lào Cai"
   },
   {
      "id":"801",
      "tinhthanh":"Long An"
   },
   {
      "id":"113",
      "tinhthanh":"Nam Định"
   },
   {
      "id":"403",
      "tinhthanh":"Nghệ An"
   },
   {
      "id":"117",
      "tinhthanh":"Ninh Bình"
   },
   {
      "id":"705",
      "tinhthanh":"Ninh Thuận"
   },
   {
      "id":"217",
      "tinhthanh":"Phú Thọ"
   },
   {
      "id":"509",
      "tinhthanh":"Phú Yên"
   },
   {
      "id":"407",
      "tinhthanh":"Quảng Bình"
   },
   {
      "id":"503",
      "tinhthanh":"Quảng Nam"
   },
   {
      "id":"505",
      "tinhthanh":"Quảng Ngãi"
   },
   {
      "id":"225",
      "tinhthanh":"Quảng Ninh"
   },
   {
      "id":"409",
      "tinhthanh":"Quảng Trị"
   },
   {
      "id":"819",
      "tinhthanh":"Sóc Trăng"
   },
   {
      "id":"303",
      "tinhthanh":"Sơn La"
   },
   {
      "id":"709",
      "tinhthanh":"Tây Ninh"
   },
   {
      "id":"115",
      "tinhthanh":"Thái Bình"
   },
   {
      "id":"215",
      "tinhthanh":"Thái Nguyên"
   },
   {
      "id":"401",
      "tinhthanh":"Thanh Hóa"
   },
   {
      "id":"411",
      "tinhthanh":"Thừa Thiên - Huế"
   },
   {
      "id":"807",
      "tinhthanh":"Tiền Giang"
   },
   {
      "id":"817",
      "tinhthanh":"Trà Vinh"
   },
   {
      "id":"211",
      "tinhthanh":"Tuyên Quang"
   },
   {
      "id":"809",
      "tinhthanh":"Vĩnh Long"
   },
   {
      "id":"104",
      "tinhthanh":"Vĩnh Phúc"
   },
   {
      "id":"213",
      "tinhthanh":"Yên Bái"
   }
]
export {
   provincesList
}

export default {
   provincesList
}