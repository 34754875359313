import { SAVE_COUNTRIES_LIST, SAVE_COUNTRIES_LIST_FAILURE, SAVE_COUNTRIES_LIST_SUCCESS } from "../actions/types";

const initialState = {
    data: [],
    cities: [],
    indexCity: 0,
    indexDistrict: 0,
    districts: [],
    wards: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_COUNTRIES_LIST:
            return {
                ...state,
            };
        case SAVE_COUNTRIES_LIST_SUCCESS:
            return {
                ...state,
               data: action.data,
               cities: action.cities,
               indexCity: action.indexCity,
               indexDistrict: action.indexDistrict,
               districts: action.districts,
               wards: action.wards
            };
        case SAVE_COUNTRIES_LIST_FAILURE:
            return state
        default:
            return state;
    }
}
export default reducer;