// import cookie from 'react-cookies';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { GET_ALL_TEST_RESULT_FAILURE, GET_ALL_TEST_RESULT_SUCCESS, GET_ALL_TEST_RESULT, GET_LIST_PROFILE, GET_LIST_PROFILE_SUCCESS, GET_LIST_TEST, GET_LIST_TEST_SUCCESS, SAVE_CITY, SAVE_COUNTRIES_LIST, SAVE_COUNTRIES_LIST_SUCCESS, SAVE_LANGUAGE, SAVE_LANGUAGE_SUCCESS, SAVE_RESULT, SAVE_RESULT_SUCCESS, SAVE_USER_INFO, SAVE_USER_INFO_SUCCESS, SAVE_WARD } from "../actions/types.js";
import { LINK_API } from '../constants/API.js';
import axios from "axios"
import DataJson from "../constants/vietnam.json"
import { contriesList } from '../constants/addressList/country.js';
import { provincesList } from '../constants/addressList/province.js';
import { districtsList } from '../constants/addressList/district.js';
import { wardsList } from '../constants/addressList/ward.js';

const getAddress = (state) => state.countries

const getDataUser = (state) => state.userInfo
const getDataProfile = (state) => state.listProfile
const getDataTest = (state) => state.testList

function* saveWardIndex(payload) {
  const dataNow = yield select(getAddress);

  let tempArray = [];

  for (let i = 0; i < DataJson[dataNow.indexCity].Districts[payload.index].Wards.length; i++) {
    tempArray.push({
      name: DataJson[dataNow.indexCity].Districts[payload.index].Wards[i].Name,
      index: i
    })
  }

  yield put({
    type: SAVE_COUNTRIES_LIST_SUCCESS,
    data: dataNow.data,
    cities: dataNow.cities,
    indexCity: dataNow.indexCity,
    indexDistrict: payload.index,
    districts: dataNow.districts,
    wards: tempArray
  })
}


function* saveCityIndex(payload) {

  const dataNow = yield select(getAddress);

  let tempArray = [];

  for (let i = 0; i < DataJson[payload.index].Districts.length; i++) {
    tempArray.push({
      name: DataJson[payload.index].Districts[i].Name,
      index: i
    })
  }

  yield put({
    type: SAVE_COUNTRIES_LIST_SUCCESS,
    data: dataNow.data,
    cities: dataNow.cities,
    indexCity: payload.index,
    indexDistrict: dataNow.indexDistrict,
    districts: tempArray,
    wards: []
  })
}

function* getListCountries() {
  const response = yield call(fetchListCountries);

  let tempData = response.data.data;

  let array = ['Vietnam'];

  for (let i = 0; i < tempData.length; i++) {
    if (tempData[i].country !== 'Vietnam') {
      array.push(tempData[i].country)
    }
  }

  let cities = [];
  for (let i = 0; i < DataJson.length; i++) {
    cities.push({
      name: DataJson[i].Name,
      index: i
    })
  }
  yield put({ type: SAVE_COUNTRIES_LIST_SUCCESS, data: array, cities: cities, wards: [] })
}
function fetchListCountries() {
  return axios({
    method: 'GET',
    url: LINK_API.GET_LIST_COUNTRIES
  })
}
function* saveLang(lang) {
  yield put({ type: SAVE_LANGUAGE_SUCCESS, data: lang.lang })
}

// lay danh sach result sau khi submit form
function* saveResult(payload) {
  yield put({ type: SAVE_RESULT_SUCCESS, data: payload.data })
}



//get List hồ sơ cho admin
function* getListProfile(page) {

  const profileData = yield select(getDataProfile)

  const totalItem = profileData.totalItem;
  const fullData = profileData.fullData;

  const size = page.size;
  if (fullData[`${page.page}`]) {
    yield put({
      type: GET_LIST_PROFILE_SUCCESS,
      data: fullData[`${page.page}`],
      fullData: fullData
    })
  }
  const dataToken = yield select(getDataUser);

  const response = yield call(fetchProfile, dataToken.data.accessToken, page.page, page.size, page.value);
  const data = response.data;
  if (totalItem !== response.data.totalItems) {
    const newList = data
    fullData[`${page.page}`] = newList
    yield put({
      type: GET_LIST_PROFILE_SUCCESS,
      data: newList,
      totalItem: response.data.totalItems,
      totalPage: response.data.totalPages,
      fullData: fullData
    })
  } else {
    if (!fullData[`${page.page}`]) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_LIST_PROFILE_SUCCESS,
        data: newList,
        totalItem: response.data.totalItems,
        totalPage: response.data.totalPages,
        fullData: fullData
      })
    } else {
      if (fullData[`${page.page}`] !== data) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_LIST_PROFILE_SUCCESS,
          data: newList,
          totalItem: response.data.totalItems,
          totalPage: response.data.totalPages,
          fullData: fullData
        })
      }
    }
  }
}

function fetchProfile(token, page, size, value) {
  let url = LINK_API.SUBMIT_FORM.concat('?page=', page, '&size=', size);
  if (value) {
    if (value.id) {
      url = url + '&id=' + value.id
    }
    if (value.cmnd) {
      if (value.cmnd.length === 9 || value.cmnd.length === 12) {
        url = url + '&cccd=' + value.cmnd
      }
      else {
        url = url + '&passport=' + value.cmnd
      }
    }
    if (value.phone) {
      url = url + '&phone=' + value.phone
    }
    if (value.full_name) {
      url = url + '&full_name=' + value.full_name
    }
    if (value.is_approved !== 3) {
      url = url + '&is_approved=' + value.is_approved
    }
    if (value.is_paid !== 3) {
      url = url + '&is_paid=' + value.is_paid
    }
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
  return axios({
    method: 'GET',
    url: url
  })
}

//lấy danh sách dữ liệu xét nghiệm cho admin
//get List hồ sơ cho admin
function* getListTest(page) {
  // console.log('page', page)
  const profileData = yield select(getDataTest)

  const totalItem = profileData.totalItem;
  const fullData = profileData.fullData;

  const size = page.size;
  if (fullData[`${page.page}`]) {
    yield put({
      type: GET_LIST_TEST_SUCCESS,
      data: fullData[`${page.page}`],
      fullData: fullData
    })
  }
  const dataToken = yield select(getDataUser);
  const response = yield call(fetchTest, dataToken.data.accessToken, page.page, page.size, page.value);
  const data = response.data;
  if (totalItem !== response.data.totalItems) {
    const newList = data
    fullData[`${page.page}`] = newList
    yield put({
      type: GET_LIST_TEST_SUCCESS,
      data: newList,
      totalItem: response.data.totalItems,
      totalPage: response.data.totalPages,
      fullData: fullData
    })
  } else {
    if (!fullData[`${page.page}`]) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_LIST_TEST_SUCCESS,
        data: newList,
        totalItem: response.data.totalItems,
        totalPage: response.data.totalPages,
        fullData: fullData
      })
    } else {
      if (fullData[`${page.page}`] !== data) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_LIST_TEST_SUCCESS,
          data: newList,
          totalItem: response.data.totalItems,
          totalPage: response.data.totalPages,
          fullData: fullData
        })
      }
    }
  }
}

function fetchTest(token, page, size, value) {
  let url = LINK_API.GET_TEST.concat('?page=', page, '&size=', size);
  if (value) {
    if (value.id) {
      url = url + '&id=' + value.id
    }
    if (value.cmnd) {
      if (value.cmnd.length === 9 || value.cmnd.length === 12) {
        url = url + '&cccd=' + value.cmnd
      }
      else {
        url = url + '&passport=' + value.cmnd
      }
    }
    if (value.code) {
      url = url + '&code=' + value.code
    }
    if (value.full_name) {
      url = url + '&full_name=' + value.full_name
    }
    if (value.sample_id) {
      url = url + '&sample_id=' + value.sample_id
    }
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
  return axios({
    method: 'GET',
    url: url
  })
}
//lưu dữ liệu user khi đăng nhập
function* saveUserInfo(payload) {
  yield put({ type: SAVE_USER_INFO_SUCCESS, data: payload.data })

}

function* getAllTestResult(receivedData) {
  const {
    page, size, value
  } = receivedData;
  try {
    const dataToken = yield select(getDataUser);
    const response = yield call(featchTestResult, dataToken.data.accessToken, page, size, value)
    const data = response.data
    yield put({ type: GET_ALL_TEST_RESULT_SUCCESS, data: data })
  } catch (error) {
    yield put({ type: GET_ALL_TEST_RESULT_FAILURE, payload: '', errorCode: 0 })
  }
}

function featchTestResult(token, page, size, value) {
  let url = LINK_API.GET_ALL_TEST_RESULT.concat('?page=', page, '&size=', size);
  if (value) {
    if (value.id) {
      url = url + '&id=' + value.id
    }
    if (value.cmnd) {
      if (value.cmnd.length === 9 || value.cmnd.length === 12) {
        url = url + '&cccd=' + value.cmnd
      }
      else {
        url = url + '&passport=' + value.cmnd
      }
    }
    if (value.code) {
      url = url + '&code=' + value.code
    }
    if (value.full_name) {
      url = url + '&full_name=' + value.full_name
    }
    if (value.sample_id) {
      url = url + '&sample_id=' + value.sample_id
    }
    if (value.is_mapped !== 3) {
      url = url + '&is_mapped=' + value.is_mapped
    }
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
  return axios({
    method: 'GET',
    url: url
  })
}

function* getAllCountriesList() {
  const newCountries = contriesList.filter(item => item.id !== "0");
  const sortedCountries = newCountries.sort(function(a, b){
    if(a.quocgia < b.quocgia) { return -1; }
    if(a.quocgia > b.quocgia) { return 1; }
    return 0;
  })
  const data = sortedCountries.map(item => {
    return {
      ...item,
      value: item.id,
      label: item.quocgia
    }
  })
  yield put({ type: "GET_COUNTRIES_LIST_SUCCESS", data: data })
}

function* handleAddress(receivedData) {
  let data = {
    province: provincesList.filter(item => item.id !== "000"),
    districts: [],
    wards: []
  };
  if(receivedData.data && receivedData.data.province){
    data.districts = districtsList.filter(item => item.tinhthanh == receivedData.data.province);
    data.wards = [];
  }
  if(receivedData.data && receivedData.data.district){
    data.wards = wardsList.filter(item => item.quanhuyen === receivedData.data.district);
  }
  yield put({ type: "GET_ADDRESS_INFO_SUCCESS", data: data })
}


//root saga
export default function* rootSaga() {
  yield takeLatest(SAVE_LANGUAGE, saveLang)
  yield takeLatest(SAVE_COUNTRIES_LIST, getListCountries)
  yield takeLatest(SAVE_CITY, saveCityIndex)
  yield takeLatest(SAVE_WARD, saveWardIndex)
  yield takeLatest(SAVE_RESULT, saveResult)
  yield takeLatest(GET_LIST_PROFILE, getListProfile)
  yield takeLatest(SAVE_USER_INFO, saveUserInfo)
  yield takeLatest(GET_LIST_TEST, getListTest)
  yield takeLatest(GET_ALL_TEST_RESULT, getAllTestResult)
  yield takeLatest("GET_COUNTRIES_LIST", getAllCountriesList)
  yield takeLatest("GET_ADDRESS_INFO", handleAddress)
}