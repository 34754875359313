import { SAVE_RESULT, SAVE_RESULT_FAILURE, SAVE_RESULT_SUCCESS } from "../actions/types";

const initialState = {
    data: [],
  
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_RESULT:
            return {
                ...state,
            };
        case SAVE_RESULT_SUCCESS:
            return {
                ...state,
               data: action.data,
            };
        case SAVE_RESULT_FAILURE:
            return state
        default:
            return state;
    }
}
export default reducer;