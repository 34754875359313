import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/base.scss"
import "./view/css/index.css"
import "./view/css/header.css"
import "./view/css/headerUserBox.css"
import "./view/css/dropZone.css"
import "./view/css/input.css"
import App from "./App.js"
 
ReactDOM.render(
  <App/>,
  document.getElementById('app')
);