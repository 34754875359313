const initialState = {
  data: ""
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
      case 'GET_COUNTRIES_LIST':
          return {
              ...state,
          };
      case 'GET_COUNTRIES_LIST_SUCCESS':
          return {
              ...state,
             data: action.data
          };
      case 'GET_COUNTRIES_LIST_FAILURE':
          return state
      default:
          return state;
  }
}
export default reducer;