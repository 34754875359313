const contriesList = [
   {
      "id":"0",
      "quocgia":"None"
   },
   {
      "id":"1",
      "quocgia":"Andorra"
   },
   {
      "id":"2",
      "quocgia":"United arab emirates"
   },
   {
      "id":"3",
      "quocgia":"Antigua and barbuda"
   },
   {
      "id":"4",
      "quocgia":"Anguilla"
   },
   {
      "id":"5",
      "quocgia":"An ba ni"
   },
   {
      "id":"6",
      "quocgia":"Ác mê nia"
   },
   {
      "id":"7",
      "quocgia":"Netherlands antilles"
   },
   {
      "id":"8",
      "quocgia":"Ăng gô la"
   },
   {
      "id":"9",
      "quocgia":"Antarctica"
   },
   {
      "id":"10",
      "quocgia":"Ác hen ti na"
   },
   {
      "id":"11",
      "quocgia":"Samoa"
   },
   {
      "id":"12",
      "quocgia":"Áo"
   },
   {
      "id":"13",
      "quocgia":"Úc"
   },
   {
      "id":"14",
      "quocgia":"Aruba"
   },
   {
      "id":"15",
      "quocgia":"Azerbaijan"
   },
   {
      "id":"16",
      "quocgia":"Bosnia and herzegovina"
   },
   {
      "id":"17",
      "quocgia":"Barbados"
   },
   {
      "id":"18",
      "quocgia":"Bangladesh"
   },
   {
      "id":"19",
      "quocgia":"Bỉ"
   },
   {
      "id":"20",
      "quocgia":"Burkina faso"
   },
   {
      "id":"21",
      "quocgia":"Bungari"
   },
   {
      "id":"22",
      "quocgia":"Bahrain"
   },
   {
      "id":"23",
      "quocgia":"Burundi"
   },
   {
      "id":"24",
      "quocgia":"Benin"
   },
   {
      "id":"25",
      "quocgia":"Bermuda"
   },
   {
      "id":"26",
      "quocgia":"Brunei"
   },
   {
      "id":"27",
      "quocgia":"Bolivia"
   },
   {
      "id":"28",
      "quocgia":"Braxin"
   },
   {
      "id":"29",
      "quocgia":"Bahamas"
   },
   {
      "id":"30",
      "quocgia":"Bhutan"
   },
   {
      "id":"31",
      "quocgia":"Bouvet island"
   },
   {
      "id":"32",
      "quocgia":"Botswana"
   },
   {
      "id":"33",
      "quocgia":"Belarus"
   },
   {
      "id":"34",
      "quocgia":"Belize"
   },
   {
      "id":"35",
      "quocgia":"Canada"
   },
   {
      "id":"36",
      "quocgia":"Cocos (keeling) islands"
   },
   {
      "id":"37",
      "quocgia":"Congo, the democratic republic of the"
   },
   {
      "id":"38",
      "quocgia":"Central african republic"
   },
   {
      "id":"39",
      "quocgia":"Công gô"
   },
   {
      "id":"40",
      "quocgia":"Switzerland"
   },
   {
      "id":"41",
      "quocgia":"COTE D'IVOIRE"
   },
   {
      "id":"42",
      "quocgia":"Cook islands"
   },
   {
      "id":"43",
      "quocgia":"Chi lê"
   },
   {
      "id":"44",
      "quocgia":"Cameroon"
   },
   {
      "id":"45",
      "quocgia":"Trung quốc"
   },
   {
      "id":"46",
      "quocgia":"Colômbia"
   },
   {
      "id":"47",
      "quocgia":"Costa rica"
   },
   {
      "id":"48",
      "quocgia":"Cu ba"
   },
   {
      "id":"49",
      "quocgia":"Cape verde"
   },
   {
      "id":"50",
      "quocgia":"Christmas island"
   },
   {
      "id":"51",
      "quocgia":"Cyprus"
   },
   {
      "id":"52",
      "quocgia":"Czech republic"
   },
   {
      "id":"53",
      "quocgia":"Germany"
   },
   {
      "id":"54",
      "quocgia":"Djibouti"
   },
   {
      "id":"55",
      "quocgia":"Denmark"
   },
   {
      "id":"56",
      "quocgia":"Dominica"
   },
   {
      "id":"57",
      "quocgia":"Dominican republic"
   },
   {
      "id":"58",
      "quocgia":"Algerie"
   },
   {
      "id":"59",
      "quocgia":"East timor"
   },
   {
      "id":"60",
      "quocgia":"Ecuador"
   },
   {
      "id":"61",
      "quocgia":"Estonia"
   },
   {
      "id":"62",
      "quocgia":"Egypt"
   },
   {
      "id":"63",
      "quocgia":"Western sahara"
   },
   {
      "id":"64",
      "quocgia":"Eritrea"
   },
   {
      "id":"66",
      "quocgia":"Ethiopia"
   },
   {
      "id":"67",
      "quocgia":"Finland"
   },
   {
      "id":"68",
      "quocgia":"Fiji"
   },
   {
      "id":"69",
      "quocgia":"Falkland islands (malvinas)"
   },
   {
      "id":"71",
      "quocgia":"Faroe islands"
   },
   {
      "id":"72",
      "quocgia":"Pháp"
   },
   {
      "id":"73",
      "quocgia":"Serbia and montenegro (formerly yugoslavia)"
   },
   {
      "id":"74",
      "quocgia":"Gabon"
   },
   {
      "id":"75",
      "quocgia":"Anh"
   },
   {
      "id":"76",
      "quocgia":"Grenada"
   },
   {
      "id":"77",
      "quocgia":"Georgia"
   },
   {
      "id":"78",
      "quocgia":"French guiana"
   },
   {
      "id":"79",
      "quocgia":"Guernsey"
   },
   {
      "id":"81",
      "quocgia":"Gibraltar"
   },
   {
      "id":"82",
      "quocgia":"Greenland"
   },
   {
      "id":"83",
      "quocgia":"Gambia"
   },
   {
      "id":"84",
      "quocgia":"Guinea"
   },
   {
      "id":"86",
      "quocgia":"Equatorial guinea"
   },
   {
      "id":"87",
      "quocgia":"Greece"
   },
   {
      "id":"88",
      "quocgia":"South georgia and the south sandwich islands"
   },
   {
      "id":"89",
      "quocgia":"Guatemala"
   },
   {
      "id":"91",
      "quocgia":"Guyana"
   },
   {
      "id":"92",
      "quocgia":"Guinea-bissau"
   },
   {
      "id":"93",
      "quocgia":"Hong kong"
   },
   {
      "id":"95",
      "quocgia":"Honduras"
   },
   {
      "id":"96",
      "quocgia":"Croatia (local name: hrvatska)"
   },
   {
      "id":"97",
      "quocgia":"Haiti"
   },
   {
      "id":"98",
      "quocgia":"Hungary"
   },
   {
      "id":"99",
      "quocgia":"Indonesia"
   },
   {
      "id":"100",
      "quocgia":"Ireland"
   },
   {
      "id":"101",
      "quocgia":"Israel"
   },
   {
      "id":"102",
      "quocgia":"Isle of man"
   },
   {
      "id":"103",
      "quocgia":"India"
   },
   {
      "id":"105",
      "quocgia":"Iraq"
   },
   {
      "id":"106",
      "quocgia":"Iran (islamic republic of)"
   },
   {
      "id":"107",
      "quocgia":"Iceland"
   },
   {
      "id":"108",
      "quocgia":"Italy"
   },
   {
      "id":"109",
      "quocgia":"Jersey"
   },
   {
      "id":"110",
      "quocgia":"Jamaica"
   },
   {
      "id":"111",
      "quocgia":"Jordan"
   },
   {
      "id":"112",
      "quocgia":"Nhật"
   },
   {
      "id":"113",
      "quocgia":"Kenya"
   },
   {
      "id":"114",
      "quocgia":"Kyrgyzstan"
   },
   {
      "id":"116",
      "quocgia":"Kiribati"
   },
   {
      "id":"117",
      "quocgia":"Comoros"
   },
   {
      "id":"118",
      "quocgia":"St. kitts and nevis"
   },
   {
      "id":"119",
      "quocgia":"Bắc Triều Tiên"
   },
   {
      "id":"120",
      "quocgia":"Hàn quốc"
   },
   {
      "id":"121",
      "quocgia":"Kuwait"
   },
   {
      "id":"122",
      "quocgia":"Cayman islands"
   },
   {
      "id":"123",
      "quocgia":"Kazakhstan"
   },
   {
      "id":"124",
      "quocgia":"Lào"
   },
   {
      "id":"125",
      "quocgia":"Lebanon"
   },
   {
      "id":"126",
      "quocgia":"St. lucia"
   },
   {
      "id":"127",
      "quocgia":"Liechtenstein"
   },
   {
      "id":"128",
      "quocgia":"Sri lanka"
   },
   {
      "id":"129",
      "quocgia":"Liberia"
   },
   {
      "id":"130",
      "quocgia":"Lesotho"
   },
   {
      "id":"131",
      "quocgia":"Lithuania"
   },
   {
      "id":"132",
      "quocgia":"Luxembourg"
   },
   {
      "id":"133",
      "quocgia":"Latvia"
   },
   {
      "id":"134",
      "quocgia":"Libyan arab jamahiriya"
   },
   {
      "id":"135",
      "quocgia":"Morocco"
   },
   {
      "id":"136",
      "quocgia":"Monaco"
   },
   {
      "id":"137",
      "quocgia":"Moldova, republic of"
   },
   {
      "id":"138",
      "quocgia":"Madagascar"
   },
   {
      "id":"139",
      "quocgia":"Marshall islands"
   },
   {
      "id":"140",
      "quocgia":"Macedonia, the former yugoslav republic of"
   },
   {
      "id":"141",
      "quocgia":"Mali"
   },
   {
      "id":"142",
      "quocgia":"Myanmar"
   },
   {
      "id":"143",
      "quocgia":"Mongolia"
   },
   {
      "id":"144",
      "quocgia":"Macau"
   },
   {
      "id":"145",
      "quocgia":"Northern mariana islands"
   },
   {
      "id":"146",
      "quocgia":"Martinique"
   },
   {
      "id":"147",
      "quocgia":"Mauritania"
   },
   {
      "id":"148",
      "quocgia":"Montserrat"
   },
   {
      "id":"149",
      "quocgia":"Malta"
   },
   {
      "id":"150",
      "quocgia":"Mauritius"
   },
   {
      "id":"151",
      "quocgia":"Maldives"
   },
   {
      "id":"152",
      "quocgia":"Malawi"
   },
   {
      "id":"153",
      "quocgia":"Mexico"
   },
   {
      "id":"154",
      "quocgia":"Malaisia"
   },
   {
      "id":"155",
      "quocgia":"Mozambique"
   },
   {
      "id":"156",
      "quocgia":"Namibia"
   },
   {
      "id":"157",
      "quocgia":"New caledonia"
   },
   {
      "id":"158",
      "quocgia":"Niger"
   },
   {
      "id":"159",
      "quocgia":"Norfolk island"
   },
   {
      "id":"160",
      "quocgia":"Nigeria"
   },
   {
      "id":"161",
      "quocgia":"Nicaragua"
   },
   {
      "id":"162",
      "quocgia":"Hà lan"
   },
   {
      "id":"163",
      "quocgia":"Norway"
   },
   {
      "id":"164",
      "quocgia":"Nepal"
   },
   {
      "id":"165",
      "quocgia":"Nauru"
   },
   {
      "id":"166",
      "quocgia":"Niue"
   },
   {
      "id":"167",
      "quocgia":"New zealand"
   },
   {
      "id":"168",
      "quocgia":"Oman"
   },
   {
      "id":"169",
      "quocgia":"Panama"
   },
   {
      "id":"170",
      "quocgia":"Peru"
   },
   {
      "id":"171",
      "quocgia":"French polynesia"
   },
   {
      "id":"172",
      "quocgia":"Papua new guinea"
   },
   {
      "id":"173",
      "quocgia":"Philippines"
   },
   {
      "id":"174",
      "quocgia":"Pakistan"
   },
   {
      "id":"175",
      "quocgia":"Poland"
   },
   {
      "id":"176",
      "quocgia":"St. pierre and miquelon"
   },
   {
      "id":"177",
      "quocgia":"Pitcairn"
   },
   {
      "id":"178",
      "quocgia":"Puerto rico"
   },
   {
      "id":"179",
      "quocgia":"Palestinian authority"
   },
   {
      "id":"180",
      "quocgia":"Portugal"
   },
   {
      "id":"181",
      "quocgia":"Palau"
   },
   {
      "id":"182",
      "quocgia":"Paraguay"
   },
   {
      "id":"183",
      "quocgia":"Qatar"
   },
   {
      "id":"184",
      "quocgia":"Reunion"
   },
   {
      "id":"186",
      "quocgia":"Nga"
   },
   {
      "id":"187",
      "quocgia":"Rwanda"
   },
   {
      "id":"188",
      "quocgia":"Saudi arabia"
   },
   {
      "id":"190",
      "quocgia":"Seychelles"
   },
   {
      "id":"191",
      "quocgia":"Sudan"
   },
   {
      "id":"192",
      "quocgia":"Sweden"
   },
   {
      "id":"193",
      "quocgia":"Singapore"
   },
   {
      "id":"194",
      "quocgia":"St. helena"
   },
   {
      "id":"195",
      "quocgia":"Slovenia"
   },
   {
      "id":"197",
      "quocgia":"Slovakia (slovak republic)"
   },
   {
      "id":"198",
      "quocgia":"Sierra leone"
   },
   {
      "id":"199",
      "quocgia":"San marino"
   },
   {
      "id":"200",
      "quocgia":"Senegal"
   },
   {
      "id":"201",
      "quocgia":"Somalia"
   },
   {
      "id":"202",
      "quocgia":"Suriname"
   },
   {
      "id":"203",
      "quocgia":"Sao tome and principe"
   },
   {
      "id":"204",
      "quocgia":"El salvador"
   },
   {
      "id":"205",
      "quocgia":"Syrian arab republic"
   },
   {
      "id":"206",
      "quocgia":"Swaziland"
   },
   {
      "id":"208",
      "quocgia":"Chad"
   },
   {
      "id":"209",
      "quocgia":"French southern territories"
   },
   {
      "id":"210",
      "quocgia":"Togo"
   },
   {
      "id":"211",
      "quocgia":"Thái lan"
   },
   {
      "id":"212",
      "quocgia":"Tajikistan"
   },
   {
      "id":"213",
      "quocgia":"Tokelau"
   },
   {
      "id":"214",
      "quocgia":"Timor leste"
   },
   {
      "id":"215",
      "quocgia":"Turkmenistan"
   },
   {
      "id":"216",
      "quocgia":"Tunisia"
   },
   {
      "id":"217",
      "quocgia":"Tonga"
   },
   {
      "id":"218",
      "quocgia":"Turkey"
   },
   {
      "id":"219",
      "quocgia":"Trinidad and tobago"
   },
   {
      "id":"220",
      "quocgia":"Tuvalu"
   },
   {
      "id":"221",
      "quocgia":"Đài loan"
   },
   {
      "id":"222",
      "quocgia":"Tanzania, united republic of"
   },
   {
      "id":"223",
      "quocgia":"Ukraine"
   },
   {
      "id":"224",
      "quocgia":"Uganda"
   },
   {
      "id":"225",
      "quocgia":"United states minor outlying islands"
   },
   {
      "id":"226",
      "quocgia":"Hoa kỳ (Mỹ)"
   },
   {
      "id":"227",
      "quocgia":"Uruguay"
   },
   {
      "id":"228",
      "quocgia":"Uzbekistan"
   },
   {
      "id":"229",
      "quocgia":"Vatican city state (holy see)"
   },
   {
      "id":"230",
      "quocgia":"St. vincent and the grenadines"
   },
   {
      "id":"231",
      "quocgia":"Venezuela"
   },
   {
      "id":"232",
      "quocgia":"Virgin islands (british)"
   },
   {
      "id":"233",
      "quocgia":"Virgin islands (u.s.)"
   },
   {
      "id":"234",
      "quocgia":"Việt nam"
   },
   {
      "id":"235",
      "quocgia":"Vanuatu"
   },
   {
      "id":"236",
      "quocgia":"Wallis and futuna islands"
   },
   {
      "id":"237",
      "quocgia":"Samoa"
   },
   {
      "id":"238",
      "quocgia":"Yemen"
   },
   {
      "id":"239",
      "quocgia":"Mayotte"
   },
   {
      "id":"240",
      "quocgia":"Yugoslavia (serbia and montenegro)"
   },
   {
      "id":"241",
      "quocgia":"Sovereign military order of malta (smom)no iso code"
   },
   {
      "id":"242",
      "quocgia":"British southern and antarctic territories>"
   },
   {
      "id":"243",
      "quocgia":"England no iso code"
   },
   {
      "id":"244",
      "quocgia":"Scotlandno iso code"
   },
   {
      "id":"245",
      "quocgia":"Northern ireland  no iso code"
   },
   {
      "id":"246",
      "quocgia":"Great britain see unite"
   },
   {
      "id":"247",
      "quocgia":"Wales  no iso code"
   },
   {
      "id":"248",
      "quocgia":"South africa"
   },
   {
      "id":"249",
      "quocgia":"Zambia"
   },
   {
      "id":"250",
      "quocgia":"Zimbabwe"
   },
   {
      "id":"251",
      "quocgia":"Không xác định"
   },
   {
      "id":"254",
      "quocgia":"Ấn Độ"
   },
   {
      "id":"255",
      "quocgia":"Tây Ban Nha"
   },
   {
      "id":"256",
      "quocgia":"Ba Lan"
   },
   {
      "id":"257",
      "quocgia":"Ai Cập"
   },
   {
      "id":"258",
      "quocgia":"Taiwan"
   },
   {
      "id":"260",
      "quocgia":"Đan Mạch"
   },
   {
      "id":"263",
      "quocgia":"Mỹ-Anh-Đức-Ailen"
   },
   {
      "id":"264",
      "quocgia":"Nauy"
   },
   {
      "id":"252",
      "quocgia":"Thụy Sỹ"
   },
   {
      "id":"253",
      "quocgia":"Đức"
   },
   {
      "id":"259",
      "quocgia":"Serbia"
   },
   {
      "id":"261",
      "quocgia":"Mã Lai"
   },
   {
      "id":"262",
      "quocgia":"Teveik "
   },
   {
      "id":"65",
      "quocgia":"Spain"
   },
   {
      "id":"70",
      "quocgia":"Micronesia, federated states of"
   },
   {
      "id":"80",
      "quocgia":"Ghana"
   },
   {
      "id":"85",
      "quocgia":"Guadeloupe"
   },
   {
      "id":"90",
      "quocgia":"Guam"
   },
   {
      "id":"94",
      "quocgia":"Heard and mc donald islands"
   },
   {
      "id":"104",
      "quocgia":"British indian ocean territory"
   },
   {
      "id":"115",
      "quocgia":"Campuchia"
   },
   {
      "id":"185",
      "quocgia":"Romania"
   },
   {
      "id":"189",
      "quocgia":"Solomon islands"
   },
   {
      "id":"196",
      "quocgia":"Svalbard and jan mayen islands"
   },
   {
      "id":"207",
      "quocgia":"Turks and caicos islands"
   }
]

export {
   contriesList
}

export default {
   contriesList
}