export const GET_OVERVIEW = "GET_OVERVIEW"
export const GET_OVERVIEW_SUCCESS = "GET_OVERVIEW_SUCCESS"
export const GET_OVERVIEW_FAILURE = "GET_OVERVIEW_FAILURE"

export const GET_DIARY = "GET_DIARY"
export const GET_DIARY_SUCCESS = "GET_DIARY_SUCCESS"
export const GET_DIARY_FAILURE = "GET_DIARY_FAILURE"

export const GET_DISTRIBUTOR = "GET_DISTRIBUTOR"
export const GET_DISTRIBUTOR_SUCCESS = "GET_DISTRIBUTOR_SUCCESS"
export const GET_DISTRIBUTOR_FAILURE = "GET_DISTRIBUTOR_FAILURE"

export const GET_MANUFACTURER_SUCCESS = "GET_MANUFACTURER_SUCCESS"

export const GET_CERTIFICATE_SUCCESS = "GET_CERTIFICATE_SUCCESS"

export const GET_MAP_LOCATION = "GET_MAP_LOCATION"
export const GET_MAP_LOCATION_SUCCESS = "GET_MAP_LOCATION_SUCCESS"
export const GET_MAP_LOCATION_FAILURE = "GET_MAP_LOCATION_FAILURE"

export const SAVE_LANGUAGE = 'SAVE_LANGUAGE'
export const SAVE_LANGUAGE_SUCCESS = 'SAVE_LANGUAGE_SUCCESS'
export const SAVE_LANGUAGE_FAILURE = 'SAVE_LANGUAGE_FAILURE'

export const SAVE_COUNTRIES_LIST = 'SAVE_COUNTRIES_LIST'
export const SAVE_COUNTRIES_LIST_SUCCESS = 'SAVE_COUNTRIES_LIST_SUCCESS'
export const SAVE_COUNTRIES_LIST_FAILURE = 'SAVE_COUNTRIES_LIST_FAILURE'

export const SAVE_DISTRICT_LIST = 'SAVE_DISTRICT_LIST'
export const SAVE_DISTRICT_LIST_SUCCESS = 'SAVE_DISTRICT_LIST_SUCCESS'
export const SAVE_DISTRICT_LIST_FAILURE = 'SAVE_DISTRICT_LIST_FAILURE'

export const SAVE_CITY = 'SAVE_CITY'
export const SAVE_CITY_SUCCESS = 'SAVE_CITY_SUCCESS'
export const SAVE_CITY_FAILURE = 'SAVE_CITY_FAILURE'

export const SAVE_DISTRICT = 'SAVE_DISTRICT'
export const SAVE_DISTRICT_SUCCESS = 'SAVE_DISTRICT_SUCCESS'
export const SAVE_DISTRICT_FAILURE = 'SAVE_DISTRICT_FAILURE'

export const SAVE_WARD = 'SAVE_WARD'
export const SAVE_WARD_SUCCESS = 'SAVE_WARD_SUCCESS'
export const SAVE_WARD_FAILURE = 'SAVE_WARD_FAILURE'

export const SAVE_RESULT = 'SAVE_RESULT'
export const SAVE_RESULT_SUCCESS = 'SAVE_RESULT_SUCCESS'
export const SAVE_RESULT_FAILURE = 'SAVE_RESULT_FAILURE'

export const GET_LIST_PROFILE = 'GET_LIST_PROFILE'
export const GET_LIST_PROFILE_SUCCESS = 'GET_LIST_PROFILE_SUCCESS'
export const GET_LIST_PROFILE_FAILURE = 'GET_LIST_PROFILE_FAILURE'

export const SAVE_USER_INFO = 'SAVE_USER_INFO'
export const SAVE_USER_INFO_SUCCESS = 'SAVE_USER_INFO_SUCCESS'
export const SAVE_USER_INFO_FAILURE = 'SAVE_USER_INFO_FAILURE'

export const GET_LIST_TEST = 'GET_LIST_TEST'
export const GET_LIST_TEST_SUCCESS = 'GET_LIST_TEST_SUCCESS'
export const GET_LIST_TEST_FAILURE = 'GET_LIST_TEST_FAILURE'

export const GET_LIST_PAYMENT = 'GET_LIST_PAYMENT'
export const GET_LIST_PAYMENT_SUCCESS = 'GET_LIST_PAYMENT_SUCCESS'
export const GET_LIST_PAYMENT_FAILURE = 'GET_LIST_PAYMENT_FAILURE'

export const GET_ALL_TEST_RESULT = 'GET_ALL_TEST_RESULT'
export const GET_ALL_TEST_RESULT_SUCCESS = 'GET_ALL_TEST_RESULT_SUCCESS'
export const GET_ALL_TEST_RESULT_FAILURE = 'GET_ALL_TEST_RESULT_FAILURE'








