const HOST = process.env.REACT_APP_URL

const LINK_API = {
    GET_LIST_COUNTRIES: 'https://countriesnow.space/api/v0.1/countries',
    SEND_SMS: HOST + '/api/auth/sendsms',
    SEND_OTP: HOST + '/api/auth/verifycode',
    SUBMIT_FORM: HOST + '/api/register-tests',
    SIGN_IN: HOST + '/api/auth/signin',
    GET_RESULT: HOST + '/api/register-tests/',
    GET_TEST: HOST + '/api/test-results/approving-result',
    UPLOAD_FILE: HOST + '/api/test-results/upload',
    APPROVED_TEST: HOST + '/api/register-tests',
    UPLOAD_IMAGE: HOST + '/api/register-tests/upload',
    RESET_PASSWORD: HOST + '/api/auth/password/reset',
    CHANGE_PASSWORD: HOST + '/api/auth/password',
    CREATE_ACCOUNT: HOST + '/api/auth/signup',
    GET_INFOR_BY_PHONE: HOST + '/api/register-tests/phone/',
    GET_ALL_USERS: HOST + '/api/users',
    GET_ALL_TEST_RESULT: HOST + '/api/test-results',
    GET_MANUAL_APPROVAL: HOST + '/api/register-tests/mapping-approve-manual',
    REGISTER_FOR_PATIENT: HOST + '/api/register-tests/login',
    GET_LIST_RECORD_BY_PHONE: HOST + '/api/register-tests/phone-list/',
    CHECKIN: HOST + '/api/human_resource/check',
    GET_CONFIG: HOST + '​/api/configs',
    GET_TRANSACTION_INFO: process.env.REACT_APP_DOMAIN_BLOCKEXPLORER + '/api/v2/transactions/details?hash='
}

export {
    LINK_API,
    HOST
};

export default {
    LINK_API,
    HOST
};
