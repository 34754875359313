const wardsList = [
   {
      "id":"1011701",
      "phuongxa":"Thị trấn Đông Anh",
      "quanhuyen":"10117"
   },
   {
      "id":"1011703",
      "phuongxa":"Xã Xuân Nộn",
      "quanhuyen":"10117"
   },
   {
      "id":"1011705",
      "phuongxa":"Xã Nguyên Khê",
      "quanhuyen":"10117"
   },
   {
      "id":"1011707",
      "phuongxa":"Xã Uy Nỗ",
      "quanhuyen":"10117"
   },
   {
      "id":"1011709",
      "phuongxa":"Xã Cổ Loa",
      "quanhuyen":"10117"
   },
   {
      "id":"1011711",
      "phuongxa":"Xã Tiên Dương",
      "quanhuyen":"10117"
   },
   {
      "id":"1011713",
      "phuongxa":"Xã Nam Hồng",
      "quanhuyen":"10117"
   },
   {
      "id":"1011715",
      "phuongxa":"Xã Bắc Hồng",
      "quanhuyen":"10117"
   },
   {
      "id":"1011717",
      "phuongxa":"Xã Vân Nội",
      "quanhuyen":"10117"
   },
   {
      "id":"1011719",
      "phuongxa":"Xã Kim Nỗ",
      "quanhuyen":"10117"
   },
   {
      "id":"1011721",
      "phuongxa":"Xã Kim Chung",
      "quanhuyen":"10117"
   },
   {
      "id":"1011725",
      "phuongxa":"Xã Võng La",
      "quanhuyen":"10117"
   },
   {
      "id":"1011727",
      "phuongxa":"Xã Hải Bối",
      "quanhuyen":"10117"
   },
   {
      "id":"1011729",
      "phuongxa":"Xã Liên Hà",
      "quanhuyen":"10117"
   },
   {
      "id":"1011731",
      "phuongxa":"Xã Vân Hà",
      "quanhuyen":"10117"
   },
   {
      "id":"1011733",
      "phuongxa":"Xã Dục Tú",
      "quanhuyen":"10117"
   },
   {
      "id":"1011735",
      "phuongxa":"Xã Thuỵ Lâm",
      "quanhuyen":"10117"
   },
   {
      "id":"1011737",
      "phuongxa":"Xã Việt Hùng",
      "quanhuyen":"10117"
   },
   {
      "id":"1011739",
      "phuongxa":"Xã Mai Lâm",
      "quanhuyen":"10117"
   },
   {
      "id":"1011741",
      "phuongxa":"Xã Đông Hội",
      "quanhuyen":"10117"
   },
   {
      "id":"1011743",
      "phuongxa":"Xã Xuân Canh",
      "quanhuyen":"10117"
   },
   {
      "id":"1011745",
      "phuongxa":"Xã Tầm Xá",
      "quanhuyen":"10117"
   },
   {
      "id":"1011747",
      "phuongxa":"Xã Vĩnh Ngọc",
      "quanhuyen":"10117"
   },
   {
      "id":"1011900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10119"
   },
   {
      "id":"1011901",
      "phuongxa":"Thị trấn Gia Lâm",
      "quanhuyen":"10119"
   },
   {
      "id":"1011903",
      "phuongxa":"Thị trấn Đức Giang",
      "quanhuyen":"10119"
   },
   {
      "id":"1011905",
      "phuongxa":"Thị trấn Yên Viên",
      "quanhuyen":"10119"
   },
   {
      "id":"1011907",
      "phuongxa":"Thị trấn Sài Đồng",
      "quanhuyen":"10119"
   },
   {
      "id":"1011909",
      "phuongxa":"Xã Thạch Bàn",
      "quanhuyen":"10119"
   },
   {
      "id":"1011911",
      "phuongxa":"Xã Bát Tràng",
      "quanhuyen":"10119"
   },
   {
      "id":"1011915",
      "phuongxa":"Xã Đình Xuyên",
      "quanhuyen":"10119"
   },
   {
      "id":"1011917",
      "phuongxa":"Xã Ninh Hiệp",
      "quanhuyen":"10119"
   },
   {
      "id":"1011919",
      "phuongxa":"Xã Phù Đổng",
      "quanhuyen":"10119"
   },
   {
      "id":"1011921",
      "phuongxa":"Xã Trung Màu",
      "quanhuyen":"10119"
   },
   {
      "id":"1011923",
      "phuongxa":"Xã Dương Hà",
      "quanhuyen":"10119"
   },
   {
      "id":"1011925",
      "phuongxa":"Xã Yên Thường",
      "quanhuyen":"10119"
   },
   {
      "id":"1011927",
      "phuongxa":"Xã Yên Viên",
      "quanhuyen":"10119"
   },
   {
      "id":"1011929",
      "phuongxa":"Xã Giang Biên",
      "quanhuyen":"10119"
   },
   {
      "id":"1011931",
      "phuongxa":"Xã Thượng Thanh",
      "quanhuyen":"10119"
   },
   {
      "id":"1011933",
      "phuongxa":"Xã Kim Lan",
      "quanhuyen":"10119"
   },
   {
      "id":"1011935",
      "phuongxa":"Xã Việt Hưng",
      "quanhuyen":"10119"
   },
   {
      "id":"1011937",
      "phuongxa":"Xã Ngọc Thuỵ",
      "quanhuyen":"10119"
   },
   {
      "id":"1011939",
      "phuongxa":"Xã Gia Thuỵ",
      "quanhuyen":"10119"
   },
   {
      "id":"1011941",
      "phuongxa":"Xã Bồ Đề",
      "quanhuyen":"10119"
   },
   {
      "id":"1011943",
      "phuongxa":"Xã Long Biên",
      "quanhuyen":"10119"
   },
   {
      "id":"1011945",
      "phuongxa":"Xã Hội Xá",
      "quanhuyen":"10119"
   },
   {
      "id":"1011947",
      "phuongxa":"Xã Cổ Bi",
      "quanhuyen":"10119"
   },
   {
      "id":"1011949",
      "phuongxa":"Xã Trâu Quỳ",
      "quanhuyen":"10119"
   },
   {
      "id":"1011953",
      "phuongxa":"Xã Dương Xá",
      "quanhuyen":"10119"
   },
   {
      "id":"1011955",
      "phuongxa":"Xã Kiêu Kỵ",
      "quanhuyen":"10119"
   },
   {
      "id":"1011957",
      "phuongxa":"Xã Văn Đức",
      "quanhuyen":"10119"
   },
   {
      "id":"1011961",
      "phuongxa":"Xã Cự Khối",
      "quanhuyen":"10119"
   },
   {
      "id":"1011963",
      "phuongxa":"Xã Dương Quang",
      "quanhuyen":"10119"
   },
   {
      "id":"1011965",
      "phuongxa":"Xã Phú Thị",
      "quanhuyen":"10119"
   },
   {
      "id":"1011967",
      "phuongxa":"Xã Đặng Xá",
      "quanhuyen":"10119"
   },
   {
      "id":"1011969",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"10119"
   },
   {
      "id":"1012101",
      "phuongxa":"Phường Cầu Diễn",
      "quanhuyen":"10121"
   },
   {
      "id":"1012103",
      "phuongxa":"Phường Mỹ Đình 1",
      "quanhuyen":"10121"
   },
   {
      "id":"1012105",
      "phuongxa":"Phường Tây Tựu",
      "quanhuyen":"10121"
   },
   {
      "id":"1012107",
      "phuongxa":"Xã Phú Diễn",
      "quanhuyen":"10121"
   },
   {
      "id":"1012109",
      "phuongxa":"Phường Minh Khai",
      "quanhuyen":"10121"
   },
   {
      "id":"1012113",
      "phuongxa":"Phường Liên Mạc",
      "quanhuyen":"10121"
   },
   {
      "id":"1012115",
      "phuongxa":"Xã Thuỵ Phương",
      "quanhuyen":"10121"
   },
   {
      "id":"1012117",
      "phuongxa":"Phường Đông Ngạc",
      "quanhuyen":"10121"
   },
   {
      "id":"1012119",
      "phuongxa":"Phường Xuân Đỉnh",
      "quanhuyen":"10121"
   },
   {
      "id":"1012125",
      "phuongxa":"Phường Mễ Trì",
      "quanhuyen":"10121"
   },
   {
      "id":"1012127",
      "phuongxa":"Phường Tây Mỗ",
      "quanhuyen":"10121"
   },
   {
      "id":"1012129",
      "phuongxa":"Phường Đại Mỗ",
      "quanhuyen":"10121"
   },
   {
      "id":"1012131",
      "phuongxa":"Phường Xuân Phương",
      "quanhuyen":"10121"
   },
   {
      "id":"1012300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10123"
   },
   {
      "id":"1012301",
      "phuongxa":"Thị trấn Văn Điển",
      "quanhuyen":"10123"
   },
   {
      "id":"1012303",
      "phuongxa":"Xã Định Công",
      "quanhuyen":"10123"
   },
   {
      "id":"1012305",
      "phuongxa":"Xã Vĩnh Tuy",
      "quanhuyen":"10123"
   },
   {
      "id":"1012307",
      "phuongxa":"Xã Thanh Trì",
      "quanhuyen":"10123"
   },
   {
      "id":"1012309",
      "phuongxa":"Xã Trần Phú",
      "quanhuyen":"10123"
   },
   {
      "id":"1012311",
      "phuongxa":"Xã Yên Sở",
      "quanhuyen":"10123"
   },
   {
      "id":"1012313",
      "phuongxa":"Xã Ngũ Hiệp",
      "quanhuyen":"10123"
   },
   {
      "id":"1012315",
      "phuongxa":"Xã Đông Mỹ",
      "quanhuyen":"10123"
   },
   {
      "id":"1012317",
      "phuongxa":"Xã Yên Mỹ",
      "quanhuyen":"10123"
   },
   {
      "id":"1012319",
      "phuongxa":"Xã Duyên Hà",
      "quanhuyen":"10123"
   },
   {
      "id":"1012321",
      "phuongxa":"Xã Vạn Phúc",
      "quanhuyen":"10123"
   },
   {
      "id":"1012323",
      "phuongxa":"Xã Tứ Hiệp",
      "quanhuyen":"10123"
   },
   {
      "id":"1012327",
      "phuongxa":"Xã Thanh Liệt",
      "quanhuyen":"10123"
   },
   {
      "id":"1012329",
      "phuongxa":"Xã Đại Kim",
      "quanhuyen":"10123"
   },
   {
      "id":"1012331",
      "phuongxa":"Xã Lĩnh Nam",
      "quanhuyen":"10123"
   },
   {
      "id":"1012333",
      "phuongxa":"Xã Tam Hiệp",
      "quanhuyen":"10123"
   },
   {
      "id":"1012335",
      "phuongxa":"Xã Tân Triều",
      "quanhuyen":"10123"
   },
   {
      "id":"1012337",
      "phuongxa":"Xã Hoàng Liệt",
      "quanhuyen":"10123"
   },
   {
      "id":"1012339",
      "phuongxa":"Xã Vĩnh Quỳnh",
      "quanhuyen":"10123"
   },
   {
      "id":"1012341",
      "phuongxa":"Xã Liên Ninh",
      "quanhuyen":"10123"
   },
   {
      "id":"1012343",
      "phuongxa":"Xã Ngọc Hồi",
      "quanhuyen":"10123"
   },
   {
      "id":"1012345",
      "phuongxa":"Xã Đại áng",
      "quanhuyen":"10123"
   },
   {
      "id":"1012347",
      "phuongxa":"Xã Hữu Hoà",
      "quanhuyen":"10123"
   },
   {
      "id":"1012349",
      "phuongxa":"Xã Tả Thanh Oai",
      "quanhuyen":"10123"
   },
   {
      "id":"1012500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10125"
   },
   {
      "id":"1012501",
      "phuongxa":"Phường Nguyễn Trãi",
      "quanhuyen":"10125"
   },
   {
      "id":"1012503",
      "phuongxa":"Phường Văn Mỗ",
      "quanhuyen":"10125"
   },
   {
      "id":"1012505",
      "phuongxa":"Phường Vạn Phúc",
      "quanhuyen":"10125"
   },
   {
      "id":"1012507",
      "phuongxa":"Phường Yết Kiêu",
      "quanhuyen":"10125"
   },
   {
      "id":"1012509",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"10125"
   },
   {
      "id":"1012511",
      "phuongxa":"Phường Phúc La",
      "quanhuyen":"10125"
   },
   {
      "id":"1012513",
      "phuongxa":"Phường Hà Cầu",
      "quanhuyen":"10125"
   },
   {
      "id":"1012515",
      "phuongxa":"Phường Văn Khê",
      "quanhuyen":"10125"
   },
   {
      "id":"1012519",
      "phuongxa":"Phường Kiến Hưng",
      "quanhuyen":"10125"
   },
   {
      "id":"1012521",
      "phuongxa":"Phường Phú Lãm",
      "quanhuyen":"10125"
   },
   {
      "id":"1012523",
      "phuongxa":"Phường Phú Lương",
      "quanhuyen":"10125"
   },
   {
      "id":"1012525",
      "phuongxa":"Phường Dương Nội",
      "quanhuyen":"10125"
   },
   {
      "id":"1012527",
      "phuongxa":"Phường Đồng Mai",
      "quanhuyen":"10125"
   },
   {
      "id":"1012529",
      "phuongxa":"Phường Biên Giang",
      "quanhuyen":"10125"
   },
   {
      "id":"1012531",
      "phuongxa":"Phường Văn Quán",
      "quanhuyen":"10125"
   },
   {
      "id":"1012533",
      "phuongxa":"Phường Mộ Lao",
      "quanhuyen":"10125"
   },
   {
      "id":"1012535",
      "phuongxa":"Phường Phú La",
      "quanhuyen":"10125"
   },
   {
      "id":"1012537",
      "phuongxa":"Phường La Khê",
      "quanhuyen":"10125"
   },
   {
      "id":"1012701",
      "phuongxa":"Phường Lê Lợi",
      "quanhuyen":"10127"
   },
   {
      "id":"1012703",
      "phuongxa":"Phường Phú Thịnh",
      "quanhuyen":"10127"
   },
   {
      "id":"1012705",
      "phuongxa":"Phường Ngô Quyền",
      "quanhuyen":"10127"
   },
   {
      "id":"1012707",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"10127"
   },
   {
      "id":"1012709",
      "phuongxa":"Phường Sơn Lộc",
      "quanhuyen":"10127"
   },
   {
      "id":"1012713",
      "phuongxa":"Xã Đường Lâm",
      "quanhuyen":"10127"
   },
   {
      "id":"1012715",
      "phuongxa":"Xã Viên Sơn",
      "quanhuyen":"10127"
   },
   {
      "id":"1012717",
      "phuongxa":"Xã Xuân Sơn",
      "quanhuyen":"10127"
   },
   {
      "id":"1012719",
      "phuongxa":"Xã Trung Hưng",
      "quanhuyen":"10127"
   },
   {
      "id":"1012721",
      "phuongxa":"Xã Thanh Mỹ",
      "quanhuyen":"10127"
   },
   {
      "id":"1012723",
      "phuongxa":"Xã Trung Sơn Trầm",
      "quanhuyen":"10127"
   },
   {
      "id":"1012727",
      "phuongxa":"Xã Sơn Đông",
      "quanhuyen":"10127"
   },
   {
      "id":"1012729",
      "phuongxa":"Xã Cổ Đông",
      "quanhuyen":"10127"
   },
   {
      "id":"1012900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10129"
   },
   {
      "id":"1012903",
      "phuongxa":"Xã Tân Đức",
      "quanhuyen":"10129"
   },
   {
      "id":"1012907",
      "phuongxa":"Xã Cổ Đô",
      "quanhuyen":"10129"
   },
   {
      "id":"1012909",
      "phuongxa":"Xã Tản Hồng",
      "quanhuyen":"10129"
   },
   {
      "id":"1012911",
      "phuongxa":"Xã Vạn Thắng",
      "quanhuyen":"10129"
   },
   {
      "id":"1012913",
      "phuongxa":"Xã Châu Sơn",
      "quanhuyen":"10129"
   },
   {
      "id":"1012915",
      "phuongxa":"Xã Phong Vân",
      "quanhuyen":"10129"
   },
   {
      "id":"1012919",
      "phuongxa":"Xã Phú Phương",
      "quanhuyen":"10129"
   },
   {
      "id":"1012921",
      "phuongxa":"Xã Phú Châu",
      "quanhuyen":"10129"
   },
   {
      "id":"1012923",
      "phuongxa":"Xã Thái Hòa",
      "quanhuyen":"10129"
   },
   {
      "id":"1012925",
      "phuongxa":"Xã Đồng Thái",
      "quanhuyen":"10129"
   },
   {
      "id":"1012927",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"10129"
   },
   {
      "id":"1012929",
      "phuongxa":"Xã Minh Châu",
      "quanhuyen":"10129"
   },
   {
      "id":"1012931",
      "phuongxa":"Xã Vật Lại",
      "quanhuyen":"10129"
   },
   {
      "id":"1012933",
      "phuongxa":"Xã Chu Minh",
      "quanhuyen":"10129"
   },
   {
      "id":"1012935",
      "phuongxa":"Xã Tòng Bạt",
      "quanhuyen":"10129"
   },
   {
      "id":"1012937",
      "phuongxa":"Xã Cẩm Lĩnh",
      "quanhuyen":"10129"
   },
   {
      "id":"1012939",
      "phuongxa":"Xã Sơn Đà",
      "quanhuyen":"10129"
   },
   {
      "id":"1012941",
      "phuongxa":"Xã Đông Quang",
      "quanhuyen":"10129"
   },
   {
      "id":"1012943",
      "phuongxa":"Xã Tiên Phong",
      "quanhuyen":"10129"
   },
   {
      "id":"1012945",
      "phuongxa":"Xã Thụy An",
      "quanhuyen":"10129"
   },
   {
      "id":"1012947",
      "phuongxa":"Xã Cam Thượng",
      "quanhuyen":"10129"
   },
   {
      "id":"1012949",
      "phuongxa":"Xã Thuần Mỹ",
      "quanhuyen":"10129"
   },
   {
      "id":"1012951",
      "phuongxa":"Xã Tản Lĩnh",
      "quanhuyen":"10129"
   },
   {
      "id":"1012955",
      "phuongxa":"Xã Minh Quang",
      "quanhuyen":"10129"
   },
   {
      "id":"1012957",
      "phuongxa":"Xã Ba Vì",
      "quanhuyen":"10129"
   },
   {
      "id":"1012959",
      "phuongxa":"Xã Vân Hòa",
      "quanhuyen":"10129"
   },
   {
      "id":"1012961",
      "phuongxa":"Xã Yên Bài",
      "quanhuyen":"10129"
   },
   {
      "id":"1012963",
      "phuongxa":"Xã Khánh Thượng",
      "quanhuyen":"10129"
   },
   {
      "id":"1013100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10131"
   },
   {
      "id":"1013101",
      "phuongxa":"Thị trấn Phúc Thọ",
      "quanhuyen":"10131"
   },
   {
      "id":"1013103",
      "phuongxa":"Xã Vân Hà",
      "quanhuyen":"10131"
   },
   {
      "id":"1013105",
      "phuongxa":"Xã Vân Phúc",
      "quanhuyen":"10131"
   },
   {
      "id":"1013107",
      "phuongxa":"Xã Vân Nam",
      "quanhuyen":"10131"
   },
   {
      "id":"1013109",
      "phuongxa":"Xã Xuân Phú",
      "quanhuyen":"10131"
   },
   {
      "id":"1013111",
      "phuongxa":"Xã Phương Độ",
      "quanhuyen":"10131"
   },
   {
      "id":"1013113",
      "phuongxa":"Xã Sen Chiểu",
      "quanhuyen":"10131"
   },
   {
      "id":"1013115",
      "phuongxa":"Xã Cẩm Đình",
      "quanhuyen":"10131"
   },
   {
      "id":"1013117",
      "phuongxa":"Xã Võng Xuyên",
      "quanhuyen":"10131"
   },
   {
      "id":"1013119",
      "phuongxa":"Xã Thọ Lộc",
      "quanhuyen":"10131"
   },
   {
      "id":"1013121",
      "phuongxa":"Xã Long Xuyên",
      "quanhuyen":"10131"
   },
   {
      "id":"1013123",
      "phuongxa":"Xã Thượng Cốc",
      "quanhuyen":"10131"
   },
   {
      "id":"1013127",
      "phuongxa":"Xã Tích Giang",
      "quanhuyen":"10131"
   },
   {
      "id":"1013129",
      "phuongxa":"Xã Thanh Đa",
      "quanhuyen":"10131"
   },
   {
      "id":"1013131",
      "phuongxa":"Xã Trạch Mỹ Lộc",
      "quanhuyen":"10131"
   },
   {
      "id":"1013133",
      "phuongxa":"Xã Phúc Hòa",
      "quanhuyen":"10131"
   },
   {
      "id":"1013135",
      "phuongxa":"Xã Ngọc Tảo",
      "quanhuyen":"10131"
   },
   {
      "id":"1013137",
      "phuongxa":"Xã Phụng Thượng",
      "quanhuyen":"10131"
   },
   {
      "id":"1013139",
      "phuongxa":"Xã Tam Thuấn",
      "quanhuyen":"10131"
   },
   {
      "id":"1013141",
      "phuongxa":"Xã Tam Hiệp",
      "quanhuyen":"10131"
   },
   {
      "id":"1013143",
      "phuongxa":"Xã Hiệp Thuận",
      "quanhuyen":"10131"
   },
   {
      "id":"1013145",
      "phuongxa":"Xã Liên Hiệp",
      "quanhuyen":"10131"
   },
   {
      "id":"1013300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10133"
   },
   {
      "id":"1013301",
      "phuongxa":"Thị trấn Phùng",
      "quanhuyen":"10133"
   },
   {
      "id":"1013303",
      "phuongxa":"Xã Trung Châu",
      "quanhuyen":"10133"
   },
   {
      "id":"1013305",
      "phuongxa":"Xã Thọ An",
      "quanhuyen":"10133"
   },
   {
      "id":"1013307",
      "phuongxa":"Xã Thọ Xuân",
      "quanhuyen":"10133"
   },
   {
      "id":"1013309",
      "phuongxa":"Xã Hồng Hà",
      "quanhuyen":"10133"
   },
   {
      "id":"1013311",
      "phuongxa":"Xã Liên Hồng",
      "quanhuyen":"10133"
   },
   {
      "id":"1013313",
      "phuongxa":"Xã Liên Hà",
      "quanhuyen":"10133"
   },
   {
      "id":"1013315",
      "phuongxa":"Xã Hạ Mỗ",
      "quanhuyen":"10133"
   },
   {
      "id":"1013321",
      "phuongxa":"Xã Thượng Mỗ",
      "quanhuyen":"10133"
   },
   {
      "id":"1013323",
      "phuongxa":"Xã Tân Hội",
      "quanhuyen":"10133"
   },
   {
      "id":"1013325",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"10133"
   },
   {
      "id":"1013327",
      "phuongxa":"Xã Đan Phượng",
      "quanhuyen":"10133"
   },
   {
      "id":"1013329",
      "phuongxa":"Xã Đồng Tháp",
      "quanhuyen":"10133"
   },
   {
      "id":"1013331",
      "phuongxa":"Xã Song Phượng",
      "quanhuyen":"10133"
   },
   {
      "id":"1013501",
      "phuongxa":"Thị trấn Trạm Trôi",
      "quanhuyen":"10135"
   },
   {
      "id":"1013503",
      "phuongxa":"Xã Đức Thượng",
      "quanhuyen":"10135"
   },
   {
      "id":"1013505",
      "phuongxa":"Xã Minh Khai",
      "quanhuyen":"10135"
   },
   {
      "id":"1013507",
      "phuongxa":"Xã Dương Liễu",
      "quanhuyen":"10135"
   },
   {
      "id":"1013509",
      "phuongxa":"Xã Di Trạch",
      "quanhuyen":"10135"
   },
   {
      "id":"1013513",
      "phuongxa":"Xã Cát Quế",
      "quanhuyen":"10135"
   },
   {
      "id":"1013515",
      "phuongxa":"Xã Kim Chung",
      "quanhuyen":"10135"
   },
   {
      "id":"1013517",
      "phuongxa":"Xã Yên Sở",
      "quanhuyen":"10135"
   },
   {
      "id":"1013519",
      "phuongxa":"Xã Sơn Đồng",
      "quanhuyen":"10135"
   },
   {
      "id":"1013521",
      "phuongxa":"Xã Vân Canh",
      "quanhuyen":"10135"
   },
   {
      "id":"1013523",
      "phuongxa":"Xã Đắc Sở",
      "quanhuyen":"10135"
   },
   {
      "id":"1013527",
      "phuongxa":"Xã Tiền Yên",
      "quanhuyen":"10135"
   },
   {
      "id":"1013529",
      "phuongxa":"Xã Song Phương",
      "quanhuyen":"10135"
   },
   {
      "id":"1013531",
      "phuongxa":"Xã An Khánh",
      "quanhuyen":"10135"
   },
   {
      "id":"1013533",
      "phuongxa":"Xã An Thượng",
      "quanhuyen":"10135"
   },
   {
      "id":"1013535",
      "phuongxa":"Xã Vân Côn",
      "quanhuyen":"10135"
   },
   {
      "id":"0000000",
      "phuongxa":"Không xác định",
      "quanhuyen":"00000"
   },
   {
      "id":"1010000",
      "phuongxa":"Không xác định",
      "quanhuyen":"10100"
   },
   {
      "id":"1010100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10101"
   },
   {
      "id":"1010101",
      "phuongxa":"Phường Phúc Xá",
      "quanhuyen":"10101"
   },
   {
      "id":"1010103",
      "phuongxa":"Phường Nguyễn Trung Trực",
      "quanhuyen":"10101"
   },
   {
      "id":"1010105",
      "phuongxa":"Phường Quán Thánh",
      "quanhuyen":"10101"
   },
   {
      "id":"1010107",
      "phuongxa":"Phường Trúc Bạch",
      "quanhuyen":"10101"
   },
   {
      "id":"1010109",
      "phuongxa":"Phường Điện Biên",
      "quanhuyen":"10101"
   },
   {
      "id":"1010111",
      "phuongxa":"Phường Kim Mã",
      "quanhuyen":"10101"
   },
   {
      "id":"1010115",
      "phuongxa":"Phường Cống Vị",
      "quanhuyen":"10101"
   },
   {
      "id":"1010117",
      "phuongxa":"Phường Ngọc Khánh",
      "quanhuyen":"10101"
   },
   {
      "id":"1010119",
      "phuongxa":"Phường Giảng Võ",
      "quanhuyen":"10101"
   },
   {
      "id":"1010121",
      "phuongxa":"Phường Ngọc Hà",
      "quanhuyen":"10101"
   },
   {
      "id":"1010123",
      "phuongxa":"Phường Thành Công",
      "quanhuyen":"10101"
   },
   {
      "id":"1010300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10103"
   },
   {
      "id":"1010301",
      "phuongxa":"Phường Bưởi",
      "quanhuyen":"10103"
   },
   {
      "id":"1010303",
      "phuongxa":"Phường Thuỵ Khuê",
      "quanhuyen":"10103"
   },
   {
      "id":"1010305",
      "phuongxa":"Phường Yên Phụ",
      "quanhuyen":"10103"
   },
   {
      "id":"1010307",
      "phuongxa":"Phường Tứ Liên",
      "quanhuyen":"10103"
   },
   {
      "id":"1010309",
      "phuongxa":"Phường Nhật Tân",
      "quanhuyen":"10103"
   },
   {
      "id":"1010311",
      "phuongxa":"Phường Quảng An",
      "quanhuyen":"10103"
   },
   {
      "id":"1010313",
      "phuongxa":"Phường Xuân La",
      "quanhuyen":"10103"
   },
   {
      "id":"1010315",
      "phuongxa":"Phường Phú Thượng",
      "quanhuyen":"10103"
   },
   {
      "id":"1010500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10105"
   },
   {
      "id":"1010501",
      "phuongxa":"Phường Cửa Nam",
      "quanhuyen":"10105"
   },
   {
      "id":"1010503",
      "phuongxa":"Phường Trần Hưng Đạo",
      "quanhuyen":"10105"
   },
   {
      "id":"1010505",
      "phuongxa":"Phường Hàng Bài",
      "quanhuyen":"10105"
   },
   {
      "id":"1010507",
      "phuongxa":"Phường Phan Chu Trinh",
      "quanhuyen":"10105"
   },
   {
      "id":"1010509",
      "phuongxa":"Phường Tràng Tiền",
      "quanhuyen":"10105"
   },
   {
      "id":"1010511",
      "phuongxa":"Phường Hàng Bạc",
      "quanhuyen":"10105"
   },
   {
      "id":"1010515",
      "phuongxa":"Phường Hàng Buồm",
      "quanhuyen":"10105"
   },
   {
      "id":"1010517",
      "phuongxa":"Phường Đồng Xuân",
      "quanhuyen":"10105"
   },
   {
      "id":"1010519",
      "phuongxa":"Phường Hàng Đào",
      "quanhuyen":"10105"
   },
   {
      "id":"1010521",
      "phuongxa":"Phường Hàng Mã",
      "quanhuyen":"10105"
   },
   {
      "id":"1010523",
      "phuongxa":"Phường Hàng Bồ",
      "quanhuyen":"10105"
   },
   {
      "id":"1010525",
      "phuongxa":"Phường Cửa Đông",
      "quanhuyen":"10105"
   },
   {
      "id":"1010529",
      "phuongxa":"Phường Hàng Gai",
      "quanhuyen":"10105"
   },
   {
      "id":"1010531",
      "phuongxa":"Phường Hàng Trống",
      "quanhuyen":"10105"
   },
   {
      "id":"1010533",
      "phuongxa":"Phường Phúc Tân",
      "quanhuyen":"10105"
   },
   {
      "id":"1010535",
      "phuongxa":"Phường Chương Dương Độ",
      "quanhuyen":"10105"
   },
   {
      "id":"1010700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10107"
   },
   {
      "id":"1010703",
      "phuongxa":"Phường Lê Đại Hành",
      "quanhuyen":"10107"
   },
   {
      "id":"1010705",
      "phuongxa":"Phường Bùi Thị Xuân",
      "quanhuyen":"10107"
   },
   {
      "id":"1010707",
      "phuongxa":"Phường Phố Huế",
      "quanhuyen":"10107"
   },
   {
      "id":"1010709",
      "phuongxa":"Phường Ngô Thì Nhậm",
      "quanhuyen":"10107"
   },
   {
      "id":"1010713",
      "phuongxa":"Phường Đồng Nhân",
      "quanhuyen":"10107"
   },
   {
      "id":"1010715",
      "phuongxa":"Phường Đông Mác",
      "quanhuyen":"10107"
   },
   {
      "id":"1010717",
      "phuongxa":"Phường Bạch Đằng",
      "quanhuyen":"10107"
   },
   {
      "id":"1010719",
      "phuongxa":"Phường Thanh Lương",
      "quanhuyen":"10107"
   },
   {
      "id":"1010721",
      "phuongxa":"Phường Thanh Nhàn",
      "quanhuyen":"10107"
   },
   {
      "id":"1010723",
      "phuongxa":"Phường Cầu Dền",
      "quanhuyen":"10107"
   },
   {
      "id":"1010727",
      "phuongxa":"Phường Bạch Mai",
      "quanhuyen":"10107"
   },
   {
      "id":"1010729",
      "phuongxa":"Phường Quỳnh Lôi",
      "quanhuyen":"10107"
   },
   {
      "id":"1010731",
      "phuongxa":"Phường Quỳnh Mai",
      "quanhuyen":"10107"
   },
   {
      "id":"1010737",
      "phuongxa":"Phường Trương Định",
      "quanhuyen":"10107"
   },
   {
      "id":"1010739",
      "phuongxa":"Phường Đồng Tâm",
      "quanhuyen":"10107"
   },
   {
      "id":"1010741",
      "phuongxa":"Phường Tương Mai",
      "quanhuyen":"10107"
   },
   {
      "id":"1010743",
      "phuongxa":"Phường Giáp Bát",
      "quanhuyen":"10107"
   },
   {
      "id":"1010745",
      "phuongxa":"Phường Mai Động",
      "quanhuyen":"10107"
   },
   {
      "id":"1010747",
      "phuongxa":"Phường Tân Mai",
      "quanhuyen":"10107"
   },
   {
      "id":"1010749",
      "phuongxa":"Phường Hoàng Văn Thụ",
      "quanhuyen":"10107"
   },
   {
      "id":"1010900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10109"
   },
   {
      "id":"1010901",
      "phuongxa":"Phường Văn Miếu",
      "quanhuyen":"10109"
   },
   {
      "id":"1010903",
      "phuongxa":"Phường Cát Linh",
      "quanhuyen":"10109"
   },
   {
      "id":"1010905",
      "phuongxa":"Phường Quốc Tử Giám",
      "quanhuyen":"10109"
   },
   {
      "id":"1010909",
      "phuongxa":"Phường Hàng Bột",
      "quanhuyen":"10109"
   },
   {
      "id":"1010911",
      "phuongxa":"Phường Ô Chợ Dừa",
      "quanhuyen":"10109"
   },
   {
      "id":"1010913",
      "phuongxa":"Phường Nam Đồng",
      "quanhuyen":"10109"
   },
   {
      "id":"1010915",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"10109"
   },
   {
      "id":"1010917",
      "phuongxa":"Phường Trung Liệt",
      "quanhuyen":"10109"
   },
   {
      "id":"1010919",
      "phuongxa":"Phường Thổ Quan",
      "quanhuyen":"10109"
   },
   {
      "id":"1010921",
      "phuongxa":"Phường Khâm Thiên",
      "quanhuyen":"10109"
   },
   {
      "id":"1010923",
      "phuongxa":"Phường Trung Phụng",
      "quanhuyen":"10109"
   },
   {
      "id":"1010925",
      "phuongxa":"Phường Phương Liên",
      "quanhuyen":"10109"
   },
   {
      "id":"1010927",
      "phuongxa":"Phường Kim Liên",
      "quanhuyen":"10109"
   },
   {
      "id":"1010929",
      "phuongxa":"Phường Phương Mai",
      "quanhuyen":"10109"
   },
   {
      "id":"1010931",
      "phuongxa":"Phường Trung Tự",
      "quanhuyen":"10109"
   },
   {
      "id":"1010933",
      "phuongxa":"Phường Khương Thượng",
      "quanhuyen":"10109"
   },
   {
      "id":"1010935",
      "phuongxa":"Phường Thịnh Quang",
      "quanhuyen":"10109"
   },
   {
      "id":"1010937",
      "phuongxa":"Phường Ngã Tư Sở",
      "quanhuyen":"10109"
   },
   {
      "id":"1010939",
      "phuongxa":"Phường Láng Hạ",
      "quanhuyen":"10109"
   },
   {
      "id":"1010941",
      "phuongxa":"Phường Láng Thượng",
      "quanhuyen":"10109"
   },
   {
      "id":"1011100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10111"
   },
   {
      "id":"1011101",
      "phuongxa":"Phường Nhân Chính",
      "quanhuyen":"10111"
   },
   {
      "id":"1011103",
      "phuongxa":"Phường Khương Trung",
      "quanhuyen":"10111"
   },
   {
      "id":"1011105",
      "phuongxa":"Phường Khương Mai",
      "quanhuyen":"10111"
   },
   {
      "id":"1011107",
      "phuongxa":"Phường Phương Liệt",
      "quanhuyen":"10111"
   },
   {
      "id":"1011109",
      "phuongxa":"Phường Thượng Đình",
      "quanhuyen":"10111"
   },
   {
      "id":"1011111",
      "phuongxa":"Phường Thanh Xuân Trung",
      "quanhuyen":"10111"
   },
   {
      "id":"1011115",
      "phuongxa":"Phường Hạ Đình",
      "quanhuyen":"10111"
   },
   {
      "id":"1011117",
      "phuongxa":"Phường Thanh Xuân Bắc",
      "quanhuyen":"10111"
   },
   {
      "id":"1011119",
      "phuongxa":"Phường Thanh Xuân Nam",
      "quanhuyen":"10111"
   },
   {
      "id":"1011121",
      "phuongxa":"Phường Kim Giang",
      "quanhuyen":"10111"
   },
   {
      "id":"1011300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10113"
   },
   {
      "id":"1011301",
      "phuongxa":"Phường Nghĩa Đô",
      "quanhuyen":"10113"
   },
   {
      "id":"1011305",
      "phuongxa":"Phường Quan Hoa",
      "quanhuyen":"10113"
   },
   {
      "id":"1011307",
      "phuongxa":"Phường Mai Dịch",
      "quanhuyen":"10113"
   },
   {
      "id":"1011309",
      "phuongxa":"Phường Dịch Vọng",
      "quanhuyen":"10113"
   },
   {
      "id":"1011311",
      "phuongxa":"Phường Yên Hoà",
      "quanhuyen":"10113"
   },
   {
      "id":"1011313",
      "phuongxa":"Phường Trung Hoà",
      "quanhuyen":"10113"
   },
   {
      "id":"1011501",
      "phuongxa":"Thị trấn Sóc Sơn",
      "quanhuyen":"10115"
   },
   {
      "id":"1011503",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"10115"
   },
   {
      "id":"1011505",
      "phuongxa":"Xã Thanh Xuân",
      "quanhuyen":"10115"
   },
   {
      "id":"1011507",
      "phuongxa":"Xã Minh Trí",
      "quanhuyen":"10115"
   },
   {
      "id":"1011509",
      "phuongxa":"Xã Minh Phú",
      "quanhuyen":"10115"
   },
   {
      "id":"1011513",
      "phuongxa":"Xã Quang Tiến",
      "quanhuyen":"10115"
   },
   {
      "id":"1011515",
      "phuongxa":"Xã Phú Cường",
      "quanhuyen":"10115"
   },
   {
      "id":"1011517",
      "phuongxa":"Xã Phú Minh",
      "quanhuyen":"10115"
   },
   {
      "id":"1011519",
      "phuongxa":"Xã Mai Đình",
      "quanhuyen":"10115"
   },
   {
      "id":"1011521",
      "phuongxa":"Xã Phù Lỗ",
      "quanhuyen":"10115"
   },
   {
      "id":"1011523",
      "phuongxa":"Xã Đông Xuân",
      "quanhuyen":"10115"
   },
   {
      "id":"1011527",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"10115"
   },
   {
      "id":"1011529",
      "phuongxa":"Xã Hồng Kỳ",
      "quanhuyen":"10115"
   },
   {
      "id":"1011533",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"10115"
   },
   {
      "id":"1011535",
      "phuongxa":"Xã Bắc Phú",
      "quanhuyen":"10115"
   },
   {
      "id":"1011539",
      "phuongxa":"Xã Xuân Giang",
      "quanhuyen":"10115"
   },
   {
      "id":"1011541",
      "phuongxa":"Xã Đức Hoà",
      "quanhuyen":"10115"
   },
   {
      "id":"1011543",
      "phuongxa":"Xã Xuân Thu",
      "quanhuyen":"10115"
   },
   {
      "id":"1011545",
      "phuongxa":"Xã Kim Lũ",
      "quanhuyen":"10115"
   },
   {
      "id":"1011547",
      "phuongxa":"Xã Phù Linh",
      "quanhuyen":"10115"
   },
   {
      "id":"1011549",
      "phuongxa":"Xã Tân Minh",
      "quanhuyen":"10115"
   },
   {
      "id":"1011551",
      "phuongxa":"Xã Tiên Dược",
      "quanhuyen":"10115"
   },
   {
      "id":"1011700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10117"
   },
   {
      "id":"1014157",
      "phuongxa":"Xã Văn Võ",
      "quanhuyen":"10141"
   },
   {
      "id":"1014159",
      "phuongxa":"Xã Đồng Lạc",
      "quanhuyen":"10141"
   },
   {
      "id":"1014161",
      "phuongxa":"Xã Hòa Chính",
      "quanhuyen":"10141"
   },
   {
      "id":"1014163",
      "phuongxa":"Xã Phú Nam An",
      "quanhuyen":"10141"
   },
   {
      "id":"1014300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10143"
   },
   {
      "id":"1014301",
      "phuongxa":"Thị trấn Kim Bài",
      "quanhuyen":"10143"
   },
   {
      "id":"1014303",
      "phuongxa":"Xã Cự Khê",
      "quanhuyen":"10143"
   },
   {
      "id":"1014305",
      "phuongxa":"Xã Bích Hòa",
      "quanhuyen":"10143"
   },
   {
      "id":"1014307",
      "phuongxa":"Xã Mỹ Hưng",
      "quanhuyen":"10143"
   },
   {
      "id":"1014309",
      "phuongxa":"Xã Cao Viên",
      "quanhuyen":"10143"
   },
   {
      "id":"1014311",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"10143"
   },
   {
      "id":"1014313",
      "phuongxa":"Xã Tam Hưng",
      "quanhuyen":"10143"
   },
   {
      "id":"1014317",
      "phuongxa":"Xã Thanh Thùy",
      "quanhuyen":"10143"
   },
   {
      "id":"1014319",
      "phuongxa":"Xã Thanh Mai",
      "quanhuyen":"10143"
   },
   {
      "id":"1014321",
      "phuongxa":"Xã Thanh Văn",
      "quanhuyen":"10143"
   },
   {
      "id":"1014323",
      "phuongxa":"Xã Đỗ Động",
      "quanhuyen":"10143"
   },
   {
      "id":"1014325",
      "phuongxa":"Xã Kim An",
      "quanhuyen":"10143"
   },
   {
      "id":"1014327",
      "phuongxa":"Xã Kim Thư",
      "quanhuyen":"10143"
   },
   {
      "id":"1014329",
      "phuongxa":"Xã Phương Trung",
      "quanhuyen":"10143"
   },
   {
      "id":"1014331",
      "phuongxa":"Xã Tân Ước",
      "quanhuyen":"10143"
   },
   {
      "id":"1014333",
      "phuongxa":"Xã Dân Hòa",
      "quanhuyen":"10143"
   },
   {
      "id":"1014335",
      "phuongxa":"Xã Liên Châu",
      "quanhuyen":"10143"
   },
   {
      "id":"1014337",
      "phuongxa":"Xã Cao Dương",
      "quanhuyen":"10143"
   },
   {
      "id":"1014339",
      "phuongxa":"Xã Xuân Dương",
      "quanhuyen":"10143"
   },
   {
      "id":"1014341",
      "phuongxa":"Xã Hồng Dương",
      "quanhuyen":"10143"
   },
   {
      "id":"1014500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10145"
   },
   {
      "id":"1014501",
      "phuongxa":"Thị trấn Thường Tín",
      "quanhuyen":"10145"
   },
   {
      "id":"1014503",
      "phuongxa":"Xã Ninh Sở",
      "quanhuyen":"10145"
   },
   {
      "id":"1014505",
      "phuongxa":"Xã Nhị Khê",
      "quanhuyen":"10145"
   },
   {
      "id":"1014507",
      "phuongxa":"Xã Duyên Thái",
      "quanhuyen":"10145"
   },
   {
      "id":"1014509",
      "phuongxa":"Xã Khánh Hà",
      "quanhuyen":"10145"
   },
   {
      "id":"1014511",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"10145"
   },
   {
      "id":"1014515",
      "phuongxa":"Xã Hiền Giang",
      "quanhuyen":"10145"
   },
   {
      "id":"1014517",
      "phuongxa":"Xã Hồng Vân",
      "quanhuyen":"10145"
   },
   {
      "id":"1014519",
      "phuongxa":"Xã Vân Tảo",
      "quanhuyen":"10145"
   },
   {
      "id":"1014521",
      "phuongxa":"Xã Liên Phương",
      "quanhuyen":"10145"
   },
   {
      "id":"1014523",
      "phuongxa":"Xã Văn Phú",
      "quanhuyen":"10145"
   },
   {
      "id":"1014525",
      "phuongxa":"Xã Tự Nhiên",
      "quanhuyen":"10145"
   },
   {
      "id":"1014527",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"10145"
   },
   {
      "id":"1014529",
      "phuongxa":"Xã Hà Hồi",
      "quanhuyen":"10145"
   },
   {
      "id":"1014531",
      "phuongxa":"Xã Thư Phú",
      "quanhuyen":"10145"
   },
   {
      "id":"1014533",
      "phuongxa":"Xã Nguyễn Trãi",
      "quanhuyen":"10145"
   },
   {
      "id":"1014535",
      "phuongxa":"Xã Quất Động",
      "quanhuyen":"10145"
   },
   {
      "id":"1014537",
      "phuongxa":"Xã Chương Dương",
      "quanhuyen":"10145"
   },
   {
      "id":"1014539",
      "phuongxa":"Xã Tân Minh",
      "quanhuyen":"10145"
   },
   {
      "id":"1014543",
      "phuongxa":"Xã Thắng Lợi",
      "quanhuyen":"10145"
   },
   {
      "id":"1014545",
      "phuongxa":"Xã Dũng Tiến",
      "quanhuyen":"10145"
   },
   {
      "id":"1014547",
      "phuongxa":"Xã Thống Nhất",
      "quanhuyen":"10145"
   },
   {
      "id":"1014549",
      "phuongxa":"Xã Nghiêm Xuyên",
      "quanhuyen":"10145"
   },
   {
      "id":"1014553",
      "phuongxa":"Xã Văn Tự",
      "quanhuyen":"10145"
   },
   {
      "id":"1014555",
      "phuongxa":"Xã Vạn Điểm",
      "quanhuyen":"10145"
   },
   {
      "id":"1014700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10147"
   },
   {
      "id":"1014701",
      "phuongxa":"Thị trấn Phú Minh",
      "quanhuyen":"10147"
   },
   {
      "id":"1014705",
      "phuongxa":"Xã Hồng Minh",
      "quanhuyen":"10147"
   },
   {
      "id":"1014707",
      "phuongxa":"Xã Phượng Dực",
      "quanhuyen":"10147"
   },
   {
      "id":"1014709",
      "phuongxa":"Xã Văn Nhân",
      "quanhuyen":"10147"
   },
   {
      "id":"1014711",
      "phuongxa":"Xã Thụy Phú",
      "quanhuyen":"10147"
   },
   {
      "id":"1014713",
      "phuongxa":"Xã Tri Trung",
      "quanhuyen":"10147"
   },
   {
      "id":"1014715",
      "phuongxa":"Xã Đại Thắng",
      "quanhuyen":"10147"
   },
   {
      "id":"1014719",
      "phuongxa":"Xã Văn Hoàng",
      "quanhuyen":"10147"
   },
   {
      "id":"1014721",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"10147"
   },
   {
      "id":"1014723",
      "phuongxa":"Xã Hoàng Long",
      "quanhuyen":"10147"
   },
   {
      "id":"1014725",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"10147"
   },
   {
      "id":"1014727",
      "phuongxa":"Xã Nam Phong",
      "quanhuyen":"10147"
   },
   {
      "id":"1014731",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"10147"
   },
   {
      "id":"1014733",
      "phuongxa":"Xã Sơn Hà",
      "quanhuyen":"10147"
   },
   {
      "id":"1014735",
      "phuongxa":"Xã Chuyên Mỹ",
      "quanhuyen":"10147"
   },
   {
      "id":"1014737",
      "phuongxa":"Xã Khai Thái",
      "quanhuyen":"10147"
   },
   {
      "id":"1014739",
      "phuongxa":"Xã Phúc Tiến",
      "quanhuyen":"10147"
   },
   {
      "id":"1032155",
      "phuongxa":"Xã Cổ Am",
      "quanhuyen":"10321"
   },
   {
      "id":"1032157",
      "phuongxa":"Xã Vĩnh Tiến",
      "quanhuyen":"10321"
   },
   {
      "id":"1032159",
      "phuongxa":"Xã Trấn Dương",
      "quanhuyen":"10321"
   },
   {
      "id":"1032300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10323"
   },
   {
      "id":"1032301",
      "phuongxa":"Thị trấn Cát Bà",
      "quanhuyen":"10323"
   },
   {
      "id":"1032303",
      "phuongxa":"Thị trấn Cát Hải",
      "quanhuyen":"10323"
   },
   {
      "id":"1032305",
      "phuongxa":"Xã Nghĩa Lộ",
      "quanhuyen":"10323"
   },
   {
      "id":"1032307",
      "phuongxa":"Xã Đồng Bài",
      "quanhuyen":"10323"
   },
   {
      "id":"1032311",
      "phuongxa":"Xã Văn Phong",
      "quanhuyen":"10323"
   },
   {
      "id":"1032313",
      "phuongxa":"Xã Phù Long",
      "quanhuyen":"10323"
   },
   {
      "id":"1032315",
      "phuongxa":"Xã Gia Luận",
      "quanhuyen":"10323"
   },
   {
      "id":"1032317",
      "phuongxa":"Xã Hiền Hào",
      "quanhuyen":"10323"
   },
   {
      "id":"1032319",
      "phuongxa":"Xã Trân Châu",
      "quanhuyen":"10323"
   },
   {
      "id":"1032321",
      "phuongxa":"Xã Việt Hải",
      "quanhuyen":"10323"
   },
   {
      "id":"1032323",
      "phuongxa":"Xã Xuân Đám",
      "quanhuyen":"10323"
   },
   {
      "id":"1032500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10325"
   },
   {
      "id":"1032700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10327"
   },
   {
      "id":"1032701",
      "phuongxa":"Phường Đông Hải 1",
      "quanhuyen":"10327"
   },
   {
      "id":"1032703",
      "phuongxa":"Phường Đằng Lâm",
      "quanhuyen":"10327"
   },
   {
      "id":"1032705",
      "phuongxa":"Phường Đằng Hải",
      "quanhuyen":"10327"
   },
   {
      "id":"1032707",
      "phuongxa":"Phường Nam Hải",
      "quanhuyen":"10327"
   },
   {
      "id":"1032709",
      "phuongxa":"Phường Cát Bi",
      "quanhuyen":"10327"
   },
   {
      "id":"1032711",
      "phuongxa":"Phường Tràng Cát",
      "quanhuyen":"10327"
   },
   {
      "id":"1032713",
      "phuongxa":"Phường Đông Hải 2",
      "quanhuyen":"10327"
   },
   {
      "id":"1032715",
      "phuongxa":"Phường Thành Tô",
      "quanhuyen":"10327"
   },
   {
      "id":"1032900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10329"
   },
   {
      "id":"1032901",
      "phuongxa":"Thị trấn An Dương",
      "quanhuyen":"10329"
   },
   {
      "id":"1032903",
      "phuongxa":"Xã Lê Thiện",
      "quanhuyen":"10329"
   },
   {
      "id":"1032905",
      "phuongxa":"Xã Đại Bản",
      "quanhuyen":"10329"
   },
   {
      "id":"1032909",
      "phuongxa":"Xã Hồng Phong",
      "quanhuyen":"10329"
   },
   {
      "id":"1032911",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"10329"
   },
   {
      "id":"1032913",
      "phuongxa":"Xã An Hưng",
      "quanhuyen":"10329"
   },
   {
      "id":"1032915",
      "phuongxa":"Xã An Hồng",
      "quanhuyen":"10329"
   },
   {
      "id":"1032917",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"10329"
   },
   {
      "id":"1032919",
      "phuongxa":"Xã Nam Sơn",
      "quanhuyen":"10329"
   },
   {
      "id":"1032921",
      "phuongxa":"Xã Lê Lợi",
      "quanhuyen":"10329"
   },
   {
      "id":"1032923",
      "phuongxa":"Xã Đặng Cương",
      "quanhuyen":"10329"
   },
   {
      "id":"1032925",
      "phuongxa":"Xã Đồng Thái",
      "quanhuyen":"10329"
   },
   {
      "id":"1032927",
      "phuongxa":"Xã Quốc Tuấn",
      "quanhuyen":"10329"
   },
   {
      "id":"1032929",
      "phuongxa":"Xã An Đồng",
      "quanhuyen":"10329"
   },
   {
      "id":"1032931",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"10329"
   },
   {
      "id":"1033100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10331"
   },
   {
      "id":"1033103",
      "phuongxa":"Phường Hưng Đạo",
      "quanhuyen":"10331"
   },
   {
      "id":"1033105",
      "phuongxa":"Phường Hoà Nghĩa",
      "quanhuyen":"10331"
   },
   {
      "id":"1033107",
      "phuongxa":"Phường Anh Dũng",
      "quanhuyen":"10331"
   },
   {
      "id":"1033109",
      "phuongxa":"Phường Tân Thành",
      "quanhuyen":"10331"
   },
   {
      "id":"1040100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10401"
   },
   {
      "id":"1040101",
      "phuongxa":"Phường Tích Sơn",
      "quanhuyen":"10401"
   },
   {
      "id":"1040103",
      "phuongxa":"Phường Liên Bảo",
      "quanhuyen":"10401"
   },
   {
      "id":"1040105",
      "phuongxa":"Phường Đống Đa",
      "quanhuyen":"10401"
   },
   {
      "id":"1040109",
      "phuongxa":"Thị trấn Tam Đảo",
      "quanhuyen":"10401"
   },
   {
      "id":"1040111",
      "phuongxa":"Xã Định Trung",
      "quanhuyen":"10401"
   },
   {
      "id":"1040113",
      "phuongxa":"Xã Khai Quang",
      "quanhuyen":"10401"
   },
   {
      "id":"1040115",
      "phuongxa":"Phường Hội Hợp",
      "quanhuyen":"10401"
   },
   {
      "id":"1040117",
      "phuongxa":"Phường Đồng Tâm",
      "quanhuyen":"10401"
   },
   {
      "id":"1040119",
      "phuongxa":"Xã Thanh Trù",
      "quanhuyen":"10401"
   },
   {
      "id":"1040301",
      "phuongxa":"Thị trấn Lập Thạch",
      "quanhuyen":"10403"
   },
   {
      "id":"1040303",
      "phuongxa":"Xã Đạo Trù",
      "quanhuyen":"10403"
   },
   {
      "id":"1040305",
      "phuongxa":"Xã Yên Dương",
      "quanhuyen":"10403"
   },
   {
      "id":"1040307",
      "phuongxa":"Xã Quang Sơn",
      "quanhuyen":"10403"
   },
   {
      "id":"1040309",
      "phuongxa":"Xã Ngọc Mỹ",
      "quanhuyen":"10403"
   },
   {
      "id":"1040313",
      "phuongxa":"Xã Lãng Công",
      "quanhuyen":"10403"
   },
   {
      "id":"1040317",
      "phuongxa":"Xã Bạch Lưu",
      "quanhuyen":"10403"
   },
   {
      "id":"1040319",
      "phuongxa":"Xã Hải Lựu",
      "quanhuyen":"10403"
   },
   {
      "id":"1040321",
      "phuongxa":"Xã Bồ Lý",
      "quanhuyen":"10403"
   },
   {
      "id":"1040323",
      "phuongxa":"Xã Bắc Bình",
      "quanhuyen":"10403"
   },
   {
      "id":"1040325",
      "phuongxa":"Xã Thái Hòa",
      "quanhuyen":"10403"
   },
   {
      "id":"1040327",
      "phuongxa":"Xã Liễn Sơn",
      "quanhuyen":"10403"
   },
   {
      "id":"1040329",
      "phuongxa":"Xã Xuân Hòa",
      "quanhuyen":"10403"
   },
   {
      "id":"1040331",
      "phuongxa":"Xã Vân Trục",
      "quanhuyen":"10403"
   },
   {
      "id":"1040333",
      "phuongxa":"Xã Đồng Quế",
      "quanhuyen":"10403"
   },
   {
      "id":"1040335",
      "phuongxa":"Xã Nhân Đạo",
      "quanhuyen":"10403"
   },
   {
      "id":"1040337",
      "phuongxa":"Xã Đôn Nhân",
      "quanhuyen":"10403"
   },
   {
      "id":"1040339",
      "phuongxa":"Xã Phương Khoan",
      "quanhuyen":"10403"
   },
   {
      "id":"1040341",
      "phuongxa":"Xã Liên Hòa",
      "quanhuyen":"10403"
   },
   {
      "id":"1040343",
      "phuongxa":"Xã Tử Du",
      "quanhuyen":"10403"
   },
   {
      "id":"1040345",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"10403"
   },
   {
      "id":"1040347",
      "phuongxa":"Xã Nhạo Sơn",
      "quanhuyen":"10403"
   },
   {
      "id":"1040349",
      "phuongxa":"Xã Tam Sơn",
      "quanhuyen":"10403"
   },
   {
      "id":"1040351",
      "phuongxa":"Xã Như Thụy",
      "quanhuyen":"10403"
   },
   {
      "id":"1040353",
      "phuongxa":"Xã Yên Thạch",
      "quanhuyen":"10403"
   },
   {
      "id":"1040355",
      "phuongxa":"Xã Bàn Giản",
      "quanhuyen":"10403"
   },
   {
      "id":"1040359",
      "phuongxa":"Xã Đồng ích",
      "quanhuyen":"10403"
   },
   {
      "id":"1040361",
      "phuongxa":"Xã Tiên Lữ",
      "quanhuyen":"10403"
   },
   {
      "id":"1040363",
      "phuongxa":"Xã Văn Quán",
      "quanhuyen":"10403"
   },
   {
      "id":"1040365",
      "phuongxa":"Xã Đồng Thịnh",
      "quanhuyen":"10403"
   },
   {
      "id":"1040367",
      "phuongxa":"Xã Tứ Yên",
      "quanhuyen":"10403"
   },
   {
      "id":"1040369",
      "phuongxa":"Xã Đức Bác",
      "quanhuyen":"10403"
   },
   {
      "id":"1040371",
      "phuongxa":"Xã Đình Chu",
      "quanhuyen":"10403"
   },
   {
      "id":"1040373",
      "phuongxa":"Xã Cao Phong",
      "quanhuyen":"10403"
   },
   {
      "id":"1040375",
      "phuongxa":"Xã Triệu Đề",
      "quanhuyen":"10403"
   },
   {
      "id":"1040377",
      "phuongxa":"Xã Sơn Đông",
      "quanhuyen":"10403"
   },
   {
      "id":"1040500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10405"
   },
   {
      "id":"1040505",
      "phuongxa":"Xã Đại Đình",
      "quanhuyen":"10405"
   },
   {
      "id":"1040507",
      "phuongxa":"Xã Tam Quan",
      "quanhuyen":"10405"
   },
   {
      "id":"1040513",
      "phuongxa":"Xã Hồ Sơn",
      "quanhuyen":"10405"
   },
   {
      "id":"1040515",
      "phuongxa":"Xã Hợp Châu",
      "quanhuyen":"10405"
   },
   {
      "id":"1040517",
      "phuongxa":"Xã Hoàng Hoa",
      "quanhuyen":"10405"
   },
   {
      "id":"1040519",
      "phuongxa":"Xã Đồng Tĩnh",
      "quanhuyen":"10405"
   },
   {
      "id":"1040521",
      "phuongxa":"Xã Hợp Hòa",
      "quanhuyen":"10405"
   },
   {
      "id":"1040523",
      "phuongxa":"Xã Kim Long",
      "quanhuyen":"10405"
   },
   {
      "id":"1040527",
      "phuongxa":"Xã Đạo Tú",
      "quanhuyen":"10405"
   },
   {
      "id":"1040529",
      "phuongxa":"Xã An Hòa",
      "quanhuyen":"10405"
   },
   {
      "id":"1040539",
      "phuongxa":"Xã Thanh Vân",
      "quanhuyen":"10405"
   },
   {
      "id":"1040541",
      "phuongxa":"Xã Duy Phiên",
      "quanhuyen":"10405"
   },
   {
      "id":"1040543",
      "phuongxa":"Xã Hoàng Đan",
      "quanhuyen":"10405"
   },
   {
      "id":"1040545",
      "phuongxa":"Xã Hoàng Lâu",
      "quanhuyen":"10405"
   },
   {
      "id":"1040547",
      "phuongxa":"Xã Vân Hội",
      "quanhuyen":"10405"
   },
   {
      "id":"1040549",
      "phuongxa":"Xã Hợp Thịnh",
      "quanhuyen":"10405"
   },
   {
      "id":"1040700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10407"
   },
   {
      "id":"1040701",
      "phuongxa":"Thị trấn Vĩnh Tường",
      "quanhuyen":"10407"
   },
   {
      "id":"1040703",
      "phuongxa":"Xã Kim Xá",
      "quanhuyen":"10407"
   },
   {
      "id":"1040707",
      "phuongxa":"Xã Chấn Hưng",
      "quanhuyen":"10407"
   },
   {
      "id":"1040709",
      "phuongxa":"Xã Nghĩa Hưng",
      "quanhuyen":"10407"
   },
   {
      "id":"1040711",
      "phuongxa":"Xã Yên Lập",
      "quanhuyen":"10407"
   },
   {
      "id":"1040713",
      "phuongxa":"Xã Việt Xuân",
      "quanhuyen":"10407"
   },
   {
      "id":"1040715",
      "phuongxa":"Xã Bồ Sao",
      "quanhuyen":"10407"
   },
   {
      "id":"1040717",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"10407"
   },
   {
      "id":"1040721",
      "phuongxa":"Xã Lũng Hoà",
      "quanhuyen":"10407"
   },
   {
      "id":"1040723",
      "phuongxa":"Xã Cao Đại",
      "quanhuyen":"10407"
   },
   {
      "id":"1040727",
      "phuongxa":"Xã Vĩnh Sơn",
      "quanhuyen":"10407"
   },
   {
      "id":"1040729",
      "phuongxa":"Xã Bình Dương",
      "quanhuyen":"10407"
   },
   {
      "id":"1040733",
      "phuongxa":"Xã Phú Thịnh",
      "quanhuyen":"10407"
   },
   {
      "id":"1040735",
      "phuongxa":"Xã Thượng Trưng",
      "quanhuyen":"10407"
   },
   {
      "id":"1040737",
      "phuongxa":"Xã Vũ Di",
      "quanhuyen":"10407"
   },
   {
      "id":"1040739",
      "phuongxa":"Xã Lý Nhân",
      "quanhuyen":"10407"
   },
   {
      "id":"1040741",
      "phuongxa":"Xã Tuân Chính",
      "quanhuyen":"10407"
   },
   {
      "id":"1040745",
      "phuongxa":"Xã Tam Phúc",
      "quanhuyen":"10407"
   },
   {
      "id":"1040747",
      "phuongxa":"Xã Tứ Trưng",
      "quanhuyen":"10407"
   },
   {
      "id":"1040749",
      "phuongxa":"Xã Ngũ Kiên",
      "quanhuyen":"10407"
   },
   {
      "id":"1040751",
      "phuongxa":"Xã An Tường",
      "quanhuyen":"10407"
   },
   {
      "id":"1040753",
      "phuongxa":"Xã Vĩnh Thịnh",
      "quanhuyen":"10407"
   },
   {
      "id":"1040755",
      "phuongxa":"Xã Phú Đa",
      "quanhuyen":"10407"
   },
   {
      "id":"1040757",
      "phuongxa":"Xã Vĩnh Ninh",
      "quanhuyen":"10407"
   },
   {
      "id":"1040900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10409"
   },
   {
      "id":"1040901",
      "phuongxa":"Thị trấn Yên Lạc",
      "quanhuyen":"10409"
   },
   {
      "id":"1040903",
      "phuongxa":"Xã Đồng Cương",
      "quanhuyen":"10409"
   },
   {
      "id":"1014741",
      "phuongxa":"Xã Vân Từ",
      "quanhuyen":"10147"
   },
   {
      "id":"1014743",
      "phuongxa":"Xã Tri Thủy",
      "quanhuyen":"10147"
   },
   {
      "id":"1014745",
      "phuongxa":"Xã Đại Xuyên",
      "quanhuyen":"10147"
   },
   {
      "id":"1014747",
      "phuongxa":"Xã Phú Yên",
      "quanhuyen":"10147"
   },
   {
      "id":"1014749",
      "phuongxa":"Xã Bạch Hạ",
      "quanhuyen":"10147"
   },
   {
      "id":"1014751",
      "phuongxa":"Xã Quang Lãng",
      "quanhuyen":"10147"
   },
   {
      "id":"1014753",
      "phuongxa":"Xã Châu Can",
      "quanhuyen":"10147"
   },
   {
      "id":"1014755",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"10147"
   },
   {
      "id":"1014900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10149"
   },
   {
      "id":"1014903",
      "phuongxa":"Xã Viên An",
      "quanhuyen":"10149"
   },
   {
      "id":"1014905",
      "phuongxa":"Xã Viên Nội",
      "quanhuyen":"10149"
   },
   {
      "id":"1014907",
      "phuongxa":"Xã Hoa Sơn",
      "quanhuyen":"10149"
   },
   {
      "id":"1014909",
      "phuongxa":"Xã Quảng Phú Cầu",
      "quanhuyen":"10149"
   },
   {
      "id":"1014911",
      "phuongxa":"Xã Trường Thịnh",
      "quanhuyen":"10149"
   },
   {
      "id":"1014913",
      "phuongxa":"Xã Cao Thành",
      "quanhuyen":"10149"
   },
   {
      "id":"1014915",
      "phuongxa":"Xã Liên Bạt",
      "quanhuyen":"10149"
   },
   {
      "id":"1014917",
      "phuongxa":"Xã Sơn Công",
      "quanhuyen":"10149"
   },
   {
      "id":"1014919",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"10149"
   },
   {
      "id":"1014921",
      "phuongxa":"Xã Phương Tú",
      "quanhuyen":"10149"
   },
   {
      "id":"1014923",
      "phuongxa":"Xã Trung Tú",
      "quanhuyen":"10149"
   },
   {
      "id":"1014925",
      "phuongxa":"Xã Đồng Tân",
      "quanhuyen":"10149"
   },
   {
      "id":"1014927",
      "phuongxa":"Xã Tảo Dương Văn",
      "quanhuyen":"10149"
   },
   {
      "id":"1014929",
      "phuongxa":"Xã Vạn Thái",
      "quanhuyen":"10149"
   },
   {
      "id":"1014931",
      "phuongxa":"Xã Minh Đức",
      "quanhuyen":"10149"
   },
   {
      "id":"1014933",
      "phuongxa":"Xã Hòa Lâm",
      "quanhuyen":"10149"
   },
   {
      "id":"1014935",
      "phuongxa":"Xã Hòa Xá",
      "quanhuyen":"10149"
   },
   {
      "id":"1014937",
      "phuongxa":"Xã Trầm Lộng",
      "quanhuyen":"10149"
   },
   {
      "id":"1014939",
      "phuongxa":"Xã Kim Đường",
      "quanhuyen":"10149"
   },
   {
      "id":"1014941",
      "phuongxa":"Xã Hòa Nam",
      "quanhuyen":"10149"
   },
   {
      "id":"1014943",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"10149"
   },
   {
      "id":"1014945",
      "phuongxa":"Xã Đội Bình",
      "quanhuyen":"10149"
   },
   {
      "id":"1014947",
      "phuongxa":"Xã Đại Hùng",
      "quanhuyen":"10149"
   },
   {
      "id":"1014949",
      "phuongxa":"Xã Đông Lỗ",
      "quanhuyen":"10149"
   },
   {
      "id":"1014953",
      "phuongxa":"Xã Đại Cường",
      "quanhuyen":"10149"
   },
   {
      "id":"1014955",
      "phuongxa":"Xã Lưu Hoàng",
      "quanhuyen":"10149"
   },
   {
      "id":"1014957",
      "phuongxa":"Xã Hồng Quang",
      "quanhuyen":"10149"
   },
   {
      "id":"1015100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10151"
   },
   {
      "id":"1015101",
      "phuongxa":"Thị trấn Đại Nghĩa",
      "quanhuyen":"10151"
   },
   {
      "id":"1015103",
      "phuongxa":"Xã Đồng Tâm",
      "quanhuyen":"10151"
   },
   {
      "id":"1015105",
      "phuongxa":"Xã Thượng Lâm",
      "quanhuyen":"10151"
   },
   {
      "id":"1015107",
      "phuongxa":"Xã Tuy Lai",
      "quanhuyen":"10151"
   },
   {
      "id":"1015109",
      "phuongxa":"Xã Phúc Lâm",
      "quanhuyen":"10151"
   },
   {
      "id":"1015111",
      "phuongxa":"Xã Mỹ Thành",
      "quanhuyen":"10151"
   },
   {
      "id":"1015113",
      "phuongxa":"Xã Bột Xuyên",
      "quanhuyen":"10151"
   },
   {
      "id":"1015115",
      "phuongxa":"Xã An Mỹ",
      "quanhuyen":"10151"
   },
   {
      "id":"1015117",
      "phuongxa":"Xã Hồng Sơn",
      "quanhuyen":"10151"
   },
   {
      "id":"1015119",
      "phuongxa":"Xã Lê Thanh",
      "quanhuyen":"10151"
   },
   {
      "id":"1015121",
      "phuongxa":"Xã Xuy Xá",
      "quanhuyen":"10151"
   },
   {
      "id":"1015123",
      "phuongxa":"Xã Phùng Xá",
      "quanhuyen":"10151"
   },
   {
      "id":"1015125",
      "phuongxa":"Xã Phù Lưu Tế",
      "quanhuyen":"10151"
   },
   {
      "id":"1015129",
      "phuongxa":"Xã Vạn Kim",
      "quanhuyen":"10151"
   },
   {
      "id":"1015133",
      "phuongxa":"Xã Hương Sơn",
      "quanhuyen":"10151"
   },
   {
      "id":"1015135",
      "phuongxa":"Xã Hùng Tiến",
      "quanhuyen":"10151"
   },
   {
      "id":"1015137",
      "phuongxa":"Xã An Tiến",
      "quanhuyen":"10151"
   },
   {
      "id":"1015141",
      "phuongxa":"Xã Hợp Thanh",
      "quanhuyen":"10151"
   },
   {
      "id":"1015143",
      "phuongxa":"Xã An Phú",
      "quanhuyen":"10151"
   },
   {
      "id":"1015300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10153"
   },
   {
      "id":"1015301",
      "phuongxa":"Xã Đại Thịnh",
      "quanhuyen":"10153"
   },
   {
      "id":"1015303",
      "phuongxa":"Xã Kim Hoa",
      "quanhuyen":"10153"
   },
   {
      "id":"1015305",
      "phuongxa":"Xã Thạch Đà",
      "quanhuyen":"10153"
   },
   {
      "id":"1015309",
      "phuongxa":"Xã Tự Lập",
      "quanhuyen":"10153"
   },
   {
      "id":"1015311",
      "phuongxa":"Thị trấn Quang Minh",
      "quanhuyen":"10153"
   },
   {
      "id":"1015313",
      "phuongxa":"Xã Thanh Lâm",
      "quanhuyen":"10153"
   },
   {
      "id":"1015315",
      "phuongxa":"Xã Tam Đồng",
      "quanhuyen":"10153"
   },
   {
      "id":"1015317",
      "phuongxa":"Xã Liên Mạc",
      "quanhuyen":"10153"
   },
   {
      "id":"1015321",
      "phuongxa":"Xã Chu Phan",
      "quanhuyen":"10153"
   },
   {
      "id":"1015323",
      "phuongxa":"Xã Tiến Thịnh",
      "quanhuyen":"10153"
   },
   {
      "id":"1015325",
      "phuongxa":"Xã Mê Linh",
      "quanhuyen":"10153"
   },
   {
      "id":"1015327",
      "phuongxa":"Xã Văn Khê",
      "quanhuyen":"10153"
   },
   {
      "id":"1015329",
      "phuongxa":"Xã Hoàng Kim",
      "quanhuyen":"10153"
   },
   {
      "id":"1015331",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"10153"
   },
   {
      "id":"1015335",
      "phuongxa":"Thị trấn Chi Đông",
      "quanhuyen":"10153"
   },
   {
      "id":"1015500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10155"
   },
   {
      "id":"1015501",
      "phuongxa":"Phường Thượng Thanh",
      "quanhuyen":"10155"
   },
   {
      "id":"1015503",
      "phuongxa":"Phường Ngọc Thuỵ",
      "quanhuyen":"10155"
   },
   {
      "id":"1015505",
      "phuongxa":"Phường Giang Biên",
      "quanhuyen":"10155"
   },
   {
      "id":"1015507",
      "phuongxa":"Phường Đức Giang",
      "quanhuyen":"10155"
   },
   {
      "id":"1015509",
      "phuongxa":"Phường Việt Hưng",
      "quanhuyen":"10155"
   },
   {
      "id":"1015511",
      "phuongxa":"Phường Gia Thuỵ",
      "quanhuyen":"10155"
   },
   {
      "id":"1015513",
      "phuongxa":"Phường Ngọc Lâm",
      "quanhuyen":"10155"
   },
   {
      "id":"1015515",
      "phuongxa":"Phường Phúc Lợi",
      "quanhuyen":"10155"
   },
   {
      "id":"1015517",
      "phuongxa":"Phường Bồ Đề",
      "quanhuyen":"10155"
   },
   {
      "id":"1015519",
      "phuongxa":"Phường Sài Đồng",
      "quanhuyen":"10155"
   },
   {
      "id":"1015521",
      "phuongxa":"Phường Long Biên",
      "quanhuyen":"10155"
   },
   {
      "id":"1015523",
      "phuongxa":"Phường Thạch Bàn",
      "quanhuyen":"10155"
   },
   {
      "id":"1015525",
      "phuongxa":"Phường Phúc Đồng",
      "quanhuyen":"10155"
   },
   {
      "id":"1015527",
      "phuongxa":"Phường Cự Khối",
      "quanhuyen":"10155"
   },
   {
      "id":"1015700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10157"
   },
   {
      "id":"1015701",
      "phuongxa":"Phường Thanh Trì",
      "quanhuyen":"10157"
   },
   {
      "id":"1015703",
      "phuongxa":"Phường Vĩnh Hưng",
      "quanhuyen":"10157"
   },
   {
      "id":"1015705",
      "phuongxa":"Phường Định Công",
      "quanhuyen":"10157"
   },
   {
      "id":"1015707",
      "phuongxa":"Phường Mai Động",
      "quanhuyen":"10157"
   },
   {
      "id":"1015711",
      "phuongxa":"Phường Đại Kim",
      "quanhuyen":"10157"
   },
   {
      "id":"1015713",
      "phuongxa":"Phường Tân Mai",
      "quanhuyen":"10157"
   },
   {
      "id":"1015715",
      "phuongxa":"Phường Hoàng Văn Thụ",
      "quanhuyen":"10157"
   },
   {
      "id":"1015717",
      "phuongxa":"Phường Giáp Bát",
      "quanhuyen":"10157"
   },
   {
      "id":"1015719",
      "phuongxa":"Phường Lĩnh Nam",
      "quanhuyen":"10157"
   },
   {
      "id":"1015721",
      "phuongxa":"Phường Thịnh Liệt",
      "quanhuyen":"10157"
   },
   {
      "id":"1015723",
      "phuongxa":"Phường Trần Phú",
      "quanhuyen":"10157"
   },
   {
      "id":"1015725",
      "phuongxa":"Phường Hoàng Liệt",
      "quanhuyen":"10157"
   },
   {
      "id":"1015727",
      "phuongxa":"Phường Yên Sở",
      "quanhuyen":"10157"
   },
   {
      "id":"1030000",
      "phuongxa":"Không xác định",
      "quanhuyen":"10300"
   },
   {
      "id":"1030100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10301"
   },
   {
      "id":"1030101",
      "phuongxa":"Phường Quán Toan",
      "quanhuyen":"10301"
   },
   {
      "id":"1030103",
      "phuongxa":"Phường Hùng Vương",
      "quanhuyen":"10301"
   },
   {
      "id":"1030105",
      "phuongxa":"Phường Sở Dầu",
      "quanhuyen":"10301"
   },
   {
      "id":"1030107",
      "phuongxa":"Phường Thượng Lý",
      "quanhuyen":"10301"
   },
   {
      "id":"1030109",
      "phuongxa":"Phường Trại Chuối",
      "quanhuyen":"10301"
   },
   {
      "id":"1030111",
      "phuongxa":"Phường Hạ Lý",
      "quanhuyen":"10301"
   },
   {
      "id":"1030113",
      "phuongxa":"Phường Minh Khai",
      "quanhuyen":"10301"
   },
   {
      "id":"1030117",
      "phuongxa":"Phường Hoàng Văn Thụ",
      "quanhuyen":"10301"
   },
   {
      "id":"1030119",
      "phuongxa":"Phường Phan Bội Châu",
      "quanhuyen":"10301"
   },
   {
      "id":"1030121",
      "phuongxa":"Phường Phạm Hồng Thái",
      "quanhuyen":"10301"
   },
   {
      "id":"1030300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10303"
   },
   {
      "id":"1030301",
      "phuongxa":"Phường Máy Tơ",
      "quanhuyen":"10303"
   },
   {
      "id":"1030307",
      "phuongxa":"Phường Lạc Viên",
      "quanhuyen":"10303"
   },
   {
      "id":"1030309",
      "phuongxa":"Phường Cầu Tre",
      "quanhuyen":"10303"
   },
   {
      "id":"1030311",
      "phuongxa":"Phường Lương Khánh Thiện",
      "quanhuyen":"10303"
   },
   {
      "id":"1030315",
      "phuongxa":"Phường Cầu Đất",
      "quanhuyen":"10303"
   },
   {
      "id":"1030317",
      "phuongxa":"Phường Lê Lợi",
      "quanhuyen":"10303"
   },
   {
      "id":"1030319",
      "phuongxa":"Phường Lạch Tray",
      "quanhuyen":"10303"
   },
   {
      "id":"1030321",
      "phuongxa":"Phường Đằng Giang",
      "quanhuyen":"10303"
   },
   {
      "id":"1030323",
      "phuongxa":"Phường Đông Khê",
      "quanhuyen":"10303"
   },
   {
      "id":"1030327",
      "phuongxa":"Phường Cát Bi",
      "quanhuyen":"10303"
   },
   {
      "id":"1030500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10305"
   },
   {
      "id":"1030501",
      "phuongxa":"Phường Cát Dài",
      "quanhuyen":"10305"
   },
   {
      "id":"1030503",
      "phuongxa":"Phường An Biên",
      "quanhuyen":"10305"
   },
   {
      "id":"1030505",
      "phuongxa":"Phường Mê Linh",
      "quanhuyen":"10305"
   },
   {
      "id":"1030507",
      "phuongxa":"Phường Lam Sơn",
      "quanhuyen":"10305"
   },
   {
      "id":"1030509",
      "phuongxa":"Phường An Dương",
      "quanhuyen":"10305"
   },
   {
      "id":"1030511",
      "phuongxa":"Phường Trần Nguyên Hãn",
      "quanhuyen":"10305"
   },
   {
      "id":"1030513",
      "phuongxa":"Phường Hồ Nam",
      "quanhuyen":"10305"
   },
   {
      "id":"1030515",
      "phuongxa":"Phường Trại Cau",
      "quanhuyen":"10305"
   },
   {
      "id":"1030517",
      "phuongxa":"Phường Dư Hàng",
      "quanhuyen":"10305"
   },
   {
      "id":"1030519",
      "phuongxa":"Phường Hàng Kênh",
      "quanhuyen":"10305"
   },
   {
      "id":"1030521",
      "phuongxa":"Phường Đông Hải",
      "quanhuyen":"10305"
   },
   {
      "id":"1030700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10307"
   },
   {
      "id":"1030701",
      "phuongxa":"Phường Quán Trữ",
      "quanhuyen":"10307"
   },
   {
      "id":"1030703",
      "phuongxa":"Phường Đồng Hoà",
      "quanhuyen":"10307"
   },
   {
      "id":"1030705",
      "phuongxa":"Phường Bắc Sơn",
      "quanhuyen":"10307"
   },
   {
      "id":"1030707",
      "phuongxa":"Phường Nam Sơn",
      "quanhuyen":"10307"
   },
   {
      "id":"1030709",
      "phuongxa":"Phường Ngọc Sơn",
      "quanhuyen":"10307"
   },
   {
      "id":"1030711",
      "phuongxa":"Phường Trần Thành Ngọ",
      "quanhuyen":"10307"
   },
   {
      "id":"1030713",
      "phuongxa":"Phường Văn Đẩu",
      "quanhuyen":"10307"
   },
   {
      "id":"1030715",
      "phuongxa":"Phường Phù Liễn",
      "quanhuyen":"10307"
   },
   {
      "id":"1030717",
      "phuongxa":"Phường Tràng Minh",
      "quanhuyen":"10307"
   },
   {
      "id":"1030900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10309"
   },
   {
      "id":"1030901",
      "phuongxa":"Phường Ngọc Xuyên",
      "quanhuyen":"10309"
   },
   {
      "id":"1030903",
      "phuongxa":"Phường Ngọc Hải",
      "quanhuyen":"10309"
   },
   {
      "id":"1030905",
      "phuongxa":"Phường Vạn Hương",
      "quanhuyen":"10309"
   },
   {
      "id":"1030907",
      "phuongxa":"Phường Vạn Sơn",
      "quanhuyen":"10309"
   },
   {
      "id":"1030909",
      "phuongxa":"Xã Bàng La",
      "quanhuyen":"10309"
   },
   {
      "id":"1031100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10311"
   },
   {
      "id":"1031101",
      "phuongxa":"Thị trấn Núi Đèo",
      "quanhuyen":"10311"
   },
   {
      "id":"1031103",
      "phuongxa":"Thị trấn Minh Đức",
      "quanhuyen":"10311"
   },
   {
      "id":"1031105",
      "phuongxa":"Xã Lại Xuân",
      "quanhuyen":"10311"
   },
   {
      "id":"1031107",
      "phuongxa":"Xã An Sơn",
      "quanhuyen":"10311"
   },
   {
      "id":"1031109",
      "phuongxa":"Xã Kỳ Sơn",
      "quanhuyen":"10311"
   },
   {
      "id":"1031111",
      "phuongxa":"Xã Liên Khê",
      "quanhuyen":"10311"
   },
   {
      "id":"1031115",
      "phuongxa":"Xã Gia Minh",
      "quanhuyen":"10311"
   },
   {
      "id":"1031117",
      "phuongxa":"Xã Gia Đức",
      "quanhuyen":"10311"
   },
   {
      "id":"1031119",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"10311"
   },
   {
      "id":"1031121",
      "phuongxa":"Xã Phù Ninh",
      "quanhuyen":"10311"
   },
   {
      "id":"1031123",
      "phuongxa":"Xã Quảng Thanh",
      "quanhuyen":"10311"
   },
   {
      "id":"1031125",
      "phuongxa":"Xã Chính Mỹ",
      "quanhuyen":"10311"
   },
   {
      "id":"1031127",
      "phuongxa":"Xã Kênh Giang",
      "quanhuyen":"10311"
   },
   {
      "id":"1031129",
      "phuongxa":"Xã Hợp Thành",
      "quanhuyen":"10311"
   },
   {
      "id":"1031131",
      "phuongxa":"Xã Cao Nhân",
      "quanhuyen":"10311"
   },
   {
      "id":"1031133",
      "phuongxa":"Xã Mỹ Đồng",
      "quanhuyen":"10311"
   },
   {
      "id":"1031135",
      "phuongxa":"Xã Đông Sơn",
      "quanhuyen":"10311"
   },
   {
      "id":"1031137",
      "phuongxa":"Xã Hoà Bình",
      "quanhuyen":"10311"
   },
   {
      "id":"1031139",
      "phuongxa":"Xã Trung Hà",
      "quanhuyen":"10311"
   },
   {
      "id":"1031141",
      "phuongxa":"Xã An Lư",
      "quanhuyen":"10311"
   },
   {
      "id":"1031143",
      "phuongxa":"Xã Thuỷ Triều",
      "quanhuyen":"10311"
   },
   {
      "id":"1031145",
      "phuongxa":"Xã Ngũ Lão",
      "quanhuyen":"10311"
   },
   {
      "id":"1031147",
      "phuongxa":"Xã Phục Lễ",
      "quanhuyen":"10311"
   },
   {
      "id":"1031149",
      "phuongxa":"Xã Tam Hưng",
      "quanhuyen":"10311"
   },
   {
      "id":"1031153",
      "phuongxa":"Xã Lập Lễ",
      "quanhuyen":"10311"
   },
   {
      "id":"1031157",
      "phuongxa":"Xã Thiên Hương",
      "quanhuyen":"10311"
   },
   {
      "id":"1031159",
      "phuongxa":"Xã Thuỷ Sơn",
      "quanhuyen":"10311"
   },
   {
      "id":"1031161",
      "phuongxa":"Xã Thuỷ Đường",
      "quanhuyen":"10311"
   },
   {
      "id":"1031165",
      "phuongxa":"Xã Lâm Động",
      "quanhuyen":"10311"
   },
   {
      "id":"1031167",
      "phuongxa":"Xã Hoa Động",
      "quanhuyen":"10311"
   },
   {
      "id":"1031169",
      "phuongxa":"Xã Tân Dương",
      "quanhuyen":"10311"
   },
   {
      "id":"1031171",
      "phuongxa":"Xã Dương Quan",
      "quanhuyen":"10311"
   },
   {
      "id":"1031500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10315"
   },
   {
      "id":"1031503",
      "phuongxa":"Xã Bát Trang",
      "quanhuyen":"10315"
   },
   {
      "id":"1031505",
      "phuongxa":"Xã Trường Thọ",
      "quanhuyen":"10315"
   },
   {
      "id":"1031507",
      "phuongxa":"Xã Trường Thành",
      "quanhuyen":"10315"
   },
   {
      "id":"1031509",
      "phuongxa":"Xã An Tiến",
      "quanhuyen":"10315"
   },
   {
      "id":"1031511",
      "phuongxa":"Xã Quang Hưng",
      "quanhuyen":"10315"
   },
   {
      "id":"1031515",
      "phuongxa":"Xã Quốc Tuấn",
      "quanhuyen":"10315"
   },
   {
      "id":"1031517",
      "phuongxa":"Xã An Thắng",
      "quanhuyen":"10315"
   },
   {
      "id":"1031519",
      "phuongxa":"Xã Trường Sơn",
      "quanhuyen":"10315"
   },
   {
      "id":"1031521",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"10315"
   },
   {
      "id":"1031523",
      "phuongxa":"Xã Thái Sơn",
      "quanhuyen":"10315"
   },
   {
      "id":"1031525",
      "phuongxa":"Xã Tân Viên",
      "quanhuyen":"10315"
   },
   {
      "id":"1031527",
      "phuongxa":"Xã Mỹ Đức",
      "quanhuyen":"10315"
   },
   {
      "id":"1031529",
      "phuongxa":"Xã Chiến Thắng",
      "quanhuyen":"10315"
   },
   {
      "id":"1031531",
      "phuongxa":"Xã An Thọ",
      "quanhuyen":"10315"
   },
   {
      "id":"1031533",
      "phuongxa":"Xã An Thái",
      "quanhuyen":"10315"
   },
   {
      "id":"1031701",
      "phuongxa":"Thị trấn Núi Đối",
      "quanhuyen":"10317"
   },
   {
      "id":"1031703",
      "phuongxa":"Xã Đa Phúc",
      "quanhuyen":"10317"
   },
   {
      "id":"1031705",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"10317"
   },
   {
      "id":"1031707",
      "phuongxa":"Xã Anh Dũng",
      "quanhuyen":"10317"
   },
   {
      "id":"1031709",
      "phuongxa":"Xã Hải Thành",
      "quanhuyen":"10317"
   },
   {
      "id":"1031711",
      "phuongxa":"Xã Đông Phương",
      "quanhuyen":"10317"
   },
   {
      "id":"1031713",
      "phuongxa":"Xã Thuận Thiên",
      "quanhuyen":"10317"
   },
   {
      "id":"1031715",
      "phuongxa":"Xã Hữu Bằng",
      "quanhuyen":"10317"
   },
   {
      "id":"1031717",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"10317"
   },
   {
      "id":"1031719",
      "phuongxa":"Xã Hoà Nghĩa",
      "quanhuyen":"10317"
   },
   {
      "id":"1031721",
      "phuongxa":"Xã Ngũ Phúc",
      "quanhuyen":"10317"
   },
   {
      "id":"1031723",
      "phuongxa":"Xã Kiến Quốc",
      "quanhuyen":"10317"
   },
   {
      "id":"1031725",
      "phuongxa":"Xã Thuỵ Hương",
      "quanhuyen":"10317"
   },
   {
      "id":"1031727",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"10317"
   },
   {
      "id":"1031729",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"10317"
   },
   {
      "id":"1031731",
      "phuongxa":"Xã Đại Hà",
      "quanhuyen":"10317"
   },
   {
      "id":"1031733",
      "phuongxa":"Xã Ngũ Đoan",
      "quanhuyen":"10317"
   },
   {
      "id":"1031735",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"10317"
   },
   {
      "id":"1031737",
      "phuongxa":"Xã Hợp Đức",
      "quanhuyen":"10317"
   },
   {
      "id":"1031739",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"10317"
   },
   {
      "id":"1031741",
      "phuongxa":"Xã Tân Trào",
      "quanhuyen":"10317"
   },
   {
      "id":"1031745",
      "phuongxa":"Xã Tú Sơn",
      "quanhuyen":"10317"
   },
   {
      "id":"1031747",
      "phuongxa":"Xã Đại Hợp",
      "quanhuyen":"10317"
   },
   {
      "id":"1031900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10319"
   },
   {
      "id":"1031901",
      "phuongxa":"Thị trấn Tiên Lãng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031903",
      "phuongxa":"Xã Đại Thắng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031905",
      "phuongxa":"Xã Tiên Cường",
      "quanhuyen":"10319"
   },
   {
      "id":"1031907",
      "phuongxa":"Xã Tự Cường",
      "quanhuyen":"10319"
   },
   {
      "id":"1031909",
      "phuongxa":"Xã Tiên Tiến",
      "quanhuyen":"10319"
   },
   {
      "id":"1031911",
      "phuongxa":"Xã Quyết Tiến",
      "quanhuyen":"10319"
   },
   {
      "id":"1031913",
      "phuongxa":"Xã Khởi Nghĩa",
      "quanhuyen":"10319"
   },
   {
      "id":"1031915",
      "phuongxa":"Xã Tiên Thanh",
      "quanhuyen":"10319"
   },
   {
      "id":"1031917",
      "phuongxa":"Xã Cấp Tiến",
      "quanhuyen":"10319"
   },
   {
      "id":"1031919",
      "phuongxa":"Xã Kiến Thiết",
      "quanhuyen":"10319"
   },
   {
      "id":"1031921",
      "phuongxa":"Xã Đoàn Lập",
      "quanhuyen":"10319"
   },
   {
      "id":"1031923",
      "phuongxa":"Xã Bạch Đằng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031925",
      "phuongxa":"Xã Quang Phục",
      "quanhuyen":"10319"
   },
   {
      "id":"1031927",
      "phuongxa":"Xã Toàn Thắng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031929",
      "phuongxa":"Xã Tiên Thắng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031933",
      "phuongxa":"Xã Bắc Hưng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031935",
      "phuongxa":"Xã Nam Hưng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031937",
      "phuongxa":"Xã Hùng Thắng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031939",
      "phuongxa":"Xã Tây Hưng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031941",
      "phuongxa":"Xã Đông Hưng",
      "quanhuyen":"10319"
   },
   {
      "id":"1031945",
      "phuongxa":"Xã Vinh Quang",
      "quanhuyen":"10319"
   },
   {
      "id":"1032100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10321"
   },
   {
      "id":"1032101",
      "phuongxa":"Thị trấn Vĩnh Bảo",
      "quanhuyen":"10321"
   },
   {
      "id":"1032103",
      "phuongxa":"Xã Dũng Tiến",
      "quanhuyen":"10321"
   },
   {
      "id":"1032105",
      "phuongxa":"Xã Giang Biên",
      "quanhuyen":"10321"
   },
   {
      "id":"1032109",
      "phuongxa":"Xã Trung Lập",
      "quanhuyen":"10321"
   },
   {
      "id":"1032111",
      "phuongxa":"Xã Việt Tiến",
      "quanhuyen":"10321"
   },
   {
      "id":"1032113",
      "phuongxa":"Xã Vĩnh An",
      "quanhuyen":"10321"
   },
   {
      "id":"1032115",
      "phuongxa":"Xã Vĩnh Long",
      "quanhuyen":"10321"
   },
   {
      "id":"1032117",
      "phuongxa":"Xã Hiệp Hoà",
      "quanhuyen":"10321"
   },
   {
      "id":"1032121",
      "phuongxa":"Xã An Hoà",
      "quanhuyen":"10321"
   },
   {
      "id":"1032123",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"10321"
   },
   {
      "id":"1032125",
      "phuongxa":"Xã Tân Liên",
      "quanhuyen":"10321"
   },
   {
      "id":"1032127",
      "phuongxa":"Xã Nhân Hoà",
      "quanhuyen":"10321"
   },
   {
      "id":"1032129",
      "phuongxa":"Xã Tam Đa",
      "quanhuyen":"10321"
   },
   {
      "id":"1032135",
      "phuongxa":"Xã Đồng Minh",
      "quanhuyen":"10321"
   },
   {
      "id":"1032137",
      "phuongxa":"Xã Thanh Lương",
      "quanhuyen":"10321"
   },
   {
      "id":"1032139",
      "phuongxa":"Xã Liên Am",
      "quanhuyen":"10321"
   },
   {
      "id":"1032141",
      "phuongxa":"Xã Lý Học",
      "quanhuyen":"10321"
   },
   {
      "id":"1032143",
      "phuongxa":"Xã Tam Cường",
      "quanhuyen":"10321"
   },
   {
      "id":"1032145",
      "phuongxa":"Xã Hoà Bình",
      "quanhuyen":"10321"
   },
   {
      "id":"1032147",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"10321"
   },
   {
      "id":"1032149",
      "phuongxa":"Xã Vĩnh Phong",
      "quanhuyen":"10321"
   },
   {
      "id":"1032151",
      "phuongxa":"Xã Cộng Hiền",
      "quanhuyen":"10321"
   },
   {
      "id":"1032153",
      "phuongxa":"Xã Cao Minh",
      "quanhuyen":"10321"
   },
   {
      "id":"1013537",
      "phuongxa":"Xã La Phù",
      "quanhuyen":"10135"
   },
   {
      "id":"1013539",
      "phuongxa":"Xã Đông La",
      "quanhuyen":"10135"
   },
   {
      "id":"1013700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10137"
   },
   {
      "id":"1013701",
      "phuongxa":"Thị trấn Quốc Oai",
      "quanhuyen":"10137"
   },
   {
      "id":"1013703",
      "phuongxa":"Xã Sài Sơn",
      "quanhuyen":"10137"
   },
   {
      "id":"1013705",
      "phuongxa":"Xã Phượng Cách",
      "quanhuyen":"10137"
   },
   {
      "id":"1013707",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"10137"
   },
   {
      "id":"1013711",
      "phuongxa":"Xã Ngọc Mỹ",
      "quanhuyen":"10137"
   },
   {
      "id":"1013713",
      "phuongxa":"Xã Liệp Tuyết",
      "quanhuyen":"10137"
   },
   {
      "id":"1013715",
      "phuongxa":"Xã Thạch Thán",
      "quanhuyen":"10137"
   },
   {
      "id":"1013717",
      "phuongxa":"Xã Đồng Quang",
      "quanhuyen":"10137"
   },
   {
      "id":"1013719",
      "phuongxa":"Xã Phú Cát",
      "quanhuyen":"10137"
   },
   {
      "id":"1013721",
      "phuongxa":"Xã Tuyết Nghĩa",
      "quanhuyen":"10137"
   },
   {
      "id":"1013723",
      "phuongxa":"Xã Nghĩa Hương",
      "quanhuyen":"10137"
   },
   {
      "id":"1013725",
      "phuongxa":"Xã Cộng Hòa",
      "quanhuyen":"10137"
   },
   {
      "id":"1013727",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"10137"
   },
   {
      "id":"1013729",
      "phuongxa":"Xã Đại Thành",
      "quanhuyen":"10137"
   },
   {
      "id":"1013731",
      "phuongxa":"Xã Phú Mãn",
      "quanhuyen":"10137"
   },
   {
      "id":"1013733",
      "phuongxa":"Xã Cấn Hữu",
      "quanhuyen":"10137"
   },
   {
      "id":"1013735",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"10137"
   },
   {
      "id":"1013737",
      "phuongxa":"Xã Hòa Thạch",
      "quanhuyen":"10137"
   },
   {
      "id":"1013739",
      "phuongxa":"Xã Đông Yên",
      "quanhuyen":"10137"
   },
   {
      "id":"1013741",
      "phuongxa":"Xã Đông Xuân",
      "quanhuyen":"10137"
   },
   {
      "id":"1013900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10139"
   },
   {
      "id":"1013901",
      "phuongxa":"Thị trấn Liên Quan",
      "quanhuyen":"10139"
   },
   {
      "id":"1013903",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"10139"
   },
   {
      "id":"1013905",
      "phuongxa":"Xã Cẩm Yên",
      "quanhuyen":"10139"
   },
   {
      "id":"1013909",
      "phuongxa":"Xã Phú Kim",
      "quanhuyen":"10139"
   },
   {
      "id":"1013911",
      "phuongxa":"Xã Hương Ngải",
      "quanhuyen":"10139"
   },
   {
      "id":"1013913",
      "phuongxa":"Xã Canh Nậu",
      "quanhuyen":"10139"
   },
   {
      "id":"1013915",
      "phuongxa":"Xã Kim Quan",
      "quanhuyen":"10139"
   },
   {
      "id":"1013917",
      "phuongxa":"Xã Dị Nậu",
      "quanhuyen":"10139"
   },
   {
      "id":"1013919",
      "phuongxa":"Xã Bình Yên",
      "quanhuyen":"10139"
   },
   {
      "id":"1013921",
      "phuongxa":"Xã Chàng Sơn",
      "quanhuyen":"10139"
   },
   {
      "id":"1013923",
      "phuongxa":"Xã Thạch Hoà",
      "quanhuyen":"10139"
   },
   {
      "id":"1013925",
      "phuongxa":"Xã Cần Kiệm",
      "quanhuyen":"10139"
   },
   {
      "id":"1013927",
      "phuongxa":"Xã Hữu Bằng",
      "quanhuyen":"10139"
   },
   {
      "id":"1013929",
      "phuongxa":"Xã Phùng Xá",
      "quanhuyen":"10139"
   },
   {
      "id":"1013931",
      "phuongxa":"Xã Tân Xã",
      "quanhuyen":"10139"
   },
   {
      "id":"1013933",
      "phuongxa":"Xã Thạch Xá",
      "quanhuyen":"10139"
   },
   {
      "id":"1013935",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"10139"
   },
   {
      "id":"1013937",
      "phuongxa":"Xã Hạ Bằng",
      "quanhuyen":"10139"
   },
   {
      "id":"1013939",
      "phuongxa":"Xã Đồng Trúc",
      "quanhuyen":"10139"
   },
   {
      "id":"1013941",
      "phuongxa":"Xã Yên Trung",
      "quanhuyen":"10139"
   },
   {
      "id":"1013943",
      "phuongxa":"Xã Yên Bình",
      "quanhuyen":"10139"
   },
   {
      "id":"1013945",
      "phuongxa":"Xã Tiến Xuân",
      "quanhuyen":"10139"
   },
   {
      "id":"1014100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10141"
   },
   {
      "id":"1014103",
      "phuongxa":"Thị trấn Xuân Mai",
      "quanhuyen":"10141"
   },
   {
      "id":"1014107",
      "phuongxa":"Xã Tiên Phương",
      "quanhuyen":"10141"
   },
   {
      "id":"1014109",
      "phuongxa":"Xã Đông Sơn",
      "quanhuyen":"10141"
   },
   {
      "id":"1014111",
      "phuongxa":"Xã Đông Phương Yên",
      "quanhuyen":"10141"
   },
   {
      "id":"1014115",
      "phuongxa":"Xã Trường Yên",
      "quanhuyen":"10141"
   },
   {
      "id":"1014117",
      "phuongxa":"Xã Ngọc Hòa",
      "quanhuyen":"10141"
   },
   {
      "id":"1014119",
      "phuongxa":"Xã Thủy Xuân Tiên",
      "quanhuyen":"10141"
   },
   {
      "id":"1014121",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"10141"
   },
   {
      "id":"1014123",
      "phuongxa":"Xã Trung Hòa",
      "quanhuyen":"10141"
   },
   {
      "id":"1014127",
      "phuongxa":"Xã Thụy Hương",
      "quanhuyen":"10141"
   },
   {
      "id":"1014129",
      "phuongxa":"Xã Tốt Động",
      "quanhuyen":"10141"
   },
   {
      "id":"1014131",
      "phuongxa":"Xã Lam Điền",
      "quanhuyen":"10141"
   },
   {
      "id":"1014133",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"10141"
   },
   {
      "id":"1014137",
      "phuongxa":"Xã Hợp Đồng",
      "quanhuyen":"10141"
   },
   {
      "id":"1014139",
      "phuongxa":"Xã Hoàng Văn Thụ",
      "quanhuyen":"10141"
   },
   {
      "id":"1014141",
      "phuongxa":"Xã Hoàng Diệu",
      "quanhuyen":"10141"
   },
   {
      "id":"1014143",
      "phuongxa":"Xã Hữu Văn",
      "quanhuyen":"10141"
   },
   {
      "id":"1014145",
      "phuongxa":"Xã Quảng Bị",
      "quanhuyen":"10141"
   },
   {
      "id":"1014147",
      "phuongxa":"Xã Mỹ Lương",
      "quanhuyen":"10141"
   },
   {
      "id":"1014149",
      "phuongxa":"Xã Thượng Vực",
      "quanhuyen":"10141"
   },
   {
      "id":"1014151",
      "phuongxa":"Xã Hồng Phong",
      "quanhuyen":"10141"
   },
   {
      "id":"1014153",
      "phuongxa":"Xã Đồng Phú",
      "quanhuyen":"10141"
   },
   {
      "id":"1014155",
      "phuongxa":"Xã Trần Phú",
      "quanhuyen":"10141"
   },
   {
      "id":"1090427",
      "phuongxa":"Xã Minh Châu",
      "quanhuyen":"10904"
   },
   {
      "id":"1090429",
      "phuongxa":"Xã Trung Hưng",
      "quanhuyen":"10904"
   },
   {
      "id":"1090431",
      "phuongxa":"Xã Lý Thường Kiệt",
      "quanhuyen":"10904"
   },
   {
      "id":"1090433",
      "phuongxa":"Xã Tân Việt",
      "quanhuyen":"10904"
   },
   {
      "id":"1090601",
      "phuongxa":"Thị trấn Khoái Châu",
      "quanhuyen":"10906"
   },
   {
      "id":"1090603",
      "phuongxa":"Xã Đông Tảo",
      "quanhuyen":"10906"
   },
   {
      "id":"1090605",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"10906"
   },
   {
      "id":"1090609",
      "phuongxa":"Xã Hàm Tử",
      "quanhuyen":"10906"
   },
   {
      "id":"1090611",
      "phuongxa":"Xã Ông Đình",
      "quanhuyen":"10906"
   },
   {
      "id":"1090613",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"10906"
   },
   {
      "id":"1090615",
      "phuongxa":"Xã Tứ Dân",
      "quanhuyen":"10906"
   },
   {
      "id":"1090617",
      "phuongxa":"Xã An Vĩ",
      "quanhuyen":"10906"
   },
   {
      "id":"1090619",
      "phuongxa":"Xã Đông Kết",
      "quanhuyen":"10906"
   },
   {
      "id":"1090621",
      "phuongxa":"Xã Bình Kiều",
      "quanhuyen":"10906"
   },
   {
      "id":"1090623",
      "phuongxa":"Xã Dân Tiến",
      "quanhuyen":"10906"
   },
   {
      "id":"1090625",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"10906"
   },
   {
      "id":"1090627",
      "phuongxa":"Xã Hồng Tiến",
      "quanhuyen":"10906"
   },
   {
      "id":"1090629",
      "phuongxa":"Xã Tân Châu",
      "quanhuyen":"10906"
   },
   {
      "id":"1090631",
      "phuongxa":"Xã Liên Khê",
      "quanhuyen":"10906"
   },
   {
      "id":"1090633",
      "phuongxa":"Xã Phùng Hưng",
      "quanhuyen":"10906"
   },
   {
      "id":"1090635",
      "phuongxa":"Xã Việt Hòa",
      "quanhuyen":"10906"
   },
   {
      "id":"1090637",
      "phuongxa":"Xã Đông Ninh",
      "quanhuyen":"10906"
   },
   {
      "id":"1090639",
      "phuongxa":"Xã Đại Tập",
      "quanhuyen":"10906"
   },
   {
      "id":"1090641",
      "phuongxa":"Xã Chí Tân",
      "quanhuyen":"10906"
   },
   {
      "id":"1090643",
      "phuongxa":"Xã Đại Hưng",
      "quanhuyen":"10906"
   },
   {
      "id":"1090645",
      "phuongxa":"Xã Thuần Hưng",
      "quanhuyen":"10906"
   },
   {
      "id":"1090649",
      "phuongxa":"Xã Nhuế Dương",
      "quanhuyen":"10906"
   },
   {
      "id":"1090700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10907"
   },
   {
      "id":"1090701",
      "phuongxa":"Thị trấn Ân Thi",
      "quanhuyen":"10907"
   },
   {
      "id":"1090703",
      "phuongxa":"Xã Phù ủng",
      "quanhuyen":"10907"
   },
   {
      "id":"1090705",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"10907"
   },
   {
      "id":"1090707",
      "phuongxa":"Xã Bãi Sậy",
      "quanhuyen":"10907"
   },
   {
      "id":"1090709",
      "phuongxa":"Xã Đào Dương",
      "quanhuyen":"10907"
   },
   {
      "id":"1090711",
      "phuongxa":"Xã Tân Phúc",
      "quanhuyen":"10907"
   },
   {
      "id":"1090713",
      "phuongxa":"Xã Vân Du",
      "quanhuyen":"10907"
   },
   {
      "id":"1090715",
      "phuongxa":"Xã Quang Vinh",
      "quanhuyen":"10907"
   },
   {
      "id":"1090717",
      "phuongxa":"Xã Xuân Trúc",
      "quanhuyen":"10907"
   },
   {
      "id":"1090719",
      "phuongxa":"Xã Hoàng Hoa Thám",
      "quanhuyen":"10907"
   },
   {
      "id":"1090721",
      "phuongxa":"Xã Quảng Lãng",
      "quanhuyen":"10907"
   },
   {
      "id":"1090723",
      "phuongxa":"Xã Văn Nhuệ",
      "quanhuyen":"10907"
   },
   {
      "id":"1090725",
      "phuongxa":"Xã Đặng Lễ",
      "quanhuyen":"10907"
   },
   {
      "id":"1090727",
      "phuongxa":"Xã Cẩm Ninh",
      "quanhuyen":"10907"
   },
   {
      "id":"1090729",
      "phuongxa":"Xã Nguyễn Trãi",
      "quanhuyen":"10907"
   },
   {
      "id":"1090731",
      "phuongxa":"Xã Đa Lộc",
      "quanhuyen":"10907"
   },
   {
      "id":"1090733",
      "phuongxa":"Xã Hồ Tùng Mậu",
      "quanhuyen":"10907"
   },
   {
      "id":"1090735",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"10907"
   },
   {
      "id":"1090737",
      "phuongxa":"Xã Hồng Vân",
      "quanhuyen":"10907"
   },
   {
      "id":"1090900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10909"
   },
   {
      "id":"1090901",
      "phuongxa":"Xã Nghĩa Dân",
      "quanhuyen":"10909"
   },
   {
      "id":"1090903",
      "phuongxa":"Xã Toàn Thắng",
      "quanhuyen":"10909"
   },
   {
      "id":"1090905",
      "phuongxa":"Xã Vĩnh Xá",
      "quanhuyen":"10909"
   },
   {
      "id":"1090909",
      "phuongxa":"Xã Thọ Vinh",
      "quanhuyen":"10909"
   },
   {
      "id":"1090911",
      "phuongxa":"Xã Đồng Thanh",
      "quanhuyen":"10909"
   },
   {
      "id":"1090913",
      "phuongxa":"Xã Song Mai",
      "quanhuyen":"10909"
   },
   {
      "id":"1090915",
      "phuongxa":"Xã Chính Nghĩa",
      "quanhuyen":"10909"
   },
   {
      "id":"1090917",
      "phuongxa":"Xã Nhân La",
      "quanhuyen":"10909"
   },
   {
      "id":"1090921",
      "phuongxa":"Xã Mai Động",
      "quanhuyen":"10909"
   },
   {
      "id":"1090923",
      "phuongxa":"Xã Đức Hợp",
      "quanhuyen":"10909"
   },
   {
      "id":"1090925",
      "phuongxa":"Xã Hùng An",
      "quanhuyen":"10909"
   },
   {
      "id":"1090927",
      "phuongxa":"Xã Ngọc Thanh",
      "quanhuyen":"10909"
   },
   {
      "id":"1090929",
      "phuongxa":"Xã Lương Bằng",
      "quanhuyen":"10909"
   },
   {
      "id":"1090931",
      "phuongxa":"Xã Vũ Xá",
      "quanhuyen":"10909"
   },
   {
      "id":"1090935",
      "phuongxa":"Xã Phú Cường",
      "quanhuyen":"10909"
   },
   {
      "id":"1090937",
      "phuongxa":"Xã Hùng Cường",
      "quanhuyen":"10909"
   },
   {
      "id":"1090939",
      "phuongxa":"Xã Bảo Khê",
      "quanhuyen":"10909"
   },
   {
      "id":"1091100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10911"
   },
   {
      "id":"1091101",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"10911"
   },
   {
      "id":"1091103",
      "phuongxa":"Xã Phan Sào Nam",
      "quanhuyen":"10911"
   },
   {
      "id":"1091105",
      "phuongxa":"Xã Trần Cao",
      "quanhuyen":"10911"
   },
   {
      "id":"1091107",
      "phuongxa":"Xã Quang Hưng",
      "quanhuyen":"10911"
   },
   {
      "id":"1091111",
      "phuongxa":"Xã Đoàn Đào",
      "quanhuyen":"10911"
   },
   {
      "id":"1091113",
      "phuongxa":"Xã Tống Phan",
      "quanhuyen":"10911"
   },
   {
      "id":"1091115",
      "phuongxa":"Xã Đình Cao",
      "quanhuyen":"10911"
   },
   {
      "id":"1091117",
      "phuongxa":"Xã Nhật Quang",
      "quanhuyen":"10911"
   },
   {
      "id":"1091119",
      "phuongxa":"Xã Tiền Tiến",
      "quanhuyen":"10911"
   },
   {
      "id":"1091121",
      "phuongxa":"Xã Tam Đa",
      "quanhuyen":"10911"
   },
   {
      "id":"1091123",
      "phuongxa":"Xã Minh Tiến",
      "quanhuyen":"10911"
   },
   {
      "id":"1091125",
      "phuongxa":"Xã Nguyên Hòa",
      "quanhuyen":"10911"
   },
   {
      "id":"1091127",
      "phuongxa":"Xã Tống Trân",
      "quanhuyen":"10911"
   },
   {
      "id":"1091300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10913"
   },
   {
      "id":"1091301",
      "phuongxa":"Thị trấn Vương",
      "quanhuyen":"10913"
   },
   {
      "id":"1091303",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"10913"
   },
   {
      "id":"1091305",
      "phuongxa":"Xã Ngô Quyền",
      "quanhuyen":"10913"
   },
   {
      "id":"1091307",
      "phuongxa":"Xã Nhật Tân",
      "quanhuyen":"10913"
   },
   {
      "id":"1091309",
      "phuongxa":"Xã Dị Chế",
      "quanhuyen":"10913"
   },
   {
      "id":"1091311",
      "phuongxa":"Xã Lệ Xá",
      "quanhuyen":"10913"
   },
   {
      "id":"1091313",
      "phuongxa":"Xã Trung Nghĩa",
      "quanhuyen":"10913"
   },
   {
      "id":"1091315",
      "phuongxa":"Xã An Viên",
      "quanhuyen":"10913"
   },
   {
      "id":"1091317",
      "phuongxa":"Xã Đức Thắng",
      "quanhuyen":"10913"
   },
   {
      "id":"1091319",
      "phuongxa":"Xã Trung Dũng",
      "quanhuyen":"10913"
   },
   {
      "id":"1091323",
      "phuongxa":"Xã Liên Phương",
      "quanhuyen":"10913"
   },
   {
      "id":"1091325",
      "phuongxa":"Xã Thủ Sỹ",
      "quanhuyen":"10913"
   },
   {
      "id":"1091327",
      "phuongxa":"Xã Thiện Phiến",
      "quanhuyen":"10913"
   },
   {
      "id":"1091329",
      "phuongxa":"Xã Thụy Lôi",
      "quanhuyen":"10913"
   },
   {
      "id":"1091331",
      "phuongxa":"Xã Cương Chính",
      "quanhuyen":"10913"
   },
   {
      "id":"1091333",
      "phuongxa":"Xã Minh Phương",
      "quanhuyen":"10913"
   },
   {
      "id":"1091335",
      "phuongxa":"Xã Phương Chiểu",
      "quanhuyen":"10913"
   },
   {
      "id":"1091337",
      "phuongxa":"Xã Quảng Châu",
      "quanhuyen":"10913"
   },
   {
      "id":"1091339",
      "phuongxa":"Xã Hồng Nam",
      "quanhuyen":"10913"
   },
   {
      "id":"1091341",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"10913"
   },
   {
      "id":"1091343",
      "phuongxa":"Xã Hoàng Hanh",
      "quanhuyen":"10913"
   },
   {
      "id":"1091500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10915"
   },
   {
      "id":"1091501",
      "phuongxa":"Thị trấn Văn Giang",
      "quanhuyen":"10915"
   },
   {
      "id":"1091503",
      "phuongxa":"Xã Xuân Quan",
      "quanhuyen":"10915"
   },
   {
      "id":"1091505",
      "phuongxa":"Xã Cửu Cao",
      "quanhuyen":"10915"
   },
   {
      "id":"1091507",
      "phuongxa":"Xã Phụng Công",
      "quanhuyen":"10915"
   },
   {
      "id":"1091509",
      "phuongxa":"Xã Nghĩa Trụ",
      "quanhuyen":"10915"
   },
   {
      "id":"1091511",
      "phuongxa":"Xã Long Hưng",
      "quanhuyen":"10915"
   },
   {
      "id":"1091513",
      "phuongxa":"Xã Vĩnh Khúc",
      "quanhuyen":"10915"
   },
   {
      "id":"1091515",
      "phuongxa":"Xã Liên Nghĩa",
      "quanhuyen":"10915"
   },
   {
      "id":"1091519",
      "phuongxa":"Xã Thắng Lợi",
      "quanhuyen":"10915"
   },
   {
      "id":"1091521",
      "phuongxa":"Xã Mễ Sở",
      "quanhuyen":"10915"
   },
   {
      "id":"1110100",
      "phuongxa":"Không xác định",
      "quanhuyen":"11101"
   },
   {
      "id":"1110101",
      "phuongxa":"Phường Trần Hưng Đạo",
      "quanhuyen":"11101"
   },
   {
      "id":"1110103",
      "phuongxa":"Phường Minh Khai",
      "quanhuyen":"11101"
   },
   {
      "id":"1110105",
      "phuongxa":"Phường Hai Bà Trưng",
      "quanhuyen":"11101"
   },
   {
      "id":"1110109",
      "phuongxa":"Xã Thanh Châu",
      "quanhuyen":"11101"
   },
   {
      "id":"1110111",
      "phuongxa":"Xã Liêm Chính",
      "quanhuyen":"11101"
   },
   {
      "id":"1110112",
      "phuongxa":"Phường Lam Hạ",
      "quanhuyen":"11101"
   },
   {
      "id":"1110300",
      "phuongxa":"Không xác định",
      "quanhuyen":"11103"
   },
   {
      "id":"1110301",
      "phuongxa":"Thị trấn Hòa Mạc",
      "quanhuyen":"11103"
   },
   {
      "id":"1110303",
      "phuongxa":"Thị trấn Đồng Văn",
      "quanhuyen":"11103"
   },
   {
      "id":"1110305",
      "phuongxa":"Xã Duy Minh",
      "quanhuyen":"11103"
   },
   {
      "id":"1110309",
      "phuongxa":"Xã Bạch Thượng",
      "quanhuyen":"11103"
   },
   {
      "id":"1110311",
      "phuongxa":"Xã Mộc Bắc",
      "quanhuyen":"11103"
   },
   {
      "id":"1110313",
      "phuongxa":"Xã Mộc Nam",
      "quanhuyen":"11103"
   },
   {
      "id":"1110315",
      "phuongxa":"Xã Châu Giang",
      "quanhuyen":"11103"
   },
   {
      "id":"1110317",
      "phuongxa":"Xã Chuyên Ngoại",
      "quanhuyen":"11103"
   },
   {
      "id":"1110321",
      "phuongxa":"Xã Yên Bắc",
      "quanhuyen":"11103"
   },
   {
      "id":"1110325",
      "phuongxa":"Xã Hoàng Đông",
      "quanhuyen":"11103"
   },
   {
      "id":"1110327",
      "phuongxa":"Xã Tiên Nội",
      "quanhuyen":"11103"
   },
   {
      "id":"1110329",
      "phuongxa":"Xã Tiên Ngoại",
      "quanhuyen":"11103"
   },
   {
      "id":"1110331",
      "phuongxa":"Xã Tiên Tân",
      "quanhuyen":"11103"
   },
   {
      "id":"1110333",
      "phuongxa":"Xã Tiên Hiệp",
      "quanhuyen":"11103"
   },
   {
      "id":"1110335",
      "phuongxa":"Xã Đọi Sơn",
      "quanhuyen":"11103"
   },
   {
      "id":"1110337",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"11103"
   },
   {
      "id":"1110339",
      "phuongxa":"Xã Châu Sơn",
      "quanhuyen":"11103"
   },
   {
      "id":"1040905",
      "phuongxa":"Xã Đồng Văn",
      "quanhuyen":"10409"
   },
   {
      "id":"1040907",
      "phuongxa":"Xã Bình Định",
      "quanhuyen":"10409"
   },
   {
      "id":"1040909",
      "phuongxa":"Xã Trung Nguyên",
      "quanhuyen":"10409"
   },
   {
      "id":"1040911",
      "phuongxa":"Xã Tề Lỗ",
      "quanhuyen":"10409"
   },
   {
      "id":"1040913",
      "phuongxa":"Xã Tam Hồng",
      "quanhuyen":"10409"
   },
   {
      "id":"1040915",
      "phuongxa":"Xã Yên Đồng",
      "quanhuyen":"10409"
   },
   {
      "id":"1040917",
      "phuongxa":"Xã Văn Tiến",
      "quanhuyen":"10409"
   },
   {
      "id":"1040919",
      "phuongxa":"Xã Nguyệt Đức",
      "quanhuyen":"10409"
   },
   {
      "id":"1040921",
      "phuongxa":"Xã Yên Phương",
      "quanhuyen":"10409"
   },
   {
      "id":"1040923",
      "phuongxa":"Xã Hồng Phương",
      "quanhuyen":"10409"
   },
   {
      "id":"1040925",
      "phuongxa":"Xã Trung Kiên",
      "quanhuyen":"10409"
   },
   {
      "id":"1040927",
      "phuongxa":"Xã Liên Châu",
      "quanhuyen":"10409"
   },
   {
      "id":"1040931",
      "phuongxa":"Xã Hồng Châu",
      "quanhuyen":"10409"
   },
   {
      "id":"1040933",
      "phuongxa":"Xã Trung Hà",
      "quanhuyen":"10409"
   },
   {
      "id":"1041300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10413"
   },
   {
      "id":"1041301",
      "phuongxa":"Thị trấn Hương Canh",
      "quanhuyen":"10413"
   },
   {
      "id":"1041303",
      "phuongxa":"Xã Trung Mỹ",
      "quanhuyen":"10413"
   },
   {
      "id":"1041305",
      "phuongxa":"Xã Minh Quang",
      "quanhuyen":"10413"
   },
   {
      "id":"1041307",
      "phuongxa":"Xã Gia Khánh",
      "quanhuyen":"10413"
   },
   {
      "id":"1041309",
      "phuongxa":"Xã Bá Hiến",
      "quanhuyen":"10413"
   },
   {
      "id":"1041311",
      "phuongxa":"Xã Thiện Kế",
      "quanhuyen":"10413"
   },
   {
      "id":"1041313",
      "phuongxa":"Xã Hương Sơn",
      "quanhuyen":"10413"
   },
   {
      "id":"1041315",
      "phuongxa":"Xã Tam Hợp",
      "quanhuyen":"10413"
   },
   {
      "id":"1041317",
      "phuongxa":"Xã Quất Lưu",
      "quanhuyen":"10413"
   },
   {
      "id":"1041319",
      "phuongxa":"Xã Sơn Lôi",
      "quanhuyen":"10413"
   },
   {
      "id":"1041321",
      "phuongxa":"Xã Đạo Đức",
      "quanhuyen":"10413"
   },
   {
      "id":"1041323",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"10413"
   },
   {
      "id":"1041325",
      "phuongxa":"Xã Thanh Lãng",
      "quanhuyen":"10413"
   },
   {
      "id":"1041327",
      "phuongxa":"Xã Phú Xuân",
      "quanhuyen":"10413"
   },
   {
      "id":"1041100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10411"
   },
   {
      "id":"1041103",
      "phuongxa":"Phường Hùng Vương",
      "quanhuyen":"10411"
   },
   {
      "id":"1041105",
      "phuongxa":"Phường Trưng Nhị",
      "quanhuyen":"10411"
   },
   {
      "id":"1041107",
      "phuongxa":"Phường Phúc Thắng",
      "quanhuyen":"10411"
   },
   {
      "id":"1041109",
      "phuongxa":"Phường Xuân Hoà",
      "quanhuyen":"10411"
   },
   {
      "id":"1041111",
      "phuongxa":"Xã Ngọc Thanh",
      "quanhuyen":"10411"
   },
   {
      "id":"1041113",
      "phuongxa":"Xã Cao Minh",
      "quanhuyen":"10411"
   },
   {
      "id":"1041115",
      "phuongxa":"Xã Nam Viêm",
      "quanhuyen":"10411"
   },
   {
      "id":"1041117",
      "phuongxa":"Xã Tiền Châu",
      "quanhuyen":"10411"
   },
   {
      "id":"1041119",
      "phuongxa":"Phường Đồng Xuân",
      "quanhuyen":"10411"
   },
   {
      "id":"1041500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10415"
   },
   {
      "id":"1041503",
      "phuongxa":"Xã Hải Lựu",
      "quanhuyen":"10415"
   },
   {
      "id":"1041505",
      "phuongxa":"Xã Đôn Nhân",
      "quanhuyen":"10415"
   },
   {
      "id":"1041507",
      "phuongxa":"Xã Quang Yên",
      "quanhuyen":"10415"
   },
   {
      "id":"1041509",
      "phuongxa":"Xã Lãng Công",
      "quanhuyen":"10415"
   },
   {
      "id":"1041511",
      "phuongxa":"Xã Nhân Đạo",
      "quanhuyen":"10415"
   },
   {
      "id":"1041515",
      "phuongxa":"Xã Đồng Quế",
      "quanhuyen":"10415"
   },
   {
      "id":"1041517",
      "phuongxa":"Xã Nhạo Sơn",
      "quanhuyen":"10415"
   },
   {
      "id":"1041519",
      "phuongxa":"Xã Như Thụy",
      "quanhuyen":"10415"
   },
   {
      "id":"1041521",
      "phuongxa":"Xã Yên Thạch",
      "quanhuyen":"10415"
   },
   {
      "id":"1041523",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"10415"
   },
   {
      "id":"1041525",
      "phuongxa":"Xã Tứ Yên",
      "quanhuyen":"10415"
   },
   {
      "id":"1041529",
      "phuongxa":"Xã Đức Bác",
      "quanhuyen":"10415"
   },
   {
      "id":"1041531",
      "phuongxa":"Xã Cao Phong",
      "quanhuyen":"10415"
   },
   {
      "id":"1070000",
      "phuongxa":"Không xác định",
      "quanhuyen":"10700"
   },
   {
      "id":"1070100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10701"
   },
   {
      "id":"1070103",
      "phuongxa":"Phường Nguyễn Trãi",
      "quanhuyen":"10701"
   },
   {
      "id":"1070105",
      "phuongxa":"Phường Lê Thanh Nghị",
      "quanhuyen":"10701"
   },
   {
      "id":"1070107",
      "phuongxa":"Phường Trần Phú",
      "quanhuyen":"10701"
   },
   {
      "id":"1070109",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"10701"
   },
   {
      "id":"1070111",
      "phuongxa":"Phường Trần Hưng Đạo",
      "quanhuyen":"10701"
   },
   {
      "id":"1070115",
      "phuongxa":"Phường Bình Hàn",
      "quanhuyen":"10701"
   },
   {
      "id":"1070117",
      "phuongxa":"Phường Ngọc Châu",
      "quanhuyen":"10701"
   },
   {
      "id":"1070119",
      "phuongxa":"Phường Thanh Bình",
      "quanhuyen":"10701"
   },
   {
      "id":"1070121",
      "phuongxa":"Phường Hải Tân",
      "quanhuyen":"10701"
   },
   {
      "id":"1070123",
      "phuongxa":"Xã Việt Hòa",
      "quanhuyen":"10701"
   },
   {
      "id":"1070125",
      "phuongxa":"Xã Tứ Minh",
      "quanhuyen":"10701"
   },
   {
      "id":"1070300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10703"
   },
   {
      "id":"1070301",
      "phuongxa":"Phường Sao Đỏ",
      "quanhuyen":"10703"
   },
   {
      "id":"1070303",
      "phuongxa":"Thị trấn NT Trường",
      "quanhuyen":"10703"
   },
   {
      "id":"1070305",
      "phuongxa":"Thị trấn Phả Lại",
      "quanhuyen":"10703"
   },
   {
      "id":"1070307",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"10703"
   },
   {
      "id":"1070309",
      "phuongxa":"Xã Lê Lợi",
      "quanhuyen":"10703"
   },
   {
      "id":"1070313",
      "phuongxa":"Xã Hoàng Hoa Thám",
      "quanhuyen":"10703"
   },
   {
      "id":"1070315",
      "phuongxa":"Xã Cổ Thành",
      "quanhuyen":"10703"
   },
   {
      "id":"1070317",
      "phuongxa":"Xã Cộng Hòa",
      "quanhuyen":"10703"
   },
   {
      "id":"1070319",
      "phuongxa":"Xã Hoàng Tân",
      "quanhuyen":"10703"
   },
   {
      "id":"1070321",
      "phuongxa":"Xã Hoàng Tiến",
      "quanhuyen":"10703"
   },
   {
      "id":"1070323",
      "phuongxa":"Xã Nhân Huệ",
      "quanhuyen":"10703"
   },
   {
      "id":"1070325",
      "phuongxa":"Xã Văn An",
      "quanhuyen":"10703"
   },
   {
      "id":"1070327",
      "phuongxa":"Xã Chí Minh",
      "quanhuyen":"10703"
   },
   {
      "id":"1070329",
      "phuongxa":"Xã Thái Học",
      "quanhuyen":"10703"
   },
   {
      "id":"1070331",
      "phuongxa":"Xã Văn Đức",
      "quanhuyen":"10703"
   },
   {
      "id":"1070333",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"10703"
   },
   {
      "id":"1070335",
      "phuongxa":"Xã An Lạc",
      "quanhuyen":"10703"
   },
   {
      "id":"1070337",
      "phuongxa":"Xã Kênh Giang",
      "quanhuyen":"10703"
   },
   {
      "id":"1070339",
      "phuongxa":"Xã Đồng Lạc",
      "quanhuyen":"10703"
   },
   {
      "id":"1070500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10705"
   },
   {
      "id":"1070501",
      "phuongxa":"Thị trấn Nam Sách",
      "quanhuyen":"10705"
   },
   {
      "id":"1070503",
      "phuongxa":"Xã Nam Hưng",
      "quanhuyen":"10705"
   },
   {
      "id":"1070507",
      "phuongxa":"Xã Thanh Quang",
      "quanhuyen":"10705"
   },
   {
      "id":"1070509",
      "phuongxa":"Xã Quốc Tuấn",
      "quanhuyen":"10705"
   },
   {
      "id":"1070511",
      "phuongxa":"Xã Hợp Tiến",
      "quanhuyen":"10705"
   },
   {
      "id":"1070513",
      "phuongxa":"Xã Hiệp Cát",
      "quanhuyen":"10705"
   },
   {
      "id":"1070515",
      "phuongxa":"Xã Nam Chính",
      "quanhuyen":"10705"
   },
   {
      "id":"1070517",
      "phuongxa":"Xã Nam Trung",
      "quanhuyen":"10705"
   },
   {
      "id":"1070519",
      "phuongxa":"Xã An Sơn",
      "quanhuyen":"10705"
   },
   {
      "id":"1070521",
      "phuongxa":"Xã Thái Tân",
      "quanhuyen":"10705"
   },
   {
      "id":"1070523",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"10705"
   },
   {
      "id":"1070525",
      "phuongxa":"Xã Hồng Phong",
      "quanhuyen":"10705"
   },
   {
      "id":"1070527",
      "phuongxa":"Xã Nam Hồng",
      "quanhuyen":"10705"
   },
   {
      "id":"1070529",
      "phuongxa":"Xã Thượng Đạt",
      "quanhuyen":"10705"
   },
   {
      "id":"1070531",
      "phuongxa":"Xã An Lâm",
      "quanhuyen":"10705"
   },
   {
      "id":"1070533",
      "phuongxa":"Xã Nam Đồng",
      "quanhuyen":"10705"
   },
   {
      "id":"1070535",
      "phuongxa":"Xã ái Quốc",
      "quanhuyen":"10705"
   },
   {
      "id":"1070537",
      "phuongxa":"Xã Đồng Lạc",
      "quanhuyen":"10705"
   },
   {
      "id":"1070539",
      "phuongxa":"Xã An Châu",
      "quanhuyen":"10705"
   },
   {
      "id":"1070541",
      "phuongxa":"Xã Phú Điền",
      "quanhuyen":"10705"
   },
   {
      "id":"1070545",
      "phuongxa":"Xã Cộng Hòa",
      "quanhuyen":"10705"
   },
   {
      "id":"1070700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10707"
   },
   {
      "id":"1070701",
      "phuongxa":"Thị trấn Thanh Hà",
      "quanhuyen":"10707"
   },
   {
      "id":"1070703",
      "phuongxa":"Xã Hồng Lạc",
      "quanhuyen":"10707"
   },
   {
      "id":"1070705",
      "phuongxa":"Xã Việt Hồng",
      "quanhuyen":"10707"
   },
   {
      "id":"1070709",
      "phuongxa":"Xã Thanh Lang",
      "quanhuyen":"10707"
   },
   {
      "id":"1070711",
      "phuongxa":"Xã Liên Mạc",
      "quanhuyen":"10707"
   },
   {
      "id":"1070713",
      "phuongxa":"Xã Cẩm Chế",
      "quanhuyen":"10707"
   },
   {
      "id":"1070715",
      "phuongxa":"Xã Tân Việt",
      "quanhuyen":"10707"
   },
   {
      "id":"1070717",
      "phuongxa":"Xã Quyết Thắng",
      "quanhuyen":"10707"
   },
   {
      "id":"1070721",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"10707"
   },
   {
      "id":"1070723",
      "phuongxa":"Xã Thanh Hải",
      "quanhuyen":"10707"
   },
   {
      "id":"1070725",
      "phuongxa":"Xã An Lương",
      "quanhuyen":"10707"
   },
   {
      "id":"1070727",
      "phuongxa":"Xã Phượng Hoàng",
      "quanhuyen":"10707"
   },
   {
      "id":"1070729",
      "phuongxa":"Xã Thanh Khê",
      "quanhuyen":"10707"
   },
   {
      "id":"1070733",
      "phuongxa":"Xã Thanh Xuân",
      "quanhuyen":"10707"
   },
   {
      "id":"1070735",
      "phuongxa":"Xã Thanh Thủy",
      "quanhuyen":"10707"
   },
   {
      "id":"1070737",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"10707"
   },
   {
      "id":"1070741",
      "phuongxa":"Xã Trường Thành",
      "quanhuyen":"10707"
   },
   {
      "id":"1070745",
      "phuongxa":"Xã Thanh Cường",
      "quanhuyen":"10707"
   },
   {
      "id":"1070747",
      "phuongxa":"Xã Thanh Hồng",
      "quanhuyen":"10707"
   },
   {
      "id":"1070749",
      "phuongxa":"Xã Vĩnh Lập",
      "quanhuyen":"10707"
   },
   {
      "id":"1070900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10709"
   },
   {
      "id":"1070901",
      "phuongxa":"Thị trấn An Lưu",
      "quanhuyen":"10709"
   },
   {
      "id":"1070905",
      "phuongxa":"Xã Hiến Thành",
      "quanhuyen":"10709"
   },
   {
      "id":"1070907",
      "phuongxa":"Xã Thái Thịnh",
      "quanhuyen":"10709"
   },
   {
      "id":"1070909",
      "phuongxa":"Xã Long Xuyên",
      "quanhuyen":"10709"
   },
   {
      "id":"1070911",
      "phuongxa":"Xã Hiệp An",
      "quanhuyen":"10709"
   },
   {
      "id":"1070913",
      "phuongxa":"Xã An Phụ",
      "quanhuyen":"10709"
   },
   {
      "id":"1070915",
      "phuongxa":"Xã Thượng Quận",
      "quanhuyen":"10709"
   },
   {
      "id":"1070917",
      "phuongxa":"Xã Hiệp Hòa",
      "quanhuyen":"10709"
   },
   {
      "id":"1070919",
      "phuongxa":"Xã Thăng Long",
      "quanhuyen":"10709"
   },
   {
      "id":"1070921",
      "phuongxa":"Xã Lạc Long",
      "quanhuyen":"10709"
   },
   {
      "id":"1070923",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"10709"
   },
   {
      "id":"1070925",
      "phuongxa":"Xã Phúc Thành B",
      "quanhuyen":"10709"
   },
   {
      "id":"1070927",
      "phuongxa":"Xã Lê Ninh",
      "quanhuyen":"10709"
   },
   {
      "id":"1070929",
      "phuongxa":"Xã Bạch Đằng",
      "quanhuyen":"10709"
   },
   {
      "id":"1070931",
      "phuongxa":"Xã Thất Hùng",
      "quanhuyen":"10709"
   },
   {
      "id":"1070933",
      "phuongxa":"Xã Thái Sơn",
      "quanhuyen":"10709"
   },
   {
      "id":"1070935",
      "phuongxa":"Xã An Sinh",
      "quanhuyen":"10709"
   },
   {
      "id":"1070937",
      "phuongxa":"Xã Phạm Mệnh",
      "quanhuyen":"10709"
   },
   {
      "id":"1070939",
      "phuongxa":"Xã Hiệp Sơn",
      "quanhuyen":"10709"
   },
   {
      "id":"1070941",
      "phuongxa":"Xã Hoành Sơn",
      "quanhuyen":"10709"
   },
   {
      "id":"1070945",
      "phuongxa":"Xã Phú Thứ",
      "quanhuyen":"10709"
   },
   {
      "id":"1070947",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"10709"
   },
   {
      "id":"1070949",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"10709"
   },
   {
      "id":"1071100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10711"
   },
   {
      "id":"1071101",
      "phuongxa":"Thị trấn Phú Thái",
      "quanhuyen":"10711"
   },
   {
      "id":"1071103",
      "phuongxa":"Xã Lai Vu",
      "quanhuyen":"10711"
   },
   {
      "id":"1071105",
      "phuongxa":"Xã Cộng Hòa",
      "quanhuyen":"10711"
   },
   {
      "id":"1071107",
      "phuongxa":"Xã Thượng Vũ",
      "quanhuyen":"10711"
   },
   {
      "id":"1071109",
      "phuongxa":"Xã Cổ Dũng",
      "quanhuyen":"10711"
   },
   {
      "id":"1071111",
      "phuongxa":"Xã Việt Hưng",
      "quanhuyen":"10711"
   },
   {
      "id":"1071113",
      "phuongxa":"Xã Tuấn Hưng",
      "quanhuyen":"10711"
   },
   {
      "id":"1071115",
      "phuongxa":"Xã Kim Xuyên",
      "quanhuyen":"10711"
   },
   {
      "id":"1071117",
      "phuongxa":"Xã Phúc Thành A",
      "quanhuyen":"10711"
   },
   {
      "id":"1071119",
      "phuongxa":"Xã Kim Lương",
      "quanhuyen":"10711"
   },
   {
      "id":"1071121",
      "phuongxa":"Xã Kim Khê",
      "quanhuyen":"10711"
   },
   {
      "id":"1071123",
      "phuongxa":"Xã Kim Anh",
      "quanhuyen":"10711"
   },
   {
      "id":"1071125",
      "phuongxa":"Xã Ngũ Phúc",
      "quanhuyen":"10711"
   },
   {
      "id":"1071127",
      "phuongxa":"Xã Kim Đính",
      "quanhuyen":"10711"
   },
   {
      "id":"1071131",
      "phuongxa":"Xã Bình Dân",
      "quanhuyen":"10711"
   },
   {
      "id":"1071133",
      "phuongxa":"Xã Cẩm La",
      "quanhuyen":"10711"
   },
   {
      "id":"1071135",
      "phuongxa":"Xã Liên Hòa",
      "quanhuyen":"10711"
   },
   {
      "id":"1071137",
      "phuongxa":"Xã Đồng Gia",
      "quanhuyen":"10711"
   },
   {
      "id":"1071139",
      "phuongxa":"Xã Đại Đức",
      "quanhuyen":"10711"
   },
   {
      "id":"1071141",
      "phuongxa":"Xã Tam Kỳ",
      "quanhuyen":"10711"
   },
   {
      "id":"1071300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10713"
   },
   {
      "id":"1071301",
      "phuongxa":"Thị trấn Gia Lộc",
      "quanhuyen":"10713"
   },
   {
      "id":"1071303",
      "phuongxa":"Xã Thạch Khôi",
      "quanhuyen":"10713"
   },
   {
      "id":"1071305",
      "phuongxa":"Xã Liên Hồng",
      "quanhuyen":"10713"
   },
   {
      "id":"1071309",
      "phuongxa":"Xã Trùng Khánh",
      "quanhuyen":"10713"
   },
   {
      "id":"1071311",
      "phuongxa":"Xã Yết Kiêu",
      "quanhuyen":"10713"
   },
   {
      "id":"1071313",
      "phuongxa":"Xã Gia Hòa",
      "quanhuyen":"10713"
   },
   {
      "id":"1071315",
      "phuongxa":"Xã Lê Lợi",
      "quanhuyen":"10713"
   },
   {
      "id":"1071317",
      "phuongxa":"Xã Đoàn Thượng",
      "quanhuyen":"10713"
   },
   {
      "id":"1071321",
      "phuongxa":"Xã Hồng Hưng",
      "quanhuyen":"10713"
   },
   {
      "id":"1071323",
      "phuongxa":"Xã Hoàng Diệu",
      "quanhuyen":"10713"
   },
   {
      "id":"1071325",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"10713"
   },
   {
      "id":"1071329",
      "phuongxa":"Xã Gia Xuyên",
      "quanhuyen":"10713"
   },
   {
      "id":"1071331",
      "phuongxa":"Xã Gia Tân",
      "quanhuyen":"10713"
   },
   {
      "id":"1071335",
      "phuongxa":"Xã Gia Khánh",
      "quanhuyen":"10713"
   },
   {
      "id":"1071337",
      "phuongxa":"Xã Thống Kênh",
      "quanhuyen":"10713"
   },
   {
      "id":"1071339",
      "phuongxa":"Xã Đồng Quang",
      "quanhuyen":"10713"
   },
   {
      "id":"1071341",
      "phuongxa":"Xã Đức Xương",
      "quanhuyen":"10713"
   },
   {
      "id":"1071343",
      "phuongxa":"Xã Quang Minh",
      "quanhuyen":"10713"
   },
   {
      "id":"1071347",
      "phuongxa":"Xã Phạm Trấn",
      "quanhuyen":"10713"
   },
   {
      "id":"1071349",
      "phuongxa":"Xã Phương Hưng",
      "quanhuyen":"10713"
   },
   {
      "id":"1071500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10715"
   },
   {
      "id":"1071501",
      "phuongxa":"Thị trấn Tứ Kỳ",
      "quanhuyen":"10715"
   },
   {
      "id":"1071503",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"10715"
   },
   {
      "id":"1071505",
      "phuongxa":"Xã Kỳ Sơn",
      "quanhuyen":"10715"
   },
   {
      "id":"1071507",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"10715"
   },
   {
      "id":"1071509",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"10715"
   },
   {
      "id":"1071511",
      "phuongxa":"Xã Tái Sơn",
      "quanhuyen":"10715"
   },
   {
      "id":"1071513",
      "phuongxa":"Xã Quang Phục",
      "quanhuyen":"10715"
   },
   {
      "id":"1071515",
      "phuongxa":"Xã Bình Lăng",
      "quanhuyen":"10715"
   },
   {
      "id":"1071519",
      "phuongxa":"Xã Đông Kỳ",
      "quanhuyen":"10715"
   },
   {
      "id":"1071521",
      "phuongxa":"Xã Tây Kỳ",
      "quanhuyen":"10715"
   },
   {
      "id":"1071523",
      "phuongxa":"Xã Tứ Xuyên",
      "quanhuyen":"10715"
   },
   {
      "id":"1071525",
      "phuongxa":"Xã Văn Tố",
      "quanhuyen":"10715"
   },
   {
      "id":"1071527",
      "phuongxa":"Xã Phượng Kỳ",
      "quanhuyen":"10715"
   },
   {
      "id":"1071529",
      "phuongxa":"Xã Cộng Lạc",
      "quanhuyen":"10715"
   },
   {
      "id":"1071531",
      "phuongxa":"Xã An Thanh",
      "quanhuyen":"10715"
   },
   {
      "id":"1071533",
      "phuongxa":"Xã Tiên Động",
      "quanhuyen":"10715"
   },
   {
      "id":"1071535",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"10715"
   },
   {
      "id":"1071537",
      "phuongxa":"Xã Nguyên Giáp",
      "quanhuyen":"10715"
   },
   {
      "id":"1071539",
      "phuongxa":"Xã Hà Thanh",
      "quanhuyen":"10715"
   },
   {
      "id":"1071541",
      "phuongxa":"Xã Hà Kỳ",
      "quanhuyen":"10715"
   },
   {
      "id":"1071543",
      "phuongxa":"Xã Tân Kỳ",
      "quanhuyen":"10715"
   },
   {
      "id":"1071545",
      "phuongxa":"Xã Đại Hợp",
      "quanhuyen":"10715"
   },
   {
      "id":"1071547",
      "phuongxa":"Xã Quảng Nghiệp",
      "quanhuyen":"10715"
   },
   {
      "id":"1071549",
      "phuongxa":"Xã Dân Chủ",
      "quanhuyen":"10715"
   },
   {
      "id":"1071551",
      "phuongxa":"Xã Minh Đức",
      "quanhuyen":"10715"
   },
   {
      "id":"1071553",
      "phuongxa":"Xã Quang Khải",
      "quanhuyen":"10715"
   },
   {
      "id":"1071700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10717"
   },
   {
      "id":"1071703",
      "phuongxa":"Xã Cẩm Hưng",
      "quanhuyen":"10717"
   },
   {
      "id":"1071705",
      "phuongxa":"Xã Ngọc Liên",
      "quanhuyen":"10717"
   },
   {
      "id":"1071707",
      "phuongxa":"Xã Kim Giang",
      "quanhuyen":"10717"
   },
   {
      "id":"1071709",
      "phuongxa":"Xã Thạch Lỗi",
      "quanhuyen":"10717"
   },
   {
      "id":"1071711",
      "phuongxa":"Xã Cẩm Sơn",
      "quanhuyen":"10717"
   },
   {
      "id":"1071713",
      "phuongxa":"Xã Cẩm Định",
      "quanhuyen":"10717"
   },
   {
      "id":"1071715",
      "phuongxa":"Xã Cẩm Hoàng",
      "quanhuyen":"10717"
   },
   {
      "id":"1071717",
      "phuongxa":"Xã Cẩm Vũ",
      "quanhuyen":"10717"
   },
   {
      "id":"1071719",
      "phuongxa":"Xã Cẩm Văn",
      "quanhuyen":"10717"
   },
   {
      "id":"1071721",
      "phuongxa":"Xã Đức Chính",
      "quanhuyen":"10717"
   },
   {
      "id":"1071723",
      "phuongxa":"Xã Cao An",
      "quanhuyen":"10717"
   },
   {
      "id":"1071725",
      "phuongxa":"Thị trấn Lai Cách",
      "quanhuyen":"10717"
   },
   {
      "id":"1071727",
      "phuongxa":"Xã Cẩm Đoài",
      "quanhuyen":"10717"
   },
   {
      "id":"1071729",
      "phuongxa":"Xã Cẩm Đông",
      "quanhuyen":"10717"
   },
   {
      "id":"1071731",
      "phuongxa":"Xã Tân Trường",
      "quanhuyen":"10717"
   },
   {
      "id":"1071733",
      "phuongxa":"Xã Cẩm Phúc",
      "quanhuyen":"10717"
   },
   {
      "id":"1071735",
      "phuongxa":"Xã Cẩm Điền",
      "quanhuyen":"10717"
   },
   {
      "id":"1071737",
      "phuongxa":"Xã Lương Điền",
      "quanhuyen":"10717"
   },
   {
      "id":"1071900",
      "phuongxa":"Không xác định",
      "quanhuyen":"10719"
   },
   {
      "id":"1071901",
      "phuongxa":"Thị trấn Kẻ Sặt",
      "quanhuyen":"10719"
   },
   {
      "id":"1071903",
      "phuongxa":"Xã Hưng Thịnh",
      "quanhuyen":"10719"
   },
   {
      "id":"1071905",
      "phuongxa":"Xã Tráng Liệt",
      "quanhuyen":"10719"
   },
   {
      "id":"1071907",
      "phuongxa":"Xã Vĩnh Tuy",
      "quanhuyen":"10719"
   },
   {
      "id":"1071909",
      "phuongxa":"Xã Vĩnh Hồng",
      "quanhuyen":"10719"
   },
   {
      "id":"1071915",
      "phuongxa":"Xã Tân Việt",
      "quanhuyen":"10719"
   },
   {
      "id":"1071917",
      "phuongxa":"Xã Hồng Khê",
      "quanhuyen":"10719"
   },
   {
      "id":"1071919",
      "phuongxa":"Xã Cổ Bi",
      "quanhuyen":"10719"
   },
   {
      "id":"1071921",
      "phuongxa":"Xã Nhân Quyền",
      "quanhuyen":"10719"
   },
   {
      "id":"1071923",
      "phuongxa":"Xã Bình Xuyên",
      "quanhuyen":"10719"
   },
   {
      "id":"1071925",
      "phuongxa":"Xã Thái Học",
      "quanhuyen":"10719"
   },
   {
      "id":"1071929",
      "phuongxa":"Xã Tân Hồng",
      "quanhuyen":"10719"
   },
   {
      "id":"1071931",
      "phuongxa":"Xã Thái Hòa",
      "quanhuyen":"10719"
   },
   {
      "id":"1071933",
      "phuongxa":"Xã Thái Dương",
      "quanhuyen":"10719"
   },
   {
      "id":"1071935",
      "phuongxa":"Xã Thúc Kháng",
      "quanhuyen":"10719"
   },
   {
      "id":"1072100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10721"
   },
   {
      "id":"1072103",
      "phuongxa":"Xã Thanh Giang",
      "quanhuyen":"10721"
   },
   {
      "id":"1072105",
      "phuongxa":"Xã Diên Hồng",
      "quanhuyen":"10721"
   },
   {
      "id":"1072107",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"10721"
   },
   {
      "id":"1072109",
      "phuongxa":"Xã Chi Lăng Nam",
      "quanhuyen":"10721"
   },
   {
      "id":"1072111",
      "phuongxa":"Xã Chi Lăng Bắc",
      "quanhuyen":"10721"
   },
   {
      "id":"1072113",
      "phuongxa":"Xã Ngũ Hùng",
      "quanhuyen":"10721"
   },
   {
      "id":"1072117",
      "phuongxa":"Xã Cao Thắng",
      "quanhuyen":"10721"
   },
   {
      "id":"1072119",
      "phuongxa":"Xã Phạm Kha",
      "quanhuyen":"10721"
   },
   {
      "id":"1072121",
      "phuongxa":"Xã Hùng Sơn",
      "quanhuyen":"10721"
   },
   {
      "id":"1072123",
      "phuongxa":"Xã Đoàn Tùng",
      "quanhuyen":"10721"
   },
   {
      "id":"1072125",
      "phuongxa":"Xã Lam Sơn",
      "quanhuyen":"10721"
   },
   {
      "id":"1072127",
      "phuongxa":"Xã Thanh Tùng",
      "quanhuyen":"10721"
   },
   {
      "id":"1072129",
      "phuongxa":"Xã Lê Hồng",
      "quanhuyen":"10721"
   },
   {
      "id":"1072131",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"10721"
   },
   {
      "id":"1072133",
      "phuongxa":"Xã Ngô Quyền",
      "quanhuyen":"10721"
   },
   {
      "id":"1072135",
      "phuongxa":"Xã Tân Trào",
      "quanhuyen":"10721"
   },
   {
      "id":"1072137",
      "phuongxa":"Xã Hồng Quang",
      "quanhuyen":"10721"
   },
   {
      "id":"1072300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10723"
   },
   {
      "id":"1072301",
      "phuongxa":"Thị trấn Ninh Giang",
      "quanhuyen":"10723"
   },
   {
      "id":"1072303",
      "phuongxa":"Xã Hiệp Lực",
      "quanhuyen":"10723"
   },
   {
      "id":"1072305",
      "phuongxa":"Xã Hồng Dụ",
      "quanhuyen":"10723"
   },
   {
      "id":"1072307",
      "phuongxa":"Xã Đồng Tâm",
      "quanhuyen":"10723"
   },
   {
      "id":"1072311",
      "phuongxa":"Xã Ninh Thành",
      "quanhuyen":"10723"
   },
   {
      "id":"1072313",
      "phuongxa":"Xã Tân Hương",
      "quanhuyen":"10723"
   },
   {
      "id":"1072315",
      "phuongxa":"Xã Nghĩa An",
      "quanhuyen":"10723"
   },
   {
      "id":"1072317",
      "phuongxa":"Xã Quyết Thắng",
      "quanhuyen":"10723"
   },
   {
      "id":"1072319",
      "phuongxa":"Xã ứng Hoè",
      "quanhuyen":"10723"
   },
   {
      "id":"1072321",
      "phuongxa":"Xã Ninh Hòa",
      "quanhuyen":"10723"
   },
   {
      "id":"1072323",
      "phuongxa":"Xã Vạn Phúc",
      "quanhuyen":"10723"
   },
   {
      "id":"1072325",
      "phuongxa":"Xã Hồng Đức",
      "quanhuyen":"10723"
   },
   {
      "id":"1072327",
      "phuongxa":"Xã An Đức",
      "quanhuyen":"10723"
   },
   {
      "id":"1072329",
      "phuongxa":"Xã Đông Xuyên",
      "quanhuyen":"10723"
   },
   {
      "id":"1072331",
      "phuongxa":"Xã Ninh Hải",
      "quanhuyen":"10723"
   },
   {
      "id":"1072333",
      "phuongxa":"Xã Ninh Thọ",
      "quanhuyen":"10723"
   },
   {
      "id":"1072335",
      "phuongxa":"Xã Kiến Quốc",
      "quanhuyen":"10723"
   },
   {
      "id":"1072337",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"10723"
   },
   {
      "id":"1072339",
      "phuongxa":"Xã Hồng Phúc",
      "quanhuyen":"10723"
   },
   {
      "id":"1072341",
      "phuongxa":"Xã Hưng Long",
      "quanhuyen":"10723"
   },
   {
      "id":"1072343",
      "phuongxa":"Xã Hưng Thái",
      "quanhuyen":"10723"
   },
   {
      "id":"1072345",
      "phuongxa":"Xã Văn Hội",
      "quanhuyen":"10723"
   },
   {
      "id":"1072347",
      "phuongxa":"Xã Tân Quang",
      "quanhuyen":"10723"
   },
   {
      "id":"1072351",
      "phuongxa":"Xã Hoàng Hanh",
      "quanhuyen":"10723"
   },
   {
      "id":"1072353",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"10723"
   },
   {
      "id":"1072355",
      "phuongxa":"Xã Văn Giang",
      "quanhuyen":"10723"
   },
   {
      "id":"1090000",
      "phuongxa":"Không xác định",
      "quanhuyen":"10900"
   },
   {
      "id":"1090100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10901"
   },
   {
      "id":"1090101",
      "phuongxa":"Phường Lam Sơn",
      "quanhuyen":"10901"
   },
   {
      "id":"1090103",
      "phuongxa":"Phường Hiến Nam",
      "quanhuyen":"10901"
   },
   {
      "id":"1090105",
      "phuongxa":"Phường Lê Lợi",
      "quanhuyen":"10901"
   },
   {
      "id":"1090107",
      "phuongxa":"Phường Minh Khai",
      "quanhuyen":"10901"
   },
   {
      "id":"1090109",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"10901"
   },
   {
      "id":"1090111",
      "phuongxa":"Phường Hồng Châu",
      "quanhuyen":"10901"
   },
   {
      "id":"1090201",
      "phuongxa":"Thị trấn Như Quỳnh",
      "quanhuyen":"10902"
   },
   {
      "id":"1090203",
      "phuongxa":"Xã Lạc Đạo",
      "quanhuyen":"10902"
   },
   {
      "id":"1090205",
      "phuongxa":"Xã Chỉ Đạo",
      "quanhuyen":"10902"
   },
   {
      "id":"1090207",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"10902"
   },
   {
      "id":"1090209",
      "phuongxa":"Xã Việt Hưng",
      "quanhuyen":"10902"
   },
   {
      "id":"1090213",
      "phuongxa":"Xã Đình Dù",
      "quanhuyen":"10902"
   },
   {
      "id":"1090215",
      "phuongxa":"Xã Minh Hải",
      "quanhuyen":"10902"
   },
   {
      "id":"1090217",
      "phuongxa":"Xã Lương Tài",
      "quanhuyen":"10902"
   },
   {
      "id":"1090219",
      "phuongxa":"Xã Trưng Trắc",
      "quanhuyen":"10902"
   },
   {
      "id":"1090221",
      "phuongxa":"Xã Lạc Hồng",
      "quanhuyen":"10902"
   },
   {
      "id":"1090321",
      "phuongxa":"Xã Phan Đình Phùng",
      "quanhuyen":"10903"
   },
   {
      "id":"1090329",
      "phuongxa":"Xã Cẩm Xá",
      "quanhuyen":"10903"
   },
   {
      "id":"1090331",
      "phuongxa":"Xã Dương Quang",
      "quanhuyen":"10903"
   },
   {
      "id":"1090333",
      "phuongxa":"Xã Hòa Phong",
      "quanhuyen":"10903"
   },
   {
      "id":"1090345",
      "phuongxa":"Xã Dị Sử",
      "quanhuyen":"10903"
   },
   {
      "id":"1090347",
      "phuongxa":"Xã Bạch Sam",
      "quanhuyen":"10903"
   },
   {
      "id":"1090349",
      "phuongxa":"Xã Minh Đức",
      "quanhuyen":"10903"
   },
   {
      "id":"1090357",
      "phuongxa":"Xã Phùng Chí Kiên",
      "quanhuyen":"10903"
   },
   {
      "id":"1090359",
      "phuongxa":"Xã Xuân Dục",
      "quanhuyen":"10903"
   },
   {
      "id":"1090367",
      "phuongxa":"Xã Hưng Long",
      "quanhuyen":"10903"
   },
   {
      "id":"1090368",
      "phuongxa":"Trung Hưng",
      "quanhuyen":"10903"
   },
   {
      "id":"1090401",
      "phuongxa":"Thị trấn Yên Mỹ",
      "quanhuyen":"10904"
   },
   {
      "id":"1090403",
      "phuongxa":"Xã Giai Phạm",
      "quanhuyen":"10904"
   },
   {
      "id":"1090405",
      "phuongxa":"Xã Nghĩa Hiệp",
      "quanhuyen":"10904"
   },
   {
      "id":"1090409",
      "phuongxa":"Xã Ngọc Long",
      "quanhuyen":"10904"
   },
   {
      "id":"1090411",
      "phuongxa":"Xã Liêu Xá",
      "quanhuyen":"10904"
   },
   {
      "id":"1090413",
      "phuongxa":"Xã Hoàn Long",
      "quanhuyen":"10904"
   },
   {
      "id":"1090415",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"10904"
   },
   {
      "id":"1090417",
      "phuongxa":"Xã Thanh Long",
      "quanhuyen":"10904"
   },
   {
      "id":"1090419",
      "phuongxa":"Xã Yên Phú",
      "quanhuyen":"10904"
   },
   {
      "id":"1090421",
      "phuongxa":"Xã Việt Cường",
      "quanhuyen":"10904"
   },
   {
      "id":"1090425",
      "phuongxa":"Xã Yên Hòa",
      "quanhuyen":"10904"
   },
   {
      "id":"1111115",
      "phuongxa":"Xã Mỹ Thọ",
      "quanhuyen":"11111"
   },
   {
      "id":"1111117",
      "phuongxa":"Xã An Mỹ",
      "quanhuyen":"11111"
   },
   {
      "id":"1111119",
      "phuongxa":"Xã La Sơn",
      "quanhuyen":"11111"
   },
   {
      "id":"1111121",
      "phuongxa":"Xã An Đổ",
      "quanhuyen":"11111"
   },
   {
      "id":"1111123",
      "phuongxa":"Xã Tiêu Động",
      "quanhuyen":"11111"
   },
   {
      "id":"1111125",
      "phuongxa":"Xã Trung Lương",
      "quanhuyen":"11111"
   },
   {
      "id":"1111127",
      "phuongxa":"Xã Bối Cầu",
      "quanhuyen":"11111"
   },
   {
      "id":"1111129",
      "phuongxa":"Xã An Nội",
      "quanhuyen":"11111"
   },
   {
      "id":"1111131",
      "phuongxa":"Xã Bồ Đề",
      "quanhuyen":"11111"
   },
   {
      "id":"1111133",
      "phuongxa":"Xã An Ninh",
      "quanhuyen":"11111"
   },
   {
      "id":"1111135",
      "phuongxa":"Xã Hưng Công",
      "quanhuyen":"11111"
   },
   {
      "id":"1111137",
      "phuongxa":"Xã Ngọc Lũ",
      "quanhuyen":"11111"
   },
   {
      "id":"1111139",
      "phuongxa":"Xã Vụ Bản",
      "quanhuyen":"11111"
   },
   {
      "id":"1111141",
      "phuongxa":"Xã An Lão",
      "quanhuyen":"11111"
   },
   {
      "id":"1130000",
      "phuongxa":"Không xác định",
      "quanhuyen":"11300"
   },
   {
      "id":"1130100",
      "phuongxa":"Không xác định",
      "quanhuyen":"11301"
   },
   {
      "id":"1130101",
      "phuongxa":"Phường Trần Tế Xương",
      "quanhuyen":"11301"
   },
   {
      "id":"1130103",
      "phuongxa":"Phường Hạ Long",
      "quanhuyen":"11301"
   },
   {
      "id":"1130105",
      "phuongxa":"Phường Vị Xuyên",
      "quanhuyen":"11301"
   },
   {
      "id":"1130109",
      "phuongxa":"Phường Nguyễn Du",
      "quanhuyen":"11301"
   },
   {
      "id":"1130111",
      "phuongxa":"Phường Bà Triệu",
      "quanhuyen":"11301"
   },
   {
      "id":"1130113",
      "phuongxa":"Phường Cửa Bắc",
      "quanhuyen":"11301"
   },
   {
      "id":"1130115",
      "phuongxa":"Phường Trần Hưng Đạo",
      "quanhuyen":"11301"
   },
   {
      "id":"1130117",
      "phuongxa":"Phường Phan Đình Phùng",
      "quanhuyen":"11301"
   },
   {
      "id":"1130119",
      "phuongxa":"Phường Ngô Quyền",
      "quanhuyen":"11301"
   },
   {
      "id":"1130121",
      "phuongxa":"Phường Năng Tĩnh",
      "quanhuyen":"11301"
   },
   {
      "id":"1130123",
      "phuongxa":"Phường Trần Đăng Ninh",
      "quanhuyen":"11301"
   },
   {
      "id":"1130125",
      "phuongxa":"Phường Trường Thi",
      "quanhuyen":"11301"
   },
   {
      "id":"1130127",
      "phuongxa":"Phường Văn Miếu",
      "quanhuyen":"11301"
   },
   {
      "id":"1130129",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"11301"
   },
   {
      "id":"1130131",
      "phuongxa":"Xã Mỹ Xá",
      "quanhuyen":"11301"
   },
   {
      "id":"1130133",
      "phuongxa":"Xã Lộc Hạ",
      "quanhuyen":"11301"
   },
   {
      "id":"1130135",
      "phuongxa":"Xã Lộc Vượng",
      "quanhuyen":"11301"
   },
   {
      "id":"1130137",
      "phuongxa":"Xã Lộc An",
      "quanhuyen":"11301"
   },
   {
      "id":"1130139",
      "phuongxa":"Xã Lộc Hoà",
      "quanhuyen":"11301"
   },
   {
      "id":"1130141",
      "phuongxa":"Xã Nam Phong",
      "quanhuyen":"11301"
   },
   {
      "id":"1130143",
      "phuongxa":"Xã Nam Vân",
      "quanhuyen":"11301"
   },
   {
      "id":"1130300",
      "phuongxa":"Không xác định",
      "quanhuyen":"11303"
   },
   {
      "id":"1130301",
      "phuongxa":"Thị trấn Gôi",
      "quanhuyen":"11303"
   },
   {
      "id":"1130303",
      "phuongxa":"Xã Đại Thắng",
      "quanhuyen":"11303"
   },
   {
      "id":"1130305",
      "phuongxa":"Xã Hợp Hưng",
      "quanhuyen":"11303"
   },
   {
      "id":"1130309",
      "phuongxa":"Xã Vĩnh Hào",
      "quanhuyen":"11303"
   },
   {
      "id":"1130313",
      "phuongxa":"Xã Cộng Hòa",
      "quanhuyen":"11303"
   },
   {
      "id":"1130315",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"11303"
   },
   {
      "id":"1130317",
      "phuongxa":"Xã Đại An",
      "quanhuyen":"11303"
   },
   {
      "id":"1130319",
      "phuongxa":"Xã Tân Khánh",
      "quanhuyen":"11303"
   },
   {
      "id":"1130321",
      "phuongxa":"Xã Hiển Khánh",
      "quanhuyen":"11303"
   },
   {
      "id":"1130325",
      "phuongxa":"Xã Liên Minh",
      "quanhuyen":"11303"
   },
   {
      "id":"1130327",
      "phuongxa":"Xã Minh Thuận",
      "quanhuyen":"11303"
   },
   {
      "id":"1130329",
      "phuongxa":"Xã Kim Thái",
      "quanhuyen":"11303"
   },
   {
      "id":"1130331",
      "phuongxa":"Xã Tam Thanh",
      "quanhuyen":"11303"
   },
   {
      "id":"1130333",
      "phuongxa":"Xã Thành Lợi",
      "quanhuyen":"11303"
   },
   {
      "id":"1130500",
      "phuongxa":"Không xác định",
      "quanhuyen":"11305"
   },
   {
      "id":"1130501",
      "phuongxa":"Xã Mỹ Trung",
      "quanhuyen":"11305"
   },
   {
      "id":"1130503",
      "phuongxa":"Xã Mỹ Phúc",
      "quanhuyen":"11305"
   },
   {
      "id":"1130505",
      "phuongxa":"Xã Mỹ Hưng",
      "quanhuyen":"11305"
   },
   {
      "id":"1130507",
      "phuongxa":"Xã Mỹ Hà",
      "quanhuyen":"11305"
   },
   {
      "id":"1130509",
      "phuongxa":"Xã Mỹ Thắng",
      "quanhuyen":"11305"
   },
   {
      "id":"1130513",
      "phuongxa":"Xã Mỹ Tiến",
      "quanhuyen":"11305"
   },
   {
      "id":"1130515",
      "phuongxa":"Xã Mỹ Thuận",
      "quanhuyen":"11305"
   },
   {
      "id":"1130517",
      "phuongxa":"Xã Mỹ Thành",
      "quanhuyen":"11305"
   },
   {
      "id":"1130519",
      "phuongxa":"Xã Mỹ Tân",
      "quanhuyen":"11305"
   },
   {
      "id":"1130700",
      "phuongxa":"Không xác định",
      "quanhuyen":"11307"
   },
   {
      "id":"1130701",
      "phuongxa":"Thị trấn Lâm",
      "quanhuyen":"11307"
   },
   {
      "id":"1130703",
      "phuongxa":"Xã Yên Trung",
      "quanhuyen":"11307"
   },
   {
      "id":"1130705",
      "phuongxa":"Xã Yên Thành",
      "quanhuyen":"11307"
   },
   {
      "id":"1130707",
      "phuongxa":"Xã Yên Thọ",
      "quanhuyen":"11307"
   },
   {
      "id":"1130709",
      "phuongxa":"Xã Yên Phương",
      "quanhuyen":"11307"
   },
   {
      "id":"1130711",
      "phuongxa":"Xã Yên Nghĩa",
      "quanhuyen":"11307"
   },
   {
      "id":"1130713",
      "phuongxa":"Xã Yên Tân",
      "quanhuyen":"11307"
   },
   {
      "id":"1150789",
      "phuongxa":"Xã Thái Thuỷ",
      "quanhuyen":"11507"
   },
   {
      "id":"1150791",
      "phuongxa":"Xã Thái Xuyên",
      "quanhuyen":"11507"
   },
   {
      "id":"1150793",
      "phuongxa":"Xã Thái Thượng",
      "quanhuyen":"11507"
   },
   {
      "id":"1150795",
      "phuongxa":"Xã Thái Hoà",
      "quanhuyen":"11507"
   },
   {
      "id":"1150900",
      "phuongxa":"Không xác định",
      "quanhuyen":"11509"
   },
   {
      "id":"1150901",
      "phuongxa":"Thị trấn Đông Hưng",
      "quanhuyen":"11509"
   },
   {
      "id":"1150903",
      "phuongxa":"Xã An Châu",
      "quanhuyen":"11509"
   },
   {
      "id":"1150905",
      "phuongxa":"Xã Đô Lương",
      "quanhuyen":"11509"
   },
   {
      "id":"1150907",
      "phuongxa":"Xã Liên Giang",
      "quanhuyen":"11509"
   },
   {
      "id":"1150909",
      "phuongxa":"Xã Đông Phương",
      "quanhuyen":"11509"
   },
   {
      "id":"1150913",
      "phuongxa":"Xã Lô Giang",
      "quanhuyen":"11509"
   },
   {
      "id":"1150915",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"11509"
   },
   {
      "id":"1150917",
      "phuongxa":"Xã Mê Linh",
      "quanhuyen":"11509"
   },
   {
      "id":"1150919",
      "phuongxa":"Xã Phú Lương",
      "quanhuyen":"11509"
   },
   {
      "id":"1150921",
      "phuongxa":"Xã Đông La",
      "quanhuyen":"11509"
   },
   {
      "id":"1150923",
      "phuongxa":"Xã Đông Cường",
      "quanhuyen":"11509"
   },
   {
      "id":"1150925",
      "phuongxa":"Xã Đông Xá",
      "quanhuyen":"11509"
   },
   {
      "id":"1150927",
      "phuongxa":"Xã Hợp Tiến",
      "quanhuyen":"11509"
   },
   {
      "id":"1150929",
      "phuongxa":"Xã Phong Châu",
      "quanhuyen":"11509"
   },
   {
      "id":"1150931",
      "phuongxa":"Xã Nguyên Xá",
      "quanhuyen":"11509"
   },
   {
      "id":"1150933",
      "phuongxa":"Xã Hồng Việt",
      "quanhuyen":"11509"
   },
   {
      "id":"1150935",
      "phuongxa":"Xã Thăng Long",
      "quanhuyen":"11509"
   },
   {
      "id":"1150937",
      "phuongxa":"Xã Chương Dương",
      "quanhuyen":"11509"
   },
   {
      "id":"1150939",
      "phuongxa":"Xã Đông Hợp",
      "quanhuyen":"11509"
   },
   {
      "id":"1150941",
      "phuongxa":"Xã Đông Hà",
      "quanhuyen":"11509"
   },
   {
      "id":"1150943",
      "phuongxa":"Xã Đông Giang",
      "quanhuyen":"11509"
   },
   {
      "id":"1150945",
      "phuongxa":"Xã Đông Kinh",
      "quanhuyen":"11509"
   },
   {
      "id":"1150947",
      "phuongxa":"Xã Hồng Châu",
      "quanhuyen":"11509"
   },
   {
      "id":"1150949",
      "phuongxa":"Xã Hoa Lư",
      "quanhuyen":"11509"
   },
   {
      "id":"1150953",
      "phuongxa":"Xã Phú Châu",
      "quanhuyen":"11509"
   },
   {
      "id":"1150955",
      "phuongxa":"Xã Đông Các",
      "quanhuyen":"11509"
   },
   {
      "id":"1150957",
      "phuongxa":"Xã Bạch Đằng",
      "quanhuyen":"11509"
   },
   {
      "id":"1150959",
      "phuongxa":"Xã Hoa Nam",
      "quanhuyen":"11509"
   },
   {
      "id":"1150961",
      "phuongxa":"Xã Đồng Phú",
      "quanhuyen":"11509"
   },
   {
      "id":"1150963",
      "phuongxa":"Xã Trọng Quan",
      "quanhuyen":"11509"
   },
   {
      "id":"1150965",
      "phuongxa":"Xã Đông Động",
      "quanhuyen":"11509"
   },
   {
      "id":"1150967",
      "phuongxa":"Xã Đông Vinh",
      "quanhuyen":"11509"
   },
   {
      "id":"1150969",
      "phuongxa":"Xã Đông Tân",
      "quanhuyen":"11509"
   },
   {
      "id":"1150971",
      "phuongxa":"Xã Đông Xuân",
      "quanhuyen":"11509"
   },
   {
      "id":"1150973",
      "phuongxa":"Xã Đông Quang",
      "quanhuyen":"11509"
   },
   {
      "id":"1150975",
      "phuongxa":"Xã Hồng Giang",
      "quanhuyen":"11509"
   },
   {
      "id":"1150977",
      "phuongxa":"Xã Đông Dương",
      "quanhuyen":"11509"
   },
   {
      "id":"1150981",
      "phuongxa":"Xã Đông á",
      "quanhuyen":"11509"
   },
   {
      "id":"1150983",
      "phuongxa":"Xã Đông Lĩnh",
      "quanhuyen":"11509"
   },
   {
      "id":"1150985",
      "phuongxa":"Xã Đông Hoàng",
      "quanhuyen":"11509"
   },
   {
      "id":"1150987",
      "phuongxa":"Xã Đông Thọ",
      "quanhuyen":"11509"
   },
   {
      "id":"1150989",
      "phuongxa":"Xã Đông Mỹ",
      "quanhuyen":"11509"
   },
   {
      "id":"1151100",
      "phuongxa":"Không xác định",
      "quanhuyen":"11511"
   },
   {
      "id":"1151103",
      "phuongxa":"Xã Hồng Lý",
      "quanhuyen":"11511"
   },
   {
      "id":"1151105",
      "phuongxa":"Xã Đồng Thanh",
      "quanhuyen":"11511"
   },
   {
      "id":"1151107",
      "phuongxa":"Xã Xuân Hòa",
      "quanhuyen":"11511"
   },
   {
      "id":"1151111",
      "phuongxa":"Xã Phúc Thành",
      "quanhuyen":"11511"
   },
   {
      "id":"1151113",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"11511"
   },
   {
      "id":"1151115",
      "phuongxa":"Xã Song Lãng",
      "quanhuyen":"11511"
   },
   {
      "id":"1151117",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"11511"
   },
   {
      "id":"1151119",
      "phuongxa":"Xã Việt Hùng",
      "quanhuyen":"11511"
   },
   {
      "id":"1151123",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"11511"
   },
   {
      "id":"1151125",
      "phuongxa":"Xã Minh Khai",
      "quanhuyen":"11511"
   },
   {
      "id":"1151127",
      "phuongxa":"Xã Dũng Nghĩa",
      "quanhuyen":"11511"
   },
   {
      "id":"1151129",
      "phuongxa":"Xã Minh Quang",
      "quanhuyen":"11511"
   },
   {
      "id":"1151131",
      "phuongxa":"Xã Tam Quang",
      "quanhuyen":"11511"
   },
   {
      "id":"1151135",
      "phuongxa":"Xã Tự Tân",
      "quanhuyen":"11511"
   },
   {
      "id":"1151137",
      "phuongxa":"Xã Bách Thuận",
      "quanhuyen":"11511"
   },
   {
      "id":"1151139",
      "phuongxa":"Xã Song An",
      "quanhuyen":"11511"
   },
   {
      "id":"1151141",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"11511"
   },
   {
      "id":"1151143",
      "phuongxa":"Xã Trung An",
      "quanhuyen":"11511"
   },
   {
      "id":"1151145",
      "phuongxa":"Xã Vũ Hội",
      "quanhuyen":"11511"
   },
   {
      "id":"1151147",
      "phuongxa":"Xã Nguyên Xá",
      "quanhuyen":"11511"
   },
   {
      "id":"1151149",
      "phuongxa":"Xã Việt Thuận",
      "quanhuyen":"11511"
   },
   {
      "id":"1151153",
      "phuongxa":"Xã Vũ Đoài",
      "quanhuyen":"11511"
   },
   {
      "id":"1151155",
      "phuongxa":"Xã Vũ Tiến",
      "quanhuyen":"11511"
   },
   {
      "id":"1151157",
      "phuongxa":"Xã Vũ Vân",
      "quanhuyen":"11511"
   },
   {
      "id":"1151159",
      "phuongxa":"Xã Duy Nhất",
      "quanhuyen":"11511"
   },
   {
      "id":"1151161",
      "phuongxa":"Xã Hồng Phong",
      "quanhuyen":"11511"
   },
   {
      "id":"1151300",
      "phuongxa":"Không xác định",
      "quanhuyen":"11513"
   },
   {
      "id":"1151301",
      "phuongxa":"Thị trấn Kiến Xương",
      "quanhuyen":"11513"
   },
   {
      "id":"1151303",
      "phuongxa":"Xã Vũ Đông",
      "quanhuyen":"11513"
   },
   {
      "id":"1151305",
      "phuongxa":"Xã Vũ Tây",
      "quanhuyen":"11513"
   },
   {
      "id":"1151307",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"11513"
   },
   {
      "id":"1151309",
      "phuongxa":"Xã Vũ Lạc",
      "quanhuyen":"11513"
   },
   {
      "id":"1151311",
      "phuongxa":"Xã Vũ Sơn",
      "quanhuyen":"11513"
   },
   {
      "id":"1151313",
      "phuongxa":"Xã Vũ Lễ",
      "quanhuyen":"11513"
   },
   {
      "id":"1151315",
      "phuongxa":"Xã Vũ An",
      "quanhuyen":"11513"
   },
   {
      "id":"1151317",
      "phuongxa":"Xã Trà Giang",
      "quanhuyen":"11513"
   },
   {
      "id":"1151319",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"11513"
   },
   {
      "id":"1151321",
      "phuongxa":"Xã Quốc Tuấn",
      "quanhuyen":"11513"
   },
   {
      "id":"1151323",
      "phuongxa":"Xã Bình Nguyên",
      "quanhuyen":"11513"
   },
   {
      "id":"1151327",
      "phuongxa":"Xã Lê Lợi",
      "quanhuyen":"11513"
   },
   {
      "id":"1151329",
      "phuongxa":"Xã Nam Cao",
      "quanhuyen":"11513"
   },
   {
      "id":"1151331",
      "phuongxa":"Xã Thanh Tân",
      "quanhuyen":"11513"
   },
   {
      "id":"1151333",
      "phuongxa":"Xã Đình Phùng",
      "quanhuyen":"11513"
   },
   {
      "id":"1151335",
      "phuongxa":"Xã Vũ Ninh",
      "quanhuyen":"11513"
   },
   {
      "id":"1151337",
      "phuongxa":"Xã Vũ Quí",
      "quanhuyen":"11513"
   },
   {
      "id":"1151339",
      "phuongxa":"Xã Vũ Trung",
      "quanhuyen":"11513"
   },
   {
      "id":"1151341",
      "phuongxa":"Xã Vũ Thắng",
      "quanhuyen":"11513"
   },
   {
      "id":"1151343",
      "phuongxa":"Xã Vũ Hòa",
      "quanhuyen":"11513"
   },
   {
      "id":"1151345",
      "phuongxa":"Xã Vũ Công",
      "quanhuyen":"11513"
   },
   {
      "id":"1151347",
      "phuongxa":"Xã Vũ Bình",
      "quanhuyen":"11513"
   },
   {
      "id":"1151349",
      "phuongxa":"Xã Quang Bình",
      "quanhuyen":"11513"
   },
   {
      "id":"1151351",
      "phuongxa":"Xã Quang Lịch",
      "quanhuyen":"11513"
   },
   {
      "id":"1151353",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"11513"
   },
   {
      "id":"1151355",
      "phuongxa":"Xã Thượng Hiền",
      "quanhuyen":"11513"
   },
   {
      "id":"1151357",
      "phuongxa":"Xã An Bồi",
      "quanhuyen":"11513"
   },
   {
      "id":"1151359",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"11513"
   },
   {
      "id":"1151361",
      "phuongxa":"Xã Tán Thuật",
      "quanhuyen":"11513"
   },
   {
      "id":"1151363",
      "phuongxa":"Xã Quang Minh",
      "quanhuyen":"11513"
   },
   {
      "id":"1151365",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"11513"
   },
   {
      "id":"1151369",
      "phuongxa":"Xã Minh Hưng",
      "quanhuyen":"11513"
   },
   {
      "id":"1151371",
      "phuongxa":"Xã Bình Thanh",
      "quanhuyen":"11513"
   },
   {
      "id":"1151373",
      "phuongxa":"Xã Nam Bình",
      "quanhuyen":"11513"
   },
   {
      "id":"1151375",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"11513"
   },
   {
      "id":"1151377",
      "phuongxa":"Xã Bình Định",
      "quanhuyen":"11513"
   },
   {
      "id":"1151379",
      "phuongxa":"Xã Hồng Tiến",
      "quanhuyen":"11513"
   },
   {
      "id":"1151501",
      "phuongxa":"Thị trấn Tiền Hải",
      "quanhuyen":"11515"
   },
   {
      "id":"1151503",
      "phuongxa":"Xã Đông Hải",
      "quanhuyen":"11515"
   },
   {
      "id":"1151505",
      "phuongxa":"Xã Đông Trà",
      "quanhuyen":"11515"
   },
   {
      "id":"1151507",
      "phuongxa":"Xã Đông Long",
      "quanhuyen":"11515"
   },
   {
      "id":"1151509",
      "phuongxa":"Xã Đông Quí",
      "quanhuyen":"11515"
   },
   {
      "id":"1151511",
      "phuongxa":"Xã Vũ Lăng",
      "quanhuyen":"11515"
   },
   {
      "id":"1151515",
      "phuongxa":"Xã Tây Lương",
      "quanhuyen":"11515"
   },
   {
      "id":"1151517",
      "phuongxa":"Xã Tây Ninh",
      "quanhuyen":"11515"
   },
   {
      "id":"1151519",
      "phuongxa":"Xã Đông Trung",
      "quanhuyen":"11515"
   },
   {
      "id":"1151521",
      "phuongxa":"Xã Đông Hoàng",
      "quanhuyen":"11515"
   },
   {
      "id":"1151523",
      "phuongxa":"Xã Đông Minh",
      "quanhuyen":"11515"
   },
   {
      "id":"1151527",
      "phuongxa":"Xã Đông Phong",
      "quanhuyen":"11515"
   },
   {
      "id":"1151529",
      "phuongxa":"Xã An Ninh",
      "quanhuyen":"11515"
   },
   {
      "id":"1151531",
      "phuongxa":"Xã Tây Sơn",
      "quanhuyen":"11515"
   },
   {
      "id":"1151533",
      "phuongxa":"Xã Đông Cơ",
      "quanhuyen":"11515"
   },
   {
      "id":"1151539",
      "phuongxa":"Xã Phương Công",
      "quanhuyen":"11515"
   },
   {
      "id":"1151541",
      "phuongxa":"Xã Tây Phong",
      "quanhuyen":"11515"
   },
   {
      "id":"1151543",
      "phuongxa":"Xã Tây Tiến",
      "quanhuyen":"11515"
   },
   {
      "id":"1151545",
      "phuongxa":"Xã Nam Cường",
      "quanhuyen":"11515"
   },
   {
      "id":"1151547",
      "phuongxa":"Xã Vân Trường",
      "quanhuyen":"11515"
   },
   {
      "id":"1151549",
      "phuongxa":"Xã Nam Chính",
      "quanhuyen":"11515"
   },
   {
      "id":"1151551",
      "phuongxa":"Xã Nam Thắng",
      "quanhuyen":"11515"
   },
   {
      "id":"1151553",
      "phuongxa":"Xã Nam Thịnh",
      "quanhuyen":"11515"
   },
   {
      "id":"1151555",
      "phuongxa":"Xã Bắc Hải",
      "quanhuyen":"11515"
   },
   {
      "id":"1151557",
      "phuongxa":"Xã Nam Hà",
      "quanhuyen":"11515"
   },
   {
      "id":"1151559",
      "phuongxa":"Xã Nam Thanh",
      "quanhuyen":"11515"
   },
   {
      "id":"1130715",
      "phuongxa":"Xã Yên Lợi",
      "quanhuyen":"11307"
   },
   {
      "id":"1130717",
      "phuongxa":"Xã Yên Minh",
      "quanhuyen":"11307"
   },
   {
      "id":"1130719",
      "phuongxa":"Xã Yên Bình",
      "quanhuyen":"11307"
   },
   {
      "id":"1130721",
      "phuongxa":"Xã Yên Dương",
      "quanhuyen":"11307"
   },
   {
      "id":"1130723",
      "phuongxa":"Xã Yên Mỹ",
      "quanhuyen":"11307"
   },
   {
      "id":"1130725",
      "phuongxa":"Xã Yên Ninh",
      "quanhuyen":"11307"
   },
   {
      "id":"1130727",
      "phuongxa":"Xã Yên Xá",
      "quanhuyen":"11307"
   },
   {
      "id":"1130729",
      "phuongxa":"Xã Yên Chính",
      "quanhuyen":"11307"
   },
   {
      "id":"1130731",
      "phuongxa":"Xã Yên Phú",
      "quanhuyen":"11307"
   },
   {
      "id":"1130733",
      "phuongxa":"Xã Yên Hưng",
      "quanhuyen":"11307"
   },
   {
      "id":"1130735",
      "phuongxa":"Xã Yên Phong",
      "quanhuyen":"11307"
   },
   {
      "id":"1130739",
      "phuongxa":"Xã Yên Hồng",
      "quanhuyen":"11307"
   },
   {
      "id":"1130741",
      "phuongxa":"Xã Yên Quang",
      "quanhuyen":"11307"
   },
   {
      "id":"1130743",
      "phuongxa":"Xã Yên Tiến",
      "quanhuyen":"11307"
   },
   {
      "id":"1130745",
      "phuongxa":"Xã Yên Bằng",
      "quanhuyen":"11307"
   },
   {
      "id":"1130747",
      "phuongxa":"Xã Yên Khang",
      "quanhuyen":"11307"
   },
   {
      "id":"1130749",
      "phuongxa":"Xã Yên Thắng",
      "quanhuyen":"11307"
   },
   {
      "id":"1130751",
      "phuongxa":"Xã Yên Đồng",
      "quanhuyen":"11307"
   },
   {
      "id":"1130753",
      "phuongxa":"Xã Yên Trị",
      "quanhuyen":"11307"
   },
   {
      "id":"1130755",
      "phuongxa":"Xã Yên Nhân",
      "quanhuyen":"11307"
   },
   {
      "id":"1130757",
      "phuongxa":"Xã Yên Lương",
      "quanhuyen":"11307"
   },
   {
      "id":"1130759",
      "phuongxa":"Xã Yên Cường",
      "quanhuyen":"11307"
   },
   {
      "id":"1130761",
      "phuongxa":"Xã Yên Lộc",
      "quanhuyen":"11307"
   },
   {
      "id":"1130763",
      "phuongxa":"Xã Yên Phúc",
      "quanhuyen":"11307"
   },
   {
      "id":"1130900",
      "phuongxa":"Không xác định",
      "quanhuyen":"11309"
   },
   {
      "id":"1130901",
      "phuongxa":"Xã Điền Xá",
      "quanhuyen":"11309"
   },
   {
      "id":"1130903",
      "phuongxa":"Xã Nam Mỹ",
      "quanhuyen":"11309"
   },
   {
      "id":"1130905",
      "phuongxa":"Xã Nam Thắng",
      "quanhuyen":"11309"
   },
   {
      "id":"1130907",
      "phuongxa":"Xã Nam Tiến",
      "quanhuyen":"11309"
   },
   {
      "id":"1130909",
      "phuongxa":"Xã Tân Thịnh",
      "quanhuyen":"11309"
   },
   {
      "id":"1130911",
      "phuongxa":"Xã Nghĩa An",
      "quanhuyen":"11309"
   },
   {
      "id":"1130915",
      "phuongxa":"Xã Hồng Quang",
      "quanhuyen":"11309"
   },
   {
      "id":"1130917",
      "phuongxa":"Xã Nam Cường",
      "quanhuyen":"11309"
   },
   {
      "id":"1130919",
      "phuongxa":"Xã Nam Hồng",
      "quanhuyen":"11309"
   },
   {
      "id":"1130921",
      "phuongxa":"Xã Nam Hùng",
      "quanhuyen":"11309"
   },
   {
      "id":"1130923",
      "phuongxa":"Xã Nam Giang",
      "quanhuyen":"11309"
   },
   {
      "id":"1130925",
      "phuongxa":"Xã Nam Hoa",
      "quanhuyen":"11309"
   },
   {
      "id":"1130927",
      "phuongxa":"Xã Nam Dương",
      "quanhuyen":"11309"
   },
   {
      "id":"1130929",
      "phuongxa":"Xã Đồng Sơn",
      "quanhuyen":"11309"
   },
   {
      "id":"1130931",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"11309"
   },
   {
      "id":"1130933",
      "phuongxa":"Xã Nam Thái",
      "quanhuyen":"11309"
   },
   {
      "id":"1130935",
      "phuongxa":"Xã Nam Lợi",
      "quanhuyen":"11309"
   },
   {
      "id":"1130937",
      "phuongxa":"Xã Nam Thanh",
      "quanhuyen":"11309"
   },
   {
      "id":"1131100",
      "phuongxa":"Không xác định",
      "quanhuyen":"11311"
   },
   {
      "id":"1131101",
      "phuongxa":"Thị trấn Cổ Lễ",
      "quanhuyen":"11311"
   },
   {
      "id":"1131103",
      "phuongxa":"Xã Phương Định",
      "quanhuyen":"11311"
   },
   {
      "id":"1131105",
      "phuongxa":"Xã Trung Đông",
      "quanhuyen":"11311"
   },
   {
      "id":"1131109",
      "phuongxa":"Xã Liêm Hải",
      "quanhuyen":"11311"
   },
   {
      "id":"1131111",
      "phuongxa":"Xã Trực Thuận",
      "quanhuyen":"11311"
   },
   {
      "id":"1131113",
      "phuongxa":"Xã Trực Đạo",
      "quanhuyen":"11311"
   },
   {
      "id":"1131117",
      "phuongxa":"Xã Việt Hùng",
      "quanhuyen":"11311"
   },
   {
      "id":"1131119",
      "phuongxa":"Xã Trực Hưng",
      "quanhuyen":"11311"
   },
   {
      "id":"1131123",
      "phuongxa":"Xã Trực Thanh",
      "quanhuyen":"11311"
   },
   {
      "id":"1131125",
      "phuongxa":"Xã Trực Nội",
      "quanhuyen":"11311"
   },
   {
      "id":"1131127",
      "phuongxa":"Xã Trực Mỹ",
      "quanhuyen":"11311"
   },
   {
      "id":"1131129",
      "phuongxa":"Xã Trực Tuấn",
      "quanhuyen":"11311"
   },
   {
      "id":"1131131",
      "phuongxa":"Xã Trực Thắng",
      "quanhuyen":"11311"
   },
   {
      "id":"1131135",
      "phuongxa":"Xã Trực Thái",
      "quanhuyen":"11311"
   },
   {
      "id":"1131137",
      "phuongxa":"Xã Trực Cường",
      "quanhuyen":"11311"
   },
   {
      "id":"1131139",
      "phuongxa":"Xã Trực Phú",
      "quanhuyen":"11311"
   },
   {
      "id":"1131141",
      "phuongxa":"Xã Trực Hùng",
      "quanhuyen":"11311"
   },
   {
      "id":"1131300",
      "phuongxa":"Không xác định",
      "quanhuyen":"11313"
   },
   {
      "id":"1131303",
      "phuongxa":"Xã Xuân Châu",
      "quanhuyen":"11313"
   },
   {
      "id":"1131305",
      "phuongxa":"Xã Xuân Hồng",
      "quanhuyen":"11313"
   },
   {
      "id":"1131307",
      "phuongxa":"Xã Xuân Thủy",
      "quanhuyen":"11313"
   },
   {
      "id":"1131309",
      "phuongxa":"Xã Xuân Ngọc",
      "quanhuyen":"11313"
   },
   {
      "id":"1131311",
      "phuongxa":"Xã Xuân Hùng",
      "quanhuyen":"11313"
   },
   {
      "id":"1131313",
      "phuongxa":"Xã Xuân Tiến",
      "quanhuyen":"11313"
   },
   {
      "id":"1131317",
      "phuongxa":"Xã Xuân Ninh",
      "quanhuyen":"11313"
   },
   {
      "id":"1131319",
      "phuongxa":"Xã Xuân Hòa",
      "quanhuyen":"11313"
   },
   {
      "id":"1131321",
      "phuongxa":"Xã Xuân Vinh",
      "quanhuyen":"11313"
   },
   {
      "id":"1131323",
      "phuongxa":"Xã Xuân Thành",
      "quanhuyen":"11313"
   },
   {
      "id":"1131325",
      "phuongxa":"Xã Xuân Bắc",
      "quanhuyen":"11313"
   },
   {
      "id":"1131327",
      "phuongxa":"Xã Xuân Phương",
      "quanhuyen":"11313"
   },
   {
      "id":"1131329",
      "phuongxa":"Xã Xuân Trung",
      "quanhuyen":"11313"
   },
   {
      "id":"1131331",
      "phuongxa":"Xã Xuân Đài",
      "quanhuyen":"11313"
   },
   {
      "id":"1131333",
      "phuongxa":"Xã Xuân Tân",
      "quanhuyen":"11313"
   },
   {
      "id":"1131335",
      "phuongxa":"Xã Xuân Phong",
      "quanhuyen":"11313"
   },
   {
      "id":"1131337",
      "phuongxa":"Xã Xuân Phú",
      "quanhuyen":"11313"
   },
   {
      "id":"1131339",
      "phuongxa":"Xã Thọ Nghiệp",
      "quanhuyen":"11313"
   },
   {
      "id":"1131500",
      "phuongxa":"Không xác định",
      "quanhuyen":"11315"
   },
   {
      "id":"1131501",
      "phuongxa":"Thị trấn Ngô Đồng",
      "quanhuyen":"11315"
   },
   {
      "id":"1131503",
      "phuongxa":"Xã Hoành Sơn",
      "quanhuyen":"11315"
   },
   {
      "id":"1131505",
      "phuongxa":"Xã Giao Nhân",
      "quanhuyen":"11315"
   },
   {
      "id":"1131507",
      "phuongxa":"Xã Giao Châu",
      "quanhuyen":"11315"
   },
   {
      "id":"1131509",
      "phuongxa":"Xã Giao Hải",
      "quanhuyen":"11315"
   },
   {
      "id":"1131511",
      "phuongxa":"Xã Giao Long",
      "quanhuyen":"11315"
   },
   {
      "id":"1131513",
      "phuongxa":"Xã Bình Hòa",
      "quanhuyen":"11315"
   },
   {
      "id":"1131517",
      "phuongxa":"Xã Giao Tân",
      "quanhuyen":"11315"
   },
   {
      "id":"1131519",
      "phuongxa":"Xã Giao Tiến",
      "quanhuyen":"11315"
   },
   {
      "id":"1131521",
      "phuongxa":"Xã Giao Yến",
      "quanhuyen":"11315"
   },
   {
      "id":"1131523",
      "phuongxa":"Xã Giao Thịnh",
      "quanhuyen":"11315"
   },
   {
      "id":"1131525",
      "phuongxa":"Xã Giao Lâm",
      "quanhuyen":"11315"
   },
   {
      "id":"1131527",
      "phuongxa":"Xã Giao Phong",
      "quanhuyen":"11315"
   },
   {
      "id":"1131529",
      "phuongxa":"Xã Bạch Long",
      "quanhuyen":"11315"
   },
   {
      "id":"1131531",
      "phuongxa":"Xã Giao Thiện",
      "quanhuyen":"11315"
   },
   {
      "id":"1131533",
      "phuongxa":"Xã Giao An",
      "quanhuyen":"11315"
   },
   {
      "id":"1131535",
      "phuongxa":"Xã Giao Thanh",
      "quanhuyen":"11315"
   },
   {
      "id":"1131537",
      "phuongxa":"Xã Giao Hương",
      "quanhuyen":"11315"
   },
   {
      "id":"1131539",
      "phuongxa":"Xã Hồng Thuận",
      "quanhuyen":"11315"
   },
   {
      "id":"1131541",
      "phuongxa":"Xã Giao Lạc",
      "quanhuyen":"11315"
   },
   {
      "id":"1131543",
      "phuongxa":"Xã Giao Xuân",
      "quanhuyen":"11315"
   },
   {
      "id":"1131545",
      "phuongxa":"Xã Giao Hưng",
      "quanhuyen":"11315"
   },
   {
      "id":"1131700",
      "phuongxa":"Không xác định",
      "quanhuyen":"11317"
   },
   {
      "id":"1131701",
      "phuongxa":"Thị trấn Liễu Đề",
      "quanhuyen":"11317"
   },
   {
      "id":"1131703",
      "phuongxa":"Thị trấn Rạng Đông",
      "quanhuyen":"11317"
   },
   {
      "id":"1131707",
      "phuongxa":"Xã Nghĩa Thịnh",
      "quanhuyen":"11317"
   },
   {
      "id":"1131709",
      "phuongxa":"Xã Nghĩa Minh",
      "quanhuyen":"11317"
   },
   {
      "id":"1131711",
      "phuongxa":"Xã Hoàng Nam",
      "quanhuyen":"11317"
   },
   {
      "id":"1131713",
      "phuongxa":"Xã Nghĩa Châu",
      "quanhuyen":"11317"
   },
   {
      "id":"1131715",
      "phuongxa":"Xã Nghĩa Thái",
      "quanhuyen":"11317"
   },
   {
      "id":"1131717",
      "phuongxa":"Xã Nghĩa Trung",
      "quanhuyen":"11317"
   },
   {
      "id":"1131721",
      "phuongxa":"Xã Nghĩa Lạc",
      "quanhuyen":"11317"
   },
   {
      "id":"1131723",
      "phuongxa":"Xã Nghĩa Hồng",
      "quanhuyen":"11317"
   },
   {
      "id":"1131725",
      "phuongxa":"Xã Nghĩa Phong",
      "quanhuyen":"11317"
   },
   {
      "id":"1131727",
      "phuongxa":"Xã Nghĩa Phú",
      "quanhuyen":"11317"
   },
   {
      "id":"1131729",
      "phuongxa":"Xã Nghĩa Bình",
      "quanhuyen":"11317"
   },
   {
      "id":"1131733",
      "phuongxa":"Xã Nghĩa Hòa",
      "quanhuyen":"11317"
   },
   {
      "id":"1131735",
      "phuongxa":"Xã Nghĩa Thành",
      "quanhuyen":"11317"
   },
   {
      "id":"1131737",
      "phuongxa":"Xã Nghĩa Lâm",
      "quanhuyen":"11317"
   },
   {
      "id":"1131739",
      "phuongxa":"Xã Nghĩa Hùng",
      "quanhuyen":"11317"
   },
   {
      "id":"1131741",
      "phuongxa":"Xã Nghĩa Hải",
      "quanhuyen":"11317"
   },
   {
      "id":"1131745",
      "phuongxa":"Xã Nghĩa Lợi",
      "quanhuyen":"11317"
   },
   {
      "id":"1131747",
      "phuongxa":"Xã Nghĩa Phúc",
      "quanhuyen":"11317"
   },
   {
      "id":"1131749",
      "phuongxa":"Xã Nam Điền",
      "quanhuyen":"11317"
   },
   {
      "id":"1131900",
      "phuongxa":"Không xác định",
      "quanhuyen":"11319"
   },
   {
      "id":"1131901",
      "phuongxa":"Thị trấn Yên Định",
      "quanhuyen":"11319"
   },
   {
      "id":"1131907",
      "phuongxa":"Xã Hải Vân",
      "quanhuyen":"11319"
   },
   {
      "id":"1131909",
      "phuongxa":"Xã Hải Nam",
      "quanhuyen":"11319"
   },
   {
      "id":"1131911",
      "phuongxa":"Xã Hải Hà",
      "quanhuyen":"11319"
   },
   {
      "id":"1131913",
      "phuongxa":"Xã Hải Phúc",
      "quanhuyen":"11319"
   },
   {
      "id":"1131915",
      "phuongxa":"Xã Hải Lộc",
      "quanhuyen":"11319"
   },
   {
      "id":"1131917",
      "phuongxa":"Xã Hải Thanh",
      "quanhuyen":"11319"
   },
   {
      "id":"1131919",
      "phuongxa":"Xã Hải Hưng",
      "quanhuyen":"11319"
   },
   {
      "id":"1131921",
      "phuongxa":"Xã Hải Quang",
      "quanhuyen":"11319"
   },
   {
      "id":"1131923",
      "phuongxa":"Xã Hải Đông",
      "quanhuyen":"11319"
   },
   {
      "id":"1131925",
      "phuongxa":"Xã Hải Tây",
      "quanhuyen":"11319"
   },
   {
      "id":"1131927",
      "phuongxa":"Xã Hải Lý",
      "quanhuyen":"11319"
   },
   {
      "id":"1131931",
      "phuongxa":"Xã Hải Anh",
      "quanhuyen":"11319"
   },
   {
      "id":"1131933",
      "phuongxa":"Xã Hải Trung",
      "quanhuyen":"11319"
   },
   {
      "id":"1131935",
      "phuongxa":"Xã Hải Bắc",
      "quanhuyen":"11319"
   },
   {
      "id":"1131937",
      "phuongxa":"Xã Hải Phương",
      "quanhuyen":"11319"
   },
   {
      "id":"1131939",
      "phuongxa":"Xã Hải Tân",
      "quanhuyen":"11319"
   },
   {
      "id":"1131941",
      "phuongxa":"Xã Hải Sơn",
      "quanhuyen":"11319"
   },
   {
      "id":"1131943",
      "phuongxa":"Xã Hải Long",
      "quanhuyen":"11319"
   },
   {
      "id":"1131945",
      "phuongxa":"Xã Hải Đường",
      "quanhuyen":"11319"
   },
   {
      "id":"1131947",
      "phuongxa":"Xã Hải Xuân",
      "quanhuyen":"11319"
   },
   {
      "id":"1131949",
      "phuongxa":"Xã Hải Hòa",
      "quanhuyen":"11319"
   },
   {
      "id":"1131951",
      "phuongxa":"Xã Hải Châu",
      "quanhuyen":"11319"
   },
   {
      "id":"1131953",
      "phuongxa":"Xã Hải Phú",
      "quanhuyen":"11319"
   },
   {
      "id":"1131955",
      "phuongxa":"Xã Hải Cường",
      "quanhuyen":"11319"
   },
   {
      "id":"1131957",
      "phuongxa":"Xã Hải Ninh",
      "quanhuyen":"11319"
   },
   {
      "id":"1131959",
      "phuongxa":"Xã Hải An",
      "quanhuyen":"11319"
   },
   {
      "id":"1131961",
      "phuongxa":"Xã Hải Toàn",
      "quanhuyen":"11319"
   },
   {
      "id":"1131963",
      "phuongxa":"Xã Hải Phong",
      "quanhuyen":"11319"
   },
   {
      "id":"1131965",
      "phuongxa":"Xã Hải Giang",
      "quanhuyen":"11319"
   },
   {
      "id":"1131967",
      "phuongxa":"Xã Hải Triều",
      "quanhuyen":"11319"
   },
   {
      "id":"1150000",
      "phuongxa":"Không xác định",
      "quanhuyen":"11500"
   },
   {
      "id":"1150100",
      "phuongxa":"Không xác định",
      "quanhuyen":"11501"
   },
   {
      "id":"1150101",
      "phuongxa":"Phường Lê Hồng Phong",
      "quanhuyen":"11501"
   },
   {
      "id":"1150103",
      "phuongxa":"Phường Bồ Xuyên",
      "quanhuyen":"11501"
   },
   {
      "id":"1150105",
      "phuongxa":"Phường Đề Thám",
      "quanhuyen":"11501"
   },
   {
      "id":"1150107",
      "phuongxa":"Phường Kỳ Bá",
      "quanhuyen":"11501"
   },
   {
      "id":"1150109",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"11501"
   },
   {
      "id":"1150111",
      "phuongxa":"Phường Phú Khánh",
      "quanhuyen":"11501"
   },
   {
      "id":"1150113",
      "phuongxa":"Xã Đông Hòa",
      "quanhuyen":"11501"
   },
   {
      "id":"1150115",
      "phuongxa":"Xã Hoàng Diệu",
      "quanhuyen":"11501"
   },
   {
      "id":"1150117",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"11501"
   },
   {
      "id":"1150119",
      "phuongxa":"Xã Phú Xuân",
      "quanhuyen":"11501"
   },
   {
      "id":"1150121",
      "phuongxa":"Xã Trần Lãm",
      "quanhuyen":"11501"
   },
   {
      "id":"1150123",
      "phuongxa":"Xã Vũ Chính",
      "quanhuyen":"11501"
   },
   {
      "id":"1150125",
      "phuongxa":"Xã Vũ Phúc",
      "quanhuyen":"11501"
   },
   {
      "id":"1150300",
      "phuongxa":"Không xác định",
      "quanhuyen":"11503"
   },
   {
      "id":"1150301",
      "phuongxa":"Thị trấn Quỳnh Côi",
      "quanhuyen":"11503"
   },
   {
      "id":"1150303",
      "phuongxa":"Xã Quỳnh Lâm",
      "quanhuyen":"11503"
   },
   {
      "id":"1150305",
      "phuongxa":"Xã Quỳnh Hoàng",
      "quanhuyen":"11503"
   },
   {
      "id":"1150307",
      "phuongxa":"Xã Quỳnh Khê",
      "quanhuyen":"11503"
   },
   {
      "id":"1150311",
      "phuongxa":"Xã Quỳnh Giao",
      "quanhuyen":"11503"
   },
   {
      "id":"1150313",
      "phuongxa":"Xã Quỳnh Hoa",
      "quanhuyen":"11503"
   },
   {
      "id":"1150317",
      "phuongxa":"Xã An Hiệp",
      "quanhuyen":"11503"
   },
   {
      "id":"1150319",
      "phuongxa":"Xã An Đồng",
      "quanhuyen":"11503"
   },
   {
      "id":"1150321",
      "phuongxa":"Xã An Khê",
      "quanhuyen":"11503"
   },
   {
      "id":"1150323",
      "phuongxa":"Xã An Thái",
      "quanhuyen":"11503"
   },
   {
      "id":"1150325",
      "phuongxa":"Xã An Cầu",
      "quanhuyen":"11503"
   },
   {
      "id":"1150327",
      "phuongxa":"Xã Quỳnh Minh",
      "quanhuyen":"11503"
   },
   {
      "id":"1150329",
      "phuongxa":"Xã An ấp",
      "quanhuyen":"11503"
   },
   {
      "id":"1150333",
      "phuongxa":"Xã Quỳnh Hải",
      "quanhuyen":"11503"
   },
   {
      "id":"1150335",
      "phuongxa":"Xã Quỳnh Hồng",
      "quanhuyen":"11503"
   },
   {
      "id":"1150337",
      "phuongxa":"Xã Quỳnh Mỹ",
      "quanhuyen":"11503"
   },
   {
      "id":"1150339",
      "phuongxa":"Xã Quỳnh Sơn",
      "quanhuyen":"11503"
   },
   {
      "id":"1150341",
      "phuongxa":"Xã Quỳnh Nguyên",
      "quanhuyen":"11503"
   },
   {
      "id":"1150345",
      "phuongxa":"Xã Quỳnh Bảo",
      "quanhuyen":"11503"
   },
   {
      "id":"1150347",
      "phuongxa":"Xã Quỳnh Hưng",
      "quanhuyen":"11503"
   },
   {
      "id":"1150349",
      "phuongxa":"Xã Quỳnh Trang",
      "quanhuyen":"11503"
   },
   {
      "id":"1150351",
      "phuongxa":"Xã Quỳnh Xá",
      "quanhuyen":"11503"
   },
   {
      "id":"1150353",
      "phuongxa":"Xã An Ninh",
      "quanhuyen":"11503"
   },
   {
      "id":"1150357",
      "phuongxa":"Xã An Quí",
      "quanhuyen":"11503"
   },
   {
      "id":"1150361",
      "phuongxa":"Xã An Bài",
      "quanhuyen":"11503"
   },
   {
      "id":"1150363",
      "phuongxa":"Xã An Thanh",
      "quanhuyen":"11503"
   },
   {
      "id":"1150365",
      "phuongxa":"Xã An Mỹ",
      "quanhuyen":"11503"
   },
   {
      "id":"1150367",
      "phuongxa":"Xã An Lễ",
      "quanhuyen":"11503"
   },
   {
      "id":"1150369",
      "phuongxa":"Xã An Vũ",
      "quanhuyen":"11503"
   },
   {
      "id":"1150371",
      "phuongxa":"Xã An Dục",
      "quanhuyen":"11503"
   },
   {
      "id":"1150373",
      "phuongxa":"Xã An Tràng",
      "quanhuyen":"11503"
   },
   {
      "id":"1150375",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"11503"
   },
   {
      "id":"1150500",
      "phuongxa":"Không xác định",
      "quanhuyen":"11505"
   },
   {
      "id":"1150501",
      "phuongxa":"Thị trấn Hưng Hà",
      "quanhuyen":"11505"
   },
   {
      "id":"1150503",
      "phuongxa":"Xã Tân Lễ",
      "quanhuyen":"11505"
   },
   {
      "id":"1150505",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"11505"
   },
   {
      "id":"1150507",
      "phuongxa":"Xã Tiến Đức",
      "quanhuyen":"11505"
   },
   {
      "id":"1150509",
      "phuongxa":"Xã Hồng An",
      "quanhuyen":"11505"
   },
   {
      "id":"1150511",
      "phuongxa":"Xã Canh Tân",
      "quanhuyen":"11505"
   },
   {
      "id":"1150513",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"11505"
   },
   {
      "id":"1150515",
      "phuongxa":"Xã Cộng Hòa",
      "quanhuyen":"11505"
   },
   {
      "id":"1150517",
      "phuongxa":"Xã Hòa Tiến",
      "quanhuyen":"11505"
   },
   {
      "id":"1150519",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"11505"
   },
   {
      "id":"1150521",
      "phuongxa":"Xã Phúc Khánh",
      "quanhuyen":"11505"
   },
   {
      "id":"1150525",
      "phuongxa":"Xã Thái Hưng",
      "quanhuyen":"11505"
   },
   {
      "id":"1150527",
      "phuongxa":"Xã Thái Phương",
      "quanhuyen":"11505"
   },
   {
      "id":"1150529",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"11505"
   },
   {
      "id":"1150531",
      "phuongxa":"Xã Điệp Nông",
      "quanhuyen":"11505"
   },
   {
      "id":"1150533",
      "phuongxa":"Xã Đoan Hùng",
      "quanhuyen":"11505"
   },
   {
      "id":"1150535",
      "phuongxa":"Xã Dân Chủ",
      "quanhuyen":"11505"
   },
   {
      "id":"1150537",
      "phuongxa":"Xã Duyên Hải",
      "quanhuyen":"11505"
   },
   {
      "id":"1150539",
      "phuongxa":"Xã Hùng Dũng",
      "quanhuyen":"11505"
   },
   {
      "id":"1150541",
      "phuongxa":"Xã Văn Cẩm",
      "quanhuyen":"11505"
   },
   {
      "id":"1150543",
      "phuongxa":"Xã Thống Nhất",
      "quanhuyen":"11505"
   },
   {
      "id":"1150545",
      "phuongxa":"Xã Minh Khai",
      "quanhuyen":"11505"
   },
   {
      "id":"1150547",
      "phuongxa":"Xã Hồng Lĩnh",
      "quanhuyen":"11505"
   },
   {
      "id":"1150549",
      "phuongxa":"Xã Kim Chung",
      "quanhuyen":"11505"
   },
   {
      "id":"1150551",
      "phuongxa":"Xã Chí Hòa",
      "quanhuyen":"11505"
   },
   {
      "id":"1150553",
      "phuongxa":"Xã Minh Hòa",
      "quanhuyen":"11505"
   },
   {
      "id":"1150555",
      "phuongxa":"Xã Văn Lang",
      "quanhuyen":"11505"
   },
   {
      "id":"1150557",
      "phuongxa":"Xã Độc Lập",
      "quanhuyen":"11505"
   },
   {
      "id":"1150559",
      "phuongxa":"Xã Hồng Minh",
      "quanhuyen":"11505"
   },
   {
      "id":"1150561",
      "phuongxa":"Xã Bình Lăng",
      "quanhuyen":"11505"
   },
   {
      "id":"1150563",
      "phuongxa":"Xã Tây Đô",
      "quanhuyen":"11505"
   },
   {
      "id":"1150567",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"11505"
   },
   {
      "id":"1150700",
      "phuongxa":"Không xác định",
      "quanhuyen":"11507"
   },
   {
      "id":"1150701",
      "phuongxa":"Thị trấn Diêm Điền",
      "quanhuyen":"11507"
   },
   {
      "id":"1150703",
      "phuongxa":"Xã Thụy Ninh",
      "quanhuyen":"11507"
   },
   {
      "id":"1150705",
      "phuongxa":"Xã Thụy Chính",
      "quanhuyen":"11507"
   },
   {
      "id":"1150707",
      "phuongxa":"Xã Thụy Dân",
      "quanhuyen":"11507"
   },
   {
      "id":"1150709",
      "phuongxa":"Xã Thụy Duyên",
      "quanhuyen":"11507"
   },
   {
      "id":"1150711",
      "phuongxa":"Xã Thụy Thanh",
      "quanhuyen":"11507"
   },
   {
      "id":"1150713",
      "phuongxa":"Xã Thụy Phong",
      "quanhuyen":"11507"
   },
   {
      "id":"1150715",
      "phuongxa":"Xã Thụy Sơn",
      "quanhuyen":"11507"
   },
   {
      "id":"1150717",
      "phuongxa":"Xã Thụy Phúc",
      "quanhuyen":"11507"
   },
   {
      "id":"1150719",
      "phuongxa":"Xã Thụy Hưng",
      "quanhuyen":"11507"
   },
   {
      "id":"1150721",
      "phuongxa":"Xã Thụy Việt",
      "quanhuyen":"11507"
   },
   {
      "id":"1150723",
      "phuongxa":"Xã Thụy Dương",
      "quanhuyen":"11507"
   },
   {
      "id":"1150725",
      "phuongxa":"Xã Thụy Văn",
      "quanhuyen":"11507"
   },
   {
      "id":"1150727",
      "phuongxa":"Xã Thụy Bình",
      "quanhuyen":"11507"
   },
   {
      "id":"1150731",
      "phuongxa":"Xã Thụy Hà",
      "quanhuyen":"11507"
   },
   {
      "id":"1150733",
      "phuongxa":"Xã Thụy Lương",
      "quanhuyen":"11507"
   },
   {
      "id":"1150737",
      "phuongxa":"Xã Thụy Trình",
      "quanhuyen":"11507"
   },
   {
      "id":"1150739",
      "phuongxa":"Xã Hồng Quỳnh",
      "quanhuyen":"11507"
   },
   {
      "id":"1150743",
      "phuongxa":"Xã Thụy Dũng",
      "quanhuyen":"11507"
   },
   {
      "id":"1150745",
      "phuongxa":"Xã Thụy Tân",
      "quanhuyen":"11507"
   },
   {
      "id":"1150747",
      "phuongxa":"Xã Thụy An",
      "quanhuyen":"11507"
   },
   {
      "id":"1150749",
      "phuongxa":"Xã Thụy Hải",
      "quanhuyen":"11507"
   },
   {
      "id":"1150751",
      "phuongxa":"Xã Thụy Xuân",
      "quanhuyen":"11507"
   },
   {
      "id":"1150753",
      "phuongxa":"Xã Thụy Trường",
      "quanhuyen":"11507"
   },
   {
      "id":"1150757",
      "phuongxa":"Xã Thái Sơn",
      "quanhuyen":"11507"
   },
   {
      "id":"1150759",
      "phuongxa":"Xã Thái Hà",
      "quanhuyen":"11507"
   },
   {
      "id":"1150761",
      "phuongxa":"Xã Thái Phúc",
      "quanhuyen":"11507"
   },
   {
      "id":"1150763",
      "phuongxa":"Xã Thái Dương",
      "quanhuyen":"11507"
   },
   {
      "id":"1150765",
      "phuongxa":"Xã Thái Hồng",
      "quanhuyen":"11507"
   },
   {
      "id":"1150769",
      "phuongxa":"Xã Thái Thành",
      "quanhuyen":"11507"
   },
   {
      "id":"1150771",
      "phuongxa":"Xã Thái Thịnh",
      "quanhuyen":"11507"
   },
   {
      "id":"1150773",
      "phuongxa":"Xã Thái Thọ",
      "quanhuyen":"11507"
   },
   {
      "id":"1150775",
      "phuongxa":"Xã Thái Học",
      "quanhuyen":"11507"
   },
   {
      "id":"1150777",
      "phuongxa":"Xã  Mỹ Lộc",
      "quanhuyen":"11507"
   },
   {
      "id":"1150779",
      "phuongxa":"Xã Thái Tân",
      "quanhuyen":"11507"
   },
   {
      "id":"1150781",
      "phuongxa":"Xã Thái Đô",
      "quanhuyen":"11507"
   },
   {
      "id":"1150783",
      "phuongxa":"Xã Thái Hưng",
      "quanhuyen":"11507"
   },
   {
      "id":"1150785",
      "phuongxa":"Xã Thái An",
      "quanhuyen":"11507"
   },
   {
      "id":"1150787",
      "phuongxa":"Xã Thái Nguyên",
      "quanhuyen":"11507"
   },
   {
      "id":"1110341",
      "phuongxa":"Xã Tiên Hải",
      "quanhuyen":"11103"
   },
   {
      "id":"1110343",
      "phuongxa":"Xã Lam Hạ",
      "quanhuyen":"11103"
   },
   {
      "id":"1110500",
      "phuongxa":"Không xác định",
      "quanhuyen":"11105"
   },
   {
      "id":"1110501",
      "phuongxa":"Thị trấn Quế",
      "quanhuyen":"11105"
   },
   {
      "id":"1110503",
      "phuongxa":"Xã Nguyễn úy",
      "quanhuyen":"11105"
   },
   {
      "id":"1110505",
      "phuongxa":"Xã Tượng Lĩnh",
      "quanhuyen":"11105"
   },
   {
      "id":"1110507",
      "phuongxa":"Xã Lê Hồ",
      "quanhuyen":"11105"
   },
   {
      "id":"1110509",
      "phuongxa":"Xã Đại Cương",
      "quanhuyen":"11105"
   },
   {
      "id":"1110511",
      "phuongxa":"Xã Nhật Tựu",
      "quanhuyen":"11105"
   },
   {
      "id":"1110515",
      "phuongxa":"Xã Văn Xá",
      "quanhuyen":"11105"
   },
   {
      "id":"1110517",
      "phuongxa":"Xã Kim Bình",
      "quanhuyen":"11105"
   },
   {
      "id":"1110519",
      "phuongxa":"Xã Phù Vân",
      "quanhuyen":"11105"
   },
   {
      "id":"1110521",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"11105"
   },
   {
      "id":"1110523",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"11105"
   },
   {
      "id":"1110525",
      "phuongxa":"Xã Ba Sao",
      "quanhuyen":"11105"
   },
   {
      "id":"1110527",
      "phuongxa":"Xã Khả Phong",
      "quanhuyen":"11105"
   },
   {
      "id":"1110529",
      "phuongxa":"Xã Tân Sơn",
      "quanhuyen":"11105"
   },
   {
      "id":"1110531",
      "phuongxa":"Xã Châu Sơn",
      "quanhuyen":"11105"
   },
   {
      "id":"1110533",
      "phuongxa":"Xã Liên Sơn",
      "quanhuyen":"11105"
   },
   {
      "id":"1110535",
      "phuongxa":"Xã Thụy Lôi",
      "quanhuyen":"11105"
   },
   {
      "id":"1110537",
      "phuongxa":"Xã Thi Sơn",
      "quanhuyen":"11105"
   },
   {
      "id":"1110539",
      "phuongxa":"Xã Nhật Tân",
      "quanhuyen":"11105"
   },
   {
      "id":"1110541",
      "phuongxa":"Xã Đồng Hóa",
      "quanhuyen":"11105"
   },
   {
      "id":"1110700",
      "phuongxa":"Không xác định",
      "quanhuyen":"11107"
   },
   {
      "id":"1110701",
      "phuongxa":"Thị trấn Vĩnh Trụ",
      "quanhuyen":"11107"
   },
   {
      "id":"1110703",
      "phuongxa":"Xã Hợp Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110705",
      "phuongxa":"Xã Văn Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110707",
      "phuongxa":"Xã Chính Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110709",
      "phuongxa":"Xã Nguyên Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110713",
      "phuongxa":"Xã Đức Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110715",
      "phuongxa":"Xã Đạo Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110717",
      "phuongxa":"Xã Chân Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110719",
      "phuongxa":"Xã Bắc Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110721",
      "phuongxa":"Xã Nhân Đạo",
      "quanhuyen":"11107"
   },
   {
      "id":"1110723",
      "phuongxa":"Xã Đồng Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110725",
      "phuongxa":"Xã Nhân Hưng",
      "quanhuyen":"11107"
   },
   {
      "id":"1110727",
      "phuongxa":"Xã Nhân Thịnh",
      "quanhuyen":"11107"
   },
   {
      "id":"1110729",
      "phuongxa":"Xã Nhân Khang",
      "quanhuyen":"11107"
   },
   {
      "id":"1110731",
      "phuongxa":"Xã Nhân Chính",
      "quanhuyen":"11107"
   },
   {
      "id":"1110733",
      "phuongxa":"Xã Nhân Nghĩa",
      "quanhuyen":"11107"
   },
   {
      "id":"1110735",
      "phuongxa":"Xã Nhân Bình",
      "quanhuyen":"11107"
   },
   {
      "id":"1110737",
      "phuongxa":"Xã Xuân Khê",
      "quanhuyen":"11107"
   },
   {
      "id":"1110739",
      "phuongxa":"Xã Nhân Mỹ",
      "quanhuyen":"11107"
   },
   {
      "id":"1110741",
      "phuongxa":"Xã Phú Phúc",
      "quanhuyen":"11107"
   },
   {
      "id":"1110743",
      "phuongxa":"Xã Tiến Thắng",
      "quanhuyen":"11107"
   },
   {
      "id":"1110745",
      "phuongxa":"Xã Hòa Hậu",
      "quanhuyen":"11107"
   },
   {
      "id":"1110901",
      "phuongxa":"Thị trấn Kiện Khê",
      "quanhuyen":"11109"
   },
   {
      "id":"1110905",
      "phuongxa":"Xã Liêm Tuyền",
      "quanhuyen":"11109"
   },
   {
      "id":"1110907",
      "phuongxa":"Xã Liêm Cần",
      "quanhuyen":"11109"
   },
   {
      "id":"1110909",
      "phuongxa":"Xã Liêm Tiết",
      "quanhuyen":"11109"
   },
   {
      "id":"1110913",
      "phuongxa":"Xã Thanh Hà",
      "quanhuyen":"11109"
   },
   {
      "id":"1110915",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"11109"
   },
   {
      "id":"1110917",
      "phuongxa":"Xã Thanh Tuyền",
      "quanhuyen":"11109"
   },
   {
      "id":"1110919",
      "phuongxa":"Xã Thanh Thủy",
      "quanhuyen":"11109"
   },
   {
      "id":"1110921",
      "phuongxa":"Xã Thanh Tân",
      "quanhuyen":"11109"
   },
   {
      "id":"1110923",
      "phuongxa":"Xã Thanh Nghị",
      "quanhuyen":"11109"
   },
   {
      "id":"1110927",
      "phuongxa":"Xã Thanh Hương",
      "quanhuyen":"11109"
   },
   {
      "id":"1110929",
      "phuongxa":"Xã Thanh Tâm",
      "quanhuyen":"11109"
   },
   {
      "id":"1110931",
      "phuongxa":"Xã Thanh Nguyên",
      "quanhuyen":"11109"
   },
   {
      "id":"1110933",
      "phuongxa":"Xã Thanh Phong",
      "quanhuyen":"11109"
   },
   {
      "id":"1110937",
      "phuongxa":"Xã Liêm Thuận",
      "quanhuyen":"11109"
   },
   {
      "id":"1110939",
      "phuongxa":"Xã Liêm Túc",
      "quanhuyen":"11109"
   },
   {
      "id":"1110941",
      "phuongxa":"Xã Liêm Sơn",
      "quanhuyen":"11109"
   },
   {
      "id":"1111100",
      "phuongxa":"Không xác định",
      "quanhuyen":"11111"
   },
   {
      "id":"1111103",
      "phuongxa":"Xã Bình Nghĩa",
      "quanhuyen":"11111"
   },
   {
      "id":"1111105",
      "phuongxa":"Xã Đinh Xá",
      "quanhuyen":"11111"
   },
   {
      "id":"1111107",
      "phuongxa":"Xã Tràng An",
      "quanhuyen":"11111"
   },
   {
      "id":"1111109",
      "phuongxa":"Xã Đồng Du",
      "quanhuyen":"11111"
   },
   {
      "id":"1111111",
      "phuongxa":"Xã Trịnh Xá",
      "quanhuyen":"11111"
   },
   {
      "id":"1111113",
      "phuongxa":"Xã Đồn Xá",
      "quanhuyen":"11111"
   },
   {
      "id":"2030300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20303"
   },
   {
      "id":"2030301",
      "phuongxa":"Thị trấn Bảo Lạc",
      "quanhuyen":"20303"
   },
   {
      "id":"2030303",
      "phuongxa":"Xã Đức Hạnh",
      "quanhuyen":"20303"
   },
   {
      "id":"2030305",
      "phuongxa":"Xã Cốc Pàng",
      "quanhuyen":"20303"
   },
   {
      "id":"2030307",
      "phuongxa":"Xã Thượng Hà",
      "quanhuyen":"20303"
   },
   {
      "id":"2030309",
      "phuongxa":"Xã Cô Ba",
      "quanhuyen":"20303"
   },
   {
      "id":"2030311",
      "phuongxa":"Xã Khánh Xuân",
      "quanhuyen":"20303"
   },
   {
      "id":"2030313",
      "phuongxa":"Xã Xuân Trường",
      "quanhuyen":"20303"
   },
   {
      "id":"2030315",
      "phuongxa":"Xã Lý Bôn",
      "quanhuyen":"20303"
   },
   {
      "id":"2030317",
      "phuongxa":"Xã Bảo Toàn",
      "quanhuyen":"20303"
   },
   {
      "id":"2030319",
      "phuongxa":"Xã Vĩnh Quang",
      "quanhuyen":"20303"
   },
   {
      "id":"2030321",
      "phuongxa":"Xã Hồng Trị",
      "quanhuyen":"20303"
   },
   {
      "id":"2030323",
      "phuongxa":"Xã Phan Thanh",
      "quanhuyen":"20303"
   },
   {
      "id":"2030325",
      "phuongxa":"Xã Hồng An",
      "quanhuyen":"20303"
   },
   {
      "id":"2030327",
      "phuongxa":"Xã Nam Quang",
      "quanhuyen":"20303"
   },
   {
      "id":"2030329",
      "phuongxa":"Xã Quảng Lâm",
      "quanhuyen":"20303"
   },
   {
      "id":"2030331",
      "phuongxa":"Xã Tân Việt",
      "quanhuyen":"20303"
   },
   {
      "id":"2030335",
      "phuongxa":"Xã Vĩnh Phong",
      "quanhuyen":"20303"
   },
   {
      "id":"2030337",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"20303"
   },
   {
      "id":"2030339",
      "phuongxa":"Xã Huy Giáp",
      "quanhuyen":"20303"
   },
   {
      "id":"2030341",
      "phuongxa":"Xã Thái Học",
      "quanhuyen":"20303"
   },
   {
      "id":"2030343",
      "phuongxa":"Xã Sơn Lộ",
      "quanhuyen":"20303"
   },
   {
      "id":"2030345",
      "phuongxa":"Xã Đình Phùng",
      "quanhuyen":"20303"
   },
   {
      "id":"2030347",
      "phuongxa":"Xã Yên Thổ",
      "quanhuyen":"20303"
   },
   {
      "id":"2030500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20305"
   },
   {
      "id":"2030501",
      "phuongxa":"Xã Xuân Hoà",
      "quanhuyen":"20305"
   },
   {
      "id":"2030503",
      "phuongxa":"Xã Trường Hà",
      "quanhuyen":"20305"
   },
   {
      "id":"2030505",
      "phuongxa":"Xã Kéo Yên",
      "quanhuyen":"20305"
   },
   {
      "id":"2030507",
      "phuongxa":"Xã Lũng Nặm",
      "quanhuyen":"20305"
   },
   {
      "id":"2030509",
      "phuongxa":"Xã Vân An",
      "quanhuyen":"20305"
   },
   {
      "id":"2030511",
      "phuongxa":"Xã Cải Viên",
      "quanhuyen":"20305"
   },
   {
      "id":"2030513",
      "phuongxa":"Xã Nà Sác",
      "quanhuyen":"20305"
   },
   {
      "id":"2030515",
      "phuongxa":"Xã Nội Thôn",
      "quanhuyen":"20305"
   },
   {
      "id":"2030517",
      "phuongxa":"Xã Sóc Hà",
      "quanhuyen":"20305"
   },
   {
      "id":"2030519",
      "phuongxa":"Xã Thượng Thôn",
      "quanhuyen":"20305"
   },
   {
      "id":"2030523",
      "phuongxa":"Xã Quý Quân",
      "quanhuyen":"20305"
   },
   {
      "id":"2030525",
      "phuongxa":"Xã Hồng Sĩ",
      "quanhuyen":"20305"
   },
   {
      "id":"2030527",
      "phuongxa":"Xã Sĩ Hai",
      "quanhuyen":"20305"
   },
   {
      "id":"2030529",
      "phuongxa":"Xã Đào Ngạn",
      "quanhuyen":"20305"
   },
   {
      "id":"2030531",
      "phuongxa":"Xã Phù Ngọc",
      "quanhuyen":"20305"
   },
   {
      "id":"2030533",
      "phuongxa":"Xã Mã Ba",
      "quanhuyen":"20305"
   },
   {
      "id":"2030535",
      "phuongxa":"Xã Hạ Thôn",
      "quanhuyen":"20305"
   },
   {
      "id":"2030700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20307"
   },
   {
      "id":"2030701",
      "phuongxa":"Xã Đa Thông",
      "quanhuyen":"20307"
   },
   {
      "id":"2030703",
      "phuongxa":"Xã Cần Yên",
      "quanhuyen":"20307"
   },
   {
      "id":"2030705",
      "phuongxa":"Xã Vị Quang",
      "quanhuyen":"20307"
   },
   {
      "id":"2030709",
      "phuongxa":"Xã Ngọc Động",
      "quanhuyen":"20307"
   },
   {
      "id":"2030711",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"20307"
   },
   {
      "id":"2030713",
      "phuongxa":"Xã Lương Can",
      "quanhuyen":"20307"
   },
   {
      "id":"2030715",
      "phuongxa":"Xã Thanh Long",
      "quanhuyen":"20307"
   },
   {
      "id":"2030717",
      "phuongxa":"Xã Bình Lãng",
      "quanhuyen":"20307"
   },
   {
      "id":"2030900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20309"
   },
   {
      "id":"2030903",
      "phuongxa":"Xã Cô Mười",
      "quanhuyen":"20309"
   },
   {
      "id":"2030905",
      "phuongxa":"Xã Tri Phương",
      "quanhuyen":"20309"
   },
   {
      "id":"2030909",
      "phuongxa":"Xã Quang Vinh",
      "quanhuyen":"20309"
   },
   {
      "id":"2030911",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"20309"
   },
   {
      "id":"2030915",
      "phuongxa":"Xã Xuân Nội",
      "quanhuyen":"20309"
   },
   {
      "id":"2030917",
      "phuongxa":"Xã Cao Chương",
      "quanhuyen":"20309"
   },
   {
      "id":"2030919",
      "phuongxa":"Xã Quốc Toản",
      "quanhuyen":"20309"
   },
   {
      "id":"2031100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20311"
   },
   {
      "id":"2031101",
      "phuongxa":"Thị trấn Trùng Khánh",
      "quanhuyen":"20311"
   },
   {
      "id":"2031103",
      "phuongxa":"Xã Lăng Yên",
      "quanhuyen":"20311"
   },
   {
      "id":"2031105",
      "phuongxa":"Xã Ngọc Chung",
      "quanhuyen":"20311"
   },
   {
      "id":"2031107",
      "phuongxa":"Xã Phong Nậm",
      "quanhuyen":"20311"
   },
   {
      "id":"2031109",
      "phuongxa":"Xã Ngọc Khê",
      "quanhuyen":"20311"
   },
   {
      "id":"2031111",
      "phuongxa":"Xã Đình Phong",
      "quanhuyen":"20311"
   },
   {
      "id":"2031113",
      "phuongxa":"Xã Chí Viễn",
      "quanhuyen":"20311"
   },
   {
      "id":"2031115",
      "phuongxa":"Xã Đàm Thuỷ",
      "quanhuyen":"20311"
   },
   {
      "id":"2031117",
      "phuongxa":"Xã Lăng Hiếu",
      "quanhuyen":"20311"
   },
   {
      "id":"2031119",
      "phuongxa":"Xã Khâm Thành",
      "quanhuyen":"20311"
   },
   {
      "id":"2031121",
      "phuongxa":"Xã Đình Minh",
      "quanhuyen":"20311"
   },
   {
      "id":"2031123",
      "phuongxa":"Xã Phong Châu",
      "quanhuyen":"20311"
   },
   {
      "id":"2031125",
      "phuongxa":"Xã Cảnh Tiên",
      "quanhuyen":"20311"
   },
   {
      "id":"2031127",
      "phuongxa":"Xã Trung Phúc",
      "quanhuyen":"20311"
   },
   {
      "id":"2031129",
      "phuongxa":"Xã Đức Hồng",
      "quanhuyen":"20311"
   },
   {
      "id":"2031131",
      "phuongxa":"Xã Cao Thăng",
      "quanhuyen":"20311"
   },
   {
      "id":"2031133",
      "phuongxa":"Xã Thông Hoè",
      "quanhuyen":"20311"
   },
   {
      "id":"2031135",
      "phuongxa":"Xã Đoài Côn",
      "quanhuyen":"20311"
   },
   {
      "id":"2031300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20313"
   },
   {
      "id":"2031301",
      "phuongxa":"Thị trấn Nguyên Bình",
      "quanhuyen":"20313"
   },
   {
      "id":"2031303",
      "phuongxa":"Thị trấn Tĩnh Túc",
      "quanhuyen":"20313"
   },
   {
      "id":"2031305",
      "phuongxa":"Xã Yên Lạc",
      "quanhuyen":"20313"
   },
   {
      "id":"2031307",
      "phuongxa":"Xã Ca Thành",
      "quanhuyen":"20313"
   },
   {
      "id":"2031309",
      "phuongxa":"Xã Triệu Nguyên",
      "quanhuyen":"20313"
   },
   {
      "id":"2031311",
      "phuongxa":"Xã Vũ Nông",
      "quanhuyen":"20313"
   },
   {
      "id":"2031313",
      "phuongxa":"Xã Thái Học",
      "quanhuyen":"20313"
   },
   {
      "id":"2031315",
      "phuongxa":"Xã Thể Dục",
      "quanhuyen":"20313"
   },
   {
      "id":"2031317",
      "phuongxa":"Xã Bắc Hợp",
      "quanhuyen":"20313"
   },
   {
      "id":"2031319",
      "phuongxa":"Xã Minh Tâm",
      "quanhuyen":"20313"
   },
   {
      "id":"2031321",
      "phuongxa":"Xã Minh Thanh",
      "quanhuyen":"20313"
   },
   {
      "id":"2031323",
      "phuongxa":"Xã Lang Môn",
      "quanhuyen":"20313"
   },
   {
      "id":"2031325",
      "phuongxa":"Xã Mai Long",
      "quanhuyen":"20313"
   },
   {
      "id":"2031327",
      "phuongxa":"Xã Phan Thanh",
      "quanhuyen":"20313"
   },
   {
      "id":"2031329",
      "phuongxa":"Xã Quang Thành",
      "quanhuyen":"20313"
   },
   {
      "id":"2031331",
      "phuongxa":"Xã Tam Kim",
      "quanhuyen":"20313"
   },
   {
      "id":"2031333",
      "phuongxa":"Xã Hoa Thám",
      "quanhuyen":"20313"
   },
   {
      "id":"2031335",
      "phuongxa":"Xã Thành Công",
      "quanhuyen":"20313"
   },
   {
      "id":"2031339",
      "phuongxa":"Xã Thịnh Vượng",
      "quanhuyen":"20313"
   },
   {
      "id":"2031500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20315"
   },
   {
      "id":"2031501",
      "phuongxa":"Thị trấn Nước Hai",
      "quanhuyen":"20315"
   },
   {
      "id":"2031503",
      "phuongxa":"Xã Dân Chủ",
      "quanhuyen":"20315"
   },
   {
      "id":"2031505",
      "phuongxa":"Xã Nam Tuấn",
      "quanhuyen":"20315"
   },
   {
      "id":"2031507",
      "phuongxa":"Xã Đại Tiến",
      "quanhuyen":"20315"
   },
   {
      "id":"2031509",
      "phuongxa":"Xã Đức Xuân",
      "quanhuyen":"20315"
   },
   {
      "id":"2031511",
      "phuongxa":"Xã Đức Long",
      "quanhuyen":"20315"
   },
   {
      "id":"2031513",
      "phuongxa":"Xã Trương Lương",
      "quanhuyen":"20315"
   },
   {
      "id":"2031515",
      "phuongxa":"Xã Bình Long",
      "quanhuyen":"20315"
   },
   {
      "id":"2031517",
      "phuongxa":"Xã Bế Triều",
      "quanhuyen":"20315"
   },
   {
      "id":"2031519",
      "phuongxa":"Xã Ngũ Lão",
      "quanhuyen":"20315"
   },
   {
      "id":"2031523",
      "phuongxa":"Xã Hồng Việt",
      "quanhuyen":"20315"
   },
   {
      "id":"2031525",
      "phuongxa":"Xã Vĩnh Quang",
      "quanhuyen":"20315"
   },
   {
      "id":"2031527",
      "phuongxa":"Xã Nguyễn Huệ",
      "quanhuyen":"20315"
   },
   {
      "id":"2031529",
      "phuongxa":"Xã Hoàng Tung",
      "quanhuyen":"20315"
   },
   {
      "id":"2031531",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"20315"
   },
   {
      "id":"2031535",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"20315"
   },
   {
      "id":"2031537",
      "phuongxa":"Xã Trương Vương",
      "quanhuyen":"20315"
   },
   {
      "id":"2031541",
      "phuongxa":"Xã Bạch Đằng",
      "quanhuyen":"20315"
   },
   {
      "id":"2031543",
      "phuongxa":"Xã Lê Chung",
      "quanhuyen":"20315"
   },
   {
      "id":"2031547",
      "phuongxa":"Xã Hà Trì",
      "quanhuyen":"20315"
   },
   {
      "id":"2031549",
      "phuongxa":"Xã Hồng Nam",
      "quanhuyen":"20315"
   },
   {
      "id":"2031700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20317"
   },
   {
      "id":"2031701",
      "phuongxa":"Thị trấn Quảng Uyên",
      "quanhuyen":"20317"
   },
   {
      "id":"2031703",
      "phuongxa":"Xã Phi Hải",
      "quanhuyen":"20317"
   },
   {
      "id":"2031707",
      "phuongxa":"Xã Bình Lăng",
      "quanhuyen":"20317"
   },
   {
      "id":"2031709",
      "phuongxa":"Xã Độc Lập",
      "quanhuyen":"20317"
   },
   {
      "id":"2031711",
      "phuongxa":"Xã Quốc Phong",
      "quanhuyen":"20317"
   },
   {
      "id":"2031713",
      "phuongxa":"Xã Quốc Dân",
      "quanhuyen":"20317"
   },
   {
      "id":"2031715",
      "phuongxa":"Xã Đoài Khôn",
      "quanhuyen":"20317"
   },
   {
      "id":"2031719",
      "phuongxa":"Xã Chí Thảo",
      "quanhuyen":"20317"
   },
   {
      "id":"2031721",
      "phuongxa":"Xã Cai Bộ",
      "quanhuyen":"20317"
   },
   {
      "id":"2031723",
      "phuongxa":"Xã Tự Do",
      "quanhuyen":"20317"
   },
   {
      "id":"2031725",
      "phuongxa":"Xã Ngọc Động",
      "quanhuyen":"20317"
   },
   {
      "id":"2031727",
      "phuongxa":"Xã Hồng Định",
      "quanhuyen":"20317"
   },
   {
      "id":"2031729",
      "phuongxa":"Xã Hồng Quang",
      "quanhuyen":"20317"
   },
   {
      "id":"2031731",
      "phuongxa":"Xã Hồng Đại",
      "quanhuyen":"20323"
   },
   {
      "id":"2031733",
      "phuongxa":"Xã Triệu Âủ",
      "quanhuyen":"20317"
   },
   {
      "id":"2031735",
      "phuongxa":"Xã Hoàng Hải",
      "quanhuyen":"20317"
   },
   {
      "id":"2031737",
      "phuongxa":"Xã Hạnh Phúc",
      "quanhuyen":"20317"
   },
   {
      "id":"2031739",
      "phuongxa":"Xã Lương Thiện",
      "quanhuyen":"20317"
   },
   {
      "id":"2031741",
      "phuongxa":"Xã Cách Linh",
      "quanhuyen":"20317"
   },
   {
      "id":"2031743",
      "phuongxa":"Xã Đại Sơn",
      "quanhuyen":"20317"
   },
   {
      "id":"1151561",
      "phuongxa":"Xã Nam Trung",
      "quanhuyen":"11515"
   },
   {
      "id":"1151563",
      "phuongxa":"Xã Nam Hồng",
      "quanhuyen":"11515"
   },
   {
      "id":"1151565",
      "phuongxa":"Xã Nam Hưng",
      "quanhuyen":"11515"
   },
   {
      "id":"1151567",
      "phuongxa":"Xã Nam Hải",
      "quanhuyen":"11515"
   },
   {
      "id":"1151569",
      "phuongxa":"Xã Nam Phú",
      "quanhuyen":"11515"
   },
   {
      "id":"1170000",
      "phuongxa":"Không xác định",
      "quanhuyen":"11700"
   },
   {
      "id":"1170101",
      "phuongxa":"Phường Tân Thành",
      "quanhuyen":"11701"
   },
   {
      "id":"1170103",
      "phuongxa":"Phường Đông Thành",
      "quanhuyen":"11701"
   },
   {
      "id":"1170105",
      "phuongxa":"Phường Vân Giang",
      "quanhuyen":"11701"
   },
   {
      "id":"1170107",
      "phuongxa":"Phường Thanh Bình",
      "quanhuyen":"11701"
   },
   {
      "id":"1170109",
      "phuongxa":"Phường Phúc Thành",
      "quanhuyen":"11701"
   },
   {
      "id":"1170111",
      "phuongxa":"Phường Nam Thành",
      "quanhuyen":"11701"
   },
   {
      "id":"1170113",
      "phuongxa":"Phường Nam Bình",
      "quanhuyen":"11701"
   },
   {
      "id":"1170115",
      "phuongxa":"Phường Bích Đào",
      "quanhuyen":"11701"
   },
   {
      "id":"1170300",
      "phuongxa":"Phường Tây Sơn",
      "quanhuyen":"11703"
   },
   {
      "id":"1170301",
      "phuongxa":"Phường Bắc Sơn",
      "quanhuyen":"11703"
   },
   {
      "id":"1170303",
      "phuongxa":"Phường Trung Sơn",
      "quanhuyen":"11703"
   },
   {
      "id":"1170305",
      "phuongxa":"Phường Nam Sơn",
      "quanhuyen":"11703"
   },
   {
      "id":"1170307",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"11703"
   },
   {
      "id":"1170309",
      "phuongxa":"Phường Yên Bình",
      "quanhuyen":"11703"
   },
   {
      "id":"1170311",
      "phuongxa":"Xã Đông Sơn",
      "quanhuyen":"11703"
   },
   {
      "id":"1170312",
      "phuongxa":"Phường Tân Bình",
      "quanhuyen":"11703"
   },
   {
      "id":"1170313",
      "phuongxa":"Xã Quang Sơn",
      "quanhuyen":"11703"
   },
   {
      "id":"1170500",
      "phuongxa":"Không xác định",
      "quanhuyen":"11705"
   },
   {
      "id":"1170501",
      "phuongxa":"Thị trấn Nho Quan",
      "quanhuyen":"11705"
   },
   {
      "id":"1170503",
      "phuongxa":"Xã Xích Thổ",
      "quanhuyen":"11705"
   },
   {
      "id":"1170505",
      "phuongxa":"Xã Gia Lâm",
      "quanhuyen":"11705"
   },
   {
      "id":"1170507",
      "phuongxa":"Xã Thạch Bình",
      "quanhuyen":"11705"
   },
   {
      "id":"1170511",
      "phuongxa":"Xã Gia Thủy",
      "quanhuyen":"11705"
   },
   {
      "id":"1170513",
      "phuongxa":"Xã Gia Tường",
      "quanhuyen":"11705"
   },
   {
      "id":"1170515",
      "phuongxa":"Xã Đức Long",
      "quanhuyen":"11705"
   },
   {
      "id":"1170517",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"11705"
   },
   {
      "id":"1170519",
      "phuongxa":"Xã Lạc Vân",
      "quanhuyen":"11705"
   },
   {
      "id":"1170521",
      "phuongxa":"Xã Đồng Phong",
      "quanhuyen":"11705"
   },
   {
      "id":"1170523",
      "phuongxa":"Xã Lạng Phong",
      "quanhuyen":"11705"
   },
   {
      "id":"1170527",
      "phuongxa":"Xã Yên Quang",
      "quanhuyen":"11705"
   },
   {
      "id":"1170529",
      "phuongxa":"Xã Văn Phong",
      "quanhuyen":"11705"
   },
   {
      "id":"1170531",
      "phuongxa":"Xã Thượng Hòa",
      "quanhuyen":"11705"
   },
   {
      "id":"1170533",
      "phuongxa":"Xã Văn Phương",
      "quanhuyen":"11705"
   },
   {
      "id":"1170535",
      "phuongxa":"Xã Văn Phú",
      "quanhuyen":"11705"
   },
   {
      "id":"1170539",
      "phuongxa":"Xã Sơn Thành",
      "quanhuyen":"11705"
   },
   {
      "id":"1170541",
      "phuongxa":"Xã Sơn Lai",
      "quanhuyen":"11705"
   },
   {
      "id":"1170543",
      "phuongxa":"Xã Kỳ Phú",
      "quanhuyen":"11705"
   },
   {
      "id":"1170545",
      "phuongxa":"Xã Phú Lộc",
      "quanhuyen":"11705"
   },
   {
      "id":"1170547",
      "phuongxa":"Xã Quỳnh Lưu",
      "quanhuyen":"11705"
   },
   {
      "id":"1170549",
      "phuongxa":"Xã Sơn Hà",
      "quanhuyen":"11705"
   },
   {
      "id":"1170700",
      "phuongxa":"Không xác định",
      "quanhuyen":"11707"
   },
   {
      "id":"1170701",
      "phuongxa":"Thị trấn Me",
      "quanhuyen":"11707"
   },
   {
      "id":"1170703",
      "phuongxa":"Xã Gia Hưng",
      "quanhuyen":"11707"
   },
   {
      "id":"1170705",
      "phuongxa":"Xã Gia Hòa",
      "quanhuyen":"11707"
   },
   {
      "id":"1170709",
      "phuongxa":"Xã Gia Thanh",
      "quanhuyen":"11707"
   },
   {
      "id":"1170711",
      "phuongxa":"Xã Gia Vân",
      "quanhuyen":"11707"
   },
   {
      "id":"1170713",
      "phuongxa":"Xã Gia Xuân",
      "quanhuyen":"11707"
   },
   {
      "id":"1170715",
      "phuongxa":"Xã Gia Phú",
      "quanhuyen":"11707"
   },
   {
      "id":"1170717",
      "phuongxa":"Xã Gia Vượng",
      "quanhuyen":"11707"
   },
   {
      "id":"1170721",
      "phuongxa":"Xã Gia Phương",
      "quanhuyen":"11707"
   },
   {
      "id":"1170723",
      "phuongxa":"Xã Gia Lập",
      "quanhuyen":"11707"
   },
   {
      "id":"1170725",
      "phuongxa":"Xã Gia Tân",
      "quanhuyen":"11707"
   },
   {
      "id":"1170727",
      "phuongxa":"Xã Gia Trấn",
      "quanhuyen":"11707"
   },
   {
      "id":"1170729",
      "phuongxa":"Xã Gia Thắng",
      "quanhuyen":"11707"
   },
   {
      "id":"1170733",
      "phuongxa":"Xã Gia Minh",
      "quanhuyen":"11707"
   },
   {
      "id":"1170735",
      "phuongxa":"Xã Gia Lạc",
      "quanhuyen":"11707"
   },
   {
      "id":"1170737",
      "phuongxa":"Xã Gia Tiến",
      "quanhuyen":"11707"
   },
   {
      "id":"1170739",
      "phuongxa":"Xã Gia Sinh",
      "quanhuyen":"11707"
   },
   {
      "id":"1170741",
      "phuongxa":"Xã Gia Phong",
      "quanhuyen":"11707"
   },
   {
      "id":"1170900",
      "phuongxa":"Không xác định",
      "quanhuyen":"11709"
   },
   {
      "id":"1170901",
      "phuongxa":"Xã Ninh Khánh",
      "quanhuyen":"11709"
   },
   {
      "id":"1170903",
      "phuongxa":"Xã Ninh Giang",
      "quanhuyen":"11709"
   },
   {
      "id":"1170905",
      "phuongxa":"Xã Trường Yên",
      "quanhuyen":"11709"
   },
   {
      "id":"1170907",
      "phuongxa":"Xã Ninh Khang",
      "quanhuyen":"11709"
   },
   {
      "id":"1170909",
      "phuongxa":"Xã Ninh Mỹ",
      "quanhuyen":"11709"
   },
   {
      "id":"1170911",
      "phuongxa":"Xã Ninh Hòa",
      "quanhuyen":"11709"
   },
   {
      "id":"1170913",
      "phuongxa":"Xã Ninh Nhất",
      "quanhuyen":"11709"
   },
   {
      "id":"1170915",
      "phuongxa":"Xã Ninh Xuân",
      "quanhuyen":"11709"
   },
   {
      "id":"1170917",
      "phuongxa":"Xã Ninh Hải",
      "quanhuyen":"11709"
   },
   {
      "id":"1170919",
      "phuongxa":"Xã Ninh Tiến",
      "quanhuyen":"11709"
   },
   {
      "id":"1170921",
      "phuongxa":"Xã Ninh Sơn",
      "quanhuyen":"11709"
   },
   {
      "id":"1170923",
      "phuongxa":"Xã Ninh Phúc",
      "quanhuyen":"11709"
   },
   {
      "id":"1170925",
      "phuongxa":"Xã Ninh Phong",
      "quanhuyen":"11709"
   },
   {
      "id":"1170927",
      "phuongxa":"Xã Ninh Thắng",
      "quanhuyen":"11709"
   },
   {
      "id":"1170931",
      "phuongxa":"Xã Ninh An",
      "quanhuyen":"11709"
   },
   {
      "id":"1171100",
      "phuongxa":"Không xác định",
      "quanhuyen":"11711"
   },
   {
      "id":"1171101",
      "phuongxa":"Thị trấn Yên Thịnh",
      "quanhuyen":"11711"
   },
   {
      "id":"1171103",
      "phuongxa":"Xã Khánh Thượng",
      "quanhuyen":"11711"
   },
   {
      "id":"1171105",
      "phuongxa":"Xã Khánh Dương",
      "quanhuyen":"11711"
   },
   {
      "id":"1171107",
      "phuongxa":"Xã Khánh Thịnh",
      "quanhuyen":"11711"
   },
   {
      "id":"1171109",
      "phuongxa":"Xã Yên Phong",
      "quanhuyen":"11711"
   },
   {
      "id":"1171111",
      "phuongxa":"Xã Yên Phú",
      "quanhuyen":"11711"
   },
   {
      "id":"1171113",
      "phuongxa":"Xã Yên Hòa",
      "quanhuyen":"11711"
   },
   {
      "id":"1171115",
      "phuongxa":"Xã Yên Thắng",
      "quanhuyen":"11711"
   },
   {
      "id":"1171117",
      "phuongxa":"Xã Yên Từ",
      "quanhuyen":"11711"
   },
   {
      "id":"1171119",
      "phuongxa":"Xã Yên Mỹ",
      "quanhuyen":"11711"
   },
   {
      "id":"1171121",
      "phuongxa":"Xã Yên Nhân",
      "quanhuyen":"11711"
   },
   {
      "id":"1171123",
      "phuongxa":"Xã Yên Thành",
      "quanhuyen":"11711"
   },
   {
      "id":"1171125",
      "phuongxa":"Xã Yên Mạc",
      "quanhuyen":"11711"
   },
   {
      "id":"1171127",
      "phuongxa":"Xã Yên Đồng",
      "quanhuyen":"11711"
   },
   {
      "id":"1171129",
      "phuongxa":"Xã Yên Thái",
      "quanhuyen":"11711"
   },
   {
      "id":"1171131",
      "phuongxa":"Xã Yên Lâm",
      "quanhuyen":"11711"
   },
   {
      "id":"1171300",
      "phuongxa":"Không xác định",
      "quanhuyen":"11713"
   },
   {
      "id":"1171303",
      "phuongxa":"Xã Khánh Tiên",
      "quanhuyen":"11713"
   },
   {
      "id":"1171305",
      "phuongxa":"Xã Khánh Phú",
      "quanhuyen":"11713"
   },
   {
      "id":"1171307",
      "phuongxa":"Xã Khánh Hòa",
      "quanhuyen":"11713"
   },
   {
      "id":"1171309",
      "phuongxa":"Xã Khánh An",
      "quanhuyen":"11713"
   },
   {
      "id":"1171311",
      "phuongxa":"Xã Khánh Lợi",
      "quanhuyen":"11713"
   },
   {
      "id":"1171313",
      "phuongxa":"Xã Khánh Thiện",
      "quanhuyen":"11713"
   },
   {
      "id":"1171317",
      "phuongxa":"Xã Khánh Trung",
      "quanhuyen":"11713"
   },
   {
      "id":"1171319",
      "phuongxa":"Xã Khánh Cư",
      "quanhuyen":"11713"
   },
   {
      "id":"1171321",
      "phuongxa":"Xã Khánh Hải",
      "quanhuyen":"11713"
   },
   {
      "id":"1171323",
      "phuongxa":"Xã Khánh Mậu",
      "quanhuyen":"11713"
   },
   {
      "id":"1171325",
      "phuongxa":"Xã Khánh Vân",
      "quanhuyen":"11713"
   },
   {
      "id":"1171329",
      "phuongxa":"Xã Khánh Hội",
      "quanhuyen":"11713"
   },
   {
      "id":"1171331",
      "phuongxa":"Xã Khánh Nhạc",
      "quanhuyen":"11713"
   },
   {
      "id":"1171333",
      "phuongxa":"Xã Khánh Công",
      "quanhuyen":"11713"
   },
   {
      "id":"1171335",
      "phuongxa":"Xã Khánh Thành",
      "quanhuyen":"11713"
   },
   {
      "id":"1171337",
      "phuongxa":"Xã Khánh Thủy",
      "quanhuyen":"11713"
   },
   {
      "id":"1171500",
      "phuongxa":"Không xác định",
      "quanhuyen":"11715"
   },
   {
      "id":"1171501",
      "phuongxa":"Thị trấn Phát Diệm",
      "quanhuyen":"11715"
   },
   {
      "id":"1171503",
      "phuongxa":"Thị trấn Bình Minh",
      "quanhuyen":"11715"
   },
   {
      "id":"1171505",
      "phuongxa":"Xã Xuân Thiện",
      "quanhuyen":"11715"
   },
   {
      "id":"1171507",
      "phuongxa":"Xã Chính Tâm",
      "quanhuyen":"11715"
   },
   {
      "id":"1171511",
      "phuongxa":"Xã Chất Bình",
      "quanhuyen":"11715"
   },
   {
      "id":"1171513",
      "phuongxa":"Xã Kim Định",
      "quanhuyen":"11715"
   },
   {
      "id":"1171517",
      "phuongxa":"Xã Hùng Tiến",
      "quanhuyen":"11715"
   },
   {
      "id":"1171519",
      "phuongxa":"Xã Như Hòa",
      "quanhuyen":"11715"
   },
   {
      "id":"1171521",
      "phuongxa":"Xã Quang Thiện",
      "quanhuyen":"11715"
   },
   {
      "id":"1171523",
      "phuongxa":"Xã Yên Mật",
      "quanhuyen":"11715"
   },
   {
      "id":"1171525",
      "phuongxa":"Xã Đồng Hướng",
      "quanhuyen":"11715"
   },
   {
      "id":"1171527",
      "phuongxa":"Xã Kim Chính",
      "quanhuyen":"11715"
   },
   {
      "id":"1171529",
      "phuongxa":"Xã Thượng Kiệm",
      "quanhuyen":"11715"
   },
   {
      "id":"1171531",
      "phuongxa":"Xã Lưu Phương",
      "quanhuyen":"11715"
   },
   {
      "id":"1171533",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"11715"
   },
   {
      "id":"1171535",
      "phuongxa":"Xã Yên Lộc",
      "quanhuyen":"11715"
   },
   {
      "id":"1171537",
      "phuongxa":"Xã Lai Thành",
      "quanhuyen":"11715"
   },
   {
      "id":"1171539",
      "phuongxa":"Xã Định Hóa",
      "quanhuyen":"11715"
   },
   {
      "id":"1171541",
      "phuongxa":"Xã Văn Hải",
      "quanhuyen":"11715"
   },
   {
      "id":"1171543",
      "phuongxa":"Xã Kim Tân",
      "quanhuyen":"11715"
   },
   {
      "id":"1171545",
      "phuongxa":"Xã Kim Mỹ",
      "quanhuyen":"11715"
   },
   {
      "id":"1171547",
      "phuongxa":"Xã Cồn Thoi",
      "quanhuyen":"11715"
   },
   {
      "id":"1171549",
      "phuongxa":"Xã Kim Hải",
      "quanhuyen":"11715"
   },
   {
      "id":"1171551",
      "phuongxa":"Xã Kim Trung",
      "quanhuyen":"11715"
   },
   {
      "id":"2010000",
      "phuongxa":"Không xác định",
      "quanhuyen":"20100"
   },
   {
      "id":"2010100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20101"
   },
   {
      "id":"2010101",
      "phuongxa":"Phường Trần Phú",
      "quanhuyen":"20101"
   },
   {
      "id":"2010103",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"20101"
   },
   {
      "id":"2010105",
      "phuongxa":"Phường Nguyễn Trãi",
      "quanhuyen":"20101"
   },
   {
      "id":"2010107",
      "phuongxa":"Phường Minh Khai",
      "quanhuyen":"20101"
   },
   {
      "id":"2010109",
      "phuongxa":"Xã Ngọc Đường",
      "quanhuyen":"20101"
   },
   {
      "id":"2010111",
      "phuongxa":"Xã Kim Thạch",
      "quanhuyen":"20101"
   },
   {
      "id":"2010113",
      "phuongxa":"Xã Phú Linh",
      "quanhuyen":"20101"
   },
   {
      "id":"2010115",
      "phuongxa":"Xã Kim Linh",
      "quanhuyen":"20101"
   },
   {
      "id":"2010133",
      "phuongxa":"Thị trấn Yên Minh",
      "quanhuyen":"20101"
   },
   {
      "id":"2010300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20103"
   },
   {
      "id":"2010301",
      "phuongxa":"Thị trấn Phó Bảng",
      "quanhuyen":"20103"
   },
   {
      "id":"2010303",
      "phuongxa":"Xã Đồng Văn",
      "quanhuyen":"20103"
   },
   {
      "id":"2010305",
      "phuongxa":"Xã Lũng Cú",
      "quanhuyen":"20103"
   },
   {
      "id":"2010307",
      "phuongxa":"Xã Má Lé",
      "quanhuyen":"20103"
   },
   {
      "id":"2010309",
      "phuongxa":"Xã Lũng Táo",
      "quanhuyen":"20103"
   },
   {
      "id":"2010311",
      "phuongxa":"Xã Thài Phìn Tủng",
      "quanhuyen":"20103"
   },
   {
      "id":"2010313",
      "phuongxa":"Xã Phố Là",
      "quanhuyen":"20103"
   },
   {
      "id":"2010317",
      "phuongxa":"Xã Sủng Là",
      "quanhuyen":"20103"
   },
   {
      "id":"2010319",
      "phuongxa":"Xã Tả Phìn",
      "quanhuyen":"20103"
   },
   {
      "id":"2010321",
      "phuongxa":"Xã Sính Lủng",
      "quanhuyen":"20103"
   },
   {
      "id":"2010323",
      "phuongxa":"Xã Tả Lủng",
      "quanhuyen":"20103"
   },
   {
      "id":"2010325",
      "phuongxa":"Xã Phố Cáo",
      "quanhuyen":"20103"
   },
   {
      "id":"2010327",
      "phuongxa":"Xã Sảng Tủng",
      "quanhuyen":"20103"
   },
   {
      "id":"2010329",
      "phuongxa":"Xã Lũng Thầu",
      "quanhuyen":"20103"
   },
   {
      "id":"2010331",
      "phuongxa":"Xã Hố Quáng Phìn",
      "quanhuyen":"20103"
   },
   {
      "id":"2010333",
      "phuongxa":"Xã Vần Chải",
      "quanhuyen":"20103"
   },
   {
      "id":"2010335",
      "phuongxa":"Xã Lũng Phìn",
      "quanhuyen":"20103"
   },
   {
      "id":"2010337",
      "phuongxa":"Xã Sủng Trái",
      "quanhuyen":"20103"
   },
   {
      "id":"2010500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20105"
   },
   {
      "id":"2010503",
      "phuongxa":"Xã Thượng Phùng",
      "quanhuyen":"20105"
   },
   {
      "id":"2010505",
      "phuongxa":"Xã Pải Lủng",
      "quanhuyen":"20105"
   },
   {
      "id":"2010507",
      "phuongxa":"Xã Pả Vi",
      "quanhuyen":"20105"
   },
   {
      "id":"2010509",
      "phuongxa":"Xã Xín Cái",
      "quanhuyen":"20105"
   },
   {
      "id":"2010511",
      "phuongxa":"Xã Sủng Chà",
      "quanhuyen":"20105"
   },
   {
      "id":"2010515",
      "phuongxa":"Xã Sủng Máng",
      "quanhuyen":"20105"
   },
   {
      "id":"2010519",
      "phuongxa":"Xã Sơn Vĩ",
      "quanhuyen":"20105"
   },
   {
      "id":"2010521",
      "phuongxa":"Xã Lũng Chinh",
      "quanhuyen":"20105"
   },
   {
      "id":"2010523",
      "phuongxa":"Xã Tát Ngà",
      "quanhuyen":"20105"
   },
   {
      "id":"2010525",
      "phuongxa":"Xã Lũng Pù",
      "quanhuyen":"20105"
   },
   {
      "id":"2010527",
      "phuongxa":"Xã Nậm Ban",
      "quanhuyen":"20105"
   },
   {
      "id":"2010531",
      "phuongxa":"Xã Niêm Sơn",
      "quanhuyen":"20105"
   },
   {
      "id":"2010700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20107"
   },
   {
      "id":"2010701",
      "phuongxa":"Xã Yên Minh",
      "quanhuyen":"20107"
   },
   {
      "id":"2010703",
      "phuongxa":"Xã Phú Lũng",
      "quanhuyen":"20107"
   },
   {
      "id":"2010705",
      "phuongxa":"Xã Thắng Mố",
      "quanhuyen":"20107"
   },
   {
      "id":"2010709",
      "phuongxa":"Xã Sủng Tráng",
      "quanhuyen":"20107"
   },
   {
      "id":"2010711",
      "phuongxa":"Xã Sủng Thài",
      "quanhuyen":"20107"
   },
   {
      "id":"2010713",
      "phuongxa":"Xã Na Khê",
      "quanhuyen":"20107"
   },
   {
      "id":"2010715",
      "phuongxa":"Xã Lao Và Chải",
      "quanhuyen":"20107"
   },
   {
      "id":"2010717",
      "phuongxa":"Xã Mậu Duệ",
      "quanhuyen":"20107"
   },
   {
      "id":"2010721",
      "phuongxa":"Xã Mậu Long",
      "quanhuyen":"20107"
   },
   {
      "id":"2010723",
      "phuongxa":"Xã Ngọc Long",
      "quanhuyen":"20107"
   },
   {
      "id":"2010725",
      "phuongxa":"Xã Đường Thượng",
      "quanhuyen":"20107"
   },
   {
      "id":"2010727",
      "phuongxa":"Xã Lũng Hồ",
      "quanhuyen":"20107"
   },
   {
      "id":"2010729",
      "phuongxa":"Xã Du Tiến",
      "quanhuyen":"20107"
   },
   {
      "id":"2010731",
      "phuongxa":"Xã Du Già",
      "quanhuyen":"20107"
   },
   {
      "id":"2010735",
      "phuongxa":"Xã Hữu Vinh",
      "quanhuyen":"20107"
   },
   {
      "id":"2010900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20109"
   },
   {
      "id":"2010901",
      "phuongxa":"Xã Quản Bạ",
      "quanhuyen":"20109"
   },
   {
      "id":"2010903",
      "phuongxa":"Xã Bát Đại Sơn",
      "quanhuyen":"20109"
   },
   {
      "id":"2010905",
      "phuongxa":"Xã Nghĩa Thuận",
      "quanhuyen":"20109"
   },
   {
      "id":"2010907",
      "phuongxa":"Xã Cán Tỷ",
      "quanhuyen":"20109"
   },
   {
      "id":"2010909",
      "phuongxa":"Xã Cao Mã Pờ",
      "quanhuyen":"20109"
   },
   {
      "id":"2010911",
      "phuongxa":"Xã Thanh Vân",
      "quanhuyen":"20109"
   },
   {
      "id":"2010915",
      "phuongxa":"Xã Đông Hà",
      "quanhuyen":"20109"
   },
   {
      "id":"2010917",
      "phuongxa":"Xã Lùng Tám",
      "quanhuyen":"20109"
   },
   {
      "id":"2010919",
      "phuongxa":"Xã Quyết Tiến",
      "quanhuyen":"20109"
   },
   {
      "id":"2010921",
      "phuongxa":"Xã Tả Ván",
      "quanhuyen":"20109"
   },
   {
      "id":"2010923",
      "phuongxa":"Xã Thái An",
      "quanhuyen":"20109"
   },
   {
      "id":"2010925",
      "phuongxa":"Thị trấn Tam Sơn",
      "quanhuyen":"20109"
   },
   {
      "id":"2011100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20111"
   },
   {
      "id":"2011101",
      "phuongxa":"Xã Yên Phú",
      "quanhuyen":"20111"
   },
   {
      "id":"2011103",
      "phuongxa":"Xã Minh Sơn",
      "quanhuyen":"20111"
   },
   {
      "id":"2011105",
      "phuongxa":"Xã Giáp Trung",
      "quanhuyen":"20111"
   },
   {
      "id":"2011107",
      "phuongxa":"Xã Yên Phong",
      "quanhuyen":"20111"
   },
   {
      "id":"2011109",
      "phuongxa":"Xã Yên Định",
      "quanhuyen":"20111"
   },
   {
      "id":"2011111",
      "phuongxa":"Xã Lạc Nông",
      "quanhuyen":"20111"
   },
   {
      "id":"2011113",
      "phuongxa":"Xã Phú Nam",
      "quanhuyen":"20111"
   },
   {
      "id":"2011115",
      "phuongxa":"Xã Minh Ngọc",
      "quanhuyen":"20111"
   },
   {
      "id":"2011117",
      "phuongxa":"Xã Thượng Tân",
      "quanhuyen":"20111"
   },
   {
      "id":"2011119",
      "phuongxa":"Xã Yên Cường",
      "quanhuyen":"20111"
   },
   {
      "id":"2011121",
      "phuongxa":"Xã Đường Hồng",
      "quanhuyen":"20111"
   },
   {
      "id":"2011123",
      "phuongxa":"Xã Đường Âm",
      "quanhuyen":"20111"
   },
   {
      "id":"2011300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20113"
   },
   {
      "id":"2011301",
      "phuongxa":"Xã Vinh Quang",
      "quanhuyen":"20113"
   },
   {
      "id":"2011303",
      "phuongxa":"Xã Bản Máy",
      "quanhuyen":"20113"
   },
   {
      "id":"2011305",
      "phuongxa":"Xã Thàng Tín",
      "quanhuyen":"20113"
   },
   {
      "id":"2011307",
      "phuongxa":"Xã Thèn Chu Phìn",
      "quanhuyen":"20113"
   },
   {
      "id":"2011309",
      "phuongxa":"Xã Bản Phùng",
      "quanhuyen":"20113"
   },
   {
      "id":"2011311",
      "phuongxa":"Xã Chiến Phố",
      "quanhuyen":"20113"
   },
   {
      "id":"2011313",
      "phuongxa":"Xã Đản Ván",
      "quanhuyen":"20113"
   },
   {
      "id":"2011315",
      "phuongxa":"Xã Pố Lồ",
      "quanhuyen":"20113"
   },
   {
      "id":"2011317",
      "phuongxa":"Xã Túng Sán",
      "quanhuyen":"20113"
   },
   {
      "id":"2011319",
      "phuongxa":"Xã Tụ Nhân",
      "quanhuyen":"20113"
   },
   {
      "id":"2011321",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"20113"
   },
   {
      "id":"2011323",
      "phuongxa":"Xã Pờ Ly Ngài",
      "quanhuyen":"20113"
   },
   {
      "id":"2011325",
      "phuongxa":"Xã Ngàm Đăng Vài",
      "quanhuyen":"20113"
   },
   {
      "id":"2011327",
      "phuongxa":"Xã Bản Nhùng",
      "quanhuyen":"20113"
   },
   {
      "id":"2011329",
      "phuongxa":"Xã Nàng Đôn",
      "quanhuyen":"20113"
   },
   {
      "id":"2011331",
      "phuongxa":"Xã Sán Xả Hồ",
      "quanhuyen":"20113"
   },
   {
      "id":"2011333",
      "phuongxa":"Xã Bản Luốc",
      "quanhuyen":"20113"
   },
   {
      "id":"2011335",
      "phuongxa":"Xã Tả Sử Choóng",
      "quanhuyen":"20113"
   },
   {
      "id":"2011337",
      "phuongxa":"Xã Bản Péo",
      "quanhuyen":"20113"
   },
   {
      "id":"2011339",
      "phuongxa":"Xã Nậm Dịch",
      "quanhuyen":"20113"
   },
   {
      "id":"2011341",
      "phuongxa":"Xã Hồ Thầu",
      "quanhuyen":"20113"
   },
   {
      "id":"2011347",
      "phuongxa":"Xã Nậm Tỵ",
      "quanhuyen":"20113"
   },
   {
      "id":"2011349",
      "phuongxa":"Xã Nậm Khòa",
      "quanhuyen":"20113"
   },
   {
      "id":"2011351",
      "phuongxa":"Xã Tiên Nguyên",
      "quanhuyen":"20113"
   },
   {
      "id":"2011500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20115"
   },
   {
      "id":"2011501",
      "phuongxa":"Thị trấn Vị Xuyên",
      "quanhuyen":"20115"
   },
   {
      "id":"2011503",
      "phuongxa":"Thị trấn NT Việt Lâm",
      "quanhuyen":"20115"
   },
   {
      "id":"2011505",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"20115"
   },
   {
      "id":"2011507",
      "phuongxa":"Xã Thuận Hoà",
      "quanhuyen":"20115"
   },
   {
      "id":"2011511",
      "phuongxa":"Xã Thanh Đức",
      "quanhuyen":"20115"
   },
   {
      "id":"2011513",
      "phuongxa":"Xã Tùng Bá",
      "quanhuyen":"20115"
   },
   {
      "id":"2011515",
      "phuongxa":"Xã Phong Quang",
      "quanhuyen":"20115"
   },
   {
      "id":"2011517",
      "phuongxa":"Xã Xín Chải",
      "quanhuyen":"20115"
   },
   {
      "id":"2011519",
      "phuongxa":"Xã Phương Tiến",
      "quanhuyen":"20115"
   },
   {
      "id":"2011523",
      "phuongxa":"Xã Phương Độ",
      "quanhuyen":"20115"
   },
   {
      "id":"2011525",
      "phuongxa":"Xã Phương Thiện",
      "quanhuyen":"20115"
   },
   {
      "id":"2011527",
      "phuongxa":"Xã Cao Bồ",
      "quanhuyen":"20115"
   },
   {
      "id":"2011529",
      "phuongxa":"Xã Đạo Đức",
      "quanhuyen":"20115"
   },
   {
      "id":"2011531",
      "phuongxa":"Xã Linh Hồ",
      "quanhuyen":"20115"
   },
   {
      "id":"2011533",
      "phuongxa":"Xã Thượng Sơn",
      "quanhuyen":"20115"
   },
   {
      "id":"2011535",
      "phuongxa":"Xã Ngọc Linh",
      "quanhuyen":"20115"
   },
   {
      "id":"2011537",
      "phuongxa":"Xã Việt Lâm",
      "quanhuyen":"20115"
   },
   {
      "id":"2011539",
      "phuongxa":"Xã Ngọc Minh",
      "quanhuyen":"20115"
   },
   {
      "id":"2011541",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"20115"
   },
   {
      "id":"2011543",
      "phuongxa":"Xã Quảng Ngần",
      "quanhuyen":"20115"
   },
   {
      "id":"2011545",
      "phuongxa":"Xã Bạch Ngọc",
      "quanhuyen":"20115"
   },
   {
      "id":"2011700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20117"
   },
   {
      "id":"2011703",
      "phuongxa":"Xã Nàn Xỉn",
      "quanhuyen":"20117"
   },
   {
      "id":"2011705",
      "phuongxa":"Xã Bản Díu",
      "quanhuyen":"20117"
   },
   {
      "id":"2011707",
      "phuongxa":"Xã Chí Cà",
      "quanhuyen":"20117"
   },
   {
      "id":"2011709",
      "phuongxa":"Xã Xín Mần",
      "quanhuyen":"20117"
   },
   {
      "id":"2011711",
      "phuongxa":"Xã Trung Thịnh",
      "quanhuyen":"20117"
   },
   {
      "id":"2011713",
      "phuongxa":"Xã Thèn Phàng",
      "quanhuyen":"20117"
   },
   {
      "id":"2011715",
      "phuongxa":"Xã Ngán Chiên",
      "quanhuyen":"20117"
   },
   {
      "id":"2011717",
      "phuongxa":"Xã Pà Vầy Sủ",
      "quanhuyen":"20117"
   },
   {
      "id":"2011719",
      "phuongxa":"Xã Cốc Rế",
      "quanhuyen":"20117"
   },
   {
      "id":"2011721",
      "phuongxa":"Xã Thu Tà",
      "quanhuyen":"20117"
   },
   {
      "id":"2011723",
      "phuongxa":"Xã Nàn Ma",
      "quanhuyen":"20117"
   },
   {
      "id":"2011725",
      "phuongxa":"Xã Tả Nhìu",
      "quanhuyen":"20117"
   },
   {
      "id":"2011727",
      "phuongxa":"Xã Bản Ngò",
      "quanhuyen":"20117"
   },
   {
      "id":"2011729",
      "phuongxa":"Xã Chế Là",
      "quanhuyen":"20117"
   },
   {
      "id":"2011731",
      "phuongxa":"Xã Nấm Dẩn",
      "quanhuyen":"20117"
   },
   {
      "id":"2011733",
      "phuongxa":"Xã Quảng Nguyên",
      "quanhuyen":"20117"
   },
   {
      "id":"2011735",
      "phuongxa":"Xã Nà Chì",
      "quanhuyen":"20117"
   },
   {
      "id":"2011737",
      "phuongxa":"Xã Khuôn Lùng",
      "quanhuyen":"20117"
   },
   {
      "id":"2011900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20119"
   },
   {
      "id":"2011901",
      "phuongxa":"Thị trấn Việt Quang",
      "quanhuyen":"20119"
   },
   {
      "id":"2011903",
      "phuongxa":"Thị trấn Vĩnh Tuy",
      "quanhuyen":"20119"
   },
   {
      "id":"2011905",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"20119"
   },
   {
      "id":"2011907",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"20119"
   },
   {
      "id":"2011909",
      "phuongxa":"Xã Đồng Tâm",
      "quanhuyen":"20119"
   },
   {
      "id":"2011911",
      "phuongxa":"Xã Thượng Bình",
      "quanhuyen":"20119"
   },
   {
      "id":"2011913",
      "phuongxa":"Xã Tân Quang",
      "quanhuyen":"20119"
   },
   {
      "id":"2011915",
      "phuongxa":"Xã Việt Vinh",
      "quanhuyen":"20119"
   },
   {
      "id":"2011917",
      "phuongxa":"Xã Bằng Hành",
      "quanhuyen":"20119"
   },
   {
      "id":"2011919",
      "phuongxa":"Xã Kim Ngọc",
      "quanhuyen":"20119"
   },
   {
      "id":"2011921",
      "phuongxa":"Xã Bản Rịa",
      "quanhuyen":"20119"
   },
   {
      "id":"2011923",
      "phuongxa":"Xã Yên Bình",
      "quanhuyen":"20119"
   },
   {
      "id":"2011925",
      "phuongxa":"Xã Hữu Sản",
      "quanhuyen":"20119"
   },
   {
      "id":"2011927",
      "phuongxa":"Xã Tân Trịnh",
      "quanhuyen":"20119"
   },
   {
      "id":"2011929",
      "phuongxa":"Xã Quang Minh",
      "quanhuyen":"20119"
   },
   {
      "id":"2011931",
      "phuongxa":"Xã Liên Hiệp",
      "quanhuyen":"20119"
   },
   {
      "id":"2011933",
      "phuongxa":"Xã Vô Điếm",
      "quanhuyen":"20119"
   },
   {
      "id":"2011935",
      "phuongxa":"Xã Việt Hồng",
      "quanhuyen":"20119"
   },
   {
      "id":"2011941",
      "phuongxa":"Xã Hùng An",
      "quanhuyen":"20119"
   },
   {
      "id":"2011943",
      "phuongxa":"Xã Đức Xuân",
      "quanhuyen":"20119"
   },
   {
      "id":"2011945",
      "phuongxa":"Xã Hương Sơn",
      "quanhuyen":"20119"
   },
   {
      "id":"2011947",
      "phuongxa":"Xã Xuân Giang",
      "quanhuyen":"20119"
   },
   {
      "id":"2011951",
      "phuongxa":"Xã Tiên Yên",
      "quanhuyen":"20119"
   },
   {
      "id":"2011953",
      "phuongxa":"Xã Vĩnh Hảo",
      "quanhuyen":"20119"
   },
   {
      "id":"2011955",
      "phuongxa":"Xã Nà Khương",
      "quanhuyen":"20119"
   },
   {
      "id":"2011957",
      "phuongxa":"Xã Vĩnh Phúc",
      "quanhuyen":"20119"
   },
   {
      "id":"2011959",
      "phuongxa":"Xã Vĩ Thượng",
      "quanhuyen":"20119"
   },
   {
      "id":"2011961",
      "phuongxa":"Xã Đồng Yên",
      "quanhuyen":"20119"
   },
   {
      "id":"2011965",
      "phuongxa":"Xã Yên Thành",
      "quanhuyen":"20119"
   },
   {
      "id":"2012100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20121"
   },
   {
      "id":"2012101",
      "phuongxa":"Xã Xuân Minh",
      "quanhuyen":"20121"
   },
   {
      "id":"2012103",
      "phuongxa":"Xã Tiên Nguyên",
      "quanhuyen":"20121"
   },
   {
      "id":"2012105",
      "phuongxa":"Xã Tân Nam",
      "quanhuyen":"20121"
   },
   {
      "id":"2012109",
      "phuongxa":"Xã Yên Thành",
      "quanhuyen":"20121"
   },
   {
      "id":"2012111",
      "phuongxa":"Xã Yên Bình",
      "quanhuyen":"20121"
   },
   {
      "id":"2012113",
      "phuongxa":"Xã Tân Trịnh",
      "quanhuyen":"20121"
   },
   {
      "id":"2012115",
      "phuongxa":"Xã Tân Bắc",
      "quanhuyen":"20121"
   },
   {
      "id":"2012121",
      "phuongxa":"Xã Hương Sơn",
      "quanhuyen":"20121"
   },
   {
      "id":"2012123",
      "phuongxa":"Xã Xuân Giang",
      "quanhuyen":"20121"
   },
   {
      "id":"2012125",
      "phuongxa":"Xã Nà Khương",
      "quanhuyen":"20121"
   },
   {
      "id":"2012127",
      "phuongxa":"Xã Tiên Yên",
      "quanhuyen":"20121"
   },
   {
      "id":"2012129",
      "phuongxa":"Xã Vĩ Thượng",
      "quanhuyen":"20121"
   },
   {
      "id":"2030000",
      "phuongxa":"Không xác định",
      "quanhuyen":"20300"
   },
   {
      "id":"2030100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20301"
   },
   {
      "id":"2030101",
      "phuongxa":"Phường Hợp Giang",
      "quanhuyen":"20301"
   },
   {
      "id":"2030103",
      "phuongxa":"Phường Tân Giang",
      "quanhuyen":"20301"
   },
   {
      "id":"2030105",
      "phuongxa":"Phường Sông Hiến",
      "quanhuyen":"20301"
   },
   {
      "id":"2030107",
      "phuongxa":"Phường Sông Bằng",
      "quanhuyen":"20301"
   },
   {
      "id":"2030109",
      "phuongxa":"Xã Ngọc Xuân",
      "quanhuyen":"20301"
   },
   {
      "id":"2030111",
      "phuongxa":"Xã Hoà Chung",
      "quanhuyen":"20301"
   },
   {
      "id":"2030113",
      "phuongxa":"Xã Duyệt Trung",
      "quanhuyen":"20301"
   },
   {
      "id":"2050521",
      "phuongxa":"Xã Cao Sơn",
      "quanhuyen":"20505"
   },
   {
      "id":"2050523",
      "phuongxa":"Xã Lùng Vai",
      "quanhuyen":"20505"
   },
   {
      "id":"2050525",
      "phuongxa":"Xã Bản Lầu",
      "quanhuyen":"20505"
   },
   {
      "id":"2050527",
      "phuongxa":"Xã La Pan Tẩn",
      "quanhuyen":"20505"
   },
   {
      "id":"2050531",
      "phuongxa":"Xã Bản Sen",
      "quanhuyen":"20505"
   },
   {
      "id":"2050700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20507"
   },
   {
      "id":"2050701",
      "phuongxa":"Thị trấn Bát Xát",
      "quanhuyen":"20507"
   },
   {
      "id":"2050703",
      "phuongxa":"Xã A Mú Sung",
      "quanhuyen":"20507"
   },
   {
      "id":"2050705",
      "phuongxa":"Xã Nậm Chạc",
      "quanhuyen":"20507"
   },
   {
      "id":"2050707",
      "phuongxa":"Xã A Lù",
      "quanhuyen":"20507"
   },
   {
      "id":"2050709",
      "phuongxa":"Xã Trịnh Tường",
      "quanhuyen":"20507"
   },
   {
      "id":"2050711",
      "phuongxa":"Xã Ngải Thầu",
      "quanhuyen":"20507"
   },
   {
      "id":"2050713",
      "phuongxa":"Xã Y Tý",
      "quanhuyen":"20507"
   },
   {
      "id":"2050715",
      "phuongxa":"Xã Cốc Mỳ",
      "quanhuyen":"20507"
   },
   {
      "id":"2050717",
      "phuongxa":"Xã Dền Sáng",
      "quanhuyen":"20507"
   },
   {
      "id":"2050719",
      "phuongxa":"Xã Dền Thàng",
      "quanhuyen":"20507"
   },
   {
      "id":"2050721",
      "phuongxa":"Xã Sàng Ma Sáo",
      "quanhuyen":"20507"
   },
   {
      "id":"2050723",
      "phuongxa":"Xã Bản Vược",
      "quanhuyen":"20507"
   },
   {
      "id":"2050725",
      "phuongxa":"Xã Mường Vi",
      "quanhuyen":"20507"
   },
   {
      "id":"2050727",
      "phuongxa":"Xã Bản Xèo",
      "quanhuyen":"20507"
   },
   {
      "id":"2050729",
      "phuongxa":"Xã Bản Qua",
      "quanhuyen":"20507"
   },
   {
      "id":"2050731",
      "phuongxa":"Xã Mường Hum",
      "quanhuyen":"20507"
   },
   {
      "id":"2050733",
      "phuongxa":"Xã Trung Lèng Hồ",
      "quanhuyen":"20507"
   },
   {
      "id":"2050737",
      "phuongxa":"Xã Nậm Pung",
      "quanhuyen":"20507"
   },
   {
      "id":"2050739",
      "phuongxa":"Xã Quang Kim",
      "quanhuyen":"20507"
   },
   {
      "id":"2050741",
      "phuongxa":"Xã Phìn Ngan",
      "quanhuyen":"20507"
   },
   {
      "id":"2050743",
      "phuongxa":"Xã Cốc San",
      "quanhuyen":"20507"
   },
   {
      "id":"2050745",
      "phuongxa":"Xã Tòng Sành",
      "quanhuyen":"20507"
   },
   {
      "id":"2050900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20509"
   },
   {
      "id":"2050901",
      "phuongxa":"Thị trấn Bắc Hà",
      "quanhuyen":"20509"
   },
   {
      "id":"2050903",
      "phuongxa":"Xã Nàn Sán",
      "quanhuyen":"20509"
   },
   {
      "id":"2050905",
      "phuongxa":"Xã Si Ma Cai",
      "quanhuyen":"20509"
   },
   {
      "id":"2050907",
      "phuongxa":"Xã Thào Chư Phìn",
      "quanhuyen":"20509"
   },
   {
      "id":"2050909",
      "phuongxa":"Xã Bản Mế",
      "quanhuyen":"20509"
   },
   {
      "id":"2050911",
      "phuongxa":"Xã Sán Chải",
      "quanhuyen":"20509"
   },
   {
      "id":"2050913",
      "phuongxa":"Xã Lùng Sui",
      "quanhuyen":"20509"
   },
   {
      "id":"2050915",
      "phuongxa":"Xã Mản Thẩn",
      "quanhuyen":"20509"
   },
   {
      "id":"2050919",
      "phuongxa":"Xã Cán Hồ",
      "quanhuyen":"20509"
   },
   {
      "id":"2050921",
      "phuongxa":"Xã Sín Chéng",
      "quanhuyen":"20509"
   },
   {
      "id":"2050923",
      "phuongxa":"Xã Lử Thẩn",
      "quanhuyen":"20509"
   },
   {
      "id":"2050925",
      "phuongxa":"Xã Quan Thần Sán",
      "quanhuyen":"20509"
   },
   {
      "id":"2050931",
      "phuongxa":"Xã Lùng Phình",
      "quanhuyen":"20509"
   },
   {
      "id":"2050933",
      "phuongxa":"Xã Bản Già",
      "quanhuyen":"20509"
   },
   {
      "id":"2050935",
      "phuongxa":"Xã Tả Van Chư",
      "quanhuyen":"20509"
   },
   {
      "id":"2050937",
      "phuongxa":"Xã Tả Củ Tỷ",
      "quanhuyen":"20509"
   },
   {
      "id":"2050939",
      "phuongxa":"Xã Hoàng Thu Phố",
      "quanhuyen":"20509"
   },
   {
      "id":"2050943",
      "phuongxa":"Xã Lầu Thí Ngài",
      "quanhuyen":"20509"
   },
   {
      "id":"2050945",
      "phuongxa":"Xã Thải Giàng Phố",
      "quanhuyen":"20509"
   },
   {
      "id":"2050947",
      "phuongxa":"Xã Na Hối",
      "quanhuyen":"20509"
   },
   {
      "id":"2050949",
      "phuongxa":"Xã Tà Chải",
      "quanhuyen":"20509"
   },
   {
      "id":"2050953",
      "phuongxa":"Xã Cốc Ly",
      "quanhuyen":"20509"
   },
   {
      "id":"2050955",
      "phuongxa":"Xã Nậm Mòn",
      "quanhuyen":"20509"
   },
   {
      "id":"2050957",
      "phuongxa":"Xã Nậm Đét",
      "quanhuyen":"20509"
   },
   {
      "id":"2050959",
      "phuongxa":"Xã Nậm Khánh",
      "quanhuyen":"20509"
   },
   {
      "id":"2050961",
      "phuongxa":"Xã Bảo Nhai",
      "quanhuyen":"20509"
   },
   {
      "id":"2050963",
      "phuongxa":"Xã Nậm Lúc",
      "quanhuyen":"20509"
   },
   {
      "id":"2050967",
      "phuongxa":"Xã Bản Cái",
      "quanhuyen":"20509"
   },
   {
      "id":"2051100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20511"
   },
   {
      "id":"2051101",
      "phuongxa":"Thị trấn Phố Lu",
      "quanhuyen":"20511"
   },
   {
      "id":"2051103",
      "phuongxa":"Thị trấn NT Phong Hải",
      "quanhuyen":"20511"
   },
   {
      "id":"2051105",
      "phuongxa":"Thị trấn Tằng Loỏng",
      "quanhuyen":"20511"
   },
   {
      "id":"2051107",
      "phuongxa":"Xã Bản Phiệt",
      "quanhuyen":"20511"
   },
   {
      "id":"2051109",
      "phuongxa":"Xã Bản Cầm",
      "quanhuyen":"20511"
   },
   {
      "id":"2051111",
      "phuongxa":"Xã Thái Niên",
      "quanhuyen":"20511"
   },
   {
      "id":"2051113",
      "phuongxa":"Xã Phong Niên",
      "quanhuyen":"20511"
   },
   {
      "id":"2051115",
      "phuongxa":"Xã Gia Phú",
      "quanhuyen":"20511"
   },
   {
      "id":"2051117",
      "phuongxa":"Xã Xuân Quang",
      "quanhuyen":"20511"
   },
   {
      "id":"2051119",
      "phuongxa":"Xã Xuân Giao",
      "quanhuyen":"20511"
   },
   {
      "id":"2051121",
      "phuongxa":"Xã Sơn Hà",
      "quanhuyen":"20511"
   },
   {
      "id":"2090913",
      "phuongxa":"Xã Vũ Lễ",
      "quanhuyen":"20909"
   },
   {
      "id":"2090915",
      "phuongxa":"Xã Long Đống",
      "quanhuyen":"20909"
   },
   {
      "id":"2090917",
      "phuongxa":"Xã Hữu Vĩnh",
      "quanhuyen":"20909"
   },
   {
      "id":"2090919",
      "phuongxa":"Xã Quỳnh Sơn",
      "quanhuyen":"20909"
   },
   {
      "id":"2090921",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"20909"
   },
   {
      "id":"2090923",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"20909"
   },
   {
      "id":"2090925",
      "phuongxa":"Xã Tân Hương",
      "quanhuyen":"20909"
   },
   {
      "id":"2090927",
      "phuongxa":"Xã Vũ Lăng",
      "quanhuyen":"20909"
   },
   {
      "id":"2090929",
      "phuongxa":"Xã Chiêu Vũ",
      "quanhuyen":"20909"
   },
   {
      "id":"2090931",
      "phuongxa":"Xã Hưng Vũ",
      "quanhuyen":"20909"
   },
   {
      "id":"2090933",
      "phuongxa":"Xã Trấn Yên",
      "quanhuyen":"20909"
   },
   {
      "id":"2090935",
      "phuongxa":"Xã Nhất Hòa",
      "quanhuyen":"20909"
   },
   {
      "id":"2090937",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"20909"
   },
   {
      "id":"2090939",
      "phuongxa":"Xã Nhất Tiến",
      "quanhuyen":"20909"
   },
   {
      "id":"2091100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20911"
   },
   {
      "id":"2091101",
      "phuongxa":"Thị trấn Văn Quan",
      "quanhuyen":"20911"
   },
   {
      "id":"2091103",
      "phuongxa":"Xã Trấn Ninh",
      "quanhuyen":"20911"
   },
   {
      "id":"2091105",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"20911"
   },
   {
      "id":"2091107",
      "phuongxa":"Xã Việt Yên",
      "quanhuyen":"20911"
   },
   {
      "id":"2091109",
      "phuongxa":"Xã Vân Mộng",
      "quanhuyen":"20911"
   },
   {
      "id":"2091111",
      "phuongxa":"Xã Song Giang",
      "quanhuyen":"20911"
   },
   {
      "id":"2091113",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"20911"
   },
   {
      "id":"2091117",
      "phuongxa":"Xã Văn An",
      "quanhuyen":"20911"
   },
   {
      "id":"2091119",
      "phuongxa":"Xã Đại An",
      "quanhuyen":"20911"
   },
   {
      "id":"2091121",
      "phuongxa":"Xã Tú Xuyên",
      "quanhuyen":"20911"
   },
   {
      "id":"2091123",
      "phuongxa":"Xã Lương Năng",
      "quanhuyen":"20911"
   },
   {
      "id":"2091125",
      "phuongxa":"Xã Tri Lễ",
      "quanhuyen":"20911"
   },
   {
      "id":"2091127",
      "phuongxa":"Xã Hữu Lễ",
      "quanhuyen":"20911"
   },
   {
      "id":"2091129",
      "phuongxa":"Xã Xuân Mai",
      "quanhuyen":"20911"
   },
   {
      "id":"2091131",
      "phuongxa":"Xã Bình Phúc",
      "quanhuyen":"20911"
   },
   {
      "id":"2091133",
      "phuongxa":"Xã Yên Phúc",
      "quanhuyen":"20911"
   },
   {
      "id":"2091135",
      "phuongxa":"Xã Chu Túc",
      "quanhuyen":"20911"
   },
   {
      "id":"2091137",
      "phuongxa":"Xã Tân Đoàn",
      "quanhuyen":"20911"
   },
   {
      "id":"2091139",
      "phuongxa":"Xã Tràng Sơn",
      "quanhuyen":"20911"
   },
   {
      "id":"2091141",
      "phuongxa":"Xã Tràng Phái",
      "quanhuyen":"20911"
   },
   {
      "id":"2091143",
      "phuongxa":"Xã Khánh Khê",
      "quanhuyen":"20911"
   },
   {
      "id":"2091145",
      "phuongxa":"Xã Đồng Giáp",
      "quanhuyen":"20911"
   },
   {
      "id":"2091147",
      "phuongxa":"Xã Tràng Các",
      "quanhuyen":"20911"
   },
   {
      "id":"2091300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20913"
   },
   {
      "id":"2091301",
      "phuongxa":"Thị trấn Cao Lộc",
      "quanhuyen":"20913"
   },
   {
      "id":"2091305",
      "phuongxa":"Xã Bảo Lâm",
      "quanhuyen":"20913"
   },
   {
      "id":"2091309",
      "phuongxa":"Xã Hồng Phong",
      "quanhuyen":"20913"
   },
   {
      "id":"2091311",
      "phuongxa":"Xã Phú Xá",
      "quanhuyen":"20913"
   },
   {
      "id":"2091313",
      "phuongxa":"Xã Thụy Hùng",
      "quanhuyen":"20913"
   },
   {
      "id":"2091315",
      "phuongxa":"Xã Bình Trung",
      "quanhuyen":"20913"
   },
   {
      "id":"2091319",
      "phuongxa":"Xã Lộc Thanh",
      "quanhuyen":"20913"
   },
   {
      "id":"2091321",
      "phuongxa":"Xã Hòa Cư",
      "quanhuyen":"20913"
   },
   {
      "id":"2091323",
      "phuongxa":"Xã Hải Yến",
      "quanhuyen":"20913"
   },
   {
      "id":"2091325",
      "phuongxa":"Xã Công Sơn",
      "quanhuyen":"20913"
   },
   {
      "id":"2091327",
      "phuongxa":"Xã Cao Lâu",
      "quanhuyen":"20913"
   },
   {
      "id":"2091329",
      "phuongxa":"Xã Xuất Lễ",
      "quanhuyen":"20913"
   },
   {
      "id":"2091333",
      "phuongxa":"Xã Hợp Thành",
      "quanhuyen":"20913"
   },
   {
      "id":"2091335",
      "phuongxa":"Xã Gia Cát",
      "quanhuyen":"20913"
   },
   {
      "id":"2091337",
      "phuongxa":"Xã Tân Liên",
      "quanhuyen":"20913"
   },
   {
      "id":"2091339",
      "phuongxa":"Xã Yên Trạch",
      "quanhuyen":"20913"
   },
   {
      "id":"2091341",
      "phuongxa":"Xã Xuân Long",
      "quanhuyen":"20913"
   },
   {
      "id":"2091500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20915"
   },
   {
      "id":"2091501",
      "phuongxa":"Thị trấn Lộc Bình",
      "quanhuyen":"20915"
   },
   {
      "id":"2091503",
      "phuongxa":"Thị trấn Na Dương",
      "quanhuyen":"20915"
   },
   {
      "id":"2091505",
      "phuongxa":"Xã Xuân Lễ",
      "quanhuyen":"20915"
   },
   {
      "id":"2091507",
      "phuongxa":"Xã Bằng Khánh",
      "quanhuyen":"20915"
   },
   {
      "id":"2091509",
      "phuongxa":"Xã Xuân Mãn",
      "quanhuyen":"20915"
   },
   {
      "id":"2091511",
      "phuongxa":"Xã Đồng Bục",
      "quanhuyen":"20915"
   },
   {
      "id":"2091513",
      "phuongxa":"Xã Vân Mộng",
      "quanhuyen":"20915"
   },
   {
      "id":"2091517",
      "phuongxa":"Xã Xuân Tình",
      "quanhuyen":"20915"
   },
   {
      "id":"2091519",
      "phuongxa":"Xã Nhượng Bạn",
      "quanhuyen":"20915"
   },
   {
      "id":"2091521",
      "phuongxa":"Xã Hiệp Hạ",
      "quanhuyen":"20915"
   },
   {
      "id":"2091523",
      "phuongxa":"Xã Minh Phát",
      "quanhuyen":"20915"
   },
   {
      "id":"2091525",
      "phuongxa":"Xã Hữu Lân",
      "quanhuyen":"20915"
   },
   {
      "id":"2091527",
      "phuongxa":"Xã Hữu Khánh",
      "quanhuyen":"20915"
   },
   {
      "id":"2091529",
      "phuongxa":"Xã Lục Thôn",
      "quanhuyen":"20915"
   },
   {
      "id":"2091531",
      "phuongxa":"Xã Mẫu Sơn",
      "quanhuyen":"20915"
   },
   {
      "id":"2091533",
      "phuongxa":"Xã Yên Khoái",
      "quanhuyen":"20915"
   },
   {
      "id":"2091535",
      "phuongxa":"Xã Tú Mịch",
      "quanhuyen":"20915"
   },
   {
      "id":"2091537",
      "phuongxa":"Xã Tam Gia",
      "quanhuyen":"20915"
   },
   {
      "id":"2091539",
      "phuongxa":"Xã Tĩnh Bắc",
      "quanhuyen":"20915"
   },
   {
      "id":"2091541",
      "phuongxa":"Xã Tú Đoạn",
      "quanhuyen":"20915"
   },
   {
      "id":"2091543",
      "phuongxa":"Xã Khuất Xá",
      "quanhuyen":"20915"
   },
   {
      "id":"2091545",
      "phuongxa":"Xã Quan Bản",
      "quanhuyen":"20915"
   },
   {
      "id":"2091547",
      "phuongxa":"Xã Đông Quan",
      "quanhuyen":"20915"
   },
   {
      "id":"2091549",
      "phuongxa":"Xã Sàn Viên",
      "quanhuyen":"20915"
   },
   {
      "id":"2091551",
      "phuongxa":"Xã Lợi Bác",
      "quanhuyen":"20915"
   },
   {
      "id":"2091553",
      "phuongxa":"Xã Nam Quan",
      "quanhuyen":"20915"
   },
   {
      "id":"2091557",
      "phuongxa":"Xã ái Quốc",
      "quanhuyen":"20915"
   },
   {
      "id":"2091700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20917"
   },
   {
      "id":"2091701",
      "phuongxa":"Thị trấn Đồng Mỏ",
      "quanhuyen":"20917"
   },
   {
      "id":"2091703",
      "phuongxa":"Thị trấn Chi Lăng",
      "quanhuyen":"20917"
   },
   {
      "id":"2091705",
      "phuongxa":"Xã Bằng Hữu",
      "quanhuyen":"20917"
   },
   {
      "id":"2091707",
      "phuongxa":"Xã Bằng Mạc",
      "quanhuyen":"20917"
   },
   {
      "id":"2091709",
      "phuongxa":"Xã Gia Lộc",
      "quanhuyen":"20917"
   },
   {
      "id":"2091711",
      "phuongxa":"Xã Thượng Cường",
      "quanhuyen":"20917"
   },
   {
      "id":"2091713",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"20917"
   },
   {
      "id":"2091715",
      "phuongxa":"Xã Vạn Linh",
      "quanhuyen":"20917"
   },
   {
      "id":"2091717",
      "phuongxa":"Xã Y Tịch",
      "quanhuyen":"20917"
   },
   {
      "id":"2091719",
      "phuongxa":"Xã Quang Lang",
      "quanhuyen":"20917"
   },
   {
      "id":"2091721",
      "phuongxa":"Xã Chi Lăng",
      "quanhuyen":"20917"
   },
   {
      "id":"2091723",
      "phuongxa":"Xã Mai Sao",
      "quanhuyen":"20917"
   },
   {
      "id":"2091725",
      "phuongxa":"Xã Nhân Lý",
      "quanhuyen":"20917"
   },
   {
      "id":"2091727",
      "phuongxa":"Xã Bắc Thủy",
      "quanhuyen":"20917"
   },
   {
      "id":"2091729",
      "phuongxa":"Xã Vân Thủy",
      "quanhuyen":"20917"
   },
   {
      "id":"2091731",
      "phuongxa":"Xã Vân An",
      "quanhuyen":"20917"
   },
   {
      "id":"2091733",
      "phuongxa":"Xã Chiến Thắng",
      "quanhuyen":"20917"
   },
   {
      "id":"2091735",
      "phuongxa":"Xã Liên Sơn",
      "quanhuyen":"20917"
   },
   {
      "id":"2091737",
      "phuongxa":"Xã Lâm Sơn",
      "quanhuyen":"20917"
   },
   {
      "id":"2091741",
      "phuongxa":"Xã Hữu Kiên",
      "quanhuyen":"20917"
   },
   {
      "id":"2091900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20919"
   },
   {
      "id":"2091901",
      "phuongxa":"Thị trấn Đình Lập",
      "quanhuyen":"20919"
   },
   {
      "id":"2091903",
      "phuongxa":"Thị trấn NT Thái Bình",
      "quanhuyen":"20919"
   },
   {
      "id":"2091907",
      "phuongxa":"Xã Bắc Xa",
      "quanhuyen":"20919"
   },
   {
      "id":"2091909",
      "phuongxa":"Xã Kiên Mộc",
      "quanhuyen":"20919"
   },
   {
      "id":"2091911",
      "phuongxa":"Xã Đình Lập",
      "quanhuyen":"20919"
   },
   {
      "id":"2091913",
      "phuongxa":"Xã Cường Lợi",
      "quanhuyen":"20919"
   },
   {
      "id":"2091915",
      "phuongxa":"Xã Thái Bình",
      "quanhuyen":"20919"
   },
   {
      "id":"2091919",
      "phuongxa":"Xã Châu Sơn",
      "quanhuyen":"20919"
   },
   {
      "id":"2091921",
      "phuongxa":"Xã Đồng Thắng",
      "quanhuyen":"20919"
   },
   {
      "id":"2091923",
      "phuongxa":"Xã Bắc Lãng",
      "quanhuyen":"20919"
   },
   {
      "id":"2092100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20921"
   },
   {
      "id":"2092103",
      "phuongxa":"Xã Quyết Thắng",
      "quanhuyen":"20921"
   },
   {
      "id":"2092105",
      "phuongxa":"Xã Yên Bình",
      "quanhuyen":"20921"
   },
   {
      "id":"2092107",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"20921"
   },
   {
      "id":"2092109",
      "phuongxa":"Xã Hữu Liên",
      "quanhuyen":"20921"
   },
   {
      "id":"2092111",
      "phuongxa":"Xã Yên Thịnh",
      "quanhuyen":"20921"
   },
   {
      "id":"2092113",
      "phuongxa":"Xã Yên Vượng",
      "quanhuyen":"20921"
   },
   {
      "id":"2092119",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"20921"
   },
   {
      "id":"2092121",
      "phuongxa":"Xã Minh Tiến",
      "quanhuyen":"20921"
   },
   {
      "id":"2092123",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"20921"
   },
   {
      "id":"2092125",
      "phuongxa":"Xã Vân Nham",
      "quanhuyen":"20921"
   },
   {
      "id":"2092127",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"20921"
   },
   {
      "id":"2092129",
      "phuongxa":"Xã Đô Lương",
      "quanhuyen":"20921"
   },
   {
      "id":"2092131",
      "phuongxa":"Xã Nhật Tiến",
      "quanhuyen":"20921"
   },
   {
      "id":"2092133",
      "phuongxa":"Xã Đồng Tân",
      "quanhuyen":"20921"
   },
   {
      "id":"2092135",
      "phuongxa":"Xã Cai Kinh",
      "quanhuyen":"20921"
   },
   {
      "id":"2092137",
      "phuongxa":"Xã Hòa Lạc",
      "quanhuyen":"20921"
   },
   {
      "id":"2092139",
      "phuongxa":"Xã Minh Sơn",
      "quanhuyen":"20921"
   },
   {
      "id":"2092141",
      "phuongxa":"Xã Sơn Hà",
      "quanhuyen":"20921"
   },
   {
      "id":"2092143",
      "phuongxa":"Xã Minh Hòa",
      "quanhuyen":"20921"
   },
   {
      "id":"2092145",
      "phuongxa":"Xã Hòa Sơn",
      "quanhuyen":"20921"
   },
   {
      "id":"2092147",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"20921"
   },
   {
      "id":"2092149",
      "phuongxa":"Xã Hồ Sơn",
      "quanhuyen":"20921"
   },
   {
      "id":"2092151",
      "phuongxa":"Xã Hòa Thắng",
      "quanhuyen":"20921"
   },
   {
      "id":"2110000",
      "phuongxa":"Không xác định",
      "quanhuyen":"21100"
   },
   {
      "id":"2051123",
      "phuongxa":"Xã Trì Quang",
      "quanhuyen":"20511"
   },
   {
      "id":"2051125",
      "phuongxa":"Xã Sơn Hải",
      "quanhuyen":"20511"
   },
   {
      "id":"2051127",
      "phuongxa":"Xã Phố Lu",
      "quanhuyen":"20511"
   },
   {
      "id":"2051129",
      "phuongxa":"Xã Phú Nhuận",
      "quanhuyen":"20511"
   },
   {
      "id":"2051300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20513"
   },
   {
      "id":"2051303",
      "phuongxa":"Xã Bản Khoang",
      "quanhuyen":"20513"
   },
   {
      "id":"2051305",
      "phuongxa":"Xã Tả Giàng Phình",
      "quanhuyen":"20513"
   },
   {
      "id":"2051307",
      "phuongxa":"Xã Trung Chải",
      "quanhuyen":"20513"
   },
   {
      "id":"2051309",
      "phuongxa":"Xã Tả Phìn",
      "quanhuyen":"20513"
   },
   {
      "id":"2051311",
      "phuongxa":"Xã Sa Pả",
      "quanhuyen":"20513"
   },
   {
      "id":"2051313",
      "phuongxa":"Xã San Sả Hồ",
      "quanhuyen":"20513"
   },
   {
      "id":"2051315",
      "phuongxa":"Xã Bản Phùng",
      "quanhuyen":"20513"
   },
   {
      "id":"2051317",
      "phuongxa":"Xã Lao Chải",
      "quanhuyen":"20513"
   },
   {
      "id":"2051319",
      "phuongxa":"Xã Hầu Thào",
      "quanhuyen":"20513"
   },
   {
      "id":"2051321",
      "phuongxa":"Xã Thanh Kim",
      "quanhuyen":"20513"
   },
   {
      "id":"2051323",
      "phuongxa":"Xã Tả Van",
      "quanhuyen":"20513"
   },
   {
      "id":"2051325",
      "phuongxa":"Xã Sử Pán",
      "quanhuyen":"20513"
   },
   {
      "id":"2051327",
      "phuongxa":"Xã Suối Thầu",
      "quanhuyen":"20513"
   },
   {
      "id":"2051329",
      "phuongxa":"Xã Bản Hồ",
      "quanhuyen":"20513"
   },
   {
      "id":"2051331",
      "phuongxa":"Xã Thanh Phú",
      "quanhuyen":"20513"
   },
   {
      "id":"2051333",
      "phuongxa":"Xã Nậm Sài",
      "quanhuyen":"20513"
   },
   {
      "id":"2051335",
      "phuongxa":"Xã Nậm Cang",
      "quanhuyen":"20513"
   },
   {
      "id":"2051500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20515"
   },
   {
      "id":"2051501",
      "phuongxa":"Thị trấn Phố Ràng",
      "quanhuyen":"20515"
   },
   {
      "id":"2051503",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"20515"
   },
   {
      "id":"2051505",
      "phuongxa":"Xã Nghĩa Đô",
      "quanhuyen":"20515"
   },
   {
      "id":"2051507",
      "phuongxa":"Xã Vĩnh Yên",
      "quanhuyen":"20515"
   },
   {
      "id":"2051511",
      "phuongxa":"Xã Điện Quan",
      "quanhuyen":"20515"
   },
   {
      "id":"2051513",
      "phuongxa":"Xã Thượng Hà",
      "quanhuyen":"20515"
   },
   {
      "id":"2051515",
      "phuongxa":"Xã Xuân Hoà",
      "quanhuyen":"20515"
   },
   {
      "id":"2051517",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"20515"
   },
   {
      "id":"2051519",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"20515"
   },
   {
      "id":"2051521",
      "phuongxa":"Xã Xuân Thượng",
      "quanhuyen":"20515"
   },
   {
      "id":"2051523",
      "phuongxa":"Xã Cam Cọn",
      "quanhuyen":"20515"
   },
   {
      "id":"2051525",
      "phuongxa":"Xã Việt Tiến",
      "quanhuyen":"20515"
   },
   {
      "id":"2051529",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"20515"
   },
   {
      "id":"2051531",
      "phuongxa":"Xã Lương Sơn",
      "quanhuyen":"20515"
   },
   {
      "id":"2051533",
      "phuongxa":"Xã Long Phúc",
      "quanhuyen":"20515"
   },
   {
      "id":"2051535",
      "phuongxa":"Xã Long Khánh",
      "quanhuyen":"20515"
   },
   {
      "id":"2051700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20517"
   },
   {
      "id":"2051703",
      "phuongxa":"Thị trấn NT Than Uyên",
      "quanhuyen":"20517"
   },
   {
      "id":"2051705",
      "phuongxa":"Xã Thân Thuộc",
      "quanhuyen":"20517"
   },
   {
      "id":"2051707",
      "phuongxa":"Xã Mường Khoa",
      "quanhuyen":"20517"
   },
   {
      "id":"2051709",
      "phuongxa":"Xã Nậm Cần",
      "quanhuyen":"20517"
   },
   {
      "id":"2051711",
      "phuongxa":"Xã Hố Mít",
      "quanhuyen":"20517"
   },
   {
      "id":"2051713",
      "phuongxa":"Xã Nậm Sỏ",
      "quanhuyen":"20517"
   },
   {
      "id":"2051719",
      "phuongxa":"Xã Tà Mít",
      "quanhuyen":"20517"
   },
   {
      "id":"2051721",
      "phuongxa":"Xã Mường Mít",
      "quanhuyen":"20517"
   },
   {
      "id":"2051723",
      "phuongxa":"Xã Pha Mu",
      "quanhuyen":"20517"
   },
   {
      "id":"2051725",
      "phuongxa":"Xã Nà Cang",
      "quanhuyen":"20517"
   },
   {
      "id":"2051729",
      "phuongxa":"Xã Mường Kim",
      "quanhuyen":"20517"
   },
   {
      "id":"2051731",
      "phuongxa":"Xã Tà Gia",
      "quanhuyen":"20517"
   },
   {
      "id":"2051733",
      "phuongxa":"Xã Khoen On",
      "quanhuyen":"20517"
   },
   {
      "id":"2051900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20519"
   },
   {
      "id":"2051903",
      "phuongxa":"Xã Văn Sơn",
      "quanhuyen":"20519"
   },
   {
      "id":"2051905",
      "phuongxa":"Xã Võ Lao",
      "quanhuyen":"20519"
   },
   {
      "id":"2051907",
      "phuongxa":"Xã Nậm Mả",
      "quanhuyen":"20519"
   },
   {
      "id":"2051909",
      "phuongxa":"Xã Nậm Rạng",
      "quanhuyen":"20519"
   },
   {
      "id":"2051911",
      "phuongxa":"Xã Sơn Thuỷ",
      "quanhuyen":"20519"
   },
   {
      "id":"2051913",
      "phuongxa":"Xã Tân Thượng",
      "quanhuyen":"20519"
   },
   {
      "id":"2051915",
      "phuongxa":"Xã Nậm Chầy",
      "quanhuyen":"20519"
   },
   {
      "id":"2051917",
      "phuongxa":"Xã Khánh Yên Thượng",
      "quanhuyen":"20519"
   },
   {
      "id":"2051919",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"20519"
   },
   {
      "id":"2051921",
      "phuongxa":"Xã Nậm Xé",
      "quanhuyen":"20519"
   },
   {
      "id":"2051923",
      "phuongxa":"Xã Hoà Mạc",
      "quanhuyen":"20519"
   },
   {
      "id":"2051925",
      "phuongxa":"Xã Dần Thàng",
      "quanhuyen":"20519"
   },
   {
      "id":"2051929",
      "phuongxa":"Xã Làng Giàng",
      "quanhuyen":"20519"
   },
   {
      "id":"2051931",
      "phuongxa":"Xã Khánh Yên Trung",
      "quanhuyen":"20519"
   },
   {
      "id":"2051933",
      "phuongxa":"Xã Khánh Yên Hạ",
      "quanhuyen":"20519"
   },
   {
      "id":"2051935",
      "phuongxa":"Xã Dương Quỳ",
      "quanhuyen":"20519"
   },
   {
      "id":"2051937",
      "phuongxa":"Xã Nậm Tha",
      "quanhuyen":"20519"
   },
   {
      "id":"2051939",
      "phuongxa":"Xã Minh Lương",
      "quanhuyen":"20519"
   },
   {
      "id":"2051941",
      "phuongxa":"Xã Thẩm Dương",
      "quanhuyen":"20519"
   },
   {
      "id":"2051943",
      "phuongxa":"Xã Liêm Phú",
      "quanhuyen":"20519"
   },
   {
      "id":"2051945",
      "phuongxa":"Xã Nậm Xây",
      "quanhuyen":"20519"
   },
   {
      "id":"2070000",
      "phuongxa":"Không xác định",
      "quanhuyen":"20700"
   },
   {
      "id":"2070100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20701"
   },
   {
      "id":"2070101",
      "phuongxa":"Phường Đức Xuân",
      "quanhuyen":"20701"
   },
   {
      "id":"2070103",
      "phuongxa":"Phường Nguyễn Thị Minh Khai",
      "quanhuyen":"20701"
   },
   {
      "id":"2070105",
      "phuongxa":"Phường Sông Cầu",
      "quanhuyen":"20701"
   },
   {
      "id":"2070107",
      "phuongxa":"Phường Phùng Chí Kiên",
      "quanhuyen":"20701"
   },
   {
      "id":"2070109",
      "phuongxa":"Xã Huyền Tụng",
      "quanhuyen":"20701"
   },
   {
      "id":"2070111",
      "phuongxa":"Xã Nông Thượng",
      "quanhuyen":"20701"
   },
   {
      "id":"2070113",
      "phuongxa":"Xã Xuất Hoá",
      "quanhuyen":"20701"
   },
   {
      "id":"2070115",
      "phuongxa":"Xã Dương Quang",
      "quanhuyen":"20701"
   },
   {
      "id":"2070300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20703"
   },
   {
      "id":"2070301",
      "phuongxa":"Thị trấn Chợ Rã",
      "quanhuyen":"20703"
   },
   {
      "id":"2070305",
      "phuongxa":"Xã Nhạn Môn",
      "quanhuyen":"20703"
   },
   {
      "id":"2070307",
      "phuongxa":"Xã Công Bằng",
      "quanhuyen":"20703"
   },
   {
      "id":"2070309",
      "phuongxa":"Xã Giáo Hiệu",
      "quanhuyen":"20703"
   },
   {
      "id":"2070311",
      "phuongxa":"Xã An Thắng",
      "quanhuyen":"20703"
   },
   {
      "id":"2070313",
      "phuongxa":"Xã Xuân La",
      "quanhuyen":"20703"
   },
   {
      "id":"2070315",
      "phuongxa":"Xã Bộc Bố",
      "quanhuyen":"20703"
   },
   {
      "id":"2070317",
      "phuongxa":"Xã Cổ Linh",
      "quanhuyen":"20703"
   },
   {
      "id":"2070319",
      "phuongxa":"Xã Nghiên Loan",
      "quanhuyen":"20703"
   },
   {
      "id":"2070321",
      "phuongxa":"Xã Bành Trạch",
      "quanhuyen":"20703"
   },
   {
      "id":"2070323",
      "phuongxa":"Xã Cao Tân",
      "quanhuyen":"20703"
   },
   {
      "id":"2070325",
      "phuongxa":"Xã Phúc Lộc",
      "quanhuyen":"20703"
   },
   {
      "id":"2070327",
      "phuongxa":"Xã Cao Thượng",
      "quanhuyen":"20703"
   },
   {
      "id":"2070329",
      "phuongxa":"Xã Cao Trĩ",
      "quanhuyen":"20703"
   },
   {
      "id":"2070331",
      "phuongxa":"Xã Hà Hiệu",
      "quanhuyen":"20703"
   },
   {
      "id":"2070333",
      "phuongxa":"Xã Đồng Phúc",
      "quanhuyen":"20703"
   },
   {
      "id":"2070335",
      "phuongxa":"Xã Hoàng Trĩ",
      "quanhuyen":"20703"
   },
   {
      "id":"2070337",
      "phuongxa":"Xã Mỹ Phương",
      "quanhuyen":"20703"
   },
   {
      "id":"2070339",
      "phuongxa":"Xã Quảng Khê",
      "quanhuyen":"20703"
   },
   {
      "id":"2070341",
      "phuongxa":"Xã Chu Hương",
      "quanhuyen":"20703"
   },
   {
      "id":"2070345",
      "phuongxa":"Xã Địa Linh",
      "quanhuyen":"20703"
   },
   {
      "id":"2070349",
      "phuongxa":"Xã Khang Ninh",
      "quanhuyen":"20703"
   },
   {
      "id":"2070351",
      "phuongxa":"Xã Thượng Giáo",
      "quanhuyen":"20703"
   },
   {
      "id":"2070500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20705"
   },
   {
      "id":"2070503",
      "phuongxa":"Xã Vân Tùng",
      "quanhuyen":"20705"
   },
   {
      "id":"2070505",
      "phuongxa":"Xã Đức Vân",
      "quanhuyen":"20705"
   },
   {
      "id":"2070507",
      "phuongxa":"Xã Bằng Vân",
      "quanhuyen":"20705"
   },
   {
      "id":"2070509",
      "phuongxa":"Xã Thượng Ân",
      "quanhuyen":"20705"
   },
   {
      "id":"2070511",
      "phuongxa":"Xã Cốc Đán",
      "quanhuyen":"20705"
   },
   {
      "id":"2070513",
      "phuongxa":"Xã Trung Hoà",
      "quanhuyen":"20705"
   },
   {
      "id":"2070517",
      "phuongxa":"Xã Thuần Mang",
      "quanhuyen":"20705"
   },
   {
      "id":"2070519",
      "phuongxa":"Xã Hương Nê",
      "quanhuyen":"20705"
   },
   {
      "id":"2070521",
      "phuongxa":"Xã Lãng Ngâm",
      "quanhuyen":"20705"
   },
   {
      "id":"2070700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20707"
   },
   {
      "id":"2070701",
      "phuongxa":"Thị trấn Bằng Lũng",
      "quanhuyen":"20707"
   },
   {
      "id":"2070705",
      "phuongxa":"Xã Nam Cường",
      "quanhuyen":"20707"
   },
   {
      "id":"2070707",
      "phuongxa":"Xã Đồng Lạc",
      "quanhuyen":"20707"
   },
   {
      "id":"2070709",
      "phuongxa":"Xã Bản Thi",
      "quanhuyen":"20707"
   },
   {
      "id":"2070711",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"20707"
   },
   {
      "id":"2070713",
      "phuongxa":"Xã Quảng Bạch",
      "quanhuyen":"20707"
   },
   {
      "id":"2070715",
      "phuongxa":"Xã Bằng Phúc",
      "quanhuyen":"20707"
   },
   {
      "id":"2070717",
      "phuongxa":"Xã Ngọc Phái",
      "quanhuyen":"20707"
   },
   {
      "id":"2070719",
      "phuongxa":"Xã Yên Thịnh",
      "quanhuyen":"20707"
   },
   {
      "id":"2070721",
      "phuongxa":"Xã Phương Viên",
      "quanhuyen":"20707"
   },
   {
      "id":"2070723",
      "phuongxa":"Xã Yên Thượng",
      "quanhuyen":"20707"
   },
   {
      "id":"2070725",
      "phuongxa":"Xã Rã Bản",
      "quanhuyen":"20707"
   },
   {
      "id":"2070727",
      "phuongxa":"Xã Bằng Lãng",
      "quanhuyen":"20707"
   },
   {
      "id":"2070729",
      "phuongxa":"Xã Lương Bằng",
      "quanhuyen":"20707"
   },
   {
      "id":"2070731",
      "phuongxa":"Xã ĐôngViên",
      "quanhuyen":"20707"
   },
   {
      "id":"2070733",
      "phuongxa":"Xã Đại Xảo",
      "quanhuyen":"20707"
   },
   {
      "id":"2070735",
      "phuongxa":"Xã Phong Huân",
      "quanhuyen":"20707"
   },
   {
      "id":"2070737",
      "phuongxa":"Xã Nghĩa Tá",
      "quanhuyen":"20707"
   },
   {
      "id":"2070739",
      "phuongxa":"Xã Yên Mỹ",
      "quanhuyen":"20707"
   },
   {
      "id":"2070741",
      "phuongxa":"Xã Yên Nhuận",
      "quanhuyen":"20707"
   },
   {
      "id":"2070743",
      "phuongxa":"Xã Bình Trung",
      "quanhuyen":"20707"
   },
   {
      "id":"2070900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20709"
   },
   {
      "id":"2070903",
      "phuongxa":"Xã Vũ Loan",
      "quanhuyen":"20709"
   },
   {
      "id":"2070905",
      "phuongxa":"Xã Kim Hỷ",
      "quanhuyen":"20709"
   },
   {
      "id":"2070907",
      "phuongxa":"Xã Văn Học",
      "quanhuyen":"20709"
   },
   {
      "id":"2070909",
      "phuongxa":"Xã Cường Lợi",
      "quanhuyen":"20709"
   },
   {
      "id":"2070911",
      "phuongxa":"Xã Lương Thượng",
      "quanhuyen":"20709"
   },
   {
      "id":"2070913",
      "phuongxa":"Xã Lạng San",
      "quanhuyen":"20709"
   },
   {
      "id":"2070915",
      "phuongxa":"Xã Lương Hạ",
      "quanhuyen":"20709"
   },
   {
      "id":"2070917",
      "phuongxa":"Xã Ân Tình",
      "quanhuyen":"20709"
   },
   {
      "id":"2070919",
      "phuongxa":"Xã Lương Thành",
      "quanhuyen":"20709"
   },
   {
      "id":"2070921",
      "phuongxa":"Xã Kim Lư",
      "quanhuyen":"20709"
   },
   {
      "id":"2070923",
      "phuongxa":"Xã Lam Sơn",
      "quanhuyen":"20709"
   },
   {
      "id":"2070925",
      "phuongxa":"Xã Văn Minh",
      "quanhuyen":"20709"
   },
   {
      "id":"2070927",
      "phuongxa":"Xã Cư Lễ",
      "quanhuyen":"20709"
   },
   {
      "id":"2070929",
      "phuongxa":"Xã Côn Minh",
      "quanhuyen":"20709"
   },
   {
      "id":"2070931",
      "phuongxa":"Xã Hữu Thác",
      "quanhuyen":"20709"
   },
   {
      "id":"2070933",
      "phuongxa":"Xã Quang Phong",
      "quanhuyen":"20709"
   },
   {
      "id":"2070935",
      "phuongxa":"Xã Hảo Nghĩa",
      "quanhuyen":"20709"
   },
   {
      "id":"2070937",
      "phuongxa":"Xã Dương Sơn",
      "quanhuyen":"20709"
   },
   {
      "id":"2070939",
      "phuongxa":"Xã Đổng Xá",
      "quanhuyen":"20709"
   },
   {
      "id":"2070941",
      "phuongxa":"Xã Xuân Dương",
      "quanhuyen":"20709"
   },
   {
      "id":"2070943",
      "phuongxa":"Xã Liêm Thuỷ",
      "quanhuyen":"20709"
   },
   {
      "id":"2071100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20711"
   },
   {
      "id":"2071101",
      "phuongxa":"Thị trấn Phủ Thông",
      "quanhuyen":"20711"
   },
   {
      "id":"2071107",
      "phuongxa":"Xã Vy Hương",
      "quanhuyen":"20711"
   },
   {
      "id":"2071109",
      "phuongxa":"Xã Sỹ Bình",
      "quanhuyen":"20711"
   },
   {
      "id":"2071111",
      "phuongxa":"Xã Vũ Muộn",
      "quanhuyen":"20711"
   },
   {
      "id":"2071113",
      "phuongxa":"Xã Tú Trĩ",
      "quanhuyen":"20711"
   },
   {
      "id":"2071115",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"20711"
   },
   {
      "id":"2071117",
      "phuongxa":"Xã Lục Bình",
      "quanhuyen":"20711"
   },
   {
      "id":"2071119",
      "phuongxa":"Xã Đôn Phong",
      "quanhuyen":"20711"
   },
   {
      "id":"2071121",
      "phuongxa":"Xã Quân Bình",
      "quanhuyen":"20711"
   },
   {
      "id":"2071123",
      "phuongxa":"Xã Nguyên Phúc",
      "quanhuyen":"20711"
   },
   {
      "id":"2071125",
      "phuongxa":"Xã Hà Vị",
      "quanhuyen":"20711"
   },
   {
      "id":"2071127",
      "phuongxa":"Xã Cao Sơn",
      "quanhuyen":"20711"
   },
   {
      "id":"2071129",
      "phuongxa":"Xã Cẩm Giàng",
      "quanhuyen":"20711"
   },
   {
      "id":"2071131",
      "phuongxa":"Xã Mỹ Thanh",
      "quanhuyen":"20711"
   },
   {
      "id":"2071135",
      "phuongxa":"Xã Dương Phong",
      "quanhuyen":"20711"
   },
   {
      "id":"2071300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20713"
   },
   {
      "id":"2071301",
      "phuongxa":"Thị trấn Chợ Mới",
      "quanhuyen":"20713"
   },
   {
      "id":"2071303",
      "phuongxa":"Xã Tân Sơn",
      "quanhuyen":"20713"
   },
   {
      "id":"2071305",
      "phuongxa":"Xã Thanh Vân",
      "quanhuyen":"20713"
   },
   {
      "id":"2071307",
      "phuongxa":"Xã Hoà Mục",
      "quanhuyen":"20713"
   },
   {
      "id":"2071311",
      "phuongxa":"Xã Mai Lạp",
      "quanhuyen":"20713"
   },
   {
      "id":"2071313",
      "phuongxa":"Xã Cao Kỳ",
      "quanhuyen":"20713"
   },
   {
      "id":"2071315",
      "phuongxa":"Xã Nông Hạ",
      "quanhuyen":"20713"
   },
   {
      "id":"2071319",
      "phuongxa":"Xã Yên Hân",
      "quanhuyen":"20713"
   },
   {
      "id":"2071323",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"20713"
   },
   {
      "id":"2071325",
      "phuongxa":"Xã Như Cố",
      "quanhuyen":"20713"
   },
   {
      "id":"2071327",
      "phuongxa":"Xã Bình Văn",
      "quanhuyen":"20713"
   },
   {
      "id":"2071329",
      "phuongxa":"Xã Yên Đĩnh",
      "quanhuyen":"20713"
   },
   {
      "id":"2071331",
      "phuongxa":"Xã Quảng Chu",
      "quanhuyen":"20713"
   },
   {
      "id":"2071501",
      "phuongxa":"Xã Bằng Thành",
      "quanhuyen":"20715"
   },
   {
      "id":"2071503",
      "phuongxa":"Xã Nhạn Môn",
      "quanhuyen":"20715"
   },
   {
      "id":"2071505",
      "phuongxa":"Xã Bộc Bố",
      "quanhuyen":"20715"
   },
   {
      "id":"2071507",
      "phuongxa":"Xã Công Bằng",
      "quanhuyen":"20715"
   },
   {
      "id":"2071509",
      "phuongxa":"Xã Giáo Hiệu",
      "quanhuyen":"20715"
   },
   {
      "id":"2071511",
      "phuongxa":"Xã Xuân La",
      "quanhuyen":"20715"
   },
   {
      "id":"2071513",
      "phuongxa":"Xã An Thắng",
      "quanhuyen":"20715"
   },
   {
      "id":"2071515",
      "phuongxa":"Xã Cổ Linh",
      "quanhuyen":"20715"
   },
   {
      "id":"2071517",
      "phuongxa":"Xã Nghiên Loan",
      "quanhuyen":"20715"
   },
   {
      "id":"2071519",
      "phuongxa":"Xã Cao Tân",
      "quanhuyen":"20715"
   },
   {
      "id":"2090000",
      "phuongxa":"Không xác định",
      "quanhuyen":"20900"
   },
   {
      "id":"2090100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20901"
   },
   {
      "id":"2090101",
      "phuongxa":"Phường Hoàng Văn Thụ",
      "quanhuyen":"20901"
   },
   {
      "id":"2090105",
      "phuongxa":"Phường Vĩnh Trại",
      "quanhuyen":"20901"
   },
   {
      "id":"2090107",
      "phuongxa":"Phường Đông Kinh",
      "quanhuyen":"20901"
   },
   {
      "id":"2090109",
      "phuongxa":"Phường Chi Lăng",
      "quanhuyen":"20901"
   },
   {
      "id":"2090111",
      "phuongxa":"Xã Hoàng Đồng",
      "quanhuyen":"20901"
   },
   {
      "id":"2090113",
      "phuongxa":"Xã Mai Pha",
      "quanhuyen":"20901"
   },
   {
      "id":"2090115",
      "phuongxa":"Xã Quảng Lạc",
      "quanhuyen":"20901"
   },
   {
      "id":"2090300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20903"
   },
   {
      "id":"2090301",
      "phuongxa":"Thị trấn Thất Khê",
      "quanhuyen":"20903"
   },
   {
      "id":"2090303",
      "phuongxa":"Xã Khánh Long",
      "quanhuyen":"20903"
   },
   {
      "id":"2090305",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"20903"
   },
   {
      "id":"2090307",
      "phuongxa":"Xã Cao Minh",
      "quanhuyen":"20903"
   },
   {
      "id":"2090309",
      "phuongxa":"Xã Vĩnh Tiến",
      "quanhuyen":"20903"
   },
   {
      "id":"2090311",
      "phuongxa":"Xã Tân Yên",
      "quanhuyen":"20903"
   },
   {
      "id":"2090313",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"20903"
   },
   {
      "id":"2090315",
      "phuongxa":"Xã Chí Minh",
      "quanhuyen":"20903"
   },
   {
      "id":"2090317",
      "phuongxa":"Xã Kim Đồng",
      "quanhuyen":"20903"
   },
   {
      "id":"2090319",
      "phuongxa":"Xã Bắc ái",
      "quanhuyen":"20903"
   },
   {
      "id":"2090321",
      "phuongxa":"Xã Quốc Khánh",
      "quanhuyen":"20903"
   },
   {
      "id":"2090323",
      "phuongxa":"Xã Tri Phương",
      "quanhuyen":"20903"
   },
   {
      "id":"2090325",
      "phuongxa":"Xã Đội Cấn",
      "quanhuyen":"20903"
   },
   {
      "id":"2090327",
      "phuongxa":"Xã Chi Lăng",
      "quanhuyen":"20903"
   },
   {
      "id":"2090329",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"20903"
   },
   {
      "id":"2090331",
      "phuongxa":"Xã Đề Thám",
      "quanhuyen":"20903"
   },
   {
      "id":"2090335",
      "phuongxa":"Xã Tân Minh",
      "quanhuyen":"20903"
   },
   {
      "id":"2090337",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"20903"
   },
   {
      "id":"2090339",
      "phuongxa":"Xã Đào Viên",
      "quanhuyen":"20903"
   },
   {
      "id":"2090341",
      "phuongxa":"Xã Kháng Chiến",
      "quanhuyen":"20903"
   },
   {
      "id":"2090343",
      "phuongxa":"Xã Quốc Việt",
      "quanhuyen":"20903"
   },
   {
      "id":"2090345",
      "phuongxa":"Xã Hùng Việt",
      "quanhuyen":"20903"
   },
   {
      "id":"2090500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20905"
   },
   {
      "id":"2090501",
      "phuongxa":"Thị trấn Na Sầm",
      "quanhuyen":"20905"
   },
   {
      "id":"2090503",
      "phuongxa":"Xã Tân Việt",
      "quanhuyen":"20905"
   },
   {
      "id":"2090505",
      "phuongxa":"Xã Trùng Khánh",
      "quanhuyen":"20905"
   },
   {
      "id":"2090507",
      "phuongxa":"Xã Trùng Quán",
      "quanhuyen":"20905"
   },
   {
      "id":"2090509",
      "phuongxa":"Xã Thụy Hùng",
      "quanhuyen":"20905"
   },
   {
      "id":"2090511",
      "phuongxa":"Xã An Hùng",
      "quanhuyen":"20905"
   },
   {
      "id":"2090513",
      "phuongxa":"Xã Bắc La",
      "quanhuyen":"20905"
   },
   {
      "id":"2090515",
      "phuongxa":"Xã Hội Hoan",
      "quanhuyen":"20905"
   },
   {
      "id":"2090517",
      "phuongxa":"Xã Tân Tác",
      "quanhuyen":"20905"
   },
   {
      "id":"2090521",
      "phuongxa":"Xã Gia Miễn",
      "quanhuyen":"20905"
   },
   {
      "id":"2090523",
      "phuongxa":"Xã Thành Hòa",
      "quanhuyen":"20905"
   },
   {
      "id":"2090525",
      "phuongxa":"Xã Tân Lang",
      "quanhuyen":"20905"
   },
   {
      "id":"2090531",
      "phuongxa":"Xã Tân Thanh",
      "quanhuyen":"20905"
   },
   {
      "id":"2090533",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"20905"
   },
   {
      "id":"2090535",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"20905"
   },
   {
      "id":"2090537",
      "phuongxa":"Xã  Hoàng Văn Thụ",
      "quanhuyen":"20905"
   },
   {
      "id":"2090539",
      "phuongxa":"Xã Nhạc Kỳ",
      "quanhuyen":"20905"
   },
   {
      "id":"2090700",
      "phuongxa":"Không xác định",
      "quanhuyen":"20907"
   },
   {
      "id":"2090703",
      "phuongxa":"Xã Vĩnh Yên",
      "quanhuyen":"20907"
   },
   {
      "id":"2090705",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"20907"
   },
   {
      "id":"2090707",
      "phuongxa":"Xã Hoa Thám",
      "quanhuyen":"20907"
   },
   {
      "id":"2090709",
      "phuongxa":"Xã Quý Hòa",
      "quanhuyen":"20907"
   },
   {
      "id":"2090711",
      "phuongxa":"Xã Yên Lỗ",
      "quanhuyen":"20907"
   },
   {
      "id":"2090713",
      "phuongxa":"Xã Thiện Hòa",
      "quanhuyen":"20907"
   },
   {
      "id":"2090717",
      "phuongxa":"Xã Thiện Long",
      "quanhuyen":"20907"
   },
   {
      "id":"2090719",
      "phuongxa":"Xã Mông Ân",
      "quanhuyen":"20907"
   },
   {
      "id":"2090723",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"20907"
   },
   {
      "id":"2090725",
      "phuongxa":"Xã Hồng Phong",
      "quanhuyen":"20907"
   },
   {
      "id":"2090727",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"20907"
   },
   {
      "id":"2090729",
      "phuongxa":"Xã Minh Khai",
      "quanhuyen":"20907"
   },
   {
      "id":"2090731",
      "phuongxa":"Xã Hoàng Văn Thụ",
      "quanhuyen":"20907"
   },
   {
      "id":"2090733",
      "phuongxa":"Xã Tô Hiệu",
      "quanhuyen":"20907"
   },
   {
      "id":"2090735",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"20907"
   },
   {
      "id":"2090737",
      "phuongxa":"Xã Bình La",
      "quanhuyen":"20907"
   },
   {
      "id":"2090739",
      "phuongxa":"Xã Tân Văn",
      "quanhuyen":"20907"
   },
   {
      "id":"2090900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20909"
   },
   {
      "id":"2090901",
      "phuongxa":"Thị trấn Bắc Sơn",
      "quanhuyen":"20909"
   },
   {
      "id":"2090903",
      "phuongxa":"Xã Vạn Thủy",
      "quanhuyen":"20909"
   },
   {
      "id":"2090905",
      "phuongxa":"Xã Đồng ý",
      "quanhuyen":"20909"
   },
   {
      "id":"2090907",
      "phuongxa":"Xã Vũ Sơn",
      "quanhuyen":"20909"
   },
   {
      "id":"2090909",
      "phuongxa":"Xã Tân Tri",
      "quanhuyen":"20909"
   },
   {
      "id":"2031745",
      "phuongxa":"Xã Tiên Thành",
      "quanhuyen":"20317"
   },
   {
      "id":"2031747",
      "phuongxa":"Xã Mỹ Hưng",
      "quanhuyen":"20317"
   },
   {
      "id":"2031749",
      "phuongxa":"Xã Tà Lùng",
      "quanhuyen":"20317"
   },
   {
      "id":"2031901",
      "phuongxa":"Xã Thanh Nhật",
      "quanhuyen":"20319"
   },
   {
      "id":"2031903",
      "phuongxa":"Xã Minh Long",
      "quanhuyen":"20319"
   },
   {
      "id":"2031905",
      "phuongxa":"Xã Thắng Lợi",
      "quanhuyen":"20319"
   },
   {
      "id":"2031907",
      "phuongxa":"Xã Lý Quốc",
      "quanhuyen":"20319"
   },
   {
      "id":"2031909",
      "phuongxa":"Xã Đồng Loan",
      "quanhuyen":"20319"
   },
   {
      "id":"2031911",
      "phuongxa":"Xã Đức Quang",
      "quanhuyen":"20319"
   },
   {
      "id":"2031913",
      "phuongxa":"Xã Kim Loan",
      "quanhuyen":"20319"
   },
   {
      "id":"2031915",
      "phuongxa":"Xã An Lạc",
      "quanhuyen":"20319"
   },
   {
      "id":"2031917",
      "phuongxa":"Xã Quang Long",
      "quanhuyen":"20319"
   },
   {
      "id":"2031919",
      "phuongxa":"Xã Vinh Quý",
      "quanhuyen":"20319"
   },
   {
      "id":"2031921",
      "phuongxa":"Xã Việt Chu",
      "quanhuyen":"20319"
   },
   {
      "id":"2031923",
      "phuongxa":"Xã Thái Đức",
      "quanhuyen":"20319"
   },
   {
      "id":"2031925",
      "phuongxa":"Xã Cô Ngân",
      "quanhuyen":"20319"
   },
   {
      "id":"2031927",
      "phuongxa":"Xã Thị Hoa",
      "quanhuyen":"20319"
   },
   {
      "id":"2032100",
      "phuongxa":"Không xác định",
      "quanhuyen":"20321"
   },
   {
      "id":"2032101",
      "phuongxa":"Xã Lê Lai",
      "quanhuyen":"20321"
   },
   {
      "id":"2032103",
      "phuongxa":"Xã Canh Tân",
      "quanhuyen":"20321"
   },
   {
      "id":"2032105",
      "phuongxa":"Xã Kim Đồng",
      "quanhuyen":"20321"
   },
   {
      "id":"2032107",
      "phuongxa":"Xã Minh Khai",
      "quanhuyen":"20321"
   },
   {
      "id":"2032109",
      "phuongxa":"Xã Đức Thông",
      "quanhuyen":"20321"
   },
   {
      "id":"2032111",
      "phuongxa":"Xã Quang Trọng",
      "quanhuyen":"20321"
   },
   {
      "id":"2032113",
      "phuongxa":"Xã Thái Cường",
      "quanhuyen":"20321"
   },
   {
      "id":"2032117",
      "phuongxa":"Xã Thị Ngân",
      "quanhuyen":"20321"
   },
   {
      "id":"2032119",
      "phuongxa":"Xã Thuỵ Hùng",
      "quanhuyen":"20321"
   },
   {
      "id":"2032121",
      "phuongxa":"Xã Đức Long",
      "quanhuyen":"20321"
   },
   {
      "id":"2032123",
      "phuongxa":"Xã Trọng Con",
      "quanhuyen":"20321"
   },
   {
      "id":"2032125",
      "phuongxa":"Xã Thượng Pha",
      "quanhuyen":"20321"
   },
   {
      "id":"2032127",
      "phuongxa":"Xã Danh Sỹ",
      "quanhuyen":"20321"
   },
   {
      "id":"2032129",
      "phuongxa":"Xã Lê Lợi",
      "quanhuyen":"20321"
   },
   {
      "id":"2032131",
      "phuongxa":"Xã Đức Xuân",
      "quanhuyen":"20321"
   },
   {
      "id":"2032133",
      "phuongxa":"Thị trấn Đông Khê",
      "quanhuyen":"20321"
   },
   {
      "id":"2032300",
      "phuongxa":"Không xác định",
      "quanhuyen":"20323"
   },
   {
      "id":"2032301",
      "phuongxa":"Xã Đức Hạnh",
      "quanhuyen":"20323"
   },
   {
      "id":"2032303",
      "phuongxa":"Xã Lý Bôn",
      "quanhuyen":"20323"
   },
   {
      "id":"2032305",
      "phuongxa":"Xã Nam Quang",
      "quanhuyen":"20323"
   },
   {
      "id":"2032307",
      "phuongxa":"Xã Vĩnh Quang",
      "quanhuyen":"20323"
   },
   {
      "id":"2032311",
      "phuongxa":"Xã Tân Việt",
      "quanhuyen":"20323"
   },
   {
      "id":"2032313",
      "phuongxa":"Xã Vĩnh Phong",
      "quanhuyen":"20323"
   },
   {
      "id":"2032315",
      "phuongxa":"Xã Mông Ân",
      "quanhuyen":"20323"
   },
   {
      "id":"2032317",
      "phuongxa":"Xã Thái Học",
      "quanhuyen":"20323"
   },
   {
      "id":"2032319",
      "phuongxa":"Xã Yên Thổ",
      "quanhuyen":"20323"
   },
   {
      "id":"2032323",
      "phuongxa":"Xã Thái Sơn",
      "quanhuyen":"20323"
   },
   {
      "id":"2032327",
      "phuongxa":"Xã Thạch Lâm",
      "quanhuyen":"20323"
   },
   {
      "id":"2032500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20325"
   },
   {
      "id":"2032501",
      "phuongxa":"Xã Đức Hạnh",
      "quanhuyen":"20325"
   },
   {
      "id":"2032503",
      "phuongxa":"Xã Lý Bôn",
      "quanhuyen":"20325"
   },
   {
      "id":"2032507",
      "phuongxa":"Xã Vĩnh Quang",
      "quanhuyen":"20325"
   },
   {
      "id":"2032509",
      "phuongxa":"Xã Quảng Lâm",
      "quanhuyen":"20325"
   },
   {
      "id":"2032511",
      "phuongxa":"Xã Tân Việt",
      "quanhuyen":"20325"
   },
   {
      "id":"2032513",
      "phuongxa":"Xã Vĩnh Phong",
      "quanhuyen":"20325"
   },
   {
      "id":"2032515",
      "phuongxa":"Xã Mông Ân",
      "quanhuyen":"20325"
   },
   {
      "id":"2032517",
      "phuongxa":"Xã Thái Học",
      "quanhuyen":"20325"
   },
   {
      "id":"2032519",
      "phuongxa":"Xã Yên Thổ",
      "quanhuyen":"20325"
   },
   {
      "id":"2032521",
      "phuongxa":"Thị trấn Pác Miầu",
      "quanhuyen":"20325"
   },
   {
      "id":"2032523",
      "phuongxa":"Xã Thái Sơn",
      "quanhuyen":"20325"
   },
   {
      "id":"2032527",
      "phuongxa":"Xã Thạch Lâm",
      "quanhuyen":"20325"
   },
   {
      "id":"2050500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20505"
   },
   {
      "id":"2050501",
      "phuongxa":"Xã Mường Khương",
      "quanhuyen":"20505"
   },
   {
      "id":"2050503",
      "phuongxa":"Xã Pha Long",
      "quanhuyen":"20505"
   },
   {
      "id":"2050505",
      "phuongxa":"Xã Tả Ngải Chồ",
      "quanhuyen":"20505"
   },
   {
      "id":"2050507",
      "phuongxa":"Xã Tung Chung Phố",
      "quanhuyen":"20505"
   },
   {
      "id":"2050509",
      "phuongxa":"Xã Dìn Chin",
      "quanhuyen":"20505"
   },
   {
      "id":"2050511",
      "phuongxa":"Xã Tả Gia Khâu",
      "quanhuyen":"20505"
   },
   {
      "id":"2050513",
      "phuongxa":"Xã Nậm Chảy",
      "quanhuyen":"20505"
   },
   {
      "id":"2050515",
      "phuongxa":"Xã Nấm Lư",
      "quanhuyen":"20505"
   },
   {
      "id":"2050517",
      "phuongxa":"Xã Lùng Khấu Nhin",
      "quanhuyen":"20505"
   },
   {
      "id":"2050519",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"20505"
   },
   {
      "id":"2150505",
      "phuongxa":"Xã Lam Vỹ",
      "quanhuyen":"21505"
   },
   {
      "id":"2150507",
      "phuongxa":"Xã Quy Kỳ",
      "quanhuyen":"21505"
   },
   {
      "id":"2150509",
      "phuongxa":"Xã Tân Thịnh",
      "quanhuyen":"21505"
   },
   {
      "id":"2150511",
      "phuongxa":"Xã Kim Phượng",
      "quanhuyen":"21505"
   },
   {
      "id":"2150513",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"21505"
   },
   {
      "id":"2150515",
      "phuongxa":"Xã Bảo Linh",
      "quanhuyen":"21505"
   },
   {
      "id":"2150519",
      "phuongxa":"Xã Tân Dương",
      "quanhuyen":"21505"
   },
   {
      "id":"2150521",
      "phuongxa":"Xã Phượng Tiến",
      "quanhuyen":"21505"
   },
   {
      "id":"2150523",
      "phuongxa":"Xã Bảo Cường",
      "quanhuyen":"21505"
   },
   {
      "id":"2150525",
      "phuongxa":"Xã Đồng Thịnh",
      "quanhuyen":"21505"
   },
   {
      "id":"2150527",
      "phuongxa":"Xã Định Biên",
      "quanhuyen":"21505"
   },
   {
      "id":"2150529",
      "phuongxa":"Xã Trung Hội",
      "quanhuyen":"21505"
   },
   {
      "id":"2150531",
      "phuongxa":"Xã Thanh Định",
      "quanhuyen":"21505"
   },
   {
      "id":"2150533",
      "phuongxa":"Xã Trung Lương",
      "quanhuyen":"21505"
   },
   {
      "id":"2150535",
      "phuongxa":"Xã Bình Yên",
      "quanhuyen":"21505"
   },
   {
      "id":"2150537",
      "phuongxa":"Xã Điềm Mặc",
      "quanhuyen":"21505"
   },
   {
      "id":"2150539",
      "phuongxa":"Xã Phú Tiến",
      "quanhuyen":"21505"
   },
   {
      "id":"2150541",
      "phuongxa":"Xã Bộc Nhiêu",
      "quanhuyen":"21505"
   },
   {
      "id":"2150543",
      "phuongxa":"Xã Sơn Phú",
      "quanhuyen":"21505"
   },
   {
      "id":"2150545",
      "phuongxa":"Xã Phú Đình",
      "quanhuyen":"21505"
   },
   {
      "id":"2150547",
      "phuongxa":"Xã Bình Thành",
      "quanhuyen":"21505"
   },
   {
      "id":"2150700",
      "phuongxa":"Không xác định",
      "quanhuyen":"21507"
   },
   {
      "id":"2150701",
      "phuongxa":"Thị trấn Đình Cả",
      "quanhuyen":"21507"
   },
   {
      "id":"2150703",
      "phuongxa":"Xã Sảng Mộc",
      "quanhuyen":"21507"
   },
   {
      "id":"2150705",
      "phuongxa":"Xã Nghinh Tường",
      "quanhuyen":"21507"
   },
   {
      "id":"2150709",
      "phuongxa":"Xã Vũ Chấn",
      "quanhuyen":"21507"
   },
   {
      "id":"2150711",
      "phuongxa":"Xã Thượng Nung",
      "quanhuyen":"21507"
   },
   {
      "id":"2150713",
      "phuongxa":"Xã Phú Thượng",
      "quanhuyen":"21507"
   },
   {
      "id":"2150715",
      "phuongxa":"Xã Cúc Đường",
      "quanhuyen":"21507"
   },
   {
      "id":"2150717",
      "phuongxa":"Xã Lâu Thượng",
      "quanhuyen":"21507"
   },
   {
      "id":"2150719",
      "phuongxa":"Xã Tràng Xá",
      "quanhuyen":"21507"
   },
   {
      "id":"2150721",
      "phuongxa":"Xã Phương Giao",
      "quanhuyen":"21507"
   },
   {
      "id":"2150723",
      "phuongxa":"Xã La Hiên",
      "quanhuyen":"21507"
   },
   {
      "id":"2150725",
      "phuongxa":"Xã Liên Minh",
      "quanhuyen":"21507"
   },
   {
      "id":"2150729",
      "phuongxa":"Xã Bình Long",
      "quanhuyen":"21507"
   },
   {
      "id":"2150900",
      "phuongxa":"Không xác định",
      "quanhuyen":"21509"
   },
   {
      "id":"2150901",
      "phuongxa":"Thị trấn Đu",
      "quanhuyen":"21509"
   },
   {
      "id":"2150903",
      "phuongxa":"Thị trấn Giang Tiên",
      "quanhuyen":"21509"
   },
   {
      "id":"2150905",
      "phuongxa":"Xã Yên Ninh",
      "quanhuyen":"21509"
   },
   {
      "id":"2150909",
      "phuongxa":"Xã Yên Đổ",
      "quanhuyen":"21509"
   },
   {
      "id":"2150911",
      "phuongxa":"Xã Yên Lạc",
      "quanhuyen":"21509"
   },
   {
      "id":"2150913",
      "phuongxa":"Xã Ôn Lương",
      "quanhuyen":"21509"
   },
   {
      "id":"2150915",
      "phuongxa":"Xã Động Đạt",
      "quanhuyen":"21509"
   },
   {
      "id":"2150919",
      "phuongxa":"Xã Hợp Thành",
      "quanhuyen":"21509"
   },
   {
      "id":"2150923",
      "phuongxa":"Xã Tức Tranh",
      "quanhuyen":"21509"
   },
   {
      "id":"2150925",
      "phuongxa":"Xã Phấn Mễ",
      "quanhuyen":"21509"
   },
   {
      "id":"2150927",
      "phuongxa":"Xã Vô Tranh",
      "quanhuyen":"21509"
   },
   {
      "id":"2150929",
      "phuongxa":"Xã Cổ Lũng",
      "quanhuyen":"21509"
   },
   {
      "id":"2150931",
      "phuongxa":"Xã Sơn Cẩm",
      "quanhuyen":"21509"
   },
   {
      "id":"2151101",
      "phuongxa":"Phường Chùa Hang",
      "quanhuyen":"21501"
   },
   {
      "id":"2151103",
      "phuongxa":"Thị trấn Sông Cầu",
      "quanhuyen":"21511"
   },
   {
      "id":"2151105",
      "phuongxa":"Thị trấn Trại Cau",
      "quanhuyen":"21511"
   },
   {
      "id":"2151107",
      "phuongxa":"Xã Văn Lăng",
      "quanhuyen":"21511"
   },
   {
      "id":"2151109",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"21511"
   },
   {
      "id":"2151111",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"21511"
   },
   {
      "id":"2151113",
      "phuongxa":"Xã Quang Sơn",
      "quanhuyen":"21511"
   },
   {
      "id":"2151115",
      "phuongxa":"Xã Minh Lập",
      "quanhuyen":"21511"
   },
   {
      "id":"2151117",
      "phuongxa":"Xã Văn Hán",
      "quanhuyen":"21511"
   },
   {
      "id":"2151119",
      "phuongxa":"Xã Hóa Trung",
      "quanhuyen":"21511"
   },
   {
      "id":"2151121",
      "phuongxa":"Xã Khe Mo",
      "quanhuyen":"21511"
   },
   {
      "id":"2151123",
      "phuongxa":"Xã Cây Thị",
      "quanhuyen":"21511"
   },
   {
      "id":"2151125",
      "phuongxa":"Xã Hóa Thượng",
      "quanhuyen":"21511"
   },
   {
      "id":"2151127",
      "phuongxa":"Xã Hợp Tiến",
      "quanhuyen":"21511"
   },
   {
      "id":"2151129",
      "phuongxa":"Xã Cao Ngạn",
      "quanhuyen":"21511"
   },
   {
      "id":"2151131",
      "phuongxa":"Xã Tân Lợi",
      "quanhuyen":"21511"
   },
   {
      "id":"2151133",
      "phuongxa":"Xã Linh Sơn",
      "quanhuyen":"21511"
   },
   {
      "id":"2151135",
      "phuongxa":"Xã Nam Hòa",
      "quanhuyen":"21511"
   },
   {
      "id":"2151137",
      "phuongxa":"Xã Đồng Bẩm",
      "quanhuyen":"21511"
   },
   {
      "id":"2151139",
      "phuongxa":"Xã Huống Thượng",
      "quanhuyen":"21511"
   },
   {
      "id":"2151300",
      "phuongxa":"Không xác định",
      "quanhuyen":"21513"
   },
   {
      "id":"2151301",
      "phuongxa":"Thị trấn Đại Từ",
      "quanhuyen":"21513"
   },
   {
      "id":"2151303",
      "phuongxa":"Thị trấn Quân Chu",
      "quanhuyen":"21513"
   },
   {
      "id":"2151307",
      "phuongxa":"Xã Minh Tiến",
      "quanhuyen":"21513"
   },
   {
      "id":"2151309",
      "phuongxa":"Xã Yên Lãng",
      "quanhuyen":"21513"
   },
   {
      "id":"2151311",
      "phuongxa":"Xã Đức Lương",
      "quanhuyen":"21513"
   },
   {
      "id":"2151313",
      "phuongxa":"Xã Phú Cường",
      "quanhuyen":"21513"
   },
   {
      "id":"2151315",
      "phuongxa":"Xã Na Mao",
      "quanhuyen":"21513"
   },
   {
      "id":"2151317",
      "phuongxa":"Xã Phú Lạc",
      "quanhuyen":"21513"
   },
   {
      "id":"2151319",
      "phuongxa":"Xã Phục Linh",
      "quanhuyen":"21513"
   },
   {
      "id":"2151321",
      "phuongxa":"Xã Phú Thịnh",
      "quanhuyen":"21513"
   },
   {
      "id":"2151323",
      "phuongxa":"Xã Phú Xuyên",
      "quanhuyen":"21513"
   },
   {
      "id":"2151325",
      "phuongxa":"Xã Bản Ngoại",
      "quanhuyen":"21513"
   },
   {
      "id":"2151327",
      "phuongxa":"Xã Tiên Hội",
      "quanhuyen":"21513"
   },
   {
      "id":"2151329",
      "phuongxa":"Xã Hùng Sơn",
      "quanhuyen":"21513"
   },
   {
      "id":"2151331",
      "phuongxa":"Xã Cù Vân",
      "quanhuyen":"21513"
   },
   {
      "id":"2151333",
      "phuongxa":"Xã La Bằng",
      "quanhuyen":"21513"
   },
   {
      "id":"2151335",
      "phuongxa":"Xã Hà Thượng",
      "quanhuyen":"21513"
   },
   {
      "id":"2151337",
      "phuongxa":"Xã Hoàng Nông",
      "quanhuyen":"21513"
   },
   {
      "id":"2151339",
      "phuongxa":"Xã Khôi Kỳ",
      "quanhuyen":"21513"
   },
   {
      "id":"2151341",
      "phuongxa":"Xã An Khánh",
      "quanhuyen":"21513"
   },
   {
      "id":"2151343",
      "phuongxa":"Xã Tân Thái",
      "quanhuyen":"21513"
   },
   {
      "id":"2151345",
      "phuongxa":"Xã Bình Thuận",
      "quanhuyen":"21513"
   },
   {
      "id":"2151349",
      "phuongxa":"Xã Mỹ Yên",
      "quanhuyen":"21513"
   },
   {
      "id":"2151351",
      "phuongxa":"Xã Văn Yên",
      "quanhuyen":"21513"
   },
   {
      "id":"2151353",
      "phuongxa":"Xã Ký Phú",
      "quanhuyen":"21513"
   },
   {
      "id":"2151355",
      "phuongxa":"Xã Vạn Thọ",
      "quanhuyen":"21513"
   },
   {
      "id":"2151357",
      "phuongxa":"Xã Cát Nê",
      "quanhuyen":"21513"
   },
   {
      "id":"2151359",
      "phuongxa":"Xã Quân Chu",
      "quanhuyen":"21513"
   },
   {
      "id":"2151361",
      "phuongxa":"Xã Tân Linh",
      "quanhuyen":"21513"
   },
   {
      "id":"2151500",
      "phuongxa":"Không xác định",
      "quanhuyen":"21515"
   },
   {
      "id":"2151501",
      "phuongxa":"Thị trấn úc Sơn",
      "quanhuyen":"21515"
   },
   {
      "id":"2151503",
      "phuongxa":"Xã Bàn Đạt",
      "quanhuyen":"21515"
   },
   {
      "id":"2151505",
      "phuongxa":"Xã Đồng Liên",
      "quanhuyen":"21515"
   },
   {
      "id":"2151507",
      "phuongxa":"Xã Tân Khánh",
      "quanhuyen":"21515"
   },
   {
      "id":"2151511",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"21515"
   },
   {
      "id":"2151513",
      "phuongxa":"Xã Đào Xá",
      "quanhuyen":"21515"
   },
   {
      "id":"2151515",
      "phuongxa":"Xã Thượng Đình",
      "quanhuyen":"21515"
   },
   {
      "id":"2151517",
      "phuongxa":"Xã Bảo Lý",
      "quanhuyen":"21515"
   },
   {
      "id":"2151519",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"21515"
   },
   {
      "id":"2151523",
      "phuongxa":"Xã Hương Sơn",
      "quanhuyen":"21515"
   },
   {
      "id":"2151527",
      "phuongxa":"Xã Xuân Phương",
      "quanhuyen":"21515"
   },
   {
      "id":"2151529",
      "phuongxa":"Xã Lương Phú",
      "quanhuyen":"21515"
   },
   {
      "id":"2151531",
      "phuongxa":"Xã Tân Đức",
      "quanhuyen":"21515"
   },
   {
      "id":"2151533",
      "phuongxa":"Xã úc Kỳ",
      "quanhuyen":"21515"
   },
   {
      "id":"2151537",
      "phuongxa":"Xã Kha Sơn",
      "quanhuyen":"21515"
   },
   {
      "id":"2151539",
      "phuongxa":"Xã Thanh Ninh",
      "quanhuyen":"21515"
   },
   {
      "id":"2151541",
      "phuongxa":"Xã Dương Thành",
      "quanhuyen":"21515"
   },
   {
      "id":"2151543",
      "phuongxa":"Xã Hà Châu",
      "quanhuyen":"21515"
   },
   {
      "id":"2151701",
      "phuongxa":"Thị trấn Ba Hàng",
      "quanhuyen":"21517"
   },
   {
      "id":"2151703",
      "phuongxa":"Thị trấn Bãi Bông",
      "quanhuyen":"21517"
   },
   {
      "id":"2151705",
      "phuongxa":"Thị trấn Bắc Sơn",
      "quanhuyen":"21517"
   },
   {
      "id":"2151707",
      "phuongxa":"Xã Phúc Tân",
      "quanhuyen":"21517"
   },
   {
      "id":"2151711",
      "phuongxa":"Xã Phúc Thuận",
      "quanhuyen":"21517"
   },
   {
      "id":"2151715",
      "phuongxa":"Xã Minh Đức",
      "quanhuyen":"21517"
   },
   {
      "id":"2151717",
      "phuongxa":"Xã Đắc Sơn",
      "quanhuyen":"21517"
   },
   {
      "id":"2151719",
      "phuongxa":"Xã Thành Công",
      "quanhuyen":"21517"
   },
   {
      "id":"2151721",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"21517"
   },
   {
      "id":"2151723",
      "phuongxa":"Xã Tiên Phong",
      "quanhuyen":"21517"
   },
   {
      "id":"2151725",
      "phuongxa":"Xã Vạn Phái",
      "quanhuyen":"21517"
   },
   {
      "id":"2151727",
      "phuongxa":"Xã Nam Tiến",
      "quanhuyen":"21517"
   },
   {
      "id":"2151729",
      "phuongxa":"Xã Tân Hương",
      "quanhuyen":"21517"
   },
   {
      "id":"2151731",
      "phuongxa":"Xã Đông Cao",
      "quanhuyen":"21517"
   },
   {
      "id":"2151733",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"21517"
   },
   {
      "id":"2151735",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"21517"
   },
   {
      "id":"2151737",
      "phuongxa":"Xã Thuận Thành",
      "quanhuyen":"21517"
   },
   {
      "id":"2170000",
      "phuongxa":"Không xác định",
      "quanhuyen":"21700"
   },
   {
      "id":"2170100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21701"
   },
   {
      "id":"2170101",
      "phuongxa":"Phường Tiên Cát",
      "quanhuyen":"21701"
   },
   {
      "id":"2170103",
      "phuongxa":"Phường Vân Cơ",
      "quanhuyen":"21701"
   },
   {
      "id":"2110100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21101"
   },
   {
      "id":"2110103",
      "phuongxa":"Phường Phan Thiết",
      "quanhuyen":"21101"
   },
   {
      "id":"2110105",
      "phuongxa":"Phường Minh Xuân",
      "quanhuyen":"21101"
   },
   {
      "id":"2110107",
      "phuongxa":"Xã Tràng Đà",
      "quanhuyen":"21101"
   },
   {
      "id":"2110109",
      "phuongxa":"Xã ỷ La",
      "quanhuyen":"21101"
   },
   {
      "id":"2110111",
      "phuongxa":"Xã Nông Tiến",
      "quanhuyen":"21101"
   },
   {
      "id":"2110113",
      "phuongxa":"Xã Hưng Thành",
      "quanhuyen":"21101"
   },
   {
      "id":"2110300",
      "phuongxa":"Không xác định",
      "quanhuyen":"21103"
   },
   {
      "id":"2110301",
      "phuongxa":"Thị trấn Vĩnh Yên",
      "quanhuyen":"21103"
   },
   {
      "id":"2110303",
      "phuongxa":"Xã Thúy Loa",
      "quanhuyen":"21103"
   },
   {
      "id":"2110305",
      "phuongxa":"Xã Thượng Giáp",
      "quanhuyen":"21103"
   },
   {
      "id":"2110307",
      "phuongxa":"Xã Sinh Long",
      "quanhuyen":"21103"
   },
   {
      "id":"2110309",
      "phuongxa":"Xã Thượng Nông",
      "quanhuyen":"21103"
   },
   {
      "id":"2110311",
      "phuongxa":"Xã Phúc Yên",
      "quanhuyen":"21103"
   },
   {
      "id":"2110313",
      "phuongxa":"Xã Xuân Lập",
      "quanhuyen":"21103"
   },
   {
      "id":"2110315",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"21103"
   },
   {
      "id":"2110317",
      "phuongxa":"Xã Đức Xuân",
      "quanhuyen":"21103"
   },
   {
      "id":"2110319",
      "phuongxa":"Xã Yên Hoa",
      "quanhuyen":"21103"
   },
   {
      "id":"2110321",
      "phuongxa":"Xã Côn Lôn",
      "quanhuyen":"21103"
   },
   {
      "id":"2110323",
      "phuongxa":"Xã Khuôn Hà",
      "quanhuyen":"21103"
   },
   {
      "id":"2110325",
      "phuongxa":"Xã Lăng Can",
      "quanhuyen":"21103"
   },
   {
      "id":"2110327",
      "phuongxa":"Xã Khâu Tinh",
      "quanhuyen":"21103"
   },
   {
      "id":"2110329",
      "phuongxa":"Xã Đà Vị",
      "quanhuyen":"21103"
   },
   {
      "id":"2110331",
      "phuongxa":"Xã Thượng Lâm",
      "quanhuyen":"21103"
   },
   {
      "id":"2110335",
      "phuongxa":"Xã Sơn Phú",
      "quanhuyen":"21103"
   },
   {
      "id":"2110337",
      "phuongxa":"Xã Năng Khả",
      "quanhuyen":"21103"
   },
   {
      "id":"2110339",
      "phuongxa":"Xã Vĩnh Yên",
      "quanhuyen":"21103"
   },
   {
      "id":"2110341",
      "phuongxa":"Xã Thanh Tương",
      "quanhuyen":"21103"
   },
   {
      "id":"2110500",
      "phuongxa":"Không xác định",
      "quanhuyen":"21105"
   },
   {
      "id":"2110501",
      "phuongxa":"Thị trấn Vĩnh Lộc",
      "quanhuyen":"21105"
   },
   {
      "id":"2110503",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"21105"
   },
   {
      "id":"2110507",
      "phuongxa":"Xã Thổ Bình",
      "quanhuyen":"21105"
   },
   {
      "id":"2110509",
      "phuongxa":"Xã Minh Quang",
      "quanhuyen":"21105"
   },
   {
      "id":"2110511",
      "phuongxa":"Xã Trung Hà",
      "quanhuyen":"21105"
   },
   {
      "id":"2110513",
      "phuongxa":"Xã Phúc Sơn",
      "quanhuyen":"21105"
   },
   {
      "id":"2110515",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"21105"
   },
   {
      "id":"2110517",
      "phuongxa":"Xã Hà Lang",
      "quanhuyen":"21105"
   },
   {
      "id":"2110521",
      "phuongxa":"Xã Yên Lập",
      "quanhuyen":"21105"
   },
   {
      "id":"2110523",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"21105"
   },
   {
      "id":"2110525",
      "phuongxa":"Xã Xuân Quang",
      "quanhuyen":"21105"
   },
   {
      "id":"2110527",
      "phuongxa":"Xã Phúc Thịnh",
      "quanhuyen":"21105"
   },
   {
      "id":"2110529",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"21105"
   },
   {
      "id":"2110533",
      "phuongxa":"Xã Ngọc Hội",
      "quanhuyen":"21105"
   },
   {
      "id":"2110535",
      "phuongxa":"Xã Tân Thịnh",
      "quanhuyen":"21105"
   },
   {
      "id":"2110537",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"21105"
   },
   {
      "id":"2110539",
      "phuongxa":"Xã Kiên Đài",
      "quanhuyen":"21105"
   },
   {
      "id":"2110543",
      "phuongxa":"Xã Kim Bình",
      "quanhuyen":"21105"
   },
   {
      "id":"2110547",
      "phuongxa":"Xã Hòa An",
      "quanhuyen":"21105"
   },
   {
      "id":"2110549",
      "phuongxa":"Xã Yên Nguyên",
      "quanhuyen":"21105"
   },
   {
      "id":"2110551",
      "phuongxa":"Xã Nhân Lý",
      "quanhuyen":"21105"
   },
   {
      "id":"2110553",
      "phuongxa":"Xã Tri Phú",
      "quanhuyen":"21105"
   },
   {
      "id":"2110555",
      "phuongxa":"Xã Bình Nhân",
      "quanhuyen":"21105"
   },
   {
      "id":"2110557",
      "phuongxa":"Xã Linh Phú",
      "quanhuyen":"21105"
   },
   {
      "id":"2110701",
      "phuongxa":"Thị trấn Tân Yên",
      "quanhuyen":"21107"
   },
   {
      "id":"2110703",
      "phuongxa":"Xã Yên Thuận",
      "quanhuyen":"21107"
   },
   {
      "id":"2110705",
      "phuongxa":"Xã Bạch Xa",
      "quanhuyen":"21107"
   },
   {
      "id":"2110707",
      "phuongxa":"Xã Minh Khương",
      "quanhuyen":"21107"
   },
   {
      "id":"2110709",
      "phuongxa":"Xã Phù Lưu",
      "quanhuyen":"21107"
   },
   {
      "id":"2110711",
      "phuongxa":"Xã Minh Dân",
      "quanhuyen":"21107"
   },
   {
      "id":"2110713",
      "phuongxa":"Xã Minh Hương",
      "quanhuyen":"21107"
   },
   {
      "id":"2110715",
      "phuongxa":"Xã Yên Hương",
      "quanhuyen":"21107"
   },
   {
      "id":"2110717",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"21107"
   },
   {
      "id":"2110719",
      "phuongxa":"Xã Bình Xa",
      "quanhuyen":"21107"
   },
   {
      "id":"2110721",
      "phuongxa":"Xã Nhân Mục",
      "quanhuyen":"21107"
   },
   {
      "id":"2110723",
      "phuongxa":"Xã Thái Sơn",
      "quanhuyen":"21107"
   },
   {
      "id":"2110727",
      "phuongxa":"Xã Bằng Cốc",
      "quanhuyen":"21107"
   },
   {
      "id":"2110729",
      "phuongxa":"Xã Thành Long",
      "quanhuyen":"21107"
   },
   {
      "id":"2110731",
      "phuongxa":"Xã Đức Ninh",
      "quanhuyen":"21107"
   },
   {
      "id":"2110733",
      "phuongxa":"Xã Hùng Đức",
      "quanhuyen":"21107"
   },
   {
      "id":"2110900",
      "phuongxa":"Không xác định",
      "quanhuyen":"21109"
   },
   {
      "id":"2110901",
      "phuongxa":"Thị trấn NT Sông Lô",
      "quanhuyen":"21109"
   },
   {
      "id":"2110903",
      "phuongxa":"Thị trấn NT Tháng 10",
      "quanhuyen":"21109"
   },
   {
      "id":"2110905",
      "phuongxa":"Thị trấn Tân Bình",
      "quanhuyen":"21109"
   },
   {
      "id":"2110907",
      "phuongxa":"Xã An Tường",
      "quanhuyen":"21109"
   },
   {
      "id":"2110909",
      "phuongxa":"Xã Lực Hành",
      "quanhuyen":"21109"
   },
   {
      "id":"2110911",
      "phuongxa":"Xã Chiêu Yên",
      "quanhuyen":"21109"
   },
   {
      "id":"2110913",
      "phuongxa":"Xã Quí Quân",
      "quanhuyen":"21109"
   },
   {
      "id":"2110915",
      "phuongxa":"Xã Kiến Thiết",
      "quanhuyen":"21109"
   },
   {
      "id":"2110917",
      "phuongxa":"Xã Trung Minh",
      "quanhuyen":"21109"
   },
   {
      "id":"2110919",
      "phuongxa":"Xã Trung Trực",
      "quanhuyen":"21109"
   },
   {
      "id":"2110921",
      "phuongxa":"Xã Xuân Vân",
      "quanhuyen":"21109"
   },
   {
      "id":"2110923",
      "phuongxa":"Xã Phúc Ninh",
      "quanhuyen":"21109"
   },
   {
      "id":"2110925",
      "phuongxa":"Xã Hùng Lợi",
      "quanhuyen":"21109"
   },
   {
      "id":"2110927",
      "phuongxa":"Xã Trung Sơn",
      "quanhuyen":"21109"
   },
   {
      "id":"2110931",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"21109"
   },
   {
      "id":"2110933",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"21109"
   },
   {
      "id":"2110935",
      "phuongxa":"Xã Đạo Viện",
      "quanhuyen":"21109"
   },
   {
      "id":"2110937",
      "phuongxa":"Xã Thắng Quân",
      "quanhuyen":"21109"
   },
   {
      "id":"2110939",
      "phuongxa":"Xã Kim Quan",
      "quanhuyen":"21109"
   },
   {
      "id":"2110941",
      "phuongxa":"Xã Lang Quán",
      "quanhuyen":"21109"
   },
   {
      "id":"2110943",
      "phuongxa":"Xã Phú Thịnh",
      "quanhuyen":"21109"
   },
   {
      "id":"2110945",
      "phuongxa":"Xã Trung Môn",
      "quanhuyen":"21109"
   },
   {
      "id":"2110947",
      "phuongxa":"Xã Công Đa",
      "quanhuyen":"21109"
   },
   {
      "id":"2110949",
      "phuongxa":"Xã Chân Sơn",
      "quanhuyen":"21109"
   },
   {
      "id":"2110951",
      "phuongxa":"Xã Thái Bình",
      "quanhuyen":"21109"
   },
   {
      "id":"2110953",
      "phuongxa":"Xã Kim Phú",
      "quanhuyen":"21109"
   },
   {
      "id":"2110955",
      "phuongxa":"Xã Tiến Bộ",
      "quanhuyen":"21109"
   },
   {
      "id":"2110957",
      "phuongxa":"Xã An Khang",
      "quanhuyen":"21109"
   },
   {
      "id":"2110959",
      "phuongxa":"Xã Lưỡng Vượng",
      "quanhuyen":"21109"
   },
   {
      "id":"2110961",
      "phuongxa":"Xã Hoàng Khai",
      "quanhuyen":"21109"
   },
   {
      "id":"2110963",
      "phuongxa":"Xã Phú Lâm",
      "quanhuyen":"21109"
   },
   {
      "id":"2110965",
      "phuongxa":"Xã Mỹ Bằng",
      "quanhuyen":"21109"
   },
   {
      "id":"2110969",
      "phuongxa":"Xã Đội Cấn",
      "quanhuyen":"21109"
   },
   {
      "id":"2110971",
      "phuongxa":"Xã An Khê",
      "quanhuyen":"21109"
   },
   {
      "id":"2110973",
      "phuongxa":"Xã Đội Bình",
      "quanhuyen":"21109"
   },
   {
      "id":"2111101",
      "phuongxa":"Thị trấn Sơn Dương",
      "quanhuyen":"21111"
   },
   {
      "id":"2111103",
      "phuongxa":"Thị trấn NT Tân Trào",
      "quanhuyen":"21111"
   },
   {
      "id":"2111105",
      "phuongxa":"Xã Trung Yên",
      "quanhuyen":"21111"
   },
   {
      "id":"2111107",
      "phuongxa":"Xã Minh Thanh",
      "quanhuyen":"21111"
   },
   {
      "id":"2111109",
      "phuongxa":"Xã Tân Trào",
      "quanhuyen":"21111"
   },
   {
      "id":"2111113",
      "phuongxa":"Xã Bình Yên",
      "quanhuyen":"21111"
   },
   {
      "id":"2111115",
      "phuongxa":"Xã Thượng ấm",
      "quanhuyen":"21111"
   },
   {
      "id":"2111117",
      "phuongxa":"Xã Lương Thiện",
      "quanhuyen":"21111"
   },
   {
      "id":"2111119",
      "phuongxa":"Xã Tú Thịnh",
      "quanhuyen":"21111"
   },
   {
      "id":"2111121",
      "phuongxa":"Xã Hợp Thành",
      "quanhuyen":"21111"
   },
   {
      "id":"2111125",
      "phuongxa":"Xã Đông Thọ",
      "quanhuyen":"21111"
   },
   {
      "id":"2111127",
      "phuongxa":"Xã Kháng Nhật",
      "quanhuyen":"21111"
   },
   {
      "id":"2111129",
      "phuongxa":"Xã Phúc ứng",
      "quanhuyen":"21111"
   },
   {
      "id":"2111131",
      "phuongxa":"Xã Quyết Thắng",
      "quanhuyen":"21111"
   },
   {
      "id":"2111133",
      "phuongxa":"Xã Hợp Hòa",
      "quanhuyen":"21111"
   },
   {
      "id":"2111137",
      "phuongxa":"Xã Thanh Phát",
      "quanhuyen":"21111"
   },
   {
      "id":"2111139",
      "phuongxa":"Xã Văn Phú",
      "quanhuyen":"21111"
   },
   {
      "id":"2111141",
      "phuongxa":"Xã Vân Sơn",
      "quanhuyen":"21111"
   },
   {
      "id":"2111143",
      "phuongxa":"Xã Chi Thiết",
      "quanhuyen":"21111"
   },
   {
      "id":"2111145",
      "phuongxa":"Xã Tuân Lộ",
      "quanhuyen":"21111"
   },
   {
      "id":"2111147",
      "phuongxa":"Xã Thiện Kế",
      "quanhuyen":"21111"
   },
   {
      "id":"2111149",
      "phuongxa":"Xã Hồng Lạc",
      "quanhuyen":"21111"
   },
   {
      "id":"2111151",
      "phuongxa":"Xã Đông Lợi",
      "quanhuyen":"21111"
   },
   {
      "id":"2111153",
      "phuongxa":"Xã Phú Lương",
      "quanhuyen":"21111"
   },
   {
      "id":"2111155",
      "phuongxa":"Xã Ninh Lai",
      "quanhuyen":"21111"
   },
   {
      "id":"2111157",
      "phuongxa":"Xã Sầm Dương",
      "quanhuyen":"21111"
   },
   {
      "id":"2111159",
      "phuongxa":"Xã Hào Phú",
      "quanhuyen":"21111"
   },
   {
      "id":"2111161",
      "phuongxa":"Xã Sơn Nam",
      "quanhuyen":"21111"
   },
   {
      "id":"2111165",
      "phuongxa":"Xã Đại Phú",
      "quanhuyen":"21111"
   },
   {
      "id":"2111167",
      "phuongxa":"Xã Lâm Xuyên",
      "quanhuyen":"21111"
   },
   {
      "id":"2130000",
      "phuongxa":"Không xác định",
      "quanhuyen":"21300"
   },
   {
      "id":"2130100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21301"
   },
   {
      "id":"2130101",
      "phuongxa":"Phường Nguyễn Thái Học",
      "quanhuyen":"21301"
   },
   {
      "id":"2130103",
      "phuongxa":"Phường Hồng Hà",
      "quanhuyen":"21301"
   },
   {
      "id":"2130105",
      "phuongxa":"Phường Nguyễn Phúc",
      "quanhuyen":"21301"
   },
   {
      "id":"2130107",
      "phuongxa":"Phường Minh Tân",
      "quanhuyen":"21301"
   },
   {
      "id":"2130109",
      "phuongxa":"Phường Yên Ninh",
      "quanhuyen":"21301"
   },
   {
      "id":"2130111",
      "phuongxa":"Phường Đồng Tâm",
      "quanhuyen":"21301"
   },
   {
      "id":"2130113",
      "phuongxa":"Phường Yên Thịnh",
      "quanhuyen":"21301"
   },
   {
      "id":"2130115",
      "phuongxa":"Xã Nam Cường",
      "quanhuyen":"21301"
   },
   {
      "id":"2130117",
      "phuongxa":"Xã Tuy Lộc",
      "quanhuyen":"21301"
   },
   {
      "id":"2130119",
      "phuongxa":"Xã Minh Bảo",
      "quanhuyen":"21301"
   },
   {
      "id":"2130121",
      "phuongxa":"Xã Tân Thịnh",
      "quanhuyen":"21301"
   },
   {
      "id":"2130300",
      "phuongxa":"Không xác định",
      "quanhuyen":"21303"
   },
   {
      "id":"2130301",
      "phuongxa":"Phường Trung Tâm",
      "quanhuyen":"21303"
   },
   {
      "id":"2130303",
      "phuongxa":"Phường Pú Trạng",
      "quanhuyen":"21303"
   },
   {
      "id":"2130307",
      "phuongxa":"Phường Cầu Thia",
      "quanhuyen":"21303"
   },
   {
      "id":"2130500",
      "phuongxa":"Không xác định",
      "quanhuyen":"21305"
   },
   {
      "id":"2130501",
      "phuongxa":"Thị trấn Yên Thế",
      "quanhuyen":"21305"
   },
   {
      "id":"2130503",
      "phuongxa":"Xã Yên Thắng",
      "quanhuyen":"21305"
   },
   {
      "id":"2130505",
      "phuongxa":"Xã Tân Phượng",
      "quanhuyen":"21305"
   },
   {
      "id":"2130507",
      "phuongxa":"Xã Lâm Thượng",
      "quanhuyen":"21305"
   },
   {
      "id":"2130509",
      "phuongxa":"Xã Khánh Thiện",
      "quanhuyen":"21305"
   },
   {
      "id":"2130511",
      "phuongxa":"Xã Minh Chuẩn",
      "quanhuyen":"21305"
   },
   {
      "id":"2130513",
      "phuongxa":"Xã Mai Sơn",
      "quanhuyen":"21305"
   },
   {
      "id":"2130515",
      "phuongxa":"Xã An Lạc",
      "quanhuyen":"21305"
   },
   {
      "id":"2130517",
      "phuongxa":"Xã Khai Trung",
      "quanhuyen":"21305"
   },
   {
      "id":"2130519",
      "phuongxa":"Xã Minh Xuân",
      "quanhuyen":"21305"
   },
   {
      "id":"2130521",
      "phuongxa":"Xã Mường Lai",
      "quanhuyen":"21305"
   },
   {
      "id":"2130523",
      "phuongxa":"Xã Khánh Hoà",
      "quanhuyen":"21305"
   },
   {
      "id":"2130525",
      "phuongxa":"Xã Tô Mậu",
      "quanhuyen":"21305"
   },
   {
      "id":"2130527",
      "phuongxa":"Xã Tân Lĩnh",
      "quanhuyen":"21305"
   },
   {
      "id":"2130529",
      "phuongxa":"Xã Trúc Lâu",
      "quanhuyen":"21305"
   },
   {
      "id":"2130531",
      "phuongxa":"Xã Động Quan",
      "quanhuyen":"21305"
   },
   {
      "id":"2130535",
      "phuongxa":"Xã Vĩnh Lạc",
      "quanhuyen":"21305"
   },
   {
      "id":"2130537",
      "phuongxa":"Xã Phúc Lợi",
      "quanhuyen":"21305"
   },
   {
      "id":"2130541",
      "phuongxa":"Xã Minh Tiến",
      "quanhuyen":"21305"
   },
   {
      "id":"2130543",
      "phuongxa":"Xã Trung Tâm",
      "quanhuyen":"21305"
   },
   {
      "id":"2130547",
      "phuongxa":"Xã An Phú",
      "quanhuyen":"21305"
   },
   {
      "id":"2130700",
      "phuongxa":"Không xác định",
      "quanhuyen":"21307"
   },
   {
      "id":"2130701",
      "phuongxa":"Thị trấn Mậu A",
      "quanhuyen":"21307"
   },
   {
      "id":"2130703",
      "phuongxa":"Xã Lang Thíp",
      "quanhuyen":"21307"
   },
   {
      "id":"2130705",
      "phuongxa":"Xã Châu Quế Thượng",
      "quanhuyen":"21307"
   },
   {
      "id":"2130709",
      "phuongxa":"Xã Châu Quế Hạ",
      "quanhuyen":"21307"
   },
   {
      "id":"2130711",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"21307"
   },
   {
      "id":"2130713",
      "phuongxa":"Xã Đông An",
      "quanhuyen":"21307"
   },
   {
      "id":"2130715",
      "phuongxa":"Xã Quang Minh",
      "quanhuyen":"21307"
   },
   {
      "id":"2130717",
      "phuongxa":"Xã Phong Dụ Hạ",
      "quanhuyen":"21307"
   },
   {
      "id":"2130721",
      "phuongxa":"Xã Phong Dụ Thượng",
      "quanhuyen":"21307"
   },
   {
      "id":"2130723",
      "phuongxa":"Xã Xuân Tầm",
      "quanhuyen":"21307"
   },
   {
      "id":"2130725",
      "phuongxa":"Xã Tân Hợp",
      "quanhuyen":"21307"
   },
   {
      "id":"2130727",
      "phuongxa":"Xã Mậu Đông",
      "quanhuyen":"21307"
   },
   {
      "id":"2130729",
      "phuongxa":"Xã Ngòi A",
      "quanhuyen":"21307"
   },
   {
      "id":"2130733",
      "phuongxa":"Xã Yên Thái",
      "quanhuyen":"21307"
   },
   {
      "id":"2130735",
      "phuongxa":"Xã Đại Phác",
      "quanhuyen":"21307"
   },
   {
      "id":"2130737",
      "phuongxa":"Xã Yên Hợp",
      "quanhuyen":"21307"
   },
   {
      "id":"2130739",
      "phuongxa":"Xã Yên Hưng",
      "quanhuyen":"21307"
   },
   {
      "id":"2130741",
      "phuongxa":"Xã Đại Sơn",
      "quanhuyen":"21307"
   },
   {
      "id":"2130743",
      "phuongxa":"Xã Nà Hẩu",
      "quanhuyen":"21307"
   },
   {
      "id":"2130745",
      "phuongxa":"Xã Yên Phú",
      "quanhuyen":"21307"
   },
   {
      "id":"2130747",
      "phuongxa":"Xã Xuân ái",
      "quanhuyen":"21307"
   },
   {
      "id":"2130749",
      "phuongxa":"Xã Mỏ Vàng",
      "quanhuyen":"21307"
   },
   {
      "id":"2130751",
      "phuongxa":"Xã Viễn Sơn",
      "quanhuyen":"21307"
   },
   {
      "id":"2130753",
      "phuongxa":"Xã Hoàng Thắng",
      "quanhuyen":"21307"
   },
   {
      "id":"2130900",
      "phuongxa":"Không xác định",
      "quanhuyen":"21309"
   },
   {
      "id":"2130901",
      "phuongxa":"Xã Mồ Dề",
      "quanhuyen":"21309"
   },
   {
      "id":"2130903",
      "phuongxa":"Xã Kim Nọi",
      "quanhuyen":"21309"
   },
   {
      "id":"2130905",
      "phuongxa":"Xã Hồ Bốn",
      "quanhuyen":"21309"
   },
   {
      "id":"2130907",
      "phuongxa":"Xã Khao Mang",
      "quanhuyen":"21309"
   },
   {
      "id":"2130911",
      "phuongxa":"Xã Nậm Có",
      "quanhuyen":"21309"
   },
   {
      "id":"2130913",
      "phuongxa":"Xã Lao Chải",
      "quanhuyen":"21309"
   },
   {
      "id":"2130915",
      "phuongxa":"Xã Dế Su Phình",
      "quanhuyen":"21309"
   },
   {
      "id":"2130917",
      "phuongxa":"Xã La Pán Tẩn",
      "quanhuyen":"21309"
   },
   {
      "id":"2130919",
      "phuongxa":"Xã Cao Phạ",
      "quanhuyen":"21309"
   },
   {
      "id":"2130921",
      "phuongxa":"Xã Chế Tạo",
      "quanhuyen":"21309"
   },
   {
      "id":"2130923",
      "phuongxa":"Xã Púng Luông",
      "quanhuyen":"21309"
   },
   {
      "id":"2130925",
      "phuongxa":"Xã Nậm Khắt",
      "quanhuyen":"21309"
   },
   {
      "id":"2130927",
      "phuongxa":"Thị trấn Mù Cang Chải",
      "quanhuyen":"21309"
   },
   {
      "id":"2131100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21311"
   },
   {
      "id":"2131101",
      "phuongxa":"Thị trấn Cổ Phúc",
      "quanhuyen":"21311"
   },
   {
      "id":"2131103",
      "phuongxa":"Xã Tân Đồng",
      "quanhuyen":"21311"
   },
   {
      "id":"2131105",
      "phuongxa":"Xã Báo Đáp",
      "quanhuyen":"21311"
   },
   {
      "id":"2131107",
      "phuongxa":"Xã Đào Thịnh",
      "quanhuyen":"21311"
   },
   {
      "id":"2131109",
      "phuongxa":"Xã Quy Mông",
      "quanhuyen":"21311"
   },
   {
      "id":"2131111",
      "phuongxa":"Xã Việt Thành",
      "quanhuyen":"21311"
   },
   {
      "id":"2131113",
      "phuongxa":"Xã Hòa Cuông",
      "quanhuyen":"21311"
   },
   {
      "id":"2131115",
      "phuongxa":"Xã Kiên Thành",
      "quanhuyen":"21311"
   },
   {
      "id":"2131117",
      "phuongxa":"Xã Y Can",
      "quanhuyen":"21311"
   },
   {
      "id":"2131119",
      "phuongxa":"Xã Minh Tiến",
      "quanhuyen":"21311"
   },
   {
      "id":"2131121",
      "phuongxa":"Xã Nga Quán",
      "quanhuyen":"21311"
   },
   {
      "id":"2131125",
      "phuongxa":"Xã Cường Thịnh",
      "quanhuyen":"21311"
   },
   {
      "id":"2131127",
      "phuongxa":"Xã Âu Lâu",
      "quanhuyen":"21311"
   },
   {
      "id":"2131129",
      "phuongxa":"Xã Lương Thịnh",
      "quanhuyen":"21311"
   },
   {
      "id":"2131131",
      "phuongxa":"Xã Hợp Minh",
      "quanhuyen":"21311"
   },
   {
      "id":"2131133",
      "phuongxa":"Xã Giới Phiên",
      "quanhuyen":"21311"
   },
   {
      "id":"2131135",
      "phuongxa":"Xã Hồng Ca",
      "quanhuyen":"21311"
   },
   {
      "id":"2131137",
      "phuongxa":"Xã Hưng Khánh",
      "quanhuyen":"21311"
   },
   {
      "id":"2131139",
      "phuongxa":"Xã Hưng Thịnh",
      "quanhuyen":"21311"
   },
   {
      "id":"2131141",
      "phuongxa":"Xã Việt Cường",
      "quanhuyen":"21311"
   },
   {
      "id":"2131143",
      "phuongxa":"Xã Minh Quân",
      "quanhuyen":"21311"
   },
   {
      "id":"2131145",
      "phuongxa":"Xã Bảo Hưng",
      "quanhuyen":"21311"
   },
   {
      "id":"2131147",
      "phuongxa":"Xã Phúc Lộc",
      "quanhuyen":"21311"
   },
   {
      "id":"2131149",
      "phuongxa":"Xã Văn Phú",
      "quanhuyen":"21311"
   },
   {
      "id":"2131151",
      "phuongxa":"Xã Văn Tiến",
      "quanhuyen":"21311"
   },
   {
      "id":"2131155",
      "phuongxa":"Xã Việt Hồng",
      "quanhuyen":"21311"
   },
   {
      "id":"2131157",
      "phuongxa":"Xã Vân Hội",
      "quanhuyen":"21311"
   },
   {
      "id":"2131300",
      "phuongxa":"Không xác định",
      "quanhuyen":"21313"
   },
   {
      "id":"2131301",
      "phuongxa":"Thị trấn Yên Bình",
      "quanhuyen":"21313"
   },
   {
      "id":"2131303",
      "phuongxa":"Thị trấn Thác Bà",
      "quanhuyen":"21313"
   },
   {
      "id":"2131307",
      "phuongxa":"Xã Ngọc Chấn",
      "quanhuyen":"21313"
   },
   {
      "id":"2131311",
      "phuongxa":"Xã Tân Nguyên",
      "quanhuyen":"21313"
   },
   {
      "id":"2131313",
      "phuongxa":"Xã Phúc Ninh",
      "quanhuyen":"21313"
   },
   {
      "id":"2131315",
      "phuongxa":"Xã Mỹ Gia",
      "quanhuyen":"21313"
   },
   {
      "id":"2131319",
      "phuongxa":"Xã Bảo ái",
      "quanhuyen":"21313"
   },
   {
      "id":"2131321",
      "phuongxa":"Xã Cẩm Ân",
      "quanhuyen":"21313"
   },
   {
      "id":"2131323",
      "phuongxa":"Xã Xuân Lai",
      "quanhuyen":"21313"
   },
   {
      "id":"2131325",
      "phuongxa":"Xã Tân Hương",
      "quanhuyen":"21313"
   },
   {
      "id":"2131327",
      "phuongxa":"Xã Mông Sơn",
      "quanhuyen":"21313"
   },
   {
      "id":"2131329",
      "phuongxa":"Xã Yên Thành",
      "quanhuyen":"21313"
   },
   {
      "id":"2131333",
      "phuongxa":"Xã Phúc An",
      "quanhuyen":"21313"
   },
   {
      "id":"2131335",
      "phuongxa":"Xã Phú Thịnh",
      "quanhuyen":"21313"
   },
   {
      "id":"2131337",
      "phuongxa":"Xã Vũ Linh",
      "quanhuyen":"21313"
   },
   {
      "id":"2131339",
      "phuongxa":"Xã Bạch Hà",
      "quanhuyen":"21313"
   },
   {
      "id":"2131341",
      "phuongxa":"Xã Thịnh Hưng",
      "quanhuyen":"21313"
   },
   {
      "id":"2131345",
      "phuongxa":"Xã Yên Bình",
      "quanhuyen":"21313"
   },
   {
      "id":"2131347",
      "phuongxa":"Xã Hán Đà",
      "quanhuyen":"21313"
   },
   {
      "id":"2131349",
      "phuongxa":"Xã Đại Minh",
      "quanhuyen":"21313"
   },
   {
      "id":"2131500",
      "phuongxa":"Không xác định",
      "quanhuyen":"21315"
   },
   {
      "id":"2131501",
      "phuongxa":"Thị trấn NT Liên Sơn",
      "quanhuyen":"21315"
   },
   {
      "id":"2131503",
      "phuongxa":"Thị trấn NT Nghĩa Lộ",
      "quanhuyen":"21315"
   },
   {
      "id":"2131505",
      "phuongxa":"Thị trấn NT Trần Phú",
      "quanhuyen":"21315"
   },
   {
      "id":"2131507",
      "phuongxa":"Xã Tú Lệ",
      "quanhuyen":"21315"
   },
   {
      "id":"2131511",
      "phuongxa":"Xã Gia Hội",
      "quanhuyen":"21315"
   },
   {
      "id":"2131513",
      "phuongxa":"Xã Nậm Mười",
      "quanhuyen":"21315"
   },
   {
      "id":"2131515",
      "phuongxa":"Xã Sùng Đô",
      "quanhuyen":"21315"
   },
   {
      "id":"2131517",
      "phuongxa":"Xã Nậm Lành",
      "quanhuyen":"21315"
   },
   {
      "id":"2131519",
      "phuongxa":"Xã Sơn Lương",
      "quanhuyen":"21315"
   },
   {
      "id":"2131521",
      "phuongxa":"Xã Suối Quyền",
      "quanhuyen":"21315"
   },
   {
      "id":"2131523",
      "phuongxa":"Xã An Lương",
      "quanhuyen":"21315"
   },
   {
      "id":"2131525",
      "phuongxa":"Xã Sơn A",
      "quanhuyen":"21315"
   },
   {
      "id":"2131527",
      "phuongxa":"Xã Nghĩa Lợi",
      "quanhuyen":"21315"
   },
   {
      "id":"2131529",
      "phuongxa":"Xã Nghĩa Phúc",
      "quanhuyen":"21315"
   },
   {
      "id":"2131531",
      "phuongxa":"Xã Phù Nham",
      "quanhuyen":"21315"
   },
   {
      "id":"2131533",
      "phuongxa":"Xã Suối Giàng",
      "quanhuyen":"21315"
   },
   {
      "id":"2131535",
      "phuongxa":"Xã Nghĩa Sơn",
      "quanhuyen":"21315"
   },
   {
      "id":"2131537",
      "phuongxa":"Xã Nghĩa An",
      "quanhuyen":"21315"
   },
   {
      "id":"2131539",
      "phuongxa":"Xã Thanh Lương",
      "quanhuyen":"21315"
   },
   {
      "id":"2131541",
      "phuongxa":"Xã Sơn Thịnh",
      "quanhuyen":"21315"
   },
   {
      "id":"2131543",
      "phuongxa":"Xã Hạnh Sơn",
      "quanhuyen":"21315"
   },
   {
      "id":"2131545",
      "phuongxa":"Xã Phúc Sơn",
      "quanhuyen":"21315"
   },
   {
      "id":"2131547",
      "phuongxa":"Xã Thạch Lương",
      "quanhuyen":"21315"
   },
   {
      "id":"2131551",
      "phuongxa":"Xã Đồng Khê",
      "quanhuyen":"21315"
   },
   {
      "id":"2131553",
      "phuongxa":"Xã Cát Thịnh",
      "quanhuyen":"21315"
   },
   {
      "id":"2131555",
      "phuongxa":"Xã Tân Thịnh",
      "quanhuyen":"21315"
   },
   {
      "id":"2131557",
      "phuongxa":"Xã Đại Lịch",
      "quanhuyen":"21315"
   },
   {
      "id":"2131559",
      "phuongxa":"Xã Chấn Thịnh",
      "quanhuyen":"21315"
   },
   {
      "id":"2131561",
      "phuongxa":"Xã Bình Thuận",
      "quanhuyen":"21315"
   },
   {
      "id":"2131563",
      "phuongxa":"Xã Thượng Bằng La",
      "quanhuyen":"21315"
   },
   {
      "id":"2131565",
      "phuongxa":"Xã Nghĩa Tâm",
      "quanhuyen":"21315"
   },
   {
      "id":"2131567",
      "phuongxa":"Xã Minh An",
      "quanhuyen":"21315"
   },
   {
      "id":"2131700",
      "phuongxa":"Không xác định",
      "quanhuyen":"21317"
   },
   {
      "id":"2131701",
      "phuongxa":"Xã Hát Lìu",
      "quanhuyen":"21317"
   },
   {
      "id":"2131703",
      "phuongxa":"Xã Túc Đán",
      "quanhuyen":"21317"
   },
   {
      "id":"2131705",
      "phuongxa":"Xã Xà Hồ",
      "quanhuyen":"21317"
   },
   {
      "id":"2131707",
      "phuongxa":"Xã Pá Lau",
      "quanhuyen":"21317"
   },
   {
      "id":"2131709",
      "phuongxa":"Xã Bản Công",
      "quanhuyen":"21317"
   },
   {
      "id":"2131711",
      "phuongxa":"Xã Trạm Tấu",
      "quanhuyen":"21317"
   },
   {
      "id":"2131713",
      "phuongxa":"Xã Pá Hu",
      "quanhuyen":"21317"
   },
   {
      "id":"2131715",
      "phuongxa":"Xã Làng Nhì",
      "quanhuyen":"21317"
   },
   {
      "id":"2131719",
      "phuongxa":"Xã Bản Mù",
      "quanhuyen":"21317"
   },
   {
      "id":"2131721",
      "phuongxa":"Xã Tà Si Láng",
      "quanhuyen":"21317"
   },
   {
      "id":"2131723",
      "phuongxa":"Thị trấn Trạm Tấu",
      "quanhuyen":"21317"
   },
   {
      "id":"2150000",
      "phuongxa":"Không xác định",
      "quanhuyen":"21500"
   },
   {
      "id":"2150100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21501"
   },
   {
      "id":"2150101",
      "phuongxa":"Phường Trưng Vương",
      "quanhuyen":"21501"
   },
   {
      "id":"2150105",
      "phuongxa":"Phường Quán Triều",
      "quanhuyen":"21501"
   },
   {
      "id":"2150107",
      "phuongxa":"Phường Quang Vinh",
      "quanhuyen":"21501"
   },
   {
      "id":"2150109",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"21501"
   },
   {
      "id":"2150111",
      "phuongxa":"Phường Hoàng Văn Thụ",
      "quanhuyen":"21501"
   },
   {
      "id":"2150115",
      "phuongxa":"Phường Phan Đình Phùng",
      "quanhuyen":"21501"
   },
   {
      "id":"2150117",
      "phuongxa":"Phường Túc Duyên",
      "quanhuyen":"21501"
   },
   {
      "id":"2150119",
      "phuongxa":"Phường Tân Thịnh",
      "quanhuyen":"21501"
   },
   {
      "id":"2150121",
      "phuongxa":"Phường Gia Sàng",
      "quanhuyen":"21501"
   },
   {
      "id":"2150123",
      "phuongxa":"Phường Tân Lập",
      "quanhuyen":"21501"
   },
   {
      "id":"2150127",
      "phuongxa":"Phường Cam Giá",
      "quanhuyen":"21501"
   },
   {
      "id":"2150129",
      "phuongxa":"Phường Trung Thành",
      "quanhuyen":"21501"
   },
   {
      "id":"2150131",
      "phuongxa":"Phường Hương Sơn",
      "quanhuyen":"21501"
   },
   {
      "id":"2150133",
      "phuongxa":"Phường Tân Thành",
      "quanhuyen":"21501"
   },
   {
      "id":"2150139",
      "phuongxa":"Xã Thịnh Đán",
      "quanhuyen":"21501"
   },
   {
      "id":"2150141",
      "phuongxa":"Xã Phúc Trìu",
      "quanhuyen":"21501"
   },
   {
      "id":"2150143",
      "phuongxa":"Xã Thịnh Đức",
      "quanhuyen":"21501"
   },
   {
      "id":"2150145",
      "phuongxa":"Xã Tân Cương",
      "quanhuyen":"21501"
   },
   {
      "id":"2150147",
      "phuongxa":"Xã Tích Lương",
      "quanhuyen":"21501"
   },
   {
      "id":"2150149",
      "phuongxa":"Xã Lương Sơn",
      "quanhuyen":"21501"
   },
   {
      "id":"2150300",
      "phuongxa":"Không xác định",
      "quanhuyen":"21503"
   },
   {
      "id":"2150301",
      "phuongxa":"Phường Thắng Lợi",
      "quanhuyen":"21503"
   },
   {
      "id":"2150303",
      "phuongxa":"Phường Lương Châu",
      "quanhuyen":"21503"
   },
   {
      "id":"2150305",
      "phuongxa":"Phường Mỏ Chè",
      "quanhuyen":"21503"
   },
   {
      "id":"2150307",
      "phuongxa":"Xã Tân Quang",
      "quanhuyen":"21503"
   },
   {
      "id":"2150309",
      "phuongxa":"Xã Bá Xuyên",
      "quanhuyen":"21503"
   },
   {
      "id":"2150313",
      "phuongxa":"Phường Cải Đan",
      "quanhuyen":"21503"
   },
   {
      "id":"2150315",
      "phuongxa":"Phường Phố Cò",
      "quanhuyen":"21503"
   },
   {
      "id":"2150317",
      "phuongxa":"Xã Bình Sơn",
      "quanhuyen":"21503"
   },
   {
      "id":"2150500",
      "phuongxa":"Không xác định",
      "quanhuyen":"21505"
   },
   {
      "id":"2150501",
      "phuongxa":"Thị trấn Chợ Chu",
      "quanhuyen":"21505"
   },
   {
      "id":"2150503",
      "phuongxa":"Xã Linh Thông",
      "quanhuyen":"21505"
   },
   {
      "id":"2170753",
      "phuongxa":"Xã Lang Sơn",
      "quanhuyen":"21707"
   },
   {
      "id":"2170755",
      "phuongxa":"Xã Yên Luật",
      "quanhuyen":"21707"
   },
   {
      "id":"2170757",
      "phuongxa":"Xã Chính Công",
      "quanhuyen":"21707"
   },
   {
      "id":"2170759",
      "phuongxa":"Xã Mai Tùng",
      "quanhuyen":"21707"
   },
   {
      "id":"2170761",
      "phuongxa":"Xã Minh Côi",
      "quanhuyen":"21707"
   },
   {
      "id":"2170763",
      "phuongxa":"Xã Vĩnh Chân",
      "quanhuyen":"21707"
   },
   {
      "id":"2170765",
      "phuongxa":"Xã Vụ Cầu",
      "quanhuyen":"21707"
   },
   {
      "id":"2170900",
      "phuongxa":"Không xác định",
      "quanhuyen":"21709"
   },
   {
      "id":"2170901",
      "phuongxa":"Thị trấn Thanh Ba",
      "quanhuyen":"21709"
   },
   {
      "id":"2170903",
      "phuongxa":"Xã Thanh Vân",
      "quanhuyen":"21709"
   },
   {
      "id":"2170905",
      "phuongxa":"Xã Hanh Cù",
      "quanhuyen":"21709"
   },
   {
      "id":"2170907",
      "phuongxa":"Xã Đông Lĩnh",
      "quanhuyen":"21709"
   },
   {
      "id":"2170909",
      "phuongxa":"Xã Đồng Xuân",
      "quanhuyen":"21709"
   },
   {
      "id":"2170913",
      "phuongxa":"Xã Vũ Yển",
      "quanhuyen":"21709"
   },
   {
      "id":"2170915",
      "phuongxa":"Xã Đại An",
      "quanhuyen":"21709"
   },
   {
      "id":"2170917",
      "phuongxa":"Xã Thái Ninh",
      "quanhuyen":"21709"
   },
   {
      "id":"2170919",
      "phuongxa":"Xã Năng Yên",
      "quanhuyen":"21709"
   },
   {
      "id":"2170921",
      "phuongxa":"Xã Quảng Nạp",
      "quanhuyen":"21709"
   },
   {
      "id":"2170923",
      "phuongxa":"Xã Ninh Dân",
      "quanhuyen":"21709"
   },
   {
      "id":"2170925",
      "phuongxa":"Xã Yên Nội",
      "quanhuyen":"21709"
   },
   {
      "id":"2170927",
      "phuongxa":"Xã Phương Lĩnh",
      "quanhuyen":"21709"
   },
   {
      "id":"2170929",
      "phuongxa":"Xã Mạn Lạn",
      "quanhuyen":"21709"
   },
   {
      "id":"2170931",
      "phuongxa":"Xã Khải Xuân",
      "quanhuyen":"21709"
   },
   {
      "id":"2170933",
      "phuongxa":"Xã Võ Lao",
      "quanhuyen":"21709"
   },
   {
      "id":"2170935",
      "phuongxa":"Xã Thanh Xá",
      "quanhuyen":"21709"
   },
   {
      "id":"2170937",
      "phuongxa":"Xã Hoàng Cương",
      "quanhuyen":"21709"
   },
   {
      "id":"2170939",
      "phuongxa":"Xã Chí Tiên",
      "quanhuyen":"21709"
   },
   {
      "id":"2170941",
      "phuongxa":"Xã Đông Thành",
      "quanhuyen":"21709"
   },
   {
      "id":"2170943",
      "phuongxa":"Xã Sơn Cương",
      "quanhuyen":"21709"
   },
   {
      "id":"2170945",
      "phuongxa":"Xã Thanh Hà",
      "quanhuyen":"21709"
   },
   {
      "id":"2170947",
      "phuongxa":"Xã Đỗ Sơn",
      "quanhuyen":"21709"
   },
   {
      "id":"2170951",
      "phuongxa":"Xã Lương Lỗ",
      "quanhuyen":"21709"
   },
   {
      "id":"2171100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21711"
   },
   {
      "id":"2171101",
      "phuongxa":"Thị trấn Phong Châu",
      "quanhuyen":"21711"
   },
   {
      "id":"2171107",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"21711"
   },
   {
      "id":"2171109",
      "phuongxa":"Xã Liên Hoa",
      "quanhuyen":"21711"
   },
   {
      "id":"2171111",
      "phuongxa":"Xã Trạm Thản",
      "quanhuyen":"21711"
   },
   {
      "id":"2171113",
      "phuongxa":"Xã Trị Quận",
      "quanhuyen":"21711"
   },
   {
      "id":"2171115",
      "phuongxa":"Xã Trung Giáp",
      "quanhuyen":"21711"
   },
   {
      "id":"2171117",
      "phuongxa":"Xã Hạ Giáp",
      "quanhuyen":"21711"
   },
   {
      "id":"2171121",
      "phuongxa":"Xã Tiên Phú",
      "quanhuyen":"21711"
   },
   {
      "id":"2171123",
      "phuongxa":"Xã Gia Thanh",
      "quanhuyen":"21711"
   },
   {
      "id":"2171125",
      "phuongxa":"Xã Tiên Du",
      "quanhuyen":"21711"
   },
   {
      "id":"2171127",
      "phuongxa":"Xã Phú Nham",
      "quanhuyen":"21711"
   },
   {
      "id":"2171129",
      "phuongxa":"Xã Phú Lộc",
      "quanhuyen":"21711"
   },
   {
      "id":"2171133",
      "phuongxa":"Xã Bình Bộ",
      "quanhuyen":"21711"
   },
   {
      "id":"2171137",
      "phuongxa":"Xã Tử Đà",
      "quanhuyen":"21711"
   },
   {
      "id":"2171139",
      "phuongxa":"Xã Phù Ninh",
      "quanhuyen":"21711"
   },
   {
      "id":"2171147",
      "phuongxa":"Xã Vĩnh Phú",
      "quanhuyen":"21711"
   },
   {
      "id":"2171149",
      "phuongxa":"Xã Kim Đức",
      "quanhuyen":"21711"
   },
   {
      "id":"2171203",
      "phuongxa":"Xã Tiên Kiên",
      "quanhuyen":"21712"
   },
   {
      "id":"2171205",
      "phuongxa":"Xã Xuân Lũng",
      "quanhuyen":"21712"
   },
   {
      "id":"2171207",
      "phuongxa":"Xã Xuân Huy",
      "quanhuyen":"21712"
   },
   {
      "id":"2171209",
      "phuongxa":"Xã Hà Thạch",
      "quanhuyen":"21712"
   },
   {
      "id":"2171211",
      "phuongxa":"Xã Hy Cương",
      "quanhuyen":"21712"
   },
   {
      "id":"2171213",
      "phuongxa":"Xã Thạch Sơn",
      "quanhuyen":"21712"
   },
   {
      "id":"2171215",
      "phuongxa":"Xã Chu Hóa",
      "quanhuyen":"21712"
   },
   {
      "id":"2171217",
      "phuongxa":"Xã Thanh Đình",
      "quanhuyen":"21712"
   },
   {
      "id":"2171219",
      "phuongxa":"Xã Sơn Vy",
      "quanhuyen":"21712"
   },
   {
      "id":"2171221",
      "phuongxa":"Xã Sơn Dương",
      "quanhuyen":"21712"
   },
   {
      "id":"2171223",
      "phuongxa":"Xã Hợp Hải",
      "quanhuyen":"21712"
   },
   {
      "id":"2171225",
      "phuongxa":"Xã Cao Xá",
      "quanhuyen":"21712"
   },
   {
      "id":"2171227",
      "phuongxa":"Xã Tứ Xã",
      "quanhuyen":"21712"
   },
   {
      "id":"2171229",
      "phuongxa":"Xã Kinh Kệ",
      "quanhuyen":"21712"
   },
   {
      "id":"2171231",
      "phuongxa":"Xã Vĩnh Lại",
      "quanhuyen":"21712"
   },
   {
      "id":"2171233",
      "phuongxa":"Xã Bản Nguyên",
      "quanhuyen":"21712"
   },
   {
      "id":"2171300",
      "phuongxa":"Không xác định",
      "quanhuyen":"21713"
   },
   {
      "id":"2171303",
      "phuongxa":"Xã Tiên Lương",
      "quanhuyen":"21713"
   },
   {
      "id":"2171305",
      "phuongxa":"Xã Tuy Lộc",
      "quanhuyen":"21713"
   },
   {
      "id":"2171307",
      "phuongxa":"Xã Ngô Xá",
      "quanhuyen":"21713"
   },
   {
      "id":"2211327",
      "phuongxa":"Xã An Châu",
      "quanhuyen":"22113"
   },
   {
      "id":"2211329",
      "phuongxa":"Xã Tuấn Đạo",
      "quanhuyen":"22113"
   },
   {
      "id":"2211331",
      "phuongxa":"Xã Bồng Am",
      "quanhuyen":"22113"
   },
   {
      "id":"2211333",
      "phuongxa":"Xã Dương Hưu",
      "quanhuyen":"22113"
   },
   {
      "id":"2211335",
      "phuongxa":"Xã Thanh Luận",
      "quanhuyen":"22113"
   },
   {
      "id":"2211337",
      "phuongxa":"Xã Long Sơn",
      "quanhuyen":"22113"
   },
   {
      "id":"2211339",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"22113"
   },
   {
      "id":"2211341",
      "phuongxa":"Xã Thạch Sơn",
      "quanhuyen":"22113"
   },
   {
      "id":"2211343",
      "phuongxa":"Xã Phúc Thắng",
      "quanhuyen":"22113"
   },
   {
      "id":"2211500",
      "phuongxa":"Không xác định",
      "quanhuyen":"22115"
   },
   {
      "id":"2211501",
      "phuongxa":"Thị trấn Lục Nam",
      "quanhuyen":"22115"
   },
   {
      "id":"2211503",
      "phuongxa":"Thị trấn Đồi Ngô",
      "quanhuyen":"22115"
   },
   {
      "id":"2211505",
      "phuongxa":"Xã Tiên Hưng",
      "quanhuyen":"22115"
   },
   {
      "id":"2211507",
      "phuongxa":"Xã Bảo Sơn",
      "quanhuyen":"22115"
   },
   {
      "id":"2211509",
      "phuongxa":"Xã Tam Dị",
      "quanhuyen":"22115"
   },
   {
      "id":"2211511",
      "phuongxa":"Xã Đông Phú",
      "quanhuyen":"22115"
   },
   {
      "id":"2211513",
      "phuongxa":"Xã Đông Hưng",
      "quanhuyen":"22115"
   },
   {
      "id":"2211515",
      "phuongxa":"Xã Bảo Đài",
      "quanhuyen":"22115"
   },
   {
      "id":"2211517",
      "phuongxa":"Xã Thanh Lâm",
      "quanhuyen":"22115"
   },
   {
      "id":"2211519",
      "phuongxa":"Xã Phương Sơn",
      "quanhuyen":"22115"
   },
   {
      "id":"2211521",
      "phuongxa":"Xã Tiên Nha",
      "quanhuyen":"22115"
   },
   {
      "id":"2211523",
      "phuongxa":"Xã Trường Giang",
      "quanhuyen":"22115"
   },
   {
      "id":"2211527",
      "phuongxa":"Xã Cương Sơn",
      "quanhuyen":"22115"
   },
   {
      "id":"2211529",
      "phuongxa":"Xã Lan Mẫu",
      "quanhuyen":"22115"
   },
   {
      "id":"2211531",
      "phuongxa":"Xã Nghĩa Phương",
      "quanhuyen":"22115"
   },
   {
      "id":"2211533",
      "phuongxa":"Xã Vô Tranh",
      "quanhuyen":"22115"
   },
   {
      "id":"2211535",
      "phuongxa":"Xã Khám Lạng",
      "quanhuyen":"22115"
   },
   {
      "id":"2211537",
      "phuongxa":"Xã Huyền Sơn",
      "quanhuyen":"22115"
   },
   {
      "id":"2211539",
      "phuongxa":"Xã Bình Sơn",
      "quanhuyen":"22115"
   },
   {
      "id":"2211541",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"22115"
   },
   {
      "id":"2211543",
      "phuongxa":"Xã Bắc Lũng",
      "quanhuyen":"22115"
   },
   {
      "id":"2211545",
      "phuongxa":"Xã Vũ Xá",
      "quanhuyen":"22115"
   },
   {
      "id":"2211547",
      "phuongxa":"Xã Trường Sơn",
      "quanhuyen":"22115"
   },
   {
      "id":"2211549",
      "phuongxa":"Xã Lục Sơn",
      "quanhuyen":"22115"
   },
   {
      "id":"2211551",
      "phuongxa":"Xã Cẩm Lý",
      "quanhuyen":"22115"
   },
   {
      "id":"2211553",
      "phuongxa":"Xã Đan Hội",
      "quanhuyen":"22115"
   },
   {
      "id":"2211700",
      "phuongxa":"Không xác định",
      "quanhuyen":"22117"
   },
   {
      "id":"2211701",
      "phuongxa":"Thị trấn  Bích Động",
      "quanhuyen":"22117"
   },
   {
      "id":"2211705",
      "phuongxa":"Xã Thượng Lan",
      "quanhuyen":"22117"
   },
   {
      "id":"2211707",
      "phuongxa":"Xã Việt Tiến",
      "quanhuyen":"22117"
   },
   {
      "id":"2211709",
      "phuongxa":"Xã Minh Đức",
      "quanhuyen":"22117"
   },
   {
      "id":"2211713",
      "phuongxa":"Xã Hương Mai",
      "quanhuyen":"22117"
   },
   {
      "id":"2211717",
      "phuongxa":"Xã Trung Sơn",
      "quanhuyen":"22117"
   },
   {
      "id":"2211719",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"22117"
   },
   {
      "id":"2211721",
      "phuongxa":"Xã Quảng Minh",
      "quanhuyen":"22117"
   },
   {
      "id":"2211723",
      "phuongxa":"Xã Hoàng Ninh",
      "quanhuyen":"22117"
   },
   {
      "id":"2211725",
      "phuongxa":"Xã Tăng Tiến",
      "quanhuyen":"22117"
   },
   {
      "id":"2211729",
      "phuongxa":"Xã Ninh Sơn",
      "quanhuyen":"22117"
   },
   {
      "id":"2211731",
      "phuongxa":"Xã Vân Trung",
      "quanhuyen":"22117"
   },
   {
      "id":"2211733",
      "phuongxa":"Xã Vân Hà",
      "quanhuyen":"22117"
   },
   {
      "id":"2211735",
      "phuongxa":"Xã Quang Châu",
      "quanhuyen":"22117"
   },
   {
      "id":"2211900",
      "phuongxa":"Không xác định",
      "quanhuyen":"22119"
   },
   {
      "id":"2211903",
      "phuongxa":"Xã Nham Sơn",
      "quanhuyen":"22119"
   },
   {
      "id":"2211905",
      "phuongxa":"Xã Lão Hộ",
      "quanhuyen":"22119"
   },
   {
      "id":"2211907",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"22119"
   },
   {
      "id":"2211909",
      "phuongxa":"Xã Đồng Sơn",
      "quanhuyen":"22119"
   },
   {
      "id":"2211911",
      "phuongxa":"Xã Hương Gián",
      "quanhuyen":"22119"
   },
   {
      "id":"2211913",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"22119"
   },
   {
      "id":"2211915",
      "phuongxa":"Xã Song Khê",
      "quanhuyen":"22119"
   },
   {
      "id":"2211917",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"22119"
   },
   {
      "id":"2211919",
      "phuongxa":"Xã Quỳnh Sơn",
      "quanhuyen":"22119"
   },
   {
      "id":"2211921",
      "phuongxa":"Xã Nội Hoàng",
      "quanhuyen":"22119"
   },
   {
      "id":"2211923",
      "phuongxa":"Xã Tân Liễu",
      "quanhuyen":"22119"
   },
   {
      "id":"2211925",
      "phuongxa":"Xã Xuân Phú",
      "quanhuyen":"22119"
   },
   {
      "id":"2211927",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"22119"
   },
   {
      "id":"2211929",
      "phuongxa":"Xã Lãng Sơn",
      "quanhuyen":"22119"
   },
   {
      "id":"2211931",
      "phuongxa":"Xã Trí Yên",
      "quanhuyen":"22119"
   },
   {
      "id":"2211933",
      "phuongxa":"Xã Tiến Dũng",
      "quanhuyen":"22119"
   },
   {
      "id":"2211937",
      "phuongxa":"Xã Cảnh Thụy",
      "quanhuyen":"22119"
   },
   {
      "id":"2211939",
      "phuongxa":"Xã Tư Mại",
      "quanhuyen":"22119"
   },
   {
      "id":"2211941",
      "phuongxa":"Xã Đức Giang",
      "quanhuyen":"22119"
   },
   {
      "id":"2211943",
      "phuongxa":"Xã Thắng Cương",
      "quanhuyen":"22119"
   },
   {
      "id":"2211945",
      "phuongxa":"Xã Đồng Phúc",
      "quanhuyen":"22119"
   },
   {
      "id":"2211947",
      "phuongxa":"Xã Đồng Việt",
      "quanhuyen":"22119"
   },
   {
      "id":"2230000",
      "phuongxa":"Không xác định",
      "quanhuyen":"22300"
   },
   {
      "id":"2230100",
      "phuongxa":"Không xác định",
      "quanhuyen":"22301"
   },
   {
      "id":"2230101",
      "phuongxa":"Phường Ninh Xá",
      "quanhuyen":"22301"
   },
   {
      "id":"2230103",
      "phuongxa":"Phường Đáp Cầu",
      "quanhuyen":"22301"
   },
   {
      "id":"2230105",
      "phuongxa":"Phường Thị Cầu",
      "quanhuyen":"22301"
   },
   {
      "id":"2230107",
      "phuongxa":"Phường Tiền An",
      "quanhuyen":"22301"
   },
   {
      "id":"2230109",
      "phuongxa":"Phường Vệ An",
      "quanhuyen":"22301"
   },
   {
      "id":"2230111",
      "phuongxa":"Xã Vũ Ninh",
      "quanhuyen":"22301"
   },
   {
      "id":"2230113",
      "phuongxa":"Xã Kinh Bắc",
      "quanhuyen":"22301"
   },
   {
      "id":"2230115",
      "phuongxa":"Xã Đại Phúc",
      "quanhuyen":"22301"
   },
   {
      "id":"2230117",
      "phuongxa":"Xã Võ Cường",
      "quanhuyen":"22301"
   },
   {
      "id":"2230300",
      "phuongxa":"Không xác định",
      "quanhuyen":"22303"
   },
   {
      "id":"2230303",
      "phuongxa":"Xã Dũng Liệt",
      "quanhuyen":"22303"
   },
   {
      "id":"2230305",
      "phuongxa":"Xã Hòa Tiến",
      "quanhuyen":"22303"
   },
   {
      "id":"2230307",
      "phuongxa":"Xã Tam Giang",
      "quanhuyen":"22303"
   },
   {
      "id":"2230309",
      "phuongxa":"Xã Tam Đa",
      "quanhuyen":"22303"
   },
   {
      "id":"2230311",
      "phuongxa":"Xã Yên Trung",
      "quanhuyen":"22303"
   },
   {
      "id":"2230313",
      "phuongxa":"Xã Đông Tiến",
      "quanhuyen":"22303"
   },
   {
      "id":"2230315",
      "phuongxa":"Xã Thụy Hòa",
      "quanhuyen":"22303"
   },
   {
      "id":"2230317",
      "phuongxa":"Xã Hòa Long",
      "quanhuyen":"22303"
   },
   {
      "id":"2230319",
      "phuongxa":"Xã Yên Phụ",
      "quanhuyen":"22303"
   },
   {
      "id":"2230321",
      "phuongxa":"Xã Đông Phong",
      "quanhuyen":"22303"
   },
   {
      "id":"2230323",
      "phuongxa":"Xã Vạn An",
      "quanhuyen":"22303"
   },
   {
      "id":"2230325",
      "phuongxa":"Xã Long Châu",
      "quanhuyen":"22303"
   },
   {
      "id":"2230327",
      "phuongxa":"Xã Trung Nghĩa",
      "quanhuyen":"22303"
   },
   {
      "id":"2230329",
      "phuongxa":"Xã Khúc Xuyên",
      "quanhuyen":"22303"
   },
   {
      "id":"2230331",
      "phuongxa":"Xã Văn Môn",
      "quanhuyen":"22303"
   },
   {
      "id":"2230333",
      "phuongxa":"Xã Đông Thọ",
      "quanhuyen":"22303"
   },
   {
      "id":"2230335",
      "phuongxa":"Xã Phong Khê",
      "quanhuyen":"22303"
   },
   {
      "id":"2230500",
      "phuongxa":"Không xác định",
      "quanhuyen":"22305"
   },
   {
      "id":"2230501",
      "phuongxa":"Thị trấn Phố Mới",
      "quanhuyen":"22305"
   },
   {
      "id":"2230505",
      "phuongxa":"Xã Kim Chân",
      "quanhuyen":"22305"
   },
   {
      "id":"2230507",
      "phuongxa":"Xã Đại Xuân",
      "quanhuyen":"22305"
   },
   {
      "id":"2230511",
      "phuongxa":"Xã Bằng An",
      "quanhuyen":"22305"
   },
   {
      "id":"2230513",
      "phuongxa":"Xã Quế Tân",
      "quanhuyen":"22305"
   },
   {
      "id":"2230515",
      "phuongxa":"Xã Vân Dương",
      "quanhuyen":"22305"
   },
   {
      "id":"2230517",
      "phuongxa":"Xã Phù Lương",
      "quanhuyen":"22305"
   },
   {
      "id":"2230519",
      "phuongxa":"Xã Phương Liễu",
      "quanhuyen":"22305"
   },
   {
      "id":"2230523",
      "phuongxa":"Xã Việt Hùng",
      "quanhuyen":"22305"
   },
   {
      "id":"2230525",
      "phuongxa":"Xã Nam Sơn",
      "quanhuyen":"22305"
   },
   {
      "id":"2230527",
      "phuongxa":"Xã Phù Lãng",
      "quanhuyen":"22305"
   },
   {
      "id":"2230529",
      "phuongxa":"Xã Ngọc Xá",
      "quanhuyen":"22305"
   },
   {
      "id":"2230531",
      "phuongxa":"Xã Châu Phong",
      "quanhuyen":"22305"
   },
   {
      "id":"2230533",
      "phuongxa":"Xã Cách Bi",
      "quanhuyen":"22305"
   },
   {
      "id":"2230537",
      "phuongxa":"Xã Đào Viên",
      "quanhuyen":"22305"
   },
   {
      "id":"2230539",
      "phuongxa":"Xã Bồng Lai",
      "quanhuyen":"22305"
   },
   {
      "id":"2230541",
      "phuongxa":"Xã Mộ Đạo",
      "quanhuyen":"22305"
   },
   {
      "id":"2230543",
      "phuongxa":"Xã Đức Long",
      "quanhuyen":"22305"
   },
   {
      "id":"2230545",
      "phuongxa":"Xã Chi Lăng",
      "quanhuyen":"22305"
   },
   {
      "id":"2230700",
      "phuongxa":"Không xác định",
      "quanhuyen":"22307"
   },
   {
      "id":"2230701",
      "phuongxa":"Thị trấn Lim",
      "quanhuyen":"22307"
   },
   {
      "id":"2230705",
      "phuongxa":"Xã Phú Lâm",
      "quanhuyen":"22307"
   },
   {
      "id":"2230715",
      "phuongxa":"Xã Nội Duệ",
      "quanhuyen":"22307"
   },
   {
      "id":"2230721",
      "phuongxa":"Xã Hạp Lĩnh",
      "quanhuyen":"22307"
   },
   {
      "id":"2230723",
      "phuongxa":"Xã Liên Bão",
      "quanhuyen":"22307"
   },
   {
      "id":"2230727",
      "phuongxa":"Xã Lạc Vệ",
      "quanhuyen":"22307"
   },
   {
      "id":"2230729",
      "phuongxa":"Xã Hiên Vân",
      "quanhuyen":"22307"
   },
   {
      "id":"2230735",
      "phuongxa":"Xã Việt Đoàn",
      "quanhuyen":"22307"
   },
   {
      "id":"2230737",
      "phuongxa":"Xã Hoàn Sơn",
      "quanhuyen":"22307"
   },
   {
      "id":"2230741",
      "phuongxa":"Xã Phật Tích",
      "quanhuyen":"22307"
   },
   {
      "id":"2230745",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"22307"
   },
   {
      "id":"2230747",
      "phuongxa":"Xã Minh Đạo",
      "quanhuyen":"22307"
   },
   {
      "id":"2230749",
      "phuongxa":"Xã Tri Phương",
      "quanhuyen":"22307"
   },
   {
      "id":"2230751",
      "phuongxa":"Xã Cảnh Hưng",
      "quanhuyen":"22307"
   },
   {
      "id":"2230753",
      "phuongxa":"Xã Tân Chi",
      "quanhuyen":"22307"
   },
   {
      "id":"2230801",
      "phuongxa":"Thị trấn Từ Sơn",
      "quanhuyen":"22308"
   },
   {
      "id":"2230803",
      "phuongxa":"Xã Hương Mạc",
      "quanhuyen":"22308"
   },
   {
      "id":"2230805",
      "phuongxa":"Xã Tam Sơn",
      "quanhuyen":"22308"
   },
   {
      "id":"2171309",
      "phuongxa":"Xã Phượng Vĩ",
      "quanhuyen":"21713"
   },
   {
      "id":"2171311",
      "phuongxa":"Xã Phương Xá",
      "quanhuyen":"21713"
   },
   {
      "id":"2171313",
      "phuongxa":"Xã Phùng Xá",
      "quanhuyen":"21713"
   },
   {
      "id":"2171315",
      "phuongxa":"Xã Đồng Cam",
      "quanhuyen":"21713"
   },
   {
      "id":"2171319",
      "phuongxa":"Xã Tam Sơn",
      "quanhuyen":"21713"
   },
   {
      "id":"2171321",
      "phuongxa":"Xã Tùng Khê",
      "quanhuyen":"21713"
   },
   {
      "id":"2171323",
      "phuongxa":"Xã Văn Bán",
      "quanhuyen":"21713"
   },
   {
      "id":"2171325",
      "phuongxa":"Xã Sơn Nga",
      "quanhuyen":"21713"
   },
   {
      "id":"2171327",
      "phuongxa":"Xã Sai Nga",
      "quanhuyen":"21713"
   },
   {
      "id":"2171329",
      "phuongxa":"Xã Cấp Dẫn",
      "quanhuyen":"21713"
   },
   {
      "id":"2171331",
      "phuongxa":"Xã Thanh Nga",
      "quanhuyen":"21713"
   },
   {
      "id":"2171333",
      "phuongxa":"Xã Xương Thịnh",
      "quanhuyen":"21713"
   },
   {
      "id":"2171335",
      "phuongxa":"Xã Phú Khê",
      "quanhuyen":"21713"
   },
   {
      "id":"2171337",
      "phuongxa":"Xã Sơn Tình",
      "quanhuyen":"21713"
   },
   {
      "id":"2171339",
      "phuongxa":"Xã Hương Lung",
      "quanhuyen":"21713"
   },
   {
      "id":"2171341",
      "phuongxa":"Xã Yên Tập",
      "quanhuyen":"21713"
   },
   {
      "id":"2171343",
      "phuongxa":"Xã Tạ Xá",
      "quanhuyen":"21713"
   },
   {
      "id":"2171345",
      "phuongxa":"Xã Tình Cương",
      "quanhuyen":"21713"
   },
   {
      "id":"2171347",
      "phuongxa":"Xã Phú Lạc",
      "quanhuyen":"21713"
   },
   {
      "id":"2171349",
      "phuongxa":"Xã Chương Xá",
      "quanhuyen":"21713"
   },
   {
      "id":"2171351",
      "phuongxa":"Xã Hiền Đa",
      "quanhuyen":"21713"
   },
   {
      "id":"2171353",
      "phuongxa":"Xã Văn Khúc",
      "quanhuyen":"21713"
   },
   {
      "id":"2171355",
      "phuongxa":"Xã Cát Trù",
      "quanhuyen":"21713"
   },
   {
      "id":"2171357",
      "phuongxa":"Xã Điêu Lương",
      "quanhuyen":"21713"
   },
   {
      "id":"2171361",
      "phuongxa":"Xã Đồng Lương",
      "quanhuyen":"21713"
   },
   {
      "id":"2171500",
      "phuongxa":"Không xác định",
      "quanhuyen":"21715"
   },
   {
      "id":"2171501",
      "phuongxa":"Thị trấn Yên Lập",
      "quanhuyen":"21715"
   },
   {
      "id":"2171503",
      "phuongxa":"Xã Mỹ Lung",
      "quanhuyen":"21715"
   },
   {
      "id":"2171505",
      "phuongxa":"Xã Mỹ Lương",
      "quanhuyen":"21715"
   },
   {
      "id":"2171507",
      "phuongxa":"Xã Lương Sơn",
      "quanhuyen":"21715"
   },
   {
      "id":"2171509",
      "phuongxa":"Xã Xuân An",
      "quanhuyen":"21715"
   },
   {
      "id":"2171513",
      "phuongxa":"Xã Xuân Viên",
      "quanhuyen":"21715"
   },
   {
      "id":"2171515",
      "phuongxa":"Xã Xuân Thủy",
      "quanhuyen":"21715"
   },
   {
      "id":"2171517",
      "phuongxa":"Xã Hưng Long",
      "quanhuyen":"21715"
   },
   {
      "id":"2171519",
      "phuongxa":"Xã Nga Hoàng",
      "quanhuyen":"21715"
   },
   {
      "id":"2171521",
      "phuongxa":"Xã Thượng Long",
      "quanhuyen":"21715"
   },
   {
      "id":"2171525",
      "phuongxa":"Xã Đồng Lạc",
      "quanhuyen":"21715"
   },
   {
      "id":"2171527",
      "phuongxa":"Xã Phúc Khánh",
      "quanhuyen":"21715"
   },
   {
      "id":"2171529",
      "phuongxa":"Xã Minh Hòa",
      "quanhuyen":"21715"
   },
   {
      "id":"2171531",
      "phuongxa":"Xã Ngọc Lập",
      "quanhuyen":"21715"
   },
   {
      "id":"2171533",
      "phuongxa":"Xã Ngọc Đồng",
      "quanhuyen":"21715"
   },
   {
      "id":"2171700",
      "phuongxa":"Không xác định",
      "quanhuyen":"21717"
   },
   {
      "id":"2171703",
      "phuongxa":"Xã Hiền Quan",
      "quanhuyen":"21717"
   },
   {
      "id":"2171705",
      "phuongxa":"Xã Vực Trường",
      "quanhuyen":"21717"
   },
   {
      "id":"2171709",
      "phuongxa":"Xã Hương Nha",
      "quanhuyen":"21717"
   },
   {
      "id":"2171711",
      "phuongxa":"Xã Xuân Quang",
      "quanhuyen":"21717"
   },
   {
      "id":"2171713",
      "phuongxa":"Xã Tam Cường",
      "quanhuyen":"21717"
   },
   {
      "id":"2171717",
      "phuongxa":"Xã Tứ Mỹ",
      "quanhuyen":"21717"
   },
   {
      "id":"2171719",
      "phuongxa":"Xã Hùng Đô",
      "quanhuyen":"21717"
   },
   {
      "id":"2171721",
      "phuongxa":"Xã Phương Thịnh",
      "quanhuyen":"21717"
   },
   {
      "id":"2171723",
      "phuongxa":"Xã Quang Húc",
      "quanhuyen":"21717"
   },
   {
      "id":"2171725",
      "phuongxa":"Xã Tề Lễ",
      "quanhuyen":"21717"
   },
   {
      "id":"2171727",
      "phuongxa":"Xã Cổ Tiết",
      "quanhuyen":"21717"
   },
   {
      "id":"2171729",
      "phuongxa":"Xã Hương Nộn",
      "quanhuyen":"21717"
   },
   {
      "id":"2171731",
      "phuongxa":"Xã Dị Nậu",
      "quanhuyen":"21717"
   },
   {
      "id":"2171733",
      "phuongxa":"Xã Thọ Văn",
      "quanhuyen":"21717"
   },
   {
      "id":"2171737",
      "phuongxa":"Xã Thượng Nông",
      "quanhuyen":"21717"
   },
   {
      "id":"2171739",
      "phuongxa":"Xã Hồng Đà",
      "quanhuyen":"21717"
   },
   {
      "id":"2171801",
      "phuongxa":"Xã Xuân Lộc",
      "quanhuyen":"21718"
   },
   {
      "id":"2171803",
      "phuongxa":"Xã Đào Xá",
      "quanhuyen":"21718"
   },
   {
      "id":"2171805",
      "phuongxa":"Xã Thạch Đồng",
      "quanhuyen":"21718"
   },
   {
      "id":"2171807",
      "phuongxa":"Xã Tân Phương",
      "quanhuyen":"21718"
   },
   {
      "id":"2171809",
      "phuongxa":"Xã La Phù",
      "quanhuyen":"21718"
   },
   {
      "id":"2171811",
      "phuongxa":"Xã Bảo Yên",
      "quanhuyen":"21718"
   },
   {
      "id":"2171813",
      "phuongxa":"Xã Sơn Thủy",
      "quanhuyen":"21718"
   },
   {
      "id":"2171815",
      "phuongxa":"Xã Đoan Hạ",
      "quanhuyen":"21718"
   },
   {
      "id":"2171817",
      "phuongxa":"Xã Hoàng Xá",
      "quanhuyen":"21718"
   },
   {
      "id":"2171819",
      "phuongxa":"Xã Đồng Luận",
      "quanhuyen":"21718"
   },
   {
      "id":"2171821",
      "phuongxa":"Xã Trung Thịnh",
      "quanhuyen":"21718"
   },
   {
      "id":"2171823",
      "phuongxa":"Xã Trung Nghĩa",
      "quanhuyen":"21718"
   },
   {
      "id":"2171825",
      "phuongxa":"Xã Phượng Mao",
      "quanhuyen":"21718"
   },
   {
      "id":"2171827",
      "phuongxa":"Xã Yến Mao",
      "quanhuyen":"21718"
   },
   {
      "id":"2171829",
      "phuongxa":"Xã Tu Vũ",
      "quanhuyen":"21718"
   },
   {
      "id":"2171900",
      "phuongxa":"Không xác định",
      "quanhuyen":"21719"
   },
   {
      "id":"2171901",
      "phuongxa":"Thị trấn Thanh Sơn",
      "quanhuyen":"21719"
   },
   {
      "id":"2171905",
      "phuongxa":"Xã Thục Luyện",
      "quanhuyen":"21719"
   },
   {
      "id":"2171907",
      "phuongxa":"Xã Giáp Lai",
      "quanhuyen":"21719"
   },
   {
      "id":"2171909",
      "phuongxa":"Xã Thạch Khoán",
      "quanhuyen":"21719"
   },
   {
      "id":"2171911",
      "phuongxa":"Xã Địch Quả",
      "quanhuyen":"21719"
   },
   {
      "id":"2171913",
      "phuongxa":"Xã Cự Thắng",
      "quanhuyen":"21719"
   },
   {
      "id":"2171915",
      "phuongxa":"Xã Tất Thắng",
      "quanhuyen":"21719"
   },
   {
      "id":"2171917",
      "phuongxa":"Xã Cự Đồng",
      "quanhuyen":"21719"
   },
   {
      "id":"2171919",
      "phuongxa":"Xã Thắng Sơn",
      "quanhuyen":"21719"
   },
   {
      "id":"2171921",
      "phuongxa":"Xã Hương Cần",
      "quanhuyen":"21719"
   },
   {
      "id":"2171923",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"21719"
   },
   {
      "id":"2171925",
      "phuongxa":"Xã Yên Lương",
      "quanhuyen":"21719"
   },
   {
      "id":"2171927",
      "phuongxa":"Xã Yên Lãng",
      "quanhuyen":"21719"
   },
   {
      "id":"2171929",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"21719"
   },
   {
      "id":"2171931",
      "phuongxa":"Xã Lương Nha",
      "quanhuyen":"21719"
   },
   {
      "id":"2171933",
      "phuongxa":"Xã Tinh Nhuệ",
      "quanhuyen":"21719"
   },
   {
      "id":"2171935",
      "phuongxa":"Xã Tân Minh",
      "quanhuyen":"21719"
   },
   {
      "id":"2171937",
      "phuongxa":"Xã Võ Miếu",
      "quanhuyen":"21719"
   },
   {
      "id":"2171939",
      "phuongxa":"Xã Văn Miếu",
      "quanhuyen":"21719"
   },
   {
      "id":"2171941",
      "phuongxa":"Xã Tam Thanh",
      "quanhuyen":"21719"
   },
   {
      "id":"2171945",
      "phuongxa":"Xã Văn Luông",
      "quanhuyen":"21719"
   },
   {
      "id":"2171947",
      "phuongxa":"Xã Long Cốc",
      "quanhuyen":"21719"
   },
   {
      "id":"2171949",
      "phuongxa":"Xã Khả Cửu",
      "quanhuyen":"21719"
   },
   {
      "id":"2171951",
      "phuongxa":"Xã Đông Cửu",
      "quanhuyen":"21719"
   },
   {
      "id":"2171953",
      "phuongxa":"Xã Thượng Cửu",
      "quanhuyen":"21719"
   },
   {
      "id":"2171955",
      "phuongxa":"Xã Minh Đài",
      "quanhuyen":"21719"
   },
   {
      "id":"2171957",
      "phuongxa":"Xã Xuân Đài",
      "quanhuyen":"21719"
   },
   {
      "id":"2171959",
      "phuongxa":"Xã Kim Thượng",
      "quanhuyen":"21719"
   },
   {
      "id":"2171961",
      "phuongxa":"Xã Xuân Sơn",
      "quanhuyen":"21719"
   },
   {
      "id":"2171963",
      "phuongxa":"Xã Mỹ Thuận",
      "quanhuyen":"21719"
   },
   {
      "id":"2171967",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"21719"
   },
   {
      "id":"2171969",
      "phuongxa":"Xã Thạch Kiệt",
      "quanhuyen":"21719"
   },
   {
      "id":"2171971",
      "phuongxa":"Xã Kiệt Sơn",
      "quanhuyen":"21719"
   },
   {
      "id":"2171973",
      "phuongxa":"Xã Tân Sơn",
      "quanhuyen":"21719"
   },
   {
      "id":"2171975",
      "phuongxa":"Xã Lai Đồng",
      "quanhuyen":"21719"
   },
   {
      "id":"2171979",
      "phuongxa":"Xã Thu Cúc",
      "quanhuyen":"21719"
   },
   {
      "id":"2172100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21721"
   },
   {
      "id":"2172101",
      "phuongxa":"Thị trấn Sông Thao",
      "quanhuyen":"21721"
   },
   {
      "id":"2172103",
      "phuongxa":"Xã Tiên Lương",
      "quanhuyen":"21721"
   },
   {
      "id":"2172105",
      "phuongxa":"Xã Tuy Lộc",
      "quanhuyen":"21721"
   },
   {
      "id":"2172111",
      "phuongxa":"Xã Phượng Vĩ",
      "quanhuyen":"21721"
   },
   {
      "id":"2172113",
      "phuongxa":"Xã Đồng Cam",
      "quanhuyen":"21721"
   },
   {
      "id":"2172115",
      "phuongxa":"Xã Thụy Liễu",
      "quanhuyen":"21721"
   },
   {
      "id":"2172119",
      "phuongxa":"Xã Sơn Nga",
      "quanhuyen":"21721"
   },
   {
      "id":"2172121",
      "phuongxa":"Xã Sai Nga",
      "quanhuyen":"21721"
   },
   {
      "id":"2172123",
      "phuongxa":"Xã Tùng Khê",
      "quanhuyen":"21721"
   },
   {
      "id":"2172125",
      "phuongxa":"Xã Tam Sơn",
      "quanhuyen":"21721"
   },
   {
      "id":"2172127",
      "phuongxa":"Xã Văn Bán",
      "quanhuyen":"21721"
   },
   {
      "id":"2172129",
      "phuongxa":"Xã Cấp Dẫn",
      "quanhuyen":"21721"
   },
   {
      "id":"2172131",
      "phuongxa":"Xã Thanh Nga",
      "quanhuyen":"21721"
   },
   {
      "id":"2172133",
      "phuongxa":"Xã Xương Thịnh",
      "quanhuyen":"21721"
   },
   {
      "id":"2172135",
      "phuongxa":"Xã Phú Khê",
      "quanhuyen":"21721"
   },
   {
      "id":"2172137",
      "phuongxa":"Xã Sơn Tình",
      "quanhuyen":"21721"
   },
   {
      "id":"2172139",
      "phuongxa":"Xã Yên Tập",
      "quanhuyen":"21721"
   },
   {
      "id":"2172141",
      "phuongxa":"Xã Hương Lung",
      "quanhuyen":"21721"
   },
   {
      "id":"2172143",
      "phuongxa":"Xã Tạ Xá",
      "quanhuyen":"21721"
   },
   {
      "id":"2172145",
      "phuongxa":"Xã Phú Lạc",
      "quanhuyen":"21721"
   },
   {
      "id":"2172147",
      "phuongxa":"Xã Tình Cương",
      "quanhuyen":"21721"
   },
   {
      "id":"2172149",
      "phuongxa":"Xã Chương Xá",
      "quanhuyen":"21721"
   },
   {
      "id":"2172151",
      "phuongxa":"Xã Hiền Đa",
      "quanhuyen":"21721"
   },
   {
      "id":"2172153",
      "phuongxa":"Xã Văn Khúc",
      "quanhuyen":"21721"
   },
   {
      "id":"2172155",
      "phuongxa":"Xã Yên Dưỡng",
      "quanhuyen":"21721"
   },
   {
      "id":"2172159",
      "phuongxa":"Xã Điêu Lương",
      "quanhuyen":"21721"
   },
   {
      "id":"2172161",
      "phuongxa":"Xã Đồng Lương",
      "quanhuyen":"21721"
   },
   {
      "id":"2210000",
      "phuongxa":"Không xác định",
      "quanhuyen":"22100"
   },
   {
      "id":"2210100",
      "phuongxa":"Không xác định",
      "quanhuyen":"22101"
   },
   {
      "id":"2210101",
      "phuongxa":"Phường Trần Phú",
      "quanhuyen":"22101"
   },
   {
      "id":"2210103",
      "phuongxa":"Phường Trần Nguyên Hãn",
      "quanhuyen":"22101"
   },
   {
      "id":"2210105",
      "phuongxa":"Phường Ngô Quyền",
      "quanhuyen":"22101"
   },
   {
      "id":"2210107",
      "phuongxa":"Phường Mỹ Độ",
      "quanhuyen":"22101"
   },
   {
      "id":"2210109",
      "phuongxa":"Phường Lê Lợi",
      "quanhuyen":"22101"
   },
   {
      "id":"2210111",
      "phuongxa":"Xã Song Mai",
      "quanhuyen":"22101"
   },
   {
      "id":"2210113",
      "phuongxa":"Xã Thọ Xương",
      "quanhuyen":"22101"
   },
   {
      "id":"2210115",
      "phuongxa":"Xã Đa Mai",
      "quanhuyen":"22101"
   },
   {
      "id":"2210117",
      "phuongxa":"Xã Dĩnh Kế",
      "quanhuyen":"22101"
   },
   {
      "id":"2210119",
      "phuongxa":"Phường Thọ Xương",
      "quanhuyen":"22101"
   },
   {
      "id":"2210121",
      "phuongxa":"Phường Hoàng Văn Thụ",
      "quanhuyen":"22101"
   },
   {
      "id":"2210300",
      "phuongxa":"Không xác định",
      "quanhuyen":"22103"
   },
   {
      "id":"2210301",
      "phuongxa":"Thị trấn Câu Gồ",
      "quanhuyen":"22103"
   },
   {
      "id":"2210303",
      "phuongxa":"Thị trấn Bố Hạ",
      "quanhuyen":"22103"
   },
   {
      "id":"2210307",
      "phuongxa":"Xã Phồn Xương",
      "quanhuyen":"22103"
   },
   {
      "id":"2210309",
      "phuongxa":"Xã Xuân Lương",
      "quanhuyen":"22103"
   },
   {
      "id":"2210311",
      "phuongxa":"Xã Canh Nậu",
      "quanhuyen":"22103"
   },
   {
      "id":"2210313",
      "phuongxa":"Xã Đồng Vương",
      "quanhuyen":"22103"
   },
   {
      "id":"2210315",
      "phuongxa":"Xã Tam Tiến",
      "quanhuyen":"22103"
   },
   {
      "id":"2210317",
      "phuongxa":"Xã Tam Hiệp",
      "quanhuyen":"22103"
   },
   {
      "id":"2210319",
      "phuongxa":"Xã Tiến Thắng",
      "quanhuyen":"22103"
   },
   {
      "id":"2210321",
      "phuongxa":"Xã Tân Hiệp",
      "quanhuyen":"22103"
   },
   {
      "id":"2210323",
      "phuongxa":"Xã Đồng Kỳ",
      "quanhuyen":"22103"
   },
   {
      "id":"2210325",
      "phuongxa":"Xã Hồng Kỳ",
      "quanhuyen":"22103"
   },
   {
      "id":"2210327",
      "phuongxa":"Xã Đồng Hưu",
      "quanhuyen":"22103"
   },
   {
      "id":"2210329",
      "phuongxa":"Xã An Thương",
      "quanhuyen":"22103"
   },
   {
      "id":"2210331",
      "phuongxa":"Xã Đồng Lạc",
      "quanhuyen":"22103"
   },
   {
      "id":"2210333",
      "phuongxa":"Xã Hương Vĩ",
      "quanhuyen":"22103"
   },
   {
      "id":"2210335",
      "phuongxa":"Xã Đông Sơn",
      "quanhuyen":"22103"
   },
   {
      "id":"2210337",
      "phuongxa":"Xã Tân Sỏi",
      "quanhuyen":"22103"
   },
   {
      "id":"2210339",
      "phuongxa":"Xã Bố Hạ",
      "quanhuyen":"22103"
   },
   {
      "id":"2210341",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"22103"
   },
   {
      "id":"2210500",
      "phuongxa":"Không xác định",
      "quanhuyen":"22105"
   },
   {
      "id":"2210503",
      "phuongxa":"Xã Cao Thượng",
      "quanhuyen":"22105"
   },
   {
      "id":"2210505",
      "phuongxa":"Xã Lan Giới",
      "quanhuyen":"22105"
   },
   {
      "id":"2210507",
      "phuongxa":"Xã Nhã Nam",
      "quanhuyen":"22105"
   },
   {
      "id":"2210509",
      "phuongxa":"Xã Phúc Sơn",
      "quanhuyen":"22105"
   },
   {
      "id":"2210511",
      "phuongxa":"Xã Đại Hóa",
      "quanhuyen":"22105"
   },
   {
      "id":"2210513",
      "phuongxa":"Xã Quang Tiến",
      "quanhuyen":"22105"
   },
   {
      "id":"2210515",
      "phuongxa":"Xã Tân Trung",
      "quanhuyen":"22105"
   },
   {
      "id":"2210521",
      "phuongxa":"Xã Phúc Hòa",
      "quanhuyen":"22105"
   },
   {
      "id":"2210523",
      "phuongxa":"Xã Lam Cốt",
      "quanhuyen":"22105"
   },
   {
      "id":"2210525",
      "phuongxa":"Xã Hợp Đức",
      "quanhuyen":"22105"
   },
   {
      "id":"2210527",
      "phuongxa":"Xã Song Vân",
      "quanhuyen":"22105"
   },
   {
      "id":"2210529",
      "phuongxa":"Xã Ngọc Châu",
      "quanhuyen":"22105"
   },
   {
      "id":"2210533",
      "phuongxa":"Xã Cao Xá",
      "quanhuyen":"22105"
   },
   {
      "id":"2210535",
      "phuongxa":"Xã Việt Lập",
      "quanhuyen":"22105"
   },
   {
      "id":"2210537",
      "phuongxa":"Xã Liên Chung",
      "quanhuyen":"22105"
   },
   {
      "id":"2210539",
      "phuongxa":"Xã Ngọc Vân",
      "quanhuyen":"22105"
   },
   {
      "id":"2210541",
      "phuongxa":"Xã Ngọc Thiện",
      "quanhuyen":"22105"
   },
   {
      "id":"2210545",
      "phuongxa":"Xã Quế Nham",
      "quanhuyen":"22105"
   },
   {
      "id":"2210700",
      "phuongxa":"Không xác định",
      "quanhuyen":"22107"
   },
   {
      "id":"2210701",
      "phuongxa":"Thị trấn Chũ",
      "quanhuyen":"22107"
   },
   {
      "id":"2210705",
      "phuongxa":"Xã Tân Sơn",
      "quanhuyen":"22107"
   },
   {
      "id":"2210707",
      "phuongxa":"Xã Xa Lý",
      "quanhuyen":"22107"
   },
   {
      "id":"2210709",
      "phuongxa":"Xã Sơn Hải",
      "quanhuyen":"22107"
   },
   {
      "id":"2210711",
      "phuongxa":"Xã Hộ Đáp",
      "quanhuyen":"22107"
   },
   {
      "id":"2210713",
      "phuongxa":"Xã Phong Minh",
      "quanhuyen":"22107"
   },
   {
      "id":"2210715",
      "phuongxa":"Xã Phong Vân",
      "quanhuyen":"22107"
   },
   {
      "id":"2210717",
      "phuongxa":"Xã Kiên Lao",
      "quanhuyen":"22107"
   },
   {
      "id":"2210719",
      "phuongxa":"Xã Kiên Thành",
      "quanhuyen":"22107"
   },
   {
      "id":"2210721",
      "phuongxa":"Xã Thanh Hải",
      "quanhuyen":"22107"
   },
   {
      "id":"2210723",
      "phuongxa":"Xã Biên Sơn",
      "quanhuyen":"22107"
   },
   {
      "id":"2210725",
      "phuongxa":"Xã Tân Hoa",
      "quanhuyen":"22107"
   },
   {
      "id":"2210727",
      "phuongxa":"Xã Quý Sơn",
      "quanhuyen":"22107"
   },
   {
      "id":"2210729",
      "phuongxa":"Xã Trù Hựu",
      "quanhuyen":"22107"
   },
   {
      "id":"2210731",
      "phuongxa":"Xã Hồng Giang",
      "quanhuyen":"22107"
   },
   {
      "id":"2210733",
      "phuongxa":"Xã Giáp Sơn",
      "quanhuyen":"22107"
   },
   {
      "id":"2210735",
      "phuongxa":"Xã Phì Điền",
      "quanhuyen":"22107"
   },
   {
      "id":"2210737",
      "phuongxa":"Xã Biển Động",
      "quanhuyen":"22107"
   },
   {
      "id":"2210739",
      "phuongxa":"Xã Nghĩa Hồ",
      "quanhuyen":"22107"
   },
   {
      "id":"2210741",
      "phuongxa":"Xã Tân Quang",
      "quanhuyen":"22107"
   },
   {
      "id":"2210745",
      "phuongxa":"Xã Phượng Sơn",
      "quanhuyen":"22107"
   },
   {
      "id":"2210747",
      "phuongxa":"Xã Nam Dương",
      "quanhuyen":"22107"
   },
   {
      "id":"2210749",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"22107"
   },
   {
      "id":"2210751",
      "phuongxa":"Xã Phú Nhuận",
      "quanhuyen":"22107"
   },
   {
      "id":"2210753",
      "phuongxa":"Xã Mỹ An",
      "quanhuyen":"22107"
   },
   {
      "id":"2210755",
      "phuongxa":"Xã Đèo Gia",
      "quanhuyen":"22107"
   },
   {
      "id":"2210757",
      "phuongxa":"Xã Tân Mộc",
      "quanhuyen":"22107"
   },
   {
      "id":"2210759",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"22107"
   },
   {
      "id":"2210900",
      "phuongxa":"Không xác định",
      "quanhuyen":"22109"
   },
   {
      "id":"2210901",
      "phuongxa":"Thị trấn Thắng",
      "quanhuyen":"22109"
   },
   {
      "id":"2210903",
      "phuongxa":"Xã Đồng Tân",
      "quanhuyen":"22109"
   },
   {
      "id":"2210905",
      "phuongxa":"Xã Thanh Vân",
      "quanhuyen":"22109"
   },
   {
      "id":"2210907",
      "phuongxa":"Xã Hoàng Lương",
      "quanhuyen":"22109"
   },
   {
      "id":"2210909",
      "phuongxa":"Xã Hoàng Vân",
      "quanhuyen":"22109"
   },
   {
      "id":"2210911",
      "phuongxa":"Xã Hoàng An",
      "quanhuyen":"22109"
   },
   {
      "id":"2210913",
      "phuongxa":"Xã Hoàng Thanh",
      "quanhuyen":"22109"
   },
   {
      "id":"2210915",
      "phuongxa":"Xã Thái Sơn",
      "quanhuyen":"22109"
   },
   {
      "id":"2210917",
      "phuongxa":"Xã Hòa Sơn",
      "quanhuyen":"22109"
   },
   {
      "id":"2210919",
      "phuongxa":"Xã Đức Thắng",
      "quanhuyen":"22109"
   },
   {
      "id":"2210923",
      "phuongxa":"Xã Quang Minh",
      "quanhuyen":"22109"
   },
   {
      "id":"2210925",
      "phuongxa":"Xã Hùng Sơn",
      "quanhuyen":"22109"
   },
   {
      "id":"2210927",
      "phuongxa":"Xã Lương Phong",
      "quanhuyen":"22109"
   },
   {
      "id":"2210929",
      "phuongxa":"Xã Đại Thành",
      "quanhuyen":"22109"
   },
   {
      "id":"2210931",
      "phuongxa":"Xã Thường Thắng",
      "quanhuyen":"22109"
   },
   {
      "id":"2210933",
      "phuongxa":"Xã Hợp Thịnh",
      "quanhuyen":"22109"
   },
   {
      "id":"2210935",
      "phuongxa":"Xã Mai Trung",
      "quanhuyen":"22109"
   },
   {
      "id":"2210937",
      "phuongxa":"Xã Danh Thắng",
      "quanhuyen":"22109"
   },
   {
      "id":"2210939",
      "phuongxa":"Xã Đoan Bái",
      "quanhuyen":"22109"
   },
   {
      "id":"2210941",
      "phuongxa":"Xã Bắc Lý",
      "quanhuyen":"22109"
   },
   {
      "id":"2210943",
      "phuongxa":"Xã Xuân Cẩm",
      "quanhuyen":"22109"
   },
   {
      "id":"2210947",
      "phuongxa":"Xã Hương Lâm",
      "quanhuyen":"22109"
   },
   {
      "id":"2210949",
      "phuongxa":"Xã Mai Đình",
      "quanhuyen":"22109"
   },
   {
      "id":"2210951",
      "phuongxa":"Xã Châu Minh",
      "quanhuyen":"22109"
   },
   {
      "id":"2211100",
      "phuongxa":"Không xác định",
      "quanhuyen":"22111"
   },
   {
      "id":"2211103",
      "phuongxa":"Thị trấn Kép",
      "quanhuyen":"22111"
   },
   {
      "id":"2211105",
      "phuongxa":"Thị trấn NT Bố Hạ",
      "quanhuyen":"22111"
   },
   {
      "id":"2211107",
      "phuongxa":"Xã Yên Mỹ",
      "quanhuyen":"22111"
   },
   {
      "id":"2211109",
      "phuongxa":"Xã Nghĩa Hưng",
      "quanhuyen":"22111"
   },
   {
      "id":"2211115",
      "phuongxa":"Xã Đào Mỹ",
      "quanhuyen":"22111"
   },
   {
      "id":"2211117",
      "phuongxa":"Xã An Hà",
      "quanhuyen":"22111"
   },
   {
      "id":"2211119",
      "phuongxa":"Xã Tân Thịnh",
      "quanhuyen":"22111"
   },
   {
      "id":"2211121",
      "phuongxa":"Xã Mỹ Hà",
      "quanhuyen":"22111"
   },
   {
      "id":"2211123",
      "phuongxa":"Xã Tiên Lục",
      "quanhuyen":"22111"
   },
   {
      "id":"2211125",
      "phuongxa":"Xã Hương Sơn",
      "quanhuyen":"22111"
   },
   {
      "id":"2211129",
      "phuongxa":"Xã Tân Thanh",
      "quanhuyen":"22111"
   },
   {
      "id":"2211131",
      "phuongxa":"Xã Hương Lạc",
      "quanhuyen":"22111"
   },
   {
      "id":"2211133",
      "phuongxa":"Xã Mỹ Thái",
      "quanhuyen":"22111"
   },
   {
      "id":"2211135",
      "phuongxa":"Xã Xương Lâm",
      "quanhuyen":"22111"
   },
   {
      "id":"2211137",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"22111"
   },
   {
      "id":"2211139",
      "phuongxa":"Xã Phi Mô",
      "quanhuyen":"22111"
   },
   {
      "id":"2211141",
      "phuongxa":"Xã Xuân Hương",
      "quanhuyen":"22111"
   },
   {
      "id":"2211143",
      "phuongxa":"Xã Tân Dĩnh",
      "quanhuyen":"22111"
   },
   {
      "id":"2211145",
      "phuongxa":"Xã Thái Đào",
      "quanhuyen":"22111"
   },
   {
      "id":"2211147",
      "phuongxa":"Xã Đại Lâm",
      "quanhuyen":"22111"
   },
   {
      "id":"2211149",
      "phuongxa":"Xã Dĩnh Trì",
      "quanhuyen":"22111"
   },
   {
      "id":"2211300",
      "phuongxa":"Không xác định",
      "quanhuyen":"22113"
   },
   {
      "id":"2211301",
      "phuongxa":"Thị trấn An Châu",
      "quanhuyen":"22113"
   },
   {
      "id":"2211303",
      "phuongxa":"Xã Quế Sơn",
      "quanhuyen":"22113"
   },
   {
      "id":"2211305",
      "phuongxa":"Xã Chiên Sơn",
      "quanhuyen":"22113"
   },
   {
      "id":"2211309",
      "phuongxa":"Xã Vân Sơn",
      "quanhuyen":"22113"
   },
   {
      "id":"2211311",
      "phuongxa":"Xã Hữu Sản",
      "quanhuyen":"22113"
   },
   {
      "id":"2211313",
      "phuongxa":"Xã Cẩm Đàn",
      "quanhuyen":"22113"
   },
   {
      "id":"2211315",
      "phuongxa":"Xã Vĩnh Khương",
      "quanhuyen":"22113"
   },
   {
      "id":"2211317",
      "phuongxa":"Xã Yên Định",
      "quanhuyen":"22113"
   },
   {
      "id":"2211319",
      "phuongxa":"Xã An Lập",
      "quanhuyen":"22113"
   },
   {
      "id":"2211321",
      "phuongxa":"Xã Lệ Viễn",
      "quanhuyen":"22113"
   },
   {
      "id":"2211323",
      "phuongxa":"Xã An Lạc",
      "quanhuyen":"22113"
   },
   {
      "id":"2211325",
      "phuongxa":"Xã An Bá",
      "quanhuyen":"22113"
   },
   {
      "id":"2170105",
      "phuongxa":"Phường Nông Trang",
      "quanhuyen":"21701"
   },
   {
      "id":"2170107",
      "phuongxa":"Phường Gia Cẩm",
      "quanhuyen":"21701"
   },
   {
      "id":"2170109",
      "phuongxa":"Phường Tân Dân",
      "quanhuyen":"21701"
   },
   {
      "id":"2170111",
      "phuongxa":"Phường Thọ Sơn",
      "quanhuyen":"21701"
   },
   {
      "id":"2170113",
      "phuongxa":"Phường Thanh Miếu",
      "quanhuyen":"21701"
   },
   {
      "id":"2170115",
      "phuongxa":"Phường Bạch Hạc",
      "quanhuyen":"21701"
   },
   {
      "id":"2170117",
      "phuongxa":"Xã Phượng Lâu",
      "quanhuyen":"21701"
   },
   {
      "id":"2170119",
      "phuongxa":"Xã Vân Phú",
      "quanhuyen":"21701"
   },
   {
      "id":"2170121",
      "phuongxa":"Xã Dữu Lâu",
      "quanhuyen":"21701"
   },
   {
      "id":"2170123",
      "phuongxa":"Xã Thụy Vân",
      "quanhuyen":"21701"
   },
   {
      "id":"2170125",
      "phuongxa":"Xã Minh Phương",
      "quanhuyen":"21701"
   },
   {
      "id":"2170129",
      "phuongxa":"Xã Trưng Vương",
      "quanhuyen":"21701"
   },
   {
      "id":"2170131",
      "phuongxa":"Xã Sông Lô",
      "quanhuyen":"21701"
   },
   {
      "id":"2170300",
      "phuongxa":"Không xác định",
      "quanhuyen":"21703"
   },
   {
      "id":"2170301",
      "phuongxa":"Phường Âu Cơ",
      "quanhuyen":"21703"
   },
   {
      "id":"2170303",
      "phuongxa":"Phường Phong Châu",
      "quanhuyen":"21703"
   },
   {
      "id":"2170305",
      "phuongxa":"Phường Hùng Vương",
      "quanhuyen":"21703"
   },
   {
      "id":"2170307",
      "phuongxa":"Xã Hà Lộc",
      "quanhuyen":"21703"
   },
   {
      "id":"2170309",
      "phuongxa":"Xã Văn Lung",
      "quanhuyen":"21703"
   },
   {
      "id":"2170311",
      "phuongxa":"Xã Trường Thịnh",
      "quanhuyen":"21703"
   },
   {
      "id":"2170313",
      "phuongxa":"Xã Thanh Minh",
      "quanhuyen":"21703"
   },
   {
      "id":"2170500",
      "phuongxa":"Không xác định",
      "quanhuyen":"21705"
   },
   {
      "id":"2170501",
      "phuongxa":"Thị trấn Đoan Hùng",
      "quanhuyen":"21705"
   },
   {
      "id":"2170503",
      "phuongxa":"Xã Đông Khê",
      "quanhuyen":"21705"
   },
   {
      "id":"2170505",
      "phuongxa":"Xã Nghinh Xuyên",
      "quanhuyen":"21705"
   },
   {
      "id":"2170507",
      "phuongxa":"Xã Hùng Quan",
      "quanhuyen":"21705"
   },
   {
      "id":"2170509",
      "phuongxa":"Xã Bằng Luân",
      "quanhuyen":"21705"
   },
   {
      "id":"2170511",
      "phuongxa":"Xã Vân Du",
      "quanhuyen":"21705"
   },
   {
      "id":"2170513",
      "phuongxa":"Xã Phương Trung",
      "quanhuyen":"21705"
   },
   {
      "id":"2170515",
      "phuongxa":"Xã Quế Lâm",
      "quanhuyen":"21705"
   },
   {
      "id":"2170517",
      "phuongxa":"Xã Minh Lương",
      "quanhuyen":"21705"
   },
   {
      "id":"2170521",
      "phuongxa":"Xã Hữu Đô",
      "quanhuyen":"21705"
   },
   {
      "id":"2170523",
      "phuongxa":"Xã Ngọc Quan",
      "quanhuyen":"21705"
   },
   {
      "id":"2170527",
      "phuongxa":"Xã Phúc Lai",
      "quanhuyen":"21705"
   },
   {
      "id":"2170529",
      "phuongxa":"Xã Đại Nghĩa",
      "quanhuyen":"21705"
   },
   {
      "id":"2170531",
      "phuongxa":"Xã Phú Thứ",
      "quanhuyen":"21705"
   },
   {
      "id":"2170533",
      "phuongxa":"Xã Sóc Đăng",
      "quanhuyen":"21705"
   },
   {
      "id":"2170535",
      "phuongxa":"Xã Hùng Long",
      "quanhuyen":"21705"
   },
   {
      "id":"2170539",
      "phuongxa":"Xã Vụ Quang",
      "quanhuyen":"21705"
   },
   {
      "id":"2170541",
      "phuongxa":"Xã Vân Đồn",
      "quanhuyen":"21705"
   },
   {
      "id":"2170543",
      "phuongxa":"Xã Tiêu Sơn",
      "quanhuyen":"21705"
   },
   {
      "id":"2170545",
      "phuongxa":"Xã Minh Tiến",
      "quanhuyen":"21705"
   },
   {
      "id":"2170547",
      "phuongxa":"Xã Chân Mộng",
      "quanhuyen":"21705"
   },
   {
      "id":"2170551",
      "phuongxa":"Xã Phong Phú",
      "quanhuyen":"21705"
   },
   {
      "id":"2170553",
      "phuongxa":"Xã Chí Đám",
      "quanhuyen":"21705"
   },
   {
      "id":"2170700",
      "phuongxa":"Không xác định",
      "quanhuyen":"21707"
   },
   {
      "id":"2170701",
      "phuongxa":"Thị trấn Hạ Hoà",
      "quanhuyen":"21707"
   },
   {
      "id":"2170703",
      "phuongxa":"Xã Đại Phạm",
      "quanhuyen":"21707"
   },
   {
      "id":"2170707",
      "phuongxa":"Xã Liên Phương",
      "quanhuyen":"21707"
   },
   {
      "id":"2170709",
      "phuongxa":"Xã Đan Hà",
      "quanhuyen":"21707"
   },
   {
      "id":"2170711",
      "phuongxa":"Xã Hà Lương",
      "quanhuyen":"21707"
   },
   {
      "id":"2170713",
      "phuongxa":"Xã Lệnh Khanh",
      "quanhuyen":"21707"
   },
   {
      "id":"2170715",
      "phuongxa":"Xã Đan Thượng",
      "quanhuyen":"21707"
   },
   {
      "id":"2170717",
      "phuongxa":"Xã Hiền Lương",
      "quanhuyen":"21707"
   },
   {
      "id":"2170719",
      "phuongxa":"Xã Quân Khê",
      "quanhuyen":"21707"
   },
   {
      "id":"2170721",
      "phuongxa":"Xã Động Lâm",
      "quanhuyen":"21707"
   },
   {
      "id":"2170723",
      "phuongxa":"Xã Phụ Khánh",
      "quanhuyen":"21707"
   },
   {
      "id":"2170725",
      "phuongxa":"Xã Lâm Lợi",
      "quanhuyen":"21707"
   },
   {
      "id":"2170727",
      "phuongxa":"Xã Gia Điền",
      "quanhuyen":"21707"
   },
   {
      "id":"2170729",
      "phuongxa":"Xã Y Sơn",
      "quanhuyen":"21707"
   },
   {
      "id":"2170731",
      "phuongxa":"Xã ấm Hạ",
      "quanhuyen":"21707"
   },
   {
      "id":"2170733",
      "phuongxa":"Xã Phương Viên",
      "quanhuyen":"21707"
   },
   {
      "id":"2170735",
      "phuongxa":"Xã Chuế Lưu",
      "quanhuyen":"21707"
   },
   {
      "id":"2170737",
      "phuongxa":"Xã Xuân áng",
      "quanhuyen":"21707"
   },
   {
      "id":"2170739",
      "phuongxa":"Xã Hương Xạ",
      "quanhuyen":"21707"
   },
   {
      "id":"2170741",
      "phuongxa":"Xã Cáo Điền",
      "quanhuyen":"21707"
   },
   {
      "id":"2170745",
      "phuongxa":"Xã Bằng Giã",
      "quanhuyen":"21707"
   },
   {
      "id":"2170747",
      "phuongxa":"Xã Vô Tranh",
      "quanhuyen":"21707"
   },
   {
      "id":"2170749",
      "phuongxa":"Xã Văn Lang",
      "quanhuyen":"21707"
   },
   {
      "id":"2170751",
      "phuongxa":"Xã Yên Kỳ",
      "quanhuyen":"21707"
   },
   {
      "id":"3012321",
      "phuongxa":"Xã Pha Mu",
      "quanhuyen":"30123"
   },
   {
      "id":"3012323",
      "phuongxa":"Xã Nà Cang",
      "quanhuyen":"30123"
   },
   {
      "id":"3012325",
      "phuongxa":"Xã Tà Hừa",
      "quanhuyen":"30123"
   },
   {
      "id":"3012327",
      "phuongxa":"Xã Mường Kim",
      "quanhuyen":"30123"
   },
   {
      "id":"3012329",
      "phuongxa":"Xã Tà Gia",
      "quanhuyen":"30123"
   },
   {
      "id":"3012331",
      "phuongxa":"Xã Khoen On",
      "quanhuyen":"30123"
   },
   {
      "id":"3030000",
      "phuongxa":"Không xác định",
      "quanhuyen":"30300"
   },
   {
      "id":"3030100",
      "phuongxa":"Không xác định",
      "quanhuyen":"30301"
   },
   {
      "id":"3030101",
      "phuongxa":"Phường Chiềng Lề",
      "quanhuyen":"30301"
   },
   {
      "id":"3030103",
      "phuongxa":"Phường Quyết Thắng",
      "quanhuyen":"30301"
   },
   {
      "id":"3030105",
      "phuongxa":"Xã Chiềng Đen",
      "quanhuyen":"30301"
   },
   {
      "id":"3030107",
      "phuongxa":"Xã Chiềng Xôm",
      "quanhuyen":"30301"
   },
   {
      "id":"3030109",
      "phuongxa":"Xã Chiềng An",
      "quanhuyen":"30301"
   },
   {
      "id":"3030111",
      "phuongxa":"Xã Chiềng Ngần",
      "quanhuyen":"30301"
   },
   {
      "id":"3030113",
      "phuongxa":"Xã Chiềng Cọ",
      "quanhuyen":"30301"
   },
   {
      "id":"3030117",
      "phuongxa":"Xã Hua La",
      "quanhuyen":"30301"
   },
   {
      "id":"3030119",
      "phuongxa":"Xã Chiềng Sinh",
      "quanhuyen":"30301"
   },
   {
      "id":"3030121",
      "phuongxa":"Phường Tô Hiệu",
      "quanhuyen":"30301"
   },
   {
      "id":"3030123",
      "phuongxa":"Phường Quyết Tâm",
      "quanhuyen":"30301"
   },
   {
      "id":"3030300",
      "phuongxa":"Không xác định",
      "quanhuyen":"30303"
   },
   {
      "id":"3030301",
      "phuongxa":"Xã Mường Chiên",
      "quanhuyen":"30303"
   },
   {
      "id":"3030303",
      "phuongxa":"Xã Cà Nàng",
      "quanhuyen":"30303"
   },
   {
      "id":"3030305",
      "phuongxa":"Xã Chiềng Khay",
      "quanhuyen":"30303"
   },
   {
      "id":"3030307",
      "phuongxa":"Xã Pha Khinh",
      "quanhuyen":"30303"
   },
   {
      "id":"3030309",
      "phuongxa":"Xã Mường Giôn",
      "quanhuyen":"30303"
   },
   {
      "id":"3030311",
      "phuongxa":"Xã Pắc Ma",
      "quanhuyen":"30303"
   },
   {
      "id":"3030313",
      "phuongxa":"Xã Chiềng Æ n",
      "quanhuyen":"30303"
   },
   {
      "id":"3030500",
      "phuongxa":"Không xác định",
      "quanhuyen":"30305"
   },
   {
      "id":"3030501",
      "phuongxa":"Xã ít Ong",
      "quanhuyen":"30305"
   },
   {
      "id":"3030503",
      "phuongxa":"Xã Nậm Giôn",
      "quanhuyen":"30305"
   },
   {
      "id":"3030505",
      "phuongxa":"Xã Chiềng Lao",
      "quanhuyen":"30305"
   },
   {
      "id":"3030507",
      "phuongxa":"Xã Hua Trai",
      "quanhuyen":"30305"
   },
   {
      "id":"3030509",
      "phuongxa":"Xã Ngọc Chiến",
      "quanhuyen":"30305"
   },
   {
      "id":"3030513",
      "phuongxa":"Xã Nậm Păm",
      "quanhuyen":"30305"
   },
   {
      "id":"3030515",
      "phuongxa":"Xã Chiềng Muôn",
      "quanhuyen":"30305"
   },
   {
      "id":"3030517",
      "phuongxa":"Xã Chiềng Ân",
      "quanhuyen":"30305"
   },
   {
      "id":"3030521",
      "phuongxa":"Xã Chiềng Công",
      "quanhuyen":"30305"
   },
   {
      "id":"3030523",
      "phuongxa":"Xã Tạ Bú",
      "quanhuyen":"30305"
   },
   {
      "id":"3030527",
      "phuongxa":"Xã Mường Bú",
      "quanhuyen":"30305"
   },
   {
      "id":"3030529",
      "phuongxa":"Xã Chiềng Hoa",
      "quanhuyen":"30305"
   },
   {
      "id":"3030531",
      "phuongxa":"Xã Mường Chùm",
      "quanhuyen":"30305"
   },
   {
      "id":"3030700",
      "phuongxa":"Không xác định",
      "quanhuyen":"30307"
   },
   {
      "id":"3030703",
      "phuongxa":"Xã Mường Giàng",
      "quanhuyen":"30307"
   },
   {
      "id":"3030705",
      "phuongxa":"Xã Chiềng Bằng",
      "quanhuyen":"30307"
   },
   {
      "id":"3030707",
      "phuongxa":"Xã Mường Sại",
      "quanhuyen":"30307"
   },
   {
      "id":"3030709",
      "phuongxa":"Xã Phỏng Lái",
      "quanhuyen":"30307"
   },
   {
      "id":"3030711",
      "phuongxa":"Xã Chiềng Khoang",
      "quanhuyen":"30307"
   },
   {
      "id":"3030713",
      "phuongxa":"Xã Liệp Muội",
      "quanhuyen":"30307"
   },
   {
      "id":"3030715",
      "phuongxa":"Xã Nậm ét",
      "quanhuyen":"30307"
   },
   {
      "id":"3030719",
      "phuongxa":"Xã Chiềng Pha",
      "quanhuyen":"30307"
   },
   {
      "id":"3030723",
      "phuongxa":"Xã Chiềng Ngàm",
      "quanhuyen":"30307"
   },
   {
      "id":"3030725",
      "phuongxa":"Xã Liệp Tè",
      "quanhuyen":"30307"
   },
   {
      "id":"3030727",
      "phuongxa":"Xã é Tòng",
      "quanhuyen":"30307"
   },
   {
      "id":"3030729",
      "phuongxa":"Xã Phỏng Lập",
      "quanhuyen":"30307"
   },
   {
      "id":"3030731",
      "phuongxa":"Xã Chiềng Sơ",
      "quanhuyen":"30307"
   },
   {
      "id":"3030733",
      "phuongxa":"Xã Chiềng Ly",
      "quanhuyen":"30307"
   },
   {
      "id":"3030735",
      "phuongxa":"Xã Nong Lay",
      "quanhuyen":"30307"
   },
   {
      "id":"3030737",
      "phuongxa":"Xã Mường Khiêng",
      "quanhuyen":"30307"
   },
   {
      "id":"3030739",
      "phuongxa":"Xã Mường Bám",
      "quanhuyen":"30307"
   },
   {
      "id":"3030741",
      "phuongxa":"Xã Long Hẹ",
      "quanhuyen":"30307"
   },
   {
      "id":"3030743",
      "phuongxa":"Xã Chiềng Bôm",
      "quanhuyen":"30307"
   },
   {
      "id":"3030745",
      "phuongxa":"Xã Thôn Mòn",
      "quanhuyen":"30307"
   },
   {
      "id":"3030747",
      "phuongxa":"Xã Tòng Lệnh",
      "quanhuyen":"30307"
   },
   {
      "id":"3030749",
      "phuongxa":"Xã Tòng Cọ",
      "quanhuyen":"30307"
   },
   {
      "id":"3030751",
      "phuongxa":"Xã Bó Mười",
      "quanhuyen":"30307"
   },
   {
      "id":"3030753",
      "phuongxa":"Xã Co Mạ",
      "quanhuyen":"30307"
   },
   {
      "id":"3030755",
      "phuongxa":"Xã Púng Tra",
      "quanhuyen":"30307"
   },
   {
      "id":"3030757",
      "phuongxa":"Xã Chiềng Pấc",
      "quanhuyen":"30307"
   },
   {
      "id":"3030759",
      "phuongxa":"Xã Nậm Lầu",
      "quanhuyen":"30307"
   },
   {
      "id":"3030761",
      "phuongxa":"Xã Bon Phặng",
      "quanhuyen":"30307"
   },
   {
      "id":"3030765",
      "phuongxa":"Xã Muội Nọi",
      "quanhuyen":"30307"
   },
   {
      "id":"3030767",
      "phuongxa":"Xã Pá Lông",
      "quanhuyen":"30307"
   },
   {
      "id":"3030769",
      "phuongxa":"Xã Bản Lầm",
      "quanhuyen":"30307"
   },
   {
      "id":"3030900",
      "phuongxa":"Không xác định",
      "quanhuyen":"30309"
   },
   {
      "id":"3030901",
      "phuongxa":"Xã Phiêng Ban",
      "quanhuyen":"30309"
   },
   {
      "id":"3030903",
      "phuongxa":"Xã Hang Chú",
      "quanhuyen":"30309"
   },
   {
      "id":"3030905",
      "phuongxa":"Xã Xín Vàng",
      "quanhuyen":"30309"
   },
   {
      "id":"3030907",
      "phuongxa":"Xã Tà Xùa",
      "quanhuyen":"30309"
   },
   {
      "id":"3030909",
      "phuongxa":"Xã Bắc Ngà",
      "quanhuyen":"30309"
   },
   {
      "id":"3030911",
      "phuongxa":"Xã Làng Chếu",
      "quanhuyen":"30309"
   },
   {
      "id":"3030913",
      "phuongxa":"Xã Chim Vàn",
      "quanhuyen":"30309"
   },
   {
      "id":"3030915",
      "phuongxa":"Xã Mường Khoa",
      "quanhuyen":"30309"
   },
   {
      "id":"3030917",
      "phuongxa":"Xã Song Pe",
      "quanhuyen":"30309"
   },
   {
      "id":"3030919",
      "phuongxa":"Xã Hồng Ngài",
      "quanhuyen":"30309"
   },
   {
      "id":"3030921",
      "phuongxa":"Xã Tạ Khoa",
      "quanhuyen":"30309"
   },
   {
      "id":"3030923",
      "phuongxa":"Xã Phiêng Kôn",
      "quanhuyen":"30309"
   },
   {
      "id":"3030925",
      "phuongxa":"Xã Chiềng Sại",
      "quanhuyen":"30309"
   },
   {
      "id":"3031100",
      "phuongxa":"Không xác định",
      "quanhuyen":"30311"
   },
   {
      "id":"3031103",
      "phuongxa":"Xã Suối Tọ",
      "quanhuyen":"30311"
   },
   {
      "id":"3031105",
      "phuongxa":"Xã Mường Thải",
      "quanhuyen":"30311"
   },
   {
      "id":"3031107",
      "phuongxa":"Xã Mường Cơi",
      "quanhuyen":"30311"
   },
   {
      "id":"3031109",
      "phuongxa":"Xã Quang Huy",
      "quanhuyen":"30311"
   },
   {
      "id":"3031111",
      "phuongxa":"Xã Huy Bắc",
      "quanhuyen":"30311"
   },
   {
      "id":"3031113",
      "phuongxa":"Xã Huy Thượng",
      "quanhuyen":"30311"
   },
   {
      "id":"3031115",
      "phuongxa":"Xã Tân Lang",
      "quanhuyen":"30311"
   },
   {
      "id":"3031117",
      "phuongxa":"Xã Gia Phù",
      "quanhuyen":"30311"
   },
   {
      "id":"3031119",
      "phuongxa":"Xã Tường Phù",
      "quanhuyen":"30311"
   },
   {
      "id":"3031121",
      "phuongxa":"Xã Huy Hạ",
      "quanhuyen":"30311"
   },
   {
      "id":"3031125",
      "phuongxa":"Xã Mường Lang",
      "quanhuyen":"30311"
   },
   {
      "id":"3031127",
      "phuongxa":"Xã Suối Bau",
      "quanhuyen":"30311"
   },
   {
      "id":"3031129",
      "phuongxa":"Xã Huy Tường",
      "quanhuyen":"30311"
   },
   {
      "id":"3031131",
      "phuongxa":"Xã Mường Do",
      "quanhuyen":"30311"
   },
   {
      "id":"3031133",
      "phuongxa":"Xã Sập Xa",
      "quanhuyen":"30311"
   },
   {
      "id":"3031137",
      "phuongxa":"Xã Tường Tiến",
      "quanhuyen":"30311"
   },
   {
      "id":"3031139",
      "phuongxa":"Xã Tường Phong",
      "quanhuyen":"30311"
   },
   {
      "id":"3031141",
      "phuongxa":"Xã Tường Hạ",
      "quanhuyen":"30311"
   },
   {
      "id":"3031143",
      "phuongxa":"Xã Kim Bon",
      "quanhuyen":"30311"
   },
   {
      "id":"3031145",
      "phuongxa":"Xã Mường Bang",
      "quanhuyen":"30311"
   },
   {
      "id":"3031147",
      "phuongxa":"Xã Đá Đỏ",
      "quanhuyen":"30311"
   },
   {
      "id":"3031151",
      "phuongxa":"Xã Nam Phong",
      "quanhuyen":"30311"
   },
   {
      "id":"3031153",
      "phuongxa":"Xã Bắc Phong",
      "quanhuyen":"30311"
   },
   {
      "id":"3031301",
      "phuongxa":"Thị trấn Hát Lót",
      "quanhuyen":"30313"
   },
   {
      "id":"3031305",
      "phuongxa":"Thị trấn NT  Chiềng Sung",
      "quanhuyen":"30313"
   },
   {
      "id":"3031307",
      "phuongxa":"Xã Mường Bằng",
      "quanhuyen":"30313"
   },
   {
      "id":"3031309",
      "phuongxa":"Xã Chiềng Chăn",
      "quanhuyen":"30313"
   },
   {
      "id":"3031311",
      "phuongxa":"Xã Mương Tranh",
      "quanhuyen":"30313"
   },
   {
      "id":"3031313",
      "phuongxa":"Xã Chiềng Ban",
      "quanhuyen":"30313"
   },
   {
      "id":"3031315",
      "phuongxa":"Xã Chiềng Mung",
      "quanhuyen":"30313"
   },
   {
      "id":"3031317",
      "phuongxa":"Xã Mường Bon",
      "quanhuyen":"30313"
   },
   {
      "id":"3031319",
      "phuongxa":"Xã Chiềng Chung",
      "quanhuyen":"30313"
   },
   {
      "id":"3031321",
      "phuongxa":"Xã Chiềng Mai",
      "quanhuyen":"30313"
   },
   {
      "id":"3031323",
      "phuongxa":"Xã Hát Lót",
      "quanhuyen":"30313"
   },
   {
      "id":"3031325",
      "phuongxa":"Xã Cò  Nòi",
      "quanhuyen":"30313"
   },
   {
      "id":"3031327",
      "phuongxa":"Xã Chiềng Nơi",
      "quanhuyen":"30313"
   },
   {
      "id":"3031329",
      "phuongxa":"Xã Chiềng Dong",
      "quanhuyen":"30313"
   },
   {
      "id":"3031331",
      "phuongxa":"Xã Chiềng Kheo",
      "quanhuyen":"30313"
   },
   {
      "id":"3031333",
      "phuongxa":"Xã Chiềng Ve",
      "quanhuyen":"30313"
   },
   {
      "id":"3031335",
      "phuongxa":"Xã Chiềng Lương",
      "quanhuyen":"30313"
   },
   {
      "id":"3031337",
      "phuongxa":"Xã Phiêng Pằn",
      "quanhuyen":"30313"
   },
   {
      "id":"3031339",
      "phuongxa":"Xã Tà Hộc",
      "quanhuyen":"30313"
   },
   {
      "id":"3031341",
      "phuongxa":"Xã Phiêng Cằm",
      "quanhuyen":"30313"
   },
   {
      "id":"3031343",
      "phuongxa":"Xã Nà ớt",
      "quanhuyen":"30313"
   },
   {
      "id":"3031500",
      "phuongxa":"Không xác định",
      "quanhuyen":"30315"
   },
   {
      "id":"3031501",
      "phuongxa":"Thị trấn Sông Mã",
      "quanhuyen":"30315"
   },
   {
      "id":"3031503",
      "phuongxa":"Xã Bó Sinh",
      "quanhuyen":"30315"
   },
   {
      "id":"3031505",
      "phuongxa":"Xã Pú Pẩu",
      "quanhuyen":"30315"
   },
   {
      "id":"3031507",
      "phuongxa":"Xã Chiềng Phung",
      "quanhuyen":"30315"
   },
   {
      "id":"3031509",
      "phuongxa":"Xã Chiềng En",
      "quanhuyen":"30315"
   },
   {
      "id":"2230809",
      "phuongxa":"Xã Tương Giang",
      "quanhuyen":"22308"
   },
   {
      "id":"2230811",
      "phuongxa":"Xã Đồng Nguyên",
      "quanhuyen":"22308"
   },
   {
      "id":"2230813",
      "phuongxa":"Xã Đồng Quang",
      "quanhuyen":"22308"
   },
   {
      "id":"2230815",
      "phuongxa":"Xã Tân Hồng",
      "quanhuyen":"22308"
   },
   {
      "id":"2230817",
      "phuongxa":"Xã Châu Khê",
      "quanhuyen":"22308"
   },
   {
      "id":"2230819",
      "phuongxa":"Xã Đình Bảng",
      "quanhuyen":"22308"
   },
   {
      "id":"2230821",
      "phuongxa":"Xã Phù Chẩn",
      "quanhuyen":"22308"
   },
   {
      "id":"2230900",
      "phuongxa":"Không xác định",
      "quanhuyen":"22309"
   },
   {
      "id":"2230901",
      "phuongxa":"Thị trấn Hồ",
      "quanhuyen":"22309"
   },
   {
      "id":"2230903",
      "phuongxa":"Xã Hoài Thượng",
      "quanhuyen":"22309"
   },
   {
      "id":"2230905",
      "phuongxa":"Xã Đại Đồng Thành",
      "quanhuyen":"22309"
   },
   {
      "id":"2230907",
      "phuongxa":"Xã Song Hồ",
      "quanhuyen":"22309"
   },
   {
      "id":"2230909",
      "phuongxa":"Xã Mão Điền",
      "quanhuyen":"22309"
   },
   {
      "id":"2230911",
      "phuongxa":"Xã Đình Tổ",
      "quanhuyen":"22309"
   },
   {
      "id":"2230913",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"22309"
   },
   {
      "id":"2230915",
      "phuongxa":"Xã Trí Quả",
      "quanhuyen":"22309"
   },
   {
      "id":"2230917",
      "phuongxa":"Xã Gia Đông",
      "quanhuyen":"22309"
   },
   {
      "id":"2230919",
      "phuongxa":"Xã Thanh Khương",
      "quanhuyen":"22309"
   },
   {
      "id":"2230923",
      "phuongxa":"Xã Hà Mãn",
      "quanhuyen":"22309"
   },
   {
      "id":"2230925",
      "phuongxa":"Xã Xuân Lâm",
      "quanhuyen":"22309"
   },
   {
      "id":"2230927",
      "phuongxa":"Xã Ninh Xá",
      "quanhuyen":"22309"
   },
   {
      "id":"2230929",
      "phuongxa":"Xã Ngũ Thái",
      "quanhuyen":"22309"
   },
   {
      "id":"2230931",
      "phuongxa":"Xã Nguyệt Đức",
      "quanhuyen":"22309"
   },
   {
      "id":"2230933",
      "phuongxa":"Xã Nghĩa Đạo",
      "quanhuyen":"22309"
   },
   {
      "id":"2230935",
      "phuongxa":"Xã Song Liễu",
      "quanhuyen":"22309"
   },
   {
      "id":"2231100",
      "phuongxa":"Không xác định",
      "quanhuyen":"22311"
   },
   {
      "id":"2231101",
      "phuongxa":"Thị trấn Thứa",
      "quanhuyen":"22311"
   },
   {
      "id":"2231127",
      "phuongxa":"Xã Trung Kênh",
      "quanhuyen":"22311"
   },
   {
      "id":"2231131",
      "phuongxa":"Xã Phú Hòa",
      "quanhuyen":"22311"
   },
   {
      "id":"2231133",
      "phuongxa":"Xã Mỹ Hương",
      "quanhuyen":"22311"
   },
   {
      "id":"2231137",
      "phuongxa":"Xã Quảng Phú",
      "quanhuyen":"22311"
   },
   {
      "id":"2231139",
      "phuongxa":"Xã Lai Hạ",
      "quanhuyen":"22311"
   },
   {
      "id":"2231141",
      "phuongxa":"Xã An Thịnh",
      "quanhuyen":"22311"
   },
   {
      "id":"2231143",
      "phuongxa":"Xã Trừng Xá",
      "quanhuyen":"22311"
   },
   {
      "id":"2231145",
      "phuongxa":"Xã Trung Chính",
      "quanhuyen":"22311"
   },
   {
      "id":"2231149",
      "phuongxa":"Xã Phú Lương",
      "quanhuyen":"22311"
   },
   {
      "id":"2231151",
      "phuongxa":"Xã Bình Định",
      "quanhuyen":"22311"
   },
   {
      "id":"2231201",
      "phuongxa":"Xã Xuân Lai",
      "quanhuyen":"22312"
   },
   {
      "id":"2231203",
      "phuongxa":"Xã Vạn Ninh",
      "quanhuyen":"22312"
   },
   {
      "id":"2231207",
      "phuongxa":"Xã Đại Lai",
      "quanhuyen":"22312"
   },
   {
      "id":"2231209",
      "phuongxa":"Xã Giang Sơn",
      "quanhuyen":"22312"
   },
   {
      "id":"2231211",
      "phuongxa":"Xã Đông Cứu",
      "quanhuyen":"22312"
   },
   {
      "id":"2231213",
      "phuongxa":"Xã Cao Đức",
      "quanhuyen":"22312"
   },
   {
      "id":"2231215",
      "phuongxa":"Xã Song Giang",
      "quanhuyen":"22312"
   },
   {
      "id":"2231219",
      "phuongxa":"Xã Bình Dương",
      "quanhuyen":"22312"
   },
   {
      "id":"2231221",
      "phuongxa":"Xã Nhân Thắng",
      "quanhuyen":"22312"
   },
   {
      "id":"2231223",
      "phuongxa":"Xã Đại Bái",
      "quanhuyen":"22312"
   },
   {
      "id":"2231225",
      "phuongxa":"Xã Quỳnh Phú",
      "quanhuyen":"22312"
   },
   {
      "id":"2250000",
      "phuongxa":"Không xác định",
      "quanhuyen":"22500"
   },
   {
      "id":"2250101",
      "phuongxa":"Phường Hà Khánh",
      "quanhuyen":"22501"
   },
   {
      "id":"2250103",
      "phuongxa":"Phường Hà Lầm",
      "quanhuyen":"22501"
   },
   {
      "id":"2250105",
      "phuongxa":"Phường Hà Trung",
      "quanhuyen":"22501"
   },
   {
      "id":"2250107",
      "phuongxa":"Phường Hà Phong",
      "quanhuyen":"22501"
   },
   {
      "id":"2250111",
      "phuongxa":"Phường Hồng Hà",
      "quanhuyen":"22501"
   },
   {
      "id":"2250113",
      "phuongxa":"Phường Hồng Hải",
      "quanhuyen":"22501"
   },
   {
      "id":"2250115",
      "phuongxa":"Phường Cao Thắng",
      "quanhuyen":"22501"
   },
   {
      "id":"2250117",
      "phuongxa":"Phường Cao Xanh",
      "quanhuyen":"22501"
   },
   {
      "id":"2250119",
      "phuongxa":"Phường Yết Kiêu",
      "quanhuyen":"22501"
   },
   {
      "id":"2250121",
      "phuongxa":"Phường Trần Hưng Đạo",
      "quanhuyen":"22501"
   },
   {
      "id":"2250123",
      "phuongxa":"Phường Bạch Đằng",
      "quanhuyen":"22501"
   },
   {
      "id":"2250125",
      "phuongxa":"Phường Hồng Gai",
      "quanhuyen":"22501"
   },
   {
      "id":"2250127",
      "phuongxa":"Phường Bãi Cháy",
      "quanhuyen":"22501"
   },
   {
      "id":"2250129",
      "phuongxa":"Phường Hà Khẩu",
      "quanhuyen":"22501"
   },
   {
      "id":"2250131",
      "phuongxa":"Phường Giếng Đáy",
      "quanhuyen":"22501"
   },
   {
      "id":"2250133",
      "phuongxa":"Xã Hùng Thắng",
      "quanhuyen":"22501"
   },
   {
      "id":"2250135",
      "phuongxa":"Xã Tuần Châu",
      "quanhuyen":"22501"
   },
   {
      "id":"2250300",
      "phuongxa":"Không xác định",
      "quanhuyen":"22503"
   },
   {
      "id":"2250301",
      "phuongxa":"Phường Mông Dương",
      "quanhuyen":"22503"
   },
   {
      "id":"2250303",
      "phuongxa":"Phường Cửa Ông",
      "quanhuyen":"22503"
   },
   {
      "id":"2250305",
      "phuongxa":"Phường Cẩm Thịnh",
      "quanhuyen":"22503"
   },
   {
      "id":"2250307",
      "phuongxa":"Phường Cẩm Phú",
      "quanhuyen":"22503"
   },
   {
      "id":"2250309",
      "phuongxa":"Phường Cẩm Sơn",
      "quanhuyen":"22503"
   },
   {
      "id":"2250311",
      "phuongxa":"Phường Cẩm Đông",
      "quanhuyen":"22503"
   },
   {
      "id":"2250315",
      "phuongxa":"Phường Cẩm Thành",
      "quanhuyen":"22503"
   },
   {
      "id":"2250317",
      "phuongxa":"Phường Cẩm Trung",
      "quanhuyen":"22503"
   },
   {
      "id":"2250319",
      "phuongxa":"Phường Cẩm Thủy",
      "quanhuyen":"22503"
   },
   {
      "id":"2250321",
      "phuongxa":"Phường Cẩm Thạch",
      "quanhuyen":"22503"
   },
   {
      "id":"2250323",
      "phuongxa":"Xã Cộng Hòa",
      "quanhuyen":"22503"
   },
   {
      "id":"2250325",
      "phuongxa":"Xã Dương Huy",
      "quanhuyen":"22503"
   },
   {
      "id":"2250327",
      "phuongxa":"Xã Cẩm Bình",
      "quanhuyen":"22503"
   },
   {
      "id":"2250329",
      "phuongxa":"Xã Cẩm Hải",
      "quanhuyen":"22503"
   },
   {
      "id":"2250331",
      "phuongxa":"Xã Quang Hanh",
      "quanhuyen":"22503"
   },
   {
      "id":"2250500",
      "phuongxa":"Không xác định",
      "quanhuyen":"22505"
   },
   {
      "id":"2250501",
      "phuongxa":"Phường Vàng Danh",
      "quanhuyen":"22505"
   },
   {
      "id":"2250503",
      "phuongxa":"Phường Bắc Sơn",
      "quanhuyen":"22505"
   },
   {
      "id":"2250505",
      "phuongxa":"Phường Trương Vương",
      "quanhuyen":"22505"
   },
   {
      "id":"2250507",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"22505"
   },
   {
      "id":"2250509",
      "phuongxa":"Phường Thanh Sơn",
      "quanhuyen":"22505"
   },
   {
      "id":"2250511",
      "phuongxa":"Xã Thượng Yên Công",
      "quanhuyen":"22505"
   },
   {
      "id":"2250513",
      "phuongxa":"Xã Nam Khê",
      "quanhuyen":"22505"
   },
   {
      "id":"2250515",
      "phuongxa":"Xã Phương Đông",
      "quanhuyen":"22505"
   },
   {
      "id":"2250517",
      "phuongxa":"Xã Phương Nam",
      "quanhuyen":"22505"
   },
   {
      "id":"2250700",
      "phuongxa":"Không xác định",
      "quanhuyen":"22507"
   },
   {
      "id":"2250701",
      "phuongxa":"Thị trấn Bình Liêu",
      "quanhuyen":"22507"
   },
   {
      "id":"2250707",
      "phuongxa":"Xã Đồng Tâm",
      "quanhuyen":"22507"
   },
   {
      "id":"2250709",
      "phuongxa":"Xã Lục Hồn",
      "quanhuyen":"22507"
   },
   {
      "id":"2250711",
      "phuongxa":"Xã Tĩnh Húc",
      "quanhuyen":"22507"
   },
   {
      "id":"2250713",
      "phuongxa":"Xã Húc Động",
      "quanhuyen":"22507"
   },
   {
      "id":"2250715",
      "phuongxa":"Xã Vô Ngại",
      "quanhuyen":"22507"
   },
   {
      "id":"2250900",
      "phuongxa":"Không xác định",
      "quanhuyen":"22509"
   },
   {
      "id":"2250903",
      "phuongxa":"Phường Trần Phú",
      "quanhuyen":"22509"
   },
   {
      "id":"2250905",
      "phuongxa":"Phường Hòa Lạc",
      "quanhuyen":"22509"
   },
   {
      "id":"2250907",
      "phuongxa":"Phường Ninh Dương",
      "quanhuyen":"22509"
   },
   {
      "id":"2250909",
      "phuongxa":"Phường Trà Cổ",
      "quanhuyen":"22509"
   },
   {
      "id":"2250911",
      "phuongxa":"Xã Hải Sơn",
      "quanhuyen":"22509"
   },
   {
      "id":"2250915",
      "phuongxa":"Xã Hải Xuân",
      "quanhuyen":"22509"
   },
   {
      "id":"2250917",
      "phuongxa":"Xã Hải Hòa",
      "quanhuyen":"22509"
   },
   {
      "id":"2250919",
      "phuongxa":"Xã Vạn Ninh",
      "quanhuyen":"22509"
   },
   {
      "id":"2250921",
      "phuongxa":"Xã Hải Yến",
      "quanhuyen":"22509"
   },
   {
      "id":"2250923",
      "phuongxa":"Xã Hải Đông",
      "quanhuyen":"22509"
   },
   {
      "id":"2250927",
      "phuongxa":"Xã Quảng Nghĩa",
      "quanhuyen":"22509"
   },
   {
      "id":"2250929",
      "phuongxa":"Xã Vĩnh Thực",
      "quanhuyen":"22509"
   },
   {
      "id":"2250931",
      "phuongxa":"Xã Vĩnh Trung",
      "quanhuyen":"22509"
   },
   {
      "id":"2251301",
      "phuongxa":"Thị trấn Tiên Yên",
      "quanhuyen":"22513"
   },
   {
      "id":"2251303",
      "phuongxa":"Xã Đại Dực",
      "quanhuyen":"22513"
   },
   {
      "id":"2251305",
      "phuongxa":"Xã Hà Lâu",
      "quanhuyen":"22513"
   },
   {
      "id":"2251307",
      "phuongxa":"Xã Phong Dụ",
      "quanhuyen":"22513"
   },
   {
      "id":"2251311",
      "phuongxa":"Xã Yên Than",
      "quanhuyen":"22513"
   },
   {
      "id":"2251313",
      "phuongxa":"Xã Hải Lạng",
      "quanhuyen":"22513"
   },
   {
      "id":"2251315",
      "phuongxa":"Xã Tiên Lãng",
      "quanhuyen":"22513"
   },
   {
      "id":"2251317",
      "phuongxa":"Xã Đông Ngũ",
      "quanhuyen":"22513"
   },
   {
      "id":"2251319",
      "phuongxa":"Xã Đông Hải",
      "quanhuyen":"22513"
   },
   {
      "id":"2251321",
      "phuongxa":"XÂã ÂĐÃƠng Rui",
      "quanhuyen":"22513"
   },
   {
      "id":"2251500",
      "phuongxa":"Không xác định",
      "quanhuyen":"22515"
   },
   {
      "id":"2251501",
      "phuongxa":"Thị trấn Ba Chẽ",
      "quanhuyen":"22515"
   },
   {
      "id":"2251503",
      "phuongxa":"Xã Minh Cầm",
      "quanhuyen":"22515"
   },
   {
      "id":"2251505",
      "phuongxa":"Xã Lương Mông",
      "quanhuyen":"22515"
   },
   {
      "id":"2251507",
      "phuongxa":"Xã Thanh Lâm",
      "quanhuyen":"22515"
   },
   {
      "id":"2251509",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"22515"
   },
   {
      "id":"2251511",
      "phuongxa":"Xã Đạp Thanh",
      "quanhuyen":"22515"
   },
   {
      "id":"2251513",
      "phuongxa":"Xã Đồn Đạc",
      "quanhuyen":"22515"
   },
   {
      "id":"2251515",
      "phuongxa":"Xã Nam Sơn",
      "quanhuyen":"22515"
   },
   {
      "id":"2251700",
      "phuongxa":"Không xác định",
      "quanhuyen":"22517"
   },
   {
      "id":"2251701",
      "phuongxa":"Thị trấn Cái Rồng",
      "quanhuyen":"22517"
   },
   {
      "id":"2251703",
      "phuongxa":"Xã Đài Xuyên",
      "quanhuyen":"22517"
   },
   {
      "id":"2251707",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"22517"
   },
   {
      "id":"2251709",
      "phuongxa":"Xã Vạn Yên",
      "quanhuyen":"22517"
   },
   {
      "id":"2251711",
      "phuongxa":"Xã Hạ Long",
      "quanhuyen":"22517"
   },
   {
      "id":"2251713",
      "phuongxa":"Xã Đông Xá",
      "quanhuyen":"22517"
   },
   {
      "id":"2251715",
      "phuongxa":"Xã Minh Châu",
      "quanhuyen":"22517"
   },
   {
      "id":"2251717",
      "phuongxa":"Xã Quan Lạn",
      "quanhuyen":"22517"
   },
   {
      "id":"2251719",
      "phuongxa":"Xã Bản Sen",
      "quanhuyen":"22517"
   },
   {
      "id":"2251721",
      "phuongxa":"Xã Ngọc Vừng",
      "quanhuyen":"22517"
   },
   {
      "id":"2251723",
      "phuongxa":"Xã Thắng Lợi",
      "quanhuyen":"22517"
   },
   {
      "id":"2251900",
      "phuongxa":"Không xác định",
      "quanhuyen":"22519"
   },
   {
      "id":"2251901",
      "phuongxa":"Thị trấn Trới",
      "quanhuyen":"22519"
   },
   {
      "id":"2251903",
      "phuongxa":"Xã Kỳ Thượng",
      "quanhuyen":"22519"
   },
   {
      "id":"2251905",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"22519"
   },
   {
      "id":"2251907",
      "phuongxa":"Xã Đồng Lâm",
      "quanhuyen":"22519"
   },
   {
      "id":"2251909",
      "phuongxa":"Xã Đồng Sơn",
      "quanhuyen":"22519"
   },
   {
      "id":"2251911",
      "phuongxa":"Xã Vũ Oai",
      "quanhuyen":"22519"
   },
   {
      "id":"2251913",
      "phuongxa":"Xã Thống Nhất",
      "quanhuyen":"22519"
   },
   {
      "id":"2251915",
      "phuongxa":"Xã Lê Lợi",
      "quanhuyen":"22519"
   },
   {
      "id":"2251917",
      "phuongxa":"Xã Quảng La",
      "quanhuyen":"22519"
   },
   {
      "id":"2251921",
      "phuongxa":"Xã Dân Chủ",
      "quanhuyen":"22519"
   },
   {
      "id":"2251923",
      "phuongxa":"Xã Bằng Cả",
      "quanhuyen":"22519"
   },
   {
      "id":"2251925",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"22519"
   },
   {
      "id":"2251927",
      "phuongxa":"Xã Việt Hưng",
      "quanhuyen":"22519"
   },
   {
      "id":"2251929",
      "phuongxa":"Xã Đại Yên",
      "quanhuyen":"22519"
   },
   {
      "id":"2252100",
      "phuongxa":"Không xác định",
      "quanhuyen":"22521"
   },
   {
      "id":"2252101",
      "phuongxa":"Thị trấn Đông Triều",
      "quanhuyen":"22521"
   },
   {
      "id":"2252103",
      "phuongxa":"Thị trấn Mạo Khê",
      "quanhuyen":"22521"
   },
   {
      "id":"2252107",
      "phuongxa":"Xã Nguyễn Huệ",
      "quanhuyen":"22521"
   },
   {
      "id":"2252109",
      "phuongxa":"Xã Thủy An",
      "quanhuyen":"22521"
   },
   {
      "id":"2252111",
      "phuongxa":"Xã Việt Dân",
      "quanhuyen":"22521"
   },
   {
      "id":"2252113",
      "phuongxa":"Xã An Sinh",
      "quanhuyen":"22521"
   },
   {
      "id":"2252115",
      "phuongxa":"Xã Tân Việt",
      "quanhuyen":"22521"
   },
   {
      "id":"2252117",
      "phuongxa":"Xã Hồng Phong",
      "quanhuyen":"22521"
   },
   {
      "id":"2252121",
      "phuongxa":"Xã Tràng An",
      "quanhuyen":"22521"
   },
   {
      "id":"2252123",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"22521"
   },
   {
      "id":"2252125",
      "phuongxa":"Xã Xuân Sơn",
      "quanhuyen":"22521"
   },
   {
      "id":"2252127",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"22521"
   },
   {
      "id":"2252129",
      "phuongxa":"Xã Yên Thọ",
      "quanhuyen":"22521"
   },
   {
      "id":"2252131",
      "phuongxa":"Xã Yên Đức",
      "quanhuyen":"22521"
   },
   {
      "id":"2252137",
      "phuongxa":"Xã Hồng Thái Đông",
      "quanhuyen":"22521"
   },
   {
      "id":"2252139",
      "phuongxa":"Xã Bình Khê",
      "quanhuyen":"22521"
   },
   {
      "id":"2252141",
      "phuongxa":"Xã Tràng Lương",
      "quanhuyen":"22521"
   },
   {
      "id":"2252301",
      "phuongxa":"Xã Cô Tô",
      "quanhuyen":"22523"
   },
   {
      "id":"2252303",
      "phuongxa":"Xã Thanh Lân",
      "quanhuyen":"22523"
   },
   {
      "id":"2252500",
      "phuongxa":"Không xác định",
      "quanhuyen":"22525"
   },
   {
      "id":"2252501",
      "phuongxa":"Thị trấn Đầm Hà",
      "quanhuyen":"22525"
   },
   {
      "id":"2252503",
      "phuongxa":"Xã Quảng Lâm",
      "quanhuyen":"22525"
   },
   {
      "id":"2252505",
      "phuongxa":"Xã Quảng An",
      "quanhuyen":"22525"
   },
   {
      "id":"2252507",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"22525"
   },
   {
      "id":"2252509",
      "phuongxa":"Xã Quảng Lợi",
      "quanhuyen":"22525"
   },
   {
      "id":"2252511",
      "phuongxa":"Xã Dực Yên",
      "quanhuyen":"22525"
   },
   {
      "id":"2252513",
      "phuongxa":"Xã Quảng Tân",
      "quanhuyen":"22525"
   },
   {
      "id":"2252517",
      "phuongxa":"Xã Đại Bình",
      "quanhuyen":"22525"
   },
   {
      "id":"2252519",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"22525"
   },
   {
      "id":"2252700",
      "phuongxa":"Không xác định",
      "quanhuyen":"22527"
   },
   {
      "id":"2252701",
      "phuongxa":"Thị trấn Quảng Hà",
      "quanhuyen":"22527"
   },
   {
      "id":"2252703",
      "phuongxa":"Xã Quảng Đức",
      "quanhuyen":"22527"
   },
   {
      "id":"2252705",
      "phuongxa":"Xã Quảng Sơn",
      "quanhuyen":"22527"
   },
   {
      "id":"2252707",
      "phuongxa":"Xã Quảng Thành",
      "quanhuyen":"22527"
   },
   {
      "id":"2252709",
      "phuongxa":"Xã Quảng Thắng",
      "quanhuyen":"22527"
   },
   {
      "id":"2252711",
      "phuongxa":"Xã Quảng Thịnh",
      "quanhuyen":"22527"
   },
   {
      "id":"2252713",
      "phuongxa":"Xã Quảng Minh",
      "quanhuyen":"22527"
   },
   {
      "id":"2252715",
      "phuongxa":"Xã Quảng Chính",
      "quanhuyen":"22527"
   },
   {
      "id":"2252717",
      "phuongxa":"Xã Quảng Long",
      "quanhuyen":"22527"
   },
   {
      "id":"2252719",
      "phuongxa":"Xã Đường Hoa",
      "quanhuyen":"22527"
   },
   {
      "id":"2252721",
      "phuongxa":"Xã Quảng Phong",
      "quanhuyen":"22527"
   },
   {
      "id":"2252723",
      "phuongxa":"Xã Quảng Trung",
      "quanhuyen":"22527"
   },
   {
      "id":"2252725",
      "phuongxa":"Xã Phú Hải",
      "quanhuyen":"22527"
   },
   {
      "id":"2252727",
      "phuongxa":"Xã Quảng Điền",
      "quanhuyen":"22527"
   },
   {
      "id":"2252729",
      "phuongxa":"Xã Tiến Tới",
      "quanhuyen":"22527"
   },
   {
      "id":"2252900",
      "phuongxa":"Không xác định",
      "quanhuyen":"22529"
   },
   {
      "id":"2252901",
      "phuongxa":"Phường Quảng Yên",
      "quanhuyen":"22529"
   },
   {
      "id":"2252903",
      "phuongxa":"Phường Đông Mai",
      "quanhuyen":"22529"
   },
   {
      "id":"2252905",
      "phuongxa":"Phường Minh Thành",
      "quanhuyen":"22529"
   },
   {
      "id":"2252907",
      "phuongxa":"Xã Sông Khoai",
      "quanhuyen":"22529"
   },
   {
      "id":"2252909",
      "phuongxa":"Xã Hiệp Hòa",
      "quanhuyen":"22529"
   },
   {
      "id":"2252911",
      "phuongxa":"Xã Cộng Hòa",
      "quanhuyen":"22529"
   },
   {
      "id":"2252913",
      "phuongxa":"Xã Tiền An",
      "quanhuyen":"22529"
   },
   {
      "id":"2252915",
      "phuongxa":"Xã Hoàng Tân",
      "quanhuyen":"22529"
   },
   {
      "id":"2252917",
      "phuongxa":"Phường Tân An",
      "quanhuyen":"22529"
   },
   {
      "id":"2252919",
      "phuongxa":"Phường Yên Giang",
      "quanhuyen":"22529"
   },
   {
      "id":"2252921",
      "phuongxa":"Phường Nam Hòa",
      "quanhuyen":"22529"
   },
   {
      "id":"2252923",
      "phuongxa":"Phường Hà An",
      "quanhuyen":"22529"
   },
   {
      "id":"2252925",
      "phuongxa":"Xã Cẩm La",
      "quanhuyen":"22529"
   },
   {
      "id":"2252927",
      "phuongxa":"Phường Phong Hải",
      "quanhuyen":"22529"
   },
   {
      "id":"2252929",
      "phuongxa":"Phường Yên Hải",
      "quanhuyen":"22529"
   },
   {
      "id":"2252931",
      "phuongxa":"Xã Liên Hòa",
      "quanhuyen":"22529"
   },
   {
      "id":"2252933",
      "phuongxa":"Phường Phong Cốc",
      "quanhuyen":"22529"
   },
   {
      "id":"2252935",
      "phuongxa":"Xã Liên Vị",
      "quanhuyen":"22529"
   },
   {
      "id":"3010000",
      "phuongxa":"Không xác định",
      "quanhuyen":"30100"
   },
   {
      "id":"3010300",
      "phuongxa":"Không xác định",
      "quanhuyen":"30103"
   },
   {
      "id":"3010301",
      "phuongxa":"Phường Na Lay",
      "quanhuyen":"30103"
   },
   {
      "id":"3010303",
      "phuongxa":"Phường Lê Lợi",
      "quanhuyen":"30103"
   },
   {
      "id":"3010305",
      "phuongxa":"Phường Sông Đà",
      "quanhuyen":"30103"
   },
   {
      "id":"3010307",
      "phuongxa":"Xã Pa Ủ",
      "quanhuyen":"30103"
   },
   {
      "id":"3010309",
      "phuongxa":"Xã Mường Tè",
      "quanhuyen":"30103"
   },
   {
      "id":"3010313",
      "phuongxa":"Xã Mù Cả",
      "quanhuyen":"30103"
   },
   {
      "id":"3010315",
      "phuongxa":"Xã Bun Tở",
      "quanhuyen":"30103"
   },
   {
      "id":"3010317",
      "phuongxa":"Xã Nậm Khao",
      "quanhuyen":"30103"
   },
   {
      "id":"3010319",
      "phuongxa":"Xã Tà Tổng",
      "quanhuyen":"30103"
   },
   {
      "id":"3010321",
      "phuongxa":"Xã Bun Nưa",
      "quanhuyen":"30103"
   },
   {
      "id":"3010323",
      "phuongxa":"Xã Kan Hồ",
      "quanhuyen":"30103"
   },
   {
      "id":"3010327",
      "phuongxa":"Xã Nậm Hàng",
      "quanhuyen":"30103"
   },
   {
      "id":"3010329",
      "phuongxa":"Xã Khun Há",
      "quanhuyen":"30103"
   },
   {
      "id":"3010500",
      "phuongxa":"Không xác định",
      "quanhuyen":"30105"
   },
   {
      "id":"3010501",
      "phuongxa":"Thị trấn Mường Tè",
      "quanhuyen":"30105"
   },
   {
      "id":"3010503",
      "phuongxa":"Xã Thu Lũm",
      "quanhuyen":"30105"
   },
   {
      "id":"3010507",
      "phuongxa":"Xã Pa Vệ Sử",
      "quanhuyen":"30105"
   },
   {
      "id":"3010509",
      "phuongxa":"Xã Hua Bun",
      "quanhuyen":"30105"
   },
   {
      "id":"3010511",
      "phuongxa":"Xã Mường Tè",
      "quanhuyen":"30105"
   },
   {
      "id":"3010515",
      "phuongxa":"Xã Bun Tở",
      "quanhuyen":"30105"
   },
   {
      "id":"3010519",
      "phuongxa":"Xã Sín Thầu",
      "quanhuyen":"30105"
   },
   {
      "id":"3010521",
      "phuongxa":"Xã Chung Chải",
      "quanhuyen":"30105"
   },
   {
      "id":"3010523",
      "phuongxa":"Xã Mường Nhé",
      "quanhuyen":"30105"
   },
   {
      "id":"3010525",
      "phuongxa":"Xã Mường Toong",
      "quanhuyen":"30105"
   },
   {
      "id":"3010527",
      "phuongxa":"Xã Mù Cả",
      "quanhuyen":"30105"
   },
   {
      "id":"3010529",
      "phuongxa":"Xã Pa ủ",
      "quanhuyen":"30105"
   },
   {
      "id":"3010531",
      "phuongxa":"Xã Tà Tổng",
      "quanhuyen":"30105"
   },
   {
      "id":"3010533",
      "phuongxa":"Xã Nậm Khao",
      "quanhuyen":"30105"
   },
   {
      "id":"3010700",
      "phuongxa":"Không xác định",
      "quanhuyen":"30107"
   },
   {
      "id":"3010701",
      "phuongxa":"Thị trấn Phong Thổ",
      "quanhuyen":"30107"
   },
   {
      "id":"3010703",
      "phuongxa":"Xã Sì Lờ Lầu",
      "quanhuyen":"30107"
   },
   {
      "id":"3010705",
      "phuongxa":"Xã Mồ Sì San",
      "quanhuyen":"30107"
   },
   {
      "id":"3010707",
      "phuongxa":"Xã Ma Li Chải",
      "quanhuyen":"30107"
   },
   {
      "id":"3010709",
      "phuongxa":"Xã Pa Vây Sử",
      "quanhuyen":"30107"
   },
   {
      "id":"3010711",
      "phuongxa":"Xã Vàng Ma Chải",
      "quanhuyen":"30107"
   },
   {
      "id":"3010713",
      "phuongxa":"Xã Tông Qua Lìn",
      "quanhuyen":"30107"
   },
   {
      "id":"3010715",
      "phuongxa":"Xã Dào San",
      "quanhuyen":"30107"
   },
   {
      "id":"3010717",
      "phuongxa":"Xã Mù Sang",
      "quanhuyen":"30107"
   },
   {
      "id":"3010719",
      "phuongxa":"Xã Ma Ly Pho",
      "quanhuyen":"30107"
   },
   {
      "id":"3010721",
      "phuongxa":"Xã Hoang Thèn",
      "quanhuyen":"30107"
   },
   {
      "id":"3010723",
      "phuongxa":"Xã Bản Lang",
      "quanhuyen":"30107"
   },
   {
      "id":"3010725",
      "phuongxa":"Xã Khổng Lào",
      "quanhuyen":"30107"
   },
   {
      "id":"3010727",
      "phuongxa":"Xã Mường So",
      "quanhuyen":"30107"
   },
   {
      "id":"3010729",
      "phuongxa":"Xã Nậm Xe",
      "quanhuyen":"30107"
   },
   {
      "id":"3010731",
      "phuongxa":"Xã Sin Suối Hồ",
      "quanhuyen":"30107"
   },
   {
      "id":"3010733",
      "phuongxa":"Xã Thèn Sin",
      "quanhuyen":"30107"
   },
   {
      "id":"3010737",
      "phuongxa":"Xã Lả Nhì Thàng",
      "quanhuyen":"30107"
   },
   {
      "id":"3010739",
      "phuongxa":"Xã Sùng Phài",
      "quanhuyen":"30107"
   },
   {
      "id":"3010741",
      "phuongxa":"Xã Nậm Loỏng",
      "quanhuyen":"30107"
   },
   {
      "id":"3010743",
      "phuongxa":"Xã Nùng Nàng",
      "quanhuyen":"30107"
   },
   {
      "id":"3010745",
      "phuongxa":"Xã Tả Lèng",
      "quanhuyen":"30107"
   },
   {
      "id":"3010747",
      "phuongxa":"Xã Hồ Thầu",
      "quanhuyen":"30107"
   },
   {
      "id":"3010749",
      "phuongxa":"Xã Bản Giang",
      "quanhuyen":"30107"
   },
   {
      "id":"3010751",
      "phuongxa":"Xã Bản Hon",
      "quanhuyen":"30107"
   },
   {
      "id":"3010753",
      "phuongxa":"Xã Bình Lư",
      "quanhuyen":"30107"
   },
   {
      "id":"3010755",
      "phuongxa":"Xã Nà Tăm",
      "quanhuyen":"30107"
   },
   {
      "id":"3010757",
      "phuongxa":"Xã Bản Bo",
      "quanhuyen":"30107"
   },
   {
      "id":"3010759",
      "phuongxa":"Xã Khun Há",
      "quanhuyen":"30107"
   },
   {
      "id":"3010900",
      "phuongxa":"Không xác định",
      "quanhuyen":"30109"
   },
   {
      "id":"3010901",
      "phuongxa":"Thị trấn Sìn Hồ",
      "quanhuyen":"30109"
   },
   {
      "id":"3010903",
      "phuongxa":"Xã Huổi Luông",
      "quanhuyen":"30109"
   },
   {
      "id":"3010905",
      "phuongxa":"Xã Pa Tần",
      "quanhuyen":"30109"
   },
   {
      "id":"3010907",
      "phuongxa":"Xã Phìn Hồ",
      "quanhuyen":"30109"
   },
   {
      "id":"3010909",
      "phuongxa":"Xã Nậm Ban",
      "quanhuyen":"30109"
   },
   {
      "id":"3010911",
      "phuongxa":"Xã Hồng Thu",
      "quanhuyen":"30109"
   },
   {
      "id":"3010913",
      "phuongxa":"Xã Tả Phìn",
      "quanhuyen":"30109"
   },
   {
      "id":"3010917",
      "phuongxa":"Xã Ma Quai",
      "quanhuyen":"30109"
   },
   {
      "id":"3010919",
      "phuongxa":"Xã Sà Dề Phìn",
      "quanhuyen":"30109"
   },
   {
      "id":"3010921",
      "phuongxa":"Xã Tả Ngảo",
      "quanhuyen":"30109"
   },
   {
      "id":"3010923",
      "phuongxa":"Xã Nậm Tăm",
      "quanhuyen":"30109"
   },
   {
      "id":"3010925",
      "phuongxa":"Xã Làng Mô",
      "quanhuyen":"30109"
   },
   {
      "id":"3010927",
      "phuongxa":"Xã Nậm Cha",
      "quanhuyen":"30109"
   },
   {
      "id":"3010929",
      "phuongxa":"Xã Noong Hẻo",
      "quanhuyen":"30109"
   },
   {
      "id":"3010931",
      "phuongxa":"Xã Pu Sam Cáp",
      "quanhuyen":"30109"
   },
   {
      "id":"3010935",
      "phuongxa":"Xã Nậm Mạ",
      "quanhuyen":"30109"
   },
   {
      "id":"3010937",
      "phuongxa":"Xã Căn Co",
      "quanhuyen":"30109"
   },
   {
      "id":"3010939",
      "phuongxa":"Xã Nậm Cuổi",
      "quanhuyen":"30109"
   },
   {
      "id":"3010941",
      "phuongxa":"Xã Nậm Hăn",
      "quanhuyen":"30109"
   },
   {
      "id":"3011100",
      "phuongxa":"Không xác định",
      "quanhuyen":"30111"
   },
   {
      "id":"3011103",
      "phuongxa":"Xã Nậm Hàng",
      "quanhuyen":"30111"
   },
   {
      "id":"3011105",
      "phuongxa":"Xã Pú Đao",
      "quanhuyen":"30111"
   },
   {
      "id":"3011107",
      "phuongxa":"Xã Chăn Nưa",
      "quanhuyen":"30111"
   },
   {
      "id":"3011109",
      "phuongxa":"Xã Lay Nưa",
      "quanhuyen":"30111"
   },
   {
      "id":"3011111",
      "phuongxa":"Xã Mường Tùng",
      "quanhuyen":"30111"
   },
   {
      "id":"3011113",
      "phuongxa":"Xã Huổi Lèng",
      "quanhuyen":"30111"
   },
   {
      "id":"3011115",
      "phuongxa":"Xã Chà Nưa",
      "quanhuyen":"30111"
   },
   {
      "id":"3011117",
      "phuongxa":"Xã Chà Cang",
      "quanhuyen":"30111"
   },
   {
      "id":"3011121",
      "phuongxa":"Xã Si Pa Phìn",
      "quanhuyen":"30111"
   },
   {
      "id":"3011125",
      "phuongxa":"Xã Pa Ham",
      "quanhuyen":"30111"
   },
   {
      "id":"3011127",
      "phuongxa":"Xã Xá Tổng",
      "quanhuyen":"30111"
   },
   {
      "id":"3012100",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"30121"
   },
   {
      "id":"3012101",
      "phuongxa":"Thị Trấn Phong Thổ",
      "quanhuyen":"30121"
   },
   {
      "id":"3012103",
      "phuongxa":"Xã Lả Nhì Thàng",
      "quanhuyen":"30121"
   },
   {
      "id":"3012105",
      "phuongxa":"Xã Thèn Sin",
      "quanhuyen":"30121"
   },
   {
      "id":"3012107",
      "phuongxa":"Xã Sùng Phài",
      "quanhuyen":"30121"
   },
   {
      "id":"3012109",
      "phuongxa":"Xã Tả Lèng",
      "quanhuyen":"30121"
   },
   {
      "id":"3012111",
      "phuongxa":"Xã Nậm Loỏng",
      "quanhuyen":"30121"
   },
   {
      "id":"3012113",
      "phuongxa":"Xã Hồ Thầu",
      "quanhuyen":"30121"
   },
   {
      "id":"3012115",
      "phuongxa":"Xã Tam Đường",
      "quanhuyen":"30121"
   },
   {
      "id":"3012117",
      "phuongxa":"Xã Bình Lư",
      "quanhuyen":"30121"
   },
   {
      "id":"3012119",
      "phuongxa":"Xã Nùng Nàng",
      "quanhuyen":"30121"
   },
   {
      "id":"3012121",
      "phuongxa":"Xã Bản Giang",
      "quanhuyen":"30121"
   },
   {
      "id":"3012123",
      "phuongxa":"Xã Bản Hon",
      "quanhuyen":"30121"
   },
   {
      "id":"3012127",
      "phuongxa":"Xã Nà Tăm",
      "quanhuyen":"30121"
   },
   {
      "id":"3012129",
      "phuongxa":"Xã Khun Há",
      "quanhuyen":"30121"
   },
   {
      "id":"3012301",
      "phuongxa":"Thị Trấn Than Uyên",
      "quanhuyen":"30123"
   },
   {
      "id":"3012303",
      "phuongxa":"Xã Mường Khoa",
      "quanhuyen":"30123"
   },
   {
      "id":"3012305",
      "phuongxa":"Xã Thân Thuộc",
      "quanhuyen":"30123"
   },
   {
      "id":"3012307",
      "phuongxa":"Xã Hố Mít",
      "quanhuyen":"30123"
   },
   {
      "id":"3012309",
      "phuongxa":"Xã Nậm Cần",
      "quanhuyen":"30123"
   },
   {
      "id":"3012311",
      "phuongxa":"Xã Nậm Sỏ",
      "quanhuyen":"30123"
   },
   {
      "id":"3012313",
      "phuongxa":"Xã Pắc Ta",
      "quanhuyen":"30123"
   },
   {
      "id":"3012315",
      "phuongxa":"Xã Mường Than",
      "quanhuyen":"30123"
   },
   {
      "id":"3012317",
      "phuongxa":"Xã Tà Mít",
      "quanhuyen":"30123"
   },
   {
      "id":"3012319",
      "phuongxa":"Xã Mường Mít",
      "quanhuyen":"30123"
   },
   {
      "id":"3050100",
      "phuongxa":"Không xác định",
      "quanhuyen":"30501"
   },
   {
      "id":"3050101",
      "phuongxa":"Phường Phương Lâm",
      "quanhuyen":"30501"
   },
   {
      "id":"3050103",
      "phuongxa":"Phường Đồng Tiến",
      "quanhuyen":"30501"
   },
   {
      "id":"3050105",
      "phuongxa":"Phường Chăm Mát",
      "quanhuyen":"30501"
   },
   {
      "id":"3050107",
      "phuongxa":"Phường Tân Thịnh",
      "quanhuyen":"30501"
   },
   {
      "id":"3050111",
      "phuongxa":"Phường Hữu Nghị",
      "quanhuyen":"30501"
   },
   {
      "id":"3050113",
      "phuongxa":"Xã Yên Mông",
      "quanhuyen":"30501"
   },
   {
      "id":"3050115",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"30501"
   },
   {
      "id":"3050117",
      "phuongxa":"Xã Thịnh Lang",
      "quanhuyen":"30501"
   },
   {
      "id":"3050119",
      "phuongxa":"Xã Sủ Ngòi",
      "quanhuyen":"30501"
   },
   {
      "id":"3050121",
      "phuongxa":"Xã Dân Chủ",
      "quanhuyen":"30501"
   },
   {
      "id":"3050123",
      "phuongxa":"Xã Thái Thịnh",
      "quanhuyen":"30501"
   },
   {
      "id":"3050125",
      "phuongxa":"Xã Thái Bình",
      "quanhuyen":"30501"
   },
   {
      "id":"3050127",
      "phuongxa":"Xã Thống Nhất",
      "quanhuyen":"30501"
   },
   {
      "id":"3050300",
      "phuongxa":"Không xác định",
      "quanhuyen":"30503"
   },
   {
      "id":"3050301",
      "phuongxa":"Thị trấn Đà Bắc",
      "quanhuyen":"30503"
   },
   {
      "id":"3050303",
      "phuongxa":"Xã Đồng Nghê",
      "quanhuyen":"30503"
   },
   {
      "id":"3050305",
      "phuongxa":"Xã Suối Nánh",
      "quanhuyen":"30503"
   },
   {
      "id":"3050307",
      "phuongxa":"Xã Mường Tuổng",
      "quanhuyen":"30503"
   },
   {
      "id":"3050309",
      "phuongxa":"Xã Giáp Đắt",
      "quanhuyen":"30503"
   },
   {
      "id":"3050311",
      "phuongxa":"Xã Mường Chiềng",
      "quanhuyen":"30503"
   },
   {
      "id":"3050313",
      "phuongxa":"Xã Tân Pheo",
      "quanhuyen":"30503"
   },
   {
      "id":"3050315",
      "phuongxa":"Xã Đồng Chum",
      "quanhuyen":"30503"
   },
   {
      "id":"3050317",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"30503"
   },
   {
      "id":"3050319",
      "phuongxa":"Xã Đồng Ruộng",
      "quanhuyen":"30503"
   },
   {
      "id":"3050321",
      "phuongxa":"Xã Tân Minh",
      "quanhuyen":"30503"
   },
   {
      "id":"3050323",
      "phuongxa":"Xã Tu Lý",
      "quanhuyen":"30503"
   },
   {
      "id":"3050327",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"30503"
   },
   {
      "id":"3050329",
      "phuongxa":"Xã Toàn Sơn",
      "quanhuyen":"30503"
   },
   {
      "id":"3050331",
      "phuongxa":"Xã Yên Hòa",
      "quanhuyen":"30503"
   },
   {
      "id":"3050333",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"30503"
   },
   {
      "id":"3050337",
      "phuongxa":"Xã Cao Sơn",
      "quanhuyen":"30503"
   },
   {
      "id":"3050339",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"30503"
   },
   {
      "id":"3050341",
      "phuongxa":"Xã Vầy Nưa",
      "quanhuyen":"30503"
   },
   {
      "id":"3050500",
      "phuongxa":"Không xác định",
      "quanhuyen":"30505"
   },
   {
      "id":"3050501",
      "phuongxa":"Thị trấn Mai Châu",
      "quanhuyen":"30505"
   },
   {
      "id":"3050505",
      "phuongxa":"Xã Phúc Sạn",
      "quanhuyen":"30505"
   },
   {
      "id":"3050507",
      "phuongxa":"Xã Pà Cò",
      "quanhuyen":"30505"
   },
   {
      "id":"3050509",
      "phuongxa":"Xã Hang Kia",
      "quanhuyen":"30505"
   },
   {
      "id":"3050511",
      "phuongxa":"Xã Ba Khan",
      "quanhuyen":"30505"
   },
   {
      "id":"3050513",
      "phuongxa":"Xã Bao La",
      "quanhuyen":"30505"
   },
   {
      "id":"3050517",
      "phuongxa":"Xã Đồng Bảng",
      "quanhuyen":"30505"
   },
   {
      "id":"3050519",
      "phuongxa":"Xã Cun Pheo",
      "quanhuyen":"30505"
   },
   {
      "id":"3050521",
      "phuongxa":"Xã Tòng Đậu",
      "quanhuyen":"30505"
   },
   {
      "id":"3050525",
      "phuongxa":"Xã Thung Khe",
      "quanhuyen":"30505"
   },
   {
      "id":"3050527",
      "phuongxa":"Xã Săm Khóe",
      "quanhuyen":"30505"
   },
   {
      "id":"3050531",
      "phuongxa":"Xã Chiềng Châu",
      "quanhuyen":"30505"
   },
   {
      "id":"3050533",
      "phuongxa":"Xã Mai Hạ",
      "quanhuyen":"30505"
   },
   {
      "id":"3050537",
      "phuongxa":"Xã Nong Luông",
      "quanhuyen":"30505"
   },
   {
      "id":"3050539",
      "phuongxa":"Xã Pù Pin",
      "quanhuyen":"30505"
   },
   {
      "id":"3050541",
      "phuongxa":"Xã Vạn Mai",
      "quanhuyen":"30505"
   },
   {
      "id":"3050700",
      "phuongxa":"Không xác định",
      "quanhuyen":"30507"
   },
   {
      "id":"3050701",
      "phuongxa":"Thị trấn Kỳ Sơn",
      "quanhuyen":"30507"
   },
   {
      "id":"3050703",
      "phuongxa":"Thị trấn Cao Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"3050705",
      "phuongxa":"Xã Hợp Thịnh",
      "quanhuyen":"30507"
   },
   {
      "id":"3050707",
      "phuongxa":"Xã Phú Minh",
      "quanhuyen":"30507"
   },
   {
      "id":"3050709",
      "phuongxa":"Xã Hợp Thành",
      "quanhuyen":"30507"
   },
   {
      "id":"3050711",
      "phuongxa":"Xã Phúc Tiến",
      "quanhuyen":"30507"
   },
   {
      "id":"3050713",
      "phuongxa":"Xã Dân Hòa",
      "quanhuyen":"30507"
   },
   {
      "id":"3050715",
      "phuongxa":"Xã Mông Hóa",
      "quanhuyen":"30507"
   },
   {
      "id":"3050717",
      "phuongxa":"Xã Dân Hạ",
      "quanhuyen":"30507"
   },
   {
      "id":"3050719",
      "phuongxa":"Xã Trung Minh",
      "quanhuyen":"30507"
   },
   {
      "id":"3050721",
      "phuongxa":"Xã Độc Lập",
      "quanhuyen":"30507"
   },
   {
      "id":"3050723",
      "phuongxa":"Xã Bình Thanh",
      "quanhuyen":"30507"
   },
   {
      "id":"3050725",
      "phuongxa":"Xã Thung Nai",
      "quanhuyen":"30507"
   },
   {
      "id":"3050727",
      "phuongxa":"Xã Bắc Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"3050729",
      "phuongxa":"Xã Thu Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"3050733",
      "phuongxa":"Xã Xuân Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"4010500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40105"
   },
   {
      "id":"4010501",
      "phuongxa":"Phường Bắc Sơn",
      "quanhuyen":"40105"
   },
   {
      "id":"4010503",
      "phuongxa":"Phường Trường Sơn",
      "quanhuyen":"40105"
   },
   {
      "id":"4010505",
      "phuongxa":"Phường Trung Sơn",
      "quanhuyen":"40105"
   },
   {
      "id":"4010507",
      "phuongxa":"Xã Quảng Tiến",
      "quanhuyen":"40105"
   },
   {
      "id":"4010509",
      "phuongxa":"Xã Quảng Cư",
      "quanhuyen":"40105"
   },
   {
      "id":"4010700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40107"
   },
   {
      "id":"4010701",
      "phuongxa":"Xã Trung Lý",
      "quanhuyen":"40107"
   },
   {
      "id":"4010703",
      "phuongxa":"Xã Tén Tằn",
      "quanhuyen":"40107"
   },
   {
      "id":"4010705",
      "phuongxa":"Xã Tam Chung",
      "quanhuyen":"40107"
   },
   {
      "id":"4010707",
      "phuongxa":"Xã Phù Nhi",
      "quanhuyen":"40107"
   },
   {
      "id":"4010709",
      "phuongxa":"Xã Quang Chiểu",
      "quanhuyen":"40107"
   },
   {
      "id":"4010711",
      "phuongxa":"Xã Mường Chanh",
      "quanhuyen":"40107"
   },
   {
      "id":"4010900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40109"
   },
   {
      "id":"4010901",
      "phuongxa":"Thị trấn Quan Hóa",
      "quanhuyen":"40109"
   },
   {
      "id":"4010903",
      "phuongxa":"Xã Thiên Phú",
      "quanhuyen":"40109"
   },
   {
      "id":"4010905",
      "phuongxa":"Xã Nam Động",
      "quanhuyen":"40109"
   },
   {
      "id":"4010907",
      "phuongxa":"Xã Hiền Chung",
      "quanhuyen":"40109"
   },
   {
      "id":"4010909",
      "phuongxa":"Xã Hiền Kiệt",
      "quanhuyen":"40109"
   },
   {
      "id":"4010911",
      "phuongxa":"Xã Phú Nghiêm",
      "quanhuyen":"40109"
   },
   {
      "id":"4010915",
      "phuongxa":"Xã Phú Lệ",
      "quanhuyen":"40109"
   },
   {
      "id":"4010917",
      "phuongxa":"Xã Hồi Xuân",
      "quanhuyen":"40109"
   },
   {
      "id":"4010919",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"40109"
   },
   {
      "id":"4010921",
      "phuongxa":"Xã Phú Thanh",
      "quanhuyen":"40109"
   },
   {
      "id":"4010923",
      "phuongxa":"Xã Phú Xuân",
      "quanhuyen":"40109"
   },
   {
      "id":"4010925",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"40109"
   },
   {
      "id":"4010927",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"40109"
   },
   {
      "id":"4010929",
      "phuongxa":"Xã Thanh Xuân",
      "quanhuyen":"40109"
   },
   {
      "id":"4010933",
      "phuongxa":"Xã Nam Xuân",
      "quanhuyen":"40109"
   },
   {
      "id":"4010935",
      "phuongxa":"Xã Xuân Phú",
      "quanhuyen":"40109"
   },
   {
      "id":"4011100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40111"
   },
   {
      "id":"4011101",
      "phuongxa":"Xã Trung Xuân",
      "quanhuyen":"40111"
   },
   {
      "id":"4011103",
      "phuongxa":"Xã Trung Thượng",
      "quanhuyen":"40111"
   },
   {
      "id":"4011107",
      "phuongxa":"Xã Sơn Hà",
      "quanhuyen":"40111"
   },
   {
      "id":"4011109",
      "phuongxa":"Xã Tam Thanh",
      "quanhuyen":"40111"
   },
   {
      "id":"4011111",
      "phuongxa":"Xã Sơn Thủy",
      "quanhuyen":"40111"
   },
   {
      "id":"4011113",
      "phuongxa":"Xã Sơn Lư",
      "quanhuyen":"40111"
   },
   {
      "id":"4011115",
      "phuongxa":"Xã Tam Lư",
      "quanhuyen":"40111"
   },
   {
      "id":"4011117",
      "phuongxa":"Xã Sơn Điện",
      "quanhuyen":"40111"
   },
   {
      "id":"4011303",
      "phuongxa":"Xã Điền Thượng",
      "quanhuyen":"40113"
   },
   {
      "id":"4011305",
      "phuongxa":"Xã Điền Hạ",
      "quanhuyen":"40113"
   },
   {
      "id":"4011307",
      "phuongxa":"Xã Điền Quang",
      "quanhuyen":"40113"
   },
   {
      "id":"4011309",
      "phuongxa":"Xã Điền Trung",
      "quanhuyen":"40113"
   },
   {
      "id":"4011313",
      "phuongxa":"Xã Lương Ngoại",
      "quanhuyen":"40113"
   },
   {
      "id":"4011315",
      "phuongxa":"Xã ái Thượng",
      "quanhuyen":"40113"
   },
   {
      "id":"4011317",
      "phuongxa":"Xã Lương Nội",
      "quanhuyen":"40113"
   },
   {
      "id":"4011319",
      "phuongxa":"Xã Điền Lư",
      "quanhuyen":"40113"
   },
   {
      "id":"4011321",
      "phuongxa":"Xã Lương Trung",
      "quanhuyen":"40113"
   },
   {
      "id":"4011323",
      "phuongxa":"Xã Lũng Niêm",
      "quanhuyen":"40113"
   },
   {
      "id":"4011327",
      "phuongxa":"Xã Hạ Trung",
      "quanhuyen":"40113"
   },
   {
      "id":"4011329",
      "phuongxa":"Xã Cổ Lũng",
      "quanhuyen":"40113"
   },
   {
      "id":"4011331",
      "phuongxa":"Xã Thành Lâm",
      "quanhuyen":"40113"
   },
   {
      "id":"4011333",
      "phuongxa":"Xã Ban Công",
      "quanhuyen":"40113"
   },
   {
      "id":"4011335",
      "phuongxa":"Xã Kỳ Tân",
      "quanhuyen":"40113"
   },
   {
      "id":"4011337",
      "phuongxa":"Xã Văn Nho",
      "quanhuyen":"40113"
   },
   {
      "id":"4011339",
      "phuongxa":"Xã Thiết ống",
      "quanhuyen":"40113"
   },
   {
      "id":"4011341",
      "phuongxa":"Xã Lâm Sa",
      "quanhuyen":"40113"
   },
   {
      "id":"4011343",
      "phuongxa":"Xã Thiết Kế",
      "quanhuyen":"40113"
   },
   {
      "id":"4011345",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"40113"
   },
   {
      "id":"4011500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40115"
   },
   {
      "id":"4011501",
      "phuongxa":"Thị trấn Cẩm Thủy",
      "quanhuyen":"40115"
   },
   {
      "id":"4011503",
      "phuongxa":"Thị trấn NT Phúc Do",
      "quanhuyen":"40115"
   },
   {
      "id":"4011505",
      "phuongxa":"Xã Cẩm Thành",
      "quanhuyen":"40115"
   },
   {
      "id":"4011507",
      "phuongxa":"Xã Cẩm Quý",
      "quanhuyen":"40115"
   },
   {
      "id":"4011509",
      "phuongxa":"Xã Cẩm Lương",
      "quanhuyen":"40115"
   },
   {
      "id":"4011511",
      "phuongxa":"Xã Cẩm Thạch",
      "quanhuyen":"40115"
   },
   {
      "id":"4011513",
      "phuongxa":"Xã Cẩm Liên",
      "quanhuyen":"40115"
   },
   {
      "id":"4011515",
      "phuongxa":"Xã Cẩm Giang",
      "quanhuyen":"40115"
   },
   {
      "id":"4011519",
      "phuongxa":"Xã Cẩm Tú",
      "quanhuyen":"40115"
   },
   {
      "id":"4011521",
      "phuongxa":"Xã Cẩm Sơn",
      "quanhuyen":"40115"
   },
   {
      "id":"4011523",
      "phuongxa":"Xã Cẩm Châu",
      "quanhuyen":"40115"
   },
   {
      "id":"4011525",
      "phuongxa":"Xã Cẩm Tâm",
      "quanhuyen":"40115"
   },
   {
      "id":"4011527",
      "phuongxa":"Xã Cẩm Phong",
      "quanhuyen":"40115"
   },
   {
      "id":"4011529",
      "phuongxa":"Xã Cẩm Ngọc",
      "quanhuyen":"40115"
   },
   {
      "id":"4011531",
      "phuongxa":"Xã Cẩm Long",
      "quanhuyen":"40115"
   },
   {
      "id":"4011533",
      "phuongxa":"Xã Cẩm Yên",
      "quanhuyen":"40115"
   },
   {
      "id":"4011535",
      "phuongxa":"Xã Cẩm Tân",
      "quanhuyen":"40115"
   },
   {
      "id":"4011537",
      "phuongxa":"Xã Cẩm Phú",
      "quanhuyen":"40115"
   },
   {
      "id":"4011539",
      "phuongxa":"Xã Cẩm Vân",
      "quanhuyen":"40115"
   },
   {
      "id":"4011700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40117"
   },
   {
      "id":"4011701",
      "phuongxa":"Thị trấn Lang Chánh",
      "quanhuyen":"40117"
   },
   {
      "id":"4011703",
      "phuongxa":"Xã Yên Khương",
      "quanhuyen":"40117"
   },
   {
      "id":"4011705",
      "phuongxa":"Xã Yên Thắng",
      "quanhuyen":"40117"
   },
   {
      "id":"4011707",
      "phuongxa":"Xã Trí Nang",
      "quanhuyen":"40117"
   },
   {
      "id":"4011709",
      "phuongxa":"Xã Giao An",
      "quanhuyen":"40117"
   },
   {
      "id":"4011711",
      "phuongxa":"Xã Giao Thiện",
      "quanhuyen":"40117"
   },
   {
      "id":"4011713",
      "phuongxa":"Xã Tân Phúc",
      "quanhuyen":"40117"
   },
   {
      "id":"4011717",
      "phuongxa":"Xã Lâm Phú",
      "quanhuyen":"40117"
   },
   {
      "id":"4011719",
      "phuongxa":"Xã Quang Hiến",
      "quanhuyen":"40117"
   },
   {
      "id":"4011721",
      "phuongxa":"Xã Đồng Lương",
      "quanhuyen":"40117"
   },
   {
      "id":"4011900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40119"
   },
   {
      "id":"4011901",
      "phuongxa":"Thị trấn NT Thạch Thành",
      "quanhuyen":"40119"
   },
   {
      "id":"4011903",
      "phuongxa":"Thị trấn Kim Tân",
      "quanhuyen":"40119"
   },
   {
      "id":"4011905",
      "phuongxa":"Thị trấn NT Vân Du",
      "quanhuyen":"40119"
   },
   {
      "id":"4011907",
      "phuongxa":"Xã Thạch Lâm",
      "quanhuyen":"40119"
   },
   {
      "id":"4011909",
      "phuongxa":"Xã Thạch Quảng",
      "quanhuyen":"40119"
   },
   {
      "id":"4011913",
      "phuongxa":"Xã Thạch Cẩm",
      "quanhuyen":"40119"
   },
   {
      "id":"4011915",
      "phuongxa":"Xã Thạch Sơn",
      "quanhuyen":"40119"
   },
   {
      "id":"4011917",
      "phuongxa":"Xã Thạch Bình",
      "quanhuyen":"40119"
   },
   {
      "id":"4011919",
      "phuongxa":"Xã Thạch Định",
      "quanhuyen":"40119"
   },
   {
      "id":"4011921",
      "phuongxa":"Xã Thạch Đồng",
      "quanhuyen":"40119"
   },
   {
      "id":"4011923",
      "phuongxa":"Xã Thạch Long",
      "quanhuyen":"40119"
   },
   {
      "id":"4011927",
      "phuongxa":"Xã Thành Yên",
      "quanhuyen":"40119"
   },
   {
      "id":"4011929",
      "phuongxa":"Xã Thành Vinh",
      "quanhuyen":"40119"
   },
   {
      "id":"4011931",
      "phuongxa":"Xã Thành Minh",
      "quanhuyen":"40119"
   },
   {
      "id":"4011935",
      "phuongxa":"Xã Thành Tân",
      "quanhuyen":"40119"
   },
   {
      "id":"4011939",
      "phuongxa":"Xã Thành Vân",
      "quanhuyen":"40119"
   },
   {
      "id":"4011941",
      "phuongxa":"Xã Thành Tâm",
      "quanhuyen":"40119"
   },
   {
      "id":"4011943",
      "phuongxa":"Xã Thành An",
      "quanhuyen":"40119"
   },
   {
      "id":"4011945",
      "phuongxa":"Xã Thành Thọ",
      "quanhuyen":"40119"
   },
   {
      "id":"4011947",
      "phuongxa":"Xã Thành Tiến",
      "quanhuyen":"40119"
   },
   {
      "id":"4011951",
      "phuongxa":"Xã Thành Kim",
      "quanhuyen":"40119"
   },
   {
      "id":"4011953",
      "phuongxa":"Xã Thành Hưng",
      "quanhuyen":"40119"
   },
   {
      "id":"4011955",
      "phuongxa":"Xã Ngọc Trạo",
      "quanhuyen":"40119"
   },
   {
      "id":"4012100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40121"
   },
   {
      "id":"4012101",
      "phuongxa":"Thị trấn Ngọc Lạc",
      "quanhuyen":"40121"
   },
   {
      "id":"4012103",
      "phuongxa":"Thị trấn NT Lam Sơn",
      "quanhuyen":"40121"
   },
   {
      "id":"4012105",
      "phuongxa":"Thị trấn NT Sông Âm",
      "quanhuyen":"40121"
   },
   {
      "id":"4012107",
      "phuongxa":"Xã Mỹ Tân",
      "quanhuyen":"40121"
   },
   {
      "id":"4012109",
      "phuongxa":"Xã Thúy Sơn",
      "quanhuyen":"40121"
   },
   {
      "id":"4012111",
      "phuongxa":"Xã Thạch Lập",
      "quanhuyen":"40121"
   },
   {
      "id":"4012113",
      "phuongxa":"Xã Vân Âm",
      "quanhuyen":"40121"
   },
   {
      "id":"4012115",
      "phuongxa":"Xã Cao Ngọc",
      "quanhuyen":"40121"
   },
   {
      "id":"4012117",
      "phuongxa":"Xã Ngọc Khê",
      "quanhuyen":"40121"
   },
   {
      "id":"4012119",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"40121"
   },
   {
      "id":"4012121",
      "phuongxa":"Xã Đông Thịnh",
      "quanhuyen":"40121"
   },
   {
      "id":"4012123",
      "phuongxa":"Xã Ngọc Liên",
      "quanhuyen":"40121"
   },
   {
      "id":"4012125",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"40121"
   },
   {
      "id":"4012127",
      "phuongxa":"Xã Lộc Thịnh",
      "quanhuyen":"40121"
   },
   {
      "id":"4012129",
      "phuongxa":"Xã Cao Thịnh",
      "quanhuyen":"40121"
   },
   {
      "id":"4012131",
      "phuongxa":"Xã Ngọc Trung",
      "quanhuyen":"40121"
   },
   {
      "id":"4012133",
      "phuongxa":"Xã Phùng Giáo",
      "quanhuyen":"40121"
   },
   {
      "id":"4012135",
      "phuongxa":"Xã Phùng Minh",
      "quanhuyen":"40121"
   },
   {
      "id":"4012137",
      "phuongxa":"Xã Phúc Thịnh",
      "quanhuyen":"40121"
   },
   {
      "id":"4012139",
      "phuongxa":"Xã Nguyệt ấn",
      "quanhuyen":"40121"
   },
   {
      "id":"4012143",
      "phuongxa":"Xã Minh Tiến",
      "quanhuyen":"40121"
   },
   {
      "id":"4012145",
      "phuongxa":"Xã Minh Sơn",
      "quanhuyen":"40121"
   },
   {
      "id":"3050735",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"3050737",
      "phuongxa":"Xã Tây Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"3050739",
      "phuongxa":"Xã Dũng Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"3050741",
      "phuongxa":"Xã Nam Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"3050743",
      "phuongxa":"Xã Yên Lập",
      "quanhuyen":"30507"
   },
   {
      "id":"3050745",
      "phuongxa":"Xã Yên Thượng",
      "quanhuyen":"30507"
   },
   {
      "id":"3050900",
      "phuongxa":"Không xác định",
      "quanhuyen":"30509"
   },
   {
      "id":"3050901",
      "phuongxa":"Thị trấn Lương Sơn",
      "quanhuyen":"30509"
   },
   {
      "id":"3050903",
      "phuongxa":"Xã Yên Bình",
      "quanhuyen":"30509"
   },
   {
      "id":"3050905",
      "phuongxa":"Xã Yên Trung",
      "quanhuyen":"30509"
   },
   {
      "id":"3050907",
      "phuongxa":"Xã Yên Quang",
      "quanhuyen":"30509"
   },
   {
      "id":"3050909",
      "phuongxa":"Xã Tiến Xuân",
      "quanhuyen":"30509"
   },
   {
      "id":"3050911",
      "phuongxa":"Xã Đông Xuân",
      "quanhuyen":"30509"
   },
   {
      "id":"3050913",
      "phuongxa":"Xã Lâm Sơn",
      "quanhuyen":"30509"
   },
   {
      "id":"3050915",
      "phuongxa":"Xã Hòa Sơn",
      "quanhuyen":"30509"
   },
   {
      "id":"3050917",
      "phuongxa":"Xã Trường Sơn",
      "quanhuyen":"30509"
   },
   {
      "id":"3050919",
      "phuongxa":"Xã Tân Vinh",
      "quanhuyen":"30509"
   },
   {
      "id":"3050923",
      "phuongxa":"Xã Cao Răm",
      "quanhuyen":"30509"
   },
   {
      "id":"3050925",
      "phuongxa":"Xã Cư Yên",
      "quanhuyen":"30509"
   },
   {
      "id":"3050927",
      "phuongxa":"Xã Hợp Hòa",
      "quanhuyen":"30509"
   },
   {
      "id":"3050929",
      "phuongxa":"Xã Liên Sơn",
      "quanhuyen":"30509"
   },
   {
      "id":"3050931",
      "phuongxa":"Xã Thành Lập",
      "quanhuyen":"30509"
   },
   {
      "id":"3050933",
      "phuongxa":"Xã Tiến Sơn",
      "quanhuyen":"30509"
   },
   {
      "id":"3050935",
      "phuongxa":"Xã Trung Sơn",
      "quanhuyen":"30509"
   },
   {
      "id":"3051100",
      "phuongxa":"Không xác định",
      "quanhuyen":"30511"
   },
   {
      "id":"3051101",
      "phuongxa":"Thị trấn Bo",
      "quanhuyen":"30511"
   },
   {
      "id":"3051103",
      "phuongxa":"Thị trấn NT Thanh Hà",
      "quanhuyen":"30511"
   },
   {
      "id":"3051105",
      "phuongxa":"Xã Đú Sáng",
      "quanhuyen":"30511"
   },
   {
      "id":"3051109",
      "phuongxa":"Xã Bình Sơn",
      "quanhuyen":"30511"
   },
   {
      "id":"3051111",
      "phuongxa":"Xã Hùng Tiến",
      "quanhuyen":"30511"
   },
   {
      "id":"3051113",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"30511"
   },
   {
      "id":"3051115",
      "phuongxa":"Xã Tú Sơn",
      "quanhuyen":"30511"
   },
   {
      "id":"3051117",
      "phuongxa":"Xã Nật Sơn",
      "quanhuyen":"30511"
   },
   {
      "id":"3051121",
      "phuongxa":"Xã Sơn Thủy",
      "quanhuyen":"30511"
   },
   {
      "id":"3051123",
      "phuongxa":"Xã Cao Dương",
      "quanhuyen":"30511"
   },
   {
      "id":"3051125",
      "phuongxa":"Xã Hạ Bì",
      "quanhuyen":"30511"
   },
   {
      "id":"3051127",
      "phuongxa":"Xã Đông Bắc",
      "quanhuyen":"30511"
   },
   {
      "id":"3051129",
      "phuongxa":"Xã Lập Chiệng",
      "quanhuyen":"30511"
   },
   {
      "id":"3051135",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"30511"
   },
   {
      "id":"3051137",
      "phuongxa":"Xã Hợp Đồng",
      "quanhuyen":"30511"
   },
   {
      "id":"3051139",
      "phuongxa":"Xã Long Sơn",
      "quanhuyen":"30511"
   },
   {
      "id":"3051141",
      "phuongxa":"Xã Thượng Tiến",
      "quanhuyen":"30511"
   },
   {
      "id":"3051143",
      "phuongxa":"Xã Cao Thắng",
      "quanhuyen":"30511"
   },
   {
      "id":"3051147",
      "phuongxa":"Xã Kim Tiến",
      "quanhuyen":"30511"
   },
   {
      "id":"3051149",
      "phuongxa":"Xã Hợp Kim",
      "quanhuyen":"30511"
   },
   {
      "id":"3051151",
      "phuongxa":"Xã Thanh Lương",
      "quanhuyen":"30511"
   },
   {
      "id":"3051153",
      "phuongxa":"Xã Kim Bôi",
      "quanhuyen":"30511"
   },
   {
      "id":"3051155",
      "phuongxa":"Xã Hợp Thanh",
      "quanhuyen":"30511"
   },
   {
      "id":"3051159",
      "phuongxa":"Xã Thanh Nông",
      "quanhuyen":"30511"
   },
   {
      "id":"3051161",
      "phuongxa":"Xã Kim Truy",
      "quanhuyen":"30511"
   },
   {
      "id":"3051163",
      "phuongxa":"Xã Sào Báy",
      "quanhuyen":"30511"
   },
   {
      "id":"3051165",
      "phuongxa":"Xã Cuối Hạ",
      "quanhuyen":"30511"
   },
   {
      "id":"3051167",
      "phuongxa":"Xã Mi Hòa",
      "quanhuyen":"30511"
   },
   {
      "id":"3051169",
      "phuongxa":"Xã Nuông Dăm",
      "quanhuyen":"30511"
   },
   {
      "id":"3051300",
      "phuongxa":"Không xác định",
      "quanhuyen":"30513"
   },
   {
      "id":"3051301",
      "phuongxa":"Thị trấn Mường Khến",
      "quanhuyen":"30513"
   },
   {
      "id":"3051303",
      "phuongxa":"Xã Ngòi Hoa",
      "quanhuyen":"30513"
   },
   {
      "id":"3051305",
      "phuongxa":"Xã Trung Hòa",
      "quanhuyen":"30513"
   },
   {
      "id":"3051307",
      "phuongxa":"Xã Phú Vinh",
      "quanhuyen":"30513"
   },
   {
      "id":"3051309",
      "phuongxa":"Xã Phú Cường",
      "quanhuyen":"30513"
   },
   {
      "id":"3051311",
      "phuongxa":"Xã Mỹ Hòa",
      "quanhuyen":"30513"
   },
   {
      "id":"3051315",
      "phuongxa":"Xã Phong Phú",
      "quanhuyen":"30513"
   },
   {
      "id":"3051317",
      "phuongxa":"Xã Mãn Đức",
      "quanhuyen":"30513"
   },
   {
      "id":"3051319",
      "phuongxa":"Xã Quyết Chiến",
      "quanhuyen":"30513"
   },
   {
      "id":"3051321",
      "phuongxa":"Xã Địch Giáo",
      "quanhuyen":"30513"
   },
   {
      "id":"3051323",
      "phuongxa":"Xã Tuân Lộ",
      "quanhuyen":"30513"
   },
   {
      "id":"3051325",
      "phuongxa":"Xã Tử Nê",
      "quanhuyen":"30513"
   },
   {
      "id":"3051327",
      "phuongxa":"Xã Ngọc Mỹ",
      "quanhuyen":"30513"
   },
   {
      "id":"3051329",
      "phuongxa":"Xã Thanh Hối",
      "quanhuyen":"30513"
   },
   {
      "id":"3051331",
      "phuongxa":"Xã Đông Lai",
      "quanhuyen":"30513"
   },
   {
      "id":"3051333",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"30513"
   },
   {
      "id":"3051335",
      "phuongxa":"Xã Lũng Vân",
      "quanhuyen":"30513"
   },
   {
      "id":"3051337",
      "phuongxa":"Xã Quy Mỹ",
      "quanhuyen":"30513"
   },
   {
      "id":"3051339",
      "phuongxa":"Xã Do Nhân",
      "quanhuyen":"30513"
   },
   {
      "id":"3051341",
      "phuongxa":"Xã Nam Sơn",
      "quanhuyen":"30513"
   },
   {
      "id":"3051343",
      "phuongxa":"Xã Lỗ Sơn",
      "quanhuyen":"30513"
   },
   {
      "id":"3051345",
      "phuongxa":"Xã Ngổ Luông",
      "quanhuyen":"30513"
   },
   {
      "id":"3051347",
      "phuongxa":"Xã Gia Mô",
      "quanhuyen":"30513"
   },
   {
      "id":"3051500",
      "phuongxa":"Không xác định",
      "quanhuyen":"30515"
   },
   {
      "id":"3051501",
      "phuongxa":"Thị trấn Vụ Bản",
      "quanhuyen":"30515"
   },
   {
      "id":"3051505",
      "phuongxa":"Xã Miền Đồi",
      "quanhuyen":"30515"
   },
   {
      "id":"3051507",
      "phuongxa":"Xã Mỹ Thành",
      "quanhuyen":"30515"
   },
   {
      "id":"3051509",
      "phuongxa":"Xã Tuân Đạo",
      "quanhuyen":"30515"
   },
   {
      "id":"3051511",
      "phuongxa":"Xã Văn Nghĩa",
      "quanhuyen":"30515"
   },
   {
      "id":"3051513",
      "phuongxa":"Xã Văn Sơn",
      "quanhuyen":"30515"
   },
   {
      "id":"3051515",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"30515"
   },
   {
      "id":"3051517",
      "phuongxa":"Xã Nhân Nghĩa",
      "quanhuyen":"30515"
   },
   {
      "id":"3051519",
      "phuongxa":"Xã Thượng Cốc",
      "quanhuyen":"30515"
   },
   {
      "id":"3051521",
      "phuongxa":"Xã Phú Lương",
      "quanhuyen":"30515"
   },
   {
      "id":"3051523",
      "phuongxa":"Xã Phúc Tuy",
      "quanhuyen":"30515"
   },
   {
      "id":"3051525",
      "phuongxa":"Xã Xuất Hóa",
      "quanhuyen":"30515"
   },
   {
      "id":"3051527",
      "phuongxa":"Xã Yên Phú",
      "quanhuyen":"30515"
   },
   {
      "id":"3051529",
      "phuongxa":"Xã Bình Hẻm",
      "quanhuyen":"30515"
   },
   {
      "id":"3051531",
      "phuongxa":"Xã Chí Đạo",
      "quanhuyen":"30515"
   },
   {
      "id":"3051533",
      "phuongxa":"Xã Chí Thiện",
      "quanhuyen":"30515"
   },
   {
      "id":"3051535",
      "phuongxa":"Xã Bình Cảng",
      "quanhuyen":"30515"
   },
   {
      "id":"3051537",
      "phuongxa":"Xã Bình Chân",
      "quanhuyen":"30515"
   },
   {
      "id":"3051539",
      "phuongxa":"Xã Định Cư",
      "quanhuyen":"30515"
   },
   {
      "id":"3051543",
      "phuongxa":"Xã Liên Vũ",
      "quanhuyen":"30515"
   },
   {
      "id":"3051545",
      "phuongxa":"Xã Đa Phúc",
      "quanhuyen":"30515"
   },
   {
      "id":"3051547",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"30515"
   },
   {
      "id":"3051549",
      "phuongxa":"Xã Tự Do",
      "quanhuyen":"30515"
   },
   {
      "id":"3051551",
      "phuongxa":"Xã Vũ Lâm",
      "quanhuyen":"30515"
   },
   {
      "id":"3051555",
      "phuongxa":"Xã Ân Nghĩa",
      "quanhuyen":"30515"
   },
   {
      "id":"3051557",
      "phuongxa":"Xã Yên Nghiệp",
      "quanhuyen":"30515"
   },
   {
      "id":"3051559",
      "phuongxa":"Xã Ngọc Lâu",
      "quanhuyen":"30515"
   },
   {
      "id":"3051700",
      "phuongxa":"Không xác định",
      "quanhuyen":"30517"
   },
   {
      "id":"3051703",
      "phuongxa":"Thị trấn NT Sông Bôi",
      "quanhuyen":"30517"
   },
   {
      "id":"3051705",
      "phuongxa":"Xã Phú Lão",
      "quanhuyen":"30517"
   },
   {
      "id":"3051707",
      "phuongxa":"Xã Phú Thành",
      "quanhuyen":"30517"
   },
   {
      "id":"3051709",
      "phuongxa":"Xã Cố Nghĩa",
      "quanhuyen":"30517"
   },
   {
      "id":"3051711",
      "phuongxa":"Xã Hưng Thi",
      "quanhuyen":"30517"
   },
   {
      "id":"3051713",
      "phuongxa":"Xã Lạc Long",
      "quanhuyen":"30517"
   },
   {
      "id":"3051717",
      "phuongxa":"Xã Khoan Dụ",
      "quanhuyen":"30517"
   },
   {
      "id":"3051719",
      "phuongxa":"Xã Đồng Môn",
      "quanhuyen":"30517"
   },
   {
      "id":"3051721",
      "phuongxa":"Xã Đồng Tâm",
      "quanhuyen":"30517"
   },
   {
      "id":"3051723",
      "phuongxa":"Xã Yên Bồng",
      "quanhuyen":"30517"
   },
   {
      "id":"3051725",
      "phuongxa":"Xã An Lạc",
      "quanhuyen":"30517"
   },
   {
      "id":"3051900",
      "phuongxa":"Không xác định",
      "quanhuyen":"30519"
   },
   {
      "id":"3051901",
      "phuongxa":"Thị trấn Hàng Trạm",
      "quanhuyen":"30519"
   },
   {
      "id":"3051905",
      "phuongxa":"Xã Lạc Hưng",
      "quanhuyen":"30519"
   },
   {
      "id":"3051907",
      "phuongxa":"Xã Lạc Lương",
      "quanhuyen":"30519"
   },
   {
      "id":"3051909",
      "phuongxa":"Xã Bảo Hiệu",
      "quanhuyen":"30519"
   },
   {
      "id":"3051911",
      "phuongxa":"Xã Hữu Lợi",
      "quanhuyen":"30519"
   },
   {
      "id":"3051913",
      "phuongxa":"Xã Lạc Thịnh",
      "quanhuyen":"30519"
   },
   {
      "id":"3051915",
      "phuongxa":"Xã Yên Lạc",
      "quanhuyen":"30519"
   },
   {
      "id":"3051917",
      "phuongxa":"Xã Phú Lai",
      "quanhuyen":"30519"
   },
   {
      "id":"3051919",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"30519"
   },
   {
      "id":"3051921",
      "phuongxa":"Xã Yên Trị",
      "quanhuyen":"30519"
   },
   {
      "id":"3051923",
      "phuongxa":"Xã Ngọc Lương",
      "quanhuyen":"30519"
   },
   {
      "id":"3052100",
      "phuongxa":"Không xác định",
      "quanhuyen":"30521"
   },
   {
      "id":"3052101",
      "phuongxa":"Thị trấn Cao Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3052103",
      "phuongxa":"Xã Bình Thanh",
      "quanhuyen":"30521"
   },
   {
      "id":"3052105",
      "phuongxa":"Xã Thung Nai",
      "quanhuyen":"30521"
   },
   {
      "id":"3052107",
      "phuongxa":"Xã Bắc Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3052109",
      "phuongxa":"Xã Thu Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3052111",
      "phuongxa":"Xã Đông Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3052113",
      "phuongxa":"Xã Xuân Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3052117",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3052119",
      "phuongxa":"Xã Dũng Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3052121",
      "phuongxa":"Xã Nam Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3052123",
      "phuongxa":"Xã Yên Lập",
      "quanhuyen":"30521"
   },
   {
      "id":"3052125",
      "phuongxa":"Xã Yên Thượng",
      "quanhuyen":"30521"
   },
   {
      "id":"3070100",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"30701"
   },
   {
      "id":"3070101",
      "phuongxa":"Phường Him Lam",
      "quanhuyen":"30701"
   },
   {
      "id":"3070103",
      "phuongxa":"Phường Thanh Bình",
      "quanhuyen":"30701"
   },
   {
      "id":"3070105",
      "phuongxa":"Phường Tân Thanh",
      "quanhuyen":"30701"
   },
   {
      "id":"3070107",
      "phuongxa":"Phường Mường Thanh",
      "quanhuyen":"30701"
   },
   {
      "id":"3070109",
      "phuongxa":"Phường Nam Thanh",
      "quanhuyen":"30701"
   },
   {
      "id":"3070111",
      "phuongxa":"Phường Thanh Trường",
      "quanhuyen":"30701"
   },
   {
      "id":"3070113",
      "phuongxa":"Xã Thanh Minh",
      "quanhuyen":"30701"
   },
   {
      "id":"3070115",
      "phuongxa":"Phường Noọng Bua",
      "quanhuyen":"30701"
   },
   {
      "id":"3070500",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"30705"
   },
   {
      "id":"3070501",
      "phuongxa":"Xã Sín Thầu",
      "quanhuyen":"30705"
   },
   {
      "id":"3070503",
      "phuongxa":"Xã Chung Chải",
      "quanhuyen":"30705"
   },
   {
      "id":"3070505",
      "phuongxa":"Xã Mường Nhé",
      "quanhuyen":"30705"
   },
   {
      "id":"3070507",
      "phuongxa":"Xã Mường Toong",
      "quanhuyen":"30705"
   },
   {
      "id":"3070511",
      "phuongxa":"Xã Nà Hỳ",
      "quanhuyen":"30705"
   },
   {
      "id":"3070700",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"30707"
   },
   {
      "id":"3070701",
      "phuongxa":"Thị Trấn Mường Lay",
      "quanhuyen":"30707"
   },
   {
      "id":"3070703",
      "phuongxa":"Xã Chà Tở",
      "quanhuyen":"30707"
   },
   {
      "id":"3070705",
      "phuongxa":"Xã Xá Tổng",
      "quanhuyen":"30707"
   },
   {
      "id":"3070707",
      "phuongxa":"Xã Mường Tùng",
      "quanhuyen":"30707"
   },
   {
      "id":"3070709",
      "phuongxa":"Xã Lay Nưa",
      "quanhuyen":"30707"
   },
   {
      "id":"3070711",
      "phuongxa":"Xã Chà Nưa",
      "quanhuyen":"30707"
   },
   {
      "id":"3070715",
      "phuongxa":"Xã Pa Hang",
      "quanhuyen":"30707"
   },
   {
      "id":"3070717",
      "phuongxa":"Xã Thuổi Lèng",
      "quanhuyen":"30707"
   },
   {
      "id":"3070719",
      "phuongxa":"Xã Si Pa Phìn",
      "quanhuyen":"30707"
   },
   {
      "id":"3070721",
      "phuongxa":"Xã Mường Mươn",
      "quanhuyen":"30707"
   },
   {
      "id":"3070900",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"30709"
   },
   {
      "id":"3070903",
      "phuongxa":"Xã Huổi Só",
      "quanhuyen":"30709"
   },
   {
      "id":"3070905",
      "phuongxa":"Xã Xin Chải",
      "quanhuyen":"30709"
   },
   {
      "id":"3070907",
      "phuongxa":"Xã Tả Sin Thàng",
      "quanhuyen":"30709"
   },
   {
      "id":"3070909",
      "phuongxa":"Xã Lao Xả Phình",
      "quanhuyen":"30709"
   },
   {
      "id":"3070911",
      "phuongxa":"Xã Tả Phìn",
      "quanhuyen":"30709"
   },
   {
      "id":"3070913",
      "phuongxa":"Xã Tủa Thàng",
      "quanhuyen":"30709"
   },
   {
      "id":"3070919",
      "phuongxa":"Xã Sáng Nhè",
      "quanhuyen":"30709"
   },
   {
      "id":"3070921",
      "phuongxa":"Xã Mường Đun",
      "quanhuyen":"30709"
   },
   {
      "id":"3070923",
      "phuongxa":"Xã Mường Báng",
      "quanhuyen":"30709"
   },
   {
      "id":"3071100",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"30711"
   },
   {
      "id":"3071103",
      "phuongxa":"Thị Trấn Mường Ẳng",
      "quanhuyen":"30711"
   },
   {
      "id":"3071105",
      "phuongxa":"Xã Phình Sáng",
      "quanhuyen":"30711"
   },
   {
      "id":"3071107",
      "phuongxa":"Xã Mùn Chung",
      "quanhuyen":"30711"
   },
   {
      "id":"3071109",
      "phuongxa":"Xã Ta Ma",
      "quanhuyen":"30711"
   },
   {
      "id":"3071111",
      "phuongxa":"Xã Mường Mùn",
      "quanhuyen":"30711"
   },
   {
      "id":"3071113",
      "phuongxa":"Xã Pú Nhung",
      "quanhuyen":"30711"
   },
   {
      "id":"3071115",
      "phuongxa":"Xã Quài Nưa",
      "quanhuyen":"30711"
   },
   {
      "id":"3071117",
      "phuongxa":"Xã Mường Thín",
      "quanhuyen":"30711"
   },
   {
      "id":"3071119",
      "phuongxa":"Xã Tỏa Tình",
      "quanhuyen":"30711"
   },
   {
      "id":"3071121",
      "phuongxa":"Xã Nà Sáy",
      "quanhuyen":"30711"
   },
   {
      "id":"3071123",
      "phuongxa":"Xã Mường Đăng",
      "quanhuyen":"30711"
   },
   {
      "id":"3071125",
      "phuongxa":"Xã Quài Cang",
      "quanhuyen":"30711"
   },
   {
      "id":"3071127",
      "phuongxa":"Xã Ẳng Tở",
      "quanhuyen":"30711"
   },
   {
      "id":"3071129",
      "phuongxa":"Xã Chiềng Sinh",
      "quanhuyen":"30711"
   },
   {
      "id":"3071131",
      "phuongxa":"Xã Búng Lao",
      "quanhuyen":"30711"
   },
   {
      "id":"3071133",
      "phuongxa":"Xã Tênh Phông",
      "quanhuyen":"30711"
   },
   {
      "id":"3071137",
      "phuongxa":"Xã Ẳng Cang",
      "quanhuyen":"30711"
   },
   {
      "id":"3071139",
      "phuongxa":"Xã Mường Lạn",
      "quanhuyen":"30711"
   },
   {
      "id":"3071141",
      "phuongxa":"Xã Mường Lạn",
      "quanhuyen":"30711"
   },
   {
      "id":"3071300",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"30713"
   },
   {
      "id":"3071301",
      "phuongxa":"Xã Nà Tấu",
      "quanhuyen":"30713"
   },
   {
      "id":"3071303",
      "phuongxa":"Xã Mường Pồn",
      "quanhuyen":"30713"
   },
   {
      "id":"3071305",
      "phuongxa":"Xã Thanh Luông",
      "quanhuyen":"30713"
   },
   {
      "id":"3071307",
      "phuongxa":"Xã Thanh Hưng",
      "quanhuyen":"30713"
   },
   {
      "id":"3071309",
      "phuongxa":"Xã Thanh Xương",
      "quanhuyen":"30713"
   },
   {
      "id":"307131",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"30713"
   },
   {
      "id":"3071311",
      "phuongxa":"Xã Thanh Chăn",
      "quanhuyen":"30713"
   },
   {
      "id":"3071313",
      "phuongxa":"Xã Pa Thơm",
      "quanhuyen":"30713"
   },
   {
      "id":"3071315",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"30713"
   },
   {
      "id":"3071317",
      "phuongxa":"Xã Thanh Yên",
      "quanhuyen":"30713"
   },
   {
      "id":"3071319",
      "phuongxa":"Xã Noong Luống",
      "quanhuyen":"30713"
   },
   {
      "id":"3071321",
      "phuongxa":"Xã Noọng Hẹt",
      "quanhuyen":"30713"
   },
   {
      "id":"3071323",
      "phuongxa":"Xã Sang Mứn",
      "quanhuyen":"30713"
   },
   {
      "id":"3071325",
      "phuongxa":"Xã Núa Ngam",
      "quanhuyen":"30713"
   },
   {
      "id":"3071327",
      "phuongxa":"Xã Na Ư",
      "quanhuyen":"30713"
   },
   {
      "id":"3071331",
      "phuongxa":"Xã Mường Lôi",
      "quanhuyen":"30713"
   },
   {
      "id":"3071333",
      "phuongxa":"Xã Mường Nhà",
      "quanhuyen":"30713"
   },
   {
      "id":"3071335",
      "phuongxa":"Xã Mường Lói",
      "quanhuyen":"30713"
   },
   {
      "id":"3071500",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"30715"
   },
   {
      "id":"3071501",
      "phuongxa":"Xã Pú Nhi",
      "quanhuyen":"30715"
   },
   {
      "id":"3071503",
      "phuongxa":"Xã Xa Dung",
      "quanhuyen":"30715"
   },
   {
      "id":"3071505",
      "phuongxa":"Xã Keo Lôm",
      "quanhuyen":"30715"
   },
   {
      "id":"3071507",
      "phuongxa":"Xã Luân Giới",
      "quanhuyen":"30715"
   },
   {
      "id":"3071509",
      "phuongxa":"Xã Phình Giàng",
      "quanhuyen":"30715"
   },
   {
      "id":"3071511",
      "phuongxa":"Xã Háng Lìa",
      "quanhuyen":"30715"
   },
   {
      "id":"3071513",
      "phuongxa":"Xã Na Son",
      "quanhuyen":"30715"
   },
   {
      "id":"3071515",
      "phuongxa":"Xã Phì Nhừ",
      "quanhuyen":"30715"
   },
   {
      "id":"3071517",
      "phuongxa":"Xã Chiềng Sơ",
      "quanhuyen":"30715"
   },
   {
      "id":"3071519",
      "phuongxa":"Xã Mường Luân",
      "quanhuyen":"30715"
   },
   {
      "id":"3071700",
      "phuongxa":"Không xác định",
      "quanhuyen":"30717"
   },
   {
      "id":"3071701",
      "phuongxa":"Xã ẳng Cang",
      "quanhuyen":"30717"
   },
   {
      "id":"3071703",
      "phuongxa":"Xã ẳng Nưa",
      "quanhuyen":"30717"
   },
   {
      "id":"3071705",
      "phuongxa":"Xã ẳng Tở",
      "quanhuyen":"30717"
   },
   {
      "id":"3071707",
      "phuongxa":"Xã Búng Lao",
      "quanhuyen":"30717"
   },
   {
      "id":"3071711",
      "phuongxa":"Xã Mường Đăng",
      "quanhuyen":"30717"
   },
   {
      "id":"3071713",
      "phuongxa":"Xã Xuân Lao",
      "quanhuyen":"30717"
   },
   {
      "id":"3071715",
      "phuongxa":"Xã Nặm Lịch",
      "quanhuyen":"30717"
   },
   {
      "id":"3071717",
      "phuongxa":"Xã Ngối Cáy",
      "quanhuyen":"30717"
   },
   {
      "id":"3071900",
      "phuongxa":"Không xác định",
      "quanhuyen":"30719"
   },
   {
      "id":"3071901",
      "phuongxa":"Thị trấn Mường Chà",
      "quanhuyen":"30719"
   },
   {
      "id":"3071903",
      "phuongxa":"Xã Chà Tở",
      "quanhuyen":"30719"
   },
   {
      "id":"3071905",
      "phuongxa":"Xã Xá Tổng",
      "quanhuyen":"30719"
   },
   {
      "id":"3071907",
      "phuongxa":"Xã Mường Tùng",
      "quanhuyen":"30719"
   },
   {
      "id":"3071909",
      "phuongxa":"Xã Lay Nưa",
      "quanhuyen":"30719"
   },
   {
      "id":"3071913",
      "phuongxa":"Xã Hừa Ngài",
      "quanhuyen":"30719"
   },
   {
      "id":"3071915",
      "phuongxa":"Xã Pa Ham",
      "quanhuyen":"30719"
   },
   {
      "id":"3071917",
      "phuongxa":"Xã Huổi Lèng",
      "quanhuyen":"30719"
   },
   {
      "id":"3071919",
      "phuongxa":"Xã Si Pa Phìn",
      "quanhuyen":"30719"
   },
   {
      "id":"3071921",
      "phuongxa":"Xã Mường Mươn",
      "quanhuyen":"30719"
   },
   {
      "id":"3071925",
      "phuongxa":"Xã Sa Lông",
      "quanhuyen":"30719"
   },
   {
      "id":"3071927",
      "phuongxa":"Xã Ma Thì Hồ",
      "quanhuyen":"30719"
   },
   {
      "id":"3071929",
      "phuongxa":"Xã Phìn Hồ",
      "quanhuyen":"30719"
   },
   {
      "id":"3071931",
      "phuongxa":"Xã Nậm Khăn",
      "quanhuyen":"30719"
   },
   {
      "id":"4010000",
      "phuongxa":"Không xác định",
      "quanhuyen":"40100"
   },
   {
      "id":"4010103",
      "phuongxa":"Phường Nam Ngạn",
      "quanhuyen":"40101"
   },
   {
      "id":"4010105",
      "phuongxa":"Phường Trường Thi",
      "quanhuyen":"40101"
   },
   {
      "id":"4010107",
      "phuongxa":"Phường Đông Sơn",
      "quanhuyen":"40101"
   },
   {
      "id":"4010109",
      "phuongxa":"Phường Điện Biên",
      "quanhuyen":"40101"
   },
   {
      "id":"4010111",
      "phuongxa":"Phường Lam Sơn",
      "quanhuyen":"40101"
   },
   {
      "id":"4010113",
      "phuongxa":"Phường Ba Đình",
      "quanhuyen":"40101"
   },
   {
      "id":"4010115",
      "phuongxa":"Phường Ngọc Trạo",
      "quanhuyen":"40101"
   },
   {
      "id":"4010117",
      "phuongxa":"Phường Phú Sơn",
      "quanhuyen":"40101"
   },
   {
      "id":"4010119",
      "phuongxa":"Phường Đông Vệ",
      "quanhuyen":"40101"
   },
   {
      "id":"4010121",
      "phuongxa":"Phường Đông Thọ",
      "quanhuyen":"40101"
   },
   {
      "id":"4010123",
      "phuongxa":"Xã Đông Hương",
      "quanhuyen":"40101"
   },
   {
      "id":"4010125",
      "phuongxa":"Xã Đông Hải",
      "quanhuyen":"40101"
   },
   {
      "id":"4010127",
      "phuongxa":"Xã Quảng Thắng",
      "quanhuyen":"40101"
   },
   {
      "id":"4010129",
      "phuongxa":"Xã Đông Cương",
      "quanhuyen":"40101"
   },
   {
      "id":"4010133",
      "phuongxa":"Xã Quảng Hưng",
      "quanhuyen":"40101"
   },
   {
      "id":"4010300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40103"
   },
   {
      "id":"4010301",
      "phuongxa":"Phường Bắc Sơn",
      "quanhuyen":"40103"
   },
   {
      "id":"4010303",
      "phuongxa":"Phường Ngọc Trạo",
      "quanhuyen":"40103"
   },
   {
      "id":"4010305",
      "phuongxa":"Phường Ba Đình",
      "quanhuyen":"40103"
   },
   {
      "id":"4010307",
      "phuongxa":"Phường Lam Sơn",
      "quanhuyen":"40103"
   },
   {
      "id":"4010309",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"40103"
   },
   {
      "id":"4010311",
      "phuongxa":"Xã Hà Lan",
      "quanhuyen":"40103"
   },
   {
      "id":"3031511",
      "phuongxa":"Xã Mường Lầm",
      "quanhuyen":"30315"
   },
   {
      "id":"3031513",
      "phuongxa":"Xã Nậm Ty",
      "quanhuyen":"30315"
   },
   {
      "id":"3031515",
      "phuongxa":"Xã Đứa Mòn",
      "quanhuyen":"30315"
   },
   {
      "id":"3031517",
      "phuongxa":"Xã Yên Hưng",
      "quanhuyen":"30315"
   },
   {
      "id":"3031519",
      "phuongxa":"Xã Chiềng Sơ",
      "quanhuyen":"30315"
   },
   {
      "id":"3031521",
      "phuongxa":"Xã Nà Ngựu",
      "quanhuyen":"30315"
   },
   {
      "id":"3031523",
      "phuongxa":"Xã Nậm Mằn",
      "quanhuyen":"30315"
   },
   {
      "id":"3031525",
      "phuongxa":"Xã Chiềng Khoong",
      "quanhuyen":"30315"
   },
   {
      "id":"3031527",
      "phuongxa":"Xã Chiềng Cang",
      "quanhuyen":"30315"
   },
   {
      "id":"3031529",
      "phuongxa":"Xã Sam Kha",
      "quanhuyen":"30315"
   },
   {
      "id":"3031531",
      "phuongxa":"Xã Púng Bánh",
      "quanhuyen":"30315"
   },
   {
      "id":"3031533",
      "phuongxa":"Xã Huổi Một",
      "quanhuyen":"30315"
   },
   {
      "id":"3031535",
      "phuongxa":"Xã Mường Sai",
      "quanhuyen":"30315"
   },
   {
      "id":"3031539",
      "phuongxa":"Xã Xốp Cộp",
      "quanhuyen":"30315"
   },
   {
      "id":"3031541",
      "phuongxa":"Xã Mường Cai",
      "quanhuyen":"30315"
   },
   {
      "id":"3031543",
      "phuongxa":"Xã Mường Hung",
      "quanhuyen":"30315"
   },
   {
      "id":"3031545",
      "phuongxa":"Xã Mường Lèo",
      "quanhuyen":"30315"
   },
   {
      "id":"3031547",
      "phuongxa":"Xã Chiềng Khương",
      "quanhuyen":"30315"
   },
   {
      "id":"3031549",
      "phuongxa":"Xã Nậm Lạnh",
      "quanhuyen":"30315"
   },
   {
      "id":"3031551",
      "phuongxa":"Xã Mường Và",
      "quanhuyen":"30315"
   },
   {
      "id":"3031553",
      "phuongxa":"Xã Mường Lạn",
      "quanhuyen":"30315"
   },
   {
      "id":"3031700",
      "phuongxa":"Không xác định",
      "quanhuyen":"30317"
   },
   {
      "id":"3031701",
      "phuongxa":"Thị trấn Yên Châu",
      "quanhuyen":"30317"
   },
   {
      "id":"3031703",
      "phuongxa":"Xã Chiềng Đông",
      "quanhuyen":"30317"
   },
   {
      "id":"3031705",
      "phuongxa":"Xã Sập Vạt",
      "quanhuyen":"30317"
   },
   {
      "id":"3031707",
      "phuongxa":"Xã Chiềng Sàng",
      "quanhuyen":"30317"
   },
   {
      "id":"3031709",
      "phuongxa":"Xã Chiềng Pằn",
      "quanhuyen":"30317"
   },
   {
      "id":"3031711",
      "phuongxa":"Xã Viêng Lán",
      "quanhuyen":"30317"
   },
   {
      "id":"3031713",
      "phuongxa":"Xã Chiềng Hặc",
      "quanhuyen":"30317"
   },
   {
      "id":"3031717",
      "phuongxa":"Xã Chiềng On",
      "quanhuyen":"30317"
   },
   {
      "id":"3031719",
      "phuongxa":"Xã Chiềng Khoi",
      "quanhuyen":"30317"
   },
   {
      "id":"3031721",
      "phuongxa":"Xã Tú Nang",
      "quanhuyen":"30317"
   },
   {
      "id":"3031725",
      "phuongxa":"Xã Phiêng Khoài",
      "quanhuyen":"30317"
   },
   {
      "id":"3031729",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"30317"
   },
   {
      "id":"3031900",
      "phuongxa":"Không xác định",
      "quanhuyen":"30319"
   },
   {
      "id":"3031901",
      "phuongxa":"Thị trấn Mộc Châu",
      "quanhuyen":"30319"
   },
   {
      "id":"3031903",
      "phuongxa":"Thị trấn NT Mộc Châu",
      "quanhuyen":"30319"
   },
   {
      "id":"3031907",
      "phuongxa":"Xã Tân Hợp",
      "quanhuyen":"30319"
   },
   {
      "id":"3031909",
      "phuongxa":"Xã Qui Hướng",
      "quanhuyen":"30319"
   },
   {
      "id":"3031911",
      "phuongxa":"Xã Suối Bàng",
      "quanhuyen":"30319"
   },
   {
      "id":"3031913",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"30319"
   },
   {
      "id":"3031915",
      "phuongxa":"Xã Nà Mường",
      "quanhuyen":"30319"
   },
   {
      "id":"3031917",
      "phuongxa":"Xã Song Khủa",
      "quanhuyen":"30319"
   },
   {
      "id":"3031919",
      "phuongxa":"Xã Chiềng Hắc",
      "quanhuyen":"30319"
   },
   {
      "id":"3031921",
      "phuongxa":"Xã Hua Păng",
      "quanhuyen":"30319"
   },
   {
      "id":"3031925",
      "phuongxa":"Xã Mường Tè",
      "quanhuyen":"30319"
   },
   {
      "id":"3031927",
      "phuongxa":"Xã Chiềng Khừa",
      "quanhuyen":"30319"
   },
   {
      "id":"3031929",
      "phuongxa":"Xã Mường Sang",
      "quanhuyen":"30319"
   },
   {
      "id":"3031931",
      "phuongxa":"Xã Phiêng Luông",
      "quanhuyen":"30319"
   },
   {
      "id":"3031933",
      "phuongxa":"Xã Chiềng Khoa",
      "quanhuyen":"30319"
   },
   {
      "id":"3031935",
      "phuongxa":"Xã Mường Men",
      "quanhuyen":"30319"
   },
   {
      "id":"3031939",
      "phuongxa":"Xã Lóng Sập",
      "quanhuyen":"30319"
   },
   {
      "id":"3031941",
      "phuongxa":"Xã Vân Hồ",
      "quanhuyen":"30319"
   },
   {
      "id":"3031943",
      "phuongxa":"Xã Lóng Luông",
      "quanhuyen":"30319"
   },
   {
      "id":"3031945",
      "phuongxa":"Xã Chiềng Yên",
      "quanhuyen":"30319"
   },
   {
      "id":"3031947",
      "phuongxa":"Xã Xuân Nha",
      "quanhuyen":"30319"
   },
   {
      "id":"3031949",
      "phuongxa":"Xã Đông Sang",
      "quanhuyen":"30319"
   },
   {
      "id":"3031951",
      "phuongxa":"Xã Tà Lai",
      "quanhuyen":"30319"
   },
   {
      "id":"3031953",
      "phuongxa":"Xã Liên Hòa",
      "quanhuyen":"30319"
   },
   {
      "id":"3032100",
      "phuongxa":"Không xác định",
      "quanhuyen":"30321"
   },
   {
      "id":"3032101",
      "phuongxa":"Xã Sam Kha",
      "quanhuyen":"30321"
   },
   {
      "id":"3032103",
      "phuongxa":"Xã Púng Bánh",
      "quanhuyen":"30321"
   },
   {
      "id":"3032105",
      "phuongxa":"Xã Xốp Cộp",
      "quanhuyen":"30321"
   },
   {
      "id":"3032107",
      "phuongxa":"Xã Dồm Cang",
      "quanhuyen":"30321"
   },
   {
      "id":"3032109",
      "phuongxa":"Xã Nậm Lạnh",
      "quanhuyen":"30321"
   },
   {
      "id":"3032111",
      "phuongxa":"Xã Mường Lèo",
      "quanhuyen":"30321"
   },
   {
      "id":"3032113",
      "phuongxa":"Xã Mường Và",
      "quanhuyen":"30321"
   },
   {
      "id":"3032115",
      "phuongxa":"Xã Mường Lạn",
      "quanhuyen":"30321"
   },
   {
      "id":"3050000",
      "phuongxa":"Không xác định",
      "quanhuyen":"30500"
   },
   {
      "id":"4014937",
      "phuongxa":"Xã Quảng Trung",
      "quanhuyen":"40149"
   },
   {
      "id":"4014939",
      "phuongxa":"Xã Quảng Chính",
      "quanhuyen":"40149"
   },
   {
      "id":"4014943",
      "phuongxa":"Xã Quảng Trường",
      "quanhuyen":"40149"
   },
   {
      "id":"4014945",
      "phuongxa":"Xã Quảng Phúc",
      "quanhuyen":"40149"
   },
   {
      "id":"4014947",
      "phuongxa":"Xã Quảng Cát",
      "quanhuyen":"40149"
   },
   {
      "id":"4014949",
      "phuongxa":"Xã Quảng Vọng",
      "quanhuyen":"40149"
   },
   {
      "id":"4014951",
      "phuongxa":"Xã Quảng Minh",
      "quanhuyen":"40149"
   },
   {
      "id":"4014953",
      "phuongxa":"Xã Quảng Hùng",
      "quanhuyen":"40149"
   },
   {
      "id":"4014955",
      "phuongxa":"Xã Quảng Giao",
      "quanhuyen":"40149"
   },
   {
      "id":"4014957",
      "phuongxa":"Xã Quảng Phú",
      "quanhuyen":"40149"
   },
   {
      "id":"4014959",
      "phuongxa":"Xã Quảng Tâm",
      "quanhuyen":"40149"
   },
   {
      "id":"4014961",
      "phuongxa":"Phường Quảng Thọ",
      "quanhuyen":"40105"
   },
   {
      "id":"4014963",
      "phuongxa":"Phường Quảng Châu",
      "quanhuyen":"40105"
   },
   {
      "id":"4014965",
      "phuongxa":"Phường Quảng Vinh",
      "quanhuyen":"40105"
   },
   {
      "id":"4014967",
      "phuongxa":"Xã Quảng Đại",
      "quanhuyen":"40149"
   },
   {
      "id":"4014969",
      "phuongxa":"Xã Quảng Hải",
      "quanhuyen":"40149"
   },
   {
      "id":"4014971",
      "phuongxa":"Xã Quảng Lưu",
      "quanhuyen":"40149"
   },
   {
      "id":"4014973",
      "phuongxa":"Xã Quảng Lộc",
      "quanhuyen":"40149"
   },
   {
      "id":"4014975",
      "phuongxa":"Xã Quảng Lợi",
      "quanhuyen":"40149"
   },
   {
      "id":"4014977",
      "phuongxa":"Xã Quảng Nham",
      "quanhuyen":"40149"
   },
   {
      "id":"4014979",
      "phuongxa":"Xã Quảng Thạch",
      "quanhuyen":"40149"
   },
   {
      "id":"4014981",
      "phuongxa":"Xã Quảng Thái",
      "quanhuyen":"40149"
   },
   {
      "id":"4015100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40151"
   },
   {
      "id":"4015103",
      "phuongxa":"Thị trấn Yên Mỹ",
      "quanhuyen":"40151"
   },
   {
      "id":"4015105",
      "phuongxa":"Xã Tân Phúc",
      "quanhuyen":"40151"
   },
   {
      "id":"4015107",
      "phuongxa":"Xã Tân Thọ",
      "quanhuyen":"40151"
   },
   {
      "id":"4015111",
      "phuongxa":"Xã Trung Chính",
      "quanhuyen":"40151"
   },
   {
      "id":"4015113",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"40151"
   },
   {
      "id":"4015115",
      "phuongxa":"Xã Trung ý",
      "quanhuyen":"40151"
   },
   {
      "id":"4015117",
      "phuongxa":"Xã Hoàng Giang",
      "quanhuyen":"40151"
   },
   {
      "id":"4015119",
      "phuongxa":"Xã Hoàng Sơn",
      "quanhuyen":"40151"
   },
   {
      "id":"4015123",
      "phuongxa":"Xã Tế Tân",
      "quanhuyen":"40151"
   },
   {
      "id":"4015125",
      "phuongxa":"Xã Tế Lợi",
      "quanhuyen":"40151"
   },
   {
      "id":"4015127",
      "phuongxa":"Xã Tế Thắng",
      "quanhuyen":"40151"
   },
   {
      "id":"4015129",
      "phuongxa":"Xã Minh Khôi",
      "quanhuyen":"40151"
   },
   {
      "id":"4015131",
      "phuongxa":"Xã Minh Nghĩa",
      "quanhuyen":"40151"
   },
   {
      "id":"4015135",
      "phuongxa":"Xã Vạn Thắng",
      "quanhuyen":"40151"
   },
   {
      "id":"4015137",
      "phuongxa":"Xã Vạn Hòa",
      "quanhuyen":"40151"
   },
   {
      "id":"4015139",
      "phuongxa":"Xã Vạn Thiện",
      "quanhuyen":"40151"
   },
   {
      "id":"4015141",
      "phuongxa":"Xã Thăng Long",
      "quanhuyen":"40151"
   },
   {
      "id":"4015143",
      "phuongxa":"Xã Thăng Thọ",
      "quanhuyen":"40151"
   },
   {
      "id":"4015147",
      "phuongxa":"Xã Công Liêm",
      "quanhuyen":"40151"
   },
   {
      "id":"4015149",
      "phuongxa":"Xã Công Chính",
      "quanhuyen":"40151"
   },
   {
      "id":"4015151",
      "phuongxa":"Xã Công Bình",
      "quanhuyen":"40151"
   },
   {
      "id":"4015153",
      "phuongxa":"Xã Tượng Văn",
      "quanhuyen":"40151"
   },
   {
      "id":"4015155",
      "phuongxa":"Xã Tượng Lĩnh",
      "quanhuyen":"40151"
   },
   {
      "id":"4015157",
      "phuongxa":"Xã Tượng Sơn",
      "quanhuyen":"40151"
   },
   {
      "id":"4015159",
      "phuongxa":"Xã Trường Sơn",
      "quanhuyen":"40151"
   },
   {
      "id":"4015161",
      "phuongxa":"Xã Trường Giang",
      "quanhuyen":"40151"
   },
   {
      "id":"4015163",
      "phuongxa":"Xã Trừơng Trung",
      "quanhuyen":"40151"
   },
   {
      "id":"4015165",
      "phuongxa":"Xã Trường Minh",
      "quanhuyen":"40151"
   },
   {
      "id":"4015300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40153"
   },
   {
      "id":"4015301",
      "phuongxa":"Thị trấn Tĩnh Gia",
      "quanhuyen":"40153"
   },
   {
      "id":"4015303",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"40153"
   },
   {
      "id":"4015305",
      "phuongxa":"Xã Thanh Thủy",
      "quanhuyen":"40153"
   },
   {
      "id":"4015307",
      "phuongxa":"Xã Triêu Dương",
      "quanhuyen":"40153"
   },
   {
      "id":"4015309",
      "phuongxa":"Xã Ngọc Lĩnh",
      "quanhuyen":"40153"
   },
   {
      "id":"4015311",
      "phuongxa":"Xã Các Sơn",
      "quanhuyen":"40153"
   },
   {
      "id":"4015313",
      "phuongxa":"Xã Anh Sơn",
      "quanhuyen":"40153"
   },
   {
      "id":"4015315",
      "phuongxa":"Xã Hùng Sơn",
      "quanhuyen":"40153"
   },
   {
      "id":"4015317",
      "phuongxa":"Xã Định Hải",
      "quanhuyen":"40153"
   },
   {
      "id":"4015319",
      "phuongxa":"Xã Hải Nhân",
      "quanhuyen":"40153"
   },
   {
      "id":"4015321",
      "phuongxa":"Xã Nguyên Bình",
      "quanhuyen":"40153"
   },
   {
      "id":"4015323",
      "phuongxa":"Xã Xuân Lâm",
      "quanhuyen":"40153"
   },
   {
      "id":"4015327",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"40153"
   },
   {
      "id":"4015329",
      "phuongxa":"Xã Trúc Lâm",
      "quanhuyen":"40153"
   },
   {
      "id":"4015331",
      "phuongxa":"Xã Tùng Lâm",
      "quanhuyen":"40153"
   },
   {
      "id":"4015333",
      "phuongxa":"Xã Tân Trường",
      "quanhuyen":"40153"
   },
   {
      "id":"4015335",
      "phuongxa":"Xã Trường Lâm",
      "quanhuyen":"40153"
   },
   {
      "id":"4015337",
      "phuongxa":"Xã Hải Châu",
      "quanhuyen":"40153"
   },
   {
      "id":"4015339",
      "phuongxa":"Xã Hải Ninh",
      "quanhuyen":"40153"
   },
   {
      "id":"4015341",
      "phuongxa":"Xã Hải An",
      "quanhuyen":"40153"
   },
   {
      "id":"4015343",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"40153"
   },
   {
      "id":"4015345",
      "phuongxa":"Xã Hải Lĩnh",
      "quanhuyen":"40153"
   },
   {
      "id":"4015347",
      "phuongxa":"Xã Ninh Hải",
      "quanhuyen":"40153"
   },
   {
      "id":"4015349",
      "phuongxa":"Xã Hải Hòa",
      "quanhuyen":"40153"
   },
   {
      "id":"4015351",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"40153"
   },
   {
      "id":"4015353",
      "phuongxa":"Xã Hải Thanh",
      "quanhuyen":"40153"
   },
   {
      "id":"4015355",
      "phuongxa":"Xã Hải Bình",
      "quanhuyen":"40153"
   },
   {
      "id":"4015357",
      "phuongxa":"Xã Tĩnh Hải",
      "quanhuyen":"40153"
   },
   {
      "id":"4015359",
      "phuongxa":"Xã Hải Yến",
      "quanhuyen":"40153"
   },
   {
      "id":"4015361",
      "phuongxa":"Xã Mai Lâm",
      "quanhuyen":"40153"
   },
   {
      "id":"4015365",
      "phuongxa":"Xã Hải Hà",
      "quanhuyen":"40153"
   },
   {
      "id":"4015367",
      "phuongxa":"Xã Nghi Sơn",
      "quanhuyen":"40153"
   },
   {
      "id":"4030000",
      "phuongxa":"Không xác định",
      "quanhuyen":"40300"
   },
   {
      "id":"4030100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40301"
   },
   {
      "id":"4030101",
      "phuongxa":"Phường Lê Mao",
      "quanhuyen":"40301"
   },
   {
      "id":"4030103",
      "phuongxa":"Phường Hà Huy Tập",
      "quanhuyen":"40301"
   },
   {
      "id":"4030105",
      "phuongxa":"Phường Đội Cung",
      "quanhuyen":"40301"
   },
   {
      "id":"4030107",
      "phuongxa":"Phường Lê Lợi",
      "quanhuyen":"40301"
   },
   {
      "id":"4030109",
      "phuongxa":"Phường Hưng Bình",
      "quanhuyen":"40301"
   },
   {
      "id":"4030111",
      "phuongxa":"Phường Cửa Nam",
      "quanhuyen":"40301"
   },
   {
      "id":"4030113",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"40301"
   },
   {
      "id":"4030117",
      "phuongxa":"Phường Hồng Sơn",
      "quanhuyen":"40301"
   },
   {
      "id":"4030119",
      "phuongxa":"Phường Trung Đô",
      "quanhuyen":"40301"
   },
   {
      "id":"4030121",
      "phuongxa":"Phường Bến Thủy",
      "quanhuyen":"40301"
   },
   {
      "id":"4030123",
      "phuongxa":"Phường Đông Vĩnh",
      "quanhuyen":"40301"
   },
   {
      "id":"4030125",
      "phuongxa":"Phường Hưng Dũng",
      "quanhuyen":"40301"
   },
   {
      "id":"4030129",
      "phuongxa":"Xã Hưng Đông",
      "quanhuyen":"40301"
   },
   {
      "id":"4030131",
      "phuongxa":"Xã Hưng Lộc",
      "quanhuyen":"40301"
   },
   {
      "id":"4030133",
      "phuongxa":"Xã Hưng Hòa",
      "quanhuyen":"40301"
   },
   {
      "id":"4030135",
      "phuongxa":"Xã Vinh Tân",
      "quanhuyen":"40301"
   },
   {
      "id":"4030300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40303"
   },
   {
      "id":"4030305",
      "phuongxa":"Phường Thu Thuỷ",
      "quanhuyen":"40303"
   },
   {
      "id":"4030307",
      "phuongxa":"Phường Nghi Hoà",
      "quanhuyen":"40303"
   },
   {
      "id":"4030309",
      "phuongxa":"Phường Nghi Hải",
      "quanhuyen":"40303"
   },
   {
      "id":"4030311",
      "phuongxa":"Xã Nghi Thu",
      "quanhuyen":"40303"
   },
   {
      "id":"4030500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40305"
   },
   {
      "id":"4030501",
      "phuongxa":"Thị trấn Kim Sơn",
      "quanhuyen":"40305"
   },
   {
      "id":"4030503",
      "phuongxa":"Xã Thông Thụ",
      "quanhuyen":"40305"
   },
   {
      "id":"4030505",
      "phuongxa":"Xã Đồng Văn",
      "quanhuyen":"40305"
   },
   {
      "id":"4030507",
      "phuongxa":"Xã Hạnh Dịch",
      "quanhuyen":"40305"
   },
   {
      "id":"4030511",
      "phuongxa":"Xã Nậm Giải",
      "quanhuyen":"40305"
   },
   {
      "id":"4030513",
      "phuongxa":"Xã Châu Kim",
      "quanhuyen":"40305"
   },
   {
      "id":"4030515",
      "phuongxa":"Xã Tri Lễ",
      "quanhuyen":"40305"
   },
   {
      "id":"4030517",
      "phuongxa":"Xã Châu Thôn",
      "quanhuyen":"40305"
   },
   {
      "id":"4030519",
      "phuongxa":"Xã Mường Nọc",
      "quanhuyen":"40305"
   },
   {
      "id":"4030521",
      "phuongxa":"Xã Nậm Nhoóng",
      "quanhuyen":"40305"
   },
   {
      "id":"4030525",
      "phuongxa":"Xã Căm Muộn",
      "quanhuyen":"40305"
   },
   {
      "id":"4030700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40307"
   },
   {
      "id":"4030701",
      "phuongxa":"Thị trấn Quỳ Châu",
      "quanhuyen":"40307"
   },
   {
      "id":"4030703",
      "phuongxa":"Xã Châu Bính",
      "quanhuyen":"40307"
   },
   {
      "id":"4030705",
      "phuongxa":"Xã Châu Thuận",
      "quanhuyen":"40307"
   },
   {
      "id":"4030707",
      "phuongxa":"Xã Châu Tiến",
      "quanhuyen":"40307"
   },
   {
      "id":"4030709",
      "phuongxa":"Xã Châu Thắng",
      "quanhuyen":"40307"
   },
   {
      "id":"4030711",
      "phuongxa":"Xã Châu Hạnh",
      "quanhuyen":"40307"
   },
   {
      "id":"4030713",
      "phuongxa":"Xã Châu Hội",
      "quanhuyen":"40307"
   },
   {
      "id":"4030715",
      "phuongxa":"Xã Châu Nga",
      "quanhuyen":"40307"
   },
   {
      "id":"4030717",
      "phuongxa":"Xã Châu Phong",
      "quanhuyen":"40307"
   },
   {
      "id":"4030719",
      "phuongxa":"Xã Châu Bình",
      "quanhuyen":"40307"
   },
   {
      "id":"4030721",
      "phuongxa":"Xã Diên Lãm",
      "quanhuyen":"40307"
   },
   {
      "id":"4030723",
      "phuongxa":"Xã Châu Hoàn",
      "quanhuyen":"40307"
   },
   {
      "id":"4030900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40309"
   },
   {
      "id":"4030901",
      "phuongxa":"Thị trấn Mường Xén",
      "quanhuyen":"40309"
   },
   {
      "id":"4030903",
      "phuongxa":"Xã Keng Đu",
      "quanhuyen":"40309"
   },
   {
      "id":"4030905",
      "phuongxa":"Xã Bắc Lý",
      "quanhuyen":"40309"
   },
   {
      "id":"4012300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40123"
   },
   {
      "id":"4012301",
      "phuongxa":"Thị trấn Thường Xuân",
      "quanhuyen":"40123"
   },
   {
      "id":"4012303",
      "phuongxa":"Xã Bát Mọt",
      "quanhuyen":"40123"
   },
   {
      "id":"4012307",
      "phuongxa":"Xã Xuân Khao",
      "quanhuyen":"40123"
   },
   {
      "id":"4012309",
      "phuongxa":"Xã Xuân Liên",
      "quanhuyen":"40123"
   },
   {
      "id":"4012311",
      "phuongxa":"Xã Xuân Lẹ",
      "quanhuyen":"40123"
   },
   {
      "id":"4012313",
      "phuongxa":"Xã Vạn Xuân",
      "quanhuyen":"40123"
   },
   {
      "id":"4012315",
      "phuongxa":"Xã Xuân Mỹ",
      "quanhuyen":"40123"
   },
   {
      "id":"4012317",
      "phuongxa":"Xã Lương Sơn",
      "quanhuyen":"40123"
   },
   {
      "id":"4012319",
      "phuongxa":"Xã Xuân Cao",
      "quanhuyen":"40123"
   },
   {
      "id":"4012321",
      "phuongxa":"Xã Luận Thành",
      "quanhuyen":"40123"
   },
   {
      "id":"4012323",
      "phuongxa":"Xã Luận Khê",
      "quanhuyen":"40123"
   },
   {
      "id":"4012325",
      "phuongxa":"Xã Xuân Thắng",
      "quanhuyen":"40123"
   },
   {
      "id":"4012327",
      "phuongxa":"Xã Xuân Lộc",
      "quanhuyen":"40123"
   },
   {
      "id":"4012329",
      "phuongxa":"Xã Xuân Cẩm",
      "quanhuyen":"40123"
   },
   {
      "id":"4012331",
      "phuongxa":"Xã Xuân Dương",
      "quanhuyen":"40123"
   },
   {
      "id":"4012333",
      "phuongxa":"Xã Thọ Thanh",
      "quanhuyen":"40123"
   },
   {
      "id":"4012335",
      "phuongxa":"Xã Ngọc Phụng",
      "quanhuyen":"40123"
   },
   {
      "id":"4012337",
      "phuongxa":"Xã Xuân Chinh",
      "quanhuyen":"40123"
   },
   {
      "id":"4012339",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"40123"
   },
   {
      "id":"4012500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40125"
   },
   {
      "id":"4012501",
      "phuongxa":"Thị trấn Yên Cát",
      "quanhuyen":"40125"
   },
   {
      "id":"4012505",
      "phuongxa":"Xã Xuân Bình",
      "quanhuyen":"40125"
   },
   {
      "id":"4012507",
      "phuongxa":"Xã Hóa Quỳ",
      "quanhuyen":"40125"
   },
   {
      "id":"4012509",
      "phuongxa":"Xã Xuân Quỳ",
      "quanhuyen":"40125"
   },
   {
      "id":"4012511",
      "phuongxa":"Xã Yên Lễ",
      "quanhuyen":"40125"
   },
   {
      "id":"4012515",
      "phuongxa":"Xã Cát Tân",
      "quanhuyen":"40125"
   },
   {
      "id":"4012517",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"40125"
   },
   {
      "id":"4012519",
      "phuongxa":"Xã Bình Lương",
      "quanhuyen":"40125"
   },
   {
      "id":"4012521",
      "phuongxa":"Xã Thanh Quân",
      "quanhuyen":"40125"
   },
   {
      "id":"4012523",
      "phuongxa":"Xã Thanh Xuân",
      "quanhuyen":"40125"
   },
   {
      "id":"4012527",
      "phuongxa":"Xã Thanh Lâm",
      "quanhuyen":"40125"
   },
   {
      "id":"4012529",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"40125"
   },
   {
      "id":"4012531",
      "phuongxa":"Xã Thượng Ninh",
      "quanhuyen":"40125"
   },
   {
      "id":"4012700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40127"
   },
   {
      "id":"4012701",
      "phuongxa":"Xã Thanh Kỳ",
      "quanhuyen":"40127"
   },
   {
      "id":"4012705",
      "phuongxa":"Xã Xuân Thái",
      "quanhuyen":"40127"
   },
   {
      "id":"4012707",
      "phuongxa":"Xã Yên Lạc",
      "quanhuyen":"40127"
   },
   {
      "id":"4012709",
      "phuongxa":"Xã Yên Thọ",
      "quanhuyen":"40127"
   },
   {
      "id":"4012711",
      "phuongxa":"Xã Xuân Phúc",
      "quanhuyen":"40127"
   },
   {
      "id":"4012713",
      "phuongxa":"Xã Phúc Đường",
      "quanhuyen":"40127"
   },
   {
      "id":"4012717",
      "phuongxa":"Xã Xuân Khang",
      "quanhuyen":"40127"
   },
   {
      "id":"4012719",
      "phuongxa":"Xã Hải Long",
      "quanhuyen":"40127"
   },
   {
      "id":"4012721",
      "phuongxa":"Xã Phú Nhuận",
      "quanhuyen":"40127"
   },
   {
      "id":"4012723",
      "phuongxa":"Xã Mậu Lâm",
      "quanhuyen":"40127"
   },
   {
      "id":"4012725",
      "phuongxa":"Xã Phượng Nghi",
      "quanhuyen":"40127"
   },
   {
      "id":"4012727",
      "phuongxa":"Xã Xuân Du",
      "quanhuyen":"40127"
   },
   {
      "id":"4012729",
      "phuongxa":"Xã Cán Khê",
      "quanhuyen":"40127"
   },
   {
      "id":"4012731",
      "phuongxa":"Xã Hải Vân",
      "quanhuyen":"40127"
   },
   {
      "id":"4012900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40129"
   },
   {
      "id":"4012901",
      "phuongxa":"Thị trấn Vĩnh Lộc",
      "quanhuyen":"40129"
   },
   {
      "id":"4012903",
      "phuongxa":"Xã Vĩnh Thành",
      "quanhuyen":"40129"
   },
   {
      "id":"4012905",
      "phuongxa":"Xã Vĩnh Quang",
      "quanhuyen":"40129"
   },
   {
      "id":"4012907",
      "phuongxa":"Xã Vĩnh Yên",
      "quanhuyen":"40129"
   },
   {
      "id":"4012909",
      "phuongxa":"Xã Vĩnh Tiến",
      "quanhuyen":"40129"
   },
   {
      "id":"4012911",
      "phuongxa":"Xã Vĩnh Long",
      "quanhuyen":"40129"
   },
   {
      "id":"4012913",
      "phuongxa":"Xã Vĩnh Phúc",
      "quanhuyen":"40129"
   },
   {
      "id":"4012917",
      "phuongxa":"Xã Vĩnh Minh",
      "quanhuyen":"40129"
   },
   {
      "id":"4012919",
      "phuongxa":"Xã Vĩnh Khang",
      "quanhuyen":"40129"
   },
   {
      "id":"4012921",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"40129"
   },
   {
      "id":"4012923",
      "phuongxa":"Xã Vĩnh Hùng",
      "quanhuyen":"40129"
   },
   {
      "id":"4012925",
      "phuongxa":"Xã Vĩnh Tân",
      "quanhuyen":"40129"
   },
   {
      "id":"4012927",
      "phuongxa":"Xã Vĩnh Ninh",
      "quanhuyen":"40129"
   },
   {
      "id":"4012929",
      "phuongxa":"Xã Vĩnh Thịnh",
      "quanhuyen":"40129"
   },
   {
      "id":"4012931",
      "phuongxa":"Xã Vĩnh An",
      "quanhuyen":"40129"
   },
   {
      "id":"4013100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40131"
   },
   {
      "id":"4013101",
      "phuongxa":"Thị trấn Hà Trung",
      "quanhuyen":"40131"
   },
   {
      "id":"4013103",
      "phuongxa":"Xã Hà Long",
      "quanhuyen":"40131"
   },
   {
      "id":"4013105",
      "phuongxa":"Xã Hà Vinh",
      "quanhuyen":"40131"
   },
   {
      "id":"4013107",
      "phuongxa":"Xã Hà Bắc",
      "quanhuyen":"40131"
   },
   {
      "id":"4013109",
      "phuongxa":"Xã Hà Vân",
      "quanhuyen":"40131"
   },
   {
      "id":"4013111",
      "phuongxa":"Xã Hà Yên",
      "quanhuyen":"40131"
   },
   {
      "id":"4013113",
      "phuongxa":"Xã Hà Thanh",
      "quanhuyen":"40131"
   },
   {
      "id":"4013115",
      "phuongxa":"Xã Hà Giang",
      "quanhuyen":"40131"
   },
   {
      "id":"4013117",
      "phuongxa":"Xã Hà Dương",
      "quanhuyen":"40131"
   },
   {
      "id":"4013119",
      "phuongxa":"Xã Hà Phú",
      "quanhuyen":"40131"
   },
   {
      "id":"4013121",
      "phuongxa":"Xã Hà Phong",
      "quanhuyen":"40131"
   },
   {
      "id":"4013125",
      "phuongxa":"Xã Hà Ninh",
      "quanhuyen":"40131"
   },
   {
      "id":"4013127",
      "phuongxa":"Xã Hà Lâm",
      "quanhuyen":"40131"
   },
   {
      "id":"4013129",
      "phuongxa":"Xã Hà Sơn",
      "quanhuyen":"40131"
   },
   {
      "id":"4013131",
      "phuongxa":"Xã Hà Lĩnh",
      "quanhuyen":"40131"
   },
   {
      "id":"4013133",
      "phuongxa":"Xã Hà Đông",
      "quanhuyen":"40131"
   },
   {
      "id":"4013135",
      "phuongxa":"Xã Hà Tân",
      "quanhuyen":"40131"
   },
   {
      "id":"4013137",
      "phuongxa":"Xã Hà Tiến",
      "quanhuyen":"40131"
   },
   {
      "id":"4013139",
      "phuongxa":"Xã Hà Bình",
      "quanhuyen":"40131"
   },
   {
      "id":"4013141",
      "phuongxa":"Xã Hà Lai",
      "quanhuyen":"40131"
   },
   {
      "id":"4013143",
      "phuongxa":"Xã Hà Châu",
      "quanhuyen":"40131"
   },
   {
      "id":"4013145",
      "phuongxa":"Xã Hà Toại",
      "quanhuyen":"40131"
   },
   {
      "id":"4013147",
      "phuongxa":"Xã Hà Thái",
      "quanhuyen":"40131"
   },
   {
      "id":"4013149",
      "phuongxa":"Xã Hà Hải",
      "quanhuyen":"40131"
   },
   {
      "id":"4013300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40133"
   },
   {
      "id":"4013301",
      "phuongxa":"Thị trấn Nga Sơn",
      "quanhuyen":"40133"
   },
   {
      "id":"4013303",
      "phuongxa":"Xã Ba Đình",
      "quanhuyen":"40133"
   },
   {
      "id":"4013305",
      "phuongxa":"Xã Nga Vịnh",
      "quanhuyen":"40133"
   },
   {
      "id":"4013307",
      "phuongxa":"Xã Nga Văn",
      "quanhuyen":"40133"
   },
   {
      "id":"4013311",
      "phuongxa":"Xã Nga Tiến",
      "quanhuyen":"40133"
   },
   {
      "id":"4013313",
      "phuongxa":"Xã Nga Lĩnh",
      "quanhuyen":"40133"
   },
   {
      "id":"4013315",
      "phuongxa":"Xã Nga Nhân",
      "quanhuyen":"40133"
   },
   {
      "id":"4013317",
      "phuongxa":"Xã Nga Trung",
      "quanhuyen":"40133"
   },
   {
      "id":"4013321",
      "phuongxa":"Xã Nga Thanh",
      "quanhuyen":"40133"
   },
   {
      "id":"4013323",
      "phuongxa":"Xã Nga Hưng",
      "quanhuyen":"40133"
   },
   {
      "id":"4013325",
      "phuongxa":"Xã Nga Mỹ",
      "quanhuyen":"40133"
   },
   {
      "id":"4013327",
      "phuongxa":"Xã Nga Yên",
      "quanhuyen":"40133"
   },
   {
      "id":"4013329",
      "phuongxa":"Xã Nga Giáp",
      "quanhuyen":"40133"
   },
   {
      "id":"4013333",
      "phuongxa":"Xã Nga Thành",
      "quanhuyen":"40133"
   },
   {
      "id":"4013335",
      "phuongxa":"Xã Nga An",
      "quanhuyen":"40133"
   },
   {
      "id":"4013337",
      "phuongxa":"Xã Nga Phú",
      "quanhuyen":"40133"
   },
   {
      "id":"4013339",
      "phuongxa":"Xã Nga Điền",
      "quanhuyen":"40133"
   },
   {
      "id":"4013341",
      "phuongxa":"Xã Nga Tân",
      "quanhuyen":"40133"
   },
   {
      "id":"4013343",
      "phuongxa":"Xã Nga Thủy",
      "quanhuyen":"40133"
   },
   {
      "id":"4013347",
      "phuongxa":"Xã Nga Thái",
      "quanhuyen":"40133"
   },
   {
      "id":"4013349",
      "phuongxa":"Xã Nga Thạch",
      "quanhuyen":"40133"
   },
   {
      "id":"4013351",
      "phuongxa":"Xã Nga Thắng",
      "quanhuyen":"40133"
   },
   {
      "id":"4013353",
      "phuongxa":"Xã Nga Trường",
      "quanhuyen":"40133"
   },
   {
      "id":"4013501",
      "phuongxa":"Thị trấn Quán Lào",
      "quanhuyen":"40135"
   },
   {
      "id":"4013505",
      "phuongxa":"Xã Yên Phú",
      "quanhuyen":"40135"
   },
   {
      "id":"4013507",
      "phuongxa":"Xã Yên Lâm",
      "quanhuyen":"40135"
   },
   {
      "id":"4013509",
      "phuongxa":"Xã Yên Tâm",
      "quanhuyen":"40135"
   },
   {
      "id":"4013513",
      "phuongxa":"Xã Yên Qúi",
      "quanhuyen":"40135"
   },
   {
      "id":"4013515",
      "phuongxa":"Xã Yên Thọ",
      "quanhuyen":"40135"
   },
   {
      "id":"4013517",
      "phuongxa":"Xã Yên Trung",
      "quanhuyen":"40135"
   },
   {
      "id":"4013519",
      "phuongxa":"Xã Yên Trường",
      "quanhuyen":"40135"
   },
   {
      "id":"4013521",
      "phuongxa":"Xã Yên Bái",
      "quanhuyen":"40135"
   },
   {
      "id":"4013523",
      "phuongxa":"Xã Yên Phong",
      "quanhuyen":"40135"
   },
   {
      "id":"4013525",
      "phuongxa":"Xã Yên Thái",
      "quanhuyen":"40135"
   },
   {
      "id":"4013527",
      "phuongxa":"Xã Yên Hùng",
      "quanhuyen":"40135"
   },
   {
      "id":"4013529",
      "phuongxa":"Xã Yên Thịnh",
      "quanhuyen":"40135"
   },
   {
      "id":"4013531",
      "phuongxa":"Xã Yên Ninh",
      "quanhuyen":"40135"
   },
   {
      "id":"4013533",
      "phuongxa":"Xã Yên Lạc",
      "quanhuyen":"40135"
   },
   {
      "id":"4013535",
      "phuongxa":"Xã Định Tăng",
      "quanhuyen":"40135"
   },
   {
      "id":"4013537",
      "phuongxa":"Xã Định Hòa",
      "quanhuyen":"40135"
   },
   {
      "id":"4013539",
      "phuongxa":"Xã Định Thành",
      "quanhuyen":"40135"
   },
   {
      "id":"4013541",
      "phuongxa":"Xã Định Công",
      "quanhuyen":"40135"
   },
   {
      "id":"4013543",
      "phuongxa":"Xã Định Tân",
      "quanhuyen":"40135"
   },
   {
      "id":"4013545",
      "phuongxa":"Xã Định Tiến",
      "quanhuyen":"40135"
   },
   {
      "id":"4013547",
      "phuongxa":"Xã Định Long",
      "quanhuyen":"40135"
   },
   {
      "id":"4013549",
      "phuongxa":"Xã Định Liên",
      "quanhuyen":"40135"
   },
   {
      "id":"4013551",
      "phuongxa":"Xã Định Tường",
      "quanhuyen":"40135"
   },
   {
      "id":"4013555",
      "phuongxa":"Xã Định Hải",
      "quanhuyen":"40135"
   },
   {
      "id":"4013557",
      "phuongxa":"Xã Định Bình",
      "quanhuyen":"40135"
   },
   {
      "id":"4013700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40137"
   },
   {
      "id":"4013701",
      "phuongxa":"Thị trấn Thọ Xuân",
      "quanhuyen":"40137"
   },
   {
      "id":"4013703",
      "phuongxa":"Thị trấn Lam Sơn",
      "quanhuyen":"40137"
   },
   {
      "id":"4013705",
      "phuongxa":"Thị trấn NT Sao Vàng",
      "quanhuyen":"40137"
   },
   {
      "id":"4013707",
      "phuongxa":"Xã Xuân Khánh",
      "quanhuyen":"40137"
   },
   {
      "id":"4013709",
      "phuongxa":"Xã Thọ Nguyên",
      "quanhuyen":"40137"
   },
   {
      "id":"4013711",
      "phuongxa":"Xã Xuân Thành",
      "quanhuyen":"40137"
   },
   {
      "id":"4013713",
      "phuongxa":"Xã Hạnh Phúc",
      "quanhuyen":"40137"
   },
   {
      "id":"4013715",
      "phuongxa":"Xã Bắc Lương",
      "quanhuyen":"40137"
   },
   {
      "id":"4013717",
      "phuongxa":"Xã Nam Giang",
      "quanhuyen":"40137"
   },
   {
      "id":"4013719",
      "phuongxa":"Xã Xuân Phong",
      "quanhuyen":"40137"
   },
   {
      "id":"4013721",
      "phuongxa":"Xã Thọ Lộc",
      "quanhuyen":"40137"
   },
   {
      "id":"4013723",
      "phuongxa":"Xã Xuân Trường",
      "quanhuyen":"40137"
   },
   {
      "id":"4013725",
      "phuongxa":"Xã Xuân Hòa",
      "quanhuyen":"40137"
   },
   {
      "id":"4013727",
      "phuongxa":"Xã Thọ Hải",
      "quanhuyen":"40137"
   },
   {
      "id":"4013729",
      "phuongxa":"Xã Tây Hồ",
      "quanhuyen":"40137"
   },
   {
      "id":"4013731",
      "phuongxa":"Xã Xuân Giang",
      "quanhuyen":"40137"
   },
   {
      "id":"4013735",
      "phuongxa":"Xã Xuân Sơn",
      "quanhuyen":"40137"
   },
   {
      "id":"4013737",
      "phuongxa":"Xã Xuân Hưng",
      "quanhuyen":"40137"
   },
   {
      "id":"4013739",
      "phuongxa":"Xã Thọ Diên",
      "quanhuyen":"40137"
   },
   {
      "id":"4013741",
      "phuongxa":"Xã Thọ Lâm",
      "quanhuyen":"40137"
   },
   {
      "id":"4013743",
      "phuongxa":"Xã Thọ Xương",
      "quanhuyen":"40137"
   },
   {
      "id":"4013745",
      "phuongxa":"Xã Xuân Bái",
      "quanhuyen":"40137"
   },
   {
      "id":"4013747",
      "phuongxa":"Xã Xuân Phú",
      "quanhuyen":"40137"
   },
   {
      "id":"4013749",
      "phuongxa":"Xã Xuân Thắng",
      "quanhuyen":"40137"
   },
   {
      "id":"4013753",
      "phuongxa":"Xã Xuân Thiên",
      "quanhuyen":"40137"
   },
   {
      "id":"4013755",
      "phuongxa":"Xã Thọ Minh",
      "quanhuyen":"40137"
   },
   {
      "id":"4013757",
      "phuongxa":"Xã Xuân Châu",
      "quanhuyen":"40137"
   },
   {
      "id":"4013759",
      "phuongxa":"Xã Thọ Lập",
      "quanhuyen":"40137"
   },
   {
      "id":"4013761",
      "phuongxa":"Xã Quảng Phú",
      "quanhuyen":"40137"
   },
   {
      "id":"4013765",
      "phuongxa":"Xã Phú Yên",
      "quanhuyen":"40137"
   },
   {
      "id":"4013767",
      "phuongxa":"Xã Xuân Yên",
      "quanhuyen":"40137"
   },
   {
      "id":"4013769",
      "phuongxa":"Xã Xuân Lai",
      "quanhuyen":"40137"
   },
   {
      "id":"4013771",
      "phuongxa":"Xã Xuân Lập",
      "quanhuyen":"40137"
   },
   {
      "id":"4013773",
      "phuongxa":"Xã Thọ Thắng",
      "quanhuyen":"40137"
   },
   {
      "id":"4013777",
      "phuongxa":"Xã Xuân Tân",
      "quanhuyen":"40137"
   },
   {
      "id":"4013779",
      "phuongxa":"Xã Xuân Vinh",
      "quanhuyen":"40137"
   },
   {
      "id":"4013781",
      "phuongxa":"Xã Thọ Trường",
      "quanhuyen":"40137"
   },
   {
      "id":"4013901",
      "phuongxa":"Thị trấn Hậu Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013905",
      "phuongxa":"Xã Đại Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013907",
      "phuongxa":"Xã Triệu Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013909",
      "phuongxa":"Xã Châu Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013911",
      "phuongxa":"Xã Tiến Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013913",
      "phuongxa":"Xã Lộc Sơn",
      "quanhuyen":"40139"
   },
   {
      "id":"4013915",
      "phuongxa":"Xã Cầu Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013917",
      "phuongxa":"Xã Thành Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013919",
      "phuongxa":"Xã Tuy Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013921",
      "phuongxa":"Xã Phong Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013923",
      "phuongxa":"Xã Mỹ Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013925",
      "phuongxa":"Xã Văn Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013927",
      "phuongxa":"Xã Thuần Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013929",
      "phuongxa":"Xã Lộc Tân",
      "quanhuyen":"40139"
   },
   {
      "id":"4013931",
      "phuongxa":"Xã Xuân Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013933",
      "phuongxa":"Xã Thịnh Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013935",
      "phuongxa":"Xã Hoa Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013937",
      "phuongxa":"Xã Liên Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013939",
      "phuongxa":"Xã Quang Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013943",
      "phuongxa":"Xã Hòa Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013945",
      "phuongxa":"Xã Minh Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013947",
      "phuongxa":"Xã Hưng Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013949",
      "phuongxa":"Xã Hải Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013951",
      "phuongxa":"Xã Đa Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4013953",
      "phuongxa":"Xã Ngư Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4014100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40141"
   },
   {
      "id":"4014101",
      "phuongxa":"Xã Thiệu Ngọc",
      "quanhuyen":"40141"
   },
   {
      "id":"4014103",
      "phuongxa":"Xã Thiệu Vũ",
      "quanhuyen":"40141"
   },
   {
      "id":"4014105",
      "phuongxa":"Xã Thiệu Phúc",
      "quanhuyen":"40141"
   },
   {
      "id":"4014107",
      "phuongxa":"Xã Thiệu Tiến",
      "quanhuyen":"40141"
   },
   {
      "id":"4014109",
      "phuongxa":"Xã Thiệu Công",
      "quanhuyen":"40141"
   },
   {
      "id":"4014111",
      "phuongxa":"Xã Thiệu Phú",
      "quanhuyen":"40141"
   },
   {
      "id":"4014113",
      "phuongxa":"Xã Thiệu Hưng",
      "quanhuyen":"40141"
   },
   {
      "id":"4014115",
      "phuongxa":"Xã Thiệu Long",
      "quanhuyen":"40141"
   },
   {
      "id":"4014117",
      "phuongxa":"Xã Thiệu Giang",
      "quanhuyen":"40141"
   },
   {
      "id":"4014119",
      "phuongxa":"Xã Thiệu Duy",
      "quanhuyen":"40141"
   },
   {
      "id":"4014121",
      "phuongxa":"Xã Thiệu Nguyên",
      "quanhuyen":"40141"
   },
   {
      "id":"4014125",
      "phuongxa":"Xã Thiệu Thịnh",
      "quanhuyen":"40141"
   },
   {
      "id":"4014127",
      "phuongxa":"Xã Thiệu Quang",
      "quanhuyen":"40141"
   },
   {
      "id":"4014129",
      "phuongxa":"Xã Thiệu Thành",
      "quanhuyen":"40141"
   },
   {
      "id":"4014131",
      "phuongxa":"Xã Thiệu Toán",
      "quanhuyen":"40141"
   },
   {
      "id":"4014133",
      "phuongxa":"Xã Thiệu Chính",
      "quanhuyen":"40141"
   },
   {
      "id":"4014135",
      "phuongxa":"Xã Thiệu Hòa",
      "quanhuyen":"40141"
   },
   {
      "id":"4014137",
      "phuongxa":"Xã Thiệu Minh",
      "quanhuyen":"40141"
   },
   {
      "id":"4014139",
      "phuongxa":"Xã Thiệu Tâm",
      "quanhuyen":"40141"
   },
   {
      "id":"4014141",
      "phuongxa":"Xã Thiệu Viên",
      "quanhuyen":"40141"
   },
   {
      "id":"4014143",
      "phuongxa":"Xã Thiệu Lý",
      "quanhuyen":"40141"
   },
   {
      "id":"4014145",
      "phuongxa":"Xã Thiệu Vận",
      "quanhuyen":"40141"
   },
   {
      "id":"4014147",
      "phuongxa":"Xã Thiệu Trung",
      "quanhuyen":"40141"
   },
   {
      "id":"4014149",
      "phuongxa":"Xã Thiệu Đô",
      "quanhuyen":"40141"
   },
   {
      "id":"4014151",
      "phuongxa":"Xã Thiệu Châu",
      "quanhuyen":"40141"
   },
   {
      "id":"4014153",
      "phuongxa":"Xã Thiệu Vân",
      "quanhuyen":"40101"
   },
   {
      "id":"4014155",
      "phuongxa":"Xã Thiệu Giao",
      "quanhuyen":"40141"
   },
   {
      "id":"4014157",
      "phuongxa":"Xã Thiệu Khánh",
      "quanhuyen":"40101"
   },
   {
      "id":"4014159",
      "phuongxa":"Xã Thiệu Dương",
      "quanhuyen":"40101"
   },
   {
      "id":"4014161",
      "phuongxa":"Xã Thiệu Tân",
      "quanhuyen":"40141"
   },
   {
      "id":"4014300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40143"
   },
   {
      "id":"4014305",
      "phuongxa":"Xã Hoằng Xuân",
      "quanhuyen":"40143"
   },
   {
      "id":"4014307",
      "phuongxa":"Xã Hoằng Khánh",
      "quanhuyen":"40143"
   },
   {
      "id":"4014309",
      "phuongxa":"Xã Hoằng Phượng",
      "quanhuyen":"40143"
   },
   {
      "id":"4014311",
      "phuongxa":"Xã Hoằng Phú",
      "quanhuyen":"40143"
   },
   {
      "id":"4014313",
      "phuongxa":"Xã Hoằng Quỳ",
      "quanhuyen":"40143"
   },
   {
      "id":"4014317",
      "phuongxa":"Xã Hoằng Trung",
      "quanhuyen":"40143"
   },
   {
      "id":"4014319",
      "phuongxa":"Xã Hoằng Trinh",
      "quanhuyen":"40143"
   },
   {
      "id":"4014321",
      "phuongxa":"Xã Hoằng Sơn",
      "quanhuyen":"40143"
   },
   {
      "id":"4014323",
      "phuongxa":"Xã Hoằng Lương",
      "quanhuyen":"40143"
   },
   {
      "id":"4014325",
      "phuongxa":"Xã Hoằng Xuyên",
      "quanhuyen":"40143"
   },
   {
      "id":"4014329",
      "phuongxa":"Xã Hoằng Khê",
      "quanhuyen":"40143"
   },
   {
      "id":"4014331",
      "phuongxa":"Xã Hoằng Lý",
      "quanhuyen":"40101"
   },
   {
      "id":"4014333",
      "phuongxa":"Xã Hoằng Quý",
      "quanhuyen":"40143"
   },
   {
      "id":"4014335",
      "phuongxa":"Xã Hoằng Hợp",
      "quanhuyen":"40143"
   },
   {
      "id":"4014337",
      "phuongxa":"Xã Hoằng Long",
      "quanhuyen":"40101"
   },
   {
      "id":"4014341",
      "phuongxa":"Xã Hoằng Minh",
      "quanhuyen":"40143"
   },
   {
      "id":"4014343",
      "phuongxa":"Xã Hoằng Phúc",
      "quanhuyen":"40143"
   },
   {
      "id":"4014345",
      "phuongxa":"Xã Hoằng Đức",
      "quanhuyen":"40143"
   },
   {
      "id":"4014347",
      "phuongxa":"Xã Hoằng Hà",
      "quanhuyen":"40143"
   },
   {
      "id":"4014349",
      "phuongxa":"Xã Hoằng Đạt",
      "quanhuyen":"40143"
   },
   {
      "id":"4014351",
      "phuongxa":"Xã Hoằng Vinh",
      "quanhuyen":"40143"
   },
   {
      "id":"4014353",
      "phuongxa":"Xã Hoằng Đạo",
      "quanhuyen":"40143"
   },
   {
      "id":"4014355",
      "phuongxa":"Xã Hoằng Thắng",
      "quanhuyen":"40143"
   },
   {
      "id":"4014357",
      "phuongxa":"Xã Hoằng Đồng",
      "quanhuyen":"40143"
   },
   {
      "id":"4014359",
      "phuongxa":"Xã Hoằng Thái",
      "quanhuyen":"40143"
   },
   {
      "id":"4014361",
      "phuongxa":"Xã Hoằng Thịnh",
      "quanhuyen":"40143"
   },
   {
      "id":"4014363",
      "phuongxa":"Xã Hoằng Thành",
      "quanhuyen":"40143"
   },
   {
      "id":"4014365",
      "phuongxa":"Xã Hoằng Lộc",
      "quanhuyen":"40143"
   },
   {
      "id":"4014367",
      "phuongxa":"Xã Hoằng Trạch",
      "quanhuyen":"40143"
   },
   {
      "id":"4014369",
      "phuongxa":"Xã Hoằng Đại",
      "quanhuyen":"40101"
   },
   {
      "id":"4014371",
      "phuongxa":"Xã Hoằng Phong",
      "quanhuyen":"40143"
   },
   {
      "id":"4014373",
      "phuongxa":"Xã Hoằng Lưu",
      "quanhuyen":"40143"
   },
   {
      "id":"4014375",
      "phuongxa":"Xã Hoằng Châu",
      "quanhuyen":"40143"
   },
   {
      "id":"4014377",
      "phuongxa":"Xã Hoằng Tân",
      "quanhuyen":"40143"
   },
   {
      "id":"4014379",
      "phuongxa":"Xã Hoằng Yến",
      "quanhuyen":"40143"
   },
   {
      "id":"4014383",
      "phuongxa":"Xã Hoằng Hải",
      "quanhuyen":"40143"
   },
   {
      "id":"4014385",
      "phuongxa":"Xã Hoằng Ngọc",
      "quanhuyen":"40143"
   },
   {
      "id":"4014387",
      "phuongxa":"Xã Hoằng Đông",
      "quanhuyen":"40143"
   },
   {
      "id":"4014389",
      "phuongxa":"Xã Hoằng Thanh",
      "quanhuyen":"40143"
   },
   {
      "id":"4014391",
      "phuongxa":"Xã Hoằng Phụ",
      "quanhuyen":"40143"
   },
   {
      "id":"4014393",
      "phuongxa":"Xã Hoằng Trường",
      "quanhuyen":"40143"
   },
   {
      "id":"4014395",
      "phuongxa":"Xã Hoằng Anh",
      "quanhuyen":"40101"
   },
   {
      "id":"4014500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40145"
   },
   {
      "id":"4014501",
      "phuongxa":"Thị trấn Rừng Thông",
      "quanhuyen":"40145"
   },
   {
      "id":"4014503",
      "phuongxa":"Xã Đông Hoàng",
      "quanhuyen":"40145"
   },
   {
      "id":"4014505",
      "phuongxa":"Xã Đông Ninh",
      "quanhuyen":"40145"
   },
   {
      "id":"4014507",
      "phuongxa":"Xã Đông Khê",
      "quanhuyen":"40145"
   },
   {
      "id":"4014509",
      "phuongxa":"Xã Đông Hòa",
      "quanhuyen":"40145"
   },
   {
      "id":"4014511",
      "phuongxa":"Xã Đông Yên",
      "quanhuyen":"40145"
   },
   {
      "id":"4014513",
      "phuongxa":"Xã Đông Lĩnh",
      "quanhuyen":"40101"
   },
   {
      "id":"4014515",
      "phuongxa":"Xã Đông Minh",
      "quanhuyen":"40145"
   },
   {
      "id":"4014517",
      "phuongxa":"Xã Đông Thanh",
      "quanhuyen":"40145"
   },
   {
      "id":"4014519",
      "phuongxa":"Xã Đông Tiến",
      "quanhuyen":"40145"
   },
   {
      "id":"4014521",
      "phuongxa":"Xã Đông Anh",
      "quanhuyen":"40145"
   },
   {
      "id":"4014523",
      "phuongxa":"Xã Đông Xuân",
      "quanhuyen":"40145"
   },
   {
      "id":"4014525",
      "phuongxa":"Xã Đông Thịnh",
      "quanhuyen":"40145"
   },
   {
      "id":"4014529",
      "phuongxa":"Xã Đông Phú",
      "quanhuyen":"40145"
   },
   {
      "id":"4014531",
      "phuongxa":"Xã Đông Nam",
      "quanhuyen":"40145"
   },
   {
      "id":"4014533",
      "phuongxa":"Xã Đông Quang",
      "quanhuyen":"40145"
   },
   {
      "id":"4014535",
      "phuongxa":"Xã Đông Vinh",
      "quanhuyen":"40101"
   },
   {
      "id":"4014537",
      "phuongxa":"Xã Đông Tân",
      "quanhuyen":"40101"
   },
   {
      "id":"4014539",
      "phuongxa":"Xã Đông Hưng",
      "quanhuyen":"40101"
   },
   {
      "id":"4014700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40147"
   },
   {
      "id":"4014703",
      "phuongxa":"Xã Thọ Sơn",
      "quanhuyen":"40147"
   },
   {
      "id":"4014705",
      "phuongxa":"Xã Thọ Bình",
      "quanhuyen":"40147"
   },
   {
      "id":"4014707",
      "phuongxa":"Xã Thọ Tiến",
      "quanhuyen":"40147"
   },
   {
      "id":"4014709",
      "phuongxa":"Xã Hợp Lý",
      "quanhuyen":"40147"
   },
   {
      "id":"4014711",
      "phuongxa":"Xã Hợp Tiến",
      "quanhuyen":"40147"
   },
   {
      "id":"4014713",
      "phuongxa":"Xã Hợp Thành",
      "quanhuyen":"40147"
   },
   {
      "id":"4014715",
      "phuongxa":"Xã Triệu Thành",
      "quanhuyen":"40147"
   },
   {
      "id":"4014719",
      "phuongxa":"Xã Minh Sơn",
      "quanhuyen":"40147"
   },
   {
      "id":"4014721",
      "phuongxa":"Xã Minh Dân",
      "quanhuyen":"40147"
   },
   {
      "id":"4014723",
      "phuongxa":"Xã Minh Châu",
      "quanhuyen":"40147"
   },
   {
      "id":"4014725",
      "phuongxa":"Xã Dân Lực",
      "quanhuyen":"40147"
   },
   {
      "id":"4014727",
      "phuongxa":"Xã Dân Lý",
      "quanhuyen":"40147"
   },
   {
      "id":"4014733",
      "phuongxa":"Xã Văn Sơn",
      "quanhuyen":"40147"
   },
   {
      "id":"4014735",
      "phuongxa":"Xã Thái Hòa",
      "quanhuyen":"40147"
   },
   {
      "id":"4014737",
      "phuongxa":"Xã Tân Ninh",
      "quanhuyen":"40147"
   },
   {
      "id":"4014739",
      "phuongxa":"Xã Đồng Lợi",
      "quanhuyen":"40147"
   },
   {
      "id":"4014741",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"40147"
   },
   {
      "id":"4014745",
      "phuongxa":"Xã Tiến Nông",
      "quanhuyen":"40147"
   },
   {
      "id":"4014747",
      "phuongxa":"Xã Khuyến Nông",
      "quanhuyen":"40147"
   },
   {
      "id":"4014749",
      "phuongxa":"Xã Xuân Thịnh",
      "quanhuyen":"40147"
   },
   {
      "id":"4014751",
      "phuongxa":"Xã Xuân Lộc",
      "quanhuyen":"40147"
   },
   {
      "id":"4014753",
      "phuongxa":"Xã Thọ Dân",
      "quanhuyen":"40147"
   },
   {
      "id":"4014759",
      "phuongxa":"Xã Thọ Ngọc",
      "quanhuyen":"40147"
   },
   {
      "id":"4014761",
      "phuongxa":"Xã Thọ Cường",
      "quanhuyen":"40147"
   },
   {
      "id":"4014763",
      "phuongxa":"Xã Thọ Phú",
      "quanhuyen":"40147"
   },
   {
      "id":"4014765",
      "phuongxa":"Xã Thọ Vực",
      "quanhuyen":"40147"
   },
   {
      "id":"4014767",
      "phuongxa":"Xã Thọ Thế",
      "quanhuyen":"40147"
   },
   {
      "id":"4014769",
      "phuongxa":"Xã Nông Trường",
      "quanhuyen":"40147"
   },
   {
      "id":"4014771",
      "phuongxa":"Xã Bình Sơn",
      "quanhuyen":"40147"
   },
   {
      "id":"4014900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40149"
   },
   {
      "id":"4014901",
      "phuongxa":"Thị trấn Quảng Xương",
      "quanhuyen":"40149"
   },
   {
      "id":"4014903",
      "phuongxa":"Xã Quảng Thịnh",
      "quanhuyen":"40149"
   },
   {
      "id":"4014905",
      "phuongxa":"Xã Quảng Tân",
      "quanhuyen":"40149"
   },
   {
      "id":"4014907",
      "phuongxa":"Xã Quảng Trạch",
      "quanhuyen":"40149"
   },
   {
      "id":"4014909",
      "phuongxa":"Xã Quảng Phong",
      "quanhuyen":"40149"
   },
   {
      "id":"4014911",
      "phuongxa":"Xã Quảng Đức",
      "quanhuyen":"40149"
   },
   {
      "id":"4014913",
      "phuongxa":"Xã Quảng Định",
      "quanhuyen":"40149"
   },
   {
      "id":"4014915",
      "phuongxa":"Xã Quảng Đông",
      "quanhuyen":"40149"
   },
   {
      "id":"4014917",
      "phuongxa":"Xã Quảng Nhân",
      "quanhuyen":"40149"
   },
   {
      "id":"4014919",
      "phuongxa":"Xã Quảng Ninh",
      "quanhuyen":"40149"
   },
   {
      "id":"4014921",
      "phuongxa":"Xã Quảng Bình",
      "quanhuyen":"40149"
   },
   {
      "id":"4014925",
      "phuongxa":"Xã Quảng Văn",
      "quanhuyen":"40149"
   },
   {
      "id":"4014927",
      "phuongxa":"Xã Quảng Long",
      "quanhuyen":"40149"
   },
   {
      "id":"4014929",
      "phuongxa":"Xã Quảng Yên",
      "quanhuyen":"40149"
   },
   {
      "id":"4014931",
      "phuongxa":"Xã Quảng Hòa",
      "quanhuyen":"40149"
   },
   {
      "id":"4014933",
      "phuongxa":"Xã Quảng Lĩnh",
      "quanhuyen":"40149"
   },
   {
      "id":"4014935",
      "phuongxa":"Xã Quảng Khê",
      "quanhuyen":"40149"
   },
   {
      "id":"4031507",
      "phuongxa":"Xã Tam Thái",
      "quanhuyen":"40315"
   },
   {
      "id":"4031509",
      "phuongxa":"Xã Tam Hợp",
      "quanhuyen":"40315"
   },
   {
      "id":"4031511",
      "phuongxa":"Xã Thạch Giám",
      "quanhuyen":"40315"
   },
   {
      "id":"4031513",
      "phuongxa":"Xã Xá Lượng",
      "quanhuyen":"40315"
   },
   {
      "id":"4031515",
      "phuongxa":"Xã Lưu Kiền",
      "quanhuyen":"40315"
   },
   {
      "id":"4031517",
      "phuongxa":"Xã Lưỡng Minh",
      "quanhuyen":"40315"
   },
   {
      "id":"4031519",
      "phuongxa":"Xã Kim Đa",
      "quanhuyen":"40315"
   },
   {
      "id":"4031521",
      "phuongxa":"Xã Kim Tiến",
      "quanhuyen":"40315"
   },
   {
      "id":"4031523",
      "phuongxa":"Xã Hữu Khuông",
      "quanhuyen":"40315"
   },
   {
      "id":"4031525",
      "phuongxa":"Xã Hữu Dương",
      "quanhuyen":"40315"
   },
   {
      "id":"4031527",
      "phuongxa":"Xã Luân Mai",
      "quanhuyen":"40315"
   },
   {
      "id":"4031529",
      "phuongxa":"Xã Mai Sơn",
      "quanhuyen":"40315"
   },
   {
      "id":"4031531",
      "phuongxa":"Xã Nhôn Mai",
      "quanhuyen":"40315"
   },
   {
      "id":"4031535",
      "phuongxa":"Xã Yên Hòa",
      "quanhuyen":"40315"
   },
   {
      "id":"4031537",
      "phuongxa":"Xã Yên Tĩnh",
      "quanhuyen":"40315"
   },
   {
      "id":"4031539",
      "phuongxa":"Xã Yên Thắng",
      "quanhuyen":"40315"
   },
   {
      "id":"4031541",
      "phuongxa":"Xã Nga My",
      "quanhuyen":"40315"
   },
   {
      "id":"4031700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40317"
   },
   {
      "id":"4031701",
      "phuongxa":"Thị trấn Cầu Giát",
      "quanhuyen":"40317"
   },
   {
      "id":"4031703",
      "phuongxa":"Xã Quỳnh Thắng",
      "quanhuyen":"40317"
   },
   {
      "id":"4031705",
      "phuongxa":"Xã Quỳnh Vinh",
      "quanhuyen":"40317"
   },
   {
      "id":"4031707",
      "phuongxa":"Xã Quỳnh Lộc",
      "quanhuyen":"40317"
   },
   {
      "id":"4031709",
      "phuongxa":"Xã Quỳnh Trang",
      "quanhuyen":"40317"
   },
   {
      "id":"4031713",
      "phuongxa":"Xã Quỳnh Dị",
      "quanhuyen":"40317"
   },
   {
      "id":"4031715",
      "phuongxa":"Xã Quỳnh Lập",
      "quanhuyen":"40317"
   },
   {
      "id":"4031717",
      "phuongxa":"Xã Quỳnh Phương",
      "quanhuyen":"40317"
   },
   {
      "id":"4031719",
      "phuongxa":"Xã Quỳnh Châu",
      "quanhuyen":"40317"
   },
   {
      "id":"4031721",
      "phuongxa":"Xã Quỳnh Văn",
      "quanhuyen":"40317"
   },
   {
      "id":"4031725",
      "phuongxa":"Xã Quỳnh Tân",
      "quanhuyen":"40317"
   },
   {
      "id":"4031727",
      "phuongxa":"Xã Quỳnh Tam",
      "quanhuyen":"40317"
   },
   {
      "id":"4031729",
      "phuongxa":"Xã Quỳnh Hoa",
      "quanhuyen":"40317"
   },
   {
      "id":"4031733",
      "phuongxa":"Xã Quỳnh Mỹ",
      "quanhuyen":"40317"
   },
   {
      "id":"4031735",
      "phuongxa":"Xã Quỳnh Hậu",
      "quanhuyen":"40317"
   },
   {
      "id":"4031739",
      "phuongxa":"Xã Quỳnh Hưng",
      "quanhuyen":"40317"
   },
   {
      "id":"4031741",
      "phuongxa":"Xã Quỳnh Thanh",
      "quanhuyen":"40317"
   },
   {
      "id":"4031743",
      "phuongxa":"Xã Quỳnh Ngọc",
      "quanhuyen":"40317"
   },
   {
      "id":"4031745",
      "phuongxa":"Xã Quỳnh Bảng",
      "quanhuyen":"40317"
   },
   {
      "id":"4031747",
      "phuongxa":"Xã Quỳnh Lâm",
      "quanhuyen":"40317"
   },
   {
      "id":"4031751",
      "phuongxa":"Xã Quỳnh Đôi",
      "quanhuyen":"40317"
   },
   {
      "id":"4031753",
      "phuongxa":"Xã Quỳnh Lương",
      "quanhuyen":"40317"
   },
   {
      "id":"4031755",
      "phuongxa":"Xã Quỳnh Giang",
      "quanhuyen":"40317"
   },
   {
      "id":"4031757",
      "phuongxa":"Xã Quỳnh Bá",
      "quanhuyen":"40317"
   },
   {
      "id":"4031759",
      "phuongxa":"Xã Quỳnh Yên",
      "quanhuyen":"40317"
   },
   {
      "id":"4031763",
      "phuongxa":"Xã Tân Sơn",
      "quanhuyen":"40317"
   },
   {
      "id":"4031765",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"40317"
   },
   {
      "id":"4031767",
      "phuongxa":"Xã Mai Hùng",
      "quanhuyen":"40317"
   },
   {
      "id":"4031769",
      "phuongxa":"Xã Quỳnh Liên",
      "quanhuyen":"40317"
   },
   {
      "id":"4031771",
      "phuongxa":"Xã Quỳnh Nghĩa",
      "quanhuyen":"40317"
   },
   {
      "id":"4031773",
      "phuongxa":"Xã Quỳnh Thọ",
      "quanhuyen":"40317"
   },
   {
      "id":"4031775",
      "phuongxa":"Xã Tiến Thủy",
      "quanhuyen":"40317"
   },
   {
      "id":"4031777",
      "phuongxa":"Xã An Hòa",
      "quanhuyen":"40317"
   },
   {
      "id":"4031779",
      "phuongxa":"Xã Sơn Hải",
      "quanhuyen":"40317"
   },
   {
      "id":"4031781",
      "phuongxa":"Xã Quỳnh Thuận",
      "quanhuyen":"40317"
   },
   {
      "id":"4031783",
      "phuongxa":"Xã Quỳnh Long",
      "quanhuyen":"40317"
   },
   {
      "id":"4031900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40319"
   },
   {
      "id":"4031901",
      "phuongxa":"Thị trấn Tân Kỳ",
      "quanhuyen":"40319"
   },
   {
      "id":"4031903",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"40319"
   },
   {
      "id":"4031905",
      "phuongxa":"Xã Tân Hợp",
      "quanhuyen":"40319"
   },
   {
      "id":"4031907",
      "phuongxa":"Xã Tân Xuân",
      "quanhuyen":"40319"
   },
   {
      "id":"4031909",
      "phuongxa":"Xã Nghĩa Bình",
      "quanhuyen":"40319"
   },
   {
      "id":"4031911",
      "phuongxa":"Xã Giai Xuân",
      "quanhuyen":"40319"
   },
   {
      "id":"4031913",
      "phuongxa":"Xã Nghĩa Thái",
      "quanhuyen":"40319"
   },
   {
      "id":"4031915",
      "phuongxa":"Xã Nghĩa Đồng",
      "quanhuyen":"40319"
   },
   {
      "id":"4031919",
      "phuongxa":"Xã Đồng Văn",
      "quanhuyen":"40319"
   },
   {
      "id":"4031921",
      "phuongxa":"Xã Nghĩa Phúc",
      "quanhuyen":"40319"
   },
   {
      "id":"4031923",
      "phuongxa":"Xã Nghĩa Hoàn",
      "quanhuyen":"40319"
   },
   {
      "id":"4031925",
      "phuongxa":"Xã Nghĩa Dũng",
      "quanhuyen":"40319"
   },
   {
      "id":"4031927",
      "phuongxa":"Xã Tiên Kỳ",
      "quanhuyen":"40319"
   },
   {
      "id":"4050507",
      "phuongxa":"Xã Xuân Trường",
      "quanhuyen":"40505"
   },
   {
      "id":"4050509",
      "phuongxa":"Xã Xuân Đan",
      "quanhuyen":"40505"
   },
   {
      "id":"4050511",
      "phuongxa":"Xã Xuân Phổ",
      "quanhuyen":"40505"
   },
   {
      "id":"4050513",
      "phuongxa":"Xã Xuân Hải",
      "quanhuyen":"40505"
   },
   {
      "id":"4050515",
      "phuongxa":"Xã Xuân Yên",
      "quanhuyen":"40505"
   },
   {
      "id":"4050517",
      "phuongxa":"Xã Tiên Điền",
      "quanhuyen":"40505"
   },
   {
      "id":"4050519",
      "phuongxa":"Xã Xuân Giang",
      "quanhuyen":"40505"
   },
   {
      "id":"4050521",
      "phuongxa":"Xã Xuân Mỹ",
      "quanhuyen":"40505"
   },
   {
      "id":"4050523",
      "phuongxa":"Xã Xuân Thành",
      "quanhuyen":"40505"
   },
   {
      "id":"4050525",
      "phuongxa":"Xã Xuân Hồng",
      "quanhuyen":"40505"
   },
   {
      "id":"4050527",
      "phuongxa":"Xã Xuân Viên",
      "quanhuyen":"40505"
   },
   {
      "id":"4050529",
      "phuongxa":"Xã Xuân Lam",
      "quanhuyen":"40505"
   },
   {
      "id":"4050531",
      "phuongxa":"Xã Xuân Lĩnh",
      "quanhuyen":"40505"
   },
   {
      "id":"4050533",
      "phuongxa":"Xã Xuân Liên",
      "quanhuyen":"40505"
   },
   {
      "id":"4050535",
      "phuongxa":"Xã Cỗ Đạm",
      "quanhuyen":"40505"
   },
   {
      "id":"4050700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40507"
   },
   {
      "id":"4050701",
      "phuongxa":"Thị trấn Đức Thọ",
      "quanhuyen":"40507"
   },
   {
      "id":"4050703",
      "phuongxa":"Xã Đức Tùng",
      "quanhuyen":"40507"
   },
   {
      "id":"4050705",
      "phuongxa":"Xã Đức Quang",
      "quanhuyen":"40507"
   },
   {
      "id":"4050707",
      "phuongxa":"Xã Đức Châu",
      "quanhuyen":"40507"
   },
   {
      "id":"4050709",
      "phuongxa":"Xã Trường Sơn",
      "quanhuyen":"40507"
   },
   {
      "id":"4050711",
      "phuongxa":"Xã Liên Minh",
      "quanhuyen":"40507"
   },
   {
      "id":"4050713",
      "phuongxa":"Xã Đức La",
      "quanhuyen":"40507"
   },
   {
      "id":"4050717",
      "phuongxa":"Xã Tùng ảnh",
      "quanhuyen":"40507"
   },
   {
      "id":"4050719",
      "phuongxa":"Xã Đức Yên",
      "quanhuyen":"40507"
   },
   {
      "id":"4050721",
      "phuongxa":"Xã Đức Nhân",
      "quanhuyen":"40507"
   },
   {
      "id":"4050723",
      "phuongxa":"Xã Bùi Xá",
      "quanhuyen":"40507"
   },
   {
      "id":"4050725",
      "phuongxa":"Xã Yên Hồ",
      "quanhuyen":"40507"
   },
   {
      "id":"4050727",
      "phuongxa":"Xã Trung Lễ",
      "quanhuyen":"40507"
   },
   {
      "id":"4050731",
      "phuongxa":"Xã Đức Thịnh",
      "quanhuyen":"40507"
   },
   {
      "id":"4050733",
      "phuongxa":"Xã Thái Yên",
      "quanhuyen":"40507"
   },
   {
      "id":"4050735",
      "phuongxa":"Xã Đức Hòa",
      "quanhuyen":"40507"
   },
   {
      "id":"4050737",
      "phuongxa":"Xã Đức Lạc",
      "quanhuyen":"40507"
   },
   {
      "id":"4050739",
      "phuongxa":"Xã Đức Long",
      "quanhuyen":"40507"
   },
   {
      "id":"4050741",
      "phuongxa":"Xã Đức Lâm",
      "quanhuyen":"40507"
   },
   {
      "id":"4050745",
      "phuongxa":"Xã Ân Phú",
      "quanhuyen":"40507"
   },
   {
      "id":"4050749",
      "phuongxa":"Xã Đức Dũng",
      "quanhuyen":"40507"
   },
   {
      "id":"4050751",
      "phuongxa":"Xã Đức An",
      "quanhuyen":"40507"
   },
   {
      "id":"4050753",
      "phuongxa":"Xã Đức Giang",
      "quanhuyen":"40507"
   },
   {
      "id":"4050757",
      "phuongxa":"Xã Đức Lĩnh",
      "quanhuyen":"40507"
   },
   {
      "id":"4050759",
      "phuongxa":"Xã Đức Hương",
      "quanhuyen":"40507"
   },
   {
      "id":"4050761",
      "phuongxa":"Xã Đức Lạng",
      "quanhuyen":"40507"
   },
   {
      "id":"4050763",
      "phuongxa":"Xã Tân Hương",
      "quanhuyen":"40507"
   },
   {
      "id":"4050765",
      "phuongxa":"Xã Đức Bồng",
      "quanhuyen":"40507"
   },
   {
      "id":"4050767",
      "phuongxa":"Xã Đức Liên",
      "quanhuyen":"40507"
   },
   {
      "id":"4050900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40509"
   },
   {
      "id":"4050901",
      "phuongxa":"Thị trấn  Tây Sơn",
      "quanhuyen":"40509"
   },
   {
      "id":"4050903",
      "phuongxa":"Thị trấn Phố Châu",
      "quanhuyen":"40509"
   },
   {
      "id":"4050905",
      "phuongxa":"Xã Sơn Hồng",
      "quanhuyen":"40509"
   },
   {
      "id":"4050909",
      "phuongxa":"Xã Sơn Lâm",
      "quanhuyen":"40509"
   },
   {
      "id":"4050911",
      "phuongxa":"Xã Sơn Lễ",
      "quanhuyen":"40509"
   },
   {
      "id":"4050913",
      "phuongxa":"Xã Sơn Tiến",
      "quanhuyen":"40509"
   },
   {
      "id":"4050915",
      "phuongxa":"Xã Sơn An",
      "quanhuyen":"40509"
   },
   {
      "id":"4050917",
      "phuongxa":"Xã Sơn Kim",
      "quanhuyen":"40509"
   },
   {
      "id":"4050919",
      "phuongxa":"Xã Sơn Tây",
      "quanhuyen":"40509"
   },
   {
      "id":"4050921",
      "phuongxa":"Xã Sơn Quang",
      "quanhuyen":"40509"
   },
   {
      "id":"4050923",
      "phuongxa":"Xã Sơn Giang",
      "quanhuyen":"40509"
   },
   {
      "id":"4050925",
      "phuongxa":"Xã Sơn Ninh",
      "quanhuyen":"40509"
   },
   {
      "id":"4050927",
      "phuongxa":"Xã Sơn Hòa",
      "quanhuyen":"40509"
   },
   {
      "id":"4050929",
      "phuongxa":"Xã Sơn Thịnh",
      "quanhuyen":"40509"
   },
   {
      "id":"4050931",
      "phuongxa":"Xã Sơn Diệm",
      "quanhuyen":"40509"
   },
   {
      "id":"4050933",
      "phuongxa":"Xã Sơn Hàm",
      "quanhuyen":"40509"
   },
   {
      "id":"4050935",
      "phuongxa":"Xã Sơn Trung",
      "quanhuyen":"40509"
   },
   {
      "id":"4050937",
      "phuongxa":"Xã Sơn Phố",
      "quanhuyen":"40509"
   },
   {
      "id":"4050939",
      "phuongxa":"Xã Sơn Phúc",
      "quanhuyen":"40509"
   },
   {
      "id":"4050941",
      "phuongxa":"Xã Sơn Bằng",
      "quanhuyen":"40509"
   },
   {
      "id":"4050943",
      "phuongxa":"Xã Sơn Mai",
      "quanhuyen":"40509"
   },
   {
      "id":"4050945",
      "phuongxa":"Xã Sơn Thọ",
      "quanhuyen":"40509"
   },
   {
      "id":"4050949",
      "phuongxa":"Xã Sơn Bình",
      "quanhuyen":"40509"
   },
   {
      "id":"4050951",
      "phuongxa":"Xã Sơn Hà",
      "quanhuyen":"40509"
   },
   {
      "id":"4050953",
      "phuongxa":"Xã Sơn Mỹ",
      "quanhuyen":"40509"
   },
   {
      "id":"4050955",
      "phuongxa":"Xã Sơn Tân",
      "quanhuyen":"40509"
   },
   {
      "id":"4050957",
      "phuongxa":"Xã Sơn Long",
      "quanhuyen":"40509"
   },
   {
      "id":"4050959",
      "phuongxa":"Xã Sơn Phú",
      "quanhuyen":"40509"
   },
   {
      "id":"4050961",
      "phuongxa":"Xã Sơn Thủy",
      "quanhuyen":"40509"
   },
   {
      "id":"4050963",
      "phuongxa":"Xã Sơn Trà",
      "quanhuyen":"40509"
   },
   {
      "id":"4050965",
      "phuongxa":"Xã Sơn Trường",
      "quanhuyen":"40509"
   },
   {
      "id":"4051100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40511"
   },
   {
      "id":"4051101",
      "phuongxa":"Thị trấn Can Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051103",
      "phuongxa":"Xã Hồng Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051105",
      "phuongxa":"Xã Tân Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051107",
      "phuongxa":"Xã Thịnh Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051109",
      "phuongxa":"Xã Thiên Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051111",
      "phuongxa":"Xã An Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051113",
      "phuongxa":"Xã Kim Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051115",
      "phuongxa":"Xã Thanh Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051117",
      "phuongxa":"Xã Song Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051121",
      "phuongxa":"Xã Trường Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051123",
      "phuongxa":"Xã Vượng Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051125",
      "phuongxa":"Xã Bình Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051127",
      "phuongxa":"Xã  Thường Nga",
      "quanhuyen":"40511"
   },
   {
      "id":"4051129",
      "phuongxa":"Xã Vĩnh Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051131",
      "phuongxa":"Xã Khánh Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051133",
      "phuongxa":"Xã Đại Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051135",
      "phuongxa":"Xã Tùng Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051139",
      "phuongxa":"Xã ích Hậu",
      "quanhuyen":"40511"
   },
   {
      "id":"4051141",
      "phuongxa":"Xã Phú Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051143",
      "phuongxa":"Xã Yên Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051145",
      "phuongxa":"Xã Trung Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051147",
      "phuongxa":"Xã Phù Lưu",
      "quanhuyen":"40511"
   },
   {
      "id":"4051149",
      "phuongxa":"Xã Xuân Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051151",
      "phuongxa":"Xã Tiến Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051155",
      "phuongxa":"Xã Đồng Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051157",
      "phuongxa":"Xã Quang Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051159",
      "phuongxa":"Xã Mỹ Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051161",
      "phuongxa":"Xã Sơn Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051303",
      "phuongxa":"Thị trấn NT Thạch Ngọc",
      "quanhuyen":"40513"
   },
   {
      "id":"4051305",
      "phuongxa":"Xã Thạch Liên",
      "quanhuyen":"40513"
   },
   {
      "id":"4051307",
      "phuongxa":"Xã Thạch Kênh",
      "quanhuyen":"40513"
   },
   {
      "id":"4051309",
      "phuongxa":"Xã  Việt Xuyên",
      "quanhuyen":"40513"
   },
   {
      "id":"4051311",
      "phuongxa":"Xã Thạch Sơn",
      "quanhuyen":"40513"
   },
   {
      "id":"4051315",
      "phuongxa":"Xã Thạch Mỹ",
      "quanhuyen":"40513"
   },
   {
      "id":"4051317",
      "phuongxa":"Xã Thạch Châu",
      "quanhuyen":"40513"
   },
   {
      "id":"4051319",
      "phuongxa":"Xã Thạch Kim",
      "quanhuyen":"40513"
   },
   {
      "id":"4051321",
      "phuongxa":"Xã Thạch Bàn",
      "quanhuyen":"40513"
   },
   {
      "id":"4051323",
      "phuongxa":"Xã Phù Việt",
      "quanhuyen":"40513"
   },
   {
      "id":"4051325",
      "phuongxa":"Xã Thạch Long",
      "quanhuyen":"40513"
   },
   {
      "id":"4051327",
      "phuongxa":"Xã Thạch Đỉnh",
      "quanhuyen":"40513"
   },
   {
      "id":"4051329",
      "phuongxa":"Xã Thạch Hải",
      "quanhuyen":"40513"
   },
   {
      "id":"4051331",
      "phuongxa":"Xã Thạch Tiến",
      "quanhuyen":"40513"
   },
   {
      "id":"4051333",
      "phuongxa":"Xã Thạch Thanh",
      "quanhuyen":"40513"
   },
   {
      "id":"4051335",
      "phuongxa":"Xã Thạch Thượng",
      "quanhuyen":"40513"
   },
   {
      "id":"4051337",
      "phuongxa":"Xã Thạch Hạ",
      "quanhuyen":"40513"
   },
   {
      "id":"4051339",
      "phuongxa":"Xã Thạch Đồng",
      "quanhuyen":"40513"
   },
   {
      "id":"4051341",
      "phuongxa":"Xã Mai Phụ",
      "quanhuyen":"40513"
   },
   {
      "id":"4051343",
      "phuongxa":"Xã Thạch Môn",
      "quanhuyen":"40513"
   },
   {
      "id":"4051347",
      "phuongxa":"Xã Thạch Khê",
      "quanhuyen":"40513"
   },
   {
      "id":"4051349",
      "phuongxa":"Xã Thạch Trị",
      "quanhuyen":"40513"
   },
   {
      "id":"4051351",
      "phuongxa":"Xã Thạch Hưng",
      "quanhuyen":"40513"
   },
   {
      "id":"4051353",
      "phuongxa":"Xã  Tượng Sơn",
      "quanhuyen":"40513"
   },
   {
      "id":"4051355",
      "phuongxa":"Xã Thạch Lạc",
      "quanhuyen":"40513"
   },
   {
      "id":"4051357",
      "phuongxa":"Xã Thạch Ngọc",
      "quanhuyen":"40513"
   },
   {
      "id":"4051359",
      "phuongxa":"Xã Thạch Vĩnh",
      "quanhuyen":"40513"
   },
   {
      "id":"4051361",
      "phuongxa":"Xã Thạch Lưu",
      "quanhuyen":"40513"
   },
   {
      "id":"4051363",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"40513"
   },
   {
      "id":"4051365",
      "phuongxa":"Xã Thạch Đài",
      "quanhuyen":"40513"
   },
   {
      "id":"4051367",
      "phuongxa":"Xã Thạch Thắng",
      "quanhuyen":"40513"
   },
   {
      "id":"4051369",
      "phuongxa":"Xã Thạch Hội",
      "quanhuyen":"40513"
   },
   {
      "id":"4051371",
      "phuongxa":"Xã Thạch Văn",
      "quanhuyen":"40513"
   },
   {
      "id":"4051373",
      "phuongxa":"Xã Thạch Xuân",
      "quanhuyen":"40513"
   },
   {
      "id":"4051375",
      "phuongxa":"Xã Thạch Tân",
      "quanhuyen":"40513"
   },
   {
      "id":"4031929",
      "phuongxa":"Xã Nghĩa Hành",
      "quanhuyen":"40319"
   },
   {
      "id":"4031931",
      "phuongxa":"Xã Hương Sơn",
      "quanhuyen":"40319"
   },
   {
      "id":"4031935",
      "phuongxa":"Xã Kỳ Tân",
      "quanhuyen":"40319"
   },
   {
      "id":"4031937",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"40319"
   },
   {
      "id":"4031939",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"40319"
   },
   {
      "id":"4031941",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"40319"
   },
   {
      "id":"4032100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40321"
   },
   {
      "id":"4032101",
      "phuongxa":"Thị trấn Con Cuông",
      "quanhuyen":"40321"
   },
   {
      "id":"4032103",
      "phuongxa":"Xã Bình Chuẩn",
      "quanhuyen":"40321"
   },
   {
      "id":"4032105",
      "phuongxa":"Xã Lạng Khê",
      "quanhuyen":"40321"
   },
   {
      "id":"4032107",
      "phuongxa":"Xã Cam Lâm",
      "quanhuyen":"40321"
   },
   {
      "id":"4032109",
      "phuongxa":"Xã Đôn Phục",
      "quanhuyen":"40321"
   },
   {
      "id":"4032111",
      "phuongxa":"Xã Mậu Đức",
      "quanhuyen":"40321"
   },
   {
      "id":"4032113",
      "phuongxa":"Xã Thạch Ngàn",
      "quanhuyen":"40321"
   },
   {
      "id":"4032115",
      "phuongxa":"Xã Châu Khê",
      "quanhuyen":"40321"
   },
   {
      "id":"4032117",
      "phuongxa":"Xã Chi Khê",
      "quanhuyen":"40321"
   },
   {
      "id":"4032119",
      "phuongxa":"Xã Bồng Khê",
      "quanhuyen":"40321"
   },
   {
      "id":"4032121",
      "phuongxa":"Xã Yên Khê",
      "quanhuyen":"40321"
   },
   {
      "id":"4032123",
      "phuongxa":"Xã Lục Dạ",
      "quanhuyen":"40321"
   },
   {
      "id":"4032300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40323"
   },
   {
      "id":"4032301",
      "phuongxa":"Thị trấn Yên Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032303",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032305",
      "phuongxa":"Xã Đức Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032307",
      "phuongxa":"Xã Mã Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032309",
      "phuongxa":"Xã Đô Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032311",
      "phuongxa":"Xã Lăng Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032315",
      "phuongxa":"Xã Quang Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032317",
      "phuongxa":"Xã Đồng Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032319",
      "phuongxa":"Xã Hậu Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032321",
      "phuongxa":"Xã Phúc Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032323",
      "phuongxa":"Xã Phú Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032327",
      "phuongxa":"Xã Tăng Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032329",
      "phuongxa":"Xã Hoa Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032331",
      "phuongxa":"Xã Hợp Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032333",
      "phuongxa":"Xã Văn Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032335",
      "phuongxa":"Xã Thịnh Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032339",
      "phuongxa":"Xã Xuân Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032343",
      "phuongxa":"Xã Minh Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032345",
      "phuongxa":"Xã Lý Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032347",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032351",
      "phuongxa":"Xã Nam Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032353",
      "phuongxa":"Xã Vĩnh Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032355",
      "phuongxa":"Xã Long Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032357",
      "phuongxa":"Xã Liên Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032359",
      "phuongxa":"Xã Viên Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032363",
      "phuongxa":"Xã Bảo Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032365",
      "phuongxa":"Xã Mỹ Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032367",
      "phuongxa":"Xã Sơn Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40325"
   },
   {
      "id":"4032501",
      "phuongxa":"Thị trấn Diễn Châu",
      "quanhuyen":"40325"
   },
   {
      "id":"4032503",
      "phuongxa":"Xã Diễn Lâm",
      "quanhuyen":"40325"
   },
   {
      "id":"4032505",
      "phuongxa":"Xã Diễn Đoài",
      "quanhuyen":"40325"
   },
   {
      "id":"4032507",
      "phuongxa":"Xã Diễn Trường",
      "quanhuyen":"40325"
   },
   {
      "id":"4032509",
      "phuongxa":"Xã Diễn Hùng",
      "quanhuyen":"40325"
   },
   {
      "id":"4032511",
      "phuongxa":"Xã Diễn Yên",
      "quanhuyen":"40325"
   },
   {
      "id":"4032513",
      "phuongxa":"Xã Diễn Hoàng",
      "quanhuyen":"40325"
   },
   {
      "id":"4032515",
      "phuongxa":"Xã Diễn Mỹ",
      "quanhuyen":"40325"
   },
   {
      "id":"4032517",
      "phuongxa":"Xã Diễn Phong",
      "quanhuyen":"40325"
   },
   {
      "id":"4032519",
      "phuongxa":"Xã Diễn Tháp",
      "quanhuyen":"40325"
   },
   {
      "id":"4032521",
      "phuongxa":"Xã Diễn Hồng",
      "quanhuyen":"40325"
   },
   {
      "id":"4032523",
      "phuongxa":"Xã Diễn Hải",
      "quanhuyen":"40325"
   },
   {
      "id":"4032525",
      "phuongxa":"Xã Diễn Liên",
      "quanhuyen":"40325"
   },
   {
      "id":"4032527",
      "phuongxa":"Xã Diễn Vạn",
      "quanhuyen":"40325"
   },
   {
      "id":"4032529",
      "phuongxa":"Xã Diễn Kim",
      "quanhuyen":"40325"
   },
   {
      "id":"4032531",
      "phuongxa":"Xã Diễn Thái",
      "quanhuyen":"40325"
   },
   {
      "id":"4032533",
      "phuongxa":"Xã Diễn Xuân",
      "quanhuyen":"40325"
   },
   {
      "id":"4032537",
      "phuongxa":"Xã Diễn Bích",
      "quanhuyen":"40325"
   },
   {
      "id":"4032539",
      "phuongxa":"Xã Diễn Đồng",
      "quanhuyen":"40325"
   },
   {
      "id":"4032541",
      "phuongxa":"Xã Diễn Hạnh",
      "quanhuyen":"40325"
   },
   {
      "id":"4032543",
      "phuongxa":"Xã Diễn Hoa",
      "quanhuyen":"40325"
   },
   {
      "id":"4032545",
      "phuongxa":"Xã Diễn Ngọc",
      "quanhuyen":"40325"
   },
   {
      "id":"4032547",
      "phuongxa":"Xã Diễn Nguyên",
      "quanhuyen":"40325"
   },
   {
      "id":"4032549",
      "phuongxa":"Xã Diễn Quảng",
      "quanhuyen":"40325"
   },
   {
      "id":"4032551",
      "phuongxa":"Xã Diễn Thành",
      "quanhuyen":"40325"
   },
   {
      "id":"4032553",
      "phuongxa":"Xã Diễn Minh",
      "quanhuyen":"40325"
   },
   {
      "id":"4032555",
      "phuongxa":"Xã Diễn Phúc",
      "quanhuyen":"40325"
   },
   {
      "id":"4032557",
      "phuongxa":"Xã Diễn Tân",
      "quanhuyen":"40325"
   },
   {
      "id":"4032559",
      "phuongxa":"Xã Diễn Bình",
      "quanhuyen":"40325"
   },
   {
      "id":"4032561",
      "phuongxa":"Xã Diễn Cát",
      "quanhuyen":"40325"
   },
   {
      "id":"4032563",
      "phuongxa":"Xã Diễn Thắng",
      "quanhuyen":"40325"
   },
   {
      "id":"4032565",
      "phuongxa":"Xã Diễn Thịnh",
      "quanhuyen":"40325"
   },
   {
      "id":"4032567",
      "phuongxa":"Xã Diễn Phú",
      "quanhuyen":"40325"
   },
   {
      "id":"4032569",
      "phuongxa":"Xã Diễn Thọ",
      "quanhuyen":"40325"
   },
   {
      "id":"4032571",
      "phuongxa":"Xã Diễn Lợi",
      "quanhuyen":"40325"
   },
   {
      "id":"4032575",
      "phuongxa":"Xã Diễn Trung",
      "quanhuyen":"40325"
   },
   {
      "id":"4032577",
      "phuongxa":"Xã Diễn Lộc",
      "quanhuyen":"40325"
   },
   {
      "id":"4032700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40327"
   },
   {
      "id":"4032701",
      "phuongxa":"Thị trấn Anh Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032703",
      "phuongxa":"Xã Thọ Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032705",
      "phuongxa":"Xã Tam Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032707",
      "phuongxa":"Xã Thành Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032709",
      "phuongxa":"Xã Bình Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032713",
      "phuongxa":"Xã Cẩm Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032715",
      "phuongxa":"Xã Tường Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032717",
      "phuongxa":"Xã Hùng Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032719",
      "phuongxa":"Xã Đức Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032721",
      "phuongxa":"Xã Hội Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032725",
      "phuongxa":"Xã Vĩnh Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032727",
      "phuongxa":"Xã Khai Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032729",
      "phuongxa":"Xã Lạng Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032731",
      "phuongxa":"Xã Tào Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032733",
      "phuongxa":"Xã Phúc Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032739",
      "phuongxa":"Xã Lĩnh Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40329"
   },
   {
      "id":"4032901",
      "phuongxa":"Thị trấn Đô Lương",
      "quanhuyen":"40329"
   },
   {
      "id":"4032903",
      "phuongxa":"Xã Giang Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032907",
      "phuongxa":"Xã Bồi Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032909",
      "phuongxa":"Xã Hồng Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032911",
      "phuongxa":"Xã Bài Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032913",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032915",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032917",
      "phuongxa":"Xã Tràng Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032919",
      "phuongxa":"Xã Thượng Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032921",
      "phuongxa":"Xã Hòa Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032923",
      "phuongxa":"Xã Đặng Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032925",
      "phuongxa":"Xã Đông Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032927",
      "phuongxa":"Xã Nam Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032929",
      "phuongxa":"Xã Lưu Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032931",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032933",
      "phuongxa":"Xã Văn Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032935",
      "phuongxa":"Xã Đà Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032937",
      "phuongxa":"Xã Lạc Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032939",
      "phuongxa":"Xã Tân Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032943",
      "phuongxa":"Xã Quang Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032945",
      "phuongxa":"Xã Thịnh Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032947",
      "phuongxa":"Xã Trung Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032949",
      "phuongxa":"Xã Xuân Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032951",
      "phuongxa":"Xã Minh Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032953",
      "phuongxa":"Xã Thuận Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032955",
      "phuongxa":"Xã Nhân Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032957",
      "phuongxa":"Xã Hiến Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032959",
      "phuongxa":"Xã Mỹ Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032961",
      "phuongxa":"Xã Trù Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4032963",
      "phuongxa":"Xã Đại Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4033100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40331"
   },
   {
      "id":"4033101",
      "phuongxa":"Thị trấn Thanh Chương",
      "quanhuyen":"40331"
   },
   {
      "id":"4033103",
      "phuongxa":"Xã Cát Văn",
      "quanhuyen":"40331"
   },
   {
      "id":"4033105",
      "phuongxa":"Xã Hạnh Lâm",
      "quanhuyen":"40331"
   },
   {
      "id":"4033107",
      "phuongxa":"Xã Thanh Nho",
      "quanhuyen":"40331"
   },
   {
      "id":"4033109",
      "phuongxa":"Xã Thanh Hòa",
      "quanhuyen":"40331"
   },
   {
      "id":"4033111",
      "phuongxa":"Xã Phong Thịnh",
      "quanhuyen":"40331"
   },
   {
      "id":"4033113",
      "phuongxa":"Xã Thanh Mỹ",
      "quanhuyen":"40331"
   },
   {
      "id":"4033117",
      "phuongxa":"Xã Thanh Tiên",
      "quanhuyen":"40331"
   },
   {
      "id":"4033119",
      "phuongxa":"Xã Thanh Hưng",
      "quanhuyen":"40331"
   },
   {
      "id":"4033121",
      "phuongxa":"Xã Thanh Phong",
      "quanhuyen":"40331"
   },
   {
      "id":"4033123",
      "phuongxa":"Xã Thanh Văn",
      "quanhuyen":"40331"
   },
   {
      "id":"4033125",
      "phuongxa":"Xã Thanh Tường",
      "quanhuyen":"40331"
   },
   {
      "id":"4033127",
      "phuongxa":"Xã Thanh Hương",
      "quanhuyen":"40331"
   },
   {
      "id":"4033129",
      "phuongxa":"Xã Thanh Lĩnh",
      "quanhuyen":"40331"
   },
   {
      "id":"4033131",
      "phuongxa":"Xã Thanh Đồng",
      "quanhuyen":"40331"
   },
   {
      "id":"4033133",
      "phuongxa":"Xã Thanh Thịnh",
      "quanhuyen":"40331"
   },
   {
      "id":"4033135",
      "phuongxa":"Xã Đồng Văn",
      "quanhuyen":"40331"
   },
   {
      "id":"4033137",
      "phuongxa":"Xã Thanh Ngọc",
      "quanhuyen":"40331"
   },
   {
      "id":"4033139",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"40331"
   },
   {
      "id":"4033141",
      "phuongxa":"Xã Thanh Chi",
      "quanhuyen":"40331"
   },
   {
      "id":"4033143",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"40331"
   },
   {
      "id":"4033145",
      "phuongxa":"Xã Thanh Khê",
      "quanhuyen":"40331"
   },
   {
      "id":"4033147",
      "phuongxa":"Xã Xuân Tường",
      "quanhuyen":"40331"
   },
   {
      "id":"4033149",
      "phuongxa":"Xã Võ Liệt",
      "quanhuyen":"40331"
   },
   {
      "id":"4033151",
      "phuongxa":"Xã Thanh Long",
      "quanhuyen":"40331"
   },
   {
      "id":"4033153",
      "phuongxa":"Xã Thanh Thủy",
      "quanhuyen":"40331"
   },
   {
      "id":"4033155",
      "phuongxa":"Xã Thanh Dương",
      "quanhuyen":"40331"
   },
   {
      "id":"4033159",
      "phuongxa":"Xã Thanh Lương",
      "quanhuyen":"40331"
   },
   {
      "id":"4033163",
      "phuongxa":"Xã Thanh Yên",
      "quanhuyen":"40331"
   },
   {
      "id":"4033165",
      "phuongxa":"Xã Thanh Tùng",
      "quanhuyen":"40331"
   },
   {
      "id":"4033167",
      "phuongxa":"Xã Thanh Giang",
      "quanhuyen":"40331"
   },
   {
      "id":"4033169",
      "phuongxa":"Xã Thanh Mai",
      "quanhuyen":"40331"
   },
   {
      "id":"4033171",
      "phuongxa":"Xã Thanh Xuân",
      "quanhuyen":"40331"
   },
   {
      "id":"4033300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40333"
   },
   {
      "id":"4033301",
      "phuongxa":"Thị trấn Quán Hành",
      "quanhuyen":"40333"
   },
   {
      "id":"4033303",
      "phuongxa":"Xã Nghi Văn",
      "quanhuyen":"40333"
   },
   {
      "id":"4033305",
      "phuongxa":"Xã Nghi Yên",
      "quanhuyen":"40333"
   },
   {
      "id":"4033307",
      "phuongxa":"Xã Nghi Kiều",
      "quanhuyen":"40333"
   },
   {
      "id":"4033311",
      "phuongxa":"Xã Nghi Đồng",
      "quanhuyen":"40333"
   },
   {
      "id":"4033313",
      "phuongxa":"Xã Nghi Hưng",
      "quanhuyen":"40333"
   },
   {
      "id":"4033315",
      "phuongxa":"Xã Nghi Tiến",
      "quanhuyen":"40333"
   },
   {
      "id":"4033317",
      "phuongxa":"Xã Nghi Mỹ",
      "quanhuyen":"40333"
   },
   {
      "id":"4033319",
      "phuongxa":"Xã Nghi Phương",
      "quanhuyen":"40333"
   },
   {
      "id":"4033323",
      "phuongxa":"Xã Nghi Quang",
      "quanhuyen":"40333"
   },
   {
      "id":"4033325",
      "phuongxa":"Xã Nghi Thiết",
      "quanhuyen":"40333"
   },
   {
      "id":"4033327",
      "phuongxa":"Xã Nghi Xá",
      "quanhuyen":"40333"
   },
   {
      "id":"4033329",
      "phuongxa":"Xã Nghi Hợp",
      "quanhuyen":"40333"
   },
   {
      "id":"4033331",
      "phuongxa":"Xã Nghi Khánh",
      "quanhuyen":"40333"
   },
   {
      "id":"4033333",
      "phuongxa":"Xã Nghi Long",
      "quanhuyen":"40333"
   },
   {
      "id":"4033335",
      "phuongxa":"Xã Nghi Thịnh",
      "quanhuyen":"40333"
   },
   {
      "id":"4033337",
      "phuongxa":"Xã Nghi Công",
      "quanhuyen":"40333"
   },
   {
      "id":"4033339",
      "phuongxa":"Xã Nghi Diên",
      "quanhuyen":"40333"
   },
   {
      "id":"4033341",
      "phuongxa":"Xã Nghi Trung",
      "quanhuyen":"40333"
   },
   {
      "id":"4033343",
      "phuongxa":"Xã Nghi Trường",
      "quanhuyen":"40333"
   },
   {
      "id":"4033345",
      "phuongxa":"Xã Nghi Thạch",
      "quanhuyen":"40333"
   },
   {
      "id":"4033347",
      "phuongxa":"Xã Nghi Vạn",
      "quanhuyen":"40333"
   },
   {
      "id":"4033349",
      "phuongxa":"Xã Nghi Liên",
      "quanhuyen":"40333"
   },
   {
      "id":"4033353",
      "phuongxa":"Xã Nghi Phong",
      "quanhuyen":"40333"
   },
   {
      "id":"4033355",
      "phuongxa":"Xã Nghi Xuân",
      "quanhuyen":"40333"
   },
   {
      "id":"4033357",
      "phuongxa":"Xã Nghi Hoa",
      "quanhuyen":"40333"
   },
   {
      "id":"4033359",
      "phuongxa":"Xã Nghi Kim",
      "quanhuyen":"40333"
   },
   {
      "id":"4033361",
      "phuongxa":"Xã Nghi Đức",
      "quanhuyen":"40333"
   },
   {
      "id":"4033363",
      "phuongxa":"Xã Phúc Thọ",
      "quanhuyen":"40333"
   },
   {
      "id":"4033365",
      "phuongxa":"Xã Nghi Thái",
      "quanhuyen":"40333"
   },
   {
      "id":"4033500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40335"
   },
   {
      "id":"4033501",
      "phuongxa":"Thị trấn Nam Đàn",
      "quanhuyen":"40335"
   },
   {
      "id":"4033503",
      "phuongxa":"Xã Nam Hưng",
      "quanhuyen":"40335"
   },
   {
      "id":"4033505",
      "phuongxa":"Xã Nam Nghĩa",
      "quanhuyen":"40335"
   },
   {
      "id":"4033507",
      "phuongxa":"Xã Nam Thái",
      "quanhuyen":"40335"
   },
   {
      "id":"4033509",
      "phuongxa":"Xã Nam Thanh",
      "quanhuyen":"40335"
   },
   {
      "id":"4033511",
      "phuongxa":"Xã Nam Anh",
      "quanhuyen":"40335"
   },
   {
      "id":"4033513",
      "phuongxa":"Xã Vân Diên",
      "quanhuyen":"40335"
   },
   {
      "id":"4033515",
      "phuongxa":"Xã Nam Xuân",
      "quanhuyen":"40335"
   },
   {
      "id":"4033517",
      "phuongxa":"Xã Xuân Hòa",
      "quanhuyen":"40335"
   },
   {
      "id":"4033519",
      "phuongxa":"Xã Nam Lĩnh",
      "quanhuyen":"40335"
   },
   {
      "id":"4033521",
      "phuongxa":"Xã Nam Giang",
      "quanhuyen":"40335"
   },
   {
      "id":"4033525",
      "phuongxa":"Xã Nam Tân",
      "quanhuyen":"40335"
   },
   {
      "id":"4033527",
      "phuongxa":"Xã Nam Lộc",
      "quanhuyen":"40335"
   },
   {
      "id":"4033529",
      "phuongxa":"Xã Hồng Long",
      "quanhuyen":"40335"
   },
   {
      "id":"4033531",
      "phuongxa":"Xã Hùng Tiến",
      "quanhuyen":"40335"
   },
   {
      "id":"4033533",
      "phuongxa":"Xã Kim Liên",
      "quanhuyen":"40335"
   },
   {
      "id":"4033535",
      "phuongxa":"Xã Xuân Lâm",
      "quanhuyen":"40335"
   },
   {
      "id":"4033537",
      "phuongxa":"Xã Nam Cát",
      "quanhuyen":"40335"
   },
   {
      "id":"4033539",
      "phuongxa":"Xã Khánh Sơn",
      "quanhuyen":"40335"
   },
   {
      "id":"4033541",
      "phuongxa":"Xã Nam Trung",
      "quanhuyen":"40335"
   },
   {
      "id":"4033543",
      "phuongxa":"Xã Nam Phúc",
      "quanhuyen":"40335"
   },
   {
      "id":"4033545",
      "phuongxa":"Xã Nam Cường",
      "quanhuyen":"40335"
   },
   {
      "id":"4033547",
      "phuongxa":"Xã Nam Kim",
      "quanhuyen":"40335"
   },
   {
      "id":"4033700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40337"
   },
   {
      "id":"4033701",
      "phuongxa":"Xã Hưng Trung",
      "quanhuyen":"40337"
   },
   {
      "id":"4033703",
      "phuongxa":"Xã Hưng Yên",
      "quanhuyen":"40337"
   },
   {
      "id":"4033705",
      "phuongxa":"Xã Hưng Tây",
      "quanhuyen":"40337"
   },
   {
      "id":"4033707",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"40337"
   },
   {
      "id":"4033709",
      "phuongxa":"Thị trấn Hưng Nguyên",
      "quanhuyen":"40337"
   },
   {
      "id":"4033711",
      "phuongxa":"Xã Hưng Chính",
      "quanhuyen":"40337"
   },
   {
      "id":"4033713",
      "phuongxa":"Xã Hưng Thông",
      "quanhuyen":"40337"
   },
   {
      "id":"4033717",
      "phuongxa":"Xã Hưng Mỹ",
      "quanhuyen":"40337"
   },
   {
      "id":"4033721",
      "phuongxa":"Xã Hưng Lĩnh",
      "quanhuyen":"40337"
   },
   {
      "id":"4033723",
      "phuongxa":"Xã Hưng Long",
      "quanhuyen":"40337"
   },
   {
      "id":"4033725",
      "phuongxa":"Xã Hưng Xá",
      "quanhuyen":"40337"
   },
   {
      "id":"4033727",
      "phuongxa":"Xã Hưng Tiến",
      "quanhuyen":"40337"
   },
   {
      "id":"4033729",
      "phuongxa":"Xã Hưng Thắng",
      "quanhuyen":"40337"
   },
   {
      "id":"4033731",
      "phuongxa":"Xã Hưng Phú",
      "quanhuyen":"40337"
   },
   {
      "id":"4033735",
      "phuongxa":"Xã Hưng Xuân",
      "quanhuyen":"40337"
   },
   {
      "id":"4033737",
      "phuongxa":"Xã Hưng Châu",
      "quanhuyen":"40337"
   },
   {
      "id":"4033739",
      "phuongxa":"Xã Hưng Lam",
      "quanhuyen":"40337"
   },
   {
      "id":"4033741",
      "phuongxa":"Xã Hưng Phúc",
      "quanhuyen":"40337"
   },
   {
      "id":"4033743",
      "phuongxa":"Xã Hưng Khánh",
      "quanhuyen":"40337"
   },
   {
      "id":"4033900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40339"
   },
   {
      "id":"4033901",
      "phuongxa":"Phường Hoà Hiếu",
      "quanhuyen":"40339"
   },
   {
      "id":"4033903",
      "phuongxa":"Phường Quang Tiến",
      "quanhuyen":"40339"
   },
   {
      "id":"4033905",
      "phuongxa":"Phường Quang Phong",
      "quanhuyen":"40339"
   },
   {
      "id":"4033909",
      "phuongxa":"Xã Nghĩa Hoà",
      "quanhuyen":"40339"
   },
   {
      "id":"4033911",
      "phuongxa":"Xã Tây Hiếu",
      "quanhuyen":"40339"
   },
   {
      "id":"4033913",
      "phuongxa":"Xã Nghĩa Mỹ",
      "quanhuyen":"40339"
   },
   {
      "id":"4033915",
      "phuongxa":"Xã Nghĩa Thuận",
      "quanhuyen":"40339"
   },
   {
      "id":"4033917",
      "phuongxa":"Xã Đông Hiếu",
      "quanhuyen":"40339"
   },
   {
      "id":"4033919",
      "phuongxa":"Xã Nghĩa Tiến",
      "quanhuyen":"40339"
   },
   {
      "id":"4050000",
      "phuongxa":"Không xác định",
      "quanhuyen":"40500"
   },
   {
      "id":"4050100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40501"
   },
   {
      "id":"4050101",
      "phuongxa":"Phường Bắc Hà",
      "quanhuyen":"40501"
   },
   {
      "id":"4050103",
      "phuongxa":"Phường Nam Hà",
      "quanhuyen":"40501"
   },
   {
      "id":"4050105",
      "phuongxa":"Phường Trần Phú",
      "quanhuyen":"40501"
   },
   {
      "id":"4050107",
      "phuongxa":"Phường Tân Giang",
      "quanhuyen":"40501"
   },
   {
      "id":"4050109",
      "phuongxa":"Xã Thạch Linh",
      "quanhuyen":"40501"
   },
   {
      "id":"4050111",
      "phuongxa":"Xã Thạch Trung",
      "quanhuyen":"40501"
   },
   {
      "id":"4050113",
      "phuongxa":"Xã Thạch Phú",
      "quanhuyen":"40501"
   },
   {
      "id":"4050115",
      "phuongxa":"Xã Thạch Quí",
      "quanhuyen":"40501"
   },
   {
      "id":"4050119",
      "phuongxa":"Xã Thạch Yên",
      "quanhuyen":"40501"
   },
   {
      "id":"4050300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40503"
   },
   {
      "id":"4050301",
      "phuongxa":"Phường Bắc Hồng",
      "quanhuyen":"40503"
   },
   {
      "id":"4050303",
      "phuongxa":"Phường Nam Hồng",
      "quanhuyen":"40503"
   },
   {
      "id":"4050305",
      "phuongxa":"Xã Trung Lương",
      "quanhuyen":"40503"
   },
   {
      "id":"4050307",
      "phuongxa":"Xã Đức Thuận",
      "quanhuyen":"40503"
   },
   {
      "id":"4050309",
      "phuongxa":"Xã Thuận Lộc",
      "quanhuyen":"40503"
   },
   {
      "id":"4050311",
      "phuongxa":"Xã Đậu Liêu",
      "quanhuyen":"40503"
   },
   {
      "id":"4050500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40505"
   },
   {
      "id":"4050501",
      "phuongxa":"Thị trấn Nghi Xuân",
      "quanhuyen":"40505"
   },
   {
      "id":"4050503",
      "phuongxa":"Thị trấn Xuân An",
      "quanhuyen":"40505"
   },
   {
      "id":"4050505",
      "phuongxa":"Xã Xuân Hội",
      "quanhuyen":"40505"
   },
   {
      "id":"4030907",
      "phuongxa":"Xã Mỹ Lý",
      "quanhuyen":"40309"
   },
   {
      "id":"4030909",
      "phuongxa":"Xã Đoọc Mạy",
      "quanhuyen":"40309"
   },
   {
      "id":"4030911",
      "phuongxa":"Xã Na Loi",
      "quanhuyen":"40309"
   },
   {
      "id":"4030913",
      "phuongxa":"Xã Huồi Tụ",
      "quanhuyen":"40309"
   },
   {
      "id":"4030915",
      "phuongxa":"Xã Mường Lống",
      "quanhuyen":"40309"
   },
   {
      "id":"4030917",
      "phuongxa":"Xã Nậm Cắn",
      "quanhuyen":"40309"
   },
   {
      "id":"4030921",
      "phuongxa":"Xã Phà Đánh",
      "quanhuyen":"40309"
   },
   {
      "id":"4030923",
      "phuongxa":"Xã Hữu Lập",
      "quanhuyen":"40309"
   },
   {
      "id":"4030925",
      "phuongxa":"Xã Bảo Thắng",
      "quanhuyen":"40309"
   },
   {
      "id":"4030927",
      "phuongxa":"Xã Tà Cạ",
      "quanhuyen":"40309"
   },
   {
      "id":"4030929",
      "phuongxa":"Xã Mường Típ",
      "quanhuyen":"40309"
   },
   {
      "id":"4030931",
      "phuongxa":"Xã Tây Sơn",
      "quanhuyen":"40309"
   },
   {
      "id":"4030933",
      "phuongxa":"Xã Hữu Kiệm",
      "quanhuyen":"40309"
   },
   {
      "id":"4030935",
      "phuongxa":"Xã Chiêu Lưu",
      "quanhuyen":"40309"
   },
   {
      "id":"4030937",
      "phuongxa":"Xã Mường ải",
      "quanhuyen":"40309"
   },
   {
      "id":"4030939",
      "phuongxa":"Xã Na Ngoi",
      "quanhuyen":"40309"
   },
   {
      "id":"4030941",
      "phuongxa":"Xã Nậm Càn",
      "quanhuyen":"40309"
   },
   {
      "id":"4031100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40311"
   },
   {
      "id":"4031101",
      "phuongxa":"Thị trấn Quỳ Hợp",
      "quanhuyen":"40311"
   },
   {
      "id":"4031103",
      "phuongxa":"Xã Minh Hợp",
      "quanhuyen":"40311"
   },
   {
      "id":"4031105",
      "phuongxa":"Xã Châu Hồng",
      "quanhuyen":"40311"
   },
   {
      "id":"4031107",
      "phuongxa":"Xã Châu Tiến",
      "quanhuyen":"40311"
   },
   {
      "id":"4031109",
      "phuongxa":"Xã Yên Hợp",
      "quanhuyen":"40311"
   },
   {
      "id":"4031111",
      "phuongxa":"Xã Châu Thành",
      "quanhuyen":"40311"
   },
   {
      "id":"4031115",
      "phuongxa":"Xã Châu Lộc",
      "quanhuyen":"40311"
   },
   {
      "id":"4031117",
      "phuongxa":"Xã Tam Hợp",
      "quanhuyen":"40311"
   },
   {
      "id":"4031121",
      "phuongxa":"Xã Châu Quang",
      "quanhuyen":"40311"
   },
   {
      "id":"4031123",
      "phuongxa":"Xã Thọ Hợp",
      "quanhuyen":"40311"
   },
   {
      "id":"4031125",
      "phuongxa":"Xã Nghĩa Xuân",
      "quanhuyen":"40311"
   },
   {
      "id":"4031127",
      "phuongxa":"Xã Châu Thái",
      "quanhuyen":"40311"
   },
   {
      "id":"4031129",
      "phuongxa":"Xã Châu Đình",
      "quanhuyen":"40311"
   },
   {
      "id":"4031133",
      "phuongxa":"Xã Văn Lợi",
      "quanhuyen":"40311"
   },
   {
      "id":"4031135",
      "phuongxa":"Xã Nam Sơn",
      "quanhuyen":"40311"
   },
   {
      "id":"4031137",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"40311"
   },
   {
      "id":"4031139",
      "phuongxa":"Xã Hạ Sơn",
      "quanhuyen":"40311"
   },
   {
      "id":"4031141",
      "phuongxa":"Xã Đồng Hợp",
      "quanhuyen":"40311"
   },
   {
      "id":"4031301",
      "phuongxa":"Thị trấn Thái Hòa",
      "quanhuyen":"40313"
   },
   {
      "id":"4031303",
      "phuongxa":"Xã Nghĩa Hồng",
      "quanhuyen":"40313"
   },
   {
      "id":"4031305",
      "phuongxa":"Xã Nghĩa Sơn",
      "quanhuyen":"40313"
   },
   {
      "id":"4031307",
      "phuongxa":"Xã Nghĩa Bình",
      "quanhuyen":"40313"
   },
   {
      "id":"4031309",
      "phuongxa":"Xã Đông Hiếu",
      "quanhuyen":"40313"
   },
   {
      "id":"4031311",
      "phuongxa":"Xã Tây Hiếu",
      "quanhuyen":"40313"
   },
   {
      "id":"4031315",
      "phuongxa":"Xã Nghĩa Tân",
      "quanhuyen":"40313"
   },
   {
      "id":"4031317",
      "phuongxa":"Xã Nghĩa Mai",
      "quanhuyen":"40313"
   },
   {
      "id":"4031319",
      "phuongxa":"Xã Nghĩa Yên",
      "quanhuyen":"40313"
   },
   {
      "id":"4031321",
      "phuongxa":"Xã Nghĩa Lâm",
      "quanhuyen":"40313"
   },
   {
      "id":"4031323",
      "phuongxa":"Xã Nghĩa Lạc",
      "quanhuyen":"40313"
   },
   {
      "id":"4031325",
      "phuongxa":"Xã Nghĩa Hưng",
      "quanhuyen":"40313"
   },
   {
      "id":"4031327",
      "phuongxa":"Xã Nghĩa Thịnh",
      "quanhuyen":"40313"
   },
   {
      "id":"4031329",
      "phuongxa":"Xã Nghĩa Minh",
      "quanhuyen":"40313"
   },
   {
      "id":"4031331",
      "phuongxa":"Xã Nghĩa Lợi",
      "quanhuyen":"40313"
   },
   {
      "id":"4031333",
      "phuongxa":"Xã Nghĩa Thọ",
      "quanhuyen":"40313"
   },
   {
      "id":"4031335",
      "phuongxa":"Xã Nghĩa Thắng",
      "quanhuyen":"40313"
   },
   {
      "id":"4031337",
      "phuongxa":"Xã Nghĩa Quang",
      "quanhuyen":"40313"
   },
   {
      "id":"4031339",
      "phuongxa":"Xã Nghĩa Trung",
      "quanhuyen":"40313"
   },
   {
      "id":"4031341",
      "phuongxa":"Xã Nghĩa Hội",
      "quanhuyen":"40313"
   },
   {
      "id":"4031343",
      "phuongxa":"Xã Nghĩa Liên",
      "quanhuyen":"40313"
   },
   {
      "id":"4031345",
      "phuongxa":"Xã Nghĩa Tiến",
      "quanhuyen":"40313"
   },
   {
      "id":"4031347",
      "phuongxa":"Xã Nghĩa Hòa",
      "quanhuyen":"40313"
   },
   {
      "id":"4031349",
      "phuongxa":"Xã Nghĩa Mỹ",
      "quanhuyen":"40313"
   },
   {
      "id":"4031351",
      "phuongxa":"Xã Nghĩa An",
      "quanhuyen":"40313"
   },
   {
      "id":"4031353",
      "phuongxa":"Xã Nghĩa Long",
      "quanhuyen":"40313"
   },
   {
      "id":"4031355",
      "phuongxa":"Xã Nghĩa Thuận",
      "quanhuyen":"40313"
   },
   {
      "id":"4031357",
      "phuongxa":"Xã Nghĩa Đức",
      "quanhuyen":"40313"
   },
   {
      "id":"4031361",
      "phuongxa":"Xã Nghĩa Lộc",
      "quanhuyen":"40313"
   },
   {
      "id":"4031363",
      "phuongxa":"Xã Nghĩa Phú",
      "quanhuyen":"40313"
   },
   {
      "id":"4031500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40315"
   },
   {
      "id":"4031501",
      "phuongxa":"Thị trấn Hòa Bình",
      "quanhuyen":"40315"
   },
   {
      "id":"4031503",
      "phuongxa":"Xã Tam Quang",
      "quanhuyen":"40315"
   },
   {
      "id":"4031505",
      "phuongxa":"Xã Tam Đình",
      "quanhuyen":"40315"
   },
   {
      "id":"4091339",
      "phuongxa":"Xã Hải Sơn",
      "quanhuyen":"40913"
   },
   {
      "id":"4091341",
      "phuongxa":"Xã Hải Chánh",
      "quanhuyen":"40913"
   },
   {
      "id":"4091500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40915"
   },
   {
      "id":"4091501",
      "phuongxa":"Thị trấn Khe Sanh",
      "quanhuyen":"40915"
   },
   {
      "id":"4091503",
      "phuongxa":"Thị trấn Lao Bảo",
      "quanhuyen":"40915"
   },
   {
      "id":"4091505",
      "phuongxa":"Xã Hướng Lập",
      "quanhuyen":"40915"
   },
   {
      "id":"4091507",
      "phuongxa":"Xã Hướng Phùng",
      "quanhuyen":"40915"
   },
   {
      "id":"4091509",
      "phuongxa":"Xã Hướng Sơn",
      "quanhuyen":"40915"
   },
   {
      "id":"4091511",
      "phuongxa":"Xã Hướng Linh",
      "quanhuyen":"40915"
   },
   {
      "id":"4091513",
      "phuongxa":"Xã Tân Hợp",
      "quanhuyen":"40915"
   },
   {
      "id":"4091515",
      "phuongxa":"Xã Hướng Tân",
      "quanhuyen":"40915"
   },
   {
      "id":"4091517",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"40915"
   },
   {
      "id":"4091519",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"40915"
   },
   {
      "id":"4091521",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"40915"
   },
   {
      "id":"4091523",
      "phuongxa":"Xã Tân Liên",
      "quanhuyen":"40915"
   },
   {
      "id":"4091527",
      "phuongxa":"Xã Thuận",
      "quanhuyen":"40915"
   },
   {
      "id":"4091529",
      "phuongxa":"Xã Hướng Lộc",
      "quanhuyen":"40915"
   },
   {
      "id":"4091531",
      "phuongxa":"Xã Ba Tầng",
      "quanhuyen":"40915"
   },
   {
      "id":"4091533",
      "phuongxa":"Xã Thanh",
      "quanhuyen":"40915"
   },
   {
      "id":"4091535",
      "phuongxa":"Xã  A Dơi",
      "quanhuyen":"40915"
   },
   {
      "id":"4091537",
      "phuongxa":"Xã A Xing",
      "quanhuyen":"40915"
   },
   {
      "id":"4091539",
      "phuongxa":"Xã A Túc",
      "quanhuyen":"40915"
   },
   {
      "id":"4091541",
      "phuongxa":"Xã Xy",
      "quanhuyen":"40915"
   },
   {
      "id":"4091700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40917"
   },
   {
      "id":"4091701",
      "phuongxa":"Xã Mò ó",
      "quanhuyen":"40917"
   },
   {
      "id":"4091703",
      "phuongxa":"Xã Hướng Hiệp",
      "quanhuyen":"40917"
   },
   {
      "id":"4091705",
      "phuongxa":"Xã Đa Krông",
      "quanhuyen":"40917"
   },
   {
      "id":"4091709",
      "phuongxa":"Xã Ba Lòng",
      "quanhuyen":"40917"
   },
   {
      "id":"4091711",
      "phuongxa":"Xã Hải Phúc",
      "quanhuyen":"40917"
   },
   {
      "id":"4091713",
      "phuongxa":"Xã Ba Nang",
      "quanhuyen":"40917"
   },
   {
      "id":"4091717",
      "phuongxa":"Xã Húc Nghì",
      "quanhuyen":"40917"
   },
   {
      "id":"4091719",
      "phuongxa":"Xã A Vao",
      "quanhuyen":"40917"
   },
   {
      "id":"4091721",
      "phuongxa":"Xã Tà Rụt",
      "quanhuyen":"40917"
   },
   {
      "id":"4091725",
      "phuongxa":"Xã A Ngo",
      "quanhuyen":"40917"
   },
   {
      "id":"4110000",
      "phuongxa":"Không xác định",
      "quanhuyen":"41100"
   },
   {
      "id":"4110100",
      "phuongxa":"Không xác định",
      "quanhuyen":"41101"
   },
   {
      "id":"4110101",
      "phuongxa":"Phường Phú Thuận",
      "quanhuyen":"41101"
   },
   {
      "id":"4110105",
      "phuongxa":"Phường Tây Lộc",
      "quanhuyen":"41101"
   },
   {
      "id":"4110107",
      "phuongxa":"Phường Thuận Lộc",
      "quanhuyen":"41101"
   },
   {
      "id":"4110109",
      "phuongxa":"Phường Phú Hiệp",
      "quanhuyen":"41101"
   },
   {
      "id":"4110111",
      "phuongxa":"Phường Phú Hậu",
      "quanhuyen":"41101"
   },
   {
      "id":"4110113",
      "phuongxa":"Phường Thuận Hòa",
      "quanhuyen":"41101"
   },
   {
      "id":"4110117",
      "phuongxa":"Phường Phú Hòa",
      "quanhuyen":"41101"
   },
   {
      "id":"4110119",
      "phuongxa":"Phường Phú Cát",
      "quanhuyen":"41101"
   },
   {
      "id":"4110121",
      "phuongxa":"Phường Kim Long",
      "quanhuyen":"41101"
   },
   {
      "id":"4110123",
      "phuongxa":"Phường Vĩ Dạ",
      "quanhuyen":"41101"
   },
   {
      "id":"4110125",
      "phuongxa":"Phường Phường Đúc",
      "quanhuyen":"41101"
   },
   {
      "id":"4110127",
      "phuongxa":"Phường Vĩnh Ninh",
      "quanhuyen":"41101"
   },
   {
      "id":"4110129",
      "phuongxa":"Phường Phú Hội",
      "quanhuyen":"41101"
   },
   {
      "id":"4110131",
      "phuongxa":"Phường Phú Nhuận",
      "quanhuyen":"41101"
   },
   {
      "id":"4110133",
      "phuongxa":"Phường Xuân Phú",
      "quanhuyen":"41101"
   },
   {
      "id":"4110135",
      "phuongxa":"Phường Trường An",
      "quanhuyen":"41101"
   },
   {
      "id":"4110137",
      "phuongxa":"Phường Phước Vĩnh",
      "quanhuyen":"41101"
   },
   {
      "id":"4110139",
      "phuongxa":"Phường An Cựu",
      "quanhuyen":"41101"
   },
   {
      "id":"4110141",
      "phuongxa":"Xã Hương Sơ",
      "quanhuyen":"41101"
   },
   {
      "id":"4110143",
      "phuongxa":"Phường Thủy Biều",
      "quanhuyen":"41101"
   },
   {
      "id":"4110147",
      "phuongxa":"Xã Thủy Xuân",
      "quanhuyen":"41101"
   },
   {
      "id":"4110149",
      "phuongxa":"Xã Thủy An",
      "quanhuyen":"41101"
   },
   {
      "id":"4110300",
      "phuongxa":"Không xác định",
      "quanhuyen":"41103"
   },
   {
      "id":"4110301",
      "phuongxa":"Thị trấn Phong Điền",
      "quanhuyen":"41103"
   },
   {
      "id":"4110303",
      "phuongxa":"Xã Điền Hương",
      "quanhuyen":"41103"
   },
   {
      "id":"4110305",
      "phuongxa":"Xã Điền Môn",
      "quanhuyen":"41103"
   },
   {
      "id":"4110307",
      "phuongxa":"Xã Phong Bình",
      "quanhuyen":"41103"
   },
   {
      "id":"4110309",
      "phuongxa":"Xã Phong Chương",
      "quanhuyen":"41103"
   },
   {
      "id":"4110311",
      "phuongxa":"Xã Điền Lộc",
      "quanhuyen":"41103"
   },
   {
      "id":"4110313",
      "phuongxa":"Xã Phong Hòa",
      "quanhuyen":"41103"
   },
   {
      "id":"4110315",
      "phuongxa":"Xã Điền Hòa",
      "quanhuyen":"41103"
   },
   {
      "id":"4110317",
      "phuongxa":"Xã Phong Hải",
      "quanhuyen":"41103"
   },
   {
      "id":"4110319",
      "phuongxa":"Xã Điền Hải",
      "quanhuyen":"41103"
   },
   {
      "id":"4110321",
      "phuongxa":"Xã Phong Mỹ",
      "quanhuyen":"41103"
   },
   {
      "id":"4110323",
      "phuongxa":"Xã Phong Thu",
      "quanhuyen":"41103"
   },
   {
      "id":"4110325",
      "phuongxa":"Xã Phong Hiền",
      "quanhuyen":"41103"
   },
   {
      "id":"4110327",
      "phuongxa":"Xã Phong An",
      "quanhuyen":"41103"
   },
   {
      "id":"4110329",
      "phuongxa":"Xã Phong Xuân",
      "quanhuyen":"41103"
   },
   {
      "id":"4110331",
      "phuongxa":"Xã Phong Sơn",
      "quanhuyen":"41103"
   },
   {
      "id":"4110500",
      "phuongxa":"Không xác định",
      "quanhuyen":"41105"
   },
   {
      "id":"4110501",
      "phuongxa":"Thị trấn Sịa",
      "quanhuyen":"41105"
   },
   {
      "id":"4110505",
      "phuongxa":"Xã Quảng Lợi",
      "quanhuyen":"41105"
   },
   {
      "id":"4110507",
      "phuongxa":"Xã Quảng Ngạn",
      "quanhuyen":"41105"
   },
   {
      "id":"4110509",
      "phuongxa":"Xã Quảng Vinh",
      "quanhuyen":"41105"
   },
   {
      "id":"4110511",
      "phuongxa":"Xã Quảng Phước",
      "quanhuyen":"41105"
   },
   {
      "id":"4110513",
      "phuongxa":"Xã Quảng Công",
      "quanhuyen":"41105"
   },
   {
      "id":"4110515",
      "phuongxa":"Xã Quảng Phú",
      "quanhuyen":"41105"
   },
   {
      "id":"4110517",
      "phuongxa":"Xã Quảng Thọ",
      "quanhuyen":"41105"
   },
   {
      "id":"4110519",
      "phuongxa":"Xã Quảng An",
      "quanhuyen":"41105"
   },
   {
      "id":"4110521",
      "phuongxa":"Xã Quảng Thành",
      "quanhuyen":"41105"
   },
   {
      "id":"4110700",
      "phuongxa":"Không xác định",
      "quanhuyen":"41107"
   },
   {
      "id":"4110701",
      "phuongxa":"Phường Tứ Hạ",
      "quanhuyen":"41107"
   },
   {
      "id":"4110703",
      "phuongxa":"Xã Hải Dương",
      "quanhuyen":"41107"
   },
   {
      "id":"4110705",
      "phuongxa":"Xã Hương Phong",
      "quanhuyen":"41107"
   },
   {
      "id":"4110707",
      "phuongxa":"Phường Hương Vân",
      "quanhuyen":"41107"
   },
   {
      "id":"4110711",
      "phuongxa":"Xã Hương Toàn",
      "quanhuyen":"41107"
   },
   {
      "id":"4110713",
      "phuongxa":"Xã Hương Vinh",
      "quanhuyen":"41107"
   },
   {
      "id":"4110715",
      "phuongxa":"Xã Hương Xuân",
      "quanhuyen":"41107"
   },
   {
      "id":"4110717",
      "phuongxa":"Phường Hương Chữ",
      "quanhuyen":"41107"
   },
   {
      "id":"4110719",
      "phuongxa":"Phường Hương An",
      "quanhuyen":"41107"
   },
   {
      "id":"4110721",
      "phuongxa":"Xã Hương Bình",
      "quanhuyen":"41107"
   },
   {
      "id":"4110725",
      "phuongxa":"Xã Hồng Tiến",
      "quanhuyen":"41107"
   },
   {
      "id":"4110729",
      "phuongxa":"Xã Bình Thành",
      "quanhuyen":"41107"
   },
   {
      "id":"4110731",
      "phuongxa":"Xã Hương Thọ",
      "quanhuyen":"41107"
   },
   {
      "id":"4110900",
      "phuongxa":"Không xác định",
      "quanhuyen":"41109"
   },
   {
      "id":"4110901",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"41109"
   },
   {
      "id":"4110905",
      "phuongxa":"Xã Phú Thanh",
      "quanhuyen":"41109"
   },
   {
      "id":"4110907",
      "phuongxa":"Xã Phú Mậu",
      "quanhuyen":"41109"
   },
   {
      "id":"4110909",
      "phuongxa":"Xã Phú Dương",
      "quanhuyen":"41109"
   },
   {
      "id":"4110911",
      "phuongxa":"Xã Phú Thuận",
      "quanhuyen":"41109"
   },
   {
      "id":"4110913",
      "phuongxa":"Xã Phú Thượng",
      "quanhuyen":"41109"
   },
   {
      "id":"4110915",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"41109"
   },
   {
      "id":"4110919",
      "phuongxa":"Xã Phú Xuân",
      "quanhuyen":"41109"
   },
   {
      "id":"4110921",
      "phuongxa":"Xã Phú Hồ",
      "quanhuyen":"41109"
   },
   {
      "id":"4110923",
      "phuongxa":"Xã Phú Hải",
      "quanhuyen":"41109"
   },
   {
      "id":"4110925",
      "phuongxa":"Xã Phú Diên",
      "quanhuyen":"41109"
   },
   {
      "id":"4110927",
      "phuongxa":"Xã Phú Lương",
      "quanhuyen":"41109"
   },
   {
      "id":"4110929",
      "phuongxa":"Xã Phú Đa",
      "quanhuyen":"41109"
   },
   {
      "id":"4110931",
      "phuongxa":"Xã Vinh Xuân",
      "quanhuyen":"41109"
   },
   {
      "id":"4110933",
      "phuongxa":"Xã Vinh Thanh",
      "quanhuyen":"41109"
   },
   {
      "id":"4110935",
      "phuongxa":"Xã Vinh Thái",
      "quanhuyen":"41109"
   },
   {
      "id":"4110937",
      "phuongxa":"Xã Vinh Phú",
      "quanhuyen":"41109"
   },
   {
      "id":"4110941",
      "phuongxa":"Xã Vinh Hà",
      "quanhuyen":"41109"
   },
   {
      "id":"4111100",
      "phuongxa":"Không xác định",
      "quanhuyen":"41111"
   },
   {
      "id":"4111101",
      "phuongxa":"Phường Phú Bài",
      "quanhuyen":"41111"
   },
   {
      "id":"4111103",
      "phuongxa":"Xã Thủy Vân",
      "quanhuyen":"41111"
   },
   {
      "id":"4111105",
      "phuongxa":"Xã Thủy Thanh",
      "quanhuyen":"41111"
   },
   {
      "id":"4111107",
      "phuongxa":"Xã Thủy Dương",
      "quanhuyen":"41111"
   },
   {
      "id":"4111109",
      "phuongxa":"Xã Thủy Bằng",
      "quanhuyen":"41111"
   },
   {
      "id":"4111111",
      "phuongxa":"Xã Thủy Phương",
      "quanhuyen":"41111"
   },
   {
      "id":"4111113",
      "phuongxa":"Xã Thủy Châu",
      "quanhuyen":"41111"
   },
   {
      "id":"4111115",
      "phuongxa":"Xã Thủy Lương",
      "quanhuyen":"41111"
   },
   {
      "id":"4111117",
      "phuongxa":"Xã Thủy Tân",
      "quanhuyen":"41111"
   },
   {
      "id":"4111119",
      "phuongxa":"Xã Thủy Phù",
      "quanhuyen":"41111"
   },
   {
      "id":"4111121",
      "phuongxa":"Xã Dương Hòa",
      "quanhuyen":"41111"
   },
   {
      "id":"4111123",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"41111"
   },
   {
      "id":"4111300",
      "phuongxa":"Không xác định",
      "quanhuyen":"41113"
   },
   {
      "id":"4111301",
      "phuongxa":"Thị trấn Phú Lộc",
      "quanhuyen":"41113"
   },
   {
      "id":"4111303",
      "phuongxa":"Xã Vinh Hưng",
      "quanhuyen":"41113"
   },
   {
      "id":"4111305",
      "phuongxa":"Xã Lộc Bổn",
      "quanhuyen":"41113"
   },
   {
      "id":"4111309",
      "phuongxa":"Xã Lộc Sơn",
      "quanhuyen":"41113"
   },
   {
      "id":"4111311",
      "phuongxa":"Xã Vinh Mỹ",
      "quanhuyen":"41113"
   },
   {
      "id":"4051377",
      "phuongxa":"Xã Thạch Bình",
      "quanhuyen":"40513"
   },
   {
      "id":"4051379",
      "phuongxa":"Xã Thạch Hương",
      "quanhuyen":"40513"
   },
   {
      "id":"4051381",
      "phuongxa":"Xã Thạch Điền",
      "quanhuyen":"40513"
   },
   {
      "id":"4051383",
      "phuongxa":"Xã Nam Hương",
      "quanhuyen":"40513"
   },
   {
      "id":"4051385",
      "phuongxa":"Xã Thạch Lâm",
      "quanhuyen":"40513"
   },
   {
      "id":"4051500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40515"
   },
   {
      "id":"4051501",
      "phuongxa":"Thị trấn Cẩm Xuyên",
      "quanhuyen":"40515"
   },
   {
      "id":"4051503",
      "phuongxa":"Xã Cẩm Vĩnh",
      "quanhuyen":"40515"
   },
   {
      "id":"4051505",
      "phuongxa":"Xã Cẩm Bình",
      "quanhuyen":"40515"
   },
   {
      "id":"4051507",
      "phuongxa":"Xã Cẩm Yên",
      "quanhuyen":"40515"
   },
   {
      "id":"4051509",
      "phuongxa":"Xã Cẩm Hòa",
      "quanhuyen":"40515"
   },
   {
      "id":"4051511",
      "phuongxa":"Xã Cẩm Nam",
      "quanhuyen":"40515"
   },
   {
      "id":"4051513",
      "phuongxa":"Xã Cẩm Dương",
      "quanhuyen":"40515"
   },
   {
      "id":"4051515",
      "phuongxa":"Xã Cẩm Thành",
      "quanhuyen":"40515"
   },
   {
      "id":"4051517",
      "phuongxa":"Xã Cẩm Quang",
      "quanhuyen":"40515"
   },
   {
      "id":"4051519",
      "phuongxa":"Xã Cẩm Huy",
      "quanhuyen":"40515"
   },
   {
      "id":"4051523",
      "phuongxa":"Xã Cẩm Long",
      "quanhuyen":"40515"
   },
   {
      "id":"4051525",
      "phuongxa":"Xã Cẩm Nhượng",
      "quanhuyen":"40515"
   },
   {
      "id":"4051527",
      "phuongxa":"Xã Cẩm Thạch",
      "quanhuyen":"40515"
   },
   {
      "id":"4051529",
      "phuongxa":"Xã Cẩm Duệ",
      "quanhuyen":"40515"
   },
   {
      "id":"4051531",
      "phuongxa":"Xã Cẩm Phúc",
      "quanhuyen":"40515"
   },
   {
      "id":"4051535",
      "phuongxa":"Xã Cẩm Quan",
      "quanhuyen":"40515"
   },
   {
      "id":"4051537",
      "phuongxa":"Xã Cẩm Hà",
      "quanhuyen":"40515"
   },
   {
      "id":"4051539",
      "phuongxa":"Xã Cẩm Mỹ",
      "quanhuyen":"40515"
   },
   {
      "id":"4051541",
      "phuongxa":"Xã Cẩm Hưng",
      "quanhuyen":"40515"
   },
   {
      "id":"4051543",
      "phuongxa":"Xã Cẩm Lộc",
      "quanhuyen":"40515"
   },
   {
      "id":"4051545",
      "phuongxa":"Xã Cẩm Trung",
      "quanhuyen":"40515"
   },
   {
      "id":"4051549",
      "phuongxa":"Xã Cẩm Sơn",
      "quanhuyen":"40515"
   },
   {
      "id":"4051551",
      "phuongxa":"Xã Cẩm Lạc",
      "quanhuyen":"40515"
   },
   {
      "id":"4051553",
      "phuongxa":"Xã Cẩm Minh",
      "quanhuyen":"40515"
   },
   {
      "id":"4051700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40517"
   },
   {
      "id":"4051701",
      "phuongxa":"Thị trấn Hương Khê",
      "quanhuyen":"40517"
   },
   {
      "id":"4051705",
      "phuongxa":"Xã Vũ Quang",
      "quanhuyen":"40517"
   },
   {
      "id":"4051707",
      "phuongxa":"Xã Hương Điền",
      "quanhuyen":"40517"
   },
   {
      "id":"4051709",
      "phuongxa":"Xã Hương Đại",
      "quanhuyen":"40517"
   },
   {
      "id":"4051711",
      "phuongxa":"Xã Hương Minh",
      "quanhuyen":"40517"
   },
   {
      "id":"4051715",
      "phuongxa":"Xã Phương Điền",
      "quanhuyen":"40517"
   },
   {
      "id":"4051719",
      "phuongxa":"Xã Hà Linh",
      "quanhuyen":"40517"
   },
   {
      "id":"4051721",
      "phuongxa":"Xã Phúc Đồng",
      "quanhuyen":"40517"
   },
   {
      "id":"4051725",
      "phuongxa":"Xã Hương Bình",
      "quanhuyen":"40517"
   },
   {
      "id":"4051727",
      "phuongxa":"Xã Hương Long",
      "quanhuyen":"40517"
   },
   {
      "id":"4051729",
      "phuongxa":"Xã Hương Thủy",
      "quanhuyen":"40517"
   },
   {
      "id":"4051731",
      "phuongxa":"Xã Hương Giang",
      "quanhuyen":"40517"
   },
   {
      "id":"4051733",
      "phuongxa":"Xã Gia Phố",
      "quanhuyen":"40517"
   },
   {
      "id":"4051735",
      "phuongxa":"Xã Phú Gia",
      "quanhuyen":"40517"
   },
   {
      "id":"4051737",
      "phuongxa":"Xã Hương Vĩnh",
      "quanhuyen":"40517"
   },
   {
      "id":"4051739",
      "phuongxa":"Xã Hương Xuân",
      "quanhuyen":"40517"
   },
   {
      "id":"4051741",
      "phuongxa":"Xã Phú Phong",
      "quanhuyen":"40517"
   },
   {
      "id":"4051743",
      "phuongxa":"Xã Lộc Yên",
      "quanhuyen":"40517"
   },
   {
      "id":"4051745",
      "phuongxa":"Xã Hương Lâm",
      "quanhuyen":"40517"
   },
   {
      "id":"4051747",
      "phuongxa":"Xã Hương Liên",
      "quanhuyen":"40517"
   },
   {
      "id":"4051749",
      "phuongxa":"Xã Hương Đô",
      "quanhuyen":"40517"
   },
   {
      "id":"4051751",
      "phuongxa":"Xã Phúc Trạch",
      "quanhuyen":"40517"
   },
   {
      "id":"4051753",
      "phuongxa":"Xã Hương Trạch",
      "quanhuyen":"40517"
   },
   {
      "id":"4051900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40519"
   },
   {
      "id":"4051901",
      "phuongxa":"Thị trấn Kỳ Anh",
      "quanhuyen":"40519"
   },
   {
      "id":"4051903",
      "phuongxa":"Xã Kỳ Bắc",
      "quanhuyen":"40519"
   },
   {
      "id":"4051907",
      "phuongxa":"Xã Kỳ Xuân",
      "quanhuyen":"40519"
   },
   {
      "id":"4051909",
      "phuongxa":"Xã Kỳ Giang",
      "quanhuyen":"40519"
   },
   {
      "id":"4051911",
      "phuongxa":"Xã Kỳ Phú",
      "quanhuyen":"40519"
   },
   {
      "id":"4051913",
      "phuongxa":"Xã Kỳ Phong",
      "quanhuyen":"40519"
   },
   {
      "id":"4051915",
      "phuongxa":"Xã Kỳ Sơn",
      "quanhuyen":"40519"
   },
   {
      "id":"4051917",
      "phuongxa":"Xã Kỳ Tây",
      "quanhuyen":"40519"
   },
   {
      "id":"4051919",
      "phuongxa":"Xã Kỳ Hợp",
      "quanhuyen":"40519"
   },
   {
      "id":"4051921",
      "phuongxa":"Xã Kỳ Lâm",
      "quanhuyen":"40519"
   },
   {
      "id":"4051923",
      "phuongxa":"Xã Kỳ Khang",
      "quanhuyen":"40519"
   },
   {
      "id":"4051925",
      "phuongxa":"Xã Kỳ Văn",
      "quanhuyen":"40519"
   },
   {
      "id":"4051927",
      "phuongxa":"Xã Kỳ Lạc",
      "quanhuyen":"40519"
   },
   {
      "id":"4051929",
      "phuongxa":"Xã Kỳ Hà",
      "quanhuyen":"40519"
   },
   {
      "id":"4051931",
      "phuongxa":"Xã Kỳ Hưng",
      "quanhuyen":"40519"
   },
   {
      "id":"4051933",
      "phuongxa":"Xã Kỳ Hải",
      "quanhuyen":"40519"
   },
   {
      "id":"4051935",
      "phuongxa":"Xã Kỳ Châu",
      "quanhuyen":"40519"
   },
   {
      "id":"4051937",
      "phuongxa":"Xã Kỳ Tân",
      "quanhuyen":"40519"
   },
   {
      "id":"4051939",
      "phuongxa":"Xã Kỳ Hoa",
      "quanhuyen":"40519"
   },
   {
      "id":"4051941",
      "phuongxa":"Xã Kỳ Thư",
      "quanhuyen":"40519"
   },
   {
      "id":"4051945",
      "phuongxa":"Xã Kỳ Phương",
      "quanhuyen":"40519"
   },
   {
      "id":"4051947",
      "phuongxa":"Xã Kỳ Lợi",
      "quanhuyen":"40519"
   },
   {
      "id":"4051949",
      "phuongxa":"Xã Kỳ Liên",
      "quanhuyen":"40519"
   },
   {
      "id":"4051951",
      "phuongxa":"Xã Kỳ Ninh",
      "quanhuyen":"40519"
   },
   {
      "id":"4051953",
      "phuongxa":"Xã Kỳ Đồng",
      "quanhuyen":"40519"
   },
   {
      "id":"4051955",
      "phuongxa":"Xã Kỳ Long",
      "quanhuyen":"40519"
   },
   {
      "id":"4051957",
      "phuongxa":"Xã Kỳ Trinh",
      "quanhuyen":"40519"
   },
   {
      "id":"4051959",
      "phuongxa":"Xã Kỳ Thịnh",
      "quanhuyen":"40519"
   },
   {
      "id":"4051961",
      "phuongxa":"Xã Kỳ Thượng",
      "quanhuyen":"40519"
   },
   {
      "id":"4051963",
      "phuongxa":"Xã Kỳ Nam",
      "quanhuyen":"40519"
   },
   {
      "id":"4052100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40521"
   },
   {
      "id":"4052101",
      "phuongxa":"Thị trấn Vũ Quang",
      "quanhuyen":"40521"
   },
   {
      "id":"4052103",
      "phuongxa":"Xã Ân Phú",
      "quanhuyen":"40521"
   },
   {
      "id":"4052105",
      "phuongxa":"Xã Đức Giang",
      "quanhuyen":"40521"
   },
   {
      "id":"4052107",
      "phuongxa":"Xã Đức Lĩnh",
      "quanhuyen":"40521"
   },
   {
      "id":"4052109",
      "phuongxa":"Xã Sơn Thọ",
      "quanhuyen":"40521"
   },
   {
      "id":"4052113",
      "phuongxa":"Xã Đức Bồng",
      "quanhuyen":"40521"
   },
   {
      "id":"4052115",
      "phuongxa":"Xã Đức Liên",
      "quanhuyen":"40521"
   },
   {
      "id":"4052119",
      "phuongxa":"Xã Hương Minh",
      "quanhuyen":"40521"
   },
   {
      "id":"4052121",
      "phuongxa":"Xã Hương Thọ",
      "quanhuyen":"40521"
   },
   {
      "id":"4052300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40523"
   },
   {
      "id":"4052301",
      "phuongxa":"Xã Ích Hậu",
      "quanhuyen":"40523"
   },
   {
      "id":"4052303",
      "phuongxa":"Xã Hồng Lộc",
      "quanhuyen":"40523"
   },
   {
      "id":"4052305",
      "phuongxa":"Xã Phù Lưu",
      "quanhuyen":"40523"
   },
   {
      "id":"4052307",
      "phuongxa":"Xã Bình Lộc",
      "quanhuyen":"40523"
   },
   {
      "id":"4052309",
      "phuongxa":"Xã Tân Lộc",
      "quanhuyen":"40523"
   },
   {
      "id":"4052313",
      "phuongxa":"Xã Thịnh Lộc",
      "quanhuyen":"40523"
   },
   {
      "id":"4052315",
      "phuongxa":"Xã Thạch Kim",
      "quanhuyen":"40523"
   },
   {
      "id":"4052317",
      "phuongxa":"Xã Thạch Bằng",
      "quanhuyen":"40523"
   },
   {
      "id":"4052319",
      "phuongxa":"Xã Thạch Châu",
      "quanhuyen":"40523"
   },
   {
      "id":"4052321",
      "phuongxa":"Xã Mai Phụ",
      "quanhuyen":"40523"
   },
   {
      "id":"4052325",
      "phuongxa":"Xã Thạch Mỹ",
      "quanhuyen":"40523"
   },
   {
      "id":"4070000",
      "phuongxa":"Không xác định",
      "quanhuyen":"40700"
   },
   {
      "id":"4070100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40701"
   },
   {
      "id":"4070101",
      "phuongxa":"Phường Đồng Sơn",
      "quanhuyen":"40701"
   },
   {
      "id":"4070103",
      "phuongxa":"Phường Bắc Lý",
      "quanhuyen":"40701"
   },
   {
      "id":"4070105",
      "phuongxa":"Phường Nam Lý",
      "quanhuyen":"40701"
   },
   {
      "id":"4070107",
      "phuongxa":"Phường Hải Thành",
      "quanhuyen":"40701"
   },
   {
      "id":"4070109",
      "phuongxa":"Phường Đồng Phú",
      "quanhuyen":"40701"
   },
   {
      "id":"4070111",
      "phuongxa":"Phường Đồng Mỹ",
      "quanhuyen":"40701"
   },
   {
      "id":"4070115",
      "phuongxa":"Phường Phú Hải",
      "quanhuyen":"40701"
   },
   {
      "id":"4070117",
      "phuongxa":"Xã Nghĩa Ninh",
      "quanhuyen":"40701"
   },
   {
      "id":"4070119",
      "phuongxa":"Xã Đức Ninh",
      "quanhuyen":"40701"
   },
   {
      "id":"4070121",
      "phuongxa":"Xã Quang Phú",
      "quanhuyen":"40701"
   },
   {
      "id":"4070123",
      "phuongxa":"Xã Lộc Ninh",
      "quanhuyen":"40701"
   },
   {
      "id":"4070125",
      "phuongxa":"Xã Bảo Ninh",
      "quanhuyen":"40701"
   },
   {
      "id":"4070127",
      "phuongxa":"Xã Thuận Đức",
      "quanhuyen":"40701"
   },
   {
      "id":"4070300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40703"
   },
   {
      "id":"4070301",
      "phuongxa":"Xã Lâm Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070303",
      "phuongxa":"Xã Hương Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070305",
      "phuongxa":"Xã Thanh Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070307",
      "phuongxa":"Xã Kim Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070309",
      "phuongxa":"Xã Lê Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070311",
      "phuongxa":"Xã Thuận Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070313",
      "phuongxa":"Xã Đồng Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070315",
      "phuongxa":"Xã Thạch Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070317",
      "phuongxa":"Xã Đức Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070319",
      "phuongxa":"Xã Phong Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070321",
      "phuongxa":"Xã Mai Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070323",
      "phuongxa":"Xã Ngư Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070325",
      "phuongxa":"Xã Tiến Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070327",
      "phuongxa":"Xã Châu Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070331",
      "phuongxa":"Xã Văn Hóa",
      "quanhuyen":"40703"
   },
   {
      "id":"4070500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40705"
   },
   {
      "id":"4070501",
      "phuongxa":"Xã Dân Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070503",
      "phuongxa":"Xã Hóa Thanh",
      "quanhuyen":"40705"
   },
   {
      "id":"4070505",
      "phuongxa":"Xã Hóa Phúc",
      "quanhuyen":"40705"
   },
   {
      "id":"4070507",
      "phuongxa":"Xã Hồng Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070509",
      "phuongxa":"Xã Hóa Tiến",
      "quanhuyen":"40705"
   },
   {
      "id":"4070511",
      "phuongxa":"Xã Hóa Hợp",
      "quanhuyen":"40705"
   },
   {
      "id":"4070513",
      "phuongxa":"Xã Hóa Sơn",
      "quanhuyen":"40705"
   },
   {
      "id":"4070515",
      "phuongxa":"Xã Xuân Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070517",
      "phuongxa":"Xã Yên Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070519",
      "phuongxa":"Xã Quy Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070521",
      "phuongxa":"Xã Trung Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070523",
      "phuongxa":"Xã Minh Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070525",
      "phuongxa":"Xã Thượng Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070527",
      "phuongxa":"Xã Tân Hóa",
      "quanhuyen":"40705"
   },
   {
      "id":"4070700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40707"
   },
   {
      "id":"4070701",
      "phuongxa":"Phường Ba Đồn",
      "quanhuyen":"82626"
   },
   {
      "id":"4070703",
      "phuongxa":"Xã Quảng Hợp",
      "quanhuyen":"40707"
   },
   {
      "id":"4070705",
      "phuongxa":"Xã Quảng Đông",
      "quanhuyen":"40707"
   },
   {
      "id":"4070709",
      "phuongxa":"Xã Quảng Phú",
      "quanhuyen":"40707"
   },
   {
      "id":"4070711",
      "phuongxa":"Xã Quảng Châu",
      "quanhuyen":"40707"
   },
   {
      "id":"4070715",
      "phuongxa":"Xã Cảnh Dương",
      "quanhuyen":"40707"
   },
   {
      "id":"4070717",
      "phuongxa":"Xã Quảng Hưng",
      "quanhuyen":"40707"
   },
   {
      "id":"4070719",
      "phuongxa":"Xã Quảng Xuân",
      "quanhuyen":"40707"
   },
   {
      "id":"4070721",
      "phuongxa":"Phường Quảng Thọ",
      "quanhuyen":"82626"
   },
   {
      "id":"4070725",
      "phuongxa":"Phường Quảng Thuận",
      "quanhuyen":"82626"
   },
   {
      "id":"4070727",
      "phuongxa":"Phường Quảng Long",
      "quanhuyen":"82626"
   },
   {
      "id":"4070729",
      "phuongxa":"Phường Quảng Phong",
      "quanhuyen":"82626"
   },
   {
      "id":"4070731",
      "phuongxa":"Xã Quảng Thanh",
      "quanhuyen":"40707"
   },
   {
      "id":"4070733",
      "phuongxa":"Xã Quảng Trường",
      "quanhuyen":"40707"
   },
   {
      "id":"4070737",
      "phuongxa":"Xã Quảng Lưu",
      "quanhuyen":"40707"
   },
   {
      "id":"4070739",
      "phuongxa":"Xã Quảng Tiến",
      "quanhuyen":"40707"
   },
   {
      "id":"4070741",
      "phuongxa":"Xã Quảng Thạch",
      "quanhuyen":"40707"
   },
   {
      "id":"4070743",
      "phuongxa":"Xã Quảng Liên",
      "quanhuyen":"40707"
   },
   {
      "id":"4070745",
      "phuongxa":"Xã Phù Hóa",
      "quanhuyen":"40707"
   },
   {
      "id":"4070747",
      "phuongxa":"Xã Cảnh Hóa",
      "quanhuyen":"40707"
   },
   {
      "id":"4070751",
      "phuongxa":"Xã Quảng Trung",
      "quanhuyen":"82626"
   },
   {
      "id":"4070753",
      "phuongxa":"Xã Quảng Thủy",
      "quanhuyen":"82626"
   },
   {
      "id":"4070755",
      "phuongxa":"Xã Quảng Sơn",
      "quanhuyen":"82626"
   },
   {
      "id":"4070759",
      "phuongxa":"Xã Quảng Hòa",
      "quanhuyen":"82626"
   },
   {
      "id":"4070761",
      "phuongxa":"Xã Quảng Lộc",
      "quanhuyen":"82626"
   },
   {
      "id":"4070763",
      "phuongxa":"Xã Quảng Tân",
      "quanhuyen":"82626"
   },
   {
      "id":"4070765",
      "phuongxa":"Xã Quảng Hải",
      "quanhuyen":"82626"
   },
   {
      "id":"4070767",
      "phuongxa":"Xã Quảng Văn",
      "quanhuyen":"82626"
   },
   {
      "id":"4070900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40709"
   },
   {
      "id":"4070901",
      "phuongxa":"Thị trấn Hoàn Lão",
      "quanhuyen":"40709"
   },
   {
      "id":"4070903",
      "phuongxa":"Thị trấn NT Việt Trung",
      "quanhuyen":"40709"
   },
   {
      "id":"4070905",
      "phuongxa":"Xã Thượng Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070907",
      "phuongxa":"Xã Tân Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070909",
      "phuongxa":"Xã Phúc Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070911",
      "phuongxa":"Xã Lâm Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070913",
      "phuongxa":"Xã Xuân Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070915",
      "phuongxa":"Xã Sơn Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070919",
      "phuongxa":"Xã Liên Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070921",
      "phuongxa":"Xã Cự Nẫm",
      "quanhuyen":"40709"
   },
   {
      "id":"4070923",
      "phuongxa":"Xã Phú Định",
      "quanhuyen":"40709"
   },
   {
      "id":"4070925",
      "phuongxa":"Xã Sơn Lộc",
      "quanhuyen":"40709"
   },
   {
      "id":"4070927",
      "phuongxa":"Xã Vạn Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070929",
      "phuongxa":"Xã Hoàn Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070931",
      "phuongxa":"Xã Tây Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070933",
      "phuongxa":"Xã Hoà Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070935",
      "phuongxa":"Xã Nam Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070937",
      "phuongxa":"Xã Mỹ Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070939",
      "phuongxa":"Xã Hạ Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070941",
      "phuongxa":"Xã Bắc Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070943",
      "phuongxa":"Xã Thanh Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070945",
      "phuongxa":"Xã Hải Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070947",
      "phuongxa":"Xã Phú Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070949",
      "phuongxa":"Xã Đức Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070951",
      "phuongxa":"Xã Đồng Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070953",
      "phuongxa":"Xã Trung Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070955",
      "phuongxa":"Xã Đại Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070957",
      "phuongxa":"Xã Nhân Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4071100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40711"
   },
   {
      "id":"4071101",
      "phuongxa":"Xã Vĩnh Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071103",
      "phuongxa":"Xã Trường Xuân",
      "quanhuyen":"40711"
   },
   {
      "id":"4071105",
      "phuongxa":"Xã Trường Sơn",
      "quanhuyen":"40711"
   },
   {
      "id":"4071107",
      "phuongxa":"Xã Duy Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071109",
      "phuongxa":"Xã Hàm Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071111",
      "phuongxa":"Xã Hiền Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071113",
      "phuongxa":"Xã Tân Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071115",
      "phuongxa":"Xã Xuân Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071117",
      "phuongxa":"Xã An Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071119",
      "phuongxa":"Xã Vạn Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071121",
      "phuongxa":"Xã Lương Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071123",
      "phuongxa":"Xã Võ Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071125",
      "phuongxa":"Xã Gia Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071127",
      "phuongxa":"Xã Hải Ninh",
      "quanhuyen":"40711"
   },
   {
      "id":"4071300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40713"
   },
   {
      "id":"4071301",
      "phuongxa":"Thị trấn Kiến Giang",
      "quanhuyen":"40713"
   },
   {
      "id":"4071305",
      "phuongxa":"Xã Kim Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071307",
      "phuongxa":"Xã Ngân Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071311",
      "phuongxa":"Xã Sơn Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071313",
      "phuongxa":"Xã Phú Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071315",
      "phuongxa":"Xã Trường Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071317",
      "phuongxa":"Xã Mai Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071321",
      "phuongxa":"Xã Lộc Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071323",
      "phuongxa":"Xã An Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071325",
      "phuongxa":"Xã Xuân Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071327",
      "phuongxa":"Xã Phong Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071329",
      "phuongxa":"Xã Liên Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071333",
      "phuongxa":"Xã Dương Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071335",
      "phuongxa":"Xã Tân Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071337",
      "phuongxa":"Xã Thái Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071339",
      "phuongxa":"Xã Hồng Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071343",
      "phuongxa":"Xã Cam Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071345",
      "phuongxa":"Xã Hưng Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071347",
      "phuongxa":"Xã Sen Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071349",
      "phuongxa":"Xã Ngư Hòa",
      "quanhuyen":"40713"
   },
   {
      "id":"4071351",
      "phuongxa":"Xã Hải Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071353",
      "phuongxa":"Xã Ngư Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4090000",
      "phuongxa":"Không xác định",
      "quanhuyen":"40900"
   },
   {
      "id":"4090100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40901"
   },
   {
      "id":"4090101",
      "phuongxa":"Phường 1",
      "quanhuyen":"40901"
   },
   {
      "id":"4090103",
      "phuongxa":"Phường 2",
      "quanhuyen":"40901"
   },
   {
      "id":"4090105",
      "phuongxa":"Phường 3",
      "quanhuyen":"40901"
   },
   {
      "id":"4090107",
      "phuongxa":"Phường 4",
      "quanhuyen":"40901"
   },
   {
      "id":"4090109",
      "phuongxa":"Phường 5",
      "quanhuyen":"40901"
   },
   {
      "id":"4090111",
      "phuongxa":"Phường Đông Thanh",
      "quanhuyen":"40901"
   },
   {
      "id":"4090113",
      "phuongxa":"Phường Đông Giang",
      "quanhuyen":"40901"
   },
   {
      "id":"4090115",
      "phuongxa":"Phường Đông Lễ",
      "quanhuyen":"40901"
   },
   {
      "id":"4090117",
      "phuongxa":"Phường Đông Lương",
      "quanhuyen":"40901"
   },
   {
      "id":"4090300",
      "phuongxa":"Phường An Đôn",
      "quanhuyen":"40903"
   },
   {
      "id":"4090301",
      "phuongxa":"Phường 1",
      "quanhuyen":"40903"
   },
   {
      "id":"4090303",
      "phuongxa":"Phường 2",
      "quanhuyen":"40903"
   },
   {
      "id":"4090500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40905"
   },
   {
      "id":"4090501",
      "phuongxa":"Thị trấn Hồ Xá",
      "quanhuyen":"40905"
   },
   {
      "id":"4090505",
      "phuongxa":"Xã Vĩnh Thái",
      "quanhuyen":"40905"
   },
   {
      "id":"4090507",
      "phuongxa":"Xã Vĩnh Tú",
      "quanhuyen":"40905"
   },
   {
      "id":"4090509",
      "phuongxa":"Xã Vĩnh Trung",
      "quanhuyen":"40905"
   },
   {
      "id":"4090511",
      "phuongxa":"Xã Vĩnh Chấp",
      "quanhuyen":"40905"
   },
   {
      "id":"4090513",
      "phuongxa":"Xã Vĩnh Nam",
      "quanhuyen":"40905"
   },
   {
      "id":"4090515",
      "phuongxa":"Xã Vĩnh Khê",
      "quanhuyen":"40905"
   },
   {
      "id":"4090517",
      "phuongxa":"Xã Vĩnh Long",
      "quanhuyen":"40905"
   },
   {
      "id":"4090519",
      "phuongxa":"Xã Vĩnh Kim",
      "quanhuyen":"40905"
   },
   {
      "id":"4090521",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"40905"
   },
   {
      "id":"4090523",
      "phuongxa":"Xã Vĩnh Thạch",
      "quanhuyen":"40905"
   },
   {
      "id":"4090525",
      "phuongxa":"Xã Vĩnh Lâm",
      "quanhuyen":"40905"
   },
   {
      "id":"4090527",
      "phuongxa":"Xã Vĩnh Hiền",
      "quanhuyen":"40905"
   },
   {
      "id":"4090529",
      "phuongxa":"Xã Vĩnh Thủy",
      "quanhuyen":"40905"
   },
   {
      "id":"4090531",
      "phuongxa":"Xã Vĩnh Thành",
      "quanhuyen":"40905"
   },
   {
      "id":"4090533",
      "phuongxa":"Xã Vĩnh Hà",
      "quanhuyen":"40905"
   },
   {
      "id":"4090535",
      "phuongxa":"Xã Vĩnh Sơn",
      "quanhuyen":"40905"
   },
   {
      "id":"4090537",
      "phuongxa":"Xã Vĩnh Tân",
      "quanhuyen":"40905"
   },
   {
      "id":"4090539",
      "phuongxa":"Xã Vĩnh Quang",
      "quanhuyen":"40905"
   },
   {
      "id":"4090541",
      "phuongxa":"Xã Vĩnh Giang",
      "quanhuyen":"40905"
   },
   {
      "id":"4090543",
      "phuongxa":"Xã Vĩnh Ô",
      "quanhuyen":"40905"
   },
   {
      "id":"4090700",
      "phuongxa":"Không xác định",
      "quanhuyen":"40907"
   },
   {
      "id":"4090701",
      "phuongxa":"Thị trấn Gio Linh",
      "quanhuyen":"40907"
   },
   {
      "id":"4090703",
      "phuongxa":"Xã Gio Châu",
      "quanhuyen":"40907"
   },
   {
      "id":"4090705",
      "phuongxa":"Xã Trung Hải",
      "quanhuyen":"40907"
   },
   {
      "id":"4090709",
      "phuongxa":"Xã Trung Sơn",
      "quanhuyen":"40907"
   },
   {
      "id":"4090711",
      "phuongxa":"Xã Gio Mỹ",
      "quanhuyen":"40907"
   },
   {
      "id":"4090713",
      "phuongxa":"Xã Gio Phong",
      "quanhuyen":"40907"
   },
   {
      "id":"4090715",
      "phuongxa":"Xã Gio An",
      "quanhuyen":"40907"
   },
   {
      "id":"4090717",
      "phuongxa":"Xã Gio Bình",
      "quanhuyen":"40907"
   },
   {
      "id":"4090719",
      "phuongxa":"Xã Gio Thành",
      "quanhuyen":"40907"
   },
   {
      "id":"4090723",
      "phuongxa":"Xã Gio Sơn",
      "quanhuyen":"40907"
   },
   {
      "id":"4090725",
      "phuongxa":"Xã Gio Hòa",
      "quanhuyen":"40907"
   },
   {
      "id":"4090727",
      "phuongxa":"Xã Linh Hải",
      "quanhuyen":"40907"
   },
   {
      "id":"4090729",
      "phuongxa":"Xã Gio Việt",
      "quanhuyen":"40907"
   },
   {
      "id":"4090731",
      "phuongxa":"Xã Vĩnh Trường",
      "quanhuyen":"40907"
   },
   {
      "id":"4090735",
      "phuongxa":"Xã Gio Mai",
      "quanhuyen":"40907"
   },
   {
      "id":"4090737",
      "phuongxa":"Xã Gio Quang",
      "quanhuyen":"40907"
   },
   {
      "id":"4090739",
      "phuongxa":"Xã Linh Thượng",
      "quanhuyen":"40907"
   },
   {
      "id":"4090900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40909"
   },
   {
      "id":"4090901",
      "phuongxa":"Thị trấn Cam Lộ",
      "quanhuyen":"40909"
   },
   {
      "id":"4090905",
      "phuongxa":"Xã Cam An",
      "quanhuyen":"40909"
   },
   {
      "id":"4090907",
      "phuongxa":"Xã Cam Thanh",
      "quanhuyen":"40909"
   },
   {
      "id":"4090909",
      "phuongxa":"Xã Cam Thủy",
      "quanhuyen":"40909"
   },
   {
      "id":"4090911",
      "phuongxa":"Xã Cam Tuyền",
      "quanhuyen":"40909"
   },
   {
      "id":"4090913",
      "phuongxa":"Xã Cam Hiếu",
      "quanhuyen":"40909"
   },
   {
      "id":"4091100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40911"
   },
   {
      "id":"4091101",
      "phuongxa":"Thị trấn ái Tử",
      "quanhuyen":"40911"
   },
   {
      "id":"4091103",
      "phuongxa":"Xã Triệu ái",
      "quanhuyen":"40911"
   },
   {
      "id":"4091105",
      "phuongxa":"Xã Triệu Thượng",
      "quanhuyen":"40911"
   },
   {
      "id":"4091109",
      "phuongxa":"Xã Triệu Vân",
      "quanhuyen":"40911"
   },
   {
      "id":"4091111",
      "phuongxa":"Xã Triệu Độ",
      "quanhuyen":"40911"
   },
   {
      "id":"4091113",
      "phuongxa":"Xã Triệu Trạch",
      "quanhuyen":"40911"
   },
   {
      "id":"4091115",
      "phuongxa":"Xã Triệu Đại",
      "quanhuyen":"40911"
   },
   {
      "id":"4091117",
      "phuongxa":"Xã Triệu Lăng",
      "quanhuyen":"40911"
   },
   {
      "id":"4091119",
      "phuongxa":"Xã Triệu Thuận",
      "quanhuyen":"40911"
   },
   {
      "id":"4091121",
      "phuongxa":"Xã Triệu Sơn",
      "quanhuyen":"40911"
   },
   {
      "id":"4091123",
      "phuongxa":"Xã Triệu Giang",
      "quanhuyen":"40911"
   },
   {
      "id":"4091125",
      "phuongxa":"Xã Triệu Hòa",
      "quanhuyen":"40911"
   },
   {
      "id":"4091127",
      "phuongxa":"Xã Triệu Tài",
      "quanhuyen":"40911"
   },
   {
      "id":"4091129",
      "phuongxa":"Xã Triệu Đông",
      "quanhuyen":"40911"
   },
   {
      "id":"4091133",
      "phuongxa":"Xã Triệu Long",
      "quanhuyen":"40911"
   },
   {
      "id":"4091135",
      "phuongxa":"Xã Triệu Thành",
      "quanhuyen":"40911"
   },
   {
      "id":"4091137",
      "phuongxa":"Xã Triệu An",
      "quanhuyen":"40911"
   },
   {
      "id":"4091300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40913"
   },
   {
      "id":"4091301",
      "phuongxa":"Thị trấn Hải Lăng",
      "quanhuyen":"40913"
   },
   {
      "id":"4091303",
      "phuongxa":"Xã Hải Lâm",
      "quanhuyen":"40913"
   },
   {
      "id":"4091305",
      "phuongxa":"Xã Hải An",
      "quanhuyen":"40913"
   },
   {
      "id":"4091307",
      "phuongxa":"Xã Hải Ba",
      "quanhuyen":"40913"
   },
   {
      "id":"4091309",
      "phuongxa":"Xã Hải Khê",
      "quanhuyen":"40913"
   },
   {
      "id":"4091311",
      "phuongxa":"Xã Hải Quy",
      "quanhuyen":"40913"
   },
   {
      "id":"4091313",
      "phuongxa":"Xã Hải Vĩnh",
      "quanhuyen":"40913"
   },
   {
      "id":"4091315",
      "phuongxa":"Xã Hải Xuân",
      "quanhuyen":"40913"
   },
   {
      "id":"4091317",
      "phuongxa":"Xã Hải Quế",
      "quanhuyen":"40913"
   },
   {
      "id":"4091319",
      "phuongxa":"Xã Hải Thiện",
      "quanhuyen":"40913"
   },
   {
      "id":"4091321",
      "phuongxa":"Xã Hải Dương",
      "quanhuyen":"40913"
   },
   {
      "id":"4091323",
      "phuongxa":"Xã Hải Trường",
      "quanhuyen":"40913"
   },
   {
      "id":"4091325",
      "phuongxa":"Xã Hải Thành",
      "quanhuyen":"40913"
   },
   {
      "id":"4091327",
      "phuongxa":"Xã Hải Phú",
      "quanhuyen":"40913"
   },
   {
      "id":"4091329",
      "phuongxa":"Xã Hải Thọ",
      "quanhuyen":"40913"
   },
   {
      "id":"4091331",
      "phuongxa":"Xã Hải Hòa",
      "quanhuyen":"40913"
   },
   {
      "id":"4091333",
      "phuongxa":"Xã Hải Lệ",
      "quanhuyen":"40903"
   },
   {
      "id":"4091337",
      "phuongxa":"Xã Hải Thượng",
      "quanhuyen":"40913"
   },
   {
      "id":"5010507",
      "phuongxa":"Phường Mân Thái",
      "quanhuyen":"50105"
   },
   {
      "id":"5010509",
      "phuongxa":"Phường Phước Mỹ",
      "quanhuyen":"50105"
   },
   {
      "id":"5010511",
      "phuongxa":"Phường Thọ Quang",
      "quanhuyen":"50105"
   },
   {
      "id":"5010513",
      "phuongxa":"Phường An Hải Đông",
      "quanhuyen":"50105"
   },
   {
      "id":"5010700",
      "phuongxa":"Phường Khuê Mỹ",
      "quanhuyen":"50107"
   },
   {
      "id":"5010701",
      "phuongxa":"Phường Mỹ An",
      "quanhuyen":"50107"
   },
   {
      "id":"5010703",
      "phuongxa":"Phường Hoà Quý",
      "quanhuyen":"50107"
   },
   {
      "id":"5010705",
      "phuongxa":"Phường Hoà Hải",
      "quanhuyen":"50107"
   },
   {
      "id":"5010900",
      "phuongxa":"Phường Hòa Hiệp Bắc",
      "quanhuyen":"50109"
   },
   {
      "id":"5010901",
      "phuongxa":"Phường Hoà Hiệp Nam",
      "quanhuyen":"50109"
   },
   {
      "id":"5010903",
      "phuongxa":"Phường Hoà Khánh Bắc",
      "quanhuyen":"50109"
   },
   {
      "id":"5010905",
      "phuongxa":"Phường Hoà Minh",
      "quanhuyen":"50109"
   },
   {
      "id":"5011100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50111"
   },
   {
      "id":"5011101",
      "phuongxa":"Xã Hòa Thọ",
      "quanhuyen":"50111"
   },
   {
      "id":"5011103",
      "phuongxa":"Xã Hòa Bắc",
      "quanhuyen":"50111"
   },
   {
      "id":"5011105",
      "phuongxa":"Xã Hòa Ninh",
      "quanhuyen":"50111"
   },
   {
      "id":"5011107",
      "phuongxa":"Xã Hoà Sơn",
      "quanhuyen":"50111"
   },
   {
      "id":"5011111",
      "phuongxa":"Xã Hoà Tiến",
      "quanhuyen":"50111"
   },
   {
      "id":"5011113",
      "phuongxa":"Xã Hòa Châu",
      "quanhuyen":"50111"
   },
   {
      "id":"5011117",
      "phuongxa":"Xã Hòa Liên",
      "quanhuyen":"50111"
   },
   {
      "id":"5011119",
      "phuongxa":"Xã Hòa Xuân",
      "quanhuyen":"50111"
   },
   {
      "id":"5011121",
      "phuongxa":"Xã Hòa Nhơn",
      "quanhuyen":"50111"
   },
   {
      "id":"5011123",
      "phuongxa":"Xã Hòa Phong",
      "quanhuyen":"50111"
   },
   {
      "id":"5011125",
      "phuongxa":"Xã Hòa Khương",
      "quanhuyen":"50111"
   },
   {
      "id":"5011300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50113"
   },
   {
      "id":"5011500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50115"
   },
   {
      "id":"5011501",
      "phuongxa":"Phường Khuê Trung",
      "quanhuyen":"50115"
   },
   {
      "id":"5011505",
      "phuongxa":"Phường Hoà An",
      "quanhuyen":"50115"
   },
   {
      "id":"5011507",
      "phuongxa":"Phường Hoà Thọ Tây",
      "quanhuyen":"50115"
   },
   {
      "id":"5011509",
      "phuongxa":"Phường Hoà Thọ Đông",
      "quanhuyen":"50115"
   },
   {
      "id":"5011511",
      "phuongxa":"Phường Hoà Xuân",
      "quanhuyen":"50115"
   },
   {
      "id":"5030000",
      "phuongxa":"Không xác định",
      "quanhuyen":"50300"
   },
   {
      "id":"5030101",
      "phuongxa":"Phường Tân Thạnh",
      "quanhuyen":"50301"
   },
   {
      "id":"5030103",
      "phuongxa":"Phường An Mỹ",
      "quanhuyen":"50301"
   },
   {
      "id":"5030105",
      "phuongxa":"Phường Trường Xuân",
      "quanhuyen":"50301"
   },
   {
      "id":"5030107",
      "phuongxa":"Phường An Xuân",
      "quanhuyen":"50301"
   },
   {
      "id":"5030109",
      "phuongxa":"Phường Phước Hòa",
      "quanhuyen":"50301"
   },
   {
      "id":"5030111",
      "phuongxa":"Phường An Sơn",
      "quanhuyen":"50301"
   },
   {
      "id":"5030113",
      "phuongxa":"Phường Hòa Hương",
      "quanhuyen":"50301"
   },
   {
      "id":"5030115",
      "phuongxa":"Xã Tam Thành",
      "quanhuyen":"50326"
   },
   {
      "id":"5030117",
      "phuongxa":"Xã Tam Lộc",
      "quanhuyen":"50326"
   },
   {
      "id":"5030119",
      "phuongxa":"Xã Tam Phước",
      "quanhuyen":"50326"
   },
   {
      "id":"5030121",
      "phuongxa":"Xã Tam An",
      "quanhuyen":"50326"
   },
   {
      "id":"5030123",
      "phuongxa":"Xã Tam Đàn",
      "quanhuyen":"50326"
   },
   {
      "id":"5030125",
      "phuongxa":"Xã Tam Thăng",
      "quanhuyen":"50301"
   },
   {
      "id":"5030127",
      "phuongxa":"Xã Tam Thanh",
      "quanhuyen":"50301"
   },
   {
      "id":"5030129",
      "phuongxa":"Xã Tam Vinh",
      "quanhuyen":"50326"
   },
   {
      "id":"5030133",
      "phuongxa":"Xã Tam Thái",
      "quanhuyen":"50326"
   },
   {
      "id":"5030135",
      "phuongxa":"Xã Tam Ngọc",
      "quanhuyen":"50301"
   },
   {
      "id":"5030137",
      "phuongxa":"Xã Tam Phú",
      "quanhuyen":"50301"
   },
   {
      "id":"5030139",
      "phuongxa":"Xã Tam Lãnh",
      "quanhuyen":"50326"
   },
   {
      "id":"5030300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50303"
   },
   {
      "id":"5030301",
      "phuongxa":"Phường Minh An",
      "quanhuyen":"50303"
   },
   {
      "id":"5030303",
      "phuongxa":"Phường Cẩm Phô",
      "quanhuyen":"50303"
   },
   {
      "id":"5030305",
      "phuongxa":"Phường Sơn Phong",
      "quanhuyen":"50303"
   },
   {
      "id":"5030307",
      "phuongxa":"Xã Cẩm Hà",
      "quanhuyen":"50303"
   },
   {
      "id":"5030309",
      "phuongxa":"Phường Cẩm Châu",
      "quanhuyen":"50303"
   },
   {
      "id":"5030311",
      "phuongxa":"Phường Cẩm An",
      "quanhuyen":"50303"
   },
   {
      "id":"5030313",
      "phuongxa":"Xã Cẩm Kim",
      "quanhuyen":"50303"
   },
   {
      "id":"5030315",
      "phuongxa":"Phường Cẩm Nam",
      "quanhuyen":"50303"
   },
   {
      "id":"5030317",
      "phuongxa":"Xã Cẩm Thanh",
      "quanhuyen":"50303"
   },
   {
      "id":"5030319",
      "phuongxa":"Xã Tân Hiệp",
      "quanhuyen":"50303"
   },
   {
      "id":"5030700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50307"
   },
   {
      "id":"5030701",
      "phuongxa":"Thị trấn ái Nghĩa",
      "quanhuyen":"50307"
   },
   {
      "id":"5030703",
      "phuongxa":"Xã Đại Sơn",
      "quanhuyen":"50307"
   },
   {
      "id":"5030705",
      "phuongxa":"Xã Đại Lãnh",
      "quanhuyen":"50307"
   },
   {
      "id":"5030709",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"50307"
   },
   {
      "id":"5030711",
      "phuongxa":"Xã Đại Quang",
      "quanhuyen":"50307"
   },
   {
      "id":"5051507",
      "phuongxa":"Xã Nghĩa Lâm",
      "quanhuyen":"50515"
   },
   {
      "id":"5051509",
      "phuongxa":"Xã Nghĩa Sơn",
      "quanhuyen":"50515"
   },
   {
      "id":"5051511",
      "phuongxa":"Xã Nghĩa Thắng",
      "quanhuyen":"50515"
   },
   {
      "id":"5051513",
      "phuongxa":"Xã Nghĩa Thọ",
      "quanhuyen":"50515"
   },
   {
      "id":"5051515",
      "phuongxa":"Xã Nghĩa Thuận",
      "quanhuyen":"50515"
   },
   {
      "id":"5051517",
      "phuongxa":"Xã Nghĩa Kỳ",
      "quanhuyen":"50515"
   },
   {
      "id":"5051519",
      "phuongxa":"Xã Nghĩa Điền",
      "quanhuyen":"50515"
   },
   {
      "id":"5051521",
      "phuongxa":"Xã Nghĩa Hà",
      "quanhuyen":"50515"
   },
   {
      "id":"5051523",
      "phuongxa":"Xã Nghĩa Hòa",
      "quanhuyen":"50515"
   },
   {
      "id":"5051525",
      "phuongxa":"Xã Nghĩa An",
      "quanhuyen":"50515"
   },
   {
      "id":"5051527",
      "phuongxa":"Xã Nghĩa Thương",
      "quanhuyen":"50515"
   },
   {
      "id":"5051529",
      "phuongxa":"Xã Nghĩa Hiệp",
      "quanhuyen":"50515"
   },
   {
      "id":"5051531",
      "phuongxa":"Xã Nghĩa Phương",
      "quanhuyen":"50515"
   },
   {
      "id":"5051533",
      "phuongxa":"Xã Nghĩa Mỹ",
      "quanhuyen":"50515"
   },
   {
      "id":"5051700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50517"
   },
   {
      "id":"5051701",
      "phuongxa":"Thị trấn Chợ Chùa",
      "quanhuyen":"50517"
   },
   {
      "id":"5051703",
      "phuongxa":"Xã Hành Thuận",
      "quanhuyen":"50517"
   },
   {
      "id":"5051707",
      "phuongxa":"Xã Hành Minh",
      "quanhuyen":"50517"
   },
   {
      "id":"5051709",
      "phuongxa":"Xã Hành Đức",
      "quanhuyen":"50517"
   },
   {
      "id":"5051711",
      "phuongxa":"Xã Hành Phước",
      "quanhuyen":"50517"
   },
   {
      "id":"5051715",
      "phuongxa":"Xã Hành Thiện",
      "quanhuyen":"50517"
   },
   {
      "id":"5051717",
      "phuongxa":"Xã Hành Tín Tây",
      "quanhuyen":"50517"
   },
   {
      "id":"5051719",
      "phuongxa":"Xã Hành Tín  Đông",
      "quanhuyen":"50517"
   },
   {
      "id":"5051721",
      "phuongxa":"Xã Hành Nhân",
      "quanhuyen":"50517"
   },
   {
      "id":"5051723",
      "phuongxa":"Xã Hành Trung",
      "quanhuyen":"50517"
   },
   {
      "id":"5051901",
      "phuongxa":"Xã Long Hiệp",
      "quanhuyen":"50519"
   },
   {
      "id":"5051903",
      "phuongxa":"Xã Long Sơn",
      "quanhuyen":"50519"
   },
   {
      "id":"5051905",
      "phuongxa":"Xã Long Mai",
      "quanhuyen":"50519"
   },
   {
      "id":"5051907",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"50519"
   },
   {
      "id":"5051909",
      "phuongxa":"Xã Long Môn",
      "quanhuyen":"50519"
   },
   {
      "id":"5052100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50521"
   },
   {
      "id":"5052103",
      "phuongxa":"Xã Đức Nhuận",
      "quanhuyen":"50521"
   },
   {
      "id":"5052105",
      "phuongxa":"Xã Đức Lợi",
      "quanhuyen":"50521"
   },
   {
      "id":"5052107",
      "phuongxa":"Xã Đức Thắng",
      "quanhuyen":"50521"
   },
   {
      "id":"5052109",
      "phuongxa":"Xã Đức Chánh",
      "quanhuyen":"50521"
   },
   {
      "id":"5052111",
      "phuongxa":"Xã Đức Hiệp",
      "quanhuyen":"50521"
   },
   {
      "id":"5052115",
      "phuongxa":"Xã Đức Thạnh",
      "quanhuyen":"50521"
   },
   {
      "id":"5052117",
      "phuongxa":"Xã Đức Tân",
      "quanhuyen":"50521"
   },
   {
      "id":"5052119",
      "phuongxa":"Xã Đức Hòa",
      "quanhuyen":"50521"
   },
   {
      "id":"5052121",
      "phuongxa":"Xã Đức Phú",
      "quanhuyen":"50521"
   },
   {
      "id":"5052123",
      "phuongxa":"Xã Đức Phong",
      "quanhuyen":"50521"
   },
   {
      "id":"5052125",
      "phuongxa":"Xã Đức Lân",
      "quanhuyen":"50521"
   },
   {
      "id":"5052300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50523"
   },
   {
      "id":"5052301",
      "phuongxa":"Thị trấn Đức Phổ",
      "quanhuyen":"50523"
   },
   {
      "id":"5052303",
      "phuongxa":"Xã Phổ Hòa",
      "quanhuyen":"50523"
   },
   {
      "id":"5052305",
      "phuongxa":"Xã Phổ Thuận",
      "quanhuyen":"50523"
   },
   {
      "id":"5052307",
      "phuongxa":"Xã Phổ Văn",
      "quanhuyen":"50523"
   },
   {
      "id":"5052309",
      "phuongxa":"Xã Phổ Phong",
      "quanhuyen":"50523"
   },
   {
      "id":"5052311",
      "phuongxa":"Xã Phổ An",
      "quanhuyen":"50523"
   },
   {
      "id":"5052313",
      "phuongxa":"Xã Phổ Quang",
      "quanhuyen":"50523"
   },
   {
      "id":"5052315",
      "phuongxa":"Xã Phổ Ninh",
      "quanhuyen":"50523"
   },
   {
      "id":"5052317",
      "phuongxa":"Xã Phổ Minh",
      "quanhuyen":"50523"
   },
   {
      "id":"5052319",
      "phuongxa":"Xã Phổ Nhơn",
      "quanhuyen":"50523"
   },
   {
      "id":"5052321",
      "phuongxa":"Xã Phổ Cường",
      "quanhuyen":"50523"
   },
   {
      "id":"5052323",
      "phuongxa":"Xã Phổ Khánh",
      "quanhuyen":"50523"
   },
   {
      "id":"5052327",
      "phuongxa":"Xã Phổ Vinh",
      "quanhuyen":"50523"
   },
   {
      "id":"5052500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50525"
   },
   {
      "id":"5052501",
      "phuongxa":"Thị trấn Ba Tơ",
      "quanhuyen":"50525"
   },
   {
      "id":"5052503",
      "phuongxa":"Xã Ba Động",
      "quanhuyen":"50525"
   },
   {
      "id":"5052505",
      "phuongxa":"Xã Ba Dinh",
      "quanhuyen":"50525"
   },
   {
      "id":"5052507",
      "phuongxa":"Xã Ba Điền",
      "quanhuyen":"50525"
   },
   {
      "id":"5052509",
      "phuongxa":"Xã Ba Thành",
      "quanhuyen":"50525"
   },
   {
      "id":"5052511",
      "phuongxa":"Xã Ba Vinh",
      "quanhuyen":"50525"
   },
   {
      "id":"5052513",
      "phuongxa":"Xã Ba Trang",
      "quanhuyen":"50525"
   },
   {
      "id":"5052515",
      "phuongxa":"Xã Ba Lế",
      "quanhuyen":"50525"
   },
   {
      "id":"5052517",
      "phuongxa":"Xã Ba Bích",
      "quanhuyen":"50525"
   },
   {
      "id":"5052519",
      "phuongxa":"Xã Ba Vì",
      "quanhuyen":"50525"
   },
   {
      "id":"5052521",
      "phuongxa":"Xã Ba Tiêu",
      "quanhuyen":"50525"
   },
   {
      "id":"5052523",
      "phuongxa":"Xã Ba Xa",
      "quanhuyen":"50525"
   },
   {
      "id":"5052525",
      "phuongxa":"Xã Ba Ngạc",
      "quanhuyen":"50525"
   },
   {
      "id":"5052527",
      "phuongxa":"Xã Ba Tô",
      "quanhuyen":"50525"
   },
   {
      "id":"5052529",
      "phuongxa":"Xã Ba Chùa",
      "quanhuyen":"50525"
   },
   {
      "id":"5052531",
      "phuongxa":"Xã Ba Cung",
      "quanhuyen":"50525"
   },
   {
      "id":"5052533",
      "phuongxa":"Xã Ba Nam",
      "quanhuyen":"50525"
   },
   {
      "id":"5052700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50527"
   },
   {
      "id":"5052701",
      "phuongxa":"Xã Trà Thanh",
      "quanhuyen":"50527"
   },
   {
      "id":"5052703",
      "phuongxa":"Xã Trà Khê",
      "quanhuyen":"50527"
   },
   {
      "id":"5052705",
      "phuongxa":"Xã Trà Quân",
      "quanhuyen":"50527"
   },
   {
      "id":"5052707",
      "phuongxa":"Xã Trà Phong",
      "quanhuyen":"50527"
   },
   {
      "id":"5052709",
      "phuongxa":"Xã Trà Lãnh",
      "quanhuyen":"50527"
   },
   {
      "id":"5052711",
      "phuongxa":"Xã Trà Nham",
      "quanhuyen":"50527"
   },
   {
      "id":"5052713",
      "phuongxa":"Xã Trà Xinh",
      "quanhuyen":"50527"
   },
   {
      "id":"5052715",
      "phuongxa":"Xã Trà Thọ",
      "quanhuyen":"50527"
   },
   {
      "id":"5052717",
      "phuongxa":"Xã Trà Trung",
      "quanhuyen":"50527"
   },
   {
      "id":"5070100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50701"
   },
   {
      "id":"5070101",
      "phuongxa":"Phường Trần Quang Diệu",
      "quanhuyen":"50701"
   },
   {
      "id":"5070103",
      "phuongxa":"Phường Bùi Thị Xuân",
      "quanhuyen":"50701"
   },
   {
      "id":"5070105",
      "phuongxa":"Phường Đống Đa",
      "quanhuyen":"50701"
   },
   {
      "id":"5070109",
      "phuongxa":"Phường Ghềnh Ráng",
      "quanhuyen":"50701"
   },
   {
      "id":"5070111",
      "phuongxa":"Phường Ngô Mây",
      "quanhuyen":"50701"
   },
   {
      "id":"5070113",
      "phuongxa":"Phường Nguyến  Văn Cừ",
      "quanhuyen":"50701"
   },
   {
      "id":"5070115",
      "phuongxa":"Phường Trần Hưng Đạo",
      "quanhuyen":"50701"
   },
   {
      "id":"5070117",
      "phuongxa":"Phường Lê Hồng Phong",
      "quanhuyen":"50701"
   },
   {
      "id":"5070123",
      "phuongxa":"Phường Hải Cảng",
      "quanhuyen":"50701"
   },
   {
      "id":"5070125",
      "phuongxa":"Phường Nhơn Bình",
      "quanhuyen":"50701"
   },
   {
      "id":"5070127",
      "phuongxa":"Phường Nhơn Phú",
      "quanhuyen":"50701"
   },
   {
      "id":"5070129",
      "phuongxa":"Xã Nhơn Lý",
      "quanhuyen":"50701"
   },
   {
      "id":"5070133",
      "phuongxa":"Xã Nhơn Hội",
      "quanhuyen":"50701"
   },
   {
      "id":"5070135",
      "phuongxa":"Xã Nhơn Châu",
      "quanhuyen":"50701"
   },
   {
      "id":"5070137",
      "phuongxa":"Phường Lý Thường Kiệt",
      "quanhuyen":"50701"
   },
   {
      "id":"5070139",
      "phuongxa":"Phường Thị Nại",
      "quanhuyen":"50701"
   },
   {
      "id":"5070300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50703"
   },
   {
      "id":"5070301",
      "phuongxa":"Xã An Hưng",
      "quanhuyen":"50703"
   },
   {
      "id":"5070303",
      "phuongxa":"Xã An Trung",
      "quanhuyen":"50703"
   },
   {
      "id":"5070305",
      "phuongxa":"Xã An Dũng",
      "quanhuyen":"50703"
   },
   {
      "id":"5070307",
      "phuongxa":"Xã An Vinh",
      "quanhuyen":"50703"
   },
   {
      "id":"5070309",
      "phuongxa":"Xã An Toàn",
      "quanhuyen":"50703"
   },
   {
      "id":"5070311",
      "phuongxa":"Xã An Tân",
      "quanhuyen":"50703"
   },
   {
      "id":"5070313",
      "phuongxa":"Xã An Hòa",
      "quanhuyen":"50703"
   },
   {
      "id":"5070315",
      "phuongxa":"Xã An Quang",
      "quanhuyen":"50703"
   },
   {
      "id":"5070317",
      "phuongxa":"Xã An Nghĩa",
      "quanhuyen":"50703"
   },
   {
      "id":"5070500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50705"
   },
   {
      "id":"5070501",
      "phuongxa":"Phường Bồng Sơn",
      "quanhuyen":"50705"
   },
   {
      "id":"5070503",
      "phuongxa":"Phường Tam Quan",
      "quanhuyen":"50705"
   },
   {
      "id":"5070507",
      "phuongxa":"Xã Hoài Châu",
      "quanhuyen":"50705"
   },
   {
      "id":"5070509",
      "phuongxa":"Xã Hoài Châu Bắc",
      "quanhuyen":"50705"
   },
   {
      "id":"5070511",
      "phuongxa":"Xã Hoài Phú",
      "quanhuyen":"50705"
   },
   {
      "id":"5070513",
      "phuongxa":"Phường Tam Quan Bắc",
      "quanhuyen":"50705"
   },
   {
      "id":"5070515",
      "phuongxa":"Phường Tam Quan Nam",
      "quanhuyen":"50705"
   },
   {
      "id":"5070517",
      "phuongxa":"Phường Hoài Hảo",
      "quanhuyen":"50705"
   },
   {
      "id":"5070519",
      "phuongxa":"Phường Hoài Thanh",
      "quanhuyen":"50705"
   },
   {
      "id":"5070521",
      "phuongxa":"Phường Hoài Thanh Tây",
      "quanhuyen":"50705"
   },
   {
      "id":"5070523",
      "phuongxa":"Phường Hoài Hương",
      "quanhuyen":"50705"
   },
   {
      "id":"5070525",
      "phuongxa":"Phường Hoài Tân",
      "quanhuyen":"50705"
   },
   {
      "id":"5070527",
      "phuongxa":"Xã Hoài Hải",
      "quanhuyen":"50705"
   },
   {
      "id":"5070529",
      "phuongxa":"Phường Hoài Xuân",
      "quanhuyen":"50705"
   },
   {
      "id":"5070531",
      "phuongxa":"Xã Hoài Mỹ",
      "quanhuyen":"50705"
   },
   {
      "id":"5070533",
      "phuongxa":"Phường Hoài Đức",
      "quanhuyen":"50705"
   },
   {
      "id":"5070700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50707"
   },
   {
      "id":"5070701",
      "phuongxa":"Thị trấn Tăng Bạt Hổ",
      "quanhuyen":"50707"
   },
   {
      "id":"5070703",
      "phuongxa":"Xã Ân Hảo",
      "quanhuyen":"50707"
   },
   {
      "id":"5070705",
      "phuongxa":"Xã Ân Mỹ",
      "quanhuyen":"50707"
   },
   {
      "id":"5070707",
      "phuongxa":"Xã Ân Sơn",
      "quanhuyen":"50707"
   },
   {
      "id":"5070711",
      "phuongxa":"Xã Ân Tín",
      "quanhuyen":"50707"
   },
   {
      "id":"5070713",
      "phuongxa":"Xã Ân Thạnh",
      "quanhuyen":"50707"
   },
   {
      "id":"5070715",
      "phuongxa":"Xã Ân Phong",
      "quanhuyen":"50707"
   },
   {
      "id":"5070717",
      "phuongxa":"Xã Ân Đức",
      "quanhuyen":"50707"
   },
   {
      "id":"5030713",
      "phuongxa":"Xã Đại Nghĩa",
      "quanhuyen":"50307"
   },
   {
      "id":"5030715",
      "phuongxa":"Xã Đại Hiệp",
      "quanhuyen":"50307"
   },
   {
      "id":"5030717",
      "phuongxa":"Xã Đại Thạnh",
      "quanhuyen":"50307"
   },
   {
      "id":"5030721",
      "phuongxa":"Xã Đại Tân",
      "quanhuyen":"50307"
   },
   {
      "id":"5030723",
      "phuongxa":"Xã Đại Phong",
      "quanhuyen":"50307"
   },
   {
      "id":"5030725",
      "phuongxa":"Xã Đại Minh",
      "quanhuyen":"50307"
   },
   {
      "id":"5030727",
      "phuongxa":"Xã Đại Thắng",
      "quanhuyen":"50307"
   },
   {
      "id":"5030729",
      "phuongxa":"Xã Đại Cường",
      "quanhuyen":"50307"
   },
   {
      "id":"5030731",
      "phuongxa":"Xã Đại Hòa",
      "quanhuyen":"50307"
   },
   {
      "id":"5030901",
      "phuongxa":"Phường Vĩnh Điện",
      "quanhuyen":"50309"
   },
   {
      "id":"5030903",
      "phuongxa":"Xã Điện Tiến",
      "quanhuyen":"50309"
   },
   {
      "id":"5030905",
      "phuongxa":"Xã Điện Hòa",
      "quanhuyen":"50309"
   },
   {
      "id":"5030907",
      "phuongxa":"Xã Điện Thắng",
      "quanhuyen":"50309"
   },
   {
      "id":"5030909",
      "phuongxa":"Phường Điện Ngọc",
      "quanhuyen":"50309"
   },
   {
      "id":"5030913",
      "phuongxa":"Xã Điện Thọ",
      "quanhuyen":"50309"
   },
   {
      "id":"5030917",
      "phuongxa":"Phường Điện An",
      "quanhuyen":"50309"
   },
   {
      "id":"5030919",
      "phuongxa":"Xã Điện Nam",
      "quanhuyen":"50309"
   },
   {
      "id":"5030921",
      "phuongxa":"Phường Điện Dương",
      "quanhuyen":"50309"
   },
   {
      "id":"5030925",
      "phuongxa":"Xã Điện Trung",
      "quanhuyen":"50309"
   },
   {
      "id":"5030927",
      "phuongxa":"Xã Điện Phong",
      "quanhuyen":"50309"
   },
   {
      "id":"5030929",
      "phuongxa":"Xã Điện Minh",
      "quanhuyen":"50309"
   },
   {
      "id":"5030931",
      "phuongxa":"Xã Điện Phương",
      "quanhuyen":"50309"
   },
   {
      "id":"5031100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50311"
   },
   {
      "id":"5031103",
      "phuongxa":"Xã Duy Thu",
      "quanhuyen":"50311"
   },
   {
      "id":"5031105",
      "phuongxa":"Xã Duy Phú",
      "quanhuyen":"50311"
   },
   {
      "id":"5031107",
      "phuongxa":"Xã Duy Tân",
      "quanhuyen":"50311"
   },
   {
      "id":"5031109",
      "phuongxa":"Xã Duy Hòa",
      "quanhuyen":"50311"
   },
   {
      "id":"5031111",
      "phuongxa":"Xã Duy Châu",
      "quanhuyen":"50311"
   },
   {
      "id":"5031113",
      "phuongxa":"Xã Duy Trinh",
      "quanhuyen":"50311"
   },
   {
      "id":"5031115",
      "phuongxa":"Xã Duy Sơn",
      "quanhuyen":"50311"
   },
   {
      "id":"5031119",
      "phuongxa":"Xã Duy Phước",
      "quanhuyen":"50311"
   },
   {
      "id":"5031121",
      "phuongxa":"Xã Duy Thành",
      "quanhuyen":"50311"
   },
   {
      "id":"5031123",
      "phuongxa":"Xã Duy Vinh",
      "quanhuyen":"50311"
   },
   {
      "id":"5031125",
      "phuongxa":"Xã Duy Nghĩa",
      "quanhuyen":"50311"
   },
   {
      "id":"5031127",
      "phuongxa":"Xã Duy Hải",
      "quanhuyen":"50311"
   },
   {
      "id":"5031500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50315"
   },
   {
      "id":"5031501",
      "phuongxa":"Thị trấn Hà Lam",
      "quanhuyen":"50315"
   },
   {
      "id":"5031503",
      "phuongxa":"Xã Bình Dương",
      "quanhuyen":"50315"
   },
   {
      "id":"5031505",
      "phuongxa":"Xã Bình Giang",
      "quanhuyen":"50315"
   },
   {
      "id":"5031507",
      "phuongxa":"Xã Bình Nguyên",
      "quanhuyen":"50315"
   },
   {
      "id":"5031509",
      "phuongxa":"Xã Bình Phục",
      "quanhuyen":"50315"
   },
   {
      "id":"5031511",
      "phuongxa":"Xã Bình Triều",
      "quanhuyen":"50315"
   },
   {
      "id":"5031513",
      "phuongxa":"Xã Bình Đào",
      "quanhuyen":"50315"
   },
   {
      "id":"5031515",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"50315"
   },
   {
      "id":"5031517",
      "phuongxa":"Xã Bình Lãnh",
      "quanhuyen":"50315"
   },
   {
      "id":"5031519",
      "phuongxa":"Xã Bình Trị",
      "quanhuyen":"50315"
   },
   {
      "id":"5031521",
      "phuongxa":"Xã Bình Định",
      "quanhuyen":"50315"
   },
   {
      "id":"5031523",
      "phuongxa":"Xã Bình Quý",
      "quanhuyen":"50315"
   },
   {
      "id":"5031525",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"50315"
   },
   {
      "id":"5031527",
      "phuongxa":"Xã Bình Chánh",
      "quanhuyen":"50315"
   },
   {
      "id":"5031529",
      "phuongxa":"Xã Bình Tú",
      "quanhuyen":"50315"
   },
   {
      "id":"5031533",
      "phuongxa":"Xã Bình Hải",
      "quanhuyen":"50315"
   },
   {
      "id":"5031535",
      "phuongxa":"Xã Bình Quế",
      "quanhuyen":"50315"
   },
   {
      "id":"5031537",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"50315"
   },
   {
      "id":"5031539",
      "phuongxa":"Xã Bình Trung",
      "quanhuyen":"50315"
   },
   {
      "id":"5031541",
      "phuongxa":"Xã Bình Nam",
      "quanhuyen":"50315"
   },
   {
      "id":"5031700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50317"
   },
   {
      "id":"5031701",
      "phuongxa":"Thị trấn Đông Phú",
      "quanhuyen":"50317"
   },
   {
      "id":"5031703",
      "phuongxa":"Xã Quế Phước",
      "quanhuyen":"50317"
   },
   {
      "id":"5031705",
      "phuongxa":"Xã Quế Lâm",
      "quanhuyen":"50317"
   },
   {
      "id":"5031707",
      "phuongxa":"Xã Quế Ninh",
      "quanhuyen":"50317"
   },
   {
      "id":"5031709",
      "phuongxa":"Xã Quế Trung",
      "quanhuyen":"50317"
   },
   {
      "id":"5031711",
      "phuongxa":"Xã Quế Lộc",
      "quanhuyen":"50317"
   },
   {
      "id":"5031713",
      "phuongxa":"Xã Quế Phong",
      "quanhuyen":"50317"
   },
   {
      "id":"5031715",
      "phuongxa":"Xã Quế Long",
      "quanhuyen":"50317"
   },
   {
      "id":"5031717",
      "phuongxa":"Xã Quế Hiệp",
      "quanhuyen":"50317"
   },
   {
      "id":"5031719",
      "phuongxa":"Xã Quế Xuân",
      "quanhuyen":"50317"
   },
   {
      "id":"5031721",
      "phuongxa":"Xã Quế Phú",
      "quanhuyen":"50317"
   },
   {
      "id":"5031723",
      "phuongxa":"Xã Quế An",
      "quanhuyen":"50317"
   },
   {
      "id":"5031727",
      "phuongxa":"Xã Quế Châu",
      "quanhuyen":"50317"
   },
   {
      "id":"5031729",
      "phuongxa":"Xã Quế Thuận",
      "quanhuyen":"50317"
   },
   {
      "id":"5031731",
      "phuongxa":"Xã Phú Thọ",
      "quanhuyen":"50317"
   },
   {
      "id":"5031733",
      "phuongxa":"Xã Quế Cường",
      "quanhuyen":"50317"
   },
   {
      "id":"5031900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50319"
   },
   {
      "id":"5031901",
      "phuongxa":"Thị trấn Tân An",
      "quanhuyen":"50319"
   },
   {
      "id":"5031903",
      "phuongxa":"Xã Hiệp Hòa",
      "quanhuyen":"50319"
   },
   {
      "id":"5031905",
      "phuongxa":"Xã Hiệp Thuận",
      "quanhuyen":"50319"
   },
   {
      "id":"5031907",
      "phuongxa":"Xã Quế Thọ",
      "quanhuyen":"50319"
   },
   {
      "id":"5031909",
      "phuongxa":"Xã Bình Lâm",
      "quanhuyen":"50319"
   },
   {
      "id":"5031911",
      "phuongxa":"Xã Phước Trà",
      "quanhuyen":"50319"
   },
   {
      "id":"5031913",
      "phuongxa":"Xã Phước Gia",
      "quanhuyen":"50319"
   },
   {
      "id":"5031915",
      "phuongxa":"Xã Quế Bình",
      "quanhuyen":"50319"
   },
   {
      "id":"5031919",
      "phuongxa":"Xã Thăng Phước",
      "quanhuyen":"50319"
   },
   {
      "id":"5031921",
      "phuongxa":"Xã Bình Sơn",
      "quanhuyen":"50319"
   },
   {
      "id":"5032100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50321"
   },
   {
      "id":"5032101",
      "phuongxa":"Thị trấn Tiên Kỳ",
      "quanhuyen":"50321"
   },
   {
      "id":"5032105",
      "phuongxa":"Xã Tiên Hà",
      "quanhuyen":"50321"
   },
   {
      "id":"5032107",
      "phuongxa":"Xã Tiên Cẩm",
      "quanhuyen":"50321"
   },
   {
      "id":"5032111",
      "phuongxa":"Xã Tiên Lãnh",
      "quanhuyen":"50321"
   },
   {
      "id":"5032113",
      "phuongxa":"Xã Tiên Ngọc",
      "quanhuyen":"50321"
   },
   {
      "id":"5032117",
      "phuongxa":"Xã Tiên Cảnh",
      "quanhuyen":"50321"
   },
   {
      "id":"5032119",
      "phuongxa":"Xã Tiên Mỹ",
      "quanhuyen":"50321"
   },
   {
      "id":"5032121",
      "phuongxa":"Xã Tiên Phong",
      "quanhuyen":"50321"
   },
   {
      "id":"5032123",
      "phuongxa":"Xã Tiên Thọ",
      "quanhuyen":"50321"
   },
   {
      "id":"5032125",
      "phuongxa":"Xã Tiên An",
      "quanhuyen":"50321"
   },
   {
      "id":"5032127",
      "phuongxa":"Xã Tiên Lộc",
      "quanhuyen":"50321"
   },
   {
      "id":"5032300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50323"
   },
   {
      "id":"5032301",
      "phuongxa":"Thị trấn Khâm Đức",
      "quanhuyen":"50323"
   },
   {
      "id":"5032303",
      "phuongxa":"Xã Phước Hiệp",
      "quanhuyen":"50323"
   },
   {
      "id":"5032305",
      "phuongxa":"Xã Phước Đức",
      "quanhuyen":"50323"
   },
   {
      "id":"5032309",
      "phuongxa":"Xã Phước Mỹ",
      "quanhuyen":"50323"
   },
   {
      "id":"5032311",
      "phuongxa":"Xã Phước Chánh",
      "quanhuyen":"50323"
   },
   {
      "id":"5032313",
      "phuongxa":"Xã Phước Công",
      "quanhuyen":"50323"
   },
   {
      "id":"5032315",
      "phuongxa":"Xã Phước Kim",
      "quanhuyen":"50323"
   },
   {
      "id":"5032317",
      "phuongxa":"Xã Phước Thành",
      "quanhuyen":"50323"
   },
   {
      "id":"5032500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50325"
   },
   {
      "id":"5032501",
      "phuongxa":"Thị trấn Núi Thành",
      "quanhuyen":"50325"
   },
   {
      "id":"5032503",
      "phuongxa":"Xã Tam Xuân I",
      "quanhuyen":"50325"
   },
   {
      "id":"5032505",
      "phuongxa":"Xã Tam Xuân II",
      "quanhuyen":"50325"
   },
   {
      "id":"5032507",
      "phuongxa":"Xã Tam Tiến",
      "quanhuyen":"50325"
   },
   {
      "id":"5032511",
      "phuongxa":"Xã Tam Thạnh",
      "quanhuyen":"50325"
   },
   {
      "id":"5032513",
      "phuongxa":"Xã Tam Anh",
      "quanhuyen":"50325"
   },
   {
      "id":"5032515",
      "phuongxa":"Xã Tam Hòa",
      "quanhuyen":"50325"
   },
   {
      "id":"5032517",
      "phuongxa":"Xã Tam Hiệp",
      "quanhuyen":"50325"
   },
   {
      "id":"5032519",
      "phuongxa":"Xã Tam Hải",
      "quanhuyen":"50325"
   },
   {
      "id":"5032521",
      "phuongxa":"Xã Tam Giang",
      "quanhuyen":"50325"
   },
   {
      "id":"5032523",
      "phuongxa":"Xã Tam Quang",
      "quanhuyen":"50325"
   },
   {
      "id":"5032525",
      "phuongxa":"Xã Tam Nghĩa",
      "quanhuyen":"50325"
   },
   {
      "id":"5032527",
      "phuongxa":"Xã Tam Mỹ",
      "quanhuyen":"50325"
   },
   {
      "id":"5032529",
      "phuongxa":"Xã Tam Trà",
      "quanhuyen":"50325"
   },
   {
      "id":"5032601",
      "phuongxa":"Xã Tam Dân",
      "quanhuyen":"50326"
   },
   {
      "id":"5032700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50327"
   },
   {
      "id":"5032701",
      "phuongxa":"Xã A Vương",
      "quanhuyen":"50327"
   },
   {
      "id":"5032703",
      "phuongxa":"Xã Bha Lê",
      "quanhuyen":"50327"
   },
   {
      "id":"5032705",
      "phuongxa":"Xã A Nông",
      "quanhuyen":"50327"
   },
   {
      "id":"5032707",
      "phuongxa":"Xã A Tiêng",
      "quanhuyen":"50327"
   },
   {
      "id":"5032709",
      "phuongxa":"Xã Lăng",
      "quanhuyen":"50327"
   },
   {
      "id":"5032711",
      "phuongxa":"Xã A Xan",
      "quanhuyen":"50327"
   },
   {
      "id":"5032715",
      "phuongxa":"Xã Dang",
      "quanhuyen":"50327"
   },
   {
      "id":"5032717",
      "phuongxa":"Xã Ch'ơm",
      "quanhuyen":"50327"
   },
   {
      "id":"5032719",
      "phuongxa":"Xã Ga Ri",
      "quanhuyen":"50327"
   },
   {
      "id":"5032900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50329"
   },
   {
      "id":"5032901",
      "phuongxa":"Thị trấn P Rao",
      "quanhuyen":"50329"
   },
   {
      "id":"5032903",
      "phuongxa":"Xã A Ting",
      "quanhuyen":"50329"
   },
   {
      "id":"5032905",
      "phuongxa":"Xã Tư",
      "quanhuyen":"50329"
   },
   {
      "id":"5032907",
      "phuongxa":"Xã Sông Kôn",
      "quanhuyen":"50329"
   },
   {
      "id":"5032909",
      "phuongxa":"Xã Tà Lu",
      "quanhuyen":"50329"
   },
   {
      "id":"5032911",
      "phuongxa":"Xã Ba",
      "quanhuyen":"50329"
   },
   {
      "id":"5032913",
      "phuongxa":"Xã Jơ Ngây",
      "quanhuyen":"50329"
   },
   {
      "id":"5032915",
      "phuongxa":"Xã Kà Dăng",
      "quanhuyen":"50329"
   },
   {
      "id":"5032919",
      "phuongxa":"Xã A Rooi",
      "quanhuyen":"50329"
   },
   {
      "id":"5032921",
      "phuongxa":"Xã Mà Cooi",
      "quanhuyen":"50329"
   },
   {
      "id":"5033100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50331"
   },
   {
      "id":"5033101",
      "phuongxa":"Thị trấn Thạnh Mỹ",
      "quanhuyen":"50331"
   },
   {
      "id":"5033103",
      "phuongxa":"Xã Zuôich",
      "quanhuyen":"50331"
   },
   {
      "id":"5033105",
      "phuongxa":"Xã Tà Bhinh",
      "quanhuyen":"50331"
   },
   {
      "id":"5033107",
      "phuongxa":"Xã La êê",
      "quanhuyen":"50331"
   },
   {
      "id":"5033109",
      "phuongxa":"Xã Cà Dy",
      "quanhuyen":"50331"
   },
   {
      "id":"5033111",
      "phuongxa":"Xã Chà vàl",
      "quanhuyen":"50331"
   },
   {
      "id":"5033113",
      "phuongxa":"Xã La Dêê",
      "quanhuyen":"50331"
   },
   {
      "id":"5033115",
      "phuongxa":"Xã Đắc pring",
      "quanhuyen":"50331"
   },
   {
      "id":"5033117",
      "phuongxa":"Xã Đắc pre",
      "quanhuyen":"50331"
   },
   {
      "id":"5033300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50333"
   },
   {
      "id":"5033301",
      "phuongxa":"Thị trấn Trà My",
      "quanhuyen":"50333"
   },
   {
      "id":"5033303",
      "phuongxa":"Xã Trà Kót",
      "quanhuyen":"50333"
   },
   {
      "id":"5033307",
      "phuongxa":"Xã Trà Đông",
      "quanhuyen":"50333"
   },
   {
      "id":"5033309",
      "phuongxa":"Xã Trà Dương",
      "quanhuyen":"50333"
   },
   {
      "id":"5033311",
      "phuongxa":"Xã Trà Giang",
      "quanhuyen":"50333"
   },
   {
      "id":"5033315",
      "phuongxa":"Xã Trà Đốc",
      "quanhuyen":"50333"
   },
   {
      "id":"5033319",
      "phuongxa":"Xã Trà Giác",
      "quanhuyen":"50333"
   },
   {
      "id":"5033321",
      "phuongxa":"Xã Trà Giáp",
      "quanhuyen":"50333"
   },
   {
      "id":"5033323",
      "phuongxa":"Xã Trà Ka",
      "quanhuyen":"50333"
   },
   {
      "id":"5033325",
      "phuongxa":"Xã Trà Sơn",
      "quanhuyen":"50333"
   },
   {
      "id":"5033500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50335"
   },
   {
      "id":"5033503",
      "phuongxa":"Xã Trà Dơn",
      "quanhuyen":"50335"
   },
   {
      "id":"5033505",
      "phuongxa":"Xã Trà Tập",
      "quanhuyen":"50335"
   },
   {
      "id":"5033507",
      "phuongxa":"Xã Trà Mai",
      "quanhuyen":"50335"
   },
   {
      "id":"5033509",
      "phuongxa":"Xã Trà Cang",
      "quanhuyen":"50335"
   },
   {
      "id":"5033511",
      "phuongxa":"Xã Trà Linh",
      "quanhuyen":"50335"
   },
   {
      "id":"5033513",
      "phuongxa":"Xã Trà Nam",
      "quanhuyen":"50335"
   },
   {
      "id":"5033517",
      "phuongxa":"Xã Trà Vân",
      "quanhuyen":"50335"
   },
   {
      "id":"5033519",
      "phuongxa":"Xã Trà Vinh",
      "quanhuyen":"50335"
   },
   {
      "id":"5033700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50337"
   },
   {
      "id":"5033701",
      "phuongxa":"Xã Quế Lộc",
      "quanhuyen":"50337"
   },
   {
      "id":"5033705",
      "phuongxa":"Xã Quế Ninh",
      "quanhuyen":"50337"
   },
   {
      "id":"5033707",
      "phuongxa":"Xã Quế Phước",
      "quanhuyen":"50337"
   },
   {
      "id":"5033709",
      "phuongxa":"Xã Quế Lâm",
      "quanhuyen":"50337"
   },
   {
      "id":"5033711",
      "phuongxa":"Xã Sơn Viên",
      "quanhuyen":"50337"
   },
   {
      "id":"5033713",
      "phuongxa":"Xã Phước Ninh",
      "quanhuyen":"50337"
   },
   {
      "id":"5050000",
      "phuongxa":"Không xác định",
      "quanhuyen":"50500"
   },
   {
      "id":"5050100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50501"
   },
   {
      "id":"5050101",
      "phuongxa":"Phường Lê Hồng Phong",
      "quanhuyen":"50501"
   },
   {
      "id":"5050103",
      "phuongxa":"Phường Trần Phú",
      "quanhuyen":"50501"
   },
   {
      "id":"5050105",
      "phuongxa":"Phường Trần Hưng Đạo",
      "quanhuyen":"50501"
   },
   {
      "id":"5050107",
      "phuongxa":"Phường Nguyễn Nghiêm",
      "quanhuyen":"50501"
   },
   {
      "id":"5050109",
      "phuongxa":"Phường Nghĩa Lộ",
      "quanhuyen":"50501"
   },
   {
      "id":"5050111",
      "phuongxa":"Phường Chánh Lộ",
      "quanhuyen":"50501"
   },
   {
      "id":"5050113",
      "phuongxa":"Xã Quảng Phú",
      "quanhuyen":"50501"
   },
   {
      "id":"5050115",
      "phuongxa":"Phường Nghĩa Dõng",
      "quanhuyen":"50501"
   },
   {
      "id":"5050117",
      "phuongxa":"Phường Nghĩa Chánh",
      "quanhuyen":"50501"
   },
   {
      "id":"5050119",
      "phuongxa":"Phường Nghĩa Dũng",
      "quanhuyen":"50501"
   },
   {
      "id":"5050300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50503"
   },
   {
      "id":"5050301",
      "phuongxa":"Xã An Vĩnh",
      "quanhuyen":"50503"
   },
   {
      "id":"5050303",
      "phuongxa":"Xã An Bình ",
      "quanhuyen":"50503"
   },
   {
      "id":"5050501",
      "phuongxa":"Thị trấn Châu ổ",
      "quanhuyen":"50505"
   },
   {
      "id":"5050503",
      "phuongxa":"Xã Bình Thới",
      "quanhuyen":"50505"
   },
   {
      "id":"5050505",
      "phuongxa":"Xã Bình Đông",
      "quanhuyen":"50505"
   },
   {
      "id":"5050507",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"50505"
   },
   {
      "id":"5050509",
      "phuongxa":"Xã Bình Chánh",
      "quanhuyen":"50505"
   },
   {
      "id":"5050511",
      "phuongxa":"Xã Bình Nguyên",
      "quanhuyen":"50505"
   },
   {
      "id":"5050513",
      "phuongxa":"Xã Bình Khương",
      "quanhuyen":"50505"
   },
   {
      "id":"5050515",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"50505"
   },
   {
      "id":"5050517",
      "phuongxa":"Xã Bình Trị",
      "quanhuyen":"50505"
   },
   {
      "id":"5050519",
      "phuongxa":"Xã Bình Hải",
      "quanhuyen":"50505"
   },
   {
      "id":"5050521",
      "phuongxa":"Xã Bình Thuận",
      "quanhuyen":"50505"
   },
   {
      "id":"5050523",
      "phuongxa":"Xã Bình Dương",
      "quanhuyen":"50505"
   },
   {
      "id":"5050525",
      "phuongxa":"Xã Bình Phước",
      "quanhuyen":"50505"
   },
   {
      "id":"5050527",
      "phuongxa":"Xã Bình Trung",
      "quanhuyen":"50505"
   },
   {
      "id":"5050529",
      "phuongxa":"Xã Bình Hòa",
      "quanhuyen":"50505"
   },
   {
      "id":"5050531",
      "phuongxa":"Xã Bình Long",
      "quanhuyen":"50505"
   },
   {
      "id":"5050533",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"50505"
   },
   {
      "id":"5050535",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"50505"
   },
   {
      "id":"5050537",
      "phuongxa":"Xã Bình Chương",
      "quanhuyen":"50505"
   },
   {
      "id":"5050539",
      "phuongxa":"Xã Bình Thanh Đông",
      "quanhuyen":"50505"
   },
   {
      "id":"5050541",
      "phuongxa":"Xã Bình Hiệp",
      "quanhuyen":"50505"
   },
   {
      "id":"5050545",
      "phuongxa":"Xã Bình Tân",
      "quanhuyen":"50505"
   },
   {
      "id":"5050547",
      "phuongxa":"Xã Bình Châu",
      "quanhuyen":"50505"
   },
   {
      "id":"5050700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50507"
   },
   {
      "id":"5050701",
      "phuongxa":"Xã Trà Xuân",
      "quanhuyen":"50507"
   },
   {
      "id":"5050703",
      "phuongxa":"Xã Trà Giang",
      "quanhuyen":"50507"
   },
   {
      "id":"5050705",
      "phuongxa":"Xã Trà Thủy",
      "quanhuyen":"50507"
   },
   {
      "id":"5050707",
      "phuongxa":"Xã Trà Hiệp",
      "quanhuyen":"50507"
   },
   {
      "id":"5050709",
      "phuongxa":"Xã Trà Phú",
      "quanhuyen":"50507"
   },
   {
      "id":"5050711",
      "phuongxa":"Xã Trà Thọ",
      "quanhuyen":"50507"
   },
   {
      "id":"5050715",
      "phuongxa":"Xã Trà Khê",
      "quanhuyen":"50507"
   },
   {
      "id":"5050717",
      "phuongxa":"Xã Trà Nham",
      "quanhuyen":"50507"
   },
   {
      "id":"5050719",
      "phuongxa":"Xã Trà Sơn",
      "quanhuyen":"50507"
   },
   {
      "id":"5050721",
      "phuongxa":"Xã Trà Lâm",
      "quanhuyen":"50507"
   },
   {
      "id":"5050723",
      "phuongxa":"Xã Trà Thanh",
      "quanhuyen":"50507"
   },
   {
      "id":"5050727",
      "phuongxa":"Xã Trà Phong",
      "quanhuyen":"50507"
   },
   {
      "id":"5050729",
      "phuongxa":"Xã Trà Lãnh",
      "quanhuyen":"50507"
   },
   {
      "id":"5050731",
      "phuongxa":"Xã Trà Bình",
      "quanhuyen":"50507"
   },
   {
      "id":"5050733",
      "phuongxa":"Xã Trà Bùi",
      "quanhuyen":"50507"
   },
   {
      "id":"5050735",
      "phuongxa":"Xã Trà Trung",
      "quanhuyen":"50507"
   },
   {
      "id":"5050901",
      "phuongxa":"Thị trấn Sơn Tịnh",
      "quanhuyen":"50509"
   },
   {
      "id":"5050903",
      "phuongxa":"Phường Tịnh ấn Tây",
      "quanhuyen":"50501"
   },
   {
      "id":"5050905",
      "phuongxa":"Xã Tịnh ấn Đông",
      "quanhuyen":"50501"
   },
   {
      "id":"5050907",
      "phuongxa":"Xã Tịnh Thọ",
      "quanhuyen":"50509"
   },
   {
      "id":"5050909",
      "phuongxa":"Xã Tịnh Trà",
      "quanhuyen":"50509"
   },
   {
      "id":"5050911",
      "phuongxa":"Xã Tịnh Hòa",
      "quanhuyen":"50501"
   },
   {
      "id":"5050913",
      "phuongxa":"Xã Tịnh Hiệp",
      "quanhuyen":"50509"
   },
   {
      "id":"5050915",
      "phuongxa":"Xã Tịnh Phong",
      "quanhuyen":"50509"
   },
   {
      "id":"5050917",
      "phuongxa":"Xã Tịnh Thiện",
      "quanhuyen":"50501"
   },
   {
      "id":"5050919",
      "phuongxa":"Xã Tịnh Bình",
      "quanhuyen":"50509"
   },
   {
      "id":"5050921",
      "phuongxa":"Xã Tịnh Kỳ",
      "quanhuyen":"50501"
   },
   {
      "id":"5050923",
      "phuongxa":"Xã Tịnh Khê",
      "quanhuyen":"50509"
   },
   {
      "id":"5050925",
      "phuongxa":"Xã Tịnh Đông",
      "quanhuyen":"50509"
   },
   {
      "id":"5050927",
      "phuongxa":"Xã Tịnh Bắc",
      "quanhuyen":"50509"
   },
   {
      "id":"5050929",
      "phuongxa":"Xã Tịnh Châu",
      "quanhuyen":"50501"
   },
   {
      "id":"5050931",
      "phuongxa":"Xã Tịnh Giang",
      "quanhuyen":"50509"
   },
   {
      "id":"5050933",
      "phuongxa":"Xã Tịnh Hà",
      "quanhuyen":"50509"
   },
   {
      "id":"5050937",
      "phuongxa":"Xã Tịnh Minh",
      "quanhuyen":"50509"
   },
   {
      "id":"5050939",
      "phuongxa":"Xã Tịnh Long",
      "quanhuyen":"50501"
   },
   {
      "id":"5050941",
      "phuongxa":"Xã Tịnh An",
      "quanhuyen":"50501"
   },
   {
      "id":"5051100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50511"
   },
   {
      "id":"5051101",
      "phuongxa":"Xã Sơn Dung",
      "quanhuyen":"50511"
   },
   {
      "id":"5051103",
      "phuongxa":"Xã Sơn Mùa",
      "quanhuyen":"50511"
   },
   {
      "id":"5051105",
      "phuongxa":"Xã Sơn Tân",
      "quanhuyen":"50511"
   },
   {
      "id":"5051107",
      "phuongxa":"Xã Sơn Tinh",
      "quanhuyen":"50511"
   },
   {
      "id":"5051300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50513"
   },
   {
      "id":"5051301",
      "phuongxa":"Thị trấn Di Lăng",
      "quanhuyen":"50513"
   },
   {
      "id":"5051303",
      "phuongxa":"Xã Sơn Trung",
      "quanhuyen":"50513"
   },
   {
      "id":"5051305",
      "phuongxa":"Xã Sơn Thượng",
      "quanhuyen":"50513"
   },
   {
      "id":"5051307",
      "phuongxa":"Xã Sơn Bao",
      "quanhuyen":"50513"
   },
   {
      "id":"5051309",
      "phuongxa":"Xã Sơn Thành",
      "quanhuyen":"50513"
   },
   {
      "id":"5051311",
      "phuongxa":"Xã Sơn Hạ",
      "quanhuyen":"50513"
   },
   {
      "id":"5051313",
      "phuongxa":"Xã Sơn Nham",
      "quanhuyen":"50513"
   },
   {
      "id":"5051315",
      "phuongxa":"Xã Sơn Giang",
      "quanhuyen":"50513"
   },
   {
      "id":"5051317",
      "phuongxa":"Xã Sơn Linh",
      "quanhuyen":"50513"
   },
   {
      "id":"5051319",
      "phuongxa":"Xã Sơn Cao",
      "quanhuyen":"50513"
   },
   {
      "id":"5051323",
      "phuongxa":"Xã Sơn Thủy",
      "quanhuyen":"50513"
   },
   {
      "id":"5051325",
      "phuongxa":"Xã Sơn Kỳ",
      "quanhuyen":"50513"
   },
   {
      "id":"5051327",
      "phuongxa":"Xã Sơn Ba",
      "quanhuyen":"50513"
   },
   {
      "id":"5051500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50515"
   },
   {
      "id":"5051501",
      "phuongxa":"Thị trấn La Hà",
      "quanhuyen":"50515"
   },
   {
      "id":"5051503",
      "phuongxa":"Thị trấn Sông Vệ",
      "quanhuyen":"50515"
   },
   {
      "id":"5051505",
      "phuongxa":"Xã Nghĩa Trung",
      "quanhuyen":"50515"
   },
   {
      "id":"4111313",
      "phuongxa":"Xã Vinh Hải",
      "quanhuyen":"41113"
   },
   {
      "id":"4111315",
      "phuongxa":"Xã Vinh Giang",
      "quanhuyen":"41113"
   },
   {
      "id":"4111317",
      "phuongxa":"Xã Vinh Hiền",
      "quanhuyen":"41113"
   },
   {
      "id":"4111319",
      "phuongxa":"Xã Xuân Lộc",
      "quanhuyen":"41113"
   },
   {
      "id":"4111321",
      "phuongxa":"Xã Lộc Hòa",
      "quanhuyen":"41113"
   },
   {
      "id":"4111323",
      "phuongxa":"Xã Lộc Điền",
      "quanhuyen":"41113"
   },
   {
      "id":"4111325",
      "phuongxa":"Xã Lộc Bình",
      "quanhuyen":"41113"
   },
   {
      "id":"4111327",
      "phuongxa":"Xã Lộc Trì",
      "quanhuyen":"41113"
   },
   {
      "id":"4111329",
      "phuongxa":"Xã Lộc Thủy",
      "quanhuyen":"41113"
   },
   {
      "id":"4111331",
      "phuongxa":"Xã Lộc Vĩnh",
      "quanhuyen":"41113"
   },
   {
      "id":"4111333",
      "phuongxa":"Xã Lộc Tiến",
      "quanhuyen":"41113"
   },
   {
      "id":"4111335",
      "phuongxa":"Xã Lộc Hải",
      "quanhuyen":"41113"
   },
   {
      "id":"4111501",
      "phuongxa":"Thị trấn A Lưới",
      "quanhuyen":"41115"
   },
   {
      "id":"4111503",
      "phuongxa":"Xã Hồng Thủy",
      "quanhuyen":"41115"
   },
   {
      "id":"4111507",
      "phuongxa":"Xã Hồng Trung",
      "quanhuyen":"41115"
   },
   {
      "id":"4111509",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"41115"
   },
   {
      "id":"4111511",
      "phuongxa":"Xã Hồng Kim",
      "quanhuyen":"41115"
   },
   {
      "id":"4111513",
      "phuongxa":"Xã Hồng Bắc",
      "quanhuyen":"41115"
   },
   {
      "id":"4111515",
      "phuongxa":"Xã Hồng Quảng",
      "quanhuyen":"41115"
   },
   {
      "id":"4111519",
      "phuongxa":"Xã A Ngo",
      "quanhuyen":"41115"
   },
   {
      "id":"4111521",
      "phuongxa":"Xã Sơn Thủy",
      "quanhuyen":"41115"
   },
   {
      "id":"4111523",
      "phuongxa":"Xã Phú Vinh",
      "quanhuyen":"41115"
   },
   {
      "id":"4111525",
      "phuongxa":"Xã Hồng Hạ",
      "quanhuyen":"41115"
   },
   {
      "id":"4111527",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"41115"
   },
   {
      "id":"4111531",
      "phuongxa":"Xã Hương Phong",
      "quanhuyen":"41115"
   },
   {
      "id":"4111533",
      "phuongxa":"Xã Hương Lâm",
      "quanhuyen":"41115"
   },
   {
      "id":"4111535",
      "phuongxa":"Xã Đông Sơn",
      "quanhuyen":"41115"
   },
   {
      "id":"4111537",
      "phuongxa":"Xã A Đớt",
      "quanhuyen":"41115"
   },
   {
      "id":"4111539",
      "phuongxa":"Xã A Roằng",
      "quanhuyen":"41115"
   },
   {
      "id":"4111700",
      "phuongxa":"Không xác định",
      "quanhuyen":"41117"
   },
   {
      "id":"4111701",
      "phuongxa":"Thị trấn Khe Tre",
      "quanhuyen":"41117"
   },
   {
      "id":"4111703",
      "phuongxa":"Xã Hương Phú",
      "quanhuyen":"41117"
   },
   {
      "id":"4111705",
      "phuongxa":"Xã Hương Sơn",
      "quanhuyen":"41117"
   },
   {
      "id":"4111707",
      "phuongxa":"Xã Thượng Quảng",
      "quanhuyen":"41117"
   },
   {
      "id":"4111711",
      "phuongxa":"Xã Hương Giang",
      "quanhuyen":"41117"
   },
   {
      "id":"4111713",
      "phuongxa":"Xã Hương Hoà",
      "quanhuyen":"41117"
   },
   {
      "id":"4111715",
      "phuongxa":"Xã Hương Lộc",
      "quanhuyen":"41117"
   },
   {
      "id":"4111717",
      "phuongxa":"Xã Thượng Long",
      "quanhuyen":"41117"
   },
   {
      "id":"4111719",
      "phuongxa":"Xã Thượng Nhật",
      "quanhuyen":"41117"
   },
   {
      "id":"4111721",
      "phuongxa":"Xã Thượng Lộ",
      "quanhuyen":"41117"
   },
   {
      "id":"5010000",
      "phuongxa":"Không xác định",
      "quanhuyen":"50100"
   },
   {
      "id":"5010100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50101"
   },
   {
      "id":"5010101",
      "phuongxa":"Phường Hải Châu  I",
      "quanhuyen":"50101"
   },
   {
      "id":"5010103",
      "phuongxa":"Phường Hải Châu II",
      "quanhuyen":"50101"
   },
   {
      "id":"5010105",
      "phuongxa":"Phường Thạch Thang",
      "quanhuyen":"50101"
   },
   {
      "id":"5010107",
      "phuongxa":"Phường Thanh Bình",
      "quanhuyen":"50101"
   },
   {
      "id":"5010109",
      "phuongxa":"Phường Thuận Phước",
      "quanhuyen":"50101"
   },
   {
      "id":"5010111",
      "phuongxa":"Phường Bình Thuận",
      "quanhuyen":"50101"
   },
   {
      "id":"5010113",
      "phuongxa":"Phường Hoà Thuận",
      "quanhuyen":"50101"
   },
   {
      "id":"5010115",
      "phuongxa":"Phường Nam Dương",
      "quanhuyen":"50101"
   },
   {
      "id":"5010117",
      "phuongxa":"Phường Phước Ninh",
      "quanhuyen":"50101"
   },
   {
      "id":"5010119",
      "phuongxa":"Phường Bình Hiên",
      "quanhuyen":"50101"
   },
   {
      "id":"5010121",
      "phuongxa":"Phường Hoà Cường",
      "quanhuyen":"50101"
   },
   {
      "id":"5010123",
      "phuongxa":"Phường Khuê Trung",
      "quanhuyen":"50101"
   },
   {
      "id":"5010301",
      "phuongxa":"Phường An Khê",
      "quanhuyen":"50103"
   },
   {
      "id":"5010303",
      "phuongxa":"Phường Thanh Lộc Đán",
      "quanhuyen":"50103"
   },
   {
      "id":"5010305",
      "phuongxa":"Phường Xuân Hà",
      "quanhuyen":"50103"
   },
   {
      "id":"5010307",
      "phuongxa":"Phường Tam Thuận",
      "quanhuyen":"50103"
   },
   {
      "id":"5010309",
      "phuongxa":"Phường Vĩnh Trung",
      "quanhuyen":"50103"
   },
   {
      "id":"5010311",
      "phuongxa":"Phường Tân Chính",
      "quanhuyen":"50103"
   },
   {
      "id":"5010313",
      "phuongxa":"Phường Thạc Gián",
      "quanhuyen":"50103"
   },
   {
      "id":"5010315",
      "phuongxa":"Phường Chính Gián",
      "quanhuyen":"50103"
   },
   {
      "id":"5010500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50105"
   },
   {
      "id":"5010501",
      "phuongxa":"Phường An Hải Tây",
      "quanhuyen":"50105"
   },
   {
      "id":"5010503",
      "phuongxa":"Phường An Hải Bắc",
      "quanhuyen":"50105"
   },
   {
      "id":"5010505",
      "phuongxa":"Phường Nại Hiên Đông",
      "quanhuyen":"50105"
   },
   {
      "id":"5111309",
      "phuongxa":"Xã Sơn Hiệp",
      "quanhuyen":"51113"
   },
   {
      "id":"5111311",
      "phuongxa":"Xã Sơn Trung",
      "quanhuyen":"51113"
   },
   {
      "id":"5111313",
      "phuongxa":"Xã Ba Cụm Bắc",
      "quanhuyen":"51113"
   },
   {
      "id":"5111315",
      "phuongxa":"Xã Ba Cụm Nam",
      "quanhuyen":"51113"
   },
   {
      "id":"5111500",
      "phuongxa":"Không xác định",
      "quanhuyen":"51115"
   },
   {
      "id":"5111700",
      "phuongxa":"Không xác định",
      "quanhuyen":"51117"
   },
   {
      "id":"5111701",
      "phuongxa":"Xã Cam Tân",
      "quanhuyen":"51117"
   },
   {
      "id":"5111703",
      "phuongxa":"Xã Cam Hoà",
      "quanhuyen":"51117"
   },
   {
      "id":"5111705",
      "phuongxa":"Xã Cam Hải Đông",
      "quanhuyen":"51117"
   },
   {
      "id":"5111711",
      "phuongxa":"Xã Cam Hiệp Bắc",
      "quanhuyen":"51117"
   },
   {
      "id":"5111713",
      "phuongxa":"Thị trấn Cam Đức",
      "quanhuyen":"51117"
   },
   {
      "id":"5111715",
      "phuongxa":"Xã Cam Hiệp Nam",
      "quanhuyen":"51117"
   },
   {
      "id":"5111717",
      "phuongxa":"Xã Cam Phước Tây",
      "quanhuyen":"51117"
   },
   {
      "id":"5111721",
      "phuongxa":"Xã Cam An Bắc",
      "quanhuyen":"51117"
   },
   {
      "id":"5111723",
      "phuongxa":"Xã Cam An Nam",
      "quanhuyen":"51117"
   },
   {
      "id":"5111725",
      "phuongxa":"Xã Suối Cát",
      "quanhuyen":"51117"
   },
   {
      "id":"5111727",
      "phuongxa":"Xã Suối Tân",
      "quanhuyen":"51117"
   },
   {
      "id":"6010000",
      "phuongxa":"Không xác định",
      "quanhuyen":"60100"
   },
   {
      "id":"6010100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60101"
   },
   {
      "id":"6010103",
      "phuongxa":"Phường Quyết Thắng",
      "quanhuyen":"60101"
   },
   {
      "id":"6010105",
      "phuongxa":"Phường Thắng Lợi",
      "quanhuyen":"60101"
   },
   {
      "id":"6010107",
      "phuongxa":"Phường Thống Nhất",
      "quanhuyen":"60101"
   },
   {
      "id":"6010109",
      "phuongxa":"Xã Đắk Cấm",
      "quanhuyen":"60101"
   },
   {
      "id":"6010111",
      "phuongxa":"Xã Kroong",
      "quanhuyen":"60101"
   },
   {
      "id":"6010115",
      "phuongxa":"Xã Vinh Quang",
      "quanhuyen":"60101"
   },
   {
      "id":"6010117",
      "phuongxa":"Xã Đắk Blà",
      "quanhuyen":"60101"
   },
   {
      "id":"6010119",
      "phuongxa":"Xã Ia Chim",
      "quanhuyen":"60101"
   },
   {
      "id":"6010121",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"60101"
   },
   {
      "id":"6010125",
      "phuongxa":"Xã Đắk Rơ Wa",
      "quanhuyen":"60101"
   },
   {
      "id":"6010127",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"60101"
   },
   {
      "id":"6010129",
      "phuongxa":"Phường Lê Lợi",
      "quanhuyen":"60101"
   },
   {
      "id":"6010131",
      "phuongxa":"Phường Duy Tân",
      "quanhuyen":"60101"
   },
   {
      "id":"6010300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60103"
   },
   {
      "id":"6010301",
      "phuongxa":"Thị trấn Đắk Glei",
      "quanhuyen":"60103"
   },
   {
      "id":"6010303",
      "phuongxa":"Xã Đắk Plô",
      "quanhuyen":"60103"
   },
   {
      "id":"6010305",
      "phuongxa":"Xã Đắk Man",
      "quanhuyen":"60103"
   },
   {
      "id":"6010307",
      "phuongxa":"Xã Đắk Nhoong",
      "quanhuyen":"60103"
   },
   {
      "id":"6010309",
      "phuongxa":"Xã Đắk Pék",
      "quanhuyen":"60103"
   },
   {
      "id":"6010313",
      "phuongxa":"Xã Mường Hoong",
      "quanhuyen":"60103"
   },
   {
      "id":"6010315",
      "phuongxa":"Xã Ngọc Linh",
      "quanhuyen":"60103"
   },
   {
      "id":"6010317",
      "phuongxa":"Xã Đắk Long",
      "quanhuyen":"60103"
   },
   {
      "id":"6010319",
      "phuongxa":"Xã Đắk KRoong",
      "quanhuyen":"60103"
   },
   {
      "id":"6010321",
      "phuongxa":"Xã Đắk Môn",
      "quanhuyen":"60103"
   },
   {
      "id":"6010500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60105"
   },
   {
      "id":"6010501",
      "phuongxa":"Thị trấn Plei Kần",
      "quanhuyen":"60105"
   },
   {
      "id":"6010503",
      "phuongxa":"Xã Đắk Ang",
      "quanhuyen":"60105"
   },
   {
      "id":"6010505",
      "phuongxa":"Xã Đắk Dục",
      "quanhuyen":"60105"
   },
   {
      "id":"6010507",
      "phuongxa":"Xã Đắk Nông",
      "quanhuyen":"60105"
   },
   {
      "id":"6010509",
      "phuongxa":"Xã Đắk Xú",
      "quanhuyen":"60105"
   },
   {
      "id":"6010511",
      "phuongxa":"Xã Pờ Y",
      "quanhuyen":"60105"
   },
   {
      "id":"6010513",
      "phuongxa":"Xã Sa Loong",
      "quanhuyen":"60105"
   },
   {
      "id":"6010700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60107"
   },
   {
      "id":"6010701",
      "phuongxa":"Thị trấn Đắk Tô",
      "quanhuyen":"60107"
   },
   {
      "id":"6010703",
      "phuongxa":"Xã Đắk Na",
      "quanhuyen":"60107"
   },
   {
      "id":"6010705",
      "phuongxa":"Xã Măng Xăng",
      "quanhuyen":"60107"
   },
   {
      "id":"6010707",
      "phuongxa":"Xã Ngọk Lây",
      "quanhuyen":"60107"
   },
   {
      "id":"6010709",
      "phuongxa":"Xã Đắk Sao",
      "quanhuyen":"60107"
   },
   {
      "id":"6010711",
      "phuongxa":"Xã Đắk Tơ Kan",
      "quanhuyen":"60107"
   },
   {
      "id":"6010715",
      "phuongxa":"Xã Ngok Yêu",
      "quanhuyen":"60107"
   },
   {
      "id":"6010717",
      "phuongxa":"Xã Ngọk Tụ",
      "quanhuyen":"60107"
   },
   {
      "id":"6010719",
      "phuongxa":"Xã Văn Lem",
      "quanhuyen":"60107"
   },
   {
      "id":"6010721",
      "phuongxa":"Xã Kon Đào",
      "quanhuyen":"60107"
   },
   {
      "id":"6010723",
      "phuongxa":"Xã Đắk Hà",
      "quanhuyen":"60107"
   },
   {
      "id":"6010725",
      "phuongxa":"Xã Tân Cảnh",
      "quanhuyen":"60107"
   },
   {
      "id":"6010727",
      "phuongxa":"Xã Pô Kô",
      "quanhuyen":"60107"
   },
   {
      "id":"6010729",
      "phuongxa":"Xã Diên Bình",
      "quanhuyen":"60107"
   },
   {
      "id":"6010900",
      "phuongxa":"Không xác định",
      "quanhuyen":"60109"
   },
   {
      "id":"6010901",
      "phuongxa":"Thị trấn Kon Plông",
      "quanhuyen":"60109"
   },
   {
      "id":"6010903",
      "phuongxa":"Xã Đak Ring",
      "quanhuyen":"60109"
   },
   {
      "id":"6010905",
      "phuongxa":"Xã Măng Buk",
      "quanhuyen":"60109"
   },
   {
      "id":"6010907",
      "phuongxa":"Xã Ngok Tem",
      "quanhuyen":"60109"
   },
   {
      "id":"6010909",
      "phuongxa":"Xã Đak Kôi",
      "quanhuyen":"60109"
   },
   {
      "id":"6010911",
      "phuongxa":"Xã Măng Cành",
      "quanhuyen":"60109"
   },
   {
      "id":"6010913",
      "phuongxa":"Xã Hiếu",
      "quanhuyen":"60109"
   },
   {
      "id":"6010915",
      "phuongxa":"Xã Pờ Ê",
      "quanhuyen":"60109"
   },
   {
      "id":"6010917",
      "phuongxa":"Xã Đak Ruồng",
      "quanhuyen":"60109"
   },
   {
      "id":"6010919",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"60109"
   },
   {
      "id":"6010921",
      "phuongxa":"Xã Đak Pne",
      "quanhuyen":"60109"
   },
   {
      "id":"6010923",
      "phuongxa":"Xã Đak Tờ Re",
      "quanhuyen":"60109"
   },
   {
      "id":"6011103",
      "phuongxa":"Xã Đak PXi",
      "quanhuyen":"60111"
   },
   {
      "id":"6011105",
      "phuongxa":"Xã Đak HRing",
      "quanhuyen":"60111"
   },
   {
      "id":"6011107",
      "phuongxa":"Xã Đak Ui",
      "quanhuyen":"60111"
   },
   {
      "id":"6011109",
      "phuongxa":"Xã Ngok Wang",
      "quanhuyen":"60111"
   },
   {
      "id":"6011111",
      "phuongxa":"Xã Hà Mòn",
      "quanhuyen":"60111"
   },
   {
      "id":"6011113",
      "phuongxa":"Xã Ngok Réo",
      "quanhuyen":"60111"
   },
   {
      "id":"6011115",
      "phuongxa":"Xã Đak La",
      "quanhuyen":"60111"
   },
   {
      "id":"6011300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60113"
   },
   {
      "id":"6011301",
      "phuongxa":"Thị trấn Sa Thầy",
      "quanhuyen":"60113"
   },
   {
      "id":"6011303",
      "phuongxa":"Xã Rơ Kơi",
      "quanhuyen":"60113"
   },
   {
      "id":"6011305",
      "phuongxa":"Xã Mô Rai",
      "quanhuyen":"60113"
   },
   {
      "id":"6011309",
      "phuongxa":"Xã Sa Sơn",
      "quanhuyen":"60113"
   },
   {
      "id":"6011311",
      "phuongxa":"Xã Sa Nghĩa",
      "quanhuyen":"60113"
   },
   {
      "id":"6011313",
      "phuongxa":"Xã Sa Bình",
      "quanhuyen":"60113"
   },
   {
      "id":"6011315",
      "phuongxa":"Xã Ya Xiêr",
      "quanhuyen":"60113"
   },
   {
      "id":"6011317",
      "phuongxa":"Xã Ya ly",
      "quanhuyen":"60113"
   },
   {
      "id":"6011500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60115"
   },
   {
      "id":"6011503",
      "phuongxa":"Xã Đắk Kôi",
      "quanhuyen":"60115"
   },
   {
      "id":"6011505",
      "phuongxa":"Xã Đắk Tơ Lung",
      "quanhuyen":"60115"
   },
   {
      "id":"6011507",
      "phuongxa":"Xã Đắk Ruồng",
      "quanhuyen":"60115"
   },
   {
      "id":"6011509",
      "phuongxa":"Xã Đắk Pne",
      "quanhuyen":"60115"
   },
   {
      "id":"6011511",
      "phuongxa":"Xã Đắk Tờ Re",
      "quanhuyen":"60115"
   },
   {
      "id":"6011513",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"60115"
   },
   {
      "id":"6030000",
      "phuongxa":"Không xác định",
      "quanhuyen":"60300"
   },
   {
      "id":"6030100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60301"
   },
   {
      "id":"6030101",
      "phuongxa":"Phường Yên Đỗ",
      "quanhuyen":"60301"
   },
   {
      "id":"6030103",
      "phuongxa":"Phường Diên Hồng",
      "quanhuyen":"60301"
   },
   {
      "id":"6030105",
      "phuongxa":"Phường Hội Thương",
      "quanhuyen":"60301"
   },
   {
      "id":"6030107",
      "phuongxa":"Phường Hội Phú",
      "quanhuyen":"60301"
   },
   {
      "id":"6030109",
      "phuongxa":"Phường Hoa Lư",
      "quanhuyen":"60301"
   },
   {
      "id":"6030111",
      "phuongxa":"Phường Thống Nhất",
      "quanhuyen":"60301"
   },
   {
      "id":"6030115",
      "phuongxa":"Xã Biển Hồ",
      "quanhuyen":"60301"
   },
   {
      "id":"6030117",
      "phuongxa":"Xã Tân Sơn",
      "quanhuyen":"60301"
   },
   {
      "id":"6030119",
      "phuongxa":"Xã Trà Đa",
      "quanhuyen":"60301"
   },
   {
      "id":"6030121",
      "phuongxa":"Xã Chư á",
      "quanhuyen":"60301"
   },
   {
      "id":"6030123",
      "phuongxa":"Xã An Phú",
      "quanhuyen":"60301"
   },
   {
      "id":"6030125",
      "phuongxa":"Xã Diên Phú",
      "quanhuyen":"60301"
   },
   {
      "id":"6030127",
      "phuongxa":"Xã Gào",
      "quanhuyen":"60301"
   },
   {
      "id":"6030300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60303"
   },
   {
      "id":"6030301",
      "phuongxa":"Thị trấn KBang",
      "quanhuyen":"60303"
   },
   {
      "id":"6030303",
      "phuongxa":"Xã Đăk Rong",
      "quanhuyen":"60303"
   },
   {
      "id":"6030305",
      "phuongxa":"Xã Sơn Lang",
      "quanhuyen":"60303"
   },
   {
      "id":"6030307",
      "phuongxa":"Xã Kon Pne",
      "quanhuyen":"60303"
   },
   {
      "id":"6030309",
      "phuongxa":"Xã KRong",
      "quanhuyen":"60303"
   },
   {
      "id":"6030311",
      "phuongxa":"Xã Sơ Pai",
      "quanhuyen":"60303"
   },
   {
      "id":"6030313",
      "phuongxa":"Xã Lơ Ku",
      "quanhuyen":"60303"
   },
   {
      "id":"6030315",
      "phuongxa":"Xã Đông",
      "quanhuyen":"60303"
   },
   {
      "id":"6030317",
      "phuongxa":"Xã Nghĩa An",
      "quanhuyen":"60303"
   },
   {
      "id":"6030319",
      "phuongxa":"Xã Tơ Tung",
      "quanhuyen":"60303"
   },
   {
      "id":"6030323",
      "phuongxa":"Xã Kông Bơ La",
      "quanhuyen":"60303"
   },
   {
      "id":"6030325",
      "phuongxa":"Xã Đăk HLơ",
      "quanhuyen":"60303"
   },
   {
      "id":"6030500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60305"
   },
   {
      "id":"6030501",
      "phuongxa":"Thị trấn Mang Yang",
      "quanhuyen":"60305"
   },
   {
      "id":"6030503",
      "phuongxa":"Xã Hà Đông",
      "quanhuyen":"60305"
   },
   {
      "id":"6030505",
      "phuongxa":"Xã Đăk Đoa",
      "quanhuyen":"60305"
   },
   {
      "id":"6030507",
      "phuongxa":"Xã Ayun",
      "quanhuyen":"60305"
   },
   {
      "id":"6030509",
      "phuongxa":"Xã Hải Yang",
      "quanhuyen":"60305"
   },
   {
      "id":"6030511",
      "phuongxa":"Xã Hà Bầu",
      "quanhuyen":"60305"
   },
   {
      "id":"6030513",
      "phuongxa":"Xã Kon Gang",
      "quanhuyen":"60305"
   },
   {
      "id":"6030515",
      "phuongxa":"Xã HRa",
      "quanhuyen":"60305"
   },
   {
      "id":"6030517",
      "phuongxa":"Xã Nam Yang",
      "quanhuyen":"60305"
   },
   {
      "id":"5070719",
      "phuongxa":"Xã Ân Hữu",
      "quanhuyen":"50707"
   },
   {
      "id":"5070721",
      "phuongxa":"Xã Bok Tới",
      "quanhuyen":"50707"
   },
   {
      "id":"5070723",
      "phuongxa":"Xã Ân Tường Đông",
      "quanhuyen":"50707"
   },
   {
      "id":"5070725",
      "phuongxa":"Xã Ân Nghĩa",
      "quanhuyen":"50707"
   },
   {
      "id":"5070727",
      "phuongxa":"Xã Ân Tường Tây",
      "quanhuyen":"50707"
   },
   {
      "id":"5070900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50709"
   },
   {
      "id":"5070901",
      "phuongxa":"Thị trấn Phù Mỹ",
      "quanhuyen":"50709"
   },
   {
      "id":"5070903",
      "phuongxa":"Xã Mỹ Đức",
      "quanhuyen":"50709"
   },
   {
      "id":"5070905",
      "phuongxa":"Xã Mỹ Châu",
      "quanhuyen":"50709"
   },
   {
      "id":"5070909",
      "phuongxa":"Xã Mỹ Lộc",
      "quanhuyen":"50709"
   },
   {
      "id":"5070911",
      "phuongxa":"Xã Mỹ Lợi",
      "quanhuyen":"50709"
   },
   {
      "id":"5070913",
      "phuongxa":"Xã Mỹ An",
      "quanhuyen":"50709"
   },
   {
      "id":"5070917",
      "phuongxa":"Xã Mỹ Trinh",
      "quanhuyen":"50709"
   },
   {
      "id":"5070919",
      "phuongxa":"Xã Mỹ Thọ",
      "quanhuyen":"50709"
   },
   {
      "id":"5070923",
      "phuongxa":"Xã Mỹ Thành",
      "quanhuyen":"50709"
   },
   {
      "id":"5070925",
      "phuongxa":"Xã Mỹ Chánh",
      "quanhuyen":"50709"
   },
   {
      "id":"5070927",
      "phuongxa":"Xã Mỹ Quang",
      "quanhuyen":"50709"
   },
   {
      "id":"5070929",
      "phuongxa":"Xã Mỹ Hiệp",
      "quanhuyen":"50709"
   },
   {
      "id":"5070931",
      "phuongxa":"Xã Mỹ Tài",
      "quanhuyen":"50709"
   },
   {
      "id":"5071100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50711"
   },
   {
      "id":"5071101",
      "phuongxa":"Xã Vĩnh Sơn",
      "quanhuyen":"50711"
   },
   {
      "id":"5071103",
      "phuongxa":"Xã Vĩnh Kim",
      "quanhuyen":"50711"
   },
   {
      "id":"5071105",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"50711"
   },
   {
      "id":"5071107",
      "phuongxa":"Xã Vĩnh Hiệp",
      "quanhuyen":"50711"
   },
   {
      "id":"5071111",
      "phuongxa":"Xã Vĩnh Quang",
      "quanhuyen":"50711"
   },
   {
      "id":"5071113",
      "phuongxa":"Xã Vĩnh Thịnh",
      "quanhuyen":"50711"
   },
   {
      "id":"5071300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50713"
   },
   {
      "id":"5071301",
      "phuongxa":"Thị trấn Ngô Mây",
      "quanhuyen":"50713"
   },
   {
      "id":"5071305",
      "phuongxa":"Xã Cát Minh",
      "quanhuyen":"50713"
   },
   {
      "id":"5071307",
      "phuongxa":"Xã Cát Tài",
      "quanhuyen":"50713"
   },
   {
      "id":"5071311",
      "phuongxa":"Xã Cát Lâm",
      "quanhuyen":"50713"
   },
   {
      "id":"5071313",
      "phuongxa":"Xã Cát Hanh",
      "quanhuyen":"50713"
   },
   {
      "id":"5071315",
      "phuongxa":"Xã Cát Thành",
      "quanhuyen":"50713"
   },
   {
      "id":"5071317",
      "phuongxa":"Xã Cát Hải",
      "quanhuyen":"50713"
   },
   {
      "id":"5071319",
      "phuongxa":"Xã Cát Hiệp",
      "quanhuyen":"50713"
   },
   {
      "id":"5071321",
      "phuongxa":"Xã Cát Trinh",
      "quanhuyen":"50713"
   },
   {
      "id":"5071323",
      "phuongxa":"Xã Cát Nhơn",
      "quanhuyen":"50713"
   },
   {
      "id":"5071325",
      "phuongxa":"Xã Cát Hưng",
      "quanhuyen":"50713"
   },
   {
      "id":"5071327",
      "phuongxa":"Xã Cát Tường",
      "quanhuyen":"50713"
   },
   {
      "id":"5071329",
      "phuongxa":"Xã Cát Tân",
      "quanhuyen":"50713"
   },
   {
      "id":"5071331",
      "phuongxa":"Thị trấn Cát Tiến",
      "quanhuyen":"50713"
   },
   {
      "id":"5071333",
      "phuongxa":"Xã Cát Thắng",
      "quanhuyen":"50713"
   },
   {
      "id":"5071335",
      "phuongxa":"Xã Cát Chánh",
      "quanhuyen":"50713"
   },
   {
      "id":"5071500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50715"
   },
   {
      "id":"5071501",
      "phuongxa":"Thị trấn Phú Phong",
      "quanhuyen":"50715"
   },
   {
      "id":"5071503",
      "phuongxa":"Xã Bình Tân",
      "quanhuyen":"50715"
   },
   {
      "id":"5071505",
      "phuongxa":"Xã Tây Thuận",
      "quanhuyen":"50715"
   },
   {
      "id":"5071507",
      "phuongxa":"Xã Bình Thuận",
      "quanhuyen":"50715"
   },
   {
      "id":"5071509",
      "phuongxa":"Xã Tây Giang",
      "quanhuyen":"50715"
   },
   {
      "id":"5071511",
      "phuongxa":"Xã Bình Thành",
      "quanhuyen":"50715"
   },
   {
      "id":"5071515",
      "phuongxa":"Xã Bình Hòa",
      "quanhuyen":"50715"
   },
   {
      "id":"5071517",
      "phuongxa":"Xã Bình Tường",
      "quanhuyen":"50715"
   },
   {
      "id":"5071519",
      "phuongxa":"Xã Tây Vinh",
      "quanhuyen":"50715"
   },
   {
      "id":"5071521",
      "phuongxa":"Xã Tây Bình",
      "quanhuyen":"50715"
   },
   {
      "id":"5071523",
      "phuongxa":"Xã Vĩnh An",
      "quanhuyen":"50715"
   },
   {
      "id":"5071525",
      "phuongxa":"Xã Tây Xuân",
      "quanhuyen":"50715"
   },
   {
      "id":"5071527",
      "phuongxa":"Xã Tây Phú",
      "quanhuyen":"50715"
   },
   {
      "id":"5071529",
      "phuongxa":"Xã Bình Nghi",
      "quanhuyen":"50715"
   },
   {
      "id":"5071700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50717"
   },
   {
      "id":"5071701",
      "phuongxa":"Phường Bình Định",
      "quanhuyen":"50717"
   },
   {
      "id":"5071703",
      "phuongxa":"Phường Đập Đá",
      "quanhuyen":"50717"
   },
   {
      "id":"5071705",
      "phuongxa":"Phường Nhơn Thành",
      "quanhuyen":"50717"
   },
   {
      "id":"5071707",
      "phuongxa":"Xã Nhơn Mỹ",
      "quanhuyen":"50717"
   },
   {
      "id":"5071709",
      "phuongxa":"Xã Nhơn Hạnh",
      "quanhuyen":"50717"
   },
   {
      "id":"5071711",
      "phuongxa":"Xã Nhơn Phong",
      "quanhuyen":"50717"
   },
   {
      "id":"5071713",
      "phuongxa":"Xã Nhơn Hậu",
      "quanhuyen":"50717"
   },
   {
      "id":"5071715",
      "phuongxa":"Xã Nhơn An",
      "quanhuyen":"50717"
   },
   {
      "id":"5071719",
      "phuongxa":"Xã Nhơn Phúc",
      "quanhuyen":"50717"
   },
   {
      "id":"5071721",
      "phuongxa":"Xã Nhơn Khánh",
      "quanhuyen":"50717"
   },
   {
      "id":"5071723",
      "phuongxa":"Xã Nhơn Lộc",
      "quanhuyen":"50717"
   },
   {
      "id":"5071725",
      "phuongxa":"Phường Nhơn Hòa",
      "quanhuyen":"50717"
   },
   {
      "id":"5071727",
      "phuongxa":"Xã Nhơn Thọ",
      "quanhuyen":"50717"
   },
   {
      "id":"5071729",
      "phuongxa":"Xã Nhơn Tân",
      "quanhuyen":"50717"
   },
   {
      "id":"5071900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50719"
   },
   {
      "id":"5071901",
      "phuongxa":"Thị trấn Tuy Phước",
      "quanhuyen":"50719"
   },
   {
      "id":"5071905",
      "phuongxa":"Xã Phước Thắng",
      "quanhuyen":"50719"
   },
   {
      "id":"5071907",
      "phuongxa":"Xã Phước Hưng",
      "quanhuyen":"50719"
   },
   {
      "id":"5071909",
      "phuongxa":"Xã Phước Hòa",
      "quanhuyen":"50719"
   },
   {
      "id":"5071911",
      "phuongxa":"Xã Phước Quang",
      "quanhuyen":"50719"
   },
   {
      "id":"5071913",
      "phuongxa":"Xã Phước Sơn",
      "quanhuyen":"50719"
   },
   {
      "id":"5071915",
      "phuongxa":"Xã Phước Hiệp",
      "quanhuyen":"50719"
   },
   {
      "id":"5071919",
      "phuongxa":"Xã Phước Thuận",
      "quanhuyen":"50719"
   },
   {
      "id":"5071921",
      "phuongxa":"Xã Phước Nghĩa",
      "quanhuyen":"50719"
   },
   {
      "id":"5071923",
      "phuongxa":"Xã Phước An",
      "quanhuyen":"50719"
   },
   {
      "id":"5071925",
      "phuongxa":"Xã Phước Thành",
      "quanhuyen":"50719"
   },
   {
      "id":"5071927",
      "phuongxa":"Xã Phước Mỹ",
      "quanhuyen":"50701"
   },
   {
      "id":"5072101",
      "phuongxa":"Xã Canh Hiệp",
      "quanhuyen":"50721"
   },
   {
      "id":"5072103",
      "phuongxa":"Xã Canh Liên",
      "quanhuyen":"50721"
   },
   {
      "id":"5072105",
      "phuongxa":"Xã Canh Vinh",
      "quanhuyen":"50721"
   },
   {
      "id":"5072107",
      "phuongxa":"Xã Canh Hiển",
      "quanhuyen":"50721"
   },
   {
      "id":"5072109",
      "phuongxa":"Xã Canh Thuận",
      "quanhuyen":"50721"
   },
   {
      "id":"5090100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50901"
   },
   {
      "id":"5090101",
      "phuongxa":"Phường 1",
      "quanhuyen":"50911"
   },
   {
      "id":"5090103",
      "phuongxa":"Phường 2",
      "quanhuyen":"50911"
   },
   {
      "id":"5090105",
      "phuongxa":"Phường 3",
      "quanhuyen":"50911"
   },
   {
      "id":"5090107",
      "phuongxa":"Phường 4",
      "quanhuyen":"50911"
   },
   {
      "id":"5090109",
      "phuongxa":"Phường 5",
      "quanhuyen":"50911"
   },
   {
      "id":"5090111",
      "phuongxa":"Phường 6",
      "quanhuyen":"50911"
   },
   {
      "id":"5090113",
      "phuongxa":"Xã Hòa Hội",
      "quanhuyen":"50901"
   },
   {
      "id":"5090115",
      "phuongxa":"Xã Hòa Định Tây",
      "quanhuyen":"50901"
   },
   {
      "id":"5090117",
      "phuongxa":"Xã Hòa Định Đông",
      "quanhuyen":"50901"
   },
   {
      "id":"5090119",
      "phuongxa":"Xã Hòa Thắng",
      "quanhuyen":"50901"
   },
   {
      "id":"5090121",
      "phuongxa":"Xã Hòa An",
      "quanhuyen":"50901"
   },
   {
      "id":"5090123",
      "phuongxa":"Xã Hòa Quang",
      "quanhuyen":"50901"
   },
   {
      "id":"5090125",
      "phuongxa":"Xã Hòa Trị",
      "quanhuyen":"50901"
   },
   {
      "id":"5090127",
      "phuongxa":"Xã Hòa Kiến",
      "quanhuyen":"50911"
   },
   {
      "id":"5090129",
      "phuongxa":"Xã Bình Kiến",
      "quanhuyen":"50911"
   },
   {
      "id":"5090300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50903"
   },
   {
      "id":"5090301",
      "phuongxa":"Thị trấn La Hai",
      "quanhuyen":"50903"
   },
   {
      "id":"5090303",
      "phuongxa":"Xã Phú Mỡ",
      "quanhuyen":"50903"
   },
   {
      "id":"5090305",
      "phuongxa":"Xã Đa Lộc",
      "quanhuyen":"50903"
   },
   {
      "id":"5090307",
      "phuongxa":"Xã Xuân Lãnh",
      "quanhuyen":"50903"
   },
   {
      "id":"5090309",
      "phuongxa":"Xã Xuân Long",
      "quanhuyen":"50903"
   },
   {
      "id":"5090311",
      "phuongxa":"Xã Xuân Quang 1",
      "quanhuyen":"50903"
   },
   {
      "id":"5090313",
      "phuongxa":"Xã Xuân Quang 2",
      "quanhuyen":"50903"
   },
   {
      "id":"5090315",
      "phuongxa":"Xã Xuân Sơn Bắc",
      "quanhuyen":"50903"
   },
   {
      "id":"5090317",
      "phuongxa":"Xã Xuân Sơn Nam",
      "quanhuyen":"50903"
   },
   {
      "id":"5090319",
      "phuongxa":"Xã Xuân Quang 3",
      "quanhuyen":"50903"
   },
   {
      "id":"5090321",
      "phuongxa":"Xã Xuân Phước",
      "quanhuyen":"50903"
   },
   {
      "id":"5090500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50905"
   },
   {
      "id":"5090501",
      "phuongxa":"Thị trấn Sông Cầu",
      "quanhuyen":"50905"
   },
   {
      "id":"5090503",
      "phuongxa":"Xã Xuân Lộc",
      "quanhuyen":"50905"
   },
   {
      "id":"5090505",
      "phuongxa":"Xã Xuân Hải",
      "quanhuyen":"50905"
   },
   {
      "id":"5090507",
      "phuongxa":"Xã Xuân Bình",
      "quanhuyen":"50905"
   },
   {
      "id":"5090509",
      "phuongxa":"Xã Xuân Hòa",
      "quanhuyen":"50905"
   },
   {
      "id":"5090511",
      "phuongxa":"Xã Xuân Cảnh",
      "quanhuyen":"50905"
   },
   {
      "id":"5090513",
      "phuongxa":"Xã Xuân Thịnh",
      "quanhuyen":"50905"
   },
   {
      "id":"5090515",
      "phuongxa":"Xã Xuân Phương",
      "quanhuyen":"50905"
   },
   {
      "id":"5090519",
      "phuongxa":"Xã Xuân Thọ 2",
      "quanhuyen":"50905"
   },
   {
      "id":"5090700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50907"
   },
   {
      "id":"5090701",
      "phuongxa":"Thị trấn Chí Thạnh",
      "quanhuyen":"50907"
   },
   {
      "id":"5090703",
      "phuongxa":"Xã An Thạch",
      "quanhuyen":"50907"
   },
   {
      "id":"5090705",
      "phuongxa":"Xã An Dân",
      "quanhuyen":"50907"
   },
   {
      "id":"5090707",
      "phuongxa":"Xã An Ninh Tây",
      "quanhuyen":"50907"
   },
   {
      "id":"5090709",
      "phuongxa":"Xã An Ninh Đông",
      "quanhuyen":"50907"
   },
   {
      "id":"5090711",
      "phuongxa":"Xã An Định",
      "quanhuyen":"50907"
   },
   {
      "id":"5090713",
      "phuongxa":"Xã An Nghiệp",
      "quanhuyen":"50907"
   },
   {
      "id":"5090715",
      "phuongxa":"Xã An Xuân",
      "quanhuyen":"50907"
   },
   {
      "id":"5090717",
      "phuongxa":"Xã An Lĩnh",
      "quanhuyen":"50907"
   },
   {
      "id":"5090719",
      "phuongxa":"Xã An Thọ",
      "quanhuyen":"50907"
   },
   {
      "id":"5090721",
      "phuongxa":"Xã An Cư",
      "quanhuyen":"50907"
   },
   {
      "id":"5090723",
      "phuongxa":"Xã An Hải",
      "quanhuyen":"50907"
   },
   {
      "id":"5090725",
      "phuongxa":"Xã An Hiệp",
      "quanhuyen":"50907"
   },
   {
      "id":"5090727",
      "phuongxa":"Xã An Hòa",
      "quanhuyen":"50907"
   },
   {
      "id":"5090729",
      "phuongxa":"Xã An Mỹ",
      "quanhuyen":"50907"
   },
   {
      "id":"5090731",
      "phuongxa":"Xã An Chấn",
      "quanhuyen":"50907"
   },
   {
      "id":"5090733",
      "phuongxa":"Xã An Phú",
      "quanhuyen":"50911"
   },
   {
      "id":"5090900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50909"
   },
   {
      "id":"5090905",
      "phuongxa":"Xã Cà Lúi",
      "quanhuyen":"50909"
   },
   {
      "id":"5090907",
      "phuongxa":"Xã Krông Pa",
      "quanhuyen":"50909"
   },
   {
      "id":"5090909",
      "phuongxa":"Xã Suối Trai",
      "quanhuyen":"50909"
   },
   {
      "id":"5090911",
      "phuongxa":"Xã Sơn Phước",
      "quanhuyen":"50909"
   },
   {
      "id":"5090913",
      "phuongxa":"Xã Sơn Hội",
      "quanhuyen":"50909"
   },
   {
      "id":"5090915",
      "phuongxa":"Xã Sơn Xuân",
      "quanhuyen":"50909"
   },
   {
      "id":"5090919",
      "phuongxa":"Xã Sơn Long",
      "quanhuyen":"50909"
   },
   {
      "id":"5090921",
      "phuongxa":"Xã Sơn Nguyên",
      "quanhuyen":"50909"
   },
   {
      "id":"5090923",
      "phuongxa":"Xã Sơn Hà",
      "quanhuyen":"50909"
   },
   {
      "id":"5090925",
      "phuongxa":"Xã Eachà Rang",
      "quanhuyen":"50909"
   },
   {
      "id":"5091100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50911"
   },
   {
      "id":"5091103",
      "phuongxa":"Xã Sơn Thành",
      "quanhuyen":"50911"
   },
   {
      "id":"5091105",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"50911"
   },
   {
      "id":"5091107",
      "phuongxa":"Xã Hòa Phong",
      "quanhuyen":"50911"
   },
   {
      "id":"5091109",
      "phuongxa":"Xã Hòa Mỹ Tây",
      "quanhuyen":"50911"
   },
   {
      "id":"5091111",
      "phuongxa":"Xã Hòa Mỹ Đông",
      "quanhuyen":"50911"
   },
   {
      "id":"5091115",
      "phuongxa":"Xã Hòa Đồng",
      "quanhuyen":"50911"
   },
   {
      "id":"5091117",
      "phuongxa":"Xã Hòa Tân Tây",
      "quanhuyen":"50911"
   },
   {
      "id":"5091119",
      "phuongxa":"Xã Hòa Bình 2",
      "quanhuyen":"50911"
   },
   {
      "id":"5091121",
      "phuongxa":"Xã Hòa Bình 1",
      "quanhuyen":"50911"
   },
   {
      "id":"5091123",
      "phuongxa":"Xã Hòa Tân Đông",
      "quanhuyen":"50911"
   },
   {
      "id":"5091125",
      "phuongxa":"Xã Hòa Thành",
      "quanhuyen":"50911"
   },
   {
      "id":"5091127",
      "phuongxa":"Xã Hòa Hiệp Bắc",
      "quanhuyen":"50911"
   },
   {
      "id":"5091129",
      "phuongxa":"Xã Hòa Hiệp Trung",
      "quanhuyen":"50911"
   },
   {
      "id":"5091131",
      "phuongxa":"Xã Hòa Hiệp Nam",
      "quanhuyen":"50911"
   },
   {
      "id":"5091133",
      "phuongxa":"Xã Hòa Vinh",
      "quanhuyen":"50911"
   },
   {
      "id":"5091135",
      "phuongxa":"Xã Hòa Xuân Đông",
      "quanhuyen":"50911"
   },
   {
      "id":"5091139",
      "phuongxa":"Xã Hòa Xuân Nam",
      "quanhuyen":"50911"
   },
   {
      "id":"5091141",
      "phuongxa":"Xã Hòa Tâm",
      "quanhuyen":"50911"
   },
   {
      "id":"5091300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50913"
   },
   {
      "id":"5091301",
      "phuongxa":"Thị trấn Hai Riêng",
      "quanhuyen":"50913"
   },
   {
      "id":"5091303",
      "phuongxa":"Xã Ea Bá",
      "quanhuyen":"50913"
   },
   {
      "id":"5091305",
      "phuongxa":"Xã Ea Lâm",
      "quanhuyen":"50913"
   },
   {
      "id":"5091307",
      "phuongxa":"Xã EaBar",
      "quanhuyen":"50913"
   },
   {
      "id":"5091309",
      "phuongxa":"Xã EaBia",
      "quanhuyen":"50913"
   },
   {
      "id":"5091311",
      "phuongxa":"Xã EaTrol",
      "quanhuyen":"50913"
   },
   {
      "id":"5091313",
      "phuongxa":"Xã Sông Hinh",
      "quanhuyen":"50913"
   },
   {
      "id":"5091315",
      "phuongxa":"Xã Đức Bình Tây",
      "quanhuyen":"50913"
   },
   {
      "id":"5091317",
      "phuongxa":"Xã Đức Bình Đông",
      "quanhuyen":"50913"
   },
   {
      "id":"5091319",
      "phuongxa":"Xã Sơn Giang",
      "quanhuyen":"50913"
   },
   {
      "id":"5091500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50915"
   },
   {
      "id":"5091501",
      "phuongxa":"Xã Hòa Quang Bắc",
      "quanhuyen":"50915"
   },
   {
      "id":"5091503",
      "phuongxa":"Xã Hoà Quang Nam",
      "quanhuyen":"50915"
   },
   {
      "id":"5091505",
      "phuongxa":"Xã Hòa Hội",
      "quanhuyen":"50915"
   },
   {
      "id":"5091507",
      "phuongxa":"Xã Hòa Trị",
      "quanhuyen":"50915"
   },
   {
      "id":"5091509",
      "phuongxa":"Xã Hòa An",
      "quanhuyen":"50915"
   },
   {
      "id":"5091511",
      "phuongxa":"Xã Hòa Định Đông",
      "quanhuyen":"50915"
   },
   {
      "id":"5091513",
      "phuongxa":"Xã Hòa Định Tây",
      "quanhuyen":"50915"
   },
   {
      "id":"5091515",
      "phuongxa":"Xã Hòa Thắng",
      "quanhuyen":"50915"
   },
   {
      "id":"5091700",
      "phuongxa":"Không xác định",
      "quanhuyen":"50917"
   },
   {
      "id":"5091701",
      "phuongxa":"Xã Sơn Thành Tây",
      "quanhuyen":"50917"
   },
   {
      "id":"5091703",
      "phuongxa":"Xã Sơn Thành Đông",
      "quanhuyen":"50917"
   },
   {
      "id":"5091705",
      "phuongxa":"Xã Hoà Bình 1",
      "quanhuyen":"50917"
   },
   {
      "id":"5091707",
      "phuongxa":"Xã Hoà Bình 2",
      "quanhuyen":"50917"
   },
   {
      "id":"5091709",
      "phuongxa":"Xã Hoà Phong",
      "quanhuyen":"50917"
   },
   {
      "id":"5091711",
      "phuongxa":"Xã Hoà Phú",
      "quanhuyen":"50917"
   },
   {
      "id":"5091713",
      "phuongxa":"Xã Hoà Tân Tây",
      "quanhuyen":"50917"
   },
   {
      "id":"5091715",
      "phuongxa":"Xã Hoà Đồng",
      "quanhuyen":"50917"
   },
   {
      "id":"5091717",
      "phuongxa":"Xã hoà Mỹ Đông",
      "quanhuyen":"50917"
   },
   {
      "id":"5091719",
      "phuongxa":"Xã Hoà Mỹ Tây",
      "quanhuyen":"50917"
   },
   {
      "id":"5091721",
      "phuongxa":"Xã Hoà Thịnh",
      "quanhuyen":"50917"
   },
   {
      "id":"5091900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50919"
   },
   {
      "id":"5091901",
      "phuongxa":"Xã Hoà Thành",
      "quanhuyen":"50919"
   },
   {
      "id":"5091903",
      "phuongxa":"Xã Hoà Hiệp Bắc",
      "quanhuyen":"50919"
   },
   {
      "id":"5091905",
      "phuongxa":"Xã Hoà Vinh",
      "quanhuyen":"50919"
   },
   {
      "id":"5091907",
      "phuongxa":"Xã Hoà Hiệp Trung",
      "quanhuyen":"50919"
   },
   {
      "id":"5091909",
      "phuongxa":"Xã Hoà Tân Đông",
      "quanhuyen":"50919"
   },
   {
      "id":"5091913",
      "phuongxa":"Xã Hoà Hiệp Nam",
      "quanhuyen":"50919"
   },
   {
      "id":"5091915",
      "phuongxa":"Xã Hoà Xuân Đông",
      "quanhuyen":"50919"
   },
   {
      "id":"5091917",
      "phuongxa":"Xã Hoà Tâm",
      "quanhuyen":"50919"
   },
   {
      "id":"5091919",
      "phuongxa":"Xã Hoà Xuân Nam",
      "quanhuyen":"50919"
   },
   {
      "id":"5110000",
      "phuongxa":"Không xác định",
      "quanhuyen":"51100"
   },
   {
      "id":"5110103",
      "phuongxa":"Phường Vĩnh Phước",
      "quanhuyen":"51101"
   },
   {
      "id":"5110105",
      "phuongxa":"Phường Vĩnh Thọ",
      "quanhuyen":"51101"
   },
   {
      "id":"5110107",
      "phuongxa":"Phường Ngọc Hiệp",
      "quanhuyen":"51101"
   },
   {
      "id":"5110109",
      "phuongxa":"Phường Vạn Thắng",
      "quanhuyen":"51101"
   },
   {
      "id":"5110113",
      "phuongxa":"Phường Xương Huân",
      "quanhuyen":"51101"
   },
   {
      "id":"5110115",
      "phuongxa":"Phường Vạn Thạnh",
      "quanhuyen":"51101"
   },
   {
      "id":"5110117",
      "phuongxa":"Phường Phương Sài",
      "quanhuyen":"51101"
   },
   {
      "id":"5110119",
      "phuongxa":"Phường Phước Tân",
      "quanhuyen":"51101"
   },
   {
      "id":"5110121",
      "phuongxa":"Phường Phước Tiến",
      "quanhuyen":"51101"
   },
   {
      "id":"5110125",
      "phuongxa":"Phường Lộc Thọ",
      "quanhuyen":"51101"
   },
   {
      "id":"5110127",
      "phuongxa":"Phường Phước Hòa",
      "quanhuyen":"51101"
   },
   {
      "id":"5110129",
      "phuongxa":"Phường Tân Lập",
      "quanhuyen":"51101"
   },
   {
      "id":"5110131",
      "phuongxa":"Phường Vĩnh Nguyên",
      "quanhuyen":"51101"
   },
   {
      "id":"5110133",
      "phuongxa":"Phường Vĩnh Trường",
      "quanhuyen":"51101"
   },
   {
      "id":"5110137",
      "phuongxa":"Xã Vĩnh Phương",
      "quanhuyen":"51101"
   },
   {
      "id":"5110139",
      "phuongxa":"Xã Vĩnh Ngọc",
      "quanhuyen":"51101"
   },
   {
      "id":"5110141",
      "phuongxa":"Xã Vĩnh Thạnh",
      "quanhuyen":"51101"
   },
   {
      "id":"5110143",
      "phuongxa":"Xã Vĩnh Hiệp",
      "quanhuyen":"51101"
   },
   {
      "id":"5110145",
      "phuongxa":"Xã Vĩnh Trung",
      "quanhuyen":"51101"
   },
   {
      "id":"5110147",
      "phuongxa":"Xã Vĩnh Thái",
      "quanhuyen":"51101"
   },
   {
      "id":"5110149",
      "phuongxa":"Xã Phước Đồng",
      "quanhuyen":"51101"
   },
   {
      "id":"5110151",
      "phuongxa":"Phường Phước Long",
      "quanhuyen":"51101"
   },
   {
      "id":"5110300",
      "phuongxa":"Không xác định",
      "quanhuyen":"51103"
   },
   {
      "id":"5110301",
      "phuongxa":"Thị trấn Vạn Giã",
      "quanhuyen":"51103"
   },
   {
      "id":"5110303",
      "phuongxa":"Xã Đại Lãnh",
      "quanhuyen":"51103"
   },
   {
      "id":"5110305",
      "phuongxa":"Xã Vạn Long",
      "quanhuyen":"51103"
   },
   {
      "id":"5110307",
      "phuongxa":"Xã Vạn Phước",
      "quanhuyen":"51103"
   },
   {
      "id":"5110309",
      "phuongxa":"Xã Vạn Thọ",
      "quanhuyen":"51103"
   },
   {
      "id":"5110311",
      "phuongxa":"Xã Vạn Bình",
      "quanhuyen":"51103"
   },
   {
      "id":"5110313",
      "phuongxa":"Xã Vạn Khánh",
      "quanhuyen":"51103"
   },
   {
      "id":"5110315",
      "phuongxa":"Xã Vạn Phú",
      "quanhuyen":"51103"
   },
   {
      "id":"5110317",
      "phuongxa":"Xã Vạn Thắng",
      "quanhuyen":"51103"
   },
   {
      "id":"5110319",
      "phuongxa":"Xã Vạn Thạnh",
      "quanhuyen":"51103"
   },
   {
      "id":"5110321",
      "phuongxa":"Xã Vạn Lương",
      "quanhuyen":"51103"
   },
   {
      "id":"5110325",
      "phuongxa":"Xã Vạn Hưng",
      "quanhuyen":"51103"
   },
   {
      "id":"5110500",
      "phuongxa":"Không xác định",
      "quanhuyen":"51105"
   },
   {
      "id":"5110501",
      "phuongxa":"Thị trấn Ninh Hòa",
      "quanhuyen":"51105"
   },
   {
      "id":"5110503",
      "phuongxa":"Xã Ninh An",
      "quanhuyen":"51105"
   },
   {
      "id":"5110505",
      "phuongxa":"Xã Ninh Tây",
      "quanhuyen":"51105"
   },
   {
      "id":"5110507",
      "phuongxa":"Xã Ninh Thượng",
      "quanhuyen":"51105"
   },
   {
      "id":"5110509",
      "phuongxa":"Xã Ninh Thọ",
      "quanhuyen":"51105"
   },
   {
      "id":"5110511",
      "phuongxa":"Phường Ninh Hải",
      "quanhuyen":"51105"
   },
   {
      "id":"5110513",
      "phuongxa":"Xã Ninh Sim",
      "quanhuyen":"51105"
   },
   {
      "id":"5110515",
      "phuongxa":"Xã Ninh Xuân",
      "quanhuyen":"51105"
   },
   {
      "id":"5110517",
      "phuongxa":"Xã Ninh Trung",
      "quanhuyen":"51105"
   },
   {
      "id":"5110519",
      "phuongxa":"Xã Ninh Thân",
      "quanhuyen":"51105"
   },
   {
      "id":"5110521",
      "phuongxa":"Xã Ninh Đông",
      "quanhuyen":"51105"
   },
   {
      "id":"5110523",
      "phuongxa":"Phường Ninh Diêm",
      "quanhuyen":"51105"
   },
   {
      "id":"5110525",
      "phuongxa":"Phường Ninh Thủy",
      "quanhuyen":"51105"
   },
   {
      "id":"5110527",
      "phuongxa":"Xã Ninh Phụng",
      "quanhuyen":"51105"
   },
   {
      "id":"5110529",
      "phuongxa":"Phường Ninh Đa",
      "quanhuyen":"51105"
   },
   {
      "id":"5110531",
      "phuongxa":"Xã Ninh Bình",
      "quanhuyen":"51105"
   },
   {
      "id":"5110533",
      "phuongxa":"Phường Ninh Giang",
      "quanhuyen":"51105"
   },
   {
      "id":"5110537",
      "phuongxa":"Xã Ninh Quang",
      "quanhuyen":"51105"
   },
   {
      "id":"5110539",
      "phuongxa":"Phường Ninh Hà",
      "quanhuyen":"51105"
   },
   {
      "id":"5110541",
      "phuongxa":"Xã Ninh Phước",
      "quanhuyen":"51105"
   },
   {
      "id":"5110543",
      "phuongxa":"Xã Ninh Tân",
      "quanhuyen":"51105"
   },
   {
      "id":"5110547",
      "phuongxa":"Xã Ninh Lộc",
      "quanhuyen":"51105"
   },
   {
      "id":"5110549",
      "phuongxa":"Xã Ninh Vân",
      "quanhuyen":"51105"
   },
   {
      "id":"5110551",
      "phuongxa":"Xã Ninh ích",
      "quanhuyen":"51105"
   },
   {
      "id":"5110553",
      "phuongxa":"Xã Ninh Hòa",
      "quanhuyen":"51105"
   },
   {
      "id":"5110700",
      "phuongxa":"Không xác định",
      "quanhuyen":"51107"
   },
   {
      "id":"5110703",
      "phuongxa":"Xã Diên Lâm",
      "quanhuyen":"51107"
   },
   {
      "id":"5110705",
      "phuongxa":"Xã Diên Điền",
      "quanhuyen":"51107"
   },
   {
      "id":"5110707",
      "phuongxa":"Xã Diên Xuân",
      "quanhuyen":"51107"
   },
   {
      "id":"5110709",
      "phuongxa":"Xã Diên Sơn",
      "quanhuyen":"51107"
   },
   {
      "id":"5110711",
      "phuongxa":"Xã Diên Đồng",
      "quanhuyen":"51107"
   },
   {
      "id":"5110713",
      "phuongxa":"Xã Diên Phú",
      "quanhuyen":"51107"
   },
   {
      "id":"5110715",
      "phuongxa":"Xã Diên Thọ",
      "quanhuyen":"51107"
   },
   {
      "id":"5110719",
      "phuongxa":"Xã Diên Lạc",
      "quanhuyen":"51107"
   },
   {
      "id":"5110721",
      "phuongxa":"Xã Diên Thạnh",
      "quanhuyen":"51107"
   },
   {
      "id":"5110723",
      "phuongxa":"Xã Diên Toàn",
      "quanhuyen":"51107"
   },
   {
      "id":"5110727",
      "phuongxa":"Xã Diên Tân",
      "quanhuyen":"51107"
   },
   {
      "id":"5110729",
      "phuongxa":"Xã Diên Hòa",
      "quanhuyen":"51107"
   },
   {
      "id":"5110733",
      "phuongxa":"Xã Diên Bình",
      "quanhuyen":"51107"
   },
   {
      "id":"5110735",
      "phuongxa":"Xã Suối Hiệp",
      "quanhuyen":"51107"
   },
   {
      "id":"5110737",
      "phuongxa":"Xã Suối Tiên",
      "quanhuyen":"51107"
   },
   {
      "id":"5110739",
      "phuongxa":"Xã Suối Cát",
      "quanhuyen":"51107"
   },
   {
      "id":"5110741",
      "phuongxa":"Xã Suối Tân",
      "quanhuyen":"51107"
   },
   {
      "id":"5110900",
      "phuongxa":"Phường Cam Lộc",
      "quanhuyen":"51109"
   },
   {
      "id":"5110901",
      "phuongxa":"Phường Ba Ngòi",
      "quanhuyen":"51109"
   },
   {
      "id":"5110903",
      "phuongxa":"Xã Cam Tân",
      "quanhuyen":"51109"
   },
   {
      "id":"5110905",
      "phuongxa":"Xã Cam Hòa",
      "quanhuyen":"51109"
   },
   {
      "id":"5110907",
      "phuongxa":"Xã Cam Hải Đông",
      "quanhuyen":"51109"
   },
   {
      "id":"5110909",
      "phuongxa":"Xã Sơn Tân",
      "quanhuyen":"51109"
   },
   {
      "id":"5110911",
      "phuongxa":"Xã Cam Hiệp Bắc",
      "quanhuyen":"51109"
   },
   {
      "id":"5110913",
      "phuongxa":"Xã Cam Hải Tây",
      "quanhuyen":"51109"
   },
   {
      "id":"5110915",
      "phuongxa":"Xã Cam Đức",
      "quanhuyen":"51109"
   },
   {
      "id":"5110919",
      "phuongxa":"Xã Cam Thành Bắc",
      "quanhuyen":"51109"
   },
   {
      "id":"5110921",
      "phuongxa":"Xã Cam Phước Tây",
      "quanhuyen":"51109"
   },
   {
      "id":"5110923",
      "phuongxa":"Xã Cam An Bắc",
      "quanhuyen":"51109"
   },
   {
      "id":"5110925",
      "phuongxa":"Xã Cam An Nam",
      "quanhuyen":"51109"
   },
   {
      "id":"5110927",
      "phuongxa":"Xã Cam Thành Nam",
      "quanhuyen":"51109"
   },
   {
      "id":"5110929",
      "phuongxa":"Phường Cam Nghĩa",
      "quanhuyen":"51109"
   },
   {
      "id":"5110931",
      "phuongxa":"Phường Cam Phúc Bắc",
      "quanhuyen":"51109"
   },
   {
      "id":"5110933",
      "phuongxa":"Xã Cam Phước Đông",
      "quanhuyen":"51109"
   },
   {
      "id":"5110935",
      "phuongxa":"Phước Cam Phúc Nam",
      "quanhuyen":"51109"
   },
   {
      "id":"5110937",
      "phuongxa":"Xã Cam Thịnh Tây",
      "quanhuyen":"51109"
   },
   {
      "id":"5110939",
      "phuongxa":"Xã Cam Thịnh Đông",
      "quanhuyen":"51109"
   },
   {
      "id":"5110941",
      "phuongxa":"Xã Cam Lập",
      "quanhuyen":"51109"
   },
   {
      "id":"5110943",
      "phuongxa":"Xã Cam Bình",
      "quanhuyen":"51109"
   },
   {
      "id":"5111100",
      "phuongxa":"Không xác định",
      "quanhuyen":"51111"
   },
   {
      "id":"5111101",
      "phuongxa":"Thị trấn Khánh Vĩnh",
      "quanhuyen":"51111"
   },
   {
      "id":"5111103",
      "phuongxa":"Xã Khánh Hiệp",
      "quanhuyen":"51111"
   },
   {
      "id":"5111105",
      "phuongxa":"Xã Khánh Bình",
      "quanhuyen":"51111"
   },
   {
      "id":"5111107",
      "phuongxa":"Xã Khánh Trung",
      "quanhuyen":"51111"
   },
   {
      "id":"5111109",
      "phuongxa":"Xã Khánh Đông",
      "quanhuyen":"51111"
   },
   {
      "id":"5111111",
      "phuongxa":"Xã Khánh Thượng",
      "quanhuyen":"51111"
   },
   {
      "id":"5111113",
      "phuongxa":"Xã Khánh Nam",
      "quanhuyen":"51111"
   },
   {
      "id":"5111115",
      "phuongxa":"Xã Giang Ly",
      "quanhuyen":"51111"
   },
   {
      "id":"5111119",
      "phuongxa":"Xã Khánh Thành",
      "quanhuyen":"51111"
   },
   {
      "id":"5111121",
      "phuongxa":"Xã Khánh Phú",
      "quanhuyen":"51111"
   },
   {
      "id":"5111123",
      "phuongxa":"Xã Sơn Thái",
      "quanhuyen":"51111"
   },
   {
      "id":"5111125",
      "phuongxa":"Xã Liên Sang",
      "quanhuyen":"51111"
   },
   {
      "id":"5111127",
      "phuongxa":"Xã Sông Cầu",
      "quanhuyen":"51111"
   },
   {
      "id":"5111300",
      "phuongxa":"Không xác định",
      "quanhuyen":"51113"
   },
   {
      "id":"5111301",
      "phuongxa":"Thị trấn Tô Hạp",
      "quanhuyen":"51113"
   },
   {
      "id":"5111303",
      "phuongxa":"Xã Thành Sơn",
      "quanhuyen":"51113"
   },
   {
      "id":"5111305",
      "phuongxa":"Xã Sơn Lâm",
      "quanhuyen":"51113"
   },
   {
      "id":"5111307",
      "phuongxa":"Xã Sơn Bình",
      "quanhuyen":"51113"
   },
   {
      "id":"6031711",
      "phuongxa":"Xã Ia Tôr",
      "quanhuyen":"60317"
   },
   {
      "id":"6031713",
      "phuongxa":"Xã Ia Boòng",
      "quanhuyen":"60317"
   },
   {
      "id":"6031715",
      "phuongxa":"Xã Ia O",
      "quanhuyen":"60317"
   },
   {
      "id":"6031717",
      "phuongxa":"Xã Ia Púch",
      "quanhuyen":"60317"
   },
   {
      "id":"6031719",
      "phuongxa":"Xã Ia Me",
      "quanhuyen":"60317"
   },
   {
      "id":"6031721",
      "phuongxa":"Xã Ia Vê",
      "quanhuyen":"60317"
   },
   {
      "id":"6031723",
      "phuongxa":"Xã Ia Pia",
      "quanhuyen":"60317"
   },
   {
      "id":"6031727",
      "phuongxa":"Xã Ia Mơ",
      "quanhuyen":"60317"
   },
   {
      "id":"6031729",
      "phuongxa":"Xã Bàu Cạn",
      "quanhuyen":"60317"
   },
   {
      "id":"6031901",
      "phuongxa":"Thị trấn Chư Sê",
      "quanhuyen":"60319"
   },
   {
      "id":"6031903",
      "phuongxa":"Xã Ia Tiêm",
      "quanhuyen":"60319"
   },
   {
      "id":"6031905",
      "phuongxa":"Xã Bờ  Ngoong",
      "quanhuyen":"60319"
   },
   {
      "id":"6031907",
      "phuongxa":"Xã Ia Glai",
      "quanhuyen":"60319"
   },
   {
      "id":"6031909",
      "phuongxa":"Xã A Lbá",
      "quanhuyen":"60319"
   },
   {
      "id":"6031911",
      "phuongxa":"Xã AYun",
      "quanhuyen":"60319"
   },
   {
      "id":"6031915",
      "phuongxa":"Xã Ia Blang",
      "quanhuyen":"60319"
   },
   {
      "id":"6031917",
      "phuongxa":"Xã Dun",
      "quanhuyen":"60319"
   },
   {
      "id":"6031919",
      "phuongxa":"Xã H Bông",
      "quanhuyen":"60319"
   },
   {
      "id":"6031921",
      "phuongxa":"Xã Ia Hrú",
      "quanhuyen":"60319"
   },
   {
      "id":"6031923",
      "phuongxa":"Xã Ia Ko",
      "quanhuyen":"60319"
   },
   {
      "id":"6031925",
      "phuongxa":"Xã Nhơn Hòa",
      "quanhuyen":"60319"
   },
   {
      "id":"6032100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60321"
   },
   {
      "id":"6032101",
      "phuongxa":"Thị trấn Ayun Pa",
      "quanhuyen":"60321"
   },
   {
      "id":"6032103",
      "phuongxa":"Xã Pờ Tó",
      "quanhuyen":"60321"
   },
   {
      "id":"6032105",
      "phuongxa":"Xã Chư A Thai",
      "quanhuyen":"60321"
   },
   {
      "id":"6032111",
      "phuongxa":"Xã Chư Mố",
      "quanhuyen":"60321"
   },
   {
      "id":"6032113",
      "phuongxa":"Xã Ia Tul",
      "quanhuyen":"60321"
   },
   {
      "id":"6032115",
      "phuongxa":"Xã Ia Piar",
      "quanhuyen":"60321"
   },
   {
      "id":"6032119",
      "phuongxa":"Xã Ia Trok",
      "quanhuyen":"60321"
   },
   {
      "id":"6032121",
      "phuongxa":"Xã Ia Hiao",
      "quanhuyen":"60321"
   },
   {
      "id":"6032123",
      "phuongxa":"Xã Ia Rbol",
      "quanhuyen":"60321"
   },
   {
      "id":"6032125",
      "phuongxa":"Xã Ia Yeng",
      "quanhuyen":"60321"
   },
   {
      "id":"6032127",
      "phuongxa":"Xã Ia Rtô",
      "quanhuyen":"60321"
   },
   {
      "id":"6032129",
      "phuongxa":"Xã Ia Sao",
      "quanhuyen":"60321"
   },
   {
      "id":"6032131",
      "phuongxa":"Xã Ia Broãi",
      "quanhuyen":"60321"
   },
   {
      "id":"6032133",
      "phuongxa":"Xã Ia Kdăm",
      "quanhuyen":"60321"
   },
   {
      "id":"6032135",
      "phuongxa":"Xã Kim Tân",
      "quanhuyen":"60321"
   },
   {
      "id":"6032137",
      "phuongxa":"Thị trấn Phú Thiện",
      "quanhuyen":"60321"
   },
   {
      "id":"6032300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60323"
   },
   {
      "id":"6032301",
      "phuongxa":"Thị trấn Phú Túc",
      "quanhuyen":"60323"
   },
   {
      "id":"6032303",
      "phuongxa":"Xã Ia RSai",
      "quanhuyen":"60323"
   },
   {
      "id":"6032305",
      "phuongxa":"Xã Ia RSươm",
      "quanhuyen":"60323"
   },
   {
      "id":"6032307",
      "phuongxa":"Xã Chư Gu",
      "quanhuyen":"60323"
   },
   {
      "id":"6032309",
      "phuongxa":"Xã Đất Bằng",
      "quanhuyen":"60323"
   },
   {
      "id":"6032311",
      "phuongxa":"Xã Ia Mláh",
      "quanhuyen":"60323"
   },
   {
      "id":"6032313",
      "phuongxa":"Xã Chư Drăng",
      "quanhuyen":"60323"
   },
   {
      "id":"6032315",
      "phuongxa":"Xã Phú Cần",
      "quanhuyen":"60323"
   },
   {
      "id":"6032319",
      "phuongxa":"Xã Ia Rmok",
      "quanhuyen":"60323"
   },
   {
      "id":"6032321",
      "phuongxa":"Xã Chư Ngọc",
      "quanhuyen":"60323"
   },
   {
      "id":"6032323",
      "phuongxa":"Xã Uar",
      "quanhuyen":"60323"
   },
   {
      "id":"6032325",
      "phuongxa":"Xã Chư RCăm",
      "quanhuyen":"60323"
   },
   {
      "id":"6032327",
      "phuongxa":"Xã Krông Năng",
      "quanhuyen":"60323"
   },
   {
      "id":"6032500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60325"
   },
   {
      "id":"6032501",
      "phuongxa":"Thị trấn Đăk Đoa",
      "quanhuyen":"60325"
   },
   {
      "id":"6032503",
      "phuongxa":"Xã Hà Đông",
      "quanhuyen":"60325"
   },
   {
      "id":"6032505",
      "phuongxa":"Xã Đăk Sơmei",
      "quanhuyen":"60325"
   },
   {
      "id":"6032507",
      "phuongxa":"Xã Hải Yang",
      "quanhuyen":"60325"
   },
   {
      "id":"6032509",
      "phuongxa":"Xã Kon Gang",
      "quanhuyen":"60325"
   },
   {
      "id":"6032511",
      "phuongxa":"Xã Hà Bầu",
      "quanhuyen":"60325"
   },
   {
      "id":"6032513",
      "phuongxa":"Xã Nam Yang",
      "quanhuyen":"60325"
   },
   {
      "id":"6032515",
      "phuongxa":"Xã K'Dang",
      "quanhuyen":"60325"
   },
   {
      "id":"6032517",
      "phuongxa":"Xã H' Neng",
      "quanhuyen":"60325"
   },
   {
      "id":"6032519",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"60325"
   },
   {
      "id":"6032521",
      "phuongxa":"Xã Glar",
      "quanhuyen":"60325"
   },
   {
      "id":"6032525",
      "phuongxa":"Xã Trang",
      "quanhuyen":"60325"
   },
   {
      "id":"6032527",
      "phuongxa":"Xã Ia Pết",
      "quanhuyen":"60325"
   },
   {
      "id":"6032529",
      "phuongxa":"Xã Ia Băng",
      "quanhuyen":"60325"
   },
   {
      "id":"6032531",
      "phuongxa":"Xã Đak Krong",
      "quanhuyen":"60325"
   },
   {
      "id":"6032533",
      "phuongxa":"Xã HNol",
      "quanhuyen":"60325"
   },
   {
      "id":"6032700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60327"
   },
   {
      "id":"6032701",
      "phuongxa":"Xã Hà Tam",
      "quanhuyen":"60327"
   },
   {
      "id":"6032703",
      "phuongxa":"Xã An Thành",
      "quanhuyen":"60327"
   },
   {
      "id":"7010313",
      "phuongxa":"Phường An Lợi Đông",
      "quanhuyen":"769"
   },
   {
      "id":"7010315",
      "phuongxa":"Phường Bình Trưng Tây",
      "quanhuyen":"769"
   },
   {
      "id":"7010317",
      "phuongxa":"Phường Bình Trưng Đông",
      "quanhuyen":"769"
   },
   {
      "id":"7010319",
      "phuongxa":"Phường Cát Lái",
      "quanhuyen":"769"
   },
   {
      "id":"7010321",
      "phuongxa":"Phường Thạnh Mỹ Lợi",
      "quanhuyen":"769"
   },
   {
      "id":"7010500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70105"
   },
   {
      "id":"7010501",
      "phuongxa":"Phường 1",
      "quanhuyen":"70105"
   },
   {
      "id":"7010503",
      "phuongxa":"Phường 2",
      "quanhuyen":"70105"
   },
   {
      "id":"7010505",
      "phuongxa":"Phường 3",
      "quanhuyen":"70105"
   },
   {
      "id":"7010509",
      "phuongxa":"Phường 5",
      "quanhuyen":"70105"
   },
   {
      "id":"7010511",
      "phuongxa":"Phường 6",
      "quanhuyen":"70105"
   },
   {
      "id":"7010515",
      "phuongxa":"Phường 8",
      "quanhuyen":"70105"
   },
   {
      "id":"7010517",
      "phuongxa":"Phường 9",
      "quanhuyen":"70105"
   },
   {
      "id":"7010519",
      "phuongxa":"Phường 10",
      "quanhuyen":"70105"
   },
   {
      "id":"7010521",
      "phuongxa":"Phường 11",
      "quanhuyen":"70105"
   },
   {
      "id":"7010523",
      "phuongxa":"Phường 12",
      "quanhuyen":"70105"
   },
   {
      "id":"7010525",
      "phuongxa":"Phường 13",
      "quanhuyen":"70105"
   },
   {
      "id":"7010700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70107"
   },
   {
      "id":"7010701",
      "phuongxa":"Phường 1",
      "quanhuyen":"70107"
   },
   {
      "id":"7010703",
      "phuongxa":"Phường 2",
      "quanhuyen":"70107"
   },
   {
      "id":"7010705",
      "phuongxa":"Phường 3",
      "quanhuyen":"70107"
   },
   {
      "id":"7010707",
      "phuongxa":"Phường 4",
      "quanhuyen":"70107"
   },
   {
      "id":"7010711",
      "phuongxa":"Phường 6",
      "quanhuyen":"70107"
   },
   {
      "id":"7010713",
      "phuongxa":"Phường 8",
      "quanhuyen":"70107"
   },
   {
      "id":"7010715",
      "phuongxa":"Phường 9",
      "quanhuyen":"70107"
   },
   {
      "id":"7010719",
      "phuongxa":"Phường 12",
      "quanhuyen":"70107"
   },
   {
      "id":"7010721",
      "phuongxa":"Phường 13",
      "quanhuyen":"70107"
   },
   {
      "id":"7010723",
      "phuongxa":"Phường 14",
      "quanhuyen":"70107"
   },
   {
      "id":"7010725",
      "phuongxa":"Phường 15",
      "quanhuyen":"70107"
   },
   {
      "id":"7010727",
      "phuongxa":"Phường 16",
      "quanhuyen":"70107"
   },
   {
      "id":"7010729",
      "phuongxa":"Phường 18",
      "quanhuyen":"70107"
   },
   {
      "id":"7010900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70109"
   },
   {
      "id":"7010901",
      "phuongxa":"Phường 1",
      "quanhuyen":"70109"
   },
   {
      "id":"7010903",
      "phuongxa":"Phường 2",
      "quanhuyen":"70109"
   },
   {
      "id":"7010905",
      "phuongxa":"Phường 3",
      "quanhuyen":"70109"
   },
   {
      "id":"7010907",
      "phuongxa":"Phường 4",
      "quanhuyen":"70109"
   },
   {
      "id":"7010909",
      "phuongxa":"Phường 5",
      "quanhuyen":"70109"
   },
   {
      "id":"7010911",
      "phuongxa":"Phường 6",
      "quanhuyen":"70109"
   },
   {
      "id":"7010913",
      "phuongxa":"Phường 7",
      "quanhuyen":"70109"
   },
   {
      "id":"7010915",
      "phuongxa":"Phường 8",
      "quanhuyen":"70109"
   },
   {
      "id":"7010917",
      "phuongxa":"Phường 9",
      "quanhuyen":"70109"
   },
   {
      "id":"7010919",
      "phuongxa":"Phường 10",
      "quanhuyen":"70109"
   },
   {
      "id":"7010921",
      "phuongxa":"Phường 11",
      "quanhuyen":"70109"
   },
   {
      "id":"7010925",
      "phuongxa":"Phường 13",
      "quanhuyen":"70109"
   },
   {
      "id":"7010927",
      "phuongxa":"Phường 14",
      "quanhuyen":"70109"
   },
   {
      "id":"7010929",
      "phuongxa":"Phường 15",
      "quanhuyen":"70109"
   },
   {
      "id":"7011100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70111"
   },
   {
      "id":"7011101",
      "phuongxa":"Phường 1",
      "quanhuyen":"70111"
   },
   {
      "id":"7011103",
      "phuongxa":"Phường 2",
      "quanhuyen":"70111"
   },
   {
      "id":"7011105",
      "phuongxa":"Phường 3",
      "quanhuyen":"70111"
   },
   {
      "id":"7011107",
      "phuongxa":"Phường 4",
      "quanhuyen":"70111"
   },
   {
      "id":"7011109",
      "phuongxa":"Phường 5",
      "quanhuyen":"70111"
   },
   {
      "id":"7011111",
      "phuongxa":"Phường 6",
      "quanhuyen":"70111"
   },
   {
      "id":"7011113",
      "phuongxa":"Phường 7",
      "quanhuyen":"70111"
   },
   {
      "id":"7011115",
      "phuongxa":"Phường 8",
      "quanhuyen":"70111"
   },
   {
      "id":"7011117",
      "phuongxa":"Phường 9",
      "quanhuyen":"70111"
   },
   {
      "id":"7011119",
      "phuongxa":"Phường 10",
      "quanhuyen":"70111"
   },
   {
      "id":"7011121",
      "phuongxa":"Phường 11",
      "quanhuyen":"70111"
   },
   {
      "id":"7011123",
      "phuongxa":"Phường 12",
      "quanhuyen":"70111"
   },
   {
      "id":"7011125",
      "phuongxa":"Phường 13",
      "quanhuyen":"70111"
   },
   {
      "id":"7011127",
      "phuongxa":"Phường 14",
      "quanhuyen":"70111"
   },
   {
      "id":"7011301",
      "phuongxa":"Phường Phú Mỹ",
      "quanhuyen":"70113"
   },
   {
      "id":"7011303",
      "phuongxa":"Phường Phú Thuận",
      "quanhuyen":"70113"
   },
   {
      "id":"7011305",
      "phuongxa":"Phường Tân Phú",
      "quanhuyen":"70113"
   },
   {
      "id":"7011307",
      "phuongxa":"Phường Tân Thuận Đông",
      "quanhuyen":"70113"
   },
   {
      "id":"7011309",
      "phuongxa":"Phường Bình Thuận",
      "quanhuyen":"70113"
   },
   {
      "id":"7011311",
      "phuongxa":"Phường Tân Thuận Tây",
      "quanhuyen":"70113"
   },
   {
      "id":"7011313",
      "phuongxa":"Phường Tân Kiểng",
      "quanhuyen":"70113"
   },
   {
      "id":"7011315",
      "phuongxa":"Phường Tân Quy",
      "quanhuyen":"70113"
   },
   {
      "id":"7011317",
      "phuongxa":"Phường Tân Phong",
      "quanhuyen":"70113"
   },
   {
      "id":"7011319",
      "phuongxa":"Phường Tân Hưng",
      "quanhuyen":"70113"
   },
   {
      "id":"7011500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70115"
   },
   {
      "id":"7011501",
      "phuongxa":"Phường 1",
      "quanhuyen":"70115"
   },
   {
      "id":"7011503",
      "phuongxa":"Phường 2",
      "quanhuyen":"70115"
   },
   {
      "id":"7011505",
      "phuongxa":"Phường 3",
      "quanhuyen":"70115"
   },
   {
      "id":"7011507",
      "phuongxa":"Phường 4",
      "quanhuyen":"70115"
   },
   {
      "id":"7011509",
      "phuongxa":"Phường 5",
      "quanhuyen":"70115"
   },
   {
      "id":"7011511",
      "phuongxa":"Phường 6",
      "quanhuyen":"70115"
   },
   {
      "id":"7011513",
      "phuongxa":"Phường 7",
      "quanhuyen":"70115"
   },
   {
      "id":"7011515",
      "phuongxa":"Phường 8",
      "quanhuyen":"70115"
   },
   {
      "id":"7011517",
      "phuongxa":"Phường 9",
      "quanhuyen":"70115"
   },
   {
      "id":"7011519",
      "phuongxa":"Phường 10",
      "quanhuyen":"70115"
   },
   {
      "id":"7011523",
      "phuongxa":"Phường 12",
      "quanhuyen":"70115"
   },
   {
      "id":"7011525",
      "phuongxa":"Phường 13",
      "quanhuyen":"70115"
   },
   {
      "id":"7011529",
      "phuongxa":"Phường 15",
      "quanhuyen":"70115"
   },
   {
      "id":"7011531",
      "phuongxa":"Phường 16",
      "quanhuyen":"70115"
   },
   {
      "id":"7011700",
      "phuongxa":"Không xác định",
      "quanhuyen":"769"
   },
   {
      "id":"7011701",
      "phuongxa":"Phường Phước Long A",
      "quanhuyen":"769"
   },
   {
      "id":"7011705",
      "phuongxa":"Phường Tăng Nhơn Phú A",
      "quanhuyen":"769"
   },
   {
      "id":"7011707",
      "phuongxa":"Phường Tăng Nhơn Phú B",
      "quanhuyen":"769"
   },
   {
      "id":"7011709",
      "phuongxa":"Phường Long Trường",
      "quanhuyen":"769"
   },
   {
      "id":"7011711",
      "phuongxa":"Phường Trường Thạnh",
      "quanhuyen":"769"
   },
   {
      "id":"7011715",
      "phuongxa":"Phường Tân Phú",
      "quanhuyen":"769"
   },
   {
      "id":"7011717",
      "phuongxa":"Phường Hiệp Phú",
      "quanhuyen":"769"
   },
   {
      "id":"7011719",
      "phuongxa":"Phường Long Thạnh Mỹ",
      "quanhuyen":"769"
   },
   {
      "id":"7011721",
      "phuongxa":"Phường Long Bình",
      "quanhuyen":"769"
   },
   {
      "id":"7011723",
      "phuongxa":"Phường Long Phước",
      "quanhuyen":"769"
   },
   {
      "id":"7011900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70119"
   },
   {
      "id":"7011901",
      "phuongxa":"Phường 1",
      "quanhuyen":"70119"
   },
   {
      "id":"7011903",
      "phuongxa":"Phường 2",
      "quanhuyen":"70119"
   },
   {
      "id":"7011905",
      "phuongxa":"Phường 3",
      "quanhuyen":"70119"
   },
   {
      "id":"7011907",
      "phuongxa":"Phường 4",
      "quanhuyen":"70119"
   },
   {
      "id":"7011909",
      "phuongxa":"Phường 5",
      "quanhuyen":"70119"
   },
   {
      "id":"7011911",
      "phuongxa":"Phường 6",
      "quanhuyen":"70119"
   },
   {
      "id":"7011913",
      "phuongxa":"Phường 7",
      "quanhuyen":"70119"
   },
   {
      "id":"7011915",
      "phuongxa":"Phường 8",
      "quanhuyen":"70119"
   },
   {
      "id":"7011917",
      "phuongxa":"Phường 9",
      "quanhuyen":"70119"
   },
   {
      "id":"7011919",
      "phuongxa":"Phường 10",
      "quanhuyen":"70119"
   },
   {
      "id":"7011921",
      "phuongxa":"Phường 11",
      "quanhuyen":"70119"
   },
   {
      "id":"7011923",
      "phuongxa":"Phường 12",
      "quanhuyen":"70119"
   },
   {
      "id":"7011925",
      "phuongxa":"Phường 13",
      "quanhuyen":"70119"
   },
   {
      "id":"7011927",
      "phuongxa":"Phường 14",
      "quanhuyen":"70119"
   },
   {
      "id":"7011929",
      "phuongxa":"Phường 15",
      "quanhuyen":"70119"
   },
   {
      "id":"7012100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70121"
   },
   {
      "id":"7012101",
      "phuongxa":"Phường 1",
      "quanhuyen":"70121"
   },
   {
      "id":"7012105",
      "phuongxa":"Phường 3",
      "quanhuyen":"70121"
   },
   {
      "id":"7012107",
      "phuongxa":"Phường 4",
      "quanhuyen":"70121"
   },
   {
      "id":"7012109",
      "phuongxa":"Phường 5",
      "quanhuyen":"70121"
   },
   {
      "id":"7012111",
      "phuongxa":"Phường 6",
      "quanhuyen":"70121"
   },
   {
      "id":"7012113",
      "phuongxa":"Phường 7",
      "quanhuyen":"70121"
   },
   {
      "id":"7012115",
      "phuongxa":"Phường 8",
      "quanhuyen":"70121"
   },
   {
      "id":"7012117",
      "phuongxa":"Phường 9",
      "quanhuyen":"70121"
   },
   {
      "id":"7012119",
      "phuongxa":"Phường 10",
      "quanhuyen":"70121"
   },
   {
      "id":"7012121",
      "phuongxa":"Phường 11",
      "quanhuyen":"70121"
   },
   {
      "id":"7012123",
      "phuongxa":"Phường 12",
      "quanhuyen":"70121"
   },
   {
      "id":"7012125",
      "phuongxa":"Phường 13",
      "quanhuyen":"70121"
   },
   {
      "id":"7012127",
      "phuongxa":"Phường 14",
      "quanhuyen":"70121"
   },
   {
      "id":"7012129",
      "phuongxa":"Phường 15",
      "quanhuyen":"70121"
   },
   {
      "id":"7012131",
      "phuongxa":"Phường 16",
      "quanhuyen":"70121"
   },
   {
      "id":"7012300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70123"
   },
   {
      "id":"7012301",
      "phuongxa":"Phường Tân Thới Nhất",
      "quanhuyen":"70123"
   },
   {
      "id":"7012303",
      "phuongxa":"Phường Đông Hưng Thuận",
      "quanhuyen":"70123"
   },
   {
      "id":"7012307",
      "phuongxa":"Phường Trung Mỹ Tây",
      "quanhuyen":"70123"
   },
   {
      "id":"7012309",
      "phuongxa":"Phường Tân Chánh Hiệp",
      "quanhuyen":"70123"
   },
   {
      "id":"7012311",
      "phuongxa":"Phường Thạnh Lộc",
      "quanhuyen":"70123"
   },
   {
      "id":"7012313",
      "phuongxa":"Phường Thạnh Xuân",
      "quanhuyen":"70123"
   },
   {
      "id":"7012315",
      "phuongxa":"Phường Hiệp Thành",
      "quanhuyen":"70123"
   },
   {
      "id":"7012317",
      "phuongxa":"Phường Thới An",
      "quanhuyen":"70123"
   },
   {
      "id":"7012319",
      "phuongxa":"Phường Tân Thới Hiệp",
      "quanhuyen":"70123"
   },
   {
      "id":"7012500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70125"
   },
   {
      "id":"7012501",
      "phuongxa":"Phường 1",
      "quanhuyen":"70125"
   },
   {
      "id":"7012503",
      "phuongxa":"Phường 3",
      "quanhuyen":"70125"
   },
   {
      "id":"7012505",
      "phuongxa":"Phường 4",
      "quanhuyen":"70125"
   },
   {
      "id":"7012507",
      "phuongxa":"Phường 5",
      "quanhuyen":"70125"
   },
   {
      "id":"7012509",
      "phuongxa":"Phường 7",
      "quanhuyen":"70125"
   },
   {
      "id":"6032705",
      "phuongxa":"Thị trấn Đăk Pơ",
      "quanhuyen":"60327"
   },
   {
      "id":"6032707",
      "phuongxa":"Xã Yang Bắc",
      "quanhuyen":"60327"
   },
   {
      "id":"6032709",
      "phuongxa":"Xã Cư An",
      "quanhuyen":"60327"
   },
   {
      "id":"6032711",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"60327"
   },
   {
      "id":"6032713",
      "phuongxa":"Xã Phú An",
      "quanhuyen":"60327"
   },
   {
      "id":"6032715",
      "phuongxa":"Xã Ya Hội",
      "quanhuyen":"60327"
   },
   {
      "id":"6032900",
      "phuongxa":"Không xác định",
      "quanhuyen":"60329"
   },
   {
      "id":"6032901",
      "phuongxa":"Xã Pờ Tó",
      "quanhuyen":"60329"
   },
   {
      "id":"6032903",
      "phuongxa":"Xã Chư Răng",
      "quanhuyen":"60329"
   },
   {
      "id":"6032905",
      "phuongxa":"Xã Ia KDăm",
      "quanhuyen":"60329"
   },
   {
      "id":"6032909",
      "phuongxa":"Xã Chư Mố",
      "quanhuyen":"60329"
   },
   {
      "id":"6032911",
      "phuongxa":"Xã Ia Tul",
      "quanhuyen":"60329"
   },
   {
      "id":"6032915",
      "phuongxa":"Xã Ia Broăi",
      "quanhuyen":"60329"
   },
   {
      "id":"6032917",
      "phuongxa":"Xã Ia Trok",
      "quanhuyen":"60329"
   },
   {
      "id":"6033101",
      "phuongxa":"Thị trấn Phú Thiện",
      "quanhuyen":"60331"
   },
   {
      "id":"6033103",
      "phuongxa":"Xã Chư A Thai",
      "quanhuyen":"60331"
   },
   {
      "id":"6033105",
      "phuongxa":"Xã Ia AKe",
      "quanhuyen":"60331"
   },
   {
      "id":"6033107",
      "phuongxa":"Xã Ia Sol",
      "quanhuyen":"60331"
   },
   {
      "id":"6033109",
      "phuongxa":"Xã Ia Piar",
      "quanhuyen":"60331"
   },
   {
      "id":"6033111",
      "phuongxa":"Xã Ia Peng",
      "quanhuyen":"60331"
   },
   {
      "id":"6033115",
      "phuongxa":"Xã Ia Yeng",
      "quanhuyen":"60331"
   },
   {
      "id":"6033117",
      "phuongxa":"Xã Ayun Hạ",
      "quanhuyen":"60331"
   },
   {
      "id":"6033119",
      "phuongxa":"Xã Chrôh Pơnan",
      "quanhuyen":"60331"
   },
   {
      "id":"6050000",
      "phuongxa":"Không xác định",
      "quanhuyen":"60500"
   },
   {
      "id":"6050100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60501"
   },
   {
      "id":"6050103",
      "phuongxa":"Phường Tân Hoà",
      "quanhuyen":"60501"
   },
   {
      "id":"6050105",
      "phuongxa":"Phường Tân An",
      "quanhuyen":"60501"
   },
   {
      "id":"6050107",
      "phuongxa":"Phường Thống Nhất",
      "quanhuyen":"60501"
   },
   {
      "id":"6050109",
      "phuongxa":"Phường Thành Nhất",
      "quanhuyen":"60501"
   },
   {
      "id":"6050111",
      "phuongxa":"Phường Thắng Lợi",
      "quanhuyen":"60501"
   },
   {
      "id":"6050115",
      "phuongxa":"Phường Thành Công",
      "quanhuyen":"60501"
   },
   {
      "id":"6050117",
      "phuongxa":"Phường Tân Thành",
      "quanhuyen":"60501"
   },
   {
      "id":"6050121",
      "phuongxa":"Phường Tự An",
      "quanhuyen":"60501"
   },
   {
      "id":"6050123",
      "phuongxa":"Phường Ea Tam",
      "quanhuyen":"60501"
   },
   {
      "id":"6050125",
      "phuongxa":"Phường Khánh Xuân",
      "quanhuyen":"60501"
   },
   {
      "id":"6050127",
      "phuongxa":"Xã Hòa Thuận",
      "quanhuyen":"60501"
   },
   {
      "id":"6050129",
      "phuongxa":"Xã Cư ÊBur",
      "quanhuyen":"60501"
   },
   {
      "id":"6050131",
      "phuongxa":"Xã Ea Tu",
      "quanhuyen":"60501"
   },
   {
      "id":"6050133",
      "phuongxa":"Xã Hòa Thắng",
      "quanhuyen":"60501"
   },
   {
      "id":"6050135",
      "phuongxa":"Xã Ea Kao",
      "quanhuyen":"60501"
   },
   {
      "id":"6050151",
      "phuongxa":"Hòa Phú",
      "quanhuyen":"60501"
   },
   {
      "id":"6050300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60503"
   },
   {
      "id":"6050301",
      "phuongxa":"Thị trấn Ea Drăng",
      "quanhuyen":"60503"
   },
   {
      "id":"6050303",
      "phuongxa":"Xã Ea H'leo",
      "quanhuyen":"60503"
   },
   {
      "id":"6050305",
      "phuongxa":"Xã Ea Sol",
      "quanhuyen":"60503"
   },
   {
      "id":"6050307",
      "phuongxa":"Xã Ea Ral",
      "quanhuyen":"60503"
   },
   {
      "id":"6050309",
      "phuongxa":"Xã Ea Wy",
      "quanhuyen":"60503"
   },
   {
      "id":"6050311",
      "phuongxa":"Xã Cư Mốt",
      "quanhuyen":"60503"
   },
   {
      "id":"6050313",
      "phuongxa":"Xã Ea Hiao",
      "quanhuyen":"60503"
   },
   {
      "id":"6050317",
      "phuongxa":"Xã Dlê Yang",
      "quanhuyen":"60503"
   },
   {
      "id":"6050319",
      "phuongxa":"Xã Ea Nam",
      "quanhuyen":"60503"
   },
   {
      "id":"6050500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60505"
   },
   {
      "id":"6050501",
      "phuongxa":"Xã Ea  Rôk",
      "quanhuyen":"60505"
   },
   {
      "id":"6050503",
      "phuongxa":"Xã Ya Tờ Mốt",
      "quanhuyen":"60505"
   },
   {
      "id":"6050505",
      "phuongxa":"Xã Ea Lê",
      "quanhuyen":"60505"
   },
   {
      "id":"6050507",
      "phuongxa":"Xã Ea Bung",
      "quanhuyen":"60505"
   },
   {
      "id":"6050509",
      "phuongxa":"Xã Cư M'Lan",
      "quanhuyen":"60505"
   },
   {
      "id":"6050511",
      "phuongxa":"Xã Cư KBang",
      "quanhuyen":"60505"
   },
   {
      "id":"6050515",
      "phuongxa":"Thị trấn Ea Súp",
      "quanhuyen":"60505"
   },
   {
      "id":"6050700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60507"
   },
   {
      "id":"6050701",
      "phuongxa":"Xã ĐLiê Ya",
      "quanhuyen":"60507"
   },
   {
      "id":"6050703",
      "phuongxa":"Xã Ea Tóh",
      "quanhuyen":"60507"
   },
   {
      "id":"6050705",
      "phuongxa":"Xã Ea Tam",
      "quanhuyen":"60507"
   },
   {
      "id":"6050707",
      "phuongxa":"Xã Phú Lộc",
      "quanhuyen":"60507"
   },
   {
      "id":"6050709",
      "phuongxa":"Xã Tam Giang",
      "quanhuyen":"60507"
   },
   {
      "id":"6050711",
      "phuongxa":"Xã Krông Năng",
      "quanhuyen":"60507"
   },
   {
      "id":"6050713",
      "phuongxa":"Xã Ea Hồ",
      "quanhuyen":"60507"
   },
   {
      "id":"6050715",
      "phuongxa":"Xã Phú Xuân",
      "quanhuyen":"60507"
   },
   {
      "id":"6050900",
      "phuongxa":"Không xác định",
      "quanhuyen":"60509"
   },
   {
      "id":"6050901",
      "phuongxa":"Thị trấn Buôn Hồ",
      "quanhuyen":"60509"
   },
   {
      "id":"6050903",
      "phuongxa":"Xã Cư Né",
      "quanhuyen":"60509"
   },
   {
      "id":"6050905",
      "phuongxa":"Xã Cư Pơng",
      "quanhuyen":"60509"
   },
   {
      "id":"6050907",
      "phuongxa":"Xã Pơng DRang",
      "quanhuyen":"60509"
   },
   {
      "id":"6050909",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"60509"
   },
   {
      "id":"6050911",
      "phuongxa":"Xã Ea BLang",
      "quanhuyen":"60509"
   },
   {
      "id":"6050915",
      "phuongxa":"Xã Thống Nhất",
      "quanhuyen":"60509"
   },
   {
      "id":"6050917",
      "phuongxa":"Xã Ea Siên",
      "quanhuyen":"60509"
   },
   {
      "id":"6050919",
      "phuongxa":"Xã Bình Thuận",
      "quanhuyen":"60509"
   },
   {
      "id":"6050921",
      "phuongxa":"Xã Cư Bao",
      "quanhuyen":"60509"
   },
   {
      "id":"6051100",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"60511"
   },
   {
      "id":"6051101",
      "phuongxa":"Xã Krông Na",
      "quanhuyen":"60511"
   },
   {
      "id":"6051103",
      "phuongxa":"Xã Ea Huar",
      "quanhuyen":"60511"
   },
   {
      "id":"6051105",
      "phuongxa":"Xã Ea Wer",
      "quanhuyen":"60511"
   },
   {
      "id":"6051107",
      "phuongxa":"Xã Cuôr KNia",
      "quanhuyen":"60511"
   },
   {
      "id":"6051109",
      "phuongxa":"Xã Ea Bar",
      "quanhuyen":"60511"
   },
   {
      "id":"6051111",
      "phuongxa":"Xã Ea Nuôl",
      "quanhuyen":"60511"
   },
   {
      "id":"6051300",
      "phuongxa":"Xã Quảng Hiệp",
      "quanhuyen":"60513"
   },
   {
      "id":"6051301",
      "phuongxa":"Thị trấn Ea Pốk",
      "quanhuyen":"60513"
   },
   {
      "id":"6051305",
      "phuongxa":"Xã Quảng Tiến",
      "quanhuyen":"60513"
   },
   {
      "id":"6051307",
      "phuongxa":"Xã Ea Kiết",
      "quanhuyen":"60513"
   },
   {
      "id":"6051309",
      "phuongxa":"Xã Ea Tar",
      "quanhuyen":"60513"
   },
   {
      "id":"6051311",
      "phuongxa":"Xã Cư Dliê M'nông",
      "quanhuyen":"60513"
   },
   {
      "id":"6051313",
      "phuongxa":"Xã Ea H'đinh",
      "quanhuyen":"60513"
   },
   {
      "id":"6051317",
      "phuongxa":"Xã Ea KPam",
      "quanhuyen":"60513"
   },
   {
      "id":"6051319",
      "phuongxa":"Xã Ea M'DRóh",
      "quanhuyen":"60513"
   },
   {
      "id":"6051321",
      "phuongxa":"Xã Cư M'gar",
      "quanhuyen":"60513"
   },
   {
      "id":"6051323",
      "phuongxa":"Xã Ea D'Rơng",
      "quanhuyen":"60513"
   },
   {
      "id":"6051325",
      "phuongxa":"Xã Ea M'nang",
      "quanhuyen":"60513"
   },
   {
      "id":"6051327",
      "phuongxa":"Xã Cư Suê",
      "quanhuyen":"60513"
   },
   {
      "id":"6051329",
      "phuongxa":"Xã Cuor Đăng",
      "quanhuyen":"60513"
   },
   {
      "id":"6051500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60515"
   },
   {
      "id":"6051501",
      "phuongxa":"Thị trấn Ea Kar",
      "quanhuyen":"60515"
   },
   {
      "id":"6051503",
      "phuongxa":"Thị trấn Ea Knốp",
      "quanhuyen":"60515"
   },
   {
      "id":"6051505",
      "phuongxa":"Xã Ea Sô",
      "quanhuyen":"60515"
   },
   {
      "id":"6051507",
      "phuongxa":"Xã Xuân Phú",
      "quanhuyen":"60515"
   },
   {
      "id":"6051509",
      "phuongxa":"Xã Cư Huê",
      "quanhuyen":"60515"
   },
   {
      "id":"6051511",
      "phuongxa":"Xã Ea Tih",
      "quanhuyen":"60515"
   },
   {
      "id":"6051513",
      "phuongxa":"Xã Ea Đar",
      "quanhuyen":"60515"
   },
   {
      "id":"6051515",
      "phuongxa":"Xã Ea Kmút",
      "quanhuyen":"60515"
   },
   {
      "id":"6051517",
      "phuongxa":"Xã Cư Ni",
      "quanhuyen":"60515"
   },
   {
      "id":"6051519",
      "phuongxa":"Xã Ea Păl",
      "quanhuyen":"60515"
   },
   {
      "id":"6051521",
      "phuongxa":"Xã Ea Ô",
      "quanhuyen":"60515"
   },
   {
      "id":"6051523",
      "phuongxa":"Xã Cư Jiang",
      "quanhuyen":"60515"
   },
   {
      "id":"6051700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60517"
   },
   {
      "id":"6051701",
      "phuongxa":"Thị trấn M'Đrắk",
      "quanhuyen":"60517"
   },
   {
      "id":"6051703",
      "phuongxa":"Xã Cư Prao",
      "quanhuyen":"60517"
   },
   {
      "id":"6051705",
      "phuongxa":"Xã Ea Pil",
      "quanhuyen":"60517"
   },
   {
      "id":"6051707",
      "phuongxa":"Xã Ea Lai",
      "quanhuyen":"60517"
   },
   {
      "id":"6051709",
      "phuongxa":"Xã Ea H'MLay",
      "quanhuyen":"60517"
   },
   {
      "id":"6051711",
      "phuongxa":"Xã Krông Jing",
      "quanhuyen":"60517"
   },
   {
      "id":"6051713",
      "phuongxa":"Xã Ea M'Doal",
      "quanhuyen":"60517"
   },
   {
      "id":"6051715",
      "phuongxa":"Xã Ea Riêng",
      "quanhuyen":"60517"
   },
   {
      "id":"6051717",
      "phuongxa":"Xã Cư M'ta",
      "quanhuyen":"60517"
   },
   {
      "id":"6051719",
      "phuongxa":"Xã Cư K Róa",
      "quanhuyen":"60517"
   },
   {
      "id":"6051721",
      "phuongxa":"Xã KRông á",
      "quanhuyen":"60517"
   },
   {
      "id":"6051723",
      "phuongxa":"Xã Ea Trang",
      "quanhuyen":"60517"
   },
   {
      "id":"6051900",
      "phuongxa":"Không xác định",
      "quanhuyen":"60519"
   },
   {
      "id":"6051901",
      "phuongxa":"Thị trấn Phước An",
      "quanhuyen":"60519"
   },
   {
      "id":"6051903",
      "phuongxa":"Xã KRông Búk",
      "quanhuyen":"60519"
   },
   {
      "id":"6051905",
      "phuongxa":"Xã Ea Kly",
      "quanhuyen":"60519"
   },
   {
      "id":"6051907",
      "phuongxa":"Xã Ea Kênh",
      "quanhuyen":"60519"
   },
   {
      "id":"6051909",
      "phuongxa":"Xã Ea Phê",
      "quanhuyen":"60519"
   },
   {
      "id":"6051911",
      "phuongxa":"Xã Ea KNuec",
      "quanhuyen":"60519"
   },
   {
      "id":"6051913",
      "phuongxa":"Xã Ea Yông",
      "quanhuyen":"60519"
   },
   {
      "id":"6051915",
      "phuongxa":"Xã Hòa An",
      "quanhuyen":"60519"
   },
   {
      "id":"6051917",
      "phuongxa":"Xã Ea Kuăng",
      "quanhuyen":"60519"
   },
   {
      "id":"6051919",
      "phuongxa":"Xã Hoà Đông",
      "quanhuyen":"60519"
   },
   {
      "id":"6051923",
      "phuongxa":"Xã Hòa Tiến",
      "quanhuyen":"60519"
   },
   {
      "id":"6051925",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"60519"
   },
   {
      "id":"6051927",
      "phuongxa":"Xã Vụ Bổn",
      "quanhuyen":"60519"
   },
   {
      "id":"6051931",
      "phuongxa":"Xã Ea Yiêng",
      "quanhuyen":"60519"
   },
   {
      "id":"6052100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60521"
   },
   {
      "id":"6052101",
      "phuongxa":"Thị trấn Ea T'Ling",
      "quanhuyen":"60521"
   },
   {
      "id":"6052103",
      "phuongxa":"Xã Eapô",
      "quanhuyen":"60521"
   },
   {
      "id":"6052105",
      "phuongxa":"Xã Nam Dong",
      "quanhuyen":"60521"
   },
   {
      "id":"6052107",
      "phuongxa":"Xã Hòa Xuân",
      "quanhuyen":"60521"
   },
   {
      "id":"6052109",
      "phuongxa":"Xã Đắk DRông",
      "quanhuyen":"60521"
   },
   {
      "id":"6052111",
      "phuongxa":"Xã Hòa Khánh",
      "quanhuyen":"60521"
   },
   {
      "id":"6052113",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"60521"
   },
   {
      "id":"6052115",
      "phuongxa":"Xã Tâm Thắng",
      "quanhuyen":"60521"
   },
   {
      "id":"6052300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60523"
   },
   {
      "id":"6052303",
      "phuongxa":"Xã Cư Ê Wi",
      "quanhuyen":"60523"
   },
   {
      "id":"6052305",
      "phuongxa":"Xã Ea Ktur",
      "quanhuyen":"60523"
   },
   {
      "id":"6052307",
      "phuongxa":"Xã Ea Tiêu",
      "quanhuyen":"60523"
   },
   {
      "id":"6052309",
      "phuongxa":"Xã Ea BHốk",
      "quanhuyen":"60523"
   },
   {
      "id":"6052311",
      "phuongxa":"Xã Ea Hu",
      "quanhuyen":"60523"
   },
   {
      "id":"6052313",
      "phuongxa":"Xã Ea Na",
      "quanhuyen":"60523"
   },
   {
      "id":"6052315",
      "phuongxa":"Xã Hòa Hiệp",
      "quanhuyen":"60523"
   },
   {
      "id":"6052319",
      "phuongxa":"Xã Dur KMăl",
      "quanhuyen":"60523"
   },
   {
      "id":"6052321",
      "phuongxa":"Xã Bình Hòa",
      "quanhuyen":"60523"
   },
   {
      "id":"6052323",
      "phuongxa":"Xã Quảng Điền",
      "quanhuyen":"60523"
   },
   {
      "id":"6052500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60525"
   },
   {
      "id":"6052501",
      "phuongxa":"Thị trấn  Krông Kmar",
      "quanhuyen":"60525"
   },
   {
      "id":"6052505",
      "phuongxa":"Xã Cư KTy",
      "quanhuyen":"60525"
   },
   {
      "id":"6052507",
      "phuongxa":"Xã Hòa Thành",
      "quanhuyen":"60525"
   },
   {
      "id":"6052511",
      "phuongxa":"Xã Hòa Phong",
      "quanhuyen":"60525"
   },
   {
      "id":"6052513",
      "phuongxa":"Xã Hòa Lễ",
      "quanhuyen":"60525"
   },
   {
      "id":"6052515",
      "phuongxa":"Xã Ea Trul",
      "quanhuyen":"60525"
   },
   {
      "id":"6052517",
      "phuongxa":"Xã Khuê Ngọc Điền",
      "quanhuyen":"60525"
   },
   {
      "id":"6052519",
      "phuongxa":"Xã Cư Pui",
      "quanhuyen":"60525"
   },
   {
      "id":"6052523",
      "phuongxa":"Xã Cư Drăm",
      "quanhuyen":"60525"
   },
   {
      "id":"6052525",
      "phuongxa":"Xã Yang Mao",
      "quanhuyen":"60525"
   },
   {
      "id":"6052700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60527"
   },
   {
      "id":"6052701",
      "phuongxa":"Thị trấn Đắk Mil",
      "quanhuyen":"60527"
   },
   {
      "id":"6052703",
      "phuongxa":"Xã Đắk Gằn",
      "quanhuyen":"60527"
   },
   {
      "id":"6052705",
      "phuongxa":"Xã Đức Mạnh",
      "quanhuyen":"60527"
   },
   {
      "id":"6052707",
      "phuongxa":"Xã  Đắk Lao",
      "quanhuyen":"60527"
   },
   {
      "id":"6052709",
      "phuongxa":"Xã Đắk Sắk",
      "quanhuyen":"60527"
   },
   {
      "id":"6052711",
      "phuongxa":"Xã Đức Minh",
      "quanhuyen":"60527"
   },
   {
      "id":"6052713",
      "phuongxa":"Xã Thuận An",
      "quanhuyen":"60527"
   },
   {
      "id":"6052715",
      "phuongxa":"Xã Đắk Môl",
      "quanhuyen":"60527"
   },
   {
      "id":"6052719",
      "phuongxa":"Xã Đắk Song",
      "quanhuyen":"60527"
   },
   {
      "id":"6052721",
      "phuongxa":"Xã Đắk R'La",
      "quanhuyen":"60527"
   },
   {
      "id":"6052900",
      "phuongxa":"Không xác định",
      "quanhuyen":"60529"
   },
   {
      "id":"6052901",
      "phuongxa":"Xã Đắk Sôr",
      "quanhuyen":"60529"
   },
   {
      "id":"6052903",
      "phuongxa":"Xã Nam Đà",
      "quanhuyen":"60529"
   },
   {
      "id":"6052905",
      "phuongxa":"Xã Buôn Choah",
      "quanhuyen":"60529"
   },
   {
      "id":"6052907",
      "phuongxa":"Xã Đắk Mâm",
      "quanhuyen":"60529"
   },
   {
      "id":"6052909",
      "phuongxa":"Xã Đắk Rồ",
      "quanhuyen":"60529"
   },
   {
      "id":"6052911",
      "phuongxa":"Xã Nam Nung",
      "quanhuyen":"60529"
   },
   {
      "id":"6052913",
      "phuongxa":"Xã Đức Xuyên",
      "quanhuyen":"60529"
   },
   {
      "id":"6052915",
      "phuongxa":"Xã Ea R'Bin",
      "quanhuyen":"60529"
   },
   {
      "id":"6052917",
      "phuongxa":"Xã Đắk Nang",
      "quanhuyen":"60529"
   },
   {
      "id":"6052919",
      "phuongxa":"Xã Quảng Phú",
      "quanhuyen":"60529"
   },
   {
      "id":"6052921",
      "phuongxa":"Xã Nam Ka",
      "quanhuyen":"60529"
   },
   {
      "id":"6053100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60531"
   },
   {
      "id":"6053103",
      "phuongxa":"Xã Yang Tao",
      "quanhuyen":"60531"
   },
   {
      "id":"6053105",
      "phuongxa":"Xã Bông Krang",
      "quanhuyen":"60531"
   },
   {
      "id":"6053107",
      "phuongxa":"Xã Đắk Liêng",
      "quanhuyen":"60531"
   },
   {
      "id":"6053111",
      "phuongxa":"Xã Buôn Tría",
      "quanhuyen":"60531"
   },
   {
      "id":"6053113",
      "phuongxa":"Xã Đắk Phơi",
      "quanhuyen":"60531"
   },
   {
      "id":"6053115",
      "phuongxa":"Xã Đắk Nuê",
      "quanhuyen":"60531"
   },
   {
      "id":"6053117",
      "phuongxa":"Xã Krông Nô",
      "quanhuyen":"60531"
   },
   {
      "id":"6053300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60533"
   },
   {
      "id":"6053301",
      "phuongxa":"Xã Quảng Trực",
      "quanhuyen":"60533"
   },
   {
      "id":"6053305",
      "phuongxa":"Xã Đắk R'Tíh",
      "quanhuyen":"60533"
   },
   {
      "id":"6053307",
      "phuongxa":"Xã Quảng Tân",
      "quanhuyen":"60533"
   },
   {
      "id":"6053309",
      "phuongxa":"Xã Nhân Cơ",
      "quanhuyen":"60533"
   },
   {
      "id":"6053311",
      "phuongxa":"Xã Kiến Đức",
      "quanhuyen":"60533"
   },
   {
      "id":"6053313",
      "phuongxa":"Xã Quảng Tín",
      "quanhuyen":"60533"
   },
   {
      "id":"6053315",
      "phuongxa":"Xã Đạo Nghĩa",
      "quanhuyen":"60533"
   },
   {
      "id":"6053317",
      "phuongxa":"Xã Đắk Sin",
      "quanhuyen":"60533"
   },
   {
      "id":"6053500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60535"
   },
   {
      "id":"6053501",
      "phuongxa":"Xã Ea Tiêu",
      "quanhuyen":"60535"
   },
   {
      "id":"6053503",
      "phuongxa":"Xã Ea Ktur",
      "quanhuyen":"60535"
   },
   {
      "id":"6053505",
      "phuongxa":"Xã Ea Bhôk",
      "quanhuyen":"60535"
   },
   {
      "id":"6053507",
      "phuongxa":"Xã Hoà Hiệp",
      "quanhuyen":"60535"
   },
   {
      "id":"6053509",
      "phuongxa":"Xã Dray Bhăng",
      "quanhuyen":"60535"
   },
   {
      "id":"6053511",
      "phuongxa":"Xã Ea Hu",
      "quanhuyen":"60535"
   },
   {
      "id":"6053513",
      "phuongxa":"Xã Cư Ê Wi",
      "quanhuyen":"60535"
   },
   {
      "id":"6053515",
      "phuongxa":"Xã Ea Ning",
      "quanhuyen":"60535"
   },
   {
      "id":"6053700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60537"
   },
   {
      "id":"6053701",
      "phuongxa":"Phường Đạt Hiếu",
      "quanhuyen":"60537"
   },
   {
      "id":"6053705",
      "phuongxa":"Phường An Bình",
      "quanhuyen":"60537"
   },
   {
      "id":"6053707",
      "phuongxa":"Phường Thiện An",
      "quanhuyen":"60537"
   },
   {
      "id":"6053709",
      "phuongxa":"Phường Đoàn Kết",
      "quanhuyen":"60537"
   },
   {
      "id":"6053713",
      "phuongxa":"Phường Bình Tân",
      "quanhuyen":"60537"
   },
   {
      "id":"6053715",
      "phuongxa":"Xã Ea Siên",
      "quanhuyen":"60537"
   },
   {
      "id":"6053717",
      "phuongxa":"Xã Ea Drông",
      "quanhuyen":"60537"
   },
   {
      "id":"6053719",
      "phuongxa":"Xã Ea Blang",
      "quanhuyen":"60537"
   },
   {
      "id":"6053721",
      "phuongxa":"Xã Bình Thuận",
      "quanhuyen":"60537"
   },
   {
      "id":"6053723",
      "phuongxa":"Xã Cư Bao",
      "quanhuyen":"60537"
   },
   {
      "id":"6070100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60701"
   },
   {
      "id":"6070101",
      "phuongxa":"Xã Quảng Sơn",
      "quanhuyen":"60701"
   },
   {
      "id":"6070103",
      "phuongxa":"Xã Đắk Ha",
      "quanhuyen":"60701"
   },
   {
      "id":"6070105",
      "phuongxa":"Xã Đắk R'Măng",
      "quanhuyen":"60701"
   },
   {
      "id":"6070107",
      "phuongxa":"Xã Quảng Khê",
      "quanhuyen":"60701"
   },
   {
      "id":"6070109",
      "phuongxa":"Xã Đắk Plao",
      "quanhuyen":"60701"
   },
   {
      "id":"6070111",
      "phuongxa":"Xã Đắk Som",
      "quanhuyen":"60701"
   },
   {
      "id":"6070300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60703"
   },
   {
      "id":"6070301",
      "phuongxa":"Xã Đắk Môl",
      "quanhuyen":"60703"
   },
   {
      "id":"6070303",
      "phuongxa":"Xã Đắk Song",
      "quanhuyen":"60703"
   },
   {
      "id":"6070305",
      "phuongxa":"Xã Thuận Hạnh",
      "quanhuyen":"60703"
   },
   {
      "id":"6070307",
      "phuongxa":"Xã Đắk N'Drung",
      "quanhuyen":"60703"
   },
   {
      "id":"6070309",
      "phuongxa":"Xã Trường Xuân",
      "quanhuyen":"60703"
   },
   {
      "id":"6070311",
      "phuongxa":"Xã Nâm N'jang",
      "quanhuyen":"60703"
   },
   {
      "id":"6070313",
      "phuongxa":"Xã Đắk Hòa",
      "quanhuyen":"60703"
   },
   {
      "id":"6070500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60705"
   },
   {
      "id":"6070501",
      "phuongxa":"Phường Nghĩa Đức",
      "quanhuyen":"60705"
   },
   {
      "id":"6070503",
      "phuongxa":"Phường Nghĩa Thành",
      "quanhuyen":"60705"
   },
   {
      "id":"6070505",
      "phuongxa":"Phường Nghĩa Phú",
      "quanhuyen":"60705"
   },
   {
      "id":"6070507",
      "phuongxa":"Phường Nghĩa Tân",
      "quanhuyen":"60705"
   },
   {
      "id":"6070511",
      "phuongxa":"Xã Đắk R'Moan",
      "quanhuyen":"60705"
   },
   {
      "id":"6070513",
      "phuongxa":"Xã Quảng Thành",
      "quanhuyen":"60705"
   },
   {
      "id":"6070515",
      "phuongxa":"Xã Đắk Nia",
      "quanhuyen":"60705"
   },
   {
      "id":"6070700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60707"
   },
   {
      "id":"6070701",
      "phuongxa":"Xã Đắk Ngo",
      "quanhuyen":"60707"
   },
   {
      "id":"6070703",
      "phuongxa":"Xã Quảng Tân",
      "quanhuyen":"60707"
   },
   {
      "id":"6070705",
      "phuongxa":"Xã Đắk Buk So",
      "quanhuyen":"60707"
   },
   {
      "id":"6070707",
      "phuongxa":"Xã Đắk R'Tíh",
      "quanhuyen":"60707"
   },
   {
      "id":"6070709",
      "phuongxa":"Xã Quảng Tâm",
      "quanhuyen":"60707"
   },
   {
      "id":"6070711",
      "phuongxa":"Xã Quảng Trực",
      "quanhuyen":"60707"
   },
   {
      "id":"7010000",
      "phuongxa":"Không xác định",
      "quanhuyen":"70100"
   },
   {
      "id":"7010101",
      "phuongxa":"Phường Tân Định",
      "quanhuyen":"70101"
   },
   {
      "id":"7010103",
      "phuongxa":"Phường Đa Kao",
      "quanhuyen":"70101"
   },
   {
      "id":"7010105",
      "phuongxa":"Phường Bến Nghé",
      "quanhuyen":"70101"
   },
   {
      "id":"7010107",
      "phuongxa":"Phường Bến Thành",
      "quanhuyen":"70101"
   },
   {
      "id":"7010111",
      "phuongxa":"Phường Phạm Ngũ Lão",
      "quanhuyen":"70101"
   },
   {
      "id":"7010113",
      "phuongxa":"Phường Cầu Ông Lãnh",
      "quanhuyen":"70101"
   },
   {
      "id":"7010115",
      "phuongxa":"Phường Cô Giang",
      "quanhuyen":"70101"
   },
   {
      "id":"7010117",
      "phuongxa":"Phường Nguyễn Cư Trinh",
      "quanhuyen":"70101"
   },
   {
      "id":"7010119",
      "phuongxa":"Phường Cầu Kho",
      "quanhuyen":"70101"
   },
   {
      "id":"7010300",
      "phuongxa":"Không xác định",
      "quanhuyen":"769"
   },
   {
      "id":"7010301",
      "phuongxa":"Phường An Phú",
      "quanhuyen":"769"
   },
   {
      "id":"7010303",
      "phuongxa":"Phường Thảo Điền",
      "quanhuyen":"769"
   },
   {
      "id":"7010305",
      "phuongxa":"Phường An Khánh",
      "quanhuyen":"769"
   },
   {
      "id":"7010307",
      "phuongxa":"Phường Bình Khánh",
      "quanhuyen":"769"
   },
   {
      "id":"7010309",
      "phuongxa":"Phường Bình An",
      "quanhuyen":"769"
   },
   {
      "id":"6030521",
      "phuongxa":"Xã Kơ Dang",
      "quanhuyen":"60305"
   },
   {
      "id":"6030523",
      "phuongxa":"Xã H Neng",
      "quanhuyen":"60305"
   },
   {
      "id":"6030525",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"60305"
   },
   {
      "id":"6030527",
      "phuongxa":"Xã Glar",
      "quanhuyen":"60305"
   },
   {
      "id":"6030529",
      "phuongxa":"Xã Ia Dơk",
      "quanhuyen":"60305"
   },
   {
      "id":"6030531",
      "phuongxa":"Xã Trang",
      "quanhuyen":"60305"
   },
   {
      "id":"6030533",
      "phuongxa":"Xã Lơ Pang",
      "quanhuyen":"60305"
   },
   {
      "id":"6030535",
      "phuongxa":"Xã Ia Pết",
      "quanhuyen":"60305"
   },
   {
      "id":"6030537",
      "phuongxa":"Xã Ia Băng",
      "quanhuyen":"60305"
   },
   {
      "id":"6030539",
      "phuongxa":"Xã Kon Thụp",
      "quanhuyen":"60305"
   },
   {
      "id":"6030541",
      "phuongxa":"Xã Đê Ar",
      "quanhuyen":"60305"
   },
   {
      "id":"6030545",
      "phuongxa":"Xã Đăk Trôi",
      "quanhuyen":"60305"
   },
   {
      "id":"6030700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60307"
   },
   {
      "id":"6030701",
      "phuongxa":"Thị trấn Phú Hòa",
      "quanhuyen":"60307"
   },
   {
      "id":"6030703",
      "phuongxa":"Xã Hà Tây",
      "quanhuyen":"60307"
   },
   {
      "id":"6030705",
      "phuongxa":"Xã Ia Khươl",
      "quanhuyen":"60307"
   },
   {
      "id":"6030707",
      "phuongxa":"Xã Ia Phí",
      "quanhuyen":"60307"
   },
   {
      "id":"6030709",
      "phuongxa":"Xã Ia Mơ Nông",
      "quanhuyen":"60307"
   },
   {
      "id":"6030711",
      "phuongxa":"Xã Đăk Tơ Ver",
      "quanhuyen":"60307"
   },
   {
      "id":"6030713",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"60307"
   },
   {
      "id":"6030715",
      "phuongxa":"Xã Chư Đăng Ya",
      "quanhuyen":"60307"
   },
   {
      "id":"6030717",
      "phuongxa":"Xã Ia Ka",
      "quanhuyen":"60307"
   },
   {
      "id":"6030721",
      "phuongxa":"Xã Chư Jôr",
      "quanhuyen":"60307"
   },
   {
      "id":"6030723",
      "phuongxa":"Xã Nghĩa Hưng",
      "quanhuyen":"60307"
   },
   {
      "id":"6030900",
      "phuongxa":"Không xác định",
      "quanhuyen":"60309"
   },
   {
      "id":"6030901",
      "phuongxa":"Thị trấn Ia Kha",
      "quanhuyen":"60309"
   },
   {
      "id":"6030903",
      "phuongxa":"Xã Ia Sao",
      "quanhuyen":"60309"
   },
   {
      "id":"6030905",
      "phuongxa":"Xã Ia Hrung",
      "quanhuyen":"60309"
   },
   {
      "id":"6030907",
      "phuongxa":"Xã Ia KRai",
      "quanhuyen":"60309"
   },
   {
      "id":"6030909",
      "phuongxa":"Xã Ia Tô",
      "quanhuyen":"60309"
   },
   {
      "id":"6030911",
      "phuongxa":"Xã Ia Kênh",
      "quanhuyen":"60309"
   },
   {
      "id":"6030913",
      "phuongxa":"Xã Ia O",
      "quanhuyen":"60309"
   },
   {
      "id":"6030915",
      "phuongxa":"Xã Ia Dêr",
      "quanhuyen":"60309"
   },
   {
      "id":"6030917",
      "phuongxa":"Xã Ia Chia",
      "quanhuyen":"60309"
   },
   {
      "id":"6030919",
      "phuongxa":"Xã Ia Pếch",
      "quanhuyen":"60309"
   },
   {
      "id":"6031100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60311"
   },
   {
      "id":"6031101",
      "phuongxa":"Thị trấn An Khê",
      "quanhuyen":"60311"
   },
   {
      "id":"6031103",
      "phuongxa":"Xã Tú An",
      "quanhuyen":"60311"
   },
   {
      "id":"6031105",
      "phuongxa":"Xã Hà Tam",
      "quanhuyen":"60311"
   },
   {
      "id":"6031107",
      "phuongxa":"Xã Cửu An",
      "quanhuyen":"60311"
   },
   {
      "id":"6031109",
      "phuongxa":"Xã Cư An",
      "quanhuyen":"60311"
   },
   {
      "id":"6031113",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"60311"
   },
   {
      "id":"6031115",
      "phuongxa":"Xã Phú An",
      "quanhuyen":"60311"
   },
   {
      "id":"6031119",
      "phuongxa":"Xã An Thành",
      "quanhuyen":"60311"
   },
   {
      "id":"6031121",
      "phuongxa":"Xã Thành An",
      "quanhuyen":"60311"
   },
   {
      "id":"6031123",
      "phuongxa":"Xã Ya Hội",
      "quanhuyen":"60311"
   },
   {
      "id":"6031300",
      "phuongxa":"Không xác định",
      "quanhuyen":"60313"
   },
   {
      "id":"6031301",
      "phuongxa":"Thị trấn Kông Chro",
      "quanhuyen":"60313"
   },
   {
      "id":"6031303",
      "phuongxa":"Xã Chư Krêy",
      "quanhuyen":"60313"
   },
   {
      "id":"6031307",
      "phuongxa":"Xã Kông Yang",
      "quanhuyen":"60313"
   },
   {
      "id":"6031309",
      "phuongxa":"Xã Đăk Tơ Pang",
      "quanhuyen":"60313"
   },
   {
      "id":"6031311",
      "phuongxa":"Xã SRó",
      "quanhuyen":"60313"
   },
   {
      "id":"6031313",
      "phuongxa":"Xã Đăk Song",
      "quanhuyen":"60313"
   },
   {
      "id":"6031315",
      "phuongxa":"Xã Yang Trung",
      "quanhuyen":"60313"
   },
   {
      "id":"6031317",
      "phuongxa":"Xã Ya Ma",
      "quanhuyen":"60313"
   },
   {
      "id":"6031319",
      "phuongxa":"Xã Chư Long",
      "quanhuyen":"60313"
   },
   {
      "id":"6031321",
      "phuongxa":"Xã Yang Nam",
      "quanhuyen":"60313"
   },
   {
      "id":"6031500",
      "phuongxa":"Không xác định",
      "quanhuyen":"60315"
   },
   {
      "id":"6031501",
      "phuongxa":"Thị trấn Chư Ty",
      "quanhuyen":"60315"
   },
   {
      "id":"6031505",
      "phuongxa":"Xã Ia Krêl",
      "quanhuyen":"60315"
   },
   {
      "id":"6031507",
      "phuongxa":"Xã Ia Din",
      "quanhuyen":"60315"
   },
   {
      "id":"6031509",
      "phuongxa":"Xã Ia Kla",
      "quanhuyen":"60315"
   },
   {
      "id":"6031511",
      "phuongxa":"Xã Ia Dom",
      "quanhuyen":"60315"
   },
   {
      "id":"6031513",
      "phuongxa":"Xã Ia Lang",
      "quanhuyen":"60315"
   },
   {
      "id":"6031515",
      "phuongxa":"Xã Ia Kriêng",
      "quanhuyen":"60315"
   },
   {
      "id":"6031517",
      "phuongxa":"Xã Ia Pnôn",
      "quanhuyen":"60315"
   },
   {
      "id":"6031519",
      "phuongxa":"Xã Ia Nan",
      "quanhuyen":"60315"
   },
   {
      "id":"6031700",
      "phuongxa":"Không xác định",
      "quanhuyen":"60317"
   },
   {
      "id":"6031703",
      "phuongxa":"Xã Bình Giáo",
      "quanhuyen":"60317"
   },
   {
      "id":"6031705",
      "phuongxa":"Xã Thăng Hưng",
      "quanhuyen":"60317"
   },
   {
      "id":"6031707",
      "phuongxa":"Xã Ia Phìn",
      "quanhuyen":"60317"
   },
   {
      "id":"6031709",
      "phuongxa":"Xã Ia Băng",
      "quanhuyen":"60317"
   },
   {
      "id":"7131321",
      "phuongxa":"Xã Xuân Phú",
      "quanhuyen":"71313"
   },
   {
      "id":"7131323",
      "phuongxa":"Xã Bảo Hoà",
      "quanhuyen":"71313"
   },
   {
      "id":"7131325",
      "phuongxa":"Xã Xuân Định",
      "quanhuyen":"71313"
   },
   {
      "id":"7131327",
      "phuongxa":"Xã Xuân Hòa",
      "quanhuyen":"71313"
   },
   {
      "id":"7131329",
      "phuongxa":"Xã Xuân Hưng",
      "quanhuyen":"71313"
   },
   {
      "id":"7131331",
      "phuongxa":"Xã Xuân Đông",
      "quanhuyen":"71318"
   },
   {
      "id":"7131333",
      "phuongxa":"Xã Xuân Tây",
      "quanhuyen":"71313"
   },
   {
      "id":"7131335",
      "phuongxa":"Xã Bảo Bình",
      "quanhuyen":"71313"
   },
   {
      "id":"7131339",
      "phuongxa":"Xã Lâm San",
      "quanhuyen":"71313"
   },
   {
      "id":"7131341",
      "phuongxa":"Xã Sông Ray",
      "quanhuyen":"71313"
   },
   {
      "id":"7131500",
      "phuongxa":"Không xác định",
      "quanhuyen":"71315"
   },
   {
      "id":"7131501",
      "phuongxa":"Thị trấn Long Thành",
      "quanhuyen":"71315"
   },
   {
      "id":"7131503",
      "phuongxa":"Phường Phước Tân",
      "quanhuyen":"71301"
   },
   {
      "id":"7131505",
      "phuongxa":"Xã An Hoà",
      "quanhuyen":"71301"
   },
   {
      "id":"7131507",
      "phuongxa":"Xã Long Hưng",
      "quanhuyen":"71301"
   },
   {
      "id":"7131509",
      "phuongxa":"Phường Tam Phước",
      "quanhuyen":"71301"
   },
   {
      "id":"7131511",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"71315"
   },
   {
      "id":"7131515",
      "phuongxa":"Xã Tam An",
      "quanhuyen":"71315"
   },
   {
      "id":"7131517",
      "phuongxa":"Xã Lộc An",
      "quanhuyen":"71315"
   },
   {
      "id":"7131519",
      "phuongxa":"Xã Long Đức",
      "quanhuyen":"71315"
   },
   {
      "id":"7131521",
      "phuongxa":"Xã Bình Sơn",
      "quanhuyen":"71315"
   },
   {
      "id":"7131523",
      "phuongxa":"Xã Cẩm Đường",
      "quanhuyen":"71315"
   },
   {
      "id":"7131525",
      "phuongxa":"Xã Long An",
      "quanhuyen":"71315"
   },
   {
      "id":"7131527",
      "phuongxa":"Xã Suối Trầu",
      "quanhuyen":"71315"
   },
   {
      "id":"7131529",
      "phuongxa":"Xã Bàu Cạn",
      "quanhuyen":"71315"
   },
   {
      "id":"7131531",
      "phuongxa":"Xã Long Phước",
      "quanhuyen":"71315"
   },
   {
      "id":"7131533",
      "phuongxa":"Xã Phước Bình",
      "quanhuyen":"71315"
   },
   {
      "id":"7131535",
      "phuongxa":"Xã Tân Hiệp",
      "quanhuyen":"71315"
   },
   {
      "id":"7131537",
      "phuongxa":"Xã Phước Thái",
      "quanhuyen":"71315"
   },
   {
      "id":"7131700",
      "phuongxa":"Không xác định",
      "quanhuyen":"71317"
   },
   {
      "id":"7131701",
      "phuongxa":"Xã Phước Thiền",
      "quanhuyen":"71317"
   },
   {
      "id":"7131703",
      "phuongxa":"Xã Long Tân",
      "quanhuyen":"71317"
   },
   {
      "id":"7131705",
      "phuongxa":"Xã Đại Phước",
      "quanhuyen":"71317"
   },
   {
      "id":"7131707",
      "phuongxa":"Xã Phú Hội",
      "quanhuyen":"71317"
   },
   {
      "id":"7131709",
      "phuongxa":"Thị trấn Hiệp Phước",
      "quanhuyen":"71317"
   },
   {
      "id":"7131711",
      "phuongxa":"Xã Phú Hữu",
      "quanhuyen":"71317"
   },
   {
      "id":"7131713",
      "phuongxa":"Xã Phú Thạnh",
      "quanhuyen":"71317"
   },
   {
      "id":"7131715",
      "phuongxa":"Xã Long Thọ",
      "quanhuyen":"71317"
   },
   {
      "id":"7131717",
      "phuongxa":"Xã Phú Đông",
      "quanhuyen":"71317"
   },
   {
      "id":"7131719",
      "phuongxa":"Xã Phước An",
      "quanhuyen":"71317"
   },
   {
      "id":"7131721",
      "phuongxa":"Xã Vĩnh Thanh",
      "quanhuyen":"71317"
   },
   {
      "id":"7131723",
      "phuongxa":"Xã Phước Khánh",
      "quanhuyen":"71317"
   },
   {
      "id":"7131801",
      "phuongxa":"sông nhạn",
      "quanhuyen":"71318"
   },
   {
      "id":"7150000",
      "phuongxa":"Không xác định",
      "quanhuyen":"71500"
   },
   {
      "id":"7150100",
      "phuongxa":"Không xác định",
      "quanhuyen":"71501"
   },
   {
      "id":"7150103",
      "phuongxa":"Phường Mũi Né",
      "quanhuyen":"71501"
   },
   {
      "id":"7150105",
      "phuongxa":"Phường Phú Trinh",
      "quanhuyen":"71501"
   },
   {
      "id":"7150107",
      "phuongxa":"Phường Phú Thủy",
      "quanhuyen":"71501"
   },
   {
      "id":"7150109",
      "phuongxa":"Phường Thanh Hải",
      "quanhuyen":"71501"
   },
   {
      "id":"7150113",
      "phuongxa":"Phường Hưng Long",
      "quanhuyen":"71501"
   },
   {
      "id":"7150115",
      "phuongxa":"Phường Đức Nghĩa",
      "quanhuyen":"71501"
   },
   {
      "id":"7150117",
      "phuongxa":"Phường Lạc Đạo",
      "quanhuyen":"71501"
   },
   {
      "id":"7150119",
      "phuongxa":"Phường Đức Long",
      "quanhuyen":"71501"
   },
   {
      "id":"7150121",
      "phuongxa":"Xã Hàm Tiến",
      "quanhuyen":"71501"
   },
   {
      "id":"7150123",
      "phuongxa":"Xã Phú Hải",
      "quanhuyen":"71501"
   },
   {
      "id":"7150125",
      "phuongxa":"Xã Phong Nẫm",
      "quanhuyen":"71501"
   },
   {
      "id":"7150127",
      "phuongxa":"Xã Tiến Lợi",
      "quanhuyen":"71501"
   },
   {
      "id":"7150129",
      "phuongxa":"Xã Tiến Thành",
      "quanhuyen":"71501"
   },
   {
      "id":"7150300",
      "phuongxa":"Không xác định",
      "quanhuyen":"71503"
   },
   {
      "id":"7150301",
      "phuongxa":"Thị trấn Liên Hương",
      "quanhuyen":"71503"
   },
   {
      "id":"7150305",
      "phuongxa":"Xã Phan Dũng",
      "quanhuyen":"71503"
   },
   {
      "id":"7150307",
      "phuongxa":"Xã Vĩnh Hảo",
      "quanhuyen":"71503"
   },
   {
      "id":"7150309",
      "phuongxa":"Xã Phong Phú",
      "quanhuyen":"71503"
   },
   {
      "id":"7150311",
      "phuongxa":"Xã Phú Lạc",
      "quanhuyen":"71503"
   },
   {
      "id":"7150313",
      "phuongxa":"Xã Phước Thể",
      "quanhuyen":"71503"
   },
   {
      "id":"7150315",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"71503"
   },
   {
      "id":"7150317",
      "phuongxa":"Xã Chí Công",
      "quanhuyen":"71503"
   },
   {
      "id":"7150319",
      "phuongxa":"Xã Hòa Minh",
      "quanhuyen":"71503"
   },
   {
      "id":"7150321",
      "phuongxa":"Xã Hoà Phú",
      "quanhuyen":"71503"
   },
   {
      "id":"7150500",
      "phuongxa":"Không xác định",
      "quanhuyen":"71505"
   },
   {
      "id":"8070925",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"82625"
   },
   {
      "id":"8070927",
      "phuongxa":"Phường Nhị Mỹ",
      "quanhuyen":"80709"
   },
   {
      "id":"8070931",
      "phuongxa":"Xã Thanh Hòa",
      "quanhuyen":"80709"
   },
   {
      "id":"8070933",
      "phuongxa":"Xã Phú Quý",
      "quanhuyen":"80709"
   },
   {
      "id":"8070935",
      "phuongxa":"Xã Long Khánh",
      "quanhuyen":"80709"
   },
   {
      "id":"8070937",
      "phuongxa":"Xã Cẩm Sơn",
      "quanhuyen":"82625"
   },
   {
      "id":"8070939",
      "phuongxa":"Xã Phú An",
      "quanhuyen":"82625"
   },
   {
      "id":"8070941",
      "phuongxa":"Xã Mỹ Long",
      "quanhuyen":"82625"
   },
   {
      "id":"8070943",
      "phuongxa":"Xã Long Tiên",
      "quanhuyen":"82625"
   },
   {
      "id":"8070945",
      "phuongxa":"Xã Hiệp Đức",
      "quanhuyen":"82625"
   },
   {
      "id":"8070947",
      "phuongxa":"Xã Long Trung",
      "quanhuyen":"82625"
   },
   {
      "id":"8070951",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"82625"
   },
   {
      "id":"8070953",
      "phuongxa":"Xã Tam Bình",
      "quanhuyen":"82625"
   },
   {
      "id":"8070955",
      "phuongxa":"Xã Ngũ Hiệp",
      "quanhuyen":"82625"
   },
   {
      "id":"8071100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80711"
   },
   {
      "id":"8071101",
      "phuongxa":"Thị trấn Chợ Gạo",
      "quanhuyen":"80711"
   },
   {
      "id":"8071103",
      "phuongxa":"Xã Trung Hòa",
      "quanhuyen":"80711"
   },
   {
      "id":"8071105",
      "phuongxa":"Xã Hòa Tịnh",
      "quanhuyen":"80711"
   },
   {
      "id":"8071107",
      "phuongxa":"Xã Mỹ Tịnh An",
      "quanhuyen":"80711"
   },
   {
      "id":"8071109",
      "phuongxa":"Xã Tân Bình Thạnh",
      "quanhuyen":"80711"
   },
   {
      "id":"8071113",
      "phuongxa":"Xã Lương Hòa Lạc",
      "quanhuyen":"80711"
   },
   {
      "id":"8071115",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"80711"
   },
   {
      "id":"8071117",
      "phuongxa":"Xã Quơn Long",
      "quanhuyen":"80711"
   },
   {
      "id":"8071119",
      "phuongxa":"Xã Bình Phục Nhứt",
      "quanhuyen":"80711"
   },
   {
      "id":"8071121",
      "phuongxa":"Xã Đăng Hưng Phước",
      "quanhuyen":"80711"
   },
   {
      "id":"8071123",
      "phuongxa":"Xã Tân Thuận Bình",
      "quanhuyen":"80711"
   },
   {
      "id":"8071125",
      "phuongxa":"Xã Song Bình",
      "quanhuyen":"80711"
   },
   {
      "id":"8071127",
      "phuongxa":"Xã Bình Phan",
      "quanhuyen":"80711"
   },
   {
      "id":"8071129",
      "phuongxa":"Xã Long Bình Điền",
      "quanhuyen":"80711"
   },
   {
      "id":"8071131",
      "phuongxa":"Xã An Thạnh Thủy",
      "quanhuyen":"80711"
   },
   {
      "id":"8071133",
      "phuongxa":"Xã Xuân Đông",
      "quanhuyen":"80711"
   },
   {
      "id":"8071135",
      "phuongxa":"Xã Hòa Định",
      "quanhuyen":"80711"
   },
   {
      "id":"8071137",
      "phuongxa":"Xã Bình Ninh",
      "quanhuyen":"80711"
   },
   {
      "id":"8071300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80713"
   },
   {
      "id":"8071301",
      "phuongxa":"Thị trấn Cái Bè",
      "quanhuyen":"80713"
   },
   {
      "id":"8071303",
      "phuongxa":"Xã Hậu Mỹ Bắc B",
      "quanhuyen":"80713"
   },
   {
      "id":"8071307",
      "phuongxa":"Xã Mỹ Trung",
      "quanhuyen":"80713"
   },
   {
      "id":"8071309",
      "phuongxa":"Xã Hậu Mỹ Trinh",
      "quanhuyen":"80713"
   },
   {
      "id":"8071311",
      "phuongxa":"Xã Hậu Mỹ Phú",
      "quanhuyen":"80713"
   },
   {
      "id":"8071313",
      "phuongxa":"Xã Mỹ Tân",
      "quanhuyen":"80713"
   },
   {
      "id":"8071315",
      "phuongxa":"Xã Mỹ Lợi B",
      "quanhuyen":"80713"
   },
   {
      "id":"8071317",
      "phuongxa":"Xã Thiện Trung",
      "quanhuyen":"80713"
   },
   {
      "id":"8071319",
      "phuongxa":"Xã Hội Cư",
      "quanhuyen":"80713"
   },
   {
      "id":"8071321",
      "phuongxa":"Xã Hậu Thành",
      "quanhuyen":"80713"
   },
   {
      "id":"8071323",
      "phuongxa":"Xã Mỹ Lợi A",
      "quanhuyen":"80713"
   },
   {
      "id":"8071325",
      "phuongxa":"Xã Hòa Khánh",
      "quanhuyen":"80713"
   },
   {
      "id":"8071327",
      "phuongxa":"Xã Thiện Trí",
      "quanhuyen":"80713"
   },
   {
      "id":"8071331",
      "phuongxa":"Xã Mỹ Đức Tây",
      "quanhuyen":"80713"
   },
   {
      "id":"8071333",
      "phuongxa":"Xã Đông Hòa Hiệp",
      "quanhuyen":"80713"
   },
   {
      "id":"8071335",
      "phuongxa":"Xã An Thái Đông",
      "quanhuyen":"80713"
   },
   {
      "id":"8071337",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"80713"
   },
   {
      "id":"8071339",
      "phuongxa":"Xã Mỹ Lương",
      "quanhuyen":"80713"
   },
   {
      "id":"8071341",
      "phuongxa":"Xã Tân Thanh",
      "quanhuyen":"80713"
   },
   {
      "id":"8071345",
      "phuongxa":"Xã An Hữu",
      "quanhuyen":"80713"
   },
   {
      "id":"8071347",
      "phuongxa":"Xã Hòa Hưng",
      "quanhuyen":"80713"
   },
   {
      "id":"8071501",
      "phuongxa":"Thị trấn Vĩnh Bình",
      "quanhuyen":"80715"
   },
   {
      "id":"8071503",
      "phuongxa":"Xã Đồng Sơn",
      "quanhuyen":"80715"
   },
   {
      "id":"8071505",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"80715"
   },
   {
      "id":"8071507",
      "phuongxa":"Xã Đồng Thanh",
      "quanhuyen":"80715"
   },
   {
      "id":"8071509",
      "phuongxa":"Xã Thành Công",
      "quanhuyen":"80715"
   },
   {
      "id":"8071511",
      "phuongxa":"Xã Bình Nhì",
      "quanhuyen":"80715"
   },
   {
      "id":"8071513",
      "phuongxa":"Xã Yên Luông",
      "quanhuyen":"80715"
   },
   {
      "id":"8071515",
      "phuongxa":"Xã Thạnh Trị",
      "quanhuyen":"80715"
   },
   {
      "id":"8071517",
      "phuongxa":"Xã Thạnh Nhựt",
      "quanhuyen":"80715"
   },
   {
      "id":"8071519",
      "phuongxa":"Xã Long Vĩnh",
      "quanhuyen":"80715"
   },
   {
      "id":"8071521",
      "phuongxa":"Xã Bình Tân",
      "quanhuyen":"80715"
   },
   {
      "id":"8071523",
      "phuongxa":"Xã Vĩnh Hựu",
      "quanhuyen":"80715"
   },
   {
      "id":"8210519",
      "phuongxa":"Xã Vĩnh Mỹ B",
      "quanhuyen":"82105"
   },
   {
      "id":"8210521",
      "phuongxa":"Xã Vĩnh Hậu",
      "quanhuyen":"82105"
   },
   {
      "id":"8210523",
      "phuongxa":"Xã Vĩnh Mỹ A",
      "quanhuyen":"82105"
   },
   {
      "id":"8210525",
      "phuongxa":"Xã Vĩnh Thịnh",
      "quanhuyen":"82105"
   },
   {
      "id":"8210700",
      "phuongxa":"Không xác định",
      "quanhuyen":"82107"
   },
   {
      "id":"8210701",
      "phuongxa":"Thị trấn Giá Rai",
      "quanhuyen":"82107"
   },
   {
      "id":"8210703",
      "phuongxa":"Thị trấn Hộ Phòng",
      "quanhuyen":"82107"
   },
   {
      "id":"8210705",
      "phuongxa":"Thị trấn Gành Hào",
      "quanhuyen":"82107"
   },
   {
      "id":"8210709",
      "phuongxa":"Xã Phong Tân",
      "quanhuyen":"82107"
   },
   {
      "id":"8210711",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"82107"
   },
   {
      "id":"8210713",
      "phuongxa":"Xã Phong Thạnh",
      "quanhuyen":"82107"
   },
   {
      "id":"8210715",
      "phuongxa":"Xã Phong Thạnh Tây",
      "quanhuyen":"82107"
   },
   {
      "id":"8210717",
      "phuongxa":"Xã Long Điền Đông",
      "quanhuyen":"82107"
   },
   {
      "id":"8210719",
      "phuongxa":"Xã Long Điền",
      "quanhuyen":"82107"
   },
   {
      "id":"8210721",
      "phuongxa":"Xã Long Điền Tây",
      "quanhuyen":"82107"
   },
   {
      "id":"8210723",
      "phuongxa":"Xã An Trạch",
      "quanhuyen":"82107"
   },
   {
      "id":"8210725",
      "phuongxa":"Xã Tân Thạnh",
      "quanhuyen":"82107"
   },
   {
      "id":"8210727",
      "phuongxa":"Xã An Phúc",
      "quanhuyen":"82107"
   },
   {
      "id":"8210801",
      "phuongxa":"Xã Vĩnh Mỹ A",
      "quanhuyen":"82108"
   },
   {
      "id":"8218282",
      "phuongxa":"Gành Hào",
      "quanhuyen":"82182"
   },
   {
      "id":"8230000",
      "phuongxa":"Không xác định",
      "quanhuyen":"82300"
   },
   {
      "id":"8230100",
      "phuongxa":"Không xác định",
      "quanhuyen":"82301"
   },
   {
      "id":"8230101",
      "phuongxa":"Phường 1",
      "quanhuyen":"82301"
   },
   {
      "id":"8230103",
      "phuongxa":"Phường 2",
      "quanhuyen":"82301"
   },
   {
      "id":"8230105",
      "phuongxa":"Phường 4",
      "quanhuyen":"82301"
   },
   {
      "id":"8230107",
      "phuongxa":"Phường 5",
      "quanhuyen":"82301"
   },
   {
      "id":"8230109",
      "phuongxa":"Phường 6",
      "quanhuyen":"82301"
   },
   {
      "id":"8230111",
      "phuongxa":"Phường 7",
      "quanhuyen":"82301"
   },
   {
      "id":"8230113",
      "phuongxa":"Phường 8",
      "quanhuyen":"82301"
   },
   {
      "id":"8230115",
      "phuongxa":"Phường 9",
      "quanhuyen":"82301"
   },
   {
      "id":"8230117",
      "phuongxa":"Xã An Xuyên",
      "quanhuyen":"82301"
   },
   {
      "id":"8230119",
      "phuongxa":"Phường Tân Thành",
      "quanhuyen":"82301"
   },
   {
      "id":"8230121",
      "phuongxa":"Xã Tắc Vân",
      "quanhuyen":"82301"
   },
   {
      "id":"8230123",
      "phuongxa":"Xã Định Bình",
      "quanhuyen":"82301"
   },
   {
      "id":"8230125",
      "phuongxa":"Xã Hòa Thành",
      "quanhuyen":"82301"
   },
   {
      "id":"8230129",
      "phuongxa":"Xã Hòa Tân",
      "quanhuyen":"82301"
   },
   {
      "id":"8230201",
      "phuongxa":"Cái Đôi Vàm",
      "quanhuyen":"82302"
   },
   {
      "id":"8230202",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"82302"
   },
   {
      "id":"8230203",
      "phuongxa":"Xã Phú Thuận",
      "quanhuyen":"82302"
   },
   {
      "id":"8230300",
      "phuongxa":"Không xác định",
      "quanhuyen":"82303"
   },
   {
      "id":"8230301",
      "phuongxa":"Thị trấn Thới Bình",
      "quanhuyen":"82303"
   },
   {
      "id":"8230305",
      "phuongxa":"Xã Biển Bạch",
      "quanhuyen":"82303"
   },
   {
      "id":"8230307",
      "phuongxa":"Xã Trí Phải",
      "quanhuyen":"82303"
   },
   {
      "id":"8230309",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"82303"
   },
   {
      "id":"8230311",
      "phuongxa":"Xã Thới Bình",
      "quanhuyen":"82303"
   },
   {
      "id":"8230313",
      "phuongxa":"Xã Tân Lộc",
      "quanhuyen":"82303"
   },
   {
      "id":"8230314",
      "phuongxa":"Xã Tân Lộc Đông",
      "quanhuyen":"82303"
   },
   {
      "id":"8230315",
      "phuongxa":"Xã Hồ Thị Kỷ",
      "quanhuyen":"82303"
   },
   {
      "id":"8230500",
      "phuongxa":"Không xác định",
      "quanhuyen":"82305"
   },
   {
      "id":"8230501",
      "phuongxa":"Thị trấn U Minh",
      "quanhuyen":"82305"
   },
   {
      "id":"8230503",
      "phuongxa":"Xã Nguyễn Phích",
      "quanhuyen":"82305"
   },
   {
      "id":"8230505",
      "phuongxa":"Xã Khánh Hòa",
      "quanhuyen":"82305"
   },
   {
      "id":"8230509",
      "phuongxa":"Xã Khánh Lâm",
      "quanhuyen":"82305"
   },
   {
      "id":"8230511",
      "phuongxa":"Xã Khánh An",
      "quanhuyen":"82305"
   },
   {
      "id":"8230700",
      "phuongxa":"Không xác định",
      "quanhuyen":"82307"
   },
   {
      "id":"8230701",
      "phuongxa":"Thị trấn Trần Văn Thời",
      "quanhuyen":"82307"
   },
   {
      "id":"8230703",
      "phuongxa":"Thị trấn Sông Đốc",
      "quanhuyen":"82307"
   },
   {
      "id":"8230705",
      "phuongxa":"Xã Khánh Bình Đông",
      "quanhuyen":"82307"
   },
   {
      "id":"8230707",
      "phuongxa":"Xã Khánh Bình",
      "quanhuyen":"82307"
   },
   {
      "id":"8230711",
      "phuongxa":"Xã Khánh Hưng",
      "quanhuyen":"82307"
   },
   {
      "id":"8230713",
      "phuongxa":"Xã Trần Hợi",
      "quanhuyen":"82307"
   },
   {
      "id":"8230715",
      "phuongxa":"Xã Lợi An",
      "quanhuyen":"82307"
   },
   {
      "id":"8230717",
      "phuongxa":"Xã Khánh Hải",
      "quanhuyen":"82307"
   },
   {
      "id":"8230719",
      "phuongxa":"Xã Phong Lạc",
      "quanhuyen":"82307"
   },
   {
      "id":"8230720",
      "phuongxa":"Khánh Bình",
      "quanhuyen":"82307"
   },
   {
      "id":"8230771",
      "phuongxa":"khánh Bình Tây Bắc",
      "quanhuyen":"82307"
   },
   {
      "id":"8230900",
      "phuongxa":"Không xác định",
      "quanhuyen":"82309"
   },
   {
      "id":"8230901",
      "phuongxa":"Thị trấn Cái Nước",
      "quanhuyen":"82309"
   },
   {
      "id":"8230903",
      "phuongxa":"Thị trấn Cái Đôi Vàm",
      "quanhuyen":"82309"
   },
   {
      "id":"7012511",
      "phuongxa":"Phường 10",
      "quanhuyen":"70125"
   },
   {
      "id":"7012513",
      "phuongxa":"Phường 11",
      "quanhuyen":"70125"
   },
   {
      "id":"7012517",
      "phuongxa":"Phường 13",
      "quanhuyen":"70125"
   },
   {
      "id":"7012518",
      "phuongxa":"phường 14",
      "quanhuyen":"70125"
   },
   {
      "id":"7012519",
      "phuongxa":"Phường 15",
      "quanhuyen":"70125"
   },
   {
      "id":"7012521",
      "phuongxa":"Phường 16",
      "quanhuyen":"70125"
   },
   {
      "id":"7012523",
      "phuongxa":"Phường 17",
      "quanhuyen":"70125"
   },
   {
      "id":"7012700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70127"
   },
   {
      "id":"7012703",
      "phuongxa":"Phường 2",
      "quanhuyen":"70127"
   },
   {
      "id":"7012705",
      "phuongxa":"Phường 3",
      "quanhuyen":"70127"
   },
   {
      "id":"7012707",
      "phuongxa":"Phường 4",
      "quanhuyen":"70127"
   },
   {
      "id":"7012709",
      "phuongxa":"Phường 5",
      "quanhuyen":"70127"
   },
   {
      "id":"7012711",
      "phuongxa":"Phường 6",
      "quanhuyen":"70127"
   },
   {
      "id":"7012713",
      "phuongxa":"Phường 7",
      "quanhuyen":"70127"
   },
   {
      "id":"7012715",
      "phuongxa":"Phường 8",
      "quanhuyen":"70127"
   },
   {
      "id":"7012717",
      "phuongxa":"Phường 9",
      "quanhuyen":"70127"
   },
   {
      "id":"7012719",
      "phuongxa":"Phường 10",
      "quanhuyen":"70127"
   },
   {
      "id":"7012721",
      "phuongxa":"Phường 11",
      "quanhuyen":"70127"
   },
   {
      "id":"7012723",
      "phuongxa":"Phường 12",
      "quanhuyen":"70127"
   },
   {
      "id":"7012725",
      "phuongxa":"Phường 13",
      "quanhuyen":"70127"
   },
   {
      "id":"7012727",
      "phuongxa":"Phường 14",
      "quanhuyen":"70127"
   },
   {
      "id":"7012729",
      "phuongxa":"Phường 15",
      "quanhuyen":"70127"
   },
   {
      "id":"7012731",
      "phuongxa":"Phường 16",
      "quanhuyen":"70127"
   },
   {
      "id":"7012733",
      "phuongxa":"Phường 17",
      "quanhuyen":"70127"
   },
   {
      "id":"7012737",
      "phuongxa":"Phường 19",
      "quanhuyen":"70127"
   },
   {
      "id":"7012739",
      "phuongxa":"Phường 20",
      "quanhuyen":"70127"
   },
   {
      "id":"7012900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70129"
   },
   {
      "id":"7012901",
      "phuongxa":"Phường 1",
      "quanhuyen":"70129"
   },
   {
      "id":"7012903",
      "phuongxa":"Phường 2",
      "quanhuyen":"70129"
   },
   {
      "id":"7012905",
      "phuongxa":"Phường 3",
      "quanhuyen":"70129"
   },
   {
      "id":"7012907",
      "phuongxa":"Phường 5",
      "quanhuyen":"70129"
   },
   {
      "id":"7012909",
      "phuongxa":"Phường 6",
      "quanhuyen":"70129"
   },
   {
      "id":"7012911",
      "phuongxa":"Phường 7",
      "quanhuyen":"70129"
   },
   {
      "id":"7012913",
      "phuongxa":"Phường 11",
      "quanhuyen":"70129"
   },
   {
      "id":"7012915",
      "phuongxa":"Phường 12",
      "quanhuyen":"70129"
   },
   {
      "id":"7012917",
      "phuongxa":"Phường 13",
      "quanhuyen":"70129"
   },
   {
      "id":"7012919",
      "phuongxa":"Phường 14",
      "quanhuyen":"70129"
   },
   {
      "id":"7012921",
      "phuongxa":"Phường 15",
      "quanhuyen":"70129"
   },
   {
      "id":"7012923",
      "phuongxa":"Phường 17",
      "quanhuyen":"70129"
   },
   {
      "id":"7012925",
      "phuongxa":"Phường 19",
      "quanhuyen":"70129"
   },
   {
      "id":"7012927",
      "phuongxa":"Phường 21",
      "quanhuyen":"70129"
   },
   {
      "id":"7012929",
      "phuongxa":"Phường 22",
      "quanhuyen":"70129"
   },
   {
      "id":"7012931",
      "phuongxa":"Phường 24",
      "quanhuyen":"70129"
   },
   {
      "id":"7012933",
      "phuongxa":"Phường 25",
      "quanhuyen":"70129"
   },
   {
      "id":"7012935",
      "phuongxa":"Phường 26",
      "quanhuyen":"70129"
   },
   {
      "id":"7012937",
      "phuongxa":"Phường 27",
      "quanhuyen":"70129"
   },
   {
      "id":"7012939",
      "phuongxa":"Phường 28",
      "quanhuyen":"70129"
   },
   {
      "id":"7013101",
      "phuongxa":"Phường 1",
      "quanhuyen":"70131"
   },
   {
      "id":"7013103",
      "phuongxa":"Phường 2",
      "quanhuyen":"70131"
   },
   {
      "id":"7013105",
      "phuongxa":"Phường 3",
      "quanhuyen":"70131"
   },
   {
      "id":"7013107",
      "phuongxa":"Phường 4",
      "quanhuyen":"70131"
   },
   {
      "id":"7013109",
      "phuongxa":"Phường 5",
      "quanhuyen":"70131"
   },
   {
      "id":"7013111",
      "phuongxa":"Phường 7",
      "quanhuyen":"70131"
   },
   {
      "id":"7013113",
      "phuongxa":"Phường 8",
      "quanhuyen":"70131"
   },
   {
      "id":"7013115",
      "phuongxa":"Phường 9",
      "quanhuyen":"70131"
   },
   {
      "id":"7013117",
      "phuongxa":"Phường 10",
      "quanhuyen":"70131"
   },
   {
      "id":"7013119",
      "phuongxa":"Phường 11",
      "quanhuyen":"70131"
   },
   {
      "id":"7013121",
      "phuongxa":"Phường 12",
      "quanhuyen":"70131"
   },
   {
      "id":"7013123",
      "phuongxa":"Phường 13",
      "quanhuyen":"70131"
   },
   {
      "id":"7013125",
      "phuongxa":"Phường 14",
      "quanhuyen":"70131"
   },
   {
      "id":"7013127",
      "phuongxa":"Phường 15",
      "quanhuyen":"70131"
   },
   {
      "id":"7013129",
      "phuongxa":"Phường 17",
      "quanhuyen":"70131"
   },
   {
      "id":"7013300",
      "phuongxa":"Không xác định",
      "quanhuyen":"769"
   },
   {
      "id":"7013301",
      "phuongxa":"Phường Linh Đông",
      "quanhuyen":"769"
   },
   {
      "id":"7013303",
      "phuongxa":"Phường Hiệp Bình Chánh",
      "quanhuyen":"769"
   },
   {
      "id":"7013309",
      "phuongxa":"Phường Linh Xuân",
      "quanhuyen":"769"
   },
   {
      "id":"7013311",
      "phuongxa":"Phường Linh Chiểu",
      "quanhuyen":"769"
   },
   {
      "id":"7013313",
      "phuongxa":"Phường Trường Thọ",
      "quanhuyen":"769"
   },
   {
      "id":"7013315",
      "phuongxa":"Phường Bình Chiểu",
      "quanhuyen":"769"
   },
   {
      "id":"7013317",
      "phuongxa":"Phường Linh Tây",
      "quanhuyen":"769"
   },
   {
      "id":"7013319",
      "phuongxa":"Phường Bình Thọ",
      "quanhuyen":"769"
   },
   {
      "id":"7013321",
      "phuongxa":"Phường Tam Bình",
      "quanhuyen":"769"
   },
   {
      "id":"7013323",
      "phuongxa":"Phường Linh Trung",
      "quanhuyen":"769"
   },
   {
      "id":"7013500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70135"
   },
   {
      "id":"7150501",
      "phuongxa":"Thị trấn Chợ Lầu",
      "quanhuyen":"71505"
   },
   {
      "id":"7150503",
      "phuongxa":"Xã Phan Lâm",
      "quanhuyen":"71505"
   },
   {
      "id":"7150505",
      "phuongxa":"Xã Phan Điền",
      "quanhuyen":"71505"
   },
   {
      "id":"7150507",
      "phuongxa":"Xã Phan Hiệp",
      "quanhuyen":"71505"
   },
   {
      "id":"7150509",
      "phuongxa":"Xã Phan Hòa",
      "quanhuyen":"71505"
   },
   {
      "id":"7150511",
      "phuongxa":"Xã Hải Ninh",
      "quanhuyen":"71505"
   },
   {
      "id":"7150513",
      "phuongxa":"Xã Phan Rí Thành",
      "quanhuyen":"71505"
   },
   {
      "id":"7150515",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"71505"
   },
   {
      "id":"7150517",
      "phuongxa":"Xã Hồng Thái",
      "quanhuyen":"71505"
   },
   {
      "id":"7150519",
      "phuongxa":"Xã Phan Sơn",
      "quanhuyen":"71505"
   },
   {
      "id":"7150523",
      "phuongxa":"Xã Phan Thanh",
      "quanhuyen":"71505"
   },
   {
      "id":"7150525",
      "phuongxa":"Xã Hòa Thắng",
      "quanhuyen":"71505"
   },
   {
      "id":"7150527",
      "phuongxa":"Xã Sông Lũy",
      "quanhuyen":"71505"
   },
   {
      "id":"7150529",
      "phuongxa":"Xã Bình Tân",
      "quanhuyen":"71505"
   },
   {
      "id":"7150531",
      "phuongxa":"Xã Hồng Phong",
      "quanhuyen":"71505"
   },
   {
      "id":"7150533",
      "phuongxa":"Xã Phan Tiến",
      "quanhuyen":"71505"
   },
   {
      "id":"7150700",
      "phuongxa":"Không xác định",
      "quanhuyen":"71507"
   },
   {
      "id":"7150701",
      "phuongxa":"Xã Ma Lâm",
      "quanhuyen":"71507"
   },
   {
      "id":"7150703",
      "phuongxa":"Xã Hồng Liêm",
      "quanhuyen":"71507"
   },
   {
      "id":"7150707",
      "phuongxa":"Xã Đông Tiến",
      "quanhuyen":"71507"
   },
   {
      "id":"7150709",
      "phuongxa":"Xã Hàm Trí",
      "quanhuyen":"71507"
   },
   {
      "id":"7150711",
      "phuongxa":"Xã Hồng Sơn",
      "quanhuyen":"71507"
   },
   {
      "id":"7150713",
      "phuongxa":"Xã Hàm Phú",
      "quanhuyen":"71507"
   },
   {
      "id":"7150715",
      "phuongxa":"Xã Hàm Đức",
      "quanhuyen":"71507"
   },
   {
      "id":"7150717",
      "phuongxa":"Xã La Dạ",
      "quanhuyen":"71507"
   },
   {
      "id":"7150719",
      "phuongxa":"Xã Đông Giang",
      "quanhuyen":"71507"
   },
   {
      "id":"7150721",
      "phuongxa":"Xã Thuận Minh",
      "quanhuyen":"71507"
   },
   {
      "id":"7150723",
      "phuongxa":"Xã Hàm Chính",
      "quanhuyen":"71507"
   },
   {
      "id":"7150725",
      "phuongxa":"Xã Hàm Thắng",
      "quanhuyen":"71507"
   },
   {
      "id":"7150727",
      "phuongxa":"Xã Hàm Nhơn",
      "quanhuyen":"71507"
   },
   {
      "id":"7150729",
      "phuongxa":"Xã Hàm Liêm",
      "quanhuyen":"71507"
   },
   {
      "id":"7150731",
      "phuongxa":"Xã Hàm Hiệp",
      "quanhuyen":"71507"
   },
   {
      "id":"7150900",
      "phuongxa":"Không xác định",
      "quanhuyen":"71509"
   },
   {
      "id":"7150901",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"71509"
   },
   {
      "id":"7150903",
      "phuongxa":"Xã Mỹ Thạnh",
      "quanhuyen":"71509"
   },
   {
      "id":"7150905",
      "phuongxa":"Xã Hàm Cần",
      "quanhuyen":"71509"
   },
   {
      "id":"7150909",
      "phuongxa":"Xã Hàm Mỹ",
      "quanhuyen":"71509"
   },
   {
      "id":"7150911",
      "phuongxa":"Xã Hàm Thạnh",
      "quanhuyen":"71509"
   },
   {
      "id":"7150913",
      "phuongxa":"Xã Hàm Kiệm",
      "quanhuyen":"71509"
   },
   {
      "id":"7150915",
      "phuongxa":"Xã Hàm Cường",
      "quanhuyen":"71509"
   },
   {
      "id":"7150917",
      "phuongxa":"Xã Hàm Minh",
      "quanhuyen":"71509"
   },
   {
      "id":"7150919",
      "phuongxa":"Xã Thuận Quí",
      "quanhuyen":"71509"
   },
   {
      "id":"7150921",
      "phuongxa":"Xã Tân Thuận",
      "quanhuyen":"71509"
   },
   {
      "id":"7150923",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"71509"
   },
   {
      "id":"7151100",
      "phuongxa":"Không xác định",
      "quanhuyen":"71511"
   },
   {
      "id":"7151103",
      "phuongxa":"Xã Bắc Ruộng",
      "quanhuyen":"71511"
   },
   {
      "id":"7151105",
      "phuongxa":"Xã La Ngâu",
      "quanhuyen":"71511"
   },
   {
      "id":"7151107",
      "phuongxa":"Xã Đồng Kho",
      "quanhuyen":"71511"
   },
   {
      "id":"7151109",
      "phuongxa":"Xã Đức Bình",
      "quanhuyen":"71511"
   },
   {
      "id":"7151111",
      "phuongxa":"Xã Đức Thuận",
      "quanhuyen":"71511"
   },
   {
      "id":"7151113",
      "phuongxa":"Xã Suối Kiết",
      "quanhuyen":"71511"
   },
   {
      "id":"7151115",
      "phuongxa":"Xã Măng Tố",
      "quanhuyen":"71511"
   },
   {
      "id":"7151117",
      "phuongxa":"Xã Huy Khiêm",
      "quanhuyen":"71511"
   },
   {
      "id":"7151119",
      "phuongxa":"Xã Nghị Đức",
      "quanhuyen":"71511"
   },
   {
      "id":"7151121",
      "phuongxa":"Xã Đức Tân",
      "quanhuyen":"71511"
   },
   {
      "id":"7151125",
      "phuongxa":"Xã Gia An",
      "quanhuyen":"71511"
   },
   {
      "id":"7151127",
      "phuongxa":"Xã Gia Huynh",
      "quanhuyen":"71511"
   },
   {
      "id":"7151300",
      "phuongxa":"Không xác định",
      "quanhuyen":"71513"
   },
   {
      "id":"7151301",
      "phuongxa":"Thị trấn La Gi",
      "quanhuyen":"71513"
   },
   {
      "id":"7151303",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"71513"
   },
   {
      "id":"7151305",
      "phuongxa":"Xã Tân Nghĩa",
      "quanhuyen":"71513"
   },
   {
      "id":"7151307",
      "phuongxa":"Xã Tân Hải",
      "quanhuyen":"71513"
   },
   {
      "id":"7151309",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"71513"
   },
   {
      "id":"7151311",
      "phuongxa":"Xã Tân Minh",
      "quanhuyen":"71513"
   },
   {
      "id":"7151313",
      "phuongxa":"Xã Tân Hà",
      "quanhuyen":"71513"
   },
   {
      "id":"7151317",
      "phuongxa":"Xã Tân Thiện",
      "quanhuyen":"71513"
   },
   {
      "id":"7151319",
      "phuongxa":"Xã Sơn Mỹ",
      "quanhuyen":"71513"
   },
   {
      "id":"7151321",
      "phuongxa":"Xã Tân Thắng",
      "quanhuyen":"71513"
   },
   {
      "id":"7151500",
      "phuongxa":"Không xác định",
      "quanhuyen":"71515"
   },
   {
      "id":"7151501",
      "phuongxa":"Thị trấn Võ Xu",
      "quanhuyen":"71515"
   },
   {
      "id":"7151503",
      "phuongxa":"Xã Mê Pu",
      "quanhuyen":"71515"
   },
   {
      "id":"7151505",
      "phuongxa":"Xã Vũ Hoà",
      "quanhuyen":"71515"
   },
   {
      "id":"7151507",
      "phuongxa":"Xã Tân Hà",
      "quanhuyen":"71515"
   },
   {
      "id":"8071525",
      "phuongxa":"Xã Long Bình",
      "quanhuyen":"80715"
   },
   {
      "id":"8071527",
      "phuongxa":"Xã Tân Thới",
      "quanhuyen":"80715"
   },
   {
      "id":"8071531",
      "phuongxa":"Xã Phú Thạnh",
      "quanhuyen":"80715"
   },
   {
      "id":"8071700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80717"
   },
   {
      "id":"8071701",
      "phuongxa":"Thị trấn Tân Hòa",
      "quanhuyen":"80717"
   },
   {
      "id":"8071703",
      "phuongxa":"Xã Bình Đông",
      "quanhuyen":"80717"
   },
   {
      "id":"8071705",
      "phuongxa":"Xã Tân Phước",
      "quanhuyen":"80717"
   },
   {
      "id":"8071707",
      "phuongxa":"Xã Gia Thuận",
      "quanhuyen":"80717"
   },
   {
      "id":"8071709",
      "phuongxa":"Xã Bình Xuân",
      "quanhuyen":"80717"
   },
   {
      "id":"8071711",
      "phuongxa":"Thị trấn Vàm Láng",
      "quanhuyen":"80717"
   },
   {
      "id":"8071713",
      "phuongxa":"Xã Tân Tây",
      "quanhuyen":"80717"
   },
   {
      "id":"8071717",
      "phuongxa":"Xã Tân Trung",
      "quanhuyen":"80703"
   },
   {
      "id":"8071719",
      "phuongxa":"Xã Tân Đông",
      "quanhuyen":"80717"
   },
   {
      "id":"8071721",
      "phuongxa":"Xã Bình Ân",
      "quanhuyen":"80717"
   },
   {
      "id":"8071723",
      "phuongxa":"Xã Tân Điền",
      "quanhuyen":"80717"
   },
   {
      "id":"8071725",
      "phuongxa":"Xã Bình Nghị",
      "quanhuyen":"80717"
   },
   {
      "id":"8071727",
      "phuongxa":"Xã Phước Trung",
      "quanhuyen":"80717"
   },
   {
      "id":"8071729",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"80717"
   },
   {
      "id":"8071731",
      "phuongxa":"Xã Phú Đông",
      "quanhuyen":"80717"
   },
   {
      "id":"8071733",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"80717"
   },
   {
      "id":"807181",
      "phuongxa":"Tân Thới",
      "quanhuyen":"80718"
   },
   {
      "id":"8071883",
      "phuongxa":"Tân Phú",
      "quanhuyen":"80718"
   },
   {
      "id":"8090000",
      "phuongxa":"Không xác định",
      "quanhuyen":"80900"
   },
   {
      "id":"8090100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80901"
   },
   {
      "id":"8090101",
      "phuongxa":"Phường 1",
      "quanhuyen":"80901"
   },
   {
      "id":"8090105",
      "phuongxa":"Phường 3",
      "quanhuyen":"80901"
   },
   {
      "id":"8090107",
      "phuongxa":"Phường 4",
      "quanhuyen":"80901"
   },
   {
      "id":"8090109",
      "phuongxa":"Phường 5",
      "quanhuyen":"80901"
   },
   {
      "id":"8090111",
      "phuongxa":"Phường 8",
      "quanhuyen":"80901"
   },
   {
      "id":"8090113",
      "phuongxa":"Phường 9",
      "quanhuyen":"80901"
   },
   {
      "id":"8090115",
      "phuongxa":"Xã Trường An",
      "quanhuyen":"80901"
   },
   {
      "id":"8090117",
      "phuongxa":"Xã Tân Ngãi",
      "quanhuyen":"80901"
   },
   {
      "id":"8090119",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"80901"
   },
   {
      "id":"8090121",
      "phuongxa":"Xã Tân Hội",
      "quanhuyen":"80901"
   },
   {
      "id":"8090300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80903"
   },
   {
      "id":"8090301",
      "phuongxa":"Thị trấn Long Hồ",
      "quanhuyen":"80903"
   },
   {
      "id":"8090303",
      "phuongxa":"Xã Đồng Phú",
      "quanhuyen":"80903"
   },
   {
      "id":"8090305",
      "phuongxa":"Xã Hòa Ninh",
      "quanhuyen":"80903"
   },
   {
      "id":"8090309",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"80903"
   },
   {
      "id":"8090311",
      "phuongxa":"Xã Thanh Đức",
      "quanhuyen":"80903"
   },
   {
      "id":"8090313",
      "phuongxa":"Xã Tân Hạnh",
      "quanhuyen":"80903"
   },
   {
      "id":"8090315",
      "phuongxa":"Xã Long Phước",
      "quanhuyen":"80903"
   },
   {
      "id":"8090317",
      "phuongxa":"Xã Phước Hậu",
      "quanhuyen":"80903"
   },
   {
      "id":"8090319",
      "phuongxa":"Xã Lộc Hòa",
      "quanhuyen":"80903"
   },
   {
      "id":"8090321",
      "phuongxa":"Xã Phú Đức",
      "quanhuyen":"80903"
   },
   {
      "id":"8090323",
      "phuongxa":"Xã Thạnh Quới",
      "quanhuyen":"80903"
   },
   {
      "id":"8090325",
      "phuongxa":"Xã Phú Quới",
      "quanhuyen":"80903"
   },
   {
      "id":"8090327",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"80903"
   },
   {
      "id":"8090329",
      "phuongxa":"Xã Long An",
      "quanhuyen":"80903"
   },
   {
      "id":"8090501",
      "phuongxa":"Thị trấn Cái Nhum",
      "quanhuyen":"80905"
   },
   {
      "id":"8090503",
      "phuongxa":"Xã Mỹ An",
      "quanhuyen":"80905"
   },
   {
      "id":"8090505",
      "phuongxa":"Xã Long Mỹ",
      "quanhuyen":"80905"
   },
   {
      "id":"8090507",
      "phuongxa":"Xã Hòa Tịnh",
      "quanhuyen":"80905"
   },
   {
      "id":"8090509",
      "phuongxa":"Xã Bình Phước",
      "quanhuyen":"80905"
   },
   {
      "id":"8090511",
      "phuongxa":"Xã Nhơn Phú",
      "quanhuyen":"80905"
   },
   {
      "id":"8090513",
      "phuongxa":"Xã Mỹ Phước",
      "quanhuyen":"80905"
   },
   {
      "id":"8090515",
      "phuongxa":"Xã Chánh Hội",
      "quanhuyen":"80905"
   },
   {
      "id":"8090517",
      "phuongxa":"Xã An Phước",
      "quanhuyen":"80905"
   },
   {
      "id":"8090519",
      "phuongxa":"Xã Chánh An",
      "quanhuyen":"80905"
   },
   {
      "id":"8090521",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"80905"
   },
   {
      "id":"8090523",
      "phuongxa":"Xã Tân An Hội",
      "quanhuyen":"80905"
   },
   {
      "id":"8090700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80907"
   },
   {
      "id":"8090701",
      "phuongxa":"Phường Cái Vồn",
      "quanhuyen":"80907"
   },
   {
      "id":"8090703",
      "phuongxa":"Xã Nguyễn Văn Thảnh",
      "quanhuyen":"80907"
   },
   {
      "id":"8090705",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"80907"
   },
   {
      "id":"8090707",
      "phuongxa":"Xã Thành Trung",
      "quanhuyen":"80907"
   },
   {
      "id":"8090709",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"80907"
   },
   {
      "id":"8090711",
      "phuongxa":"Xã Mỹ Thuận",
      "quanhuyen":"80907"
   },
   {
      "id":"8090713",
      "phuongxa":"Xã Thuận An",
      "quanhuyen":"80907"
   },
   {
      "id":"8090715",
      "phuongxa":"Xã Đông Thạnh",
      "quanhuyen":"80907"
   },
   {
      "id":"8230905",
      "phuongxa":"Xã Lương Thế Trân",
      "quanhuyen":"82309"
   },
   {
      "id":"8230907",
      "phuongxa":"Xã Phú Hưng",
      "quanhuyen":"82309"
   },
   {
      "id":"8230909",
      "phuongxa":"Xã Hưng Mỹ",
      "quanhuyen":"82309"
   },
   {
      "id":"8230911",
      "phuongxa":"Xã Đông Thới",
      "quanhuyen":"82309"
   },
   {
      "id":"8230913",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"82309"
   },
   {
      "id":"8230915",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"82309"
   },
   {
      "id":"8230917",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"82309"
   },
   {
      "id":"8230919",
      "phuongxa":"Xã Tân Hưng Đông",
      "quanhuyen":"82309"
   },
   {
      "id":"8230921",
      "phuongxa":"Xã Tân Hưng Tây",
      "quanhuyen":"82309"
   },
   {
      "id":"8230925",
      "phuongxa":"Xã Việt Khái",
      "quanhuyen":"82309"
   },
   {
      "id":"8231100",
      "phuongxa":"Không xác định",
      "quanhuyen":"82311"
   },
   {
      "id":"8231101",
      "phuongxa":"Thị trấn Đầm Dơi",
      "quanhuyen":"82311"
   },
   {
      "id":"8231103",
      "phuongxa":"Xã Tân Thuận",
      "quanhuyen":"82311"
   },
   {
      "id":"8231105",
      "phuongxa":"Xã Tân Đức",
      "quanhuyen":"82311"
   },
   {
      "id":"8231107",
      "phuongxa":"Xã Trần Phán",
      "quanhuyen":"82311"
   },
   {
      "id":"8231109",
      "phuongxa":"Xã Tạ An Khương",
      "quanhuyen":"82311"
   },
   {
      "id":"8231111",
      "phuongxa":"Xã Tân Duyệt",
      "quanhuyen":"82311"
   },
   {
      "id":"8231113",
      "phuongxa":"Xã Quách Phẩm",
      "quanhuyen":"82311"
   },
   {
      "id":"8231117",
      "phuongxa":"Xã Nguyễn Huân",
      "quanhuyen":"82311"
   },
   {
      "id":"8231119",
      "phuongxa":"Xã Thanh Tùng",
      "quanhuyen":"82311"
   },
   {
      "id":"8231300",
      "phuongxa":"Không xác định",
      "quanhuyen":"82313"
   },
   {
      "id":"8231303",
      "phuongxa":"Xã Hàm Rồng",
      "quanhuyen":"82313"
   },
   {
      "id":"8231305",
      "phuongxa":"Xã Hiệp Tùng",
      "quanhuyen":"82313"
   },
   {
      "id":"8231307",
      "phuongxa":"Xã Tam Giang Tây ",
      "quanhuyen":"82313"
   },
   {
      "id":"8231309",
      "phuongxa":"Xã Hàng Vịnh",
      "quanhuyen":"82313"
   },
   {
      "id":"8231311",
      "phuongxa":"Xã Đất Mới",
      "quanhuyen":"82313"
   },
   {
      "id":"8231313",
      "phuongxa":"Xã Tân Ân",
      "quanhuyen":"82313"
   },
   {
      "id":"8231315",
      "phuongxa":"Xã Viên An Đông",
      "quanhuyen":"82313"
   },
   {
      "id":"8231317",
      "phuongxa":"Xã Viên An",
      "quanhuyen":"82313"
   },
   {
      "id":"8231319",
      "phuongxa":"Xã Đất Mũi",
      "quanhuyen":"82313"
   },
   {
      "id":"8231320",
      "phuongxa":"Xã Đất Mũi",
      "quanhuyen":"82313"
   },
   {
      "id":"823141",
      "phuongxa":"Xã Đất Mới",
      "quanhuyen":"82314"
   },
   {
      "id":"8231442",
      "phuongxa":"hàm rồng",
      "quanhuyen":"82314"
   },
   {
      "id":"8250000",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"82500"
   },
   {
      "id":"8258201",
      "phuongxa":"Nhơn Nghĩa",
      "quanhuyen":"82582"
   },
   {
      "id":"8258202",
      "phuongxa":"Đông Thạnh",
      "quanhuyen":"82582"
   },
   {
      "id":"8258203",
      "phuongxa":"Phú Hữu",
      "quanhuyen":"82582"
   },
   {
      "id":"8258204",
      "phuongxa":"Đông Thạnh A",
      "quanhuyen":"82582"
   },
   {
      "id":"8258205",
      "phuongxa":"Trường Long A",
      "quanhuyen":"82582"
   },
   {
      "id":"8258206",
      "phuongxa":"Xã Phú An",
      "quanhuyen":"82582"
   },
   {
      "id":"8258303",
      "phuongxa":"phường 7",
      "quanhuyen":"82583"
   },
   {
      "id":"8258304",
      "phuongxa":"Xã Hỏa Tiến",
      "quanhuyen":"82583"
   },
   {
      "id":"8258503",
      "phuongxa":"Vĩnh Tường",
      "quanhuyen":"82585"
   },
   {
      "id":"8258505",
      "phuongxa":"Vị Thanh",
      "quanhuyen":"82585"
   },
   {
      "id":"8258506",
      "phuongxa":"Vị Trung",
      "quanhuyen":"82585"
   },
   {
      "id":"8258601",
      "phuongxa":"Thị Trấn Long Mỹ",
      "quanhuyen":"82586"
   },
   {
      "id":"8258662",
      "phuongxa":"Thuận Hưng",
      "quanhuyen":"82586"
   },
   {
      "id":"8258663",
      "phuongxa":"Thị Trấn Long Mỹ",
      "quanhuyen":"82586"
   },
   {
      "id":"8258600",
      "phuongxa":"Không xác định",
      "quanhuyen":"82586"
   },
   {
      "id":"8258701",
      "phuongxa":"Thị Trấn Bảy Ngàn",
      "quanhuyen":"82587"
   },
   {
      "id":"8258702",
      "phuongxa":"Tân Hòa",
      "quanhuyen":"82587"
   },
   {
      "id":"7013505",
      "phuongxa":"Xã Tân Thạnh Đông",
      "quanhuyen":"70135"
   },
   {
      "id":"7013507",
      "phuongxa":"Xã Tân Thạnh Tây",
      "quanhuyen":"70135"
   },
   {
      "id":"7013509",
      "phuongxa":"Xã Trung An",
      "quanhuyen":"70135"
   },
   {
      "id":"7013511",
      "phuongxa":"Xã Phước Vĩnh An",
      "quanhuyen":"70135"
   },
   {
      "id":"7013513",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"70135"
   },
   {
      "id":"7013515",
      "phuongxa":"Xã Tân An Hội",
      "quanhuyen":"70135"
   },
   {
      "id":"7013517",
      "phuongxa":"Xã Tân Thông Hội",
      "quanhuyen":"70135"
   },
   {
      "id":"7013519",
      "phuongxa":"Xã Tân Phú Trung",
      "quanhuyen":"70135"
   },
   {
      "id":"7013523",
      "phuongxa":"Xã Phước Thạnh",
      "quanhuyen":"70135"
   },
   {
      "id":"7013525",
      "phuongxa":"Xã An Nhơn Tây",
      "quanhuyen":"70135"
   },
   {
      "id":"7013527",
      "phuongxa":"Xã Trung Lập Thượng",
      "quanhuyen":"70135"
   },
   {
      "id":"7013529",
      "phuongxa":"Xã Phú Mỹ Hưng",
      "quanhuyen":"70135"
   },
   {
      "id":"7013531",
      "phuongxa":"Xã An Phú",
      "quanhuyen":"70135"
   },
   {
      "id":"7013533",
      "phuongxa":"Xã Nhuận Đức",
      "quanhuyen":"70135"
   },
   {
      "id":"7013535",
      "phuongxa":"Xã Phạm Văn Cội",
      "quanhuyen":"70135"
   },
   {
      "id":"7013537",
      "phuongxa":"Xã Bình Mỹ",
      "quanhuyen":"70135"
   },
   {
      "id":"7013541",
      "phuongxa":"Xã Trung Lập Hạ",
      "quanhuyen":"70135"
   },
   {
      "id":"7013542",
      "phuongxa":"Tân Thạnh Đông",
      "quanhuyen":"70135"
   },
   {
      "id":"7013700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70137"
   },
   {
      "id":"7013701",
      "phuongxa":"Thị trấn Hóc Môn",
      "quanhuyen":"70137"
   },
   {
      "id":"7013703",
      "phuongxa":"Xã Tân Thới Nhì",
      "quanhuyen":"70137"
   },
   {
      "id":"7013705",
      "phuongxa":"Xã Tân Hiệp",
      "quanhuyen":"70137"
   },
   {
      "id":"7013707",
      "phuongxa":"Xã Thới Tam Thôn",
      "quanhuyen":"70137"
   },
   {
      "id":"7013711",
      "phuongxa":"Xã Nhị Bình",
      "quanhuyen":"70137"
   },
   {
      "id":"7013713",
      "phuongxa":"Xã Xuân Thới Sơn",
      "quanhuyen":"70137"
   },
   {
      "id":"7013715",
      "phuongxa":"Xã Tân Xuân",
      "quanhuyen":"70137"
   },
   {
      "id":"7013717",
      "phuongxa":"Xã Xuân Thới Thượng",
      "quanhuyen":"70137"
   },
   {
      "id":"7013719",
      "phuongxa":"Xã Bà Điểm",
      "quanhuyen":"70137"
   },
   {
      "id":"7013900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70139"
   },
   {
      "id":"7013901",
      "phuongxa":"Thị trấn An Lạc",
      "quanhuyen":"70139"
   },
   {
      "id":"7013903",
      "phuongxa":"Xã Bình Hưng",
      "quanhuyen":"70139"
   },
   {
      "id":"7013905",
      "phuongxa":"Xã Phong Phú",
      "quanhuyen":"70139"
   },
   {
      "id":"7013909",
      "phuongxa":"Xã Qui Đức",
      "quanhuyen":"70139"
   },
   {
      "id":"7013911",
      "phuongxa":"Xã Hưng Long",
      "quanhuyen":"70139"
   },
   {
      "id":"7013913",
      "phuongxa":"Xã Tân Quý Tây",
      "quanhuyen":"70139"
   },
   {
      "id":"7013915",
      "phuongxa":"Xã Bình Chánh",
      "quanhuyen":"70139"
   },
   {
      "id":"7013917",
      "phuongxa":"Xã Bình Trị Đông",
      "quanhuyen":"70139"
   },
   {
      "id":"7013919",
      "phuongxa":"Xã Tân Tạo",
      "quanhuyen":"70139"
   },
   {
      "id":"7013921",
      "phuongxa":"Xã Bình Hưng Hòa",
      "quanhuyen":"70139"
   },
   {
      "id":"7013923",
      "phuongxa":"Xã Vĩnh Lộc A",
      "quanhuyen":"70139"
   },
   {
      "id":"7013925",
      "phuongxa":"Xã Vĩnh Lộc B",
      "quanhuyen":"70139"
   },
   {
      "id":"7013927",
      "phuongxa":"Xã Phạm Văn Hai",
      "quanhuyen":"70139"
   },
   {
      "id":"7013929",
      "phuongxa":"Xã Lê Minh Xuân",
      "quanhuyen":"70139"
   },
   {
      "id":"7013931",
      "phuongxa":"Xã Bình Lợi",
      "quanhuyen":"70139"
   },
   {
      "id":"7013933",
      "phuongxa":"Xã Tân Nhựt",
      "quanhuyen":"70139"
   },
   {
      "id":"7013935",
      "phuongxa":"Thị trấn Tân Túc",
      "quanhuyen":"70139"
   },
   {
      "id":"7013937",
      "phuongxa":"Xã Tân Kiên",
      "quanhuyen":"70139"
   },
   {
      "id":"7013939",
      "phuongxa":"Xã An Phú Tây",
      "quanhuyen":"70139"
   },
   {
      "id":"7013940",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"70139"
   },
   {
      "id":"7014100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70141"
   },
   {
      "id":"7014101",
      "phuongxa":"Thị trấn Nhà Bè",
      "quanhuyen":"70141"
   },
   {
      "id":"7014103",
      "phuongxa":"Xã Phú Xuân",
      "quanhuyen":"70141"
   },
   {
      "id":"7014105",
      "phuongxa":"Xã Phước Kiển",
      "quanhuyen":"70141"
   },
   {
      "id":"7014109",
      "phuongxa":"Xã Nhơn Đức",
      "quanhuyen":"70141"
   },
   {
      "id":"7014111",
      "phuongxa":"Xã Long Thới",
      "quanhuyen":"70141"
   },
   {
      "id":"7014113",
      "phuongxa":"Xã Hiệp Phước",
      "quanhuyen":"70141"
   },
   {
      "id":"7014300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70143"
   },
   {
      "id":"7014301",
      "phuongxa":"Thị trấn Cần Thạnh",
      "quanhuyen":"70143"
   },
   {
      "id":"7014303",
      "phuongxa":"Xã Long Hòa",
      "quanhuyen":"70143"
   },
   {
      "id":"7014305",
      "phuongxa":"Xã Lý Nhơn",
      "quanhuyen":"70143"
   },
   {
      "id":"7014307",
      "phuongxa":"Xã Thạnh An",
      "quanhuyen":"70143"
   },
   {
      "id":"7014311",
      "phuongxa":"Xã Bình Khánh",
      "quanhuyen":"70143"
   },
   {
      "id":"7014313",
      "phuongxa":"Xã An Thới Đông",
      "quanhuyen":"70143"
   },
   {
      "id":"7014500",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"70145"
   },
   {
      "id":"7014501",
      "phuongxa":"Tân Quý",
      "quanhuyen":"70145"
   },
   {
      "id":"7014503",
      "phuongxa":"Hòa Thạnh",
      "quanhuyen":"70145"
   },
   {
      "id":"7014505",
      "phuongxa":"Hiệp Tân",
      "quanhuyen":"70145"
   },
   {
      "id":"7014507",
      "phuongxa":"Phú Thạnh",
      "quanhuyen":"70145"
   },
   {
      "id":"7151509",
      "phuongxa":"Xã Trà Tân",
      "quanhuyen":"71515"
   },
   {
      "id":"7151511",
      "phuongxa":"Xã Sùng Nhơn",
      "quanhuyen":"71515"
   },
   {
      "id":"7151513",
      "phuongxa":"Xã Nam Chính",
      "quanhuyen":"71515"
   },
   {
      "id":"7151515",
      "phuongxa":"Xã Đức Chính",
      "quanhuyen":"71515"
   },
   {
      "id":"7151519",
      "phuongxa":"Xã Đa Kai",
      "quanhuyen":"71515"
   },
   {
      "id":"7151521",
      "phuongxa":"Xã Đức Hạnh",
      "quanhuyen":"71515"
   },
   {
      "id":"7151700",
      "phuongxa":"Không xác định",
      "quanhuyen":"71517"
   },
   {
      "id":"7151701",
      "phuongxa":"Xã Ngũ Phụng",
      "quanhuyen":"71517"
   },
   {
      "id":"7151703",
      "phuongxa":"Xã Long Hải",
      "quanhuyen":"71517"
   },
   {
      "id":"7151705",
      "phuongxa":"Xã Tam Thanh",
      "quanhuyen":"71517"
   },
   {
      "id":"7151900",
      "phuongxa":"Không xác định",
      "quanhuyen":"71519"
   },
   {
      "id":"7151901",
      "phuongxa":"Phường Phước Hội",
      "quanhuyen":"71519"
   },
   {
      "id":"7151905",
      "phuongxa":"Phường Tân Thiện",
      "quanhuyen":"71519"
   },
   {
      "id":"7151907",
      "phuongxa":"Phường Tân An",
      "quanhuyen":"71519"
   },
   {
      "id":"7151909",
      "phuongxa":"Phường Bình Tân",
      "quanhuyen":"71519"
   },
   {
      "id":"7151911",
      "phuongxa":"Xã Tân Hải",
      "quanhuyen":"71519"
   },
   {
      "id":"7151913",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"71519"
   },
   {
      "id":"7151915",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"71519"
   },
   {
      "id":"7151917",
      "phuongxa":"Xã Tân Phước",
      "quanhuyen":"71519"
   },
   {
      "id":"7170000",
      "phuongxa":"Không xác định",
      "quanhuyen":"71700"
   },
   {
      "id":"7170100",
      "phuongxa":"Không xác định",
      "quanhuyen":"71701"
   },
   {
      "id":"7170101",
      "phuongxa":"Phường 1",
      "quanhuyen":"71701"
   },
   {
      "id":"7170103",
      "phuongxa":"Phường 2",
      "quanhuyen":"71701"
   },
   {
      "id":"7170105",
      "phuongxa":"Phường 3",
      "quanhuyen":"71701"
   },
   {
      "id":"7170107",
      "phuongxa":"Phường 4",
      "quanhuyen":"71701"
   },
   {
      "id":"7170109",
      "phuongxa":"Phường 5",
      "quanhuyen":"71701"
   },
   {
      "id":"7170111",
      "phuongxa":"Phường 6",
      "quanhuyen":"71701"
   },
   {
      "id":"7170113",
      "phuongxa":"Phường 7",
      "quanhuyen":"71701"
   },
   {
      "id":"7170117",
      "phuongxa":"Phường 9",
      "quanhuyen":"71701"
   },
   {
      "id":"7170119",
      "phuongxa":"Phường 10",
      "quanhuyen":"71701"
   },
   {
      "id":"7170121",
      "phuongxa":"Phường 11",
      "quanhuyen":"71701"
   },
   {
      "id":"7170123",
      "phuongxa":"Xã Long Sơn",
      "quanhuyen":"71701"
   },
   {
      "id":"7170301",
      "phuongxa":"Phường Phước Hưng",
      "quanhuyen":"71703"
   },
   {
      "id":"7170303",
      "phuongxa":"Phường Phước Hiệp",
      "quanhuyen":"71703"
   },
   {
      "id":"7170305",
      "phuongxa":"Phường Phước Nguyên",
      "quanhuyen":"71703"
   },
   {
      "id":"7170307",
      "phuongxa":"Phường Long Toàn",
      "quanhuyen":"71703"
   },
   {
      "id":"7170311",
      "phuongxa":"Xã Long Phước",
      "quanhuyen":"71703"
   },
   {
      "id":"7170313",
      "phuongxa":"Xã Hoà Long",
      "quanhuyen":"71703"
   },
   {
      "id":"7170315",
      "phuongxa":"Xã Long Hương",
      "quanhuyen":"71703"
   },
   {
      "id":"7170500",
      "phuongxa":"Không xác định",
      "quanhuyen":"71705"
   },
   {
      "id":"7170501",
      "phuongxa":"Thị trấn Ngãi Giao",
      "quanhuyen":"71705"
   },
   {
      "id":"7170503",
      "phuongxa":"Xã Bình Ba",
      "quanhuyen":"71705"
   },
   {
      "id":"7170505",
      "phuongxa":"Xã Suối Nghệ",
      "quanhuyen":"71705"
   },
   {
      "id":"7170507",
      "phuongxa":"Xã Xuân Sơn",
      "quanhuyen":"71705"
   },
   {
      "id":"7170509",
      "phuongxa":"Xã Bình Giã",
      "quanhuyen":"71705"
   },
   {
      "id":"7170511",
      "phuongxa":"Xã Xà Bang",
      "quanhuyen":"71705"
   },
   {
      "id":"7170513",
      "phuongxa":"Xã Láng Lớn",
      "quanhuyen":"71705"
   },
   {
      "id":"7170515",
      "phuongxa":"Xã Quảng Thành",
      "quanhuyen":"71705"
   },
   {
      "id":"7170517",
      "phuongxa":"Xã Kim Long",
      "quanhuyen":"71705"
   },
   {
      "id":"7170519",
      "phuongxa":"Xã Suối Rao",
      "quanhuyen":"71705"
   },
   {
      "id":"7170521",
      "phuongxa":"Xã Đá Bạc",
      "quanhuyen":"71705"
   },
   {
      "id":"7170700",
      "phuongxa":"Không xác định",
      "quanhuyen":"71707"
   },
   {
      "id":"7170701",
      "phuongxa":"Thị trấn Phước Bửu",
      "quanhuyen":"71707"
   },
   {
      "id":"7170703",
      "phuongxa":"Xã Phước Thuận",
      "quanhuyen":"71707"
   },
   {
      "id":"7170705",
      "phuongxa":"Xã Phước Tân",
      "quanhuyen":"71707"
   },
   {
      "id":"7170707",
      "phuongxa":"Xã Xuyên Mộc",
      "quanhuyen":"71707"
   },
   {
      "id":"7170709",
      "phuongxa":"Xã Bông Trang",
      "quanhuyen":"71707"
   },
   {
      "id":"7170711",
      "phuongxa":"Xã Bàu Lâm",
      "quanhuyen":"71707"
   },
   {
      "id":"7170713",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"71707"
   },
   {
      "id":"7170715",
      "phuongxa":"Xã Hòa Hưng",
      "quanhuyen":"71707"
   },
   {
      "id":"7170719",
      "phuongxa":"Xã Hòa Hội",
      "quanhuyen":"71707"
   },
   {
      "id":"7170721",
      "phuongxa":"Xã Bưng Riềng",
      "quanhuyen":"71707"
   },
   {
      "id":"7170723",
      "phuongxa":"Xã Bình Châu",
      "quanhuyen":"71707"
   },
   {
      "id":"7170900",
      "phuongxa":"Không xác định",
      "quanhuyen":"71709"
   },
   {
      "id":"7170901",
      "phuongxa":"Phường Phú Mỹ",
      "quanhuyen":"71709"
   },
   {
      "id":"7170903",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"71709"
   },
   {
      "id":"7170905",
      "phuongxa":"Phường Phước Hoà",
      "quanhuyen":"71709"
   },
   {
      "id":"7170907",
      "phuongxa":"Phường Mỹ Xuân",
      "quanhuyen":"71709"
   },
   {
      "id":"7170909",
      "phuongxa":"Xã Sông Xoài",
      "quanhuyen":"71709"
   },
   {
      "id":"7170911",
      "phuongxa":"Phường Hắc Dịch",
      "quanhuyen":"71709"
   },
   {
      "id":"8090721",
      "phuongxa":"Xã Tân Lược",
      "quanhuyen":"80907"
   },
   {
      "id":"8090723",
      "phuongxa":"Xã Tân An Thạnh",
      "quanhuyen":"80907"
   },
   {
      "id":"8090725",
      "phuongxa":"Xã Thành Lợi",
      "quanhuyen":"80907"
   },
   {
      "id":"8090727",
      "phuongxa":"Xã Tân Quới",
      "quanhuyen":"80907"
   },
   {
      "id":"8090729",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"80907"
   },
   {
      "id":"8090731",
      "phuongxa":"Xã Đông Thành",
      "quanhuyen":"80907"
   },
   {
      "id":"8090732",
      "phuongxa":"Phường Đông Thuận",
      "quanhuyen":"80907"
   },
   {
      "id":"8090733",
      "phuongxa":"Xã Mỹ Hòa",
      "quanhuyen":"80907"
   },
   {
      "id":"8090772",
      "phuongxa":"Phường Thành Phước",
      "quanhuyen":"80907"
   },
   {
      "id":"8090903",
      "phuongxa":"Xã Hòa Thạnh",
      "quanhuyen":"80909"
   },
   {
      "id":"8090907",
      "phuongxa":"Xã Hòa Hiệp",
      "quanhuyen":"80909"
   },
   {
      "id":"8090909",
      "phuongxa":"Xã Hậu Lộc",
      "quanhuyen":"80909"
   },
   {
      "id":"8090911",
      "phuongxa":"Xã Hòa Lộc",
      "quanhuyen":"80909"
   },
   {
      "id":"8090913",
      "phuongxa":"Xã Phú Lộc",
      "quanhuyen":"80909"
   },
   {
      "id":"8090915",
      "phuongxa":"Xã Mỹ Lộc",
      "quanhuyen":"80909"
   },
   {
      "id":"8090917",
      "phuongxa":"Xã Phú Thịnh",
      "quanhuyen":"80909"
   },
   {
      "id":"8090919",
      "phuongxa":"Xã Song Phú",
      "quanhuyen":"80909"
   },
   {
      "id":"8090923",
      "phuongxa":"Xã Tường Lộc",
      "quanhuyen":"80909"
   },
   {
      "id":"8090925",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"80909"
   },
   {
      "id":"8090927",
      "phuongxa":"Xã Long Phú",
      "quanhuyen":"80909"
   },
   {
      "id":"8090929",
      "phuongxa":"Xã Loan Mỹ",
      "quanhuyen":"80909"
   },
   {
      "id":"8090931",
      "phuongxa":"Xã Ngãi Tứ",
      "quanhuyen":"80909"
   },
   {
      "id":"8090933",
      "phuongxa":"Xã Bình Ninh",
      "quanhuyen":"80909"
   },
   {
      "id":"8091100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80911"
   },
   {
      "id":"8091101",
      "phuongxa":"Thị trấn Trà Ôn",
      "quanhuyen":"80911"
   },
   {
      "id":"8091103",
      "phuongxa":"Xã Xuân Hiệp",
      "quanhuyen":"80911"
   },
   {
      "id":"8091105",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"80911"
   },
   {
      "id":"8091107",
      "phuongxa":"Xã Nhơn Bình",
      "quanhuyen":"80911"
   },
   {
      "id":"8091109",
      "phuongxa":"Xã Thới Hòa",
      "quanhuyen":"80911"
   },
   {
      "id":"8091113",
      "phuongxa":"Xã Thuận Thới",
      "quanhuyen":"80911"
   },
   {
      "id":"8091115",
      "phuongxa":"Xã Trà Côn",
      "quanhuyen":"80911"
   },
   {
      "id":"8091117",
      "phuongxa":"Xã Vĩnh Xuân",
      "quanhuyen":"80911"
   },
   {
      "id":"8091119",
      "phuongxa":"Xã Thiện Mỹ",
      "quanhuyen":"80911"
   },
   {
      "id":"8091121",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"80911"
   },
   {
      "id":"8091123",
      "phuongxa":"Xã Tích Thiện",
      "quanhuyen":"80911"
   },
   {
      "id":"8091125",
      "phuongxa":"Xã Lục Sỹ Thành",
      "quanhuyen":"80911"
   },
   {
      "id":"8091127",
      "phuongxa":"Xã Phú Thành",
      "quanhuyen":"80911"
   },
   {
      "id":"8091300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80913"
   },
   {
      "id":"8091301",
      "phuongxa":"Thị trấn Vũng Liêm",
      "quanhuyen":"80913"
   },
   {
      "id":"8091303",
      "phuongxa":"Xã Tân Quới Trung",
      "quanhuyen":"80913"
   },
   {
      "id":"8091305",
      "phuongxa":"Xã Quới An",
      "quanhuyen":"80913"
   },
   {
      "id":"8091307",
      "phuongxa":"Xã Quới Thiện",
      "quanhuyen":"80913"
   },
   {
      "id":"8091309",
      "phuongxa":"Xã Trung Chánh",
      "quanhuyen":"80913"
   },
   {
      "id":"8091311",
      "phuongxa":"Xã Trung Hiệp",
      "quanhuyen":"80913"
   },
   {
      "id":"8091313",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"80913"
   },
   {
      "id":"8091315",
      "phuongxa":"Xã Trung Thành Tây",
      "quanhuyen":"80913"
   },
   {
      "id":"8091317",
      "phuongxa":"Xã Tân An Luông",
      "quanhuyen":"80913"
   },
   {
      "id":"8091323",
      "phuongxa":"Xã Trung Hiếu",
      "quanhuyen":"80913"
   },
   {
      "id":"8091325",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"80913"
   },
   {
      "id":"8091327",
      "phuongxa":"Xã Trung Ngãi",
      "quanhuyen":"80913"
   },
   {
      "id":"8091329",
      "phuongxa":"Xã Trung Nghĩa",
      "quanhuyen":"80913"
   },
   {
      "id":"8091331",
      "phuongxa":"Xã Hiếu Thuận",
      "quanhuyen":"80913"
   },
   {
      "id":"8091333",
      "phuongxa":"Xã Hiếu Nhơn",
      "quanhuyen":"80913"
   },
   {
      "id":"8091335",
      "phuongxa":"Xã Trung An",
      "quanhuyen":"80913"
   },
   {
      "id":"8091337",
      "phuongxa":"Xã Hiếu Thành",
      "quanhuyen":"80913"
   },
   {
      "id":"8091339",
      "phuongxa":"Xã Hiếu Nghĩa",
      "quanhuyen":"80913"
   },
   {
      "id":"8091500",
      "phuongxa":"Xã Mỹ Thuận",
      "quanhuyen":"80915"
   },
   {
      "id":"8091503",
      "phuongxa":"Xã Thành Lợi",
      "quanhuyen":"80915"
   },
   {
      "id":"8091505",
      "phuongxa":"Xã Thành Đông",
      "quanhuyen":"80915"
   },
   {
      "id":"8091507",
      "phuongxa":"Xã Thành Trung",
      "quanhuyen":"80915"
   },
   {
      "id":"8091509",
      "phuongxa":"Xã Tân Quới",
      "quanhuyen":"80915"
   },
   {
      "id":"8091511",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"80915"
   },
   {
      "id":"8091513",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"80915"
   },
   {
      "id":"8091515",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"80915"
   },
   {
      "id":"8091517",
      "phuongxa":"Xã Tân Lược",
      "quanhuyen":"80915"
   },
   {
      "id":"8091519",
      "phuongxa":"Xã Tân An Thạnh",
      "quanhuyen":"80915"
   },
   {
      "id":"7014509",
      "phuongxa":"Phú Trung",
      "quanhuyen":"70145"
   },
   {
      "id":"7014511",
      "phuongxa":"Phú Thọ Hòa",
      "quanhuyen":"70145"
   },
   {
      "id":"7014515",
      "phuongxa":"Tân Sơn Nhì",
      "quanhuyen":"70145"
   },
   {
      "id":"7014517",
      "phuongxa":"Tân Thành",
      "quanhuyen":"70145"
   },
   {
      "id":"7014519",
      "phuongxa":"Tân Thới Hoà",
      "quanhuyen":"70145"
   },
   {
      "id":"7014700",
      "phuongxa":"Không Xác Định",
      "quanhuyen":"70147"
   },
   {
      "id":"7014701",
      "phuongxa":"Phường An Lạc",
      "quanhuyen":"70147"
   },
   {
      "id":"7014703",
      "phuongxa":"Phường An Lạc A",
      "quanhuyen":"70147"
   },
   {
      "id":"7014707",
      "phuongxa":"Bình Hưng Hoà B",
      "quanhuyen":"70147"
   },
   {
      "id":"7014709",
      "phuongxa":"Bình Hưng Hoà",
      "quanhuyen":"70147"
   },
   {
      "id":"7014711",
      "phuongxa":"Bình Trị Đông A",
      "quanhuyen":"70147"
   },
   {
      "id":"7014713",
      "phuongxa":"Bình Trị Đông B",
      "quanhuyen":"70147"
   },
   {
      "id":"7014715",
      "phuongxa":"Bình Trị Đông",
      "quanhuyen":"70147"
   },
   {
      "id":"7014717",
      "phuongxa":"Tân Tạo",
      "quanhuyen":"70147"
   },
   {
      "id":"7014719",
      "phuongxa":"Tân Tạo A",
      "quanhuyen":"70147"
   },
   {
      "id":"7030000",
      "phuongxa":"Không xác định",
      "quanhuyen":"70300"
   },
   {
      "id":"7030101",
      "phuongxa":"Phường 1",
      "quanhuyen":"70301"
   },
   {
      "id":"7030103",
      "phuongxa":"Phường 2",
      "quanhuyen":"70301"
   },
   {
      "id":"7030105",
      "phuongxa":"Phường 3",
      "quanhuyen":"70301"
   },
   {
      "id":"7030107",
      "phuongxa":"Phường 4",
      "quanhuyen":"70301"
   },
   {
      "id":"7030109",
      "phuongxa":"Phường 5",
      "quanhuyen":"70301"
   },
   {
      "id":"7030111",
      "phuongxa":"Phường 6",
      "quanhuyen":"70301"
   },
   {
      "id":"7030113",
      "phuongxa":"Phường 7",
      "quanhuyen":"70301"
   },
   {
      "id":"7030115",
      "phuongxa":"Phường 8",
      "quanhuyen":"70301"
   },
   {
      "id":"7030117",
      "phuongxa":"Phường 9",
      "quanhuyen":"70301"
   },
   {
      "id":"7030119",
      "phuongxa":"Phường 10",
      "quanhuyen":"70301"
   },
   {
      "id":"7030121",
      "phuongxa":"Phường 11",
      "quanhuyen":"70301"
   },
   {
      "id":"7030123",
      "phuongxa":"Phường 12",
      "quanhuyen":"70301"
   },
   {
      "id":"7030125",
      "phuongxa":"Xã Xuân Thọ",
      "quanhuyen":"70301"
   },
   {
      "id":"7030127",
      "phuongxa":"Xã Xuân Trường",
      "quanhuyen":"70301"
   },
   {
      "id":"7030129",
      "phuongxa":"Xã Tà Nung",
      "quanhuyen":"70301"
   },
   {
      "id":"7030300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70303"
   },
   {
      "id":"7030301",
      "phuongxa":"Phường 1",
      "quanhuyen":"70303"
   },
   {
      "id":"7030303",
      "phuongxa":"Phường 2",
      "quanhuyen":"70303"
   },
   {
      "id":"7030305",
      "phuongxa":"Phường Lộc Phát",
      "quanhuyen":"70303"
   },
   {
      "id":"7030307",
      "phuongxa":"Phường Lộc Tiến",
      "quanhuyen":"70303"
   },
   {
      "id":"7030309",
      "phuongxa":"Phường B'lao",
      "quanhuyen":"70303"
   },
   {
      "id":"7030311",
      "phuongxa":"Phường Lộc Sơn",
      "quanhuyen":"70303"
   },
   {
      "id":"7030313",
      "phuongxa":"Xã Đam Bri",
      "quanhuyen":"70303"
   },
   {
      "id":"7030315",
      "phuongxa":"Xã Lộc Thanh",
      "quanhuyen":"70303"
   },
   {
      "id":"7030319",
      "phuongxa":"Xã Lộc Châu",
      "quanhuyen":"70303"
   },
   {
      "id":"7030500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70305"
   },
   {
      "id":"7030501",
      "phuongxa":"Xã Lát",
      "quanhuyen":"70305"
   },
   {
      "id":"7030503",
      "phuongxa":"Xã Đạ Chais",
      "quanhuyen":"70305"
   },
   {
      "id":"7030505",
      "phuongxa":"Xã Đạ Sar",
      "quanhuyen":"70305"
   },
   {
      "id":"7030507",
      "phuongxa":"Xã Đạ Long",
      "quanhuyen":"70305"
   },
   {
      "id":"7030509",
      "phuongxa":"Xã Đạ Tông",
      "quanhuyen":"70305"
   },
   {
      "id":"7030511",
      "phuongxa":"Xã Đam Rong",
      "quanhuyen":"70305"
   },
   {
      "id":"7030700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70307"
   },
   {
      "id":"7030701",
      "phuongxa":"Thị trấn Thạnh Mỹ",
      "quanhuyen":"70307"
   },
   {
      "id":"7030703",
      "phuongxa":"Thị trấn D'Ran",
      "quanhuyen":"70307"
   },
   {
      "id":"7030705",
      "phuongxa":"Xã Lạc Xuân",
      "quanhuyen":"70307"
   },
   {
      "id":"7030707",
      "phuongxa":"Xã Lạc Lâm",
      "quanhuyen":"70307"
   },
   {
      "id":"7030709",
      "phuongxa":"Xã Ka Đô",
      "quanhuyen":"70307"
   },
   {
      "id":"7030713",
      "phuongxa":"Xã Pró",
      "quanhuyen":"70307"
   },
   {
      "id":"7030715",
      "phuongxa":"Xã Ka Đơn",
      "quanhuyen":"70307"
   },
   {
      "id":"7030717",
      "phuongxa":"Xã Tu Tra",
      "quanhuyen":"70307"
   },
   {
      "id":"7030900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70309"
   },
   {
      "id":"7030901",
      "phuongxa":"Thị trấn Liên Nghĩa",
      "quanhuyen":"70309"
   },
   {
      "id":"7030903",
      "phuongxa":"Xã Hiệp Thạnh",
      "quanhuyen":"70309"
   },
   {
      "id":"7030905",
      "phuongxa":"Xã Liên Hiệp",
      "quanhuyen":"70309"
   },
   {
      "id":"7030907",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"70309"
   },
   {
      "id":"7030909",
      "phuongxa":"Xã N'Thol Hạ",
      "quanhuyen":"70309"
   },
   {
      "id":"7030911",
      "phuongxa":"Xã Tân Hội",
      "quanhuyen":"70309"
   },
   {
      "id":"7030915",
      "phuongxa":"Xã Ninh Gia",
      "quanhuyen":"70309"
   },
   {
      "id":"7030917",
      "phuongxa":"Xã Tà Hine",
      "quanhuyen":"70309"
   },
   {
      "id":"7030919",
      "phuongxa":"Xã Ninh Loan",
      "quanhuyen":"70309"
   },
   {
      "id":"7030921",
      "phuongxa":"Xã Đà Loan",
      "quanhuyen":"70309"
   },
   {
      "id":"7030923",
      "phuongxa":"Xã Tà Năng",
      "quanhuyen":"70309"
   },
   {
      "id":"7031100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70311"
   },
   {
      "id":"7031101",
      "phuongxa":"Thị trấn Đinh Văn",
      "quanhuyen":"70311"
   },
   {
      "id":"7031103",
      "phuongxa":"Thị trấn Nam Ban",
      "quanhuyen":"70311"
   },
   {
      "id":"7031105",
      "phuongxa":"Xã Đạ Đờn",
      "quanhuyen":"70311"
   },
   {
      "id":"7170913",
      "phuongxa":"Xã Châu Pha",
      "quanhuyen":"71709"
   },
   {
      "id":"7170915",
      "phuongxa":"Xã Tóc Tiên",
      "quanhuyen":"71709"
   },
   {
      "id":"7171201",
      "phuongxa":"Thị trấn Long Hải",
      "quanhuyen":"71712"
   },
   {
      "id":"7171300",
      "phuongxa":"Không xác định",
      "quanhuyen":"71713"
   },
   {
      "id":"7171401",
      "phuongxa":"Thị Trấn Phước Hải",
      "quanhuyen":"71714"
   },
   {
      "id":"8010000",
      "phuongxa":"Không xác định",
      "quanhuyen":"80100"
   },
   {
      "id":"8010100",
      "phuongxa":"Phường Tân Khánh",
      "quanhuyen":"80101"
   },
   {
      "id":"8010101",
      "phuongxa":"Phường 1",
      "quanhuyen":"80101"
   },
   {
      "id":"8010103",
      "phuongxa":"Phường 2",
      "quanhuyen":"80101"
   },
   {
      "id":"8010107",
      "phuongxa":"Phường 4",
      "quanhuyen":"80101"
   },
   {
      "id":"8010111",
      "phuongxa":"Xã Hướng Thọ Phú",
      "quanhuyen":"80101"
   },
   {
      "id":"8010113",
      "phuongxa":"Phường Lợi Bình Nhơn",
      "quanhuyen":"80101"
   },
   {
      "id":"8010115",
      "phuongxa":"Xã Nhơn Thạnh Trung",
      "quanhuyen":"80101"
   },
   {
      "id":"8010117",
      "phuongxa":"Xã Bình Tâm",
      "quanhuyen":"80101"
   },
   {
      "id":"8010119",
      "phuongxa":"Xã Khánh Hậu",
      "quanhuyen":"80101"
   },
   {
      "id":"8010121",
      "phuongxa":"Xã An Vĩnh Ngãi",
      "quanhuyen":"80101"
   },
   {
      "id":"8010123",
      "phuongxa":"Phường 6",
      "quanhuyen":"80101"
   },
   {
      "id":"8010300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80103"
   },
   {
      "id":"8010301",
      "phuongxa":"Thị trấn Tân Hưng",
      "quanhuyen":"80103"
   },
   {
      "id":"8010303",
      "phuongxa":"Xã Vĩnh Thạnh",
      "quanhuyen":"80103"
   },
   {
      "id":"8010305",
      "phuongxa":"Xã Vĩnh Lợi",
      "quanhuyen":"80103"
   },
   {
      "id":"8010307",
      "phuongxa":"Xã Vĩnh Đại",
      "quanhuyen":"80103"
   },
   {
      "id":"8010309",
      "phuongxa":"Xã Hưng Điền B",
      "quanhuyen":"80103"
   },
   {
      "id":"8010311",
      "phuongxa":"Xã Hưng Thạnh",
      "quanhuyen":"80103"
   },
   {
      "id":"8010313",
      "phuongxa":"Xã Vĩnh Châu A",
      "quanhuyen":"80103"
   },
   {
      "id":"8010317",
      "phuongxa":"Xã Thạnh Hưng",
      "quanhuyen":"80103"
   },
   {
      "id":"8010319",
      "phuongxa":"Xã Hưng Hà",
      "quanhuyen":"80103"
   },
   {
      "id":"8010321",
      "phuongxa":"Xã Hưng Điền",
      "quanhuyen":"80103"
   },
   {
      "id":"8010500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80105"
   },
   {
      "id":"8010503",
      "phuongxa":"Xã Vĩnh Trị",
      "quanhuyen":"80105"
   },
   {
      "id":"8010505",
      "phuongxa":"Xã Hưng Điền A",
      "quanhuyen":"80105"
   },
   {
      "id":"8010507",
      "phuongxa":"Xã Thái Trị",
      "quanhuyen":"80105"
   },
   {
      "id":"8010509",
      "phuongxa":"Xã Thái Bình Trung",
      "quanhuyen":"80105"
   },
   {
      "id":"8010511",
      "phuongxa":"Xã Tuyên Bình",
      "quanhuyen":"80105"
   },
   {
      "id":"8010513",
      "phuongxa":"Xã Tuyên Bình Tây",
      "quanhuyen":"80105"
   },
   {
      "id":"8010515",
      "phuongxa":"Xã Vĩnh Bình",
      "quanhuyen":"80105"
   },
   {
      "id":"8010517",
      "phuongxa":"Xã Khánh Hưng",
      "quanhuyen":"80105"
   },
   {
      "id":"8010519",
      "phuongxa":"Xã Vĩnh Thuận",
      "quanhuyen":"80105"
   },
   {
      "id":"8010700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80107"
   },
   {
      "id":"8010701",
      "phuongxa":"Thị trấn Mộc Hóa",
      "quanhuyen":"80107"
   },
   {
      "id":"8010703",
      "phuongxa":"Xã Thạnh Trị",
      "quanhuyen":"80107"
   },
   {
      "id":"8010707",
      "phuongxa":"Xã Bình Hòa Tây",
      "quanhuyen":"82631"
   },
   {
      "id":"8010709",
      "phuongxa":"Xã Bình Hòa Đông",
      "quanhuyen":"82631"
   },
   {
      "id":"8010711",
      "phuongxa":"Xã Tuyên Thạnh",
      "quanhuyen":"80107"
   },
   {
      "id":"8010713",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"80107"
   },
   {
      "id":"8010715",
      "phuongxa":"Xã Bình Phong Thạnh",
      "quanhuyen":"82631"
   },
   {
      "id":"8010717",
      "phuongxa":"Xã Bình Hòa Trung",
      "quanhuyen":"80107"
   },
   {
      "id":"8010719",
      "phuongxa":"Xã Bình Tân",
      "quanhuyen":"80107"
   },
   {
      "id":"8010721",
      "phuongxa":"Xã Thạnh Hưng",
      "quanhuyen":"80107"
   },
   {
      "id":"8010723",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"80107"
   },
   {
      "id":"8010725",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"82631"
   },
   {
      "id":"8010900",
      "phuongxa":"Không xác định",
      "quanhuyen":"80109"
   },
   {
      "id":"8010901",
      "phuongxa":"Thị trấn Tân Thạnh",
      "quanhuyen":"80109"
   },
   {
      "id":"8010903",
      "phuongxa":"Xã Kiến Bình",
      "quanhuyen":"80109"
   },
   {
      "id":"8010905",
      "phuongxa":"Xã Bắc Hòa",
      "quanhuyen":"80109"
   },
   {
      "id":"8010907",
      "phuongxa":"Xã Hậu Thạnh Đông",
      "quanhuyen":"80109"
   },
   {
      "id":"8010909",
      "phuongxa":"Xã Nhơn Hòa Lập",
      "quanhuyen":"80109"
   },
   {
      "id":"8010911",
      "phuongxa":"Xã Nhơn Ninh",
      "quanhuyen":"80109"
   },
   {
      "id":"8010913",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"80109"
   },
   {
      "id":"8010915",
      "phuongxa":"Xã Tân Ninh",
      "quanhuyen":"80109"
   },
   {
      "id":"8010917",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"80109"
   },
   {
      "id":"8010919",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"80109"
   },
   {
      "id":"8010921",
      "phuongxa":"Xã Hậu Thạnh Tây",
      "quanhuyen":"80109"
   },
   {
      "id":"8010925",
      "phuongxa":"Xã Nhơn Hoà",
      "quanhuyen":"80109"
   },
   {
      "id":"8011100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80111"
   },
   {
      "id":"8011101",
      "phuongxa":"Thị trấn Thạnh Hóa",
      "quanhuyen":"80111"
   },
   {
      "id":"8011103",
      "phuongxa":"Xã Thủy Đông",
      "quanhuyen":"80111"
   },
   {
      "id":"8011105",
      "phuongxa":"Xã Thủy Tây",
      "quanhuyen":"80111"
   },
   {
      "id":"8091552",
      "phuongxa":"không xác định",
      "quanhuyen":"80915"
   },
   {
      "id":"8110000",
      "phuongxa":"Không xác định",
      "quanhuyen":"81100"
   },
   {
      "id":"8110100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81101"
   },
   {
      "id":"8110101",
      "phuongxa":"Phường 1",
      "quanhuyen":"81101"
   },
   {
      "id":"8110103",
      "phuongxa":"Phường 2",
      "quanhuyen":"81101"
   },
   {
      "id":"8110105",
      "phuongxa":"Phường 3",
      "quanhuyen":"81101"
   },
   {
      "id":"8110107",
      "phuongxa":"Phường 4",
      "quanhuyen":"81101"
   },
   {
      "id":"8110109",
      "phuongxa":"Phường 5",
      "quanhuyen":"81101"
   },
   {
      "id":"8110111",
      "phuongxa":"Phường 6",
      "quanhuyen":"81101"
   },
   {
      "id":"8110113",
      "phuongxa":"Phường 7",
      "quanhuyen":"81101"
   },
   {
      "id":"8110115",
      "phuongxa":"Phường 8",
      "quanhuyen":"81101"
   },
   {
      "id":"8110117",
      "phuongxa":"Xã Phú Khương",
      "quanhuyen":"81101"
   },
   {
      "id":"8110119",
      "phuongxa":"Xã Sơn Đông",
      "quanhuyen":"81101"
   },
   {
      "id":"8110121",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"81101"
   },
   {
      "id":"8110125",
      "phuongxa":"Xã Nhơn Thạnh",
      "quanhuyen":"81101"
   },
   {
      "id":"8110127",
      "phuongxa":"Xã Mỹ Thạnh An",
      "quanhuyen":"81101"
   },
   {
      "id":"8110201",
      "phuongxa":"Hưng Khánh Trung",
      "quanhuyen":"81102"
   },
   {
      "id":"8110300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81103"
   },
   {
      "id":"8110301",
      "phuongxa":"Thị trấn Châu Thành",
      "quanhuyen":"81103"
   },
   {
      "id":"8110303",
      "phuongxa":"Xã Tân Thạch",
      "quanhuyen":"81103"
   },
   {
      "id":"8110307",
      "phuongxa":"Xã Phú Túc",
      "quanhuyen":"81103"
   },
   {
      "id":"8110309",
      "phuongxa":"Xã Phú Đức",
      "quanhuyen":"81103"
   },
   {
      "id":"8110311",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"81103"
   },
   {
      "id":"8110313",
      "phuongxa":"Xã Tiên Long",
      "quanhuyen":"81103"
   },
   {
      "id":"8110315",
      "phuongxa":"Xã Tiên Thủy",
      "quanhuyen":"81103"
   },
   {
      "id":"8110317",
      "phuongxa":"Xã Quới Thành",
      "quanhuyen":"81103"
   },
   {
      "id":"8110321",
      "phuongxa":"Xã Tường Đa",
      "quanhuyen":"81103"
   },
   {
      "id":"8110323",
      "phuongxa":"Xã An Hiệp",
      "quanhuyen":"81103"
   },
   {
      "id":"8110325",
      "phuongxa":"Xã Sơn Hòa",
      "quanhuyen":"81103"
   },
   {
      "id":"8110327",
      "phuongxa":"Xã Mỹ Thành",
      "quanhuyen":"81103"
   },
   {
      "id":"8110329",
      "phuongxa":"Xã Tam Phước",
      "quanhuyen":"81103"
   },
   {
      "id":"8110331",
      "phuongxa":"Xã Phú An Hòa",
      "quanhuyen":"81103"
   },
   {
      "id":"8110335",
      "phuongxa":"Xã Phước Thạnh",
      "quanhuyen":"81103"
   },
   {
      "id":"8110339",
      "phuongxa":"Xã Qưới Sơn",
      "quanhuyen":"81103"
   },
   {
      "id":"8110343",
      "phuongxa":"Xã Giao Hòa",
      "quanhuyen":"81103"
   },
   {
      "id":"8110345",
      "phuongxa":"Xã An Hóa",
      "quanhuyen":"81103"
   },
   {
      "id":"8110500",
      "phuongxa":"Không xác định",
      "quanhuyen":"81105"
   },
   {
      "id":"8110501",
      "phuongxa":"Thị trấn Chợ Lách",
      "quanhuyen":"81105"
   },
   {
      "id":"8110503",
      "phuongxa":"Xã Phú Phụng",
      "quanhuyen":"81105"
   },
   {
      "id":"8110505",
      "phuongxa":"Xã Vĩnh Bình",
      "quanhuyen":"81105"
   },
   {
      "id":"8110507",
      "phuongxa":"Xã Sơn Định",
      "quanhuyen":"81105"
   },
   {
      "id":"8110509",
      "phuongxa":"Xã Hòa Nghĩa",
      "quanhuyen":"81105"
   },
   {
      "id":"8110511",
      "phuongxa":"Xã Tân Thiềng",
      "quanhuyen":"81105"
   },
   {
      "id":"8110513",
      "phuongxa":"Xã Long Thới",
      "quanhuyen":"81105"
   },
   {
      "id":"8110515",
      "phuongxa":"Xã Vĩnh Thành",
      "quanhuyen":"81105"
   },
   {
      "id":"8110517",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"81105"
   },
   {
      "id":"8110519",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"81105"
   },
   {
      "id":"8110521",
      "phuongxa":"Xã Hưng Khánh Trung",
      "quanhuyen":"81105"
   },
   {
      "id":"8110700",
      "phuongxa":"Không xác định",
      "quanhuyen":"81107"
   },
   {
      "id":"8110701",
      "phuongxa":"Thị trấn Mỏ Cầy",
      "quanhuyen":"81107"
   },
   {
      "id":"8110703",
      "phuongxa":"Xã Thanh Tân",
      "quanhuyen":"81107"
   },
   {
      "id":"8110705",
      "phuongxa":"Xã Thạnh Ngãi",
      "quanhuyen":"81107"
   },
   {
      "id":"8110707",
      "phuongxa":"Xã Phước Mỹ Trung",
      "quanhuyen":"81107"
   },
   {
      "id":"8110709",
      "phuongxa":"Xã Tân Phú Tây",
      "quanhuyen":"81107"
   },
   {
      "id":"8110711",
      "phuongxa":"Xã Tân Thành Bình",
      "quanhuyen":"81107"
   },
   {
      "id":"8110713",
      "phuongxa":"Xã Thành An",
      "quanhuyen":"81107"
   },
   {
      "id":"8110715",
      "phuongxa":"Xã Tân Thanh Tây",
      "quanhuyen":"81107"
   },
   {
      "id":"8110717",
      "phuongxa":"Xã Nhuận Phú Tân",
      "quanhuyen":"81107"
   },
   {
      "id":"8110719",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"81107"
   },
   {
      "id":"8110721",
      "phuongxa":"Xã Hòa Lộc",
      "quanhuyen":"81107"
   },
   {
      "id":"8110723",
      "phuongxa":"Xã Định Thủy",
      "quanhuyen":"81107"
   },
   {
      "id":"8110725",
      "phuongxa":"Xã Phước Hiệp",
      "quanhuyen":"81107"
   },
   {
      "id":"8110727",
      "phuongxa":"Xã Bình Khánh Tây",
      "quanhuyen":"81102"
   },
   {
      "id":"8110729",
      "phuongxa":"Xã Bình Khánh Đông",
      "quanhuyen":"81107"
   },
   {
      "id":"8110731",
      "phuongxa":"Xã Đa Phước Hội",
      "quanhuyen":"81102"
   },
   {
      "id":"8110733",
      "phuongxa":"Xã Khánh Thạnh Tân",
      "quanhuyen":"81107"
   },
   {
      "id":"8110735",
      "phuongxa":"Xã An Thạnh",
      "quanhuyen":"81107"
   },
   {
      "id":"8110737",
      "phuongxa":"Xã Thành Thới B",
      "quanhuyen":"81107"
   },
   {
      "id":"8110739",
      "phuongxa":"Xã Thành Thới A",
      "quanhuyen":"81102"
   },
   {
      "id":"7031107",
      "phuongxa":"Xã Phi Tô",
      "quanhuyen":"70311"
   },
   {
      "id":"7031109",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"70311"
   },
   {
      "id":"7031113",
      "phuongxa":"Xã Liêng S'Roin",
      "quanhuyen":"70311"
   },
   {
      "id":"7031115",
      "phuongxa":"Xã Rô Men",
      "quanhuyen":"70311"
   },
   {
      "id":"7031117",
      "phuongxa":"Xã Tân Văn",
      "quanhuyen":"70311"
   },
   {
      "id":"7031119",
      "phuongxa":"Xã Tân Hà",
      "quanhuyen":"70311"
   },
   {
      "id":"7031121",
      "phuongxa":"Xã Phúc Thọ",
      "quanhuyen":"70311"
   },
   {
      "id":"7031123",
      "phuongxa":"Xã Hoài Đức",
      "quanhuyen":"70311"
   },
   {
      "id":"7031125",
      "phuongxa":"Xã Tân Thanh",
      "quanhuyen":"70311"
   },
   {
      "id":"7031127",
      "phuongxa":"Xã Đan Phượng",
      "quanhuyen":"70311"
   },
   {
      "id":"7031129",
      "phuongxa":"Xã Gia Lâm",
      "quanhuyen":"70311"
   },
   {
      "id":"7031131",
      "phuongxa":"Xã Đông Thanh",
      "quanhuyen":"70311"
   },
   {
      "id":"7031133",
      "phuongxa":"Xã Mê Linh",
      "quanhuyen":"70311"
   },
   {
      "id":"7031301",
      "phuongxa":"Thị trấn Lộc Thắng",
      "quanhuyen":"70313"
   },
   {
      "id":"7031303",
      "phuongxa":"Xã Lộc Bảo",
      "quanhuyen":"70313"
   },
   {
      "id":"7031307",
      "phuongxa":"Xã Lộc Lâm",
      "quanhuyen":"70313"
   },
   {
      "id":"7031309",
      "phuongxa":"Xã Lộc Phú",
      "quanhuyen":"70313"
   },
   {
      "id":"7031311",
      "phuongxa":"Xã Lộc Quảng",
      "quanhuyen":"70313"
   },
   {
      "id":"7031313",
      "phuongxa":"Xã Lộc Ngãi",
      "quanhuyen":"70313"
   },
   {
      "id":"7031315",
      "phuongxa":"Xã Lộc Đức",
      "quanhuyen":"70313"
   },
   {
      "id":"7031317",
      "phuongxa":"Xã Lộc Tân",
      "quanhuyen":"70313"
   },
   {
      "id":"7031319",
      "phuongxa":"Xã Lộc An",
      "quanhuyen":"70313"
   },
   {
      "id":"7031323",
      "phuongxa":"Xã Lộc Nam",
      "quanhuyen":"70313"
   },
   {
      "id":"7031501",
      "phuongxa":"Thị trấn Di Linh",
      "quanhuyen":"70315"
   },
   {
      "id":"7031503",
      "phuongxa":"Xã Đinh Trang Thượng",
      "quanhuyen":"70315"
   },
   {
      "id":"7031505",
      "phuongxa":"Xã Tân Thượng",
      "quanhuyen":"70315"
   },
   {
      "id":"7031507",
      "phuongxa":"Xã Tân Châu",
      "quanhuyen":"70315"
   },
   {
      "id":"7031509",
      "phuongxa":"Xã Đinh Lạc",
      "quanhuyen":"70315"
   },
   {
      "id":"7031511",
      "phuongxa":"Xã Gia Hiệp",
      "quanhuyen":"70315"
   },
   {
      "id":"7031513",
      "phuongxa":"Xã Tam Bố",
      "quanhuyen":"70315"
   },
   {
      "id":"7031517",
      "phuongxa":"Xã Liên Đầm",
      "quanhuyen":"70315"
   },
   {
      "id":"7031519",
      "phuongxa":"Xã Gung Ré",
      "quanhuyen":"70315"
   },
   {
      "id":"7031521",
      "phuongxa":"Xã Bảo Thuận",
      "quanhuyen":"70315"
   },
   {
      "id":"7031523",
      "phuongxa":"Xã Hòa Ninh",
      "quanhuyen":"70315"
   },
   {
      "id":"7031525",
      "phuongxa":"Xã Hòa Trung",
      "quanhuyen":"70315"
   },
   {
      "id":"7031527",
      "phuongxa":"Xã Hòa Nam",
      "quanhuyen":"70315"
   },
   {
      "id":"7031529",
      "phuongxa":"Xã Hòa Bắc",
      "quanhuyen":"70315"
   },
   {
      "id":"7031531",
      "phuongxa":"Xã Sơn Điền",
      "quanhuyen":"70315"
   },
   {
      "id":"7031533",
      "phuongxa":"Xã Gia Bắc",
      "quanhuyen":"70315"
   },
   {
      "id":"7031700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70317"
   },
   {
      "id":"7031701",
      "phuongxa":"Thị trấn Ma Đa Guôi",
      "quanhuyen":"70317"
   },
   {
      "id":"7031703",
      "phuongxa":"Thị trấn Đạ M'ri",
      "quanhuyen":"70317"
   },
   {
      "id":"7031705",
      "phuongxa":"Xã Đạ M'ri",
      "quanhuyen":"70317"
   },
   {
      "id":"7031707",
      "phuongxa":"Xã Hà Lâm",
      "quanhuyen":"70317"
   },
   {
      "id":"7031709",
      "phuongxa":"Xã Đạ Tồn",
      "quanhuyen":"70317"
   },
   {
      "id":"7031711",
      "phuongxa":"Xã Đạ Oai",
      "quanhuyen":"70317"
   },
   {
      "id":"7031713",
      "phuongxa":"Xã Ma Đa Guôi",
      "quanhuyen":"70317"
   },
   {
      "id":"7031715",
      "phuongxa":"Xã Đạ Ploa",
      "quanhuyen":"70317"
   },
   {
      "id":"7031717",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"70317"
   },
   {
      "id":"7031900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70319"
   },
   {
      "id":"7031903",
      "phuongxa":"Xã An Nhơn",
      "quanhuyen":"70319"
   },
   {
      "id":"7031905",
      "phuongxa":"Xã Mỹ Đức",
      "quanhuyen":"70319"
   },
   {
      "id":"7031907",
      "phuongxa":"Xã Quốc Oai",
      "quanhuyen":"70319"
   },
   {
      "id":"7031909",
      "phuongxa":"Xã Đạ Lây",
      "quanhuyen":"70319"
   },
   {
      "id":"7031911",
      "phuongxa":"Xã Quảng Trị",
      "quanhuyen":"70319"
   },
   {
      "id":"7031915",
      "phuongxa":"Xã Triệu Hải",
      "quanhuyen":"70319"
   },
   {
      "id":"7031917",
      "phuongxa":"Xã Hà Đông",
      "quanhuyen":"70319"
   },
   {
      "id":"7031919",
      "phuongxa":"Xã Đạ Kho",
      "quanhuyen":"70319"
   },
   {
      "id":"7032100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70321"
   },
   {
      "id":"7032101",
      "phuongxa":"Xã Đồng Nai Thượng ",
      "quanhuyen":"70321"
   },
   {
      "id":"7032103",
      "phuongxa":"Xã Tiên Hoàng",
      "quanhuyen":"70321"
   },
   {
      "id":"7032105",
      "phuongxa":"Xã Phước Cát 2",
      "quanhuyen":"70321"
   },
   {
      "id":"7032107",
      "phuongxa":"Xã Gia Viễn",
      "quanhuyen":"70321"
   },
   {
      "id":"7032111",
      "phuongxa":"Xã Mỹ Lâm",
      "quanhuyen":"70321"
   },
   {
      "id":"7032113",
      "phuongxa":"Xã Đức Phổ",
      "quanhuyen":"70321"
   },
   {
      "id":"7032115",
      "phuongxa":"Xã Tư Nghĩa",
      "quanhuyen":"70321"
   },
   {
      "id":"7032117",
      "phuongxa":"Thị trấn Phước Cát ",
      "quanhuyen":"70321"
   },
   {
      "id":"7032119",
      "phuongxa":"Xã Phù Mỹ",
      "quanhuyen":"70321"
   },
   {
      "id":"7032121",
      "phuongxa":"Xã Quảng Ngãi",
      "quanhuyen":"70321"
   },
   {
      "id":"8011107",
      "phuongxa":"Xã Tân Đông",
      "quanhuyen":"80111"
   },
   {
      "id":"8011109",
      "phuongxa":"Xã Tân Tây",
      "quanhuyen":"80111"
   },
   {
      "id":"8011111",
      "phuongxa":"Xã Thạnh Phước",
      "quanhuyen":"80111"
   },
   {
      "id":"8011113",
      "phuongxa":"Xã Thạnh Phú",
      "quanhuyen":"80111"
   },
   {
      "id":"8011117",
      "phuongxa":"Xã Thuận Bình",
      "quanhuyen":"80111"
   },
   {
      "id":"8011119",
      "phuongxa":"Xã Tân Hiệp",
      "quanhuyen":"80111"
   },
   {
      "id":"8011300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80113"
   },
   {
      "id":"8011301",
      "phuongxa":"Thị trấn Đông Thành",
      "quanhuyen":"80113"
   },
   {
      "id":"8011303",
      "phuongxa":"Xã Mỹ Thạnh Đông",
      "quanhuyen":"80113"
   },
   {
      "id":"8011305",
      "phuongxa":"Xã Mỹ Quý Đông",
      "quanhuyen":"80113"
   },
   {
      "id":"8011307",
      "phuongxa":"Xã Mỹ Quý Tây",
      "quanhuyen":"80113"
   },
   {
      "id":"8011309",
      "phuongxa":"Xã Mỹ Thạnh Tây",
      "quanhuyen":"80113"
   },
   {
      "id":"8011311",
      "phuongxa":"Xã Bình Thành",
      "quanhuyen":"80113"
   },
   {
      "id":"8011315",
      "phuongxa":"Xã Bình Hòa Bắc",
      "quanhuyen":"80113"
   },
   {
      "id":"8011317",
      "phuongxa":"Xã Bình Hòa Nam",
      "quanhuyen":"80113"
   },
   {
      "id":"8011319",
      "phuongxa":"Xã Bình Hòa Hưng",
      "quanhuyen":"80113"
   },
   {
      "id":"8011500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80115"
   },
   {
      "id":"8011501",
      "phuongxa":"Thị trấn Hậu Nghĩa",
      "quanhuyen":"80115"
   },
   {
      "id":"8011503",
      "phuongxa":"Thị trấn Đức Hòa",
      "quanhuyen":"80115"
   },
   {
      "id":"8011505",
      "phuongxa":"Thị trấn Hiệp Hòa",
      "quanhuyen":"80115"
   },
   {
      "id":"8011507",
      "phuongxa":"Xã Lộc Giang",
      "quanhuyen":"80115"
   },
   {
      "id":"8011509",
      "phuongxa":"Xã An Ninh Đông",
      "quanhuyen":"80115"
   },
   {
      "id":"8011513",
      "phuongxa":"Xã Hiệp Hòa",
      "quanhuyen":"80115"
   },
   {
      "id":"8011515",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"80115"
   },
   {
      "id":"8011517",
      "phuongxa":"Xã Hòa Khánh Tây",
      "quanhuyen":"80115"
   },
   {
      "id":"8011519",
      "phuongxa":"Xã Hòa Khánh Đông",
      "quanhuyen":"80115"
   },
   {
      "id":"8011521",
      "phuongxa":"Xã Hòa Khánh Nam",
      "quanhuyen":"80115"
   },
   {
      "id":"8011523",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"80115"
   },
   {
      "id":"8011525",
      "phuongxa":"Xã Đức Lập Thượng",
      "quanhuyen":"80115"
   },
   {
      "id":"8011527",
      "phuongxa":"Xã Đức Lập Hạ",
      "quanhuyen":"80115"
   },
   {
      "id":"8011531",
      "phuongxa":"Xã Đức Hòa Thượng",
      "quanhuyen":"80115"
   },
   {
      "id":"8011533",
      "phuongxa":"Xã Đức Hòa Hạ",
      "quanhuyen":"80115"
   },
   {
      "id":"8011537",
      "phuongxa":"Xã Mỹ Hạnh Nam",
      "quanhuyen":"80115"
   },
   {
      "id":"8011539",
      "phuongxa":"Xã Đức Hòa Đông",
      "quanhuyen":"80115"
   },
   {
      "id":"8011700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80117"
   },
   {
      "id":"8011701",
      "phuongxa":"Thị trấn Bến Lức",
      "quanhuyen":"80117"
   },
   {
      "id":"8011703",
      "phuongxa":"Xã Thạnh Lợi",
      "quanhuyen":"80117"
   },
   {
      "id":"8011705",
      "phuongxa":"Xã Thạnh Hòa",
      "quanhuyen":"80117"
   },
   {
      "id":"8011707",
      "phuongxa":"Xã Bình Đức",
      "quanhuyen":"80117"
   },
   {
      "id":"8011709",
      "phuongxa":"Xã Lương Bình",
      "quanhuyen":"80117"
   },
   {
      "id":"8011711",
      "phuongxa":"Xã Lương Hòa",
      "quanhuyen":"80117"
   },
   {
      "id":"8011713",
      "phuongxa":"Xã Tân Bửu",
      "quanhuyen":"80117"
   },
   {
      "id":"8011715",
      "phuongxa":"Xã An Thạnh",
      "quanhuyen":"80117"
   },
   {
      "id":"8011717",
      "phuongxa":"Xã Thạnh Đức",
      "quanhuyen":"80117"
   },
   {
      "id":"8011721",
      "phuongxa":"Xã Thanh Phú",
      "quanhuyen":"80117"
   },
   {
      "id":"8011723",
      "phuongxa":"Xã Long Hiệp",
      "quanhuyen":"80117"
   },
   {
      "id":"8011725",
      "phuongxa":"Xã Mỹ Yên",
      "quanhuyen":"80117"
   },
   {
      "id":"8011727",
      "phuongxa":"Xã Phước Lợi",
      "quanhuyen":"80117"
   },
   {
      "id":"8011729",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"80117"
   },
   {
      "id":"8011900",
      "phuongxa":"Không xác định",
      "quanhuyen":"80119"
   },
   {
      "id":"8011901",
      "phuongxa":"Thị trấn Thủ Thừa",
      "quanhuyen":"80119"
   },
   {
      "id":"8011903",
      "phuongxa":"Xã Long Thạnh",
      "quanhuyen":"80119"
   },
   {
      "id":"8011905",
      "phuongxa":"Xã Long Thuận",
      "quanhuyen":"80119"
   },
   {
      "id":"8011907",
      "phuongxa":"Xã Mỹ Lạc",
      "quanhuyen":"80119"
   },
   {
      "id":"8011911",
      "phuongxa":"Xã Mỹ An",
      "quanhuyen":"80119"
   },
   {
      "id":"8011913",
      "phuongxa":"Xã Mỹ Phú",
      "quanhuyen":"80119"
   },
   {
      "id":"8011915",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"80119"
   },
   {
      "id":"8011917",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"80119"
   },
   {
      "id":"8011919",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"80119"
   },
   {
      "id":"8011921",
      "phuongxa":"Xã Nhị Thành",
      "quanhuyen":"80119"
   },
   {
      "id":"8012100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80121"
   },
   {
      "id":"8012101",
      "phuongxa":"Thị trấn Tầm Vu",
      "quanhuyen":"80121"
   },
   {
      "id":"8012103",
      "phuongxa":"Xã Bình Quới",
      "quanhuyen":"80121"
   },
   {
      "id":"8012105",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"80121"
   },
   {
      "id":"8012109",
      "phuongxa":"Xã Hiệp Thạnh",
      "quanhuyen":"80121"
   },
   {
      "id":"8012111",
      "phuongxa":"Xã Dương Xuân Hội",
      "quanhuyen":"80121"
   },
   {
      "id":"8110741",
      "phuongxa":"Xã An Thới",
      "quanhuyen":"81107"
   },
   {
      "id":"8110743",
      "phuongxa":"Xã An Định",
      "quanhuyen":"81107"
   },
   {
      "id":"8110745",
      "phuongxa":"Xã Tân Trung",
      "quanhuyen":"81107"
   },
   {
      "id":"8110747",
      "phuongxa":"Xã Ngãi Đăng",
      "quanhuyen":"81107"
   },
   {
      "id":"8110749",
      "phuongxa":"Xã Cẩm Sơn",
      "quanhuyen":"81107"
   },
   {
      "id":"8110751",
      "phuongxa":"Xã Hương Mỹ",
      "quanhuyen":"81102"
   },
   {
      "id":"8110753",
      "phuongxa":"Xã Minh Đức",
      "quanhuyen":"81107"
   },
   {
      "id":"8110901",
      "phuongxa":"Thị trấn Giồng Trôm",
      "quanhuyen":"81109"
   },
   {
      "id":"8110903",
      "phuongxa":"Xã Phong Nẫm",
      "quanhuyen":"81109"
   },
   {
      "id":"8110905",
      "phuongxa":"Xã Phong Mỹ",
      "quanhuyen":"81109"
   },
   {
      "id":"8110907",
      "phuongxa":"Xã Mỹ Thạnh",
      "quanhuyen":"81109"
   },
   {
      "id":"8110909",
      "phuongxa":"Xã Lương Phú",
      "quanhuyen":"81109"
   },
   {
      "id":"8110911",
      "phuongxa":"Xã Thuận Điền",
      "quanhuyen":"81109"
   },
   {
      "id":"8110913",
      "phuongxa":"Xã Sơn Phú",
      "quanhuyen":"81109"
   },
   {
      "id":"8110915",
      "phuongxa":"Xã Phước Long",
      "quanhuyen":"81109"
   },
   {
      "id":"8110917",
      "phuongxa":"Xã Hưng Phong",
      "quanhuyen":"81109"
   },
   {
      "id":"8110919",
      "phuongxa":"Xã Long Mỹ",
      "quanhuyen":"81109"
   },
   {
      "id":"8110923",
      "phuongxa":"Xã Lương Quới",
      "quanhuyen":"81109"
   },
   {
      "id":"8110925",
      "phuongxa":"Xã Châu Hòa",
      "quanhuyen":"81109"
   },
   {
      "id":"8110927",
      "phuongxa":"Xã Châu Bình",
      "quanhuyen":"81109"
   },
   {
      "id":"8110929",
      "phuongxa":"Xã Bình Hoà",
      "quanhuyen":"81109"
   },
   {
      "id":"8110931",
      "phuongxa":"Xã Bình Thành",
      "quanhuyen":"81109"
   },
   {
      "id":"8110933",
      "phuongxa":"Xã Tân Thanh",
      "quanhuyen":"81109"
   },
   {
      "id":"8110935",
      "phuongxa":"Xã Tân Hào",
      "quanhuyen":"81109"
   },
   {
      "id":"8110937",
      "phuongxa":"Xã Tân Lợi Thạnh",
      "quanhuyen":"81109"
   },
   {
      "id":"8110939",
      "phuongxa":"Xã Thạnh Phú Đông",
      "quanhuyen":"81109"
   },
   {
      "id":"8110943",
      "phuongxa":"Xã Hưng Nhượng",
      "quanhuyen":"81109"
   },
   {
      "id":"8111100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81111"
   },
   {
      "id":"8111101",
      "phuongxa":"Thị trấn Bình Đại",
      "quanhuyen":"81111"
   },
   {
      "id":"8111103",
      "phuongxa":"Xã Tam Hiệp",
      "quanhuyen":"81111"
   },
   {
      "id":"8111105",
      "phuongxa":"Xã Long Định",
      "quanhuyen":"81111"
   },
   {
      "id":"8111107",
      "phuongxa":"Xã Long Hòa",
      "quanhuyen":"81111"
   },
   {
      "id":"8111109",
      "phuongxa":"Xã Phú Thuận",
      "quanhuyen":"81111"
   },
   {
      "id":"8111111",
      "phuongxa":"Xã Châu Hưng",
      "quanhuyen":"81111"
   },
   {
      "id":"8111113",
      "phuongxa":"Xã Vang Quới Tây",
      "quanhuyen":"81111"
   },
   {
      "id":"8111115",
      "phuongxa":"Xã Vang Quới Đông",
      "quanhuyen":"81111"
   },
   {
      "id":"8111117",
      "phuongxa":"Xã Thới Lai",
      "quanhuyen":"81111"
   },
   {
      "id":"8111119",
      "phuongxa":"Xã Phú Vang",
      "quanhuyen":"81111"
   },
   {
      "id":"8111121",
      "phuongxa":"Xã Lộc Thuận",
      "quanhuyen":"81111"
   },
   {
      "id":"8111123",
      "phuongxa":"Xã Định Trung",
      "quanhuyen":"81111"
   },
   {
      "id":"8111125",
      "phuongxa":"Xã Phú Long",
      "quanhuyen":"81111"
   },
   {
      "id":"8111127",
      "phuongxa":"Xã Bình Thới",
      "quanhuyen":"81111"
   },
   {
      "id":"8111129",
      "phuongxa":"Xã Thạnh Trị",
      "quanhuyen":"81111"
   },
   {
      "id":"8111131",
      "phuongxa":"Xã Đại Hòa Lộc",
      "quanhuyen":"81111"
   },
   {
      "id":"8111133",
      "phuongxa":"Xã Bình Thắng",
      "quanhuyen":"81111"
   },
   {
      "id":"8111135",
      "phuongxa":"Xã Thạnh Phước",
      "quanhuyen":"81111"
   },
   {
      "id":"8111137",
      "phuongxa":"Xã Thừa Đức",
      "quanhuyen":"81111"
   },
   {
      "id":"8111139",
      "phuongxa":"Xã Thới Thuận",
      "quanhuyen":"81111"
   },
   {
      "id":"8111301",
      "phuongxa":"Thị trấn Ba Tri",
      "quanhuyen":"81113"
   },
   {
      "id":"8111303",
      "phuongxa":"Xã Tân Xuân",
      "quanhuyen":"81113"
   },
   {
      "id":"8111305",
      "phuongxa":"Xã Mỹ Hòa",
      "quanhuyen":"81113"
   },
   {
      "id":"8111307",
      "phuongxa":"Xã Mỹ Chánh",
      "quanhuyen":"81113"
   },
   {
      "id":"8111309",
      "phuongxa":"Xã Mỹ Nhơn",
      "quanhuyen":"81113"
   },
   {
      "id":"8111311",
      "phuongxa":"Xã Mỹ Thạnh",
      "quanhuyen":"81113"
   },
   {
      "id":"8111313",
      "phuongxa":"Xã An Phú Trung",
      "quanhuyen":"81113"
   },
   {
      "id":"8111315",
      "phuongxa":"Xã An Ngãi Trung",
      "quanhuyen":"81113"
   },
   {
      "id":"8111317",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"81113"
   },
   {
      "id":"8111319",
      "phuongxa":"Xã An Ngãi Tây",
      "quanhuyen":"81113"
   },
   {
      "id":"8111321",
      "phuongxa":"Xã An Hiệp",
      "quanhuyen":"81113"
   },
   {
      "id":"8111325",
      "phuongxa":"Xã Phú Lễ",
      "quanhuyen":"81113"
   },
   {
      "id":"8111327",
      "phuongxa":"Xã Phú Ngãi",
      "quanhuyen":"81113"
   },
   {
      "id":"8111329",
      "phuongxa":"Xã Phước Tuy",
      "quanhuyen":"81113"
   },
   {
      "id":"8111333",
      "phuongxa":"Xã Bảo Thuận",
      "quanhuyen":"81113"
   },
   {
      "id":"8111335",
      "phuongxa":"Xã Tân Thủy",
      "quanhuyen":"81113"
   },
   {
      "id":"8111337",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"81113"
   },
   {
      "id":"8111339",
      "phuongxa":"Xã Vĩnh An",
      "quanhuyen":"81113"
   },
   {
      "id":"8111341",
      "phuongxa":"Xã An Đức",
      "quanhuyen":"81113"
   },
   {
      "id":"8111343",
      "phuongxa":"Xã An Hòa Tây",
      "quanhuyen":"81113"
   },
   {
      "id":"8111345",
      "phuongxa":"Xã An Thủy",
      "quanhuyen":"81113"
   },
   {
      "id":"7032300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70323"
   },
   {
      "id":"7032301",
      "phuongxa":"Xã Đạ Tông",
      "quanhuyen":"70323"
   },
   {
      "id":"7032303",
      "phuongxa":"Xã Đạ Long",
      "quanhuyen":"70323"
   },
   {
      "id":"7032305",
      "phuongxa":"Xã Đạ M'Rong",
      "quanhuyen":"70323"
   },
   {
      "id":"7032307",
      "phuongxa":"Xã Liêng S'Roin",
      "quanhuyen":"70323"
   },
   {
      "id":"7032309",
      "phuongxa":"Xã Đạ Rsal",
      "quanhuyen":"70323"
   },
   {
      "id":"7032311",
      "phuongxa":"Xã Rô Men",
      "quanhuyen":"70323"
   },
   {
      "id":"7032313",
      "phuongxa":"Xã Phi Liêng",
      "quanhuyen":"70323"
   },
   {
      "id":"7032315",
      "phuongxa":"Xã Đạ KNàng",
      "quanhuyen":"70323"
   },
   {
      "id":"7050100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70501"
   },
   {
      "id":"7050101",
      "phuongxa":"Phường Mỹ Hương",
      "quanhuyen":"70501"
   },
   {
      "id":"7050103",
      "phuongxa":"Phường Kinh Dinh",
      "quanhuyen":"70501"
   },
   {
      "id":"7050105",
      "phuongxa":"Phường Đạo Long",
      "quanhuyen":"70501"
   },
   {
      "id":"7050107",
      "phuongxa":"Phường Tấn Tài",
      "quanhuyen":"70501"
   },
   {
      "id":"7050109",
      "phuongxa":"Phường Phủ Hà",
      "quanhuyen":"70501"
   },
   {
      "id":"7050111",
      "phuongxa":"Phường Thanh Sơn",
      "quanhuyen":"70501"
   },
   {
      "id":"7050113",
      "phuongxa":"Phường Phước Mỹ",
      "quanhuyen":"70501"
   },
   {
      "id":"7050115",
      "phuongxa":"Phường Bảo An",
      "quanhuyen":"70501"
   },
   {
      "id":"7050117",
      "phuongxa":"Phường Đô Vinh",
      "quanhuyen":"70501"
   },
   {
      "id":"7050119",
      "phuongxa":"Xã Thành Hải",
      "quanhuyen":"70501"
   },
   {
      "id":"7050121",
      "phuongxa":"Xã Văn Hải",
      "quanhuyen":"70501"
   },
   {
      "id":"7050125",
      "phuongxa":"Xã Mỹ Hải",
      "quanhuyen":"70501"
   },
   {
      "id":"7050300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70503"
   },
   {
      "id":"7050301",
      "phuongxa":"Xã Lâm Sơn",
      "quanhuyen":"70503"
   },
   {
      "id":"7050303",
      "phuongxa":"Xã Tân Sơn",
      "quanhuyen":"70503"
   },
   {
      "id":"7050305",
      "phuongxa":"Xã Quảng Sơn",
      "quanhuyen":"70503"
   },
   {
      "id":"7050307",
      "phuongxa":"Xã Mỹ Sơn",
      "quanhuyen":"70503"
   },
   {
      "id":"7050309",
      "phuongxa":"Xã Hòa Sơn",
      "quanhuyen":"70503"
   },
   {
      "id":"7050311",
      "phuongxa":"Xã Nhơn Sơn",
      "quanhuyen":"70503"
   },
   {
      "id":"7050313",
      "phuongxa":"Xã Phước Trung",
      "quanhuyen":"70503"
   },
   {
      "id":"7050317",
      "phuongxa":"Xã Phước Chính",
      "quanhuyen":"70503"
   },
   {
      "id":"7050319",
      "phuongxa":"Xã Phước Hòa",
      "quanhuyen":"70503"
   },
   {
      "id":"7050321",
      "phuongxa":"Xã Phước Bình",
      "quanhuyen":"70503"
   },
   {
      "id":"7050323",
      "phuongxa":"Xã Phước Tân",
      "quanhuyen":"70503"
   },
   {
      "id":"7050325",
      "phuongxa":"Xã Phước Tiến",
      "quanhuyen":"70503"
   },
   {
      "id":"7050327",
      "phuongxa":"Xã Ma Nới",
      "quanhuyen":"70503"
   },
   {
      "id":"7050329",
      "phuongxa":"Xã Phước Thành",
      "quanhuyen":"70503"
   },
   {
      "id":"7050331",
      "phuongxa":"Xã Phước Thắng",
      "quanhuyen":"70503"
   },
   {
      "id":"7050500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70505"
   },
   {
      "id":"7050501",
      "phuongxa":"Thị trấn Khánh Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050503",
      "phuongxa":"Xã Phước Kháng",
      "quanhuyen":"70505"
   },
   {
      "id":"7050505",
      "phuongxa":"Xã Phước Chiến",
      "quanhuyen":"70505"
   },
   {
      "id":"7050507",
      "phuongxa":"Xã Tân Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050509",
      "phuongxa":"Xã Hộ Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050511",
      "phuongxa":"Xã Xuân Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050513",
      "phuongxa":"Xã Phương Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050515",
      "phuongxa":"Xã Tri Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050517",
      "phuongxa":"Xã Nhơn Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050519",
      "phuongxa":"Xã Vĩnh Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050521",
      "phuongxa":"Xã Công Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050523",
      "phuongxa":"Xã Lợi Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"7050701",
      "phuongxa":"Thị trấn Phước Dân",
      "quanhuyen":"70507"
   },
   {
      "id":"7050703",
      "phuongxa":"Xã Phước Sơn",
      "quanhuyen":"70507"
   },
   {
      "id":"7050705",
      "phuongxa":"Xã Phước Thái",
      "quanhuyen":"70507"
   },
   {
      "id":"7050709",
      "phuongxa":"Xã Phước Thuận",
      "quanhuyen":"70507"
   },
   {
      "id":"7050711",
      "phuongxa":"Xã An Hải",
      "quanhuyen":"70507"
   },
   {
      "id":"7050713",
      "phuongxa":"Xã Phước Hải",
      "quanhuyen":"70507"
   },
   {
      "id":"7050715",
      "phuongxa":"Xã Phước Dinh",
      "quanhuyen":"70507"
   },
   {
      "id":"7050717",
      "phuongxa":"Xã Phước Diêm",
      "quanhuyen":"70507"
   },
   {
      "id":"7050719",
      "phuongxa":"Xã Phước Hữu",
      "quanhuyen":"70507"
   },
   {
      "id":"7050723",
      "phuongxa":"Xã Phước Hà",
      "quanhuyen":"70507"
   },
   {
      "id":"7050725",
      "phuongxa":"Xã Nhị Hà",
      "quanhuyen":"70507"
   },
   {
      "id":"7050727",
      "phuongxa":"Xã Phước Minh",
      "quanhuyen":"70507"
   },
   {
      "id":"7050900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70509"
   },
   {
      "id":"7050901",
      "phuongxa":"Xã Phước Bình",
      "quanhuyen":"70509"
   },
   {
      "id":"7050903",
      "phuongxa":"Xã Phước Hòa",
      "quanhuyen":"70509"
   },
   {
      "id":"7050905",
      "phuongxa":"Xã Phước Tân",
      "quanhuyen":"70509"
   },
   {
      "id":"7050907",
      "phuongxa":"Xã Phước Tiến",
      "quanhuyen":"70509"
   },
   {
      "id":"7050909",
      "phuongxa":"Xã Phước Thắng",
      "quanhuyen":"70509"
   },
   {
      "id":"8012113",
      "phuongxa":"Xã Long Trì",
      "quanhuyen":"80121"
   },
   {
      "id":"8012115",
      "phuongxa":"Xã Phú Ngãi Trị",
      "quanhuyen":"80121"
   },
   {
      "id":"8012117",
      "phuongxa":"Xã Phước Tân Hưng",
      "quanhuyen":"80121"
   },
   {
      "id":"8012119",
      "phuongxa":"Xã Thanh Phú Long",
      "quanhuyen":"80121"
   },
   {
      "id":"8012121",
      "phuongxa":"Xã An Lục Long",
      "quanhuyen":"80121"
   },
   {
      "id":"8012123",
      "phuongxa":"Xã Thuận Mỹ",
      "quanhuyen":"80121"
   },
   {
      "id":"8012125",
      "phuongxa":"Xã Thanh Vĩnh Đông",
      "quanhuyen":"80121"
   },
   {
      "id":"8012303",
      "phuongxa":"Xã Bình Tịnh",
      "quanhuyen":"80123"
   },
   {
      "id":"8012305",
      "phuongxa":"Xã Mỹ Bình",
      "quanhuyen":"80123"
   },
   {
      "id":"8012307",
      "phuongxa":"Xã An Nhựt Tân",
      "quanhuyen":"80123"
   },
   {
      "id":"8012309",
      "phuongxa":"Xã Quê Mỹ Thạnh",
      "quanhuyen":"80123"
   },
   {
      "id":"8012311",
      "phuongxa":"Xã Lạc Tấn",
      "quanhuyen":"80123"
   },
   {
      "id":"8012313",
      "phuongxa":"Xã Bình Lãng",
      "quanhuyen":"80123"
   },
   {
      "id":"8012315",
      "phuongxa":"Xã Bình Trinh Đông",
      "quanhuyen":"80123"
   },
   {
      "id":"8012317",
      "phuongxa":"Xã Tân Phước Tây",
      "quanhuyen":"80123"
   },
   {
      "id":"8012319",
      "phuongxa":"Xã Đức Tân",
      "quanhuyen":"80123"
   },
   {
      "id":"8012500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80125"
   },
   {
      "id":"8012501",
      "phuongxa":"Thị trấn Cần Đước",
      "quanhuyen":"80125"
   },
   {
      "id":"8012503",
      "phuongxa":"Xã Long Trạch",
      "quanhuyen":"80125"
   },
   {
      "id":"8012505",
      "phuongxa":"Xã Long Khê",
      "quanhuyen":"80125"
   },
   {
      "id":"8012507",
      "phuongxa":"Xã Long Định",
      "quanhuyen":"80125"
   },
   {
      "id":"8012509",
      "phuongxa":"Xã Phước Vân",
      "quanhuyen":"80125"
   },
   {
      "id":"8012511",
      "phuongxa":"Xã Long Cang",
      "quanhuyen":"80125"
   },
   {
      "id":"8012513",
      "phuongxa":"Xã Long Sơn",
      "quanhuyen":"80125"
   },
   {
      "id":"8012515",
      "phuongxa":"Xã Long Hòa",
      "quanhuyen":"80125"
   },
   {
      "id":"8012519",
      "phuongxa":"Xã Phước Tuy",
      "quanhuyen":"80125"
   },
   {
      "id":"8012521",
      "phuongxa":"Xã Tân Ân",
      "quanhuyen":"80125"
   },
   {
      "id":"8012523",
      "phuongxa":"Xã Tân Chánh",
      "quanhuyen":"80125"
   },
   {
      "id":"8012525",
      "phuongxa":"Xã Mỹ Lệ",
      "quanhuyen":"80125"
   },
   {
      "id":"8012527",
      "phuongxa":"Xã Tân Lân",
      "quanhuyen":"80125"
   },
   {
      "id":"8012529",
      "phuongxa":"Xã Phước Đông",
      "quanhuyen":"80125"
   },
   {
      "id":"8012531",
      "phuongxa":"Xã Long Hựu Tây",
      "quanhuyen":"80125"
   },
   {
      "id":"8012533",
      "phuongxa":"Xã Long Hựu Đông",
      "quanhuyen":"80125"
   },
   {
      "id":"8012700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80127"
   },
   {
      "id":"8012701",
      "phuongxa":"Thị trấn Cần Giuộc",
      "quanhuyen":"80127"
   },
   {
      "id":"8012703",
      "phuongxa":"Xã Phước Lý",
      "quanhuyen":"80127"
   },
   {
      "id":"8012705",
      "phuongxa":"Xã Long Thượng",
      "quanhuyen":"80127"
   },
   {
      "id":"8012707",
      "phuongxa":"Xã Phứơc Hậu",
      "quanhuyen":"80127"
   },
   {
      "id":"8012709",
      "phuongxa":"Xã Mỹ Lộc",
      "quanhuyen":"80127"
   },
   {
      "id":"8012711",
      "phuongxa":"Xã Phước Lâm",
      "quanhuyen":"80127"
   },
   {
      "id":"8012713",
      "phuongxa":"Xã Thuận Thành",
      "quanhuyen":"80127"
   },
   {
      "id":"8012715",
      "phuongxa":"Xã Long Hậu",
      "quanhuyen":"80127"
   },
   {
      "id":"8012717",
      "phuongxa":"Xã Tân Kim",
      "quanhuyen":"80127"
   },
   {
      "id":"8012719",
      "phuongxa":"Xã Trường Bình",
      "quanhuyen":"80127"
   },
   {
      "id":"8012721",
      "phuongxa":"Xã Long An",
      "quanhuyen":"80127"
   },
   {
      "id":"8012723",
      "phuongxa":"Xã Phước Lại",
      "quanhuyen":"80127"
   },
   {
      "id":"8012725",
      "phuongxa":"Xã Phước Vĩnh Tây",
      "quanhuyen":"80127"
   },
   {
      "id":"8012729",
      "phuongxa":"Xã Đông Thạnh",
      "quanhuyen":"80127"
   },
   {
      "id":"8012731",
      "phuongxa":"Xã Phước Vĩnh Đông",
      "quanhuyen":"80127"
   },
   {
      "id":"8012733",
      "phuongxa":"Xã Tân Tập",
      "quanhuyen":"80127"
   },
   {
      "id":"8030000",
      "phuongxa":"Không xác định",
      "quanhuyen":"80300"
   },
   {
      "id":"8030100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80301"
   },
   {
      "id":"8030101",
      "phuongxa":"Phường 1",
      "quanhuyen":"80301"
   },
   {
      "id":"8030103",
      "phuongxa":"Phường 2",
      "quanhuyen":"80301"
   },
   {
      "id":"8030105",
      "phuongxa":"Phường 3",
      "quanhuyen":"80301"
   },
   {
      "id":"8030107",
      "phuongxa":"Phường 4",
      "quanhuyen":"80301"
   },
   {
      "id":"8030109",
      "phuongxa":"Phường 6",
      "quanhuyen":"80301"
   },
   {
      "id":"8030111",
      "phuongxa":"Phường 11",
      "quanhuyen":"80301"
   },
   {
      "id":"8030113",
      "phuongxa":"Xã Mỹ Ngãi",
      "quanhuyen":"80301"
   },
   {
      "id":"8030115",
      "phuongxa":"Xã Mỹ Tân",
      "quanhuyen":"80301"
   },
   {
      "id":"8030119",
      "phuongxa":"Xã Hòa An",
      "quanhuyen":"80301"
   },
   {
      "id":"8030121",
      "phuongxa":"Xã Tân Thuận Đông",
      "quanhuyen":"80301"
   },
   {
      "id":"8030123",
      "phuongxa":"Xã Tân Thuận Tây",
      "quanhuyen":"80301"
   },
   {
      "id":"8030125",
      "phuongxa":"Xã Tịnh Thới",
      "quanhuyen":"80301"
   },
   {
      "id":"8030126",
      "phuongxa":"phong mỹ",
      "quanhuyen":"80301"
   },
   {
      "id":"8030300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80303"
   },
   {
      "id":"8030301",
      "phuongxa":"Phường 1",
      "quanhuyen":"80303"
   },
   {
      "id":"8030303",
      "phuongxa":"Phường 2",
      "quanhuyen":"80303"
   },
   {
      "id":"8030305",
      "phuongxa":"Phường 3",
      "quanhuyen":"80303"
   },
   {
      "id":"8111501",
      "phuongxa":"Thị trấn Thạnh Phú",
      "quanhuyen":"81115"
   },
   {
      "id":"8111505",
      "phuongxa":"Xã Đại Điền",
      "quanhuyen":"81115"
   },
   {
      "id":"8111507",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"81115"
   },
   {
      "id":"8111509",
      "phuongxa":"Xã Thới Thạnh",
      "quanhuyen":"81115"
   },
   {
      "id":"8111511",
      "phuongxa":"Xã Quới Điền",
      "quanhuyen":"81115"
   },
   {
      "id":"8111513",
      "phuongxa":"Xã Mỹ Hưng",
      "quanhuyen":"81115"
   },
   {
      "id":"8111515",
      "phuongxa":"Xã Hòa Lợi",
      "quanhuyen":"81115"
   },
   {
      "id":"8111517",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"81115"
   },
   {
      "id":"8111519",
      "phuongxa":"Xã An Thạnh",
      "quanhuyen":"81115"
   },
   {
      "id":"8111521",
      "phuongxa":"Xã An Thuận",
      "quanhuyen":"81115"
   },
   {
      "id":"8111523",
      "phuongxa":"Xã An Điền",
      "quanhuyen":"81115"
   },
   {
      "id":"8111527",
      "phuongxa":"Xã An Nhơn",
      "quanhuyen":"81115"
   },
   {
      "id":"8111529",
      "phuongxa":"Xã Giao Thạnh",
      "quanhuyen":"81115"
   },
   {
      "id":"8111531",
      "phuongxa":"Xã Thạnh Hải",
      "quanhuyen":"81115"
   },
   {
      "id":"8111533",
      "phuongxa":"Xã Thạnh Phong",
      "quanhuyen":"81115"
   },
   {
      "id":"8130000",
      "phuongxa":"Không xác định",
      "quanhuyen":"81300"
   },
   {
      "id":"8130100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81301"
   },
   {
      "id":"8130103",
      "phuongxa":"Phường Vĩnh Thanh",
      "quanhuyen":"81301"
   },
   {
      "id":"8130105",
      "phuongxa":"Phường Vĩnh Hiệp",
      "quanhuyen":"81301"
   },
   {
      "id":"8130107",
      "phuongxa":"Phường Vĩnh Lạc",
      "quanhuyen":"81301"
   },
   {
      "id":"8130109",
      "phuongxa":"Phường An Hòa",
      "quanhuyen":"81301"
   },
   {
      "id":"8130111",
      "phuongxa":"Phường Rạch Sỏi",
      "quanhuyen":"81301"
   },
   {
      "id":"8130113",
      "phuongxa":"Phường Vĩnh Thông",
      "quanhuyen":"81301"
   },
   {
      "id":"8130115",
      "phuongxa":"Xã Phi Thông",
      "quanhuyen":"81301"
   },
   {
      "id":"8130500",
      "phuongxa":"Không xác định",
      "quanhuyen":"81305"
   },
   {
      "id":"8130501",
      "phuongxa":"Thị trấn Hòn Đất",
      "quanhuyen":"81305"
   },
   {
      "id":"8130503",
      "phuongxa":"Xã Bình Sơn",
      "quanhuyen":"81305"
   },
   {
      "id":"8130505",
      "phuongxa":"Xã Bình Giang",
      "quanhuyen":"81305"
   },
   {
      "id":"8130507",
      "phuongxa":"Xã Nam Thái Sơn",
      "quanhuyen":"81305"
   },
   {
      "id":"8130511",
      "phuongxa":"Xã Sơn Kiên",
      "quanhuyen":"81305"
   },
   {
      "id":"8130513",
      "phuongxa":"Xã Sóc Sơn",
      "quanhuyen":"81305"
   },
   {
      "id":"8130515",
      "phuongxa":"Xã Thổ Sơn",
      "quanhuyen":"81305"
   },
   {
      "id":"8130517",
      "phuongxa":"Xã Mỹ Lâm",
      "quanhuyen":"81305"
   },
   {
      "id":"8130700",
      "phuongxa":"Không xác định",
      "quanhuyen":"81307"
   },
   {
      "id":"8130701",
      "phuongxa":"Thị trấn Tân Hiệp",
      "quanhuyen":"81307"
   },
   {
      "id":"8130703",
      "phuongxa":"Xã Tân Hội",
      "quanhuyen":"81307"
   },
   {
      "id":"8130705",
      "phuongxa":"Xã Tân Hiệp B",
      "quanhuyen":"81307"
   },
   {
      "id":"8130707",
      "phuongxa":"Xã Thạnh Đông B",
      "quanhuyen":"81307"
   },
   {
      "id":"8130709",
      "phuongxa":"Xã Thạnh Đông",
      "quanhuyen":"81307"
   },
   {
      "id":"8130711",
      "phuongxa":"Xã Tân Hiệp A",
      "quanhuyen":"81307"
   },
   {
      "id":"8130713",
      "phuongxa":"Xã Thạnh Đông A",
      "quanhuyen":"81307"
   },
   {
      "id":"8130715",
      "phuongxa":"Xã Thạnh Trị",
      "quanhuyen":"81307"
   },
   {
      "id":"8130900",
      "phuongxa":"Không xác định",
      "quanhuyen":"81309"
   },
   {
      "id":"8130901",
      "phuongxa":"Thị trấn Minh Lương",
      "quanhuyen":"81309"
   },
   {
      "id":"8130903",
      "phuongxa":"Xã Mong Thọ A",
      "quanhuyen":"81309"
   },
   {
      "id":"8130905",
      "phuongxa":"Xã Mong Thọ B",
      "quanhuyen":"81309"
   },
   {
      "id":"8130907",
      "phuongxa":"Xã Giục Tượng",
      "quanhuyen":"81309"
   },
   {
      "id":"8130909",
      "phuongxa":"Xã Vĩnh Hòa Hiệp",
      "quanhuyen":"81309"
   },
   {
      "id":"8130911",
      "phuongxa":"Xã Minh Hòa",
      "quanhuyen":"81309"
   },
   {
      "id":"8130913",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"81309"
   },
   {
      "id":"8130915",
      "phuongxa":"Xã Thạnh Lộc",
      "quanhuyen":"81309"
   },
   {
      "id":"8131100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81311"
   },
   {
      "id":"8131103",
      "phuongxa":"Xã Thạnh Hưng",
      "quanhuyen":"81311"
   },
   {
      "id":"8131105",
      "phuongxa":"Xã Thạnh Phước",
      "quanhuyen":"81311"
   },
   {
      "id":"8131107",
      "phuongxa":"Xã Thạnh Lộc",
      "quanhuyen":"81311"
   },
   {
      "id":"8131109",
      "phuongxa":"Xã Thạnh Hòa",
      "quanhuyen":"81311"
   },
   {
      "id":"8131111",
      "phuongxa":"Xã Bàn Tân Định",
      "quanhuyen":"81311"
   },
   {
      "id":"8131113",
      "phuongxa":"Xã Ngọc Chúc",
      "quanhuyen":"81311"
   },
   {
      "id":"8131115",
      "phuongxa":"Xã Hòa Hưng",
      "quanhuyen":"81311"
   },
   {
      "id":"8131117",
      "phuongxa":"Xã Hoà Lợi",
      "quanhuyen":"81311"
   },
   {
      "id":"8131119",
      "phuongxa":"Xã Hoà An",
      "quanhuyen":"81311"
   },
   {
      "id":"8131121",
      "phuongxa":"Xã Long Thạnh",
      "quanhuyen":"81311"
   },
   {
      "id":"8131123",
      "phuongxa":"Xã Vĩnh Thạnh",
      "quanhuyen":"81311"
   },
   {
      "id":"8131124",
      "phuongxa":"Ngọc Hòa",
      "quanhuyen":"81311"
   },
   {
      "id":"8131300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81313"
   },
   {
      "id":"8131301",
      "phuongxa":"Thị trấn Gò Quao",
      "quanhuyen":"81313"
   },
   {
      "id":"8131303",
      "phuongxa":"Xã Vĩnh Hòa Hưng Bắc",
      "quanhuyen":"81313"
   },
   {
      "id":"8131305",
      "phuongxa":"Xã Định Hòa",
      "quanhuyen":"81313"
   },
   {
      "id":"7050913",
      "phuongxa":"Xã Phước Đại",
      "quanhuyen":"70509"
   },
   {
      "id":"7050915",
      "phuongxa":"Xã Phước Chính",
      "quanhuyen":"70509"
   },
   {
      "id":"7050917",
      "phuongxa":"Xã Phước Trung",
      "quanhuyen":"70509"
   },
   {
      "id":"7051100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70511"
   },
   {
      "id":"7051103",
      "phuongxa":"Xã Công Hải",
      "quanhuyen":"70511"
   },
   {
      "id":"7051105",
      "phuongxa":"Xã Phước Kháng",
      "quanhuyen":"70511"
   },
   {
      "id":"7051107",
      "phuongxa":"Xã Lợi Hải",
      "quanhuyen":"70511"
   },
   {
      "id":"7051109",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"70511"
   },
   {
      "id":"7051111",
      "phuongxa":"Xã Bắc Phong",
      "quanhuyen":"70511"
   },
   {
      "id":"7070000",
      "phuongxa":"Không xác định",
      "quanhuyen":"70700"
   },
   {
      "id":"7070101",
      "phuongxa":"Thị trấn Đồng Xoài",
      "quanhuyen":"70701"
   },
   {
      "id":"7070103",
      "phuongxa":"Xã Thuận Lợi",
      "quanhuyen":"70701"
   },
   {
      "id":"7070107",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"70701"
   },
   {
      "id":"7070109",
      "phuongxa":"Xã Tân Phước",
      "quanhuyen":"70701"
   },
   {
      "id":"7070111",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"70701"
   },
   {
      "id":"7070113",
      "phuongxa":"Xã Tân Lợi",
      "quanhuyen":"70701"
   },
   {
      "id":"7070115",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"70701"
   },
   {
      "id":"7070117",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"70701"
   },
   {
      "id":"7070300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70703"
   },
   {
      "id":"7070301",
      "phuongxa":"Phường Thác Mơ",
      "quanhuyen":"82600"
   },
   {
      "id":"7070303",
      "phuongxa":"Phường Phước Bình",
      "quanhuyen":"82600"
   },
   {
      "id":"7070307",
      "phuongxa":"Xã Bù Gia Mâp",
      "quanhuyen":"70703"
   },
   {
      "id":"7070309",
      "phuongxa":"Xã Đức Hạnh",
      "quanhuyen":"70703"
   },
   {
      "id":"7070311",
      "phuongxa":"Xã Đa Kia",
      "quanhuyen":"70703"
   },
   {
      "id":"7070313",
      "phuongxa":"Xã Bình Thắng",
      "quanhuyen":"70703"
   },
   {
      "id":"7070315",
      "phuongxa":"Xã Bình Phước",
      "quanhuyen":"70703"
   },
   {
      "id":"7070317",
      "phuongxa":"Phường Sơn Giang",
      "quanhuyen":"82600"
   },
   {
      "id":"7070319",
      "phuongxa":"Xã Phước Tín",
      "quanhuyen":"82600"
   },
   {
      "id":"7070321",
      "phuongxa":"Xã Long Hưng",
      "quanhuyen":"70703"
   },
   {
      "id":"7070325",
      "phuongxa":"Xã Bù Nho",
      "quanhuyen":"70703"
   },
   {
      "id":"7070327",
      "phuongxa":"Xã Phú Riềng",
      "quanhuyen":"70703"
   },
   {
      "id":"7070329",
      "phuongxa":"Xã Long Tân",
      "quanhuyen":"82623"
   },
   {
      "id":"7070331",
      "phuongxa":"Xã Phú Trung",
      "quanhuyen":"82623"
   },
   {
      "id":"7070333",
      "phuongxa":"Xã Long Bình",
      "quanhuyen":"82623"
   },
   {
      "id":"7070401",
      "phuongxa":"Xã Thạnh An",
      "quanhuyen":"70704"
   },
   {
      "id":"7070500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70705"
   },
   {
      "id":"7070501",
      "phuongxa":"Thị trấn Lộc Ninh",
      "quanhuyen":"70705"
   },
   {
      "id":"7070503",
      "phuongxa":"Xã Hưng Phước",
      "quanhuyen":"70705"
   },
   {
      "id":"7070505",
      "phuongxa":"Xã Thiện Hưng",
      "quanhuyen":"70705"
   },
   {
      "id":"7070507",
      "phuongxa":"Xã Thanh Hòa",
      "quanhuyen":"70705"
   },
   {
      "id":"7070509",
      "phuongxa":"Xã Lộc Tấn",
      "quanhuyen":"70705"
   },
   {
      "id":"7070511",
      "phuongxa":"Xã Lộc An",
      "quanhuyen":"70705"
   },
   {
      "id":"7070513",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"70705"
   },
   {
      "id":"7070515",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"70705"
   },
   {
      "id":"7070517",
      "phuongxa":"Xã Lộc Hòa",
      "quanhuyen":"70705"
   },
   {
      "id":"7070519",
      "phuongxa":"Xã Lộc Hiệp",
      "quanhuyen":"70705"
   },
   {
      "id":"7070521",
      "phuongxa":"Xã Lộc Quang",
      "quanhuyen":"70705"
   },
   {
      "id":"7070523",
      "phuongxa":"Xã Lộc Khánh",
      "quanhuyen":"70705"
   },
   {
      "id":"7070525",
      "phuongxa":"Xã Lộc Thành",
      "quanhuyen":"70705"
   },
   {
      "id":"7070527",
      "phuongxa":"Xã Lộc Thái",
      "quanhuyen":"70705"
   },
   {
      "id":"7070529",
      "phuongxa":"Xã Lộc Hưng",
      "quanhuyen":"70705"
   },
   {
      "id":"7070531",
      "phuongxa":"Xã Lộc Thiện",
      "quanhuyen":"70705"
   },
   {
      "id":"7070533",
      "phuongxa":"Xã Lộc Điền",
      "quanhuyen":"70705"
   },
   {
      "id":"7070535",
      "phuongxa":"Xã Lộc Thuận",
      "quanhuyen":"70705"
   },
   {
      "id":"7070700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70707"
   },
   {
      "id":"7070701",
      "phuongxa":"Thị trấn Đức Phong",
      "quanhuyen":"70707"
   },
   {
      "id":"7070705",
      "phuongxa":"Xã Bom Bo",
      "quanhuyen":"70707"
   },
   {
      "id":"7070707",
      "phuongxa":"Xã Thọ Sơn",
      "quanhuyen":"70707"
   },
   {
      "id":"7070709",
      "phuongxa":"Xã Minh Hưng",
      "quanhuyen":"70707"
   },
   {
      "id":"7070711",
      "phuongxa":"Xã Đoàn Kết",
      "quanhuyen":"70707"
   },
   {
      "id":"7070713",
      "phuongxa":"Xã Đồng Nai",
      "quanhuyen":"70707"
   },
   {
      "id":"7070715",
      "phuongxa":"Xã Đức Liễu",
      "quanhuyen":"70707"
   },
   {
      "id":"7070717",
      "phuongxa":"Xã Thống Nhất",
      "quanhuyen":"70707"
   },
   {
      "id":"7070719",
      "phuongxa":"Xã Nghĩa Trung",
      "quanhuyen":"70707"
   },
   {
      "id":"7070721",
      "phuongxa":"Xã Đăng Hà",
      "quanhuyen":"70707"
   },
   {
      "id":"7070901",
      "phuongxa":"Phường An Lộc",
      "quanhuyen":"82619"
   },
   {
      "id":"7070903",
      "phuongxa":"Thị trấn Chơn Thành",
      "quanhuyen":"70709"
   },
   {
      "id":"7070905",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"70709"
   },
   {
      "id":"7070907",
      "phuongxa":"Xã An Khương",
      "quanhuyen":"70709"
   },
   {
      "id":"8030309",
      "phuongxa":"Xã Tân Khánh Đông",
      "quanhuyen":"80303"
   },
   {
      "id":"8030311",
      "phuongxa":"Xã Tân Phú Đông",
      "quanhuyen":"80303"
   },
   {
      "id":"8030313",
      "phuongxa":"Xã Tân Quy Đông",
      "quanhuyen":"80303"
   },
   {
      "id":"8030315",
      "phuongxa":"Xã Tân Quy Tây",
      "quanhuyen":"80303"
   },
   {
      "id":"8030500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80305"
   },
   {
      "id":"8030503",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"80305"
   },
   {
      "id":"8030505",
      "phuongxa":"Xã Tân Hộ Cơ",
      "quanhuyen":"80305"
   },
   {
      "id":"8030507",
      "phuongxa":"Xã Thông Bình",
      "quanhuyen":"80305"
   },
   {
      "id":"8030509",
      "phuongxa":"Xã Tân Thành A",
      "quanhuyen":"80305"
   },
   {
      "id":"8030511",
      "phuongxa":"Xã Tân Thành B",
      "quanhuyen":"80305"
   },
   {
      "id":"8030513",
      "phuongxa":"Xã Tân Phước",
      "quanhuyen":"80305"
   },
   {
      "id":"8030515",
      "phuongxa":"Xã An Phước",
      "quanhuyen":"80305"
   },
   {
      "id":"8030517",
      "phuongxa":"Xã Tân Công Chí",
      "quanhuyen":"80305"
   },
   {
      "id":"8030700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80307"
   },
   {
      "id":"8030703",
      "phuongxa":"Xã Long Khánh A",
      "quanhuyen":"80307"
   },
   {
      "id":"8030705",
      "phuongxa":"Xã Long Khánh B",
      "quanhuyen":"80307"
   },
   {
      "id":"8030707",
      "phuongxa":"Xã Phú Thuận A",
      "quanhuyen":"80307"
   },
   {
      "id":"8030709",
      "phuongxa":"Xã Phú Thuận B",
      "quanhuyen":"80307"
   },
   {
      "id":"8030711",
      "phuongxa":"Xã Long Thuận",
      "quanhuyen":"80307"
   },
   {
      "id":"8030713",
      "phuongxa":"Xã Thường Phước 1",
      "quanhuyen":"80307"
   },
   {
      "id":"8030715",
      "phuongxa":"Xã Thường Phước 2",
      "quanhuyen":"80307"
   },
   {
      "id":"8030717",
      "phuongxa":"Xã Thường Thới Tiền",
      "quanhuyen":"80307"
   },
   {
      "id":"8030721",
      "phuongxa":"Xã Thường Thới Hậu A",
      "quanhuyen":"80307"
   },
   {
      "id":"8030723",
      "phuongxa":"Xã Thường Thới Hậu B",
      "quanhuyen":"80307"
   },
   {
      "id":"8030725",
      "phuongxa":"Xã Tân Hội",
      "quanhuyen":"80307"
   },
   {
      "id":"8030727",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"80307"
   },
   {
      "id":"8030729",
      "phuongxa":"Xã An Bình A",
      "quanhuyen":"80307"
   },
   {
      "id":"8030731",
      "phuongxa":"Xã An Bình B",
      "quanhuyen":"80307"
   },
   {
      "id":"8030733",
      "phuongxa":"Xã Bình Phước",
      "quanhuyen":"80307"
   },
   {
      "id":"8030901",
      "phuongxa":"Thị trấn Tràm Chim",
      "quanhuyen":"80309"
   },
   {
      "id":"8030903",
      "phuongxa":"Xã An Hòa",
      "quanhuyen":"80309"
   },
   {
      "id":"8030905",
      "phuongxa":"Xã An Long",
      "quanhuyen":"80309"
   },
   {
      "id":"8030907",
      "phuongxa":"Xã Phú Ninh",
      "quanhuyen":"80309"
   },
   {
      "id":"8030909",
      "phuongxa":"Xã Phú Thành A",
      "quanhuyen":"80309"
   },
   {
      "id":"8030911",
      "phuongxa":"Xã Phú Thành B",
      "quanhuyen":"80309"
   },
   {
      "id":"8030913",
      "phuongxa":"Xã Phú Thọ",
      "quanhuyen":"80309"
   },
   {
      "id":"8030915",
      "phuongxa":"Xã Tân Công Sính",
      "quanhuyen":"80309"
   },
   {
      "id":"8030919",
      "phuongxa":"Xã Phú Đức",
      "quanhuyen":"80309"
   },
   {
      "id":"8030921",
      "phuongxa":"Xã Phú Hiệp",
      "quanhuyen":"80309"
   },
   {
      "id":"8030923",
      "phuongxa":"Xã Hoà Bình",
      "quanhuyen":"80309"
   },
   {
      "id":"8031100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80311"
   },
   {
      "id":"8031101",
      "phuongxa":"Thị trấn Thanh Bình",
      "quanhuyen":"80311"
   },
   {
      "id":"8031103",
      "phuongxa":"Xã Bình Tấn",
      "quanhuyen":"80311"
   },
   {
      "id":"8031105",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"80311"
   },
   {
      "id":"8031107",
      "phuongxa":"Xã Bình Thành",
      "quanhuyen":"80311"
   },
   {
      "id":"8031109",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"80311"
   },
   {
      "id":"8031111",
      "phuongxa":"Xã An Phong",
      "quanhuyen":"80311"
   },
   {
      "id":"8031113",
      "phuongxa":"Xã Tân Thạnh",
      "quanhuyen":"80311"
   },
   {
      "id":"8031115",
      "phuongxa":"Xã Phú Lợi",
      "quanhuyen":"80311"
   },
   {
      "id":"8031117",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"80311"
   },
   {
      "id":"8031119",
      "phuongxa":"Xã Tân Quới",
      "quanhuyen":"80311"
   },
   {
      "id":"8031123",
      "phuongxa":"Xã Tân Huề",
      "quanhuyen":"80311"
   },
   {
      "id":"8031125",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"80311"
   },
   {
      "id":"8031127",
      "phuongxa":"Xã Hội An Đông",
      "quanhuyen":"80311"
   },
   {
      "id":"8031300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80313"
   },
   {
      "id":"8031301",
      "phuongxa":"Thị trấn Mỹ An",
      "quanhuyen":"80313"
   },
   {
      "id":"8031303",
      "phuongxa":"Xã Hưng Thạnh",
      "quanhuyen":"80313"
   },
   {
      "id":"8031305",
      "phuongxa":"Xã Trường Xuân",
      "quanhuyen":"80313"
   },
   {
      "id":"8031307",
      "phuongxa":"Xã Mỹ Hòa",
      "quanhuyen":"80313"
   },
   {
      "id":"8031309",
      "phuongxa":"Xã Tân Kiều",
      "quanhuyen":"80313"
   },
   {
      "id":"8031313",
      "phuongxa":"Xã Mỹ An",
      "quanhuyen":"80313"
   },
   {
      "id":"8031315",
      "phuongxa":"Xã Phú Điền",
      "quanhuyen":"80313"
   },
   {
      "id":"8031317",
      "phuongxa":"Xã Thanh Mỹ",
      "quanhuyen":"80313"
   },
   {
      "id":"8031319",
      "phuongxa":"Xã Mỹ Quý",
      "quanhuyen":"80313"
   },
   {
      "id":"8031321",
      "phuongxa":"Xã Mỹ Đông",
      "quanhuyen":"80313"
   },
   {
      "id":"8031323",
      "phuongxa":"Xã Láng Biển",
      "quanhuyen":"80313"
   },
   {
      "id":"8031325",
      "phuongxa":"Xã Thạnh Lợi",
      "quanhuyen":"80313"
   },
   {
      "id":"8131307",
      "phuongxa":"Xã Thới Quản",
      "quanhuyen":"81313"
   },
   {
      "id":"8131309",
      "phuongxa":"Xã Định An",
      "quanhuyen":"81313"
   },
   {
      "id":"8131311",
      "phuongxa":"Xã Thủy Liểu",
      "quanhuyen":"81313"
   },
   {
      "id":"8131315",
      "phuongxa":"Xã Vĩnh Phước A",
      "quanhuyen":"81313"
   },
   {
      "id":"8131317",
      "phuongxa":"Xã Vĩnh Phước B",
      "quanhuyen":"81313"
   },
   {
      "id":"8131319",
      "phuongxa":"Xã Vĩnh Tuy",
      "quanhuyen":"81313"
   },
   {
      "id":"8131320",
      "phuongxa":"vĩnh tiến",
      "quanhuyen":"81313"
   },
   {
      "id":"8131500",
      "phuongxa":"Không xác định",
      "quanhuyen":"81315"
   },
   {
      "id":"8131501",
      "phuongxa":"Thị trấn Thứ Ba",
      "quanhuyen":"81315"
   },
   {
      "id":"8131503",
      "phuongxa":"Xã Tây Yên",
      "quanhuyen":"81315"
   },
   {
      "id":"8131505",
      "phuongxa":"Xã Tây Yên A",
      "quanhuyen":"81315"
   },
   {
      "id":"8131507",
      "phuongxa":"Xã Nam Yên",
      "quanhuyen":"81315"
   },
   {
      "id":"8131509",
      "phuongxa":"Xã Hưng Yên",
      "quanhuyen":"81315"
   },
   {
      "id":"8131511",
      "phuongxa":"Xã Nam Thái",
      "quanhuyen":"81315"
   },
   {
      "id":"8131513",
      "phuongxa":"Xã Nam Thái A",
      "quanhuyen":"81315"
   },
   {
      "id":"8131515",
      "phuongxa":"Xã Đông Thái",
      "quanhuyen":"81315"
   },
   {
      "id":"8131519",
      "phuongxa":"Xã Thạnh Yên",
      "quanhuyen":"81315"
   },
   {
      "id":"8131700",
      "phuongxa":"Không xác định",
      "quanhuyen":"81317"
   },
   {
      "id":"8131701",
      "phuongxa":"Thị trấn Thứ Mười Một",
      "quanhuyen":"81317"
   },
   {
      "id":"8131703",
      "phuongxa":"Xã Thuận Hoà",
      "quanhuyen":"81317"
   },
   {
      "id":"8131705",
      "phuongxa":"Xã Đông Hòa",
      "quanhuyen":"81317"
   },
   {
      "id":"8131707",
      "phuongxa":"Xã An Minh Bắc",
      "quanhuyen":"81317"
   },
   {
      "id":"8131709",
      "phuongxa":"Xã Đông Thạnh",
      "quanhuyen":"81317"
   },
   {
      "id":"8131711",
      "phuongxa":"Xã Đông Hưng",
      "quanhuyen":"81317"
   },
   {
      "id":"8131715",
      "phuongxa":"Xã Đông Hưng B",
      "quanhuyen":"81317"
   },
   {
      "id":"8131717",
      "phuongxa":"Xã Vân Khánh",
      "quanhuyen":"81317"
   },
   {
      "id":"8131900",
      "phuongxa":"Không xác định",
      "quanhuyen":"81319"
   },
   {
      "id":"8131901",
      "phuongxa":"Thị trấn Vĩnh Thuận",
      "quanhuyen":"81319"
   },
   {
      "id":"8131903",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"81319"
   },
   {
      "id":"8131905",
      "phuongxa":"Xã Vĩnh Bình Bắc",
      "quanhuyen":"81319"
   },
   {
      "id":"8131907",
      "phuongxa":"Xã Vĩnh Bình Nam",
      "quanhuyen":"81319"
   },
   {
      "id":"8131909",
      "phuongxa":"Xã Minh Thuận",
      "quanhuyen":"81319"
   },
   {
      "id":"8131911",
      "phuongxa":"Xã Vĩnh Thuận",
      "quanhuyen":"81319"
   },
   {
      "id":"8131913",
      "phuongxa":"Xã Tân Thuận",
      "quanhuyen":"81319"
   },
   {
      "id":"8131915",
      "phuongxa":"Xã Vĩnh Phong",
      "quanhuyen":"81319"
   },
   {
      "id":"8132100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81321"
   },
   {
      "id":"8132101",
      "phuongxa":"Phường Dương Đông",
      "quanhuyen":"81321"
   },
   {
      "id":"8132103",
      "phuongxa":"Xã Cửa Cạn",
      "quanhuyen":"81321"
   },
   {
      "id":"8132105",
      "phuongxa":"Xã Gành Dầu",
      "quanhuyen":"81321"
   },
   {
      "id":"8132107",
      "phuongxa":"Xã Cửa Dương",
      "quanhuyen":"81321"
   },
   {
      "id":"8132109",
      "phuongxa":"Xã Hàm Ninh",
      "quanhuyen":"81321"
   },
   {
      "id":"8132111",
      "phuongxa":"Xã Dương Tơ",
      "quanhuyen":"81321"
   },
   {
      "id":"8132113",
      "phuongxa":"Phường An Thới",
      "quanhuyen":"81321"
   },
   {
      "id":"8132115",
      "phuongxa":"Xã Bãi Thơm",
      "quanhuyen":"81321"
   },
   {
      "id":"8132117",
      "phuongxa":"Xã Thổ Châu",
      "quanhuyen":"81321"
   },
   {
      "id":"8132300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81323"
   },
   {
      "id":"8132301",
      "phuongxa":"Xã Hòn Tre",
      "quanhuyen":"81323"
   },
   {
      "id":"8132303",
      "phuongxa":"Xã Hòn Nghệ",
      "quanhuyen":"81323"
   },
   {
      "id":"8132305",
      "phuongxa":"Xã Lại Sơn",
      "quanhuyen":"81323"
   },
   {
      "id":"8132307",
      "phuongxa":"Xã Sơn Hải",
      "quanhuyen":"81323"
   },
   {
      "id":"8132309",
      "phuongxa":"Xã An Sơn",
      "quanhuyen":"81323"
   },
   {
      "id":"8132500",
      "phuongxa":"Không xác định",
      "quanhuyen":"81325"
   },
   {
      "id":"8132501",
      "phuongxa":"Phường Pháo Đài",
      "quanhuyen":"81325"
   },
   {
      "id":"8132503",
      "phuongxa":"Phường Bình San",
      "quanhuyen":"81325"
   },
   {
      "id":"8132507",
      "phuongxa":"Phường Đông Hồ",
      "quanhuyen":"81325"
   },
   {
      "id":"8132509",
      "phuongxa":"Phường Mỹ Đức",
      "quanhuyen":"81325"
   },
   {
      "id":"8132511",
      "phuongxa":"Xã Tiên Hải",
      "quanhuyen":"81325"
   },
   {
      "id":"8132513",
      "phuongxa":"Xã Thuận Yên",
      "quanhuyen":"81325"
   },
   {
      "id":"8132626",
      "phuongxa":"Lò  Bom",
      "quanhuyen":"81326"
   },
   {
      "id":"813271",
      "phuongxa":"Vĩnh Hòa",
      "quanhuyen":"81327"
   },
   {
      "id":"8132772",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"81327"
   },
   {
      "id":"8150000",
      "phuongxa":"Không xác định",
      "quanhuyen":"81500"
   },
   {
      "id":"8150300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81503"
   },
   {
      "id":"8150303",
      "phuongxa":"Xã Thạnh Thắng",
      "quanhuyen":"81503"
   },
   {
      "id":"8150305",
      "phuongxa":"Xã Thạnh An",
      "quanhuyen":"81503"
   },
   {
      "id":"8150307",
      "phuongxa":"Xã Thạnh Qưới",
      "quanhuyen":"81503"
   },
   {
      "id":"8150309",
      "phuongxa":"Xã Vĩnh Trinh",
      "quanhuyen":"81503"
   },
   {
      "id":"8150311",
      "phuongxa":"Phường Thới Thuận",
      "quanhuyen":"81503"
   },
   {
      "id":"8150313",
      "phuongxa":"Phường Tân Lộc",
      "quanhuyen":"81503"
   },
   {
      "id":"8150315",
      "phuongxa":"Xã Trung Nhất",
      "quanhuyen":"81503"
   },
   {
      "id":"7070909",
      "phuongxa":"Xã Thanh Lương",
      "quanhuyen":"82619"
   },
   {
      "id":"7070911",
      "phuongxa":"Xã An Phú",
      "quanhuyen":"70709"
   },
   {
      "id":"7070913",
      "phuongxa":"Xã Thanh Phú",
      "quanhuyen":"82619"
   },
   {
      "id":"7070915",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"70709"
   },
   {
      "id":"7070919",
      "phuongxa":"Xã Minh Đức",
      "quanhuyen":"70709"
   },
   {
      "id":"7070921",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"70709"
   },
   {
      "id":"7070923",
      "phuongxa":"Xã Tân Quan",
      "quanhuyen":"70709"
   },
   {
      "id":"7070925",
      "phuongxa":"Xã Đồng Nơ",
      "quanhuyen":"82590"
   },
   {
      "id":"7070927",
      "phuongxa":"Xã Tân Khai",
      "quanhuyen":"70709"
   },
   {
      "id":"7070931",
      "phuongxa":"Xã Nha Bích",
      "quanhuyen":"70709"
   },
   {
      "id":"7070933",
      "phuongxa":"Xã Minh Hưng",
      "quanhuyen":"70709"
   },
   {
      "id":"7070935",
      "phuongxa":"Xã Minh Thành",
      "quanhuyen":"70709"
   },
   {
      "id":"7070939",
      "phuongxa":"Xã Tân Lợi",
      "quanhuyen":"70709"
   },
   {
      "id":"7071100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70711"
   },
   {
      "id":"7071101",
      "phuongxa":"Thị trấn Tân Phú",
      "quanhuyen":"70711"
   },
   {
      "id":"7071103",
      "phuongxa":"Xã Thuận Lợi",
      "quanhuyen":"70711"
   },
   {
      "id":"7071105",
      "phuongxa":"Xã Đồng Tâm",
      "quanhuyen":"70711"
   },
   {
      "id":"7071107",
      "phuongxa":"Xã Tân Phước",
      "quanhuyen":"70711"
   },
   {
      "id":"7071109",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"70711"
   },
   {
      "id":"7071111",
      "phuongxa":"Xã Tân lợi",
      "quanhuyen":"70711"
   },
   {
      "id":"7071113",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"70711"
   },
   {
      "id":"7071115",
      "phuongxa":"Xã Tân Hoà",
      "quanhuyen":"70711"
   },
   {
      "id":"7071119",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"70711"
   },
   {
      "id":"7071121",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"70711"
   },
   {
      "id":"7071300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70713"
   },
   {
      "id":"7071301",
      "phuongxa":"Thị trấn Chơn Thành",
      "quanhuyen":"70713"
   },
   {
      "id":"7071303",
      "phuongxa":"Xã Minh Lập",
      "quanhuyen":"70713"
   },
   {
      "id":"7071305",
      "phuongxa":"Xã Tân Quan",
      "quanhuyen":"70713"
   },
   {
      "id":"7071307",
      "phuongxa":"Xã Minh Hưng",
      "quanhuyen":"70713"
   },
   {
      "id":"7071309",
      "phuongxa":"Xã Minh Long",
      "quanhuyen":"70713"
   },
   {
      "id":"7071311",
      "phuongxa":"Xã Minh Thành",
      "quanhuyen":"70713"
   },
   {
      "id":"7071313",
      "phuongxa":"Xã Nha Bích",
      "quanhuyen":"70713"
   },
   {
      "id":"7071315",
      "phuongxa":"Xã Minh Thắng",
      "quanhuyen":"70713"
   },
   {
      "id":"7071317",
      "phuongxa":"Xã Thành Tâm",
      "quanhuyen":"70713"
   },
   {
      "id":"7071500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70715"
   },
   {
      "id":"7071501",
      "phuongxa":"Xã Hưng Phước",
      "quanhuyen":"70715"
   },
   {
      "id":"7071503",
      "phuongxa":"Xã Thiện Hưng",
      "quanhuyen":"70715"
   },
   {
      "id":"7071505",
      "phuongxa":"Xã Thanh Hòa",
      "quanhuyen":"70715"
   },
   {
      "id":"7071507",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"70715"
   },
   {
      "id":"7071509",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"70715"
   },
   {
      "id":"7071511",
      "phuongxa":"Thị Trấn Thanh Bình",
      "quanhuyen":"70715"
   },
   {
      "id":"7071513",
      "phuongxa":"Xã Phước Thiện",
      "quanhuyen":"70715"
   },
   {
      "id":"7090000",
      "phuongxa":"Không xác định",
      "quanhuyen":"70900"
   },
   {
      "id":"7090100",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"82601"
   },
   {
      "id":"7090101",
      "phuongxa":"Phường 1",
      "quanhuyen":"82601"
   },
   {
      "id":"7090103",
      "phuongxa":"Phường 3",
      "quanhuyen":"82601"
   },
   {
      "id":"7090105",
      "phuongxa":"Phường 2",
      "quanhuyen":"82601"
   },
   {
      "id":"7090107",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"70901"
   },
   {
      "id":"7090300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70903"
   },
   {
      "id":"7090303",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"70903"
   },
   {
      "id":"7090305",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"70903"
   },
   {
      "id":"7090307",
      "phuongxa":"Xã Thạnh Tây",
      "quanhuyen":"70903"
   },
   {
      "id":"7090309",
      "phuongxa":"Xã Thạnh Bình",
      "quanhuyen":"70903"
   },
   {
      "id":"7090311",
      "phuongxa":"Xã Hòa Hiệp",
      "quanhuyen":"70903"
   },
   {
      "id":"7090313",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"70903"
   },
   {
      "id":"7090315",
      "phuongxa":"Xã Mỏ Công",
      "quanhuyen":"70903"
   },
   {
      "id":"7090317",
      "phuongxa":"Xã Trà Vong",
      "quanhuyen":"70903"
   },
   {
      "id":"7090500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70905"
   },
   {
      "id":"7090501",
      "phuongxa":"Thị trấn Tân Châu",
      "quanhuyen":"70905"
   },
   {
      "id":"7090503",
      "phuongxa":"Xã Tân Hà",
      "quanhuyen":"70905"
   },
   {
      "id":"7090507",
      "phuongxa":"Xã Tân Hội",
      "quanhuyen":"70905"
   },
   {
      "id":"7090509",
      "phuongxa":"Xã Tân Hoà",
      "quanhuyen":"70905"
   },
   {
      "id":"7090511",
      "phuongxa":"Xã Suối Ngô",
      "quanhuyen":"70905"
   },
   {
      "id":"7090513",
      "phuongxa":"Xã Suối Dây",
      "quanhuyen":"70905"
   },
   {
      "id":"7090515",
      "phuongxa":"Xã Tân Hiệp",
      "quanhuyen":"70905"
   },
   {
      "id":"7090517",
      "phuongxa":"Xã Thạnh Đông",
      "quanhuyen":"70905"
   },
   {
      "id":"7090519",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"70905"
   },
   {
      "id":"7090521",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"70905"
   },
   {
      "id":"7090523",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"70905"
   },
   {
      "id":"7090700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70907"
   },
   {
      "id":"8031500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80315"
   },
   {
      "id":"8031501",
      "phuongxa":"Thị trấn Mỹ Thọ",
      "quanhuyen":"80315"
   },
   {
      "id":"8031503",
      "phuongxa":"Xã Phong Mỹ",
      "quanhuyen":"80315"
   },
   {
      "id":"8031505",
      "phuongxa":"Xã Tân Nghĩa",
      "quanhuyen":"80315"
   },
   {
      "id":"8031507",
      "phuongxa":"Xã Phương Trà",
      "quanhuyen":"80315"
   },
   {
      "id":"8031509",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"80315"
   },
   {
      "id":"8031511",
      "phuongxa":"Xã Nhị Mỹ",
      "quanhuyen":"80315"
   },
   {
      "id":"8031513",
      "phuongxa":"Xã Mỹ Thọ",
      "quanhuyen":"80315"
   },
   {
      "id":"8031515",
      "phuongxa":"Xã Mỹ Hội",
      "quanhuyen":"80315"
   },
   {
      "id":"8031517",
      "phuongxa":"Xã Mỹ Xương",
      "quanhuyen":"80315"
   },
   {
      "id":"8031519",
      "phuongxa":"Xã Bình Hàng Trung",
      "quanhuyen":"80315"
   },
   {
      "id":"8031521",
      "phuongxa":"Xã Bình Hàng Tây",
      "quanhuyen":"80315"
   },
   {
      "id":"8031523",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"80315"
   },
   {
      "id":"8031527",
      "phuongxa":"Xã Mỹ Hiệp",
      "quanhuyen":"80315"
   },
   {
      "id":"8031529",
      "phuongxa":"Xã Ba Sao",
      "quanhuyen":"80315"
   },
   {
      "id":"8031531",
      "phuongxa":"Xã Phương Thịnh",
      "quanhuyen":"80315"
   },
   {
      "id":"8031533",
      "phuongxa":"Xã Tân Hội Trung",
      "quanhuyen":"80315"
   },
   {
      "id":"8031535",
      "phuongxa":"Xã Gáo Giồng",
      "quanhuyen":"80315"
   },
   {
      "id":"8031700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80317"
   },
   {
      "id":"8031701",
      "phuongxa":"Thị trấn Lấp Vò",
      "quanhuyen":"80317"
   },
   {
      "id":"8031703",
      "phuongxa":"Xã Bình Thành",
      "quanhuyen":"80317"
   },
   {
      "id":"8031705",
      "phuongxa":"Xã Định An",
      "quanhuyen":"80317"
   },
   {
      "id":"8031709",
      "phuongxa":"Xã Long Hưng A",
      "quanhuyen":"80317"
   },
   {
      "id":"8031711",
      "phuongxa":"Xã Long Hưng B",
      "quanhuyen":"80317"
   },
   {
      "id":"8031713",
      "phuongxa":"Xã Tân  Khánh Trung",
      "quanhuyen":"80317"
   },
   {
      "id":"8031715",
      "phuongxa":"Xã Vĩnh Thạnh",
      "quanhuyen":"80317"
   },
   {
      "id":"8031717",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"80317"
   },
   {
      "id":"8031719",
      "phuongxa":"Xã Mỹ An Hưng A",
      "quanhuyen":"80317"
   },
   {
      "id":"8031721",
      "phuongxa":"Xã Mỹ An Hưng B",
      "quanhuyen":"80317"
   },
   {
      "id":"8031725",
      "phuongxa":"Xã Bình Thạnh Trung",
      "quanhuyen":"80317"
   },
   {
      "id":"8031900",
      "phuongxa":"Không xác định",
      "quanhuyen":"80319"
   },
   {
      "id":"8031901",
      "phuongxa":"Thị trấn Lai Vung",
      "quanhuyen":"80319"
   },
   {
      "id":"8031903",
      "phuongxa":"Xã Hòa Long",
      "quanhuyen":"80319"
   },
   {
      "id":"8031905",
      "phuongxa":"Xã Hòa Thành",
      "quanhuyen":"80319"
   },
   {
      "id":"8031907",
      "phuongxa":"Xã Tân Dương",
      "quanhuyen":"80319"
   },
   {
      "id":"8031909",
      "phuongxa":"Xã Long Thắng",
      "quanhuyen":"80319"
   },
   {
      "id":"8031911",
      "phuongxa":"Xã Long Hậu",
      "quanhuyen":"80319"
   },
   {
      "id":"8031913",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"80319"
   },
   {
      "id":"8031915",
      "phuongxa":"Xã Tân Phước",
      "quanhuyen":"80319"
   },
   {
      "id":"8031917",
      "phuongxa":"Xã Vĩnh Thới",
      "quanhuyen":"80319"
   },
   {
      "id":"8031919",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"80319"
   },
   {
      "id":"8031921",
      "phuongxa":"Xã Định Hòa",
      "quanhuyen":"80319"
   },
   {
      "id":"8031923",
      "phuongxa":"Xã Phong Hòa",
      "quanhuyen":"80319"
   },
   {
      "id":"8032100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80321"
   },
   {
      "id":"8032101",
      "phuongxa":"Thị trấn Cái Tàu Hạ",
      "quanhuyen":"80321"
   },
   {
      "id":"8032103",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"80321"
   },
   {
      "id":"8032107",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"80321"
   },
   {
      "id":"8032109",
      "phuongxa":"Xã An Hiệp",
      "quanhuyen":"80321"
   },
   {
      "id":"8032111",
      "phuongxa":"Xã Tân Nhuận Đông",
      "quanhuyen":"80321"
   },
   {
      "id":"8032113",
      "phuongxa":"Xã An Nhơn",
      "quanhuyen":"80321"
   },
   {
      "id":"8032115",
      "phuongxa":"Xã Phú Hựu",
      "quanhuyen":"80321"
   },
   {
      "id":"8032117",
      "phuongxa":"Xã An Phú Thuận",
      "quanhuyen":"80321"
   },
   {
      "id":"8032119",
      "phuongxa":"Xã Hòa Tân",
      "quanhuyen":"80321"
   },
   {
      "id":"8032121",
      "phuongxa":"Xã An Khánh",
      "quanhuyen":"80321"
   },
   {
      "id":"8032123",
      "phuongxa":"Xã Phú Long",
      "quanhuyen":"80321"
   },
   {
      "id":"8032300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80323"
   },
   {
      "id":"8032303",
      "phuongxa":"Phường An Thạnh",
      "quanhuyen":"80323"
   },
   {
      "id":"8032305",
      "phuongxa":"Phường An Lạc",
      "quanhuyen":"80323"
   },
   {
      "id":"8032307",
      "phuongxa":"Xã Tân Hội",
      "quanhuyen":"80323"
   },
   {
      "id":"8032309",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"80323"
   },
   {
      "id":"8032311",
      "phuongxa":"Xã An Bình A",
      "quanhuyen":"80323"
   },
   {
      "id":"8032313",
      "phuongxa":"Xã An Bình B",
      "quanhuyen":"80323"
   },
   {
      "id":"8050000",
      "phuongxa":"Không xác định",
      "quanhuyen":"80500"
   },
   {
      "id":"8050100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80501"
   },
   {
      "id":"8050101",
      "phuongxa":"Phường Mỹ Bình",
      "quanhuyen":"80501"
   },
   {
      "id":"8050105",
      "phuongxa":"Phường Mỹ Xuyên",
      "quanhuyen":"80501"
   },
   {
      "id":"8050107",
      "phuongxa":"Phường Bình Đức",
      "quanhuyen":"80501"
   },
   {
      "id":"8050109",
      "phuongxa":"Phường Mỹ Phước",
      "quanhuyen":"80501"
   },
   {
      "id":"8050111",
      "phuongxa":"Xã Mỹ Hòa Hưng",
      "quanhuyen":"80501"
   },
   {
      "id":"8150317",
      "phuongxa":"Xã Thạnh Lộc",
      "quanhuyen":"81503"
   },
   {
      "id":"8150319",
      "phuongxa":"Xã Thạnh Phú",
      "quanhuyen":"81503"
   },
   {
      "id":"8150321",
      "phuongxa":"Xã Trung Hưng",
      "quanhuyen":"81503"
   },
   {
      "id":"8150323",
      "phuongxa":"Xã Trung An",
      "quanhuyen":"81503"
   },
   {
      "id":"8150325",
      "phuongxa":"Xã Thuận Hưng",
      "quanhuyen":"81503"
   },
   {
      "id":"8150327",
      "phuongxa":"Xã Trung Kiên",
      "quanhuyen":"81503"
   },
   {
      "id":"8150500",
      "phuongxa":"Phường Trà An",
      "quanhuyen":"82613"
   },
   {
      "id":"8150501",
      "phuongxa":"Xã Thới Tân ",
      "quanhuyen":"81516"
   },
   {
      "id":"8150503",
      "phuongxa":"Phường Thới Long",
      "quanhuyen":"81505"
   },
   {
      "id":"8150505",
      "phuongxa":"Xã Thới Đông",
      "quanhuyen":"81505"
   },
   {
      "id":"8150507",
      "phuongxa":"Xã Đông Thuận",
      "quanhuyen":"81505"
   },
   {
      "id":"8150509",
      "phuongxa":"Xã Thới Lai",
      "quanhuyen":"81505"
   },
   {
      "id":"8150511",
      "phuongxa":"Xã Thới Thạnh",
      "quanhuyen":"81505"
   },
   {
      "id":"8150513",
      "phuongxa":"Phường Thới An",
      "quanhuyen":"81505"
   },
   {
      "id":"8150519",
      "phuongxa":"Xã Định Môn",
      "quanhuyen":"81505"
   },
   {
      "id":"8150521",
      "phuongxa":"Xã Tân Thới",
      "quanhuyen":"81505"
   },
   {
      "id":"8150523",
      "phuongxa":"Xã Trường Thành",
      "quanhuyen":"81505"
   },
   {
      "id":"8150525",
      "phuongxa":"Xã Trường Xuân",
      "quanhuyen":"81505"
   },
   {
      "id":"8150527",
      "phuongxa":"Xã Đông Hiệp",
      "quanhuyen":"81505"
   },
   {
      "id":"8150529",
      "phuongxa":"Xã Đông Bình",
      "quanhuyen":"81505"
   },
   {
      "id":"8150531",
      "phuongxa":"Thị trấn Cờ Đỏ",
      "quanhuyen":"81505"
   },
   {
      "id":"8150533",
      "phuongxa":"Phường Châu Văn Liêm",
      "quanhuyen":"81505"
   },
   {
      "id":"8151414",
      "phuongxa":"Định Môn",
      "quanhuyen":"81514"
   },
   {
      "id":"8151415",
      "phuongxa":"Trung Hưng",
      "quanhuyen":"81514"
   },
   {
      "id":"8151416",
      "phuongxa":"Thị Trấn Cờ Đỏ",
      "quanhuyen":"81514"
   },
   {
      "id":"8151501",
      "phuongxa":"Phú thứ",
      "quanhuyen":"81515"
   },
   {
      "id":"8151503",
      "phuongxa":"Ba láng",
      "quanhuyen":"81515"
   },
   {
      "id":"8151553",
      "phuongxa":"Hưng phú",
      "quanhuyen":"81515"
   },
   {
      "id":"8151601",
      "phuongxa":"Long Bình",
      "quanhuyen":"81516"
   },
   {
      "id":"8151602",
      "phuongxa":"Trường Xuân B",
      "quanhuyen":"81516"
   },
   {
      "id":"8151603",
      "phuongxa":"Xã Trường Thành",
      "quanhuyen":"81516"
   },
   {
      "id":"8151604",
      "phuongxa":"Thị Trấn Thới Lai",
      "quanhuyen":"81516"
   },
   {
      "id":"8151605",
      "phuongxa":"Đông Thuận",
      "quanhuyen":"81516"
   },
   {
      "id":"8151716",
      "phuongxa":"Xã Trường Long",
      "quanhuyen":"81517"
   },
   {
      "id":"8151717",
      "phuongxa":"Tân Thới",
      "quanhuyen":"81517"
   },
   {
      "id":"8151718",
      "phuongxa":"Giai Xuân",
      "quanhuyen":"81517"
   },
   {
      "id":"8151719",
      "phuongxa":"Mỹ Khánh",
      "quanhuyen":"81517"
   },
   {
      "id":"8151720",
      "phuongxa":"Nhơn Ái",
      "quanhuyen":"81517"
   },
   {
      "id":"8151818",
      "phuongxa":"Phường Bùi Hữu Nghĩa",
      "quanhuyen":"81518"
   },
   {
      "id":"8151820",
      "phuongxa":"Phường Thới An Đông",
      "quanhuyen":"81518"
   },
   {
      "id":"8151821",
      "phuongxa":"phường Long Hòa",
      "quanhuyen":"81518"
   },
   {
      "id":"8151901",
      "phuongxa":"Thị Trấn Vĩnh Thạnh",
      "quanhuyen":"81519"
   },
   {
      "id":"8151902",
      "phuongxa":"Thạnh Phú",
      "quanhuyen":"81519"
   },
   {
      "id":"8151903",
      "phuongxa":"Thạnh Lộc",
      "quanhuyen":"81519"
   },
   {
      "id":"8152001",
      "phuongxa":"Phường An Khánh",
      "quanhuyen":"81520"
   },
   {
      "id":"8152002",
      "phuongxa":"Phường An Nghiệp",
      "quanhuyen":"81520"
   },
   {
      "id":"8152003",
      "phuongxa":"Phường An Hòa",
      "quanhuyen":"81520"
   },
   {
      "id":"8170000",
      "phuongxa":"Không xác định",
      "quanhuyen":"81700"
   },
   {
      "id":"8170100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81701"
   },
   {
      "id":"8170101",
      "phuongxa":"Phường 1",
      "quanhuyen":"81701"
   },
   {
      "id":"8170105",
      "phuongxa":"Phường 3",
      "quanhuyen":"81701"
   },
   {
      "id":"8170107",
      "phuongxa":"Phường 4",
      "quanhuyen":"81701"
   },
   {
      "id":"8170109",
      "phuongxa":"Phường 5",
      "quanhuyen":"81701"
   },
   {
      "id":"8170111",
      "phuongxa":"Phường 6",
      "quanhuyen":"81701"
   },
   {
      "id":"8170113",
      "phuongxa":"Phường 7",
      "quanhuyen":"81701"
   },
   {
      "id":"8170115",
      "phuongxa":"Xã Long Đức",
      "quanhuyen":"81701"
   },
   {
      "id":"8170117",
      "phuongxa":"Phường 8",
      "quanhuyen":"81701"
   },
   {
      "id":"8170119",
      "phuongxa":"Phường 9",
      "quanhuyen":"81701"
   },
   {
      "id":"8170300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81703"
   },
   {
      "id":"8170301",
      "phuongxa":"Thị trấn Càng Long",
      "quanhuyen":"81703"
   },
   {
      "id":"8170303",
      "phuongxa":"Xã Mỹ Cẩm",
      "quanhuyen":"81703"
   },
   {
      "id":"8170305",
      "phuongxa":"Xã An Trường",
      "quanhuyen":"81703"
   },
   {
      "id":"8170309",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"81703"
   },
   {
      "id":"8170311",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"81703"
   },
   {
      "id":"8170313",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"81703"
   },
   {
      "id":"8170315",
      "phuongxa":"Xã Phương Thạnh",
      "quanhuyen":"81703"
   },
   {
      "id":"8170317",
      "phuongxa":"Xã Đại Phước",
      "quanhuyen":"81703"
   },
   {
      "id":"8170319",
      "phuongxa":"Xã Nhị Long",
      "quanhuyen":"81703"
   },
   {
      "id":"8170321",
      "phuongxa":"Xã Đức Mỹ",
      "quanhuyen":"81703"
   },
   {
      "id":"7090703",
      "phuongxa":"Xã Phan",
      "quanhuyen":"70907"
   },
   {
      "id":"7090705",
      "phuongxa":"Xã Phước Ninh",
      "quanhuyen":"70907"
   },
   {
      "id":"7090707",
      "phuongxa":"Xã Phước Minh",
      "quanhuyen":"70907"
   },
   {
      "id":"7090709",
      "phuongxa":"Xã Bàu Năng",
      "quanhuyen":"70907"
   },
   {
      "id":"7090711",
      "phuongxa":"Xã Chà Là",
      "quanhuyen":"70907"
   },
   {
      "id":"7090715",
      "phuongxa":"Xã Bến Củi",
      "quanhuyen":"70907"
   },
   {
      "id":"7090717",
      "phuongxa":"Xã Lộc Ninh",
      "quanhuyen":"70907"
   },
   {
      "id":"7090719",
      "phuongxa":"Xã Truông Mít",
      "quanhuyen":"70907"
   },
   {
      "id":"7090721",
      "phuongxa":"Xã Suối Đá",
      "quanhuyen":"70907"
   },
   {
      "id":"7090900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70909"
   },
   {
      "id":"7090901",
      "phuongxa":"Xã Hảo Đước",
      "quanhuyen":"70909"
   },
   {
      "id":"7090903",
      "phuongxa":"Xã Phước Vinh",
      "quanhuyen":"70909"
   },
   {
      "id":"7090905",
      "phuongxa":"Xã Thái Bình",
      "quanhuyen":"70909"
   },
   {
      "id":"7090909",
      "phuongxa":"Xã Hòa Thạnh",
      "quanhuyen":"70909"
   },
   {
      "id":"7090911",
      "phuongxa":"Xã Trí Bình",
      "quanhuyen":"70909"
   },
   {
      "id":"7090913",
      "phuongxa":"Xã Hòa Hội",
      "quanhuyen":"70909"
   },
   {
      "id":"7090915",
      "phuongxa":"Xã Thanh Điền",
      "quanhuyen":"70909"
   },
   {
      "id":"7090917",
      "phuongxa":"Xã Thành Long",
      "quanhuyen":"70909"
   },
   {
      "id":"7090919",
      "phuongxa":"Xã Ninh Điền",
      "quanhuyen":"70909"
   },
   {
      "id":"7090921",
      "phuongxa":"Xã Long Vĩnh",
      "quanhuyen":"70909"
   },
   {
      "id":"7090923",
      "phuongxa":"Xã Đồng Khởi",
      "quanhuyen":"70909"
   },
   {
      "id":"7090925",
      "phuongxa":"Thị trấn Châu Thành",
      "quanhuyen":"70909"
   },
   {
      "id":"7091101",
      "phuongxa":"Thị trấn Hòa Thành",
      "quanhuyen":"70911"
   },
   {
      "id":"7091103",
      "phuongxa":"Xã Thạnh Tân",
      "quanhuyen":"70911"
   },
   {
      "id":"7091105",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"70911"
   },
   {
      "id":"7091107",
      "phuongxa":"Xã Ninh Sơn",
      "quanhuyen":"70911"
   },
   {
      "id":"7091109",
      "phuongxa":"Xã Ninh Thạnh",
      "quanhuyen":"70911"
   },
   {
      "id":"7091111",
      "phuongxa":"Xã Hiệp Ninh",
      "quanhuyen":"70911"
   },
   {
      "id":"7091113",
      "phuongxa":"Xã Hiệp Tân",
      "quanhuyen":"70911"
   },
   {
      "id":"7091115",
      "phuongxa":"Xã Long Thành Bắc",
      "quanhuyen":"70911"
   },
   {
      "id":"7091117",
      "phuongxa":"Xã Trường Hòa",
      "quanhuyen":"70911"
   },
   {
      "id":"7091119",
      "phuongxa":"Xã Trường Đông",
      "quanhuyen":"70911"
   },
   {
      "id":"7091121",
      "phuongxa":"Xã Long Thành Trung",
      "quanhuyen":"70911"
   },
   {
      "id":"7091123",
      "phuongxa":"Xã Trường Tây",
      "quanhuyen":"70911"
   },
   {
      "id":"7091125",
      "phuongxa":"Xã Long Thành Nam",
      "quanhuyen":"70911"
   },
   {
      "id":"7091300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70913"
   },
   {
      "id":"7091301",
      "phuongxa":"Xã Long Chữ",
      "quanhuyen":"70913"
   },
   {
      "id":"7091303",
      "phuongxa":"Xã Long Phước",
      "quanhuyen":"70913"
   },
   {
      "id":"7091305",
      "phuongxa":"Xã Long Giang",
      "quanhuyen":"70913"
   },
   {
      "id":"7091307",
      "phuongxa":"Xã Tiên Thuận",
      "quanhuyen":"70913"
   },
   {
      "id":"7091309",
      "phuongxa":"Xã Long Khánh",
      "quanhuyen":"70913"
   },
   {
      "id":"7091311",
      "phuongxa":"Xã Lợi Thuận",
      "quanhuyen":"70913"
   },
   {
      "id":"7091313",
      "phuongxa":"Xã Long Thuận",
      "quanhuyen":"70913"
   },
   {
      "id":"7091315",
      "phuongxa":"Xã An Thạnh",
      "quanhuyen":"70913"
   },
   {
      "id":"7091500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70915"
   },
   {
      "id":"7091501",
      "phuongxa":"Thị trấn Gò Dầu",
      "quanhuyen":"70915"
   },
   {
      "id":"7091503",
      "phuongxa":"Xã Thạnh Đức",
      "quanhuyen":"70915"
   },
   {
      "id":"7091507",
      "phuongxa":"Xã Cẩm Giang",
      "quanhuyen":"70915"
   },
   {
      "id":"7091509",
      "phuongxa":"Xã Hiệp Thạnh",
      "quanhuyen":"70915"
   },
   {
      "id":"7091511",
      "phuongxa":"Xã Phước Thạnh",
      "quanhuyen":"70915"
   },
   {
      "id":"7091513",
      "phuongxa":"Xã Phước Đông",
      "quanhuyen":"70915"
   },
   {
      "id":"7091515",
      "phuongxa":"Xã Phước Trạch",
      "quanhuyen":"70915"
   },
   {
      "id":"7091517",
      "phuongxa":"Xã Thanh Phước",
      "quanhuyen":"70915"
   },
   {
      "id":"7091700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70917"
   },
   {
      "id":"7091703",
      "phuongxa":"Xã Đôn Thuận",
      "quanhuyen":"70917"
   },
   {
      "id":"7091705",
      "phuongxa":"Xã Lộc Hưng",
      "quanhuyen":"70917"
   },
   {
      "id":"7091707",
      "phuongxa":"Xã Gia Lộc",
      "quanhuyen":"70917"
   },
   {
      "id":"7091709",
      "phuongxa":"Xã Gia Bình",
      "quanhuyen":"70917"
   },
   {
      "id":"7091711",
      "phuongxa":"Xã Phước Lưu",
      "quanhuyen":"70917"
   },
   {
      "id":"7091713",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"70917"
   },
   {
      "id":"7091715",
      "phuongxa":"Xã An Tịnh",
      "quanhuyen":"70917"
   },
   {
      "id":"7091717",
      "phuongxa":"Xã An Hòa",
      "quanhuyen":"70917"
   },
   {
      "id":"7091719",
      "phuongxa":"Xã Phước Chỉ",
      "quanhuyen":"70917"
   },
   {
      "id":"7110000",
      "phuongxa":"Không xác định",
      "quanhuyen":"71100"
   },
   {
      "id":"7110100",
      "phuongxa":"Không xác định",
      "quanhuyen":"71101"
   },
   {
      "id":"7110103",
      "phuongxa":"Phường Hiệp Thành",
      "quanhuyen":"71101"
   },
   {
      "id":"7110105",
      "phuongxa":"Phường Chánh Nghĩa",
      "quanhuyen":"71101"
   },
   {
      "id":"7110107",
      "phuongxa":"Phường Phú Hòa",
      "quanhuyen":"71101"
   },
   {
      "id":"7110109",
      "phuongxa":"Phường Phú Thọ",
      "quanhuyen":"71101"
   },
   {
      "id":"8050113",
      "phuongxa":"Xã Mỹ Khánh",
      "quanhuyen":"80501"
   },
   {
      "id":"8050115",
      "phuongxa":"Phường Mỹ Hòa",
      "quanhuyen":"80501"
   },
   {
      "id":"8050117",
      "phuongxa":"Phường Mỹ Thới",
      "quanhuyen":"80501"
   },
   {
      "id":"8050119",
      "phuongxa":"Phường Mỹ Thạnh",
      "quanhuyen":"80501"
   },
   {
      "id":"8050121",
      "phuongxa":"Phường Mỹ Quý",
      "quanhuyen":"80501"
   },
   {
      "id":"8050124",
      "phuongxa":"Phường Mỹ Hạnh",
      "quanhuyen":"80501"
   },
   {
      "id":"8050125",
      "phuongxa":"Xã Phú Hoài",
      "quanhuyen":"80501"
   },
   {
      "id":"8050300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80503"
   },
   {
      "id":"8050301",
      "phuongxa":"Phường Châu Phú B",
      "quanhuyen":"80503"
   },
   {
      "id":"8050303",
      "phuongxa":"Phường Châu Phú A",
      "quanhuyen":"80503"
   },
   {
      "id":"8050305",
      "phuongxa":"Xã Vĩnh Ngươn",
      "quanhuyen":"80503"
   },
   {
      "id":"8050307",
      "phuongxa":"Xã Vĩnh Tế",
      "quanhuyen":"80503"
   },
   {
      "id":"8050309",
      "phuongxa":"Xã Vĩnh Mỹ",
      "quanhuyen":"80503"
   },
   {
      "id":"8050311",
      "phuongxa":"Thị Trấn Châu Đốc",
      "quanhuyen":"80503"
   },
   {
      "id":"8050501",
      "phuongxa":"Thị trấn An Phú",
      "quanhuyen":"80505"
   },
   {
      "id":"8050503",
      "phuongxa":"Xã Khánh An",
      "quanhuyen":"80505"
   },
   {
      "id":"8050505",
      "phuongxa":"Xã Khánh Bình",
      "quanhuyen":"80505"
   },
   {
      "id":"8050507",
      "phuongxa":"Xã Quốc Thái",
      "quanhuyen":"80505"
   },
   {
      "id":"8050509",
      "phuongxa":"Xã Nhơn Hội",
      "quanhuyen":"80505"
   },
   {
      "id":"8050511",
      "phuongxa":"Xã Phú Hữu",
      "quanhuyen":"80505"
   },
   {
      "id":"8050513",
      "phuongxa":"Xã Phú Hội",
      "quanhuyen":"80505"
   },
   {
      "id":"8050515",
      "phuongxa":"Xã Phước Hưng",
      "quanhuyen":"80505"
   },
   {
      "id":"8050517",
      "phuongxa":"Xã Vĩnh Lộc",
      "quanhuyen":"80505"
   },
   {
      "id":"8050519",
      "phuongxa":"Xã Vĩnh Hậu",
      "quanhuyen":"80505"
   },
   {
      "id":"8050521",
      "phuongxa":"Xã Vĩnh Trường",
      "quanhuyen":"80505"
   },
   {
      "id":"8050525",
      "phuongxa":"Xã Đa Phước",
      "quanhuyen":"80505"
   },
   {
      "id":"8050700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80507"
   },
   {
      "id":"8050701",
      "phuongxa":"Thị trấn Tân Châu",
      "quanhuyen":"80507"
   },
   {
      "id":"8050703",
      "phuongxa":"Xã Phú Lộc",
      "quanhuyen":"80507"
   },
   {
      "id":"8050705",
      "phuongxa":"Xã Vĩnh Xương",
      "quanhuyen":"80507"
   },
   {
      "id":"8050707",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"80507"
   },
   {
      "id":"8050709",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"82602"
   },
   {
      "id":"8050711",
      "phuongxa":"Xã Long An",
      "quanhuyen":"80507"
   },
   {
      "id":"8050713",
      "phuongxa":"Phường Long Phú",
      "quanhuyen":"82602"
   },
   {
      "id":"8050715",
      "phuongxa":"Xã Châu Phong",
      "quanhuyen":"80507"
   },
   {
      "id":"8050717",
      "phuongxa":"Xã Phú Vĩnh",
      "quanhuyen":"80507"
   },
   {
      "id":"8050719",
      "phuongxa":"Xã Lê Chánh",
      "quanhuyen":"80507"
   },
   {
      "id":"8050900",
      "phuongxa":"Không xác định",
      "quanhuyen":"80509"
   },
   {
      "id":"8050901",
      "phuongxa":"Thị trấn Phú Mỹ",
      "quanhuyen":"80509"
   },
   {
      "id":"8050905",
      "phuongxa":"Xã Long Sơn",
      "quanhuyen":"80509"
   },
   {
      "id":"8050907",
      "phuongxa":"Xã Phú Long",
      "quanhuyen":"80509"
   },
   {
      "id":"8050909",
      "phuongxa":"Xã Phú Lâm",
      "quanhuyen":"80509"
   },
   {
      "id":"8050911",
      "phuongxa":"Xã Phú Hiệp",
      "quanhuyen":"80509"
   },
   {
      "id":"8050913",
      "phuongxa":"Xã Phú Thạnh",
      "quanhuyen":"80509"
   },
   {
      "id":"8050915",
      "phuongxa":"Xã Hoà Lạc",
      "quanhuyen":"80509"
   },
   {
      "id":"8050917",
      "phuongxa":"Xã Phú Thành",
      "quanhuyen":"80509"
   },
   {
      "id":"8050919",
      "phuongxa":"Xã Phú An",
      "quanhuyen":"80509"
   },
   {
      "id":"8050921",
      "phuongxa":"Xã Phú Xuân",
      "quanhuyen":"80509"
   },
   {
      "id":"8050922",
      "phuongxa":"xã Tân Trung",
      "quanhuyen":"80509"
   },
   {
      "id":"8050923",
      "phuongxa":"Xã Hiệp Xương",
      "quanhuyen":"80509"
   },
   {
      "id":"8050927",
      "phuongxa":"Xã Phú Thọ",
      "quanhuyen":"80509"
   },
   {
      "id":"8050929",
      "phuongxa":"Xã Phú Hưng",
      "quanhuyen":"80509"
   },
   {
      "id":"8050931",
      "phuongxa":"Xã Bình Thạnh Đông",
      "quanhuyen":"80509"
   },
   {
      "id":"8050933",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"80509"
   },
   {
      "id":"8051100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80511"
   },
   {
      "id":"8051101",
      "phuongxa":"Thị trấn Cái Dầu",
      "quanhuyen":"80511"
   },
   {
      "id":"8051103",
      "phuongxa":"Xã Khánh Hòa",
      "quanhuyen":"80511"
   },
   {
      "id":"8051105",
      "phuongxa":"Xã Mỹ Đức",
      "quanhuyen":"80511"
   },
   {
      "id":"8051107",
      "phuongxa":"Xã Mỹ Phú",
      "quanhuyen":"80511"
   },
   {
      "id":"8051111",
      "phuongxa":"Xã Vĩnh Thạnh Trung",
      "quanhuyen":"80511"
   },
   {
      "id":"8051113",
      "phuongxa":"Xã Bình Long",
      "quanhuyen":"80511"
   },
   {
      "id":"8051115",
      "phuongxa":"Xã Thạnh Mỹ Tây",
      "quanhuyen":"80511"
   },
   {
      "id":"8051117",
      "phuongxa":"Xã Bình Mỹ",
      "quanhuyen":"80511"
   },
   {
      "id":"8051119",
      "phuongxa":"Xã Bình Thủy",
      "quanhuyen":"80511"
   },
   {
      "id":"8051121",
      "phuongxa":"Xã Đào Hữu Cảnh",
      "quanhuyen":"80511"
   },
   {
      "id":"8051123",
      "phuongxa":"Xã Bình Phú",
      "quanhuyen":"80511"
   },
   {
      "id":"8051125",
      "phuongxa":"Xã Bình Chánh",
      "quanhuyen":"80511"
   },
   {
      "id":"8051300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80513"
   },
   {
      "id":"8170323",
      "phuongxa":"Xã An Trường A",
      "quanhuyen":"81703"
   },
   {
      "id":"8170325",
      "phuongxa":"Xã Nhị Long Phú",
      "quanhuyen":"81703"
   },
   {
      "id":"8170500",
      "phuongxa":"Không xác định",
      "quanhuyen":"81705"
   },
   {
      "id":"8170501",
      "phuongxa":"Thị trấn Châu Thành",
      "quanhuyen":"81705"
   },
   {
      "id":"8170503",
      "phuongxa":"Xã Đa Lộc",
      "quanhuyen":"81705"
   },
   {
      "id":"8170505",
      "phuongxa":"Xã Thanh Mỹ",
      "quanhuyen":"81705"
   },
   {
      "id":"8170507",
      "phuongxa":"Xã Lương Hòa",
      "quanhuyen":"81705"
   },
   {
      "id":"8170511",
      "phuongxa":"Xã Nguyệt Hóa",
      "quanhuyen":"81705"
   },
   {
      "id":"8170513",
      "phuongxa":"Xã Hòa Thuận",
      "quanhuyen":"81705"
   },
   {
      "id":"8170515",
      "phuongxa":"Xã Hòa Lợi",
      "quanhuyen":"81705"
   },
   {
      "id":"8170517",
      "phuongxa":"Xã Phước Hảo",
      "quanhuyen":"81705"
   },
   {
      "id":"8170519",
      "phuongxa":"Xã Hưng Mỹ",
      "quanhuyen":"81705"
   },
   {
      "id":"8170521",
      "phuongxa":"Xã Hòa Minh",
      "quanhuyen":"81705"
   },
   {
      "id":"8170523",
      "phuongxa":"Xã Long Hòa",
      "quanhuyen":"81705"
   },
   {
      "id":"8170525",
      "phuongxa":"Xã Mỹ Chánh",
      "quanhuyen":"81705"
   },
   {
      "id":"8170700",
      "phuongxa":"Không xác định",
      "quanhuyen":"81707"
   },
   {
      "id":"8170703",
      "phuongxa":"Xã Hòa Ân",
      "quanhuyen":"81707"
   },
   {
      "id":"8170705",
      "phuongxa":"Xã Châu Điền",
      "quanhuyen":"81707"
   },
   {
      "id":"8170707",
      "phuongxa":"Xã An Phú Tân",
      "quanhuyen":"81707"
   },
   {
      "id":"8170709",
      "phuongxa":"Xã Ninh Thới",
      "quanhuyen":"81707"
   },
   {
      "id":"8170711",
      "phuongxa":"Xã Phong Phú",
      "quanhuyen":"81707"
   },
   {
      "id":"8170713",
      "phuongxa":"Xã Phong Thạnh",
      "quanhuyen":"81707"
   },
   {
      "id":"8170715",
      "phuongxa":"Xã Tam Ngãi",
      "quanhuyen":"81707"
   },
   {
      "id":"8170717",
      "phuongxa":"Xã Thông Hòa",
      "quanhuyen":"81707"
   },
   {
      "id":"8170719",
      "phuongxa":"Xã Thạnh Phú",
      "quanhuyen":"81707"
   },
   {
      "id":"8170721",
      "phuongxa":"Xã Hòa Tân",
      "quanhuyen":"81707"
   },
   {
      "id":"8170901",
      "phuongxa":"Thị trấn Tiểu Cần",
      "quanhuyen":"81709"
   },
   {
      "id":"8170903",
      "phuongxa":"Thị trấn Cầu Quan",
      "quanhuyen":"81709"
   },
   {
      "id":"8170905",
      "phuongxa":"Xã Phú Cần",
      "quanhuyen":"81709"
   },
   {
      "id":"8170907",
      "phuongxa":"Xã Hiếu Tử",
      "quanhuyen":"81709"
   },
   {
      "id":"8170911",
      "phuongxa":"Xã Long Thới",
      "quanhuyen":"81709"
   },
   {
      "id":"8170913",
      "phuongxa":"Xã Hùng Hòa",
      "quanhuyen":"81709"
   },
   {
      "id":"8170915",
      "phuongxa":"Xã Tập Ngãi",
      "quanhuyen":"81709"
   },
   {
      "id":"8170917",
      "phuongxa":"Xã Ngãi Hùng",
      "quanhuyen":"81709"
   },
   {
      "id":"8170919",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"81709"
   },
   {
      "id":"8170921",
      "phuongxa":"Xã Tân Hùng",
      "quanhuyen":"81709"
   },
   {
      "id":"8171100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81711"
   },
   {
      "id":"8171101",
      "phuongxa":"Thị trấn Cầu Ngang",
      "quanhuyen":"81711"
   },
   {
      "id":"8171103",
      "phuongxa":"Thị trấn Mỹ Long",
      "quanhuyen":"81711"
   },
   {
      "id":"8171105",
      "phuongxa":"Xã Mỹ Long Bắc",
      "quanhuyen":"81711"
   },
   {
      "id":"8171107",
      "phuongxa":"Xã Mỹ Hòa",
      "quanhuyen":"81711"
   },
   {
      "id":"8171111",
      "phuongxa":"Xã Kim Hòa",
      "quanhuyen":"81711"
   },
   {
      "id":"8171113",
      "phuongxa":"Xã Hiệp Hòa",
      "quanhuyen":"81711"
   },
   {
      "id":"8171115",
      "phuongxa":"Xã Thuận Hòa",
      "quanhuyen":"81711"
   },
   {
      "id":"8171117",
      "phuongxa":"Xã Long Sơn",
      "quanhuyen":"81711"
   },
   {
      "id":"8171119",
      "phuongxa":"Xã Nhị Trường",
      "quanhuyen":"81711"
   },
   {
      "id":"8171121",
      "phuongxa":"Xã Trường Thọ",
      "quanhuyen":"81711"
   },
   {
      "id":"8171123",
      "phuongxa":"Xã Hiệp Mỹ",
      "quanhuyen":"81711"
   },
   {
      "id":"8171125",
      "phuongxa":"Xã Thạnh Hòa Sơn",
      "quanhuyen":"81711"
   },
   {
      "id":"8171127",
      "phuongxa":"Xã Mỹ Long Nam",
      "quanhuyen":"81711"
   },
   {
      "id":"8171301",
      "phuongxa":"Thị trấn Trà Cú",
      "quanhuyen":"81713"
   },
   {
      "id":"8171303",
      "phuongxa":"Xã Phước Hưng",
      "quanhuyen":"81713"
   },
   {
      "id":"8171305",
      "phuongxa":"Xã Tập Sơn",
      "quanhuyen":"81713"
   },
   {
      "id":"8171307",
      "phuongxa":"Xã An Quảng Hữu",
      "quanhuyen":"81713"
   },
   {
      "id":"8171309",
      "phuongxa":"Xã Lưu Nghiệp Anh",
      "quanhuyen":"81713"
   },
   {
      "id":"8171311",
      "phuongxa":"Xã Ngãi Xuyên",
      "quanhuyen":"81713"
   },
   {
      "id":"8171313",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"81713"
   },
   {
      "id":"8171315",
      "phuongxa":"Xã Hàm Giang",
      "quanhuyen":"81713"
   },
   {
      "id":"8171317",
      "phuongxa":"Xã Đại An",
      "quanhuyen":"81713"
   },
   {
      "id":"8171319",
      "phuongxa":"Xã Đôn Xuân",
      "quanhuyen":"81713"
   },
   {
      "id":"8171323",
      "phuongxa":"Xã Ngọc Biên",
      "quanhuyen":"81713"
   },
   {
      "id":"8171325",
      "phuongxa":"Xã Long Hiệp",
      "quanhuyen":"81713"
   },
   {
      "id":"8171327",
      "phuongxa":"Xã Tân Hiệp",
      "quanhuyen":"81713"
   },
   {
      "id":"8171329",
      "phuongxa":"Xã Định An",
      "quanhuyen":"81713"
   },
   {
      "id":"8171330",
      "phuongxa":"Bắc Trang",
      "quanhuyen":"81713"
   },
   {
      "id":"8171500",
      "phuongxa":"Không xác định",
      "quanhuyen":"81715"
   },
   {
      "id":"8171501",
      "phuongxa":"Thị trấn Duyên Hải",
      "quanhuyen":"81715"
   },
   {
      "id":"8171503",
      "phuongxa":"Xã Long Toàn",
      "quanhuyen":"81715"
   },
   {
      "id":"7110111",
      "phuongxa":"Phường Phú Mỹ",
      "quanhuyen":"71101"
   },
   {
      "id":"7110113",
      "phuongxa":"Phường Định Hòa",
      "quanhuyen":"71101"
   },
   {
      "id":"7110115",
      "phuongxa":"Phường Tân An",
      "quanhuyen":"71101"
   },
   {
      "id":"7110119",
      "phuongxa":"Phường Chánh Mỹ",
      "quanhuyen":"71101"
   },
   {
      "id":"7110171",
      "phuongxa":"Công Ty Sài Gòn STEC_v6249",
      "quanhuyen":"71101"
   },
   {
      "id":"7110300",
      "phuongxa":"Không xác định",
      "quanhuyen":"71103"
   },
   {
      "id":"7110303",
      "phuongxa":"Thị trấn Dầu Tiếng",
      "quanhuyen":"71103"
   },
   {
      "id":"7110305",
      "phuongxa":"Xã Minh Hoà",
      "quanhuyen":"71103"
   },
   {
      "id":"7110307",
      "phuongxa":"Xã Minh Thạnh",
      "quanhuyen":"71103"
   },
   {
      "id":"7110309",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"71103"
   },
   {
      "id":"7110311",
      "phuongxa":"Xã Định Hiệp",
      "quanhuyen":"71103"
   },
   {
      "id":"7110313",
      "phuongxa":"Xã An Long",
      "quanhuyen":"71103"
   },
   {
      "id":"7110315",
      "phuongxa":"Xã Long Hòa",
      "quanhuyen":"71103"
   },
   {
      "id":"7110317",
      "phuongxa":"Xã Cây Trường II",
      "quanhuyen":"82592"
   },
   {
      "id":"7110319",
      "phuongxa":"Xã Trừ Văn Thố",
      "quanhuyen":"82592"
   },
   {
      "id":"7110321",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"71103"
   },
   {
      "id":"7110323",
      "phuongxa":"Thị trấn Lai Uyên",
      "quanhuyen":"82592"
   },
   {
      "id":"7110327",
      "phuongxa":"Xã Long Tân",
      "quanhuyen":"71103"
   },
   {
      "id":"7110329",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"71103"
   },
   {
      "id":"7110331",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"71103"
   },
   {
      "id":"7110333",
      "phuongxa":"Xã Long Nguyên",
      "quanhuyen":"71103"
   },
   {
      "id":"7110335",
      "phuongxa":"Xã Hưng Hòa",
      "quanhuyen":"82592"
   },
   {
      "id":"7110337",
      "phuongxa":"Xã Lai Hưng",
      "quanhuyen":"71103"
   },
   {
      "id":"7110339",
      "phuongxa":"Xã Thanh Tuyền",
      "quanhuyen":"71103"
   },
   {
      "id":"7110341",
      "phuongxa":"Phường Chánh Phú Hòa",
      "quanhuyen":"71103"
   },
   {
      "id":"7110343",
      "phuongxa":"Xã An Điền",
      "quanhuyen":"71103"
   },
   {
      "id":"7110345",
      "phuongxa":"Xã An Tây",
      "quanhuyen":"71103"
   },
   {
      "id":"7110347",
      "phuongxa":"Phường Thới Hòa",
      "quanhuyen":"71103"
   },
   {
      "id":"7110349",
      "phuongxa":"Phường Hòa Lợi",
      "quanhuyen":"71103"
   },
   {
      "id":"7110351",
      "phuongxa":"Xã Phú An",
      "quanhuyen":"71103"
   },
   {
      "id":"7110353",
      "phuongxa":"Phường Tân Định",
      "quanhuyen":"71103"
   },
   {
      "id":"7110500",
      "phuongxa":"Không xác định",
      "quanhuyen":"71105"
   },
   {
      "id":"7110501",
      "phuongxa":"Phường Uyên Hưng",
      "quanhuyen":"82627"
   },
   {
      "id":"7110503",
      "phuongxa":"Thị trấn Phước Vĩnh",
      "quanhuyen":"71108"
   },
   {
      "id":"7110507",
      "phuongxa":"Xã An Linh",
      "quanhuyen":"71105"
   },
   {
      "id":"7110508",
      "phuongxa":"xã an bình",
      "quanhuyen":"71105"
   },
   {
      "id":"7110509",
      "phuongxa":"Xã Phước Sang",
      "quanhuyen":"71105"
   },
   {
      "id":"7110511",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"71105"
   },
   {
      "id":"7110515",
      "phuongxa":"Xã Vĩnh Hoà",
      "quanhuyen":"71105"
   },
   {
      "id":"7110517",
      "phuongxa":"Xã Phước Hòa",
      "quanhuyen":"71105"
   },
   {
      "id":"7110519",
      "phuongxa":"Xã Tân Định",
      "quanhuyen":"71105"
   },
   {
      "id":"7110521",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"71105"
   },
   {
      "id":"7110525",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"71105"
   },
   {
      "id":"7110527",
      "phuongxa":"Thị trấn Tân Thành",
      "quanhuyen":"71105"
   },
   {
      "id":"7110529",
      "phuongxa":"Xã Vĩnh Tân",
      "quanhuyen":"82627"
   },
   {
      "id":"7110531",
      "phuongxa":"Xã Lạc An",
      "quanhuyen":"71105"
   },
   {
      "id":"7110533",
      "phuongxa":"Xã Hội Nghĩa",
      "quanhuyen":"82627"
   },
   {
      "id":"7110535",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"71105"
   },
   {
      "id":"7110537",
      "phuongxa":"Phường Khánh Bình",
      "quanhuyen":"82627"
   },
   {
      "id":"7110541",
      "phuongxa":"Xã Thường Tân",
      "quanhuyen":"71105"
   },
   {
      "id":"7110543",
      "phuongxa":"Xã Bạch Đằng",
      "quanhuyen":"82627"
   },
   {
      "id":"7110545",
      "phuongxa":"Xã Tân Vĩnh Hiệp",
      "quanhuyen":"82627"
   },
   {
      "id":"7110547",
      "phuongxa":"Phường Thạnh Phước",
      "quanhuyen":"82627"
   },
   {
      "id":"7110549",
      "phuongxa":"Phường Thái Hòa",
      "quanhuyen":"82627"
   },
   {
      "id":"7110550",
      "phuongxa":"Thị trấn An Lão ",
      "quanhuyen":"50703"
   },
   {
      "id":"7110700",
      "phuongxa":"Không xác định",
      "quanhuyen":"71107"
   },
   {
      "id":"7110701",
      "phuongxa":"Phường Lái Thiêu",
      "quanhuyen":"71107"
   },
   {
      "id":"7110703",
      "phuongxa":"Thị trấn Dĩ An",
      "quanhuyen":"71107"
   },
   {
      "id":"7110705",
      "phuongxa":"Phường An Thạnh",
      "quanhuyen":"71107"
   },
   {
      "id":"7110709",
      "phuongxa":"Phường Thuận Giao",
      "quanhuyen":"71107"
   },
   {
      "id":"7110711",
      "phuongxa":"Phường An Phú",
      "quanhuyen":"71107"
   },
   {
      "id":"7110713",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"71107"
   },
   {
      "id":"7110715",
      "phuongxa":"Phường Hưng Định",
      "quanhuyen":"71107"
   },
   {
      "id":"7110717",
      "phuongxa":"Xã An Sơn",
      "quanhuyen":"71107"
   },
   {
      "id":"7110719",
      "phuongxa":"Phường Bình Nhâm",
      "quanhuyen":"71107"
   },
   {
      "id":"7110721",
      "phuongxa":"Phường Bình Hòa",
      "quanhuyen":"71107"
   },
   {
      "id":"7110723",
      "phuongxa":"Xã Tân Đông Hiệp",
      "quanhuyen":"71107"
   },
   {
      "id":"7110725",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"71107"
   },
   {
      "id":"8051301",
      "phuongxa":"Thị trấn Nhà Bàng",
      "quanhuyen":"80513"
   },
   {
      "id":"8051303",
      "phuongxa":"Thị trấn Chi Lăng",
      "quanhuyen":"80513"
   },
   {
      "id":"8051305",
      "phuongxa":"Xã Nhơn Hưng",
      "quanhuyen":"80513"
   },
   {
      "id":"8051309",
      "phuongxa":"Xã Thới Sơn",
      "quanhuyen":"80513"
   },
   {
      "id":"8051311",
      "phuongxa":"Xã Xuân Tô",
      "quanhuyen":"80513"
   },
   {
      "id":"8051313",
      "phuongxa":"Xã Văn Giáo",
      "quanhuyen":"80513"
   },
   {
      "id":"8051315",
      "phuongxa":"Xã An Cư",
      "quanhuyen":"80513"
   },
   {
      "id":"8051317",
      "phuongxa":"Xã An Nông",
      "quanhuyen":"80513"
   },
   {
      "id":"8051319",
      "phuongxa":"Xã Vĩnh Trung",
      "quanhuyen":"80513"
   },
   {
      "id":"8051321",
      "phuongxa":"Xã Tân Lợi",
      "quanhuyen":"80513"
   },
   {
      "id":"8051323",
      "phuongxa":"Xã An Hảo",
      "quanhuyen":"80513"
   },
   {
      "id":"8051325",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"80513"
   },
   {
      "id":"8051501",
      "phuongxa":"Thị trấn Tri Tôn",
      "quanhuyen":"80515"
   },
   {
      "id":"8051503",
      "phuongxa":"Xã Lạc Quới",
      "quanhuyen":"80515"
   },
   {
      "id":"8051505",
      "phuongxa":"Xã Lê Trì",
      "quanhuyen":"80515"
   },
   {
      "id":"8051507",
      "phuongxa":"Xã Vĩnh Gia",
      "quanhuyen":"80515"
   },
   {
      "id":"8051509",
      "phuongxa":"Xã Ba Chúc",
      "quanhuyen":"80515"
   },
   {
      "id":"8051511",
      "phuongxa":"Xã Châu Lăng",
      "quanhuyen":"80515"
   },
   {
      "id":"8051513",
      "phuongxa":"Xã Lương Phi",
      "quanhuyen":"80515"
   },
   {
      "id":"8051515",
      "phuongxa":"Xã Lương An Trà",
      "quanhuyen":"80515"
   },
   {
      "id":"8051517",
      "phuongxa":"Xã Tà Đảnh",
      "quanhuyen":"80515"
   },
   {
      "id":"8051519",
      "phuongxa":"Xã Núi Tô",
      "quanhuyen":"80515"
   },
   {
      "id":"8051521",
      "phuongxa":"Xã An Tức",
      "quanhuyen":"80515"
   },
   {
      "id":"8051523",
      "phuongxa":"Xã Cô Tô",
      "quanhuyen":"80515"
   },
   {
      "id":"8051525",
      "phuongxa":"Xã Tân Tuyến",
      "quanhuyen":"80515"
   },
   {
      "id":"8051527",
      "phuongxa":"Xã Ô Lâm",
      "quanhuyen":"80515"
   },
   {
      "id":"8051700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80517"
   },
   {
      "id":"8051701",
      "phuongxa":"Thị trấn Chợ Mới",
      "quanhuyen":"80517"
   },
   {
      "id":"8051703",
      "phuongxa":"Xã Kiến An",
      "quanhuyen":"80517"
   },
   {
      "id":"8051705",
      "phuongxa":"Xã Mỹ Hội Đông",
      "quanhuyen":"80517"
   },
   {
      "id":"8051707",
      "phuongxa":"Xã Long Điền A",
      "quanhuyen":"80517"
   },
   {
      "id":"8051709",
      "phuongxa":"Xã Tấn Mỹ",
      "quanhuyen":"80517"
   },
   {
      "id":"8051711",
      "phuongxa":"Xã Long Điền B",
      "quanhuyen":"80517"
   },
   {
      "id":"8051713",
      "phuongxa":"Xã Kiến Thành",
      "quanhuyen":"80517"
   },
   {
      "id":"8051715",
      "phuongxa":"Xã Mỹ Hiệp",
      "quanhuyen":"80517"
   },
   {
      "id":"8051717",
      "phuongxa":"Xã Mỹ Luông",
      "quanhuyen":"80517"
   },
   {
      "id":"8051719",
      "phuongxa":"Xã Nhơn Mỹ",
      "quanhuyen":"80517"
   },
   {
      "id":"8051721",
      "phuongxa":"Xã Long Giang",
      "quanhuyen":"80517"
   },
   {
      "id":"8051723",
      "phuongxa":"Xã Long Kiến",
      "quanhuyen":"80517"
   },
   {
      "id":"8051725",
      "phuongxa":"Xã Bình Phước Xuân",
      "quanhuyen":"80517"
   },
   {
      "id":"8051727",
      "phuongxa":"Xã An Thạnh Trung",
      "quanhuyen":"80517"
   },
   {
      "id":"8051731",
      "phuongxa":"Xã Hòa Bình",
      "quanhuyen":"80517"
   },
   {
      "id":"8051733",
      "phuongxa":"Xã Hòa An",
      "quanhuyen":"80517"
   },
   {
      "id":"8051900",
      "phuongxa":"Không xác định",
      "quanhuyen":"80519"
   },
   {
      "id":"8051901",
      "phuongxa":"Thị trấn An Châu",
      "quanhuyen":"80519"
   },
   {
      "id":"8051903",
      "phuongxa":"Xã An Hòa",
      "quanhuyen":"80519"
   },
   {
      "id":"8051905",
      "phuongxa":"Xã Cần Đăng",
      "quanhuyen":"80519"
   },
   {
      "id":"8051907",
      "phuongxa":"Xã Vĩnh Hanh",
      "quanhuyen":"80519"
   },
   {
      "id":"8051909",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"80519"
   },
   {
      "id":"8051913",
      "phuongxa":"Xã Bình Hòa",
      "quanhuyen":"80519"
   },
   {
      "id":"8051915",
      "phuongxa":"Xã Vĩnh An",
      "quanhuyen":"80519"
   },
   {
      "id":"8051917",
      "phuongxa":"Xã Hòa Bình Thạnh",
      "quanhuyen":"80519"
   },
   {
      "id":"8051919",
      "phuongxa":"Xã Vĩnh Lợi",
      "quanhuyen":"80519"
   },
   {
      "id":"8051921",
      "phuongxa":"Xã Vĩnh Nhuận",
      "quanhuyen":"80519"
   },
   {
      "id":"8051923",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"80519"
   },
   {
      "id":"8051925",
      "phuongxa":"Xã Vĩnh Thành",
      "quanhuyen":"80519"
   },
   {
      "id":"8052100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80521"
   },
   {
      "id":"8052101",
      "phuongxa":"Thị trấn Núi Sập",
      "quanhuyen":"80521"
   },
   {
      "id":"8052105",
      "phuongxa":"Xã Vĩnh Phú",
      "quanhuyen":"80521"
   },
   {
      "id":"8052107",
      "phuongxa":"Xã Vĩnh Trạch",
      "quanhuyen":"80521"
   },
   {
      "id":"8052109",
      "phuongxa":"Xã Phú Hòa",
      "quanhuyen":"80521"
   },
   {
      "id":"8052111",
      "phuongxa":"Xã Vĩnh Chánh",
      "quanhuyen":"80521"
   },
   {
      "id":"8052113",
      "phuongxa":"Xã Định Mỹ",
      "quanhuyen":"80521"
   },
   {
      "id":"8052115",
      "phuongxa":"Xã Định Thành",
      "quanhuyen":"80521"
   },
   {
      "id":"8052117",
      "phuongxa":"Xã Mỹ Phú Đông",
      "quanhuyen":"80521"
   },
   {
      "id":"8052119",
      "phuongxa":"Xã Vọng Đông",
      "quanhuyen":"80521"
   },
   {
      "id":"8052121",
      "phuongxa":"Xã Vĩnh Khánh",
      "quanhuyen":"80521"
   },
   {
      "id":"8052125",
      "phuongxa":"Xã Vọng Thê",
      "quanhuyen":"80521"
   },
   {
      "id":"8052127",
      "phuongxa":"Thị Trấn Óc Eo",
      "quanhuyen":"80521"
   },
   {
      "id":"8052129",
      "phuongxa":"Xã Mỹ Hoà",
      "quanhuyen":"80521"
   },
   {
      "id":"8052131",
      "phuongxa":"Xã Phú Thuận",
      "quanhuyen":"80521"
   },
   {
      "id":"8171507",
      "phuongxa":"Xã Long Khánh",
      "quanhuyen":"81715"
   },
   {
      "id":"8171509",
      "phuongxa":"Xã Dân Thành",
      "quanhuyen":"81715"
   },
   {
      "id":"8171511",
      "phuongxa":"Xã Trường Long Hòa",
      "quanhuyen":"81715"
   },
   {
      "id":"8171513",
      "phuongxa":"Xã Ngũ Lạc",
      "quanhuyen":"81715"
   },
   {
      "id":"8171515",
      "phuongxa":"Xã Long Vĩnh",
      "quanhuyen":"81715"
   },
   {
      "id":"8171519",
      "phuongxa":"Xã Hiệp Thạnh",
      "quanhuyen":"81715"
   },
   {
      "id":"8190000",
      "phuongxa":"Không xác định",
      "quanhuyen":"81900"
   },
   {
      "id":"8190100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81901"
   },
   {
      "id":"8190101",
      "phuongxa":"Phường 1",
      "quanhuyen":"81901"
   },
   {
      "id":"8190103",
      "phuongxa":"Phường 2",
      "quanhuyen":"81901"
   },
   {
      "id":"8190105",
      "phuongxa":"Phường 3",
      "quanhuyen":"81901"
   },
   {
      "id":"8190109",
      "phuongxa":"Phường 5",
      "quanhuyen":"81901"
   },
   {
      "id":"8190111",
      "phuongxa":"Phường 6",
      "quanhuyen":"81901"
   },
   {
      "id":"8190113",
      "phuongxa":"Phường 7",
      "quanhuyen":"81901"
   },
   {
      "id":"8190115",
      "phuongxa":"Phường 8",
      "quanhuyen":"81901"
   },
   {
      "id":"8190117",
      "phuongxa":"Phường 9",
      "quanhuyen":"81901"
   },
   {
      "id":"8190119",
      "phuongxa":"Phường 10",
      "quanhuyen":"81901"
   },
   {
      "id":"8190300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81903"
   },
   {
      "id":"8190301",
      "phuongxa":"Thị trấn Kế Sách",
      "quanhuyen":"81903"
   },
   {
      "id":"8190303",
      "phuongxa":"Xã An Mỹ",
      "quanhuyen":"81903"
   },
   {
      "id":"8190307",
      "phuongxa":"Xã Thới An Hội",
      "quanhuyen":"81903"
   },
   {
      "id":"8190309",
      "phuongxa":"Xã Kế An",
      "quanhuyen":"81903"
   },
   {
      "id":"8190311",
      "phuongxa":"Xã Kế Thành",
      "quanhuyen":"81903"
   },
   {
      "id":"8190315",
      "phuongxa":"Xã Phong Nẫm",
      "quanhuyen":"81903"
   },
   {
      "id":"8190317",
      "phuongxa":"Xã An Lạc Thôn",
      "quanhuyen":"81903"
   },
   {
      "id":"8190319",
      "phuongxa":"Xã Xuân Hòa",
      "quanhuyen":"81903"
   },
   {
      "id":"8190321",
      "phuongxa":"Xã An Lạc Tây",
      "quanhuyen":"81903"
   },
   {
      "id":"8190323",
      "phuongxa":"Xã Ba Trinh",
      "quanhuyen":"81903"
   },
   {
      "id":"8190325",
      "phuongxa":"Xã Trinh Phú",
      "quanhuyen":"81903"
   },
   {
      "id":"8190500",
      "phuongxa":"Không xác định",
      "quanhuyen":"81905"
   },
   {
      "id":"8190501",
      "phuongxa":"Thị trấn Long Phú",
      "quanhuyen":"81905"
   },
   {
      "id":"8190503",
      "phuongxa":"Xã Long Phú",
      "quanhuyen":"81905"
   },
   {
      "id":"8190505",
      "phuongxa":"Xã Tân Thạnh",
      "quanhuyen":"81905"
   },
   {
      "id":"8190507",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"81905"
   },
   {
      "id":"8190509",
      "phuongxa":"Xã Đại Ân 1",
      "quanhuyen":"81905"
   },
   {
      "id":"8190511",
      "phuongxa":"Xã Đại Ân  2",
      "quanhuyen":"81905"
   },
   {
      "id":"8190513",
      "phuongxa":"Xã An Thạnh 2",
      "quanhuyen":"81905"
   },
   {
      "id":"8190515",
      "phuongxa":"Xã Trường Khánh",
      "quanhuyen":"81905"
   },
   {
      "id":"8190517",
      "phuongxa":"Xã Hậu Thạnh",
      "quanhuyen":"81905"
   },
   {
      "id":"8190519",
      "phuongxa":"Xã Đại Ngãi",
      "quanhuyen":"81905"
   },
   {
      "id":"8190521",
      "phuongxa":"Xã Song Phụng",
      "quanhuyen":"81905"
   },
   {
      "id":"8190523",
      "phuongxa":"Xã Long Đức",
      "quanhuyen":"81905"
   },
   {
      "id":"8190525",
      "phuongxa":"Xã Châu Khánh",
      "quanhuyen":"81905"
   },
   {
      "id":"8190527",
      "phuongxa":"Xã Phú Hữu",
      "quanhuyen":"81905"
   },
   {
      "id":"8190531",
      "phuongxa":"Xã Liêu Tú",
      "quanhuyen":"81905"
   },
   {
      "id":"8190533",
      "phuongxa":"Xã Lịch Hội Thượng",
      "quanhuyen":"81905"
   },
   {
      "id":"8190535",
      "phuongxa":"Xã Trung Bình",
      "quanhuyen":"81905"
   },
   {
      "id":"8190537",
      "phuongxa":"Xã An Thạnh 3",
      "quanhuyen":"81905"
   },
   {
      "id":"8190700",
      "phuongxa":"Không xác định",
      "quanhuyen":"81907"
   },
   {
      "id":"8190701",
      "phuongxa":"Thị trấn Huỳnh Hữu Nghĩa",
      "quanhuyen":"81907"
   },
   {
      "id":"8190703",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"81907"
   },
   {
      "id":"8190705",
      "phuongxa":"Xã Mỹ Tú",
      "quanhuyen":"81907"
   },
   {
      "id":"8190707",
      "phuongxa":"Xã Hưng Phú",
      "quanhuyen":"81907"
   },
   {
      "id":"8190711",
      "phuongxa":"Xã Mỹ Thuận",
      "quanhuyen":"81907"
   },
   {
      "id":"8190713",
      "phuongxa":"Xã Mỹ Phước",
      "quanhuyen":"81907"
   },
   {
      "id":"8190715",
      "phuongxa":"Xã Long Hưng",
      "quanhuyen":"81907"
   },
   {
      "id":"8190717",
      "phuongxa":"Xã Hồ Đắc Kiện",
      "quanhuyen":"81907"
   },
   {
      "id":"8190719",
      "phuongxa":"Xã Phú Tâm",
      "quanhuyen":"81907"
   },
   {
      "id":"8190721",
      "phuongxa":"Xã Thiện Mỹ",
      "quanhuyen":"81907"
   },
   {
      "id":"8190723",
      "phuongxa":"Xã Thuận Hòa",
      "quanhuyen":"81917"
   },
   {
      "id":"8190725",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"81907"
   },
   {
      "id":"8190727",
      "phuongxa":"Xã Mỹ Hương",
      "quanhuyen":"81907"
   },
   {
      "id":"8190729",
      "phuongxa":"Xã An Hiệp",
      "quanhuyen":"81907"
   },
   {
      "id":"8190900",
      "phuongxa":"Không xác định",
      "quanhuyen":"81909"
   },
   {
      "id":"8190901",
      "phuongxa":"Thị trấn Mỹ Xuyên",
      "quanhuyen":"81909"
   },
   {
      "id":"8190903",
      "phuongxa":"Xã Tài Văn",
      "quanhuyen":"81909"
   },
   {
      "id":"8190905",
      "phuongxa":"Xã Viên An",
      "quanhuyen":"81909"
   },
   {
      "id":"8190907",
      "phuongxa":"Xã Viên Bình",
      "quanhuyen":"81909"
   },
   {
      "id":"8190909",
      "phuongxa":"Xã Thạnh Thới An",
      "quanhuyen":"81909"
   },
   {
      "id":"8190911",
      "phuongxa":"Xã Thạnh Quới",
      "quanhuyen":"81909"
   },
   {
      "id":"7110727",
      "phuongxa":"Phường Đông Hòa",
      "quanhuyen":"71107"
   },
   {
      "id":"7110900",
      "phuongxa":"Không xác định",
      "quanhuyen":"71109"
   },
   {
      "id":"7110901",
      "phuongxa":"Thị trấn Dầu Tiếng",
      "quanhuyen":"71109"
   },
   {
      "id":"7110903",
      "phuongxa":"Xã Minh Hoà",
      "quanhuyen":"71109"
   },
   {
      "id":"7110905",
      "phuongxa":"Xã Minh Thạnh",
      "quanhuyen":"71109"
   },
   {
      "id":"7110907",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"71109"
   },
   {
      "id":"7110909",
      "phuongxa":"Xã Định An",
      "quanhuyen":"71109"
   },
   {
      "id":"7110911",
      "phuongxa":"Xã Long Hoà",
      "quanhuyen":"71109"
   },
   {
      "id":"7110913",
      "phuongxa":"Xã Định Thành",
      "quanhuyen":"71109"
   },
   {
      "id":"7110915",
      "phuongxa":"Xã Định Hiệp",
      "quanhuyen":"71109"
   },
   {
      "id":"7110917",
      "phuongxa":"Xã An Lập",
      "quanhuyen":"71109"
   },
   {
      "id":"7110919",
      "phuongxa":"Xã Long Tân",
      "quanhuyen":"71109"
   },
   {
      "id":"7110923",
      "phuongxa":"Xã Thanh Tuyền",
      "quanhuyen":"71109"
   },
   {
      "id":"7111100",
      "phuongxa":"Không xác định",
      "quanhuyen":"71111"
   },
   {
      "id":"7111101",
      "phuongxa":"Phường Dĩ An",
      "quanhuyen":"71111"
   },
   {
      "id":"7111103",
      "phuongxa":"Phường Tân Bình",
      "quanhuyen":"71111"
   },
   {
      "id":"7111107",
      "phuongxa":"Phường Bình An",
      "quanhuyen":"71111"
   },
   {
      "id":"7111109",
      "phuongxa":"Phường Bình Thắng",
      "quanhuyen":"71111"
   },
   {
      "id":"7111111",
      "phuongxa":"Phường Đông Hòa",
      "quanhuyen":"71111"
   },
   {
      "id":"7111113",
      "phuongxa":"Phường An Bình",
      "quanhuyen":"71111"
   },
   {
      "id":"7130000",
      "phuongxa":"Không xác định",
      "quanhuyen":"71300"
   },
   {
      "id":"7130100",
      "phuongxa":"Không xác định",
      "quanhuyen":"71301"
   },
   {
      "id":"7130101",
      "phuongxa":"Phường Thanh Bình",
      "quanhuyen":"71301"
   },
   {
      "id":"7130103",
      "phuongxa":"Phường Hòa Bình",
      "quanhuyen":"71301"
   },
   {
      "id":"7130105",
      "phuongxa":"Phường Trung Dũng",
      "quanhuyen":"71301"
   },
   {
      "id":"7130107",
      "phuongxa":"Phường Quang Vinh",
      "quanhuyen":"71301"
   },
   {
      "id":"7130109",
      "phuongxa":"Phường Quyết Thắng",
      "quanhuyen":"71301"
   },
   {
      "id":"7130111",
      "phuongxa":"Phường Bửu Long",
      "quanhuyen":"71301"
   },
   {
      "id":"7130113",
      "phuongxa":"Phường Bửu Hòa",
      "quanhuyen":"71301"
   },
   {
      "id":"7130115",
      "phuongxa":"Phường Tân Vạn",
      "quanhuyen":"71301"
   },
   {
      "id":"7130117",
      "phuongxa":"Phường Tân Phong",
      "quanhuyen":"71301"
   },
   {
      "id":"7130119",
      "phuongxa":"Phường Thống Nhất",
      "quanhuyen":"71301"
   },
   {
      "id":"7130121",
      "phuongxa":"Phường Tân Tiến",
      "quanhuyen":"71301"
   },
   {
      "id":"7130123",
      "phuongxa":"Phường Trảng Dài",
      "quanhuyen":"71301"
   },
   {
      "id":"7130125",
      "phuongxa":"Phường Tân Mai",
      "quanhuyen":"71301"
   },
   {
      "id":"7130131",
      "phuongxa":"Phường Tam Hòa",
      "quanhuyen":"71301"
   },
   {
      "id":"7130133",
      "phuongxa":"Phường Bình Đa",
      "quanhuyen":"71301"
   },
   {
      "id":"7130135",
      "phuongxa":"Phường An Bình",
      "quanhuyen":"71301"
   },
   {
      "id":"7130137",
      "phuongxa":"Phường Long Bình Tân",
      "quanhuyen":"71301"
   },
   {
      "id":"7130139",
      "phuongxa":"Phường Long Bình",
      "quanhuyen":"71301"
   },
   {
      "id":"7130141",
      "phuongxa":"Phường Hố Nai",
      "quanhuyen":"71301"
   },
   {
      "id":"7130143",
      "phuongxa":"Phường Tân Biên",
      "quanhuyen":"71301"
   },
   {
      "id":"7130145",
      "phuongxa":"Phường Tân Hòa",
      "quanhuyen":"71301"
   },
   {
      "id":"7130147",
      "phuongxa":"Phường Tân Hạnh",
      "quanhuyen":"71301"
   },
   {
      "id":"7130149",
      "phuongxa":"Phường Hóa An",
      "quanhuyen":"71301"
   },
   {
      "id":"7130201",
      "phuongxa":"Thanh Bình",
      "quanhuyen":"71302"
   },
   {
      "id":"7130300",
      "phuongxa":"Không xác định",
      "quanhuyen":"71303"
   },
   {
      "id":"7130301",
      "phuongxa":"Thị trấn Tân Phú",
      "quanhuyen":"71303"
   },
   {
      "id":"7130303",
      "phuongxa":"Xã Dak Lua",
      "quanhuyen":"71303"
   },
   {
      "id":"7130305",
      "phuongxa":"Xã Nam Cát Tiên",
      "quanhuyen":"71303"
   },
   {
      "id":"7130307",
      "phuongxa":"Xã Phú An",
      "quanhuyen":"71303"
   },
   {
      "id":"7130309",
      "phuongxa":"Xã Núi Tượng",
      "quanhuyen":"71303"
   },
   {
      "id":"7130311",
      "phuongxa":"Xã Tà Lài",
      "quanhuyen":"71303"
   },
   {
      "id":"7130313",
      "phuongxa":"Xã Phú Lập",
      "quanhuyen":"71303"
   },
   {
      "id":"7130315",
      "phuongxa":"Xã Phú Thịnh",
      "quanhuyen":"71303"
   },
   {
      "id":"7130317",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"71303"
   },
   {
      "id":"7130319",
      "phuongxa":"Xã Phú Sơn",
      "quanhuyen":"71303"
   },
   {
      "id":"7130323",
      "phuongxa":"Xã Phú Bình",
      "quanhuyen":"71303"
   },
   {
      "id":"7130325",
      "phuongxa":"Xã Phú Lâm",
      "quanhuyen":"71303"
   },
   {
      "id":"7130327",
      "phuongxa":"Xã Phú Xuân",
      "quanhuyen":"71303"
   },
   {
      "id":"7130329",
      "phuongxa":"Xã Phú Thanh",
      "quanhuyen":"71303"
   },
   {
      "id":"7130331",
      "phuongxa":"Xã Phú Lộc",
      "quanhuyen":"71303"
   },
   {
      "id":"7130333",
      "phuongxa":"Xã Trà Cổ",
      "quanhuyen":"71303"
   },
   {
      "id":"7130335",
      "phuongxa":"Xã Phú Điền",
      "quanhuyen":"71303"
   },
   {
      "id":"7130500",
      "phuongxa":"Không xác định",
      "quanhuyen":"71305"
   },
   {
      "id":"7130501",
      "phuongxa":"Thị trấn Định Quán",
      "quanhuyen":"71305"
   },
   {
      "id":"7130505",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"71305"
   },
   {
      "id":"8070000",
      "phuongxa":"Không xác định",
      "quanhuyen":"80700"
   },
   {
      "id":"8070100",
      "phuongxa":"Không xác định",
      "quanhuyen":"80701"
   },
   {
      "id":"8070101",
      "phuongxa":"Phường 5",
      "quanhuyen":"80701"
   },
   {
      "id":"8070103",
      "phuongxa":"Phường 4",
      "quanhuyen":"80701"
   },
   {
      "id":"8070105",
      "phuongxa":"Phường 7",
      "quanhuyen":"80701"
   },
   {
      "id":"8070107",
      "phuongxa":"Phường 3",
      "quanhuyen":"80701"
   },
   {
      "id":"8070109",
      "phuongxa":"Phường 1",
      "quanhuyen":"80701"
   },
   {
      "id":"8070113",
      "phuongxa":"Phường 8",
      "quanhuyen":"80701"
   },
   {
      "id":"8070115",
      "phuongxa":"Phường 6",
      "quanhuyen":"80701"
   },
   {
      "id":"8070117",
      "phuongxa":"Xã Đạo Thạnh",
      "quanhuyen":"80701"
   },
   {
      "id":"8070119",
      "phuongxa":"Xã Trung An",
      "quanhuyen":"80701"
   },
   {
      "id":"8070121",
      "phuongxa":"Xã Mỹ Phong",
      "quanhuyen":"80701"
   },
   {
      "id":"8070123",
      "phuongxa":"Xã Tân Mỹ Chánh",
      "quanhuyen":"80701"
   },
   {
      "id":"8070300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80703"
   },
   {
      "id":"8070301",
      "phuongxa":"Phường 3",
      "quanhuyen":"80703"
   },
   {
      "id":"8070303",
      "phuongxa":"Phường 2",
      "quanhuyen":"80703"
   },
   {
      "id":"8070305",
      "phuongxa":"Phường 4",
      "quanhuyen":"80703"
   },
   {
      "id":"8070307",
      "phuongxa":"Phường 1",
      "quanhuyen":"80703"
   },
   {
      "id":"8070309",
      "phuongxa":"Xã Long Hưng",
      "quanhuyen":"80703"
   },
   {
      "id":"8070311",
      "phuongxa":"Xã Long Thuận",
      "quanhuyen":"80703"
   },
   {
      "id":"8070313",
      "phuongxa":"Xã Long Chánh",
      "quanhuyen":"80703"
   },
   {
      "id":"8070315",
      "phuongxa":"Xã Long Hòa",
      "quanhuyen":"80703"
   },
   {
      "id":"8070500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80705"
   },
   {
      "id":"8070501",
      "phuongxa":"Thị trấn Mỹ Phước",
      "quanhuyen":"80705"
   },
   {
      "id":"8070503",
      "phuongxa":"Xã Tân Hòa Đông",
      "quanhuyen":"80705"
   },
   {
      "id":"8070505",
      "phuongxa":"Xã Thạnh Tân",
      "quanhuyen":"80705"
   },
   {
      "id":"8070507",
      "phuongxa":"Xã Thạnh Mỹ",
      "quanhuyen":"80705"
   },
   {
      "id":"8070509",
      "phuongxa":"Xã Thạnh Hoà",
      "quanhuyen":"80705"
   },
   {
      "id":"8070511",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"80705"
   },
   {
      "id":"8070513",
      "phuongxa":"Xã Tân Hòa  Thành",
      "quanhuyen":"80705"
   },
   {
      "id":"8070515",
      "phuongxa":"Xã Hưng Thạnh",
      "quanhuyen":"80705"
   },
   {
      "id":"8070517",
      "phuongxa":"Xã Tân Lập 1",
      "quanhuyen":"80705"
   },
   {
      "id":"8070519",
      "phuongxa":"Xã Tân Hòa Tây",
      "quanhuyen":"80705"
   },
   {
      "id":"8070521",
      "phuongxa":"Xã Mỹ Phước",
      "quanhuyen":"80705"
   },
   {
      "id":"8070523",
      "phuongxa":"Xã Tân Lập 2",
      "quanhuyen":"80705"
   },
   {
      "id":"8070525",
      "phuongxa":"Xã Phước Lập",
      "quanhuyen":"80705"
   },
   {
      "id":"8070700",
      "phuongxa":"Không xác định",
      "quanhuyen":"80707"
   },
   {
      "id":"8070703",
      "phuongxa":"Xã Tân Hội Đông",
      "quanhuyen":"80707"
   },
   {
      "id":"8070705",
      "phuongxa":"Xã Tân Hương",
      "quanhuyen":"80707"
   },
   {
      "id":"8070707",
      "phuongxa":"Xã Tân Lý Đông",
      "quanhuyen":"80707"
   },
   {
      "id":"8070709",
      "phuongxa":"Xã Tân Lý Tây",
      "quanhuyen":"80707"
   },
   {
      "id":"8070711",
      "phuongxa":"Xã Thân Cửu Nghĩa",
      "quanhuyen":"80707"
   },
   {
      "id":"8070713",
      "phuongxa":"Xã Tam Hiệp",
      "quanhuyen":"80707"
   },
   {
      "id":"8070715",
      "phuongxa":"Xã Điềm Hy",
      "quanhuyen":"80707"
   },
   {
      "id":"8070717",
      "phuongxa":"Xã Nhị Bình",
      "quanhuyen":"80707"
   },
   {
      "id":"8070719",
      "phuongxa":"Xã Dưỡng Điềm",
      "quanhuyen":"80707"
   },
   {
      "id":"8070721",
      "phuongxa":"Xã Đông Hòa",
      "quanhuyen":"80707"
   },
   {
      "id":"8070725",
      "phuongxa":"Xã Hữu Đạo",
      "quanhuyen":"80707"
   },
   {
      "id":"8070727",
      "phuongxa":"Xã Long An",
      "quanhuyen":"80707"
   },
   {
      "id":"8070729",
      "phuongxa":"Xã Long Hưng",
      "quanhuyen":"80707"
   },
   {
      "id":"8070731",
      "phuongxa":"Xã Bình Trưng",
      "quanhuyen":"80707"
   },
   {
      "id":"8070733",
      "phuongxa":"Xã Phước Thạnh",
      "quanhuyen":"80707"
   },
   {
      "id":"8070735",
      "phuongxa":"Xã Thạnh Phú",
      "quanhuyen":"80707"
   },
   {
      "id":"8070737",
      "phuongxa":"Xã Bàn Long",
      "quanhuyen":"80707"
   },
   {
      "id":"8070739",
      "phuongxa":"Xã Vĩnh Kim",
      "quanhuyen":"80707"
   },
   {
      "id":"8070741",
      "phuongxa":"Xã Bình Đức",
      "quanhuyen":"80707"
   },
   {
      "id":"8070743",
      "phuongxa":"Xã Song Thuận",
      "quanhuyen":"80707"
   },
   {
      "id":"8070747",
      "phuongxa":"Xã Phú Phong",
      "quanhuyen":"80707"
   },
   {
      "id":"8070749",
      "phuongxa":"Xã Thới Sơn",
      "quanhuyen":"80707"
   },
   {
      "id":"8070900",
      "phuongxa":"Phường 5",
      "quanhuyen":"80709"
   },
   {
      "id":"8070901",
      "phuongxa":"Phường 3",
      "quanhuyen":"80709"
   },
   {
      "id":"8070903",
      "phuongxa":"Xã Thạnh Lộc",
      "quanhuyen":"82625"
   },
   {
      "id":"8070905",
      "phuongxa":"Xã Mỹ Thành Bắc",
      "quanhuyen":"82625"
   },
   {
      "id":"8070907",
      "phuongxa":"Xã Phú Cường",
      "quanhuyen":"82625"
   },
   {
      "id":"8070909",
      "phuongxa":"Xã Mỹ Phước Tây",
      "quanhuyen":"80709"
   },
   {
      "id":"8070911",
      "phuongxa":"Xã Mỹ Hạnh Đông",
      "quanhuyen":"80709"
   },
   {
      "id":"8070917",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"80709"
   },
   {
      "id":"8070919",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"80709"
   },
   {
      "id":"8070921",
      "phuongxa":"Xã Phú Nhuận",
      "quanhuyen":"82625"
   },
   {
      "id":"8070923",
      "phuongxa":"Xã Tân Hội",
      "quanhuyen":"80709"
   },
   {
      "id":"8190913",
      "phuongxa":"Xã Thạnh Phú",
      "quanhuyen":"81909"
   },
   {
      "id":"8190915",
      "phuongxa":"Xã Đại Tâm",
      "quanhuyen":"81909"
   },
   {
      "id":"8190917",
      "phuongxa":"Xã Ngọc Tố",
      "quanhuyen":"81909"
   },
   {
      "id":"8190919",
      "phuongxa":"Xã Ngọc Đông",
      "quanhuyen":"81909"
   },
   {
      "id":"8190921",
      "phuongxa":"Xã Hòa Tú 1",
      "quanhuyen":"81909"
   },
   {
      "id":"8190923",
      "phuongxa":"Xã Hòa Tú II",
      "quanhuyen":"81909"
   },
   {
      "id":"8190927",
      "phuongxa":"Xã Gia Hòa 2",
      "quanhuyen":"81909"
   },
   {
      "id":"8190929",
      "phuongxa":"Xã Tham Đôn",
      "quanhuyen":"81909"
   },
   {
      "id":"8190931",
      "phuongxa":"Xã Thạnh Thới Thuận",
      "quanhuyen":"81909"
   },
   {
      "id":"8191100",
      "phuongxa":"Không xác định",
      "quanhuyen":"81911"
   },
   {
      "id":"8191101",
      "phuongxa":"Thị trấn Phú Lộc",
      "quanhuyen":"81911"
   },
   {
      "id":"8191103",
      "phuongxa":"Thị trấn Ngã Năm",
      "quanhuyen":"81911"
   },
   {
      "id":"8191105",
      "phuongxa":"Xã Vĩnh Quới",
      "quanhuyen":"81911"
   },
   {
      "id":"8191107",
      "phuongxa":"Xã Lâm Tân",
      "quanhuyen":"81911"
   },
   {
      "id":"8191109",
      "phuongxa":"Xã Lâm Kiết",
      "quanhuyen":"81911"
   },
   {
      "id":"8191113",
      "phuongxa":"Xã Thạnh Tân",
      "quanhuyen":"81911"
   },
   {
      "id":"8191115",
      "phuongxa":"Xã Châu Hưng",
      "quanhuyen":"81911"
   },
   {
      "id":"8191117",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"81911"
   },
   {
      "id":"8191119",
      "phuongxa":"Xã Vĩnh Biên",
      "quanhuyen":"81911"
   },
   {
      "id":"8191121",
      "phuongxa":"Xã Long Tân",
      "quanhuyen":"81911"
   },
   {
      "id":"8191123",
      "phuongxa":"Xã Mỹ Quới",
      "quanhuyen":"81911"
   },
   {
      "id":"8191125",
      "phuongxa":"Xã Vĩnh Lợi",
      "quanhuyen":"81911"
   },
   {
      "id":"8191127",
      "phuongxa":"Xã Tuân Tức",
      "quanhuyen":"81911"
   },
   {
      "id":"8191300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81913"
   },
   {
      "id":"8191301",
      "phuongxa":"Thị trấn Vĩnh Châu",
      "quanhuyen":"81913"
   },
   {
      "id":"8191303",
      "phuongxa":"Xã Lai Hòa",
      "quanhuyen":"82611"
   },
   {
      "id":"8191305",
      "phuongxa":"Xã Vĩnh Tân",
      "quanhuyen":"82611"
   },
   {
      "id":"8191307",
      "phuongxa":"Phường Vĩnh Phước",
      "quanhuyen":"82611"
   },
   {
      "id":"8191309",
      "phuongxa":"Xã Vĩnh Hiệp",
      "quanhuyen":"82611"
   },
   {
      "id":"8191311",
      "phuongxa":"Xã Hòa Đông",
      "quanhuyen":"82611"
   },
   {
      "id":"8191315",
      "phuongxa":"Xã Vĩnh Châu",
      "quanhuyen":"81913"
   },
   {
      "id":"8191317",
      "phuongxa":"Xã Lạc Hòa",
      "quanhuyen":"82611"
   },
   {
      "id":"8191319",
      "phuongxa":"Xã Vĩnh Hải",
      "quanhuyen":"82611"
   },
   {
      "id":"8191503",
      "phuongxa":"Xã Long Bình",
      "quanhuyen":"82615"
   },
   {
      "id":"8191505",
      "phuongxa":"Long Tân",
      "quanhuyen":"81915"
   },
   {
      "id":"8191701",
      "phuongxa":"Xã Thiện Mỹ",
      "quanhuyen":"81917"
   },
   {
      "id":"8191702",
      "phuongxa":"Thị Trấn Châu Thành",
      "quanhuyen":"81917"
   },
   {
      "id":"8191802",
      "phuongxa":"Xã Đại Ân 1",
      "quanhuyen":"81918"
   },
   {
      "id":"8210000",
      "phuongxa":"Không xác định",
      "quanhuyen":"82100"
   },
   {
      "id":"8210100",
      "phuongxa":"Không xác định",
      "quanhuyen":"82101"
   },
   {
      "id":"8210101",
      "phuongxa":"Phường 2",
      "quanhuyen":"82101"
   },
   {
      "id":"8210103",
      "phuongxa":"Phường 3",
      "quanhuyen":"82101"
   },
   {
      "id":"8210105",
      "phuongxa":"Phường 5",
      "quanhuyen":"82101"
   },
   {
      "id":"8210107",
      "phuongxa":"Phường 7",
      "quanhuyen":"82101"
   },
   {
      "id":"8210109",
      "phuongxa":"Phường 8",
      "quanhuyen":"82101"
   },
   {
      "id":"8210111",
      "phuongxa":"Xã Thuận Hòa",
      "quanhuyen":"82101"
   },
   {
      "id":"8210113",
      "phuongxa":"Xã Hiệp Thành",
      "quanhuyen":"82101"
   },
   {
      "id":"8210200",
      "phuongxa":"Không xác định",
      "quanhuyen":"82102"
   },
   {
      "id":"8210300",
      "phuongxa":"Không xác định",
      "quanhuyen":"82103"
   },
   {
      "id":"8210301",
      "phuongxa":"Thị trấn Phước Long",
      "quanhuyen":"82103"
   },
   {
      "id":"8210305",
      "phuongxa":"Xã Ninh Quới",
      "quanhuyen":"82103"
   },
   {
      "id":"8210307",
      "phuongxa":"Xã Ninh Hòa",
      "quanhuyen":"82103"
   },
   {
      "id":"8210309",
      "phuongxa":"Xã Lộc Ninh",
      "quanhuyen":"82103"
   },
   {
      "id":"8210311",
      "phuongxa":"Xã Vĩnh Lộc",
      "quanhuyen":"82103"
   },
   {
      "id":"8210313",
      "phuongxa":"Xã Ninh Thạnh Lợi",
      "quanhuyen":"82103"
   },
   {
      "id":"8210315",
      "phuongxa":"Xã Vĩnh Phú Đông",
      "quanhuyen":"82103"
   },
   {
      "id":"8210317",
      "phuongxa":"Xã Vĩnh Phú Tây",
      "quanhuyen":"82103"
   },
   {
      "id":"8210319",
      "phuongxa":"Xã Phước Long",
      "quanhuyen":"82103"
   },
   {
      "id":"8210321",
      "phuongxa":"Xã Hưng Phú",
      "quanhuyen":"82103"
   },
   {
      "id":"8210323",
      "phuongxa":"Xã Vĩnh Thanh",
      "quanhuyen":"82103"
   },
   {
      "id":"8210500",
      "phuongxa":"Không xác định",
      "quanhuyen":"82105"
   },
   {
      "id":"8210501",
      "phuongxa":"Thị trấn Hòa Bình",
      "quanhuyen":"82105"
   },
   {
      "id":"8210503",
      "phuongxa":"Xã Vĩnh Hưng",
      "quanhuyen":"82105"
   },
   {
      "id":"8210505",
      "phuongxa":"Xã Châu Hưng",
      "quanhuyen":"82105"
   },
   {
      "id":"8210507",
      "phuongxa":"Xã Hưng Thành",
      "quanhuyen":"82105"
   },
   {
      "id":"8210509",
      "phuongxa":"Xã Hưng Hội",
      "quanhuyen":"82105"
   },
   {
      "id":"8210511",
      "phuongxa":"Xã Châu Thới",
      "quanhuyen":"82105"
   },
   {
      "id":"8210513",
      "phuongxa":"Xã Minh Diệu",
      "quanhuyen":"82105"
   },
   {
      "id":"8210515",
      "phuongxa":"Xã Vĩnh Bình",
      "quanhuyen":"82105"
   },
   {
      "id":"8210517",
      "phuongxa":"Xã Long Thạnh",
      "quanhuyen":"82105"
   },
   {
      "id":"7130509",
      "phuongxa":"Xã Ngọc Định",
      "quanhuyen":"71305"
   },
   {
      "id":"7130511",
      "phuongxa":"Xã La Ngà",
      "quanhuyen":"71305"
   },
   {
      "id":"7130513",
      "phuongxa":"Xã Phú Lợi",
      "quanhuyen":"71305"
   },
   {
      "id":"7130515",
      "phuongxa":"Xã Phú Hòa",
      "quanhuyen":"71305"
   },
   {
      "id":"7130517",
      "phuongxa":"Xã Gia Canh",
      "quanhuyen":"71305"
   },
   {
      "id":"7130519",
      "phuongxa":"Xã Phú Ngọc",
      "quanhuyen":"71305"
   },
   {
      "id":"7130521",
      "phuongxa":"Xã Túc Trưng",
      "quanhuyen":"71305"
   },
   {
      "id":"7130523",
      "phuongxa":"Xã Phú Cường",
      "quanhuyen":"71305"
   },
   {
      "id":"7130525",
      "phuongxa":"Xã Phú Túc",
      "quanhuyen":"71305"
   },
   {
      "id":"7130527",
      "phuongxa":"Xã Suối Nho",
      "quanhuyen":"71305"
   },
   {
      "id":"7130701",
      "phuongxa":"Thị trấn Vĩnh An",
      "quanhuyen":"71307"
   },
   {
      "id":"7130703",
      "phuongxa":"Xã Phú Lý",
      "quanhuyen":"71307"
   },
   {
      "id":"7130705",
      "phuongxa":"Xã Trị An",
      "quanhuyen":"71307"
   },
   {
      "id":"7130707",
      "phuongxa":"Xã Vĩnh Tân",
      "quanhuyen":"71307"
   },
   {
      "id":"7130709",
      "phuongxa":"Xã Tân An",
      "quanhuyen":"71307"
   },
   {
      "id":"7130711",
      "phuongxa":"Xã Thiện Tân",
      "quanhuyen":"71307"
   },
   {
      "id":"7130713",
      "phuongxa":"Xã Thạnh Phú",
      "quanhuyen":"71307"
   },
   {
      "id":"7130715",
      "phuongxa":"Xã Bình Lợi",
      "quanhuyen":"71307"
   },
   {
      "id":"7130717",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"71307"
   },
   {
      "id":"7130719",
      "phuongxa":"Xã Bình Hòa",
      "quanhuyen":"71307"
   },
   {
      "id":"7130900",
      "phuongxa":"Không xác định",
      "quanhuyen":"71309"
   },
   {
      "id":"7130901",
      "phuongxa":"Thị trấn Dầu Giây",
      "quanhuyen":"71309"
   },
   {
      "id":"7130903",
      "phuongxa":"Xã Gia Tân 1",
      "quanhuyen":"71309"
   },
   {
      "id":"7130905",
      "phuongxa":"Xã Gia Tân 2",
      "quanhuyen":"71309"
   },
   {
      "id":"7130907",
      "phuongxa":"Xã Gia Tân 3",
      "quanhuyen":"71309"
   },
   {
      "id":"7130909",
      "phuongxa":"Xã Gia Kiệm",
      "quanhuyen":"71309"
   },
   {
      "id":"7130911",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"71309"
   },
   {
      "id":"7130913",
      "phuongxa":"Xã Bàu Hàm 2",
      "quanhuyen":"71309"
   },
   {
      "id":"7130915",
      "phuongxa":"Xã Lộ 25",
      "quanhuyen":"71309"
   },
   {
      "id":"7130917",
      "phuongxa":"Xã Cây Gáo",
      "quanhuyen":"71309"
   },
   {
      "id":"7130919",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"71302"
   },
   {
      "id":"7130921",
      "phuongxa":"Xã Sông Trầu",
      "quanhuyen":"71309"
   },
   {
      "id":"7130923",
      "phuongxa":"Xã Đồi 61",
      "quanhuyen":"71309"
   },
   {
      "id":"7130925",
      "phuongxa":"Xã An Viễn",
      "quanhuyen":"71309"
   },
   {
      "id":"7130927",
      "phuongxa":"Xã Bàu Hàm",
      "quanhuyen":"71309"
   },
   {
      "id":"7130929",
      "phuongxa":"Xã Sông Thao",
      "quanhuyen":"71309"
   },
   {
      "id":"7130931",
      "phuongxa":"Xã Hưng Lộc",
      "quanhuyen":"71309"
   },
   {
      "id":"7130935",
      "phuongxa":"Xã Đông Hoà",
      "quanhuyen":"71309"
   },
   {
      "id":"7130937",
      "phuongxa":"Xã Trung Hoà",
      "quanhuyen":"71309"
   },
   {
      "id":"7130939",
      "phuongxa":"Xã Tây Hoà",
      "quanhuyen":"71309"
   },
   {
      "id":"7130941",
      "phuongxa":"Xã Quảng Tiến",
      "quanhuyen":"71302"
   },
   {
      "id":"7130943",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"71309"
   },
   {
      "id":"7130945",
      "phuongxa":"Xã Giang Điền",
      "quanhuyen":"71302"
   },
   {
      "id":"7130947",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"71309"
   },
   {
      "id":"7130949",
      "phuongxa":"Xã Hố Nai 3",
      "quanhuyen":"71302"
   },
   {
      "id":"7131100",
      "phuongxa":"Không xác định",
      "quanhuyen":"71311"
   },
   {
      "id":"7131101",
      "phuongxa":"Phường Phú Bình",
      "quanhuyen":"71311"
   },
   {
      "id":"7131105",
      "phuongxa":"Xã Bình Lộc",
      "quanhuyen":"71311"
   },
   {
      "id":"7131107",
      "phuongxa":"Xã Xuân Thiện",
      "quanhuyen":"71311"
   },
   {
      "id":"7131109",
      "phuongxa":"Xã Bảo Quang",
      "quanhuyen":"71311"
   },
   {
      "id":"7131111",
      "phuongxa":"Phường Suối Tre",
      "quanhuyen":"71311"
   },
   {
      "id":"7131113",
      "phuongxa":"Xã Xuân Thạnh",
      "quanhuyen":"71311"
   },
   {
      "id":"7131115",
      "phuongxa":"Phường Xuân Lập",
      "quanhuyen":"71311"
   },
   {
      "id":"7131117",
      "phuongxa":"Phường Bàu Sen",
      "quanhuyen":"71311"
   },
   {
      "id":"7131119",
      "phuongxa":"Phường Xuân Tân",
      "quanhuyen":"71311"
   },
   {
      "id":"7131121",
      "phuongxa":"Phường Xuân Thanh",
      "quanhuyen":"71311"
   },
   {
      "id":"7131123",
      "phuongxa":"Xã Hàng Gòn",
      "quanhuyen":"71311"
   },
   {
      "id":"7131125",
      "phuongxa":"Xã Bàu Trâm",
      "quanhuyen":"71311"
   },
   {
      "id":"7131129",
      "phuongxa":"Xã Nhân Nghĩa",
      "quanhuyen":"71311"
   },
   {
      "id":"7131131",
      "phuongxa":"Xã Xuân Mỹ",
      "quanhuyen":"71311"
   },
   {
      "id":"7131133",
      "phuongxa":"Xã Thừa Đức",
      "quanhuyen":"71311"
   },
   {
      "id":"7131135",
      "phuongxa":"Xã Xuân Đường",
      "quanhuyen":"71311"
   },
   {
      "id":"7131300",
      "phuongxa":"Không xác định",
      "quanhuyen":"71313"
   },
   {
      "id":"7131303",
      "phuongxa":"Xã Xuân Bắc",
      "quanhuyen":"71313"
   },
   {
      "id":"7131305",
      "phuongxa":"Xã Xuân Thành",
      "quanhuyen":"71313"
   },
   {
      "id":"7131307",
      "phuongxa":"Xã Xuân Trường",
      "quanhuyen":"71313"
   },
   {
      "id":"7131311",
      "phuongxa":"Xã Xuân Thọ",
      "quanhuyen":"71313"
   },
   {
      "id":"7131313",
      "phuongxa":"Xã Xuân Tâm",
      "quanhuyen":"71313"
   },
   {
      "id":"7131315",
      "phuongxa":"Xã Lang Minh",
      "quanhuyen":"71313"
   },
   {
      "id":"7131317",
      "phuongxa":"Xã Xuân Hiệp",
      "quanhuyen":"71313"
   },
   {
      "id":"7131319",
      "phuongxa":"Xã Suối Cát",
      "quanhuyen":"71313"
   },
   {
      "id":"8071811",
      "phuongxa":"Tân Thạnh",
      "quanhuyen":"80718"
   },
   {
      "id":"8071158",
      "phuongxa":"Tân Hòa",
      "quanhuyen":"80718"
   },
   {
      "id":"8258713",
      "phuongxa":"Bắc Sơn",
      "quanhuyen":"71302"
   },
   {
      "id":"8258741",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"81713"
   },
   {
      "id":"8258760",
      "phuongxa":"Long Bình",
      "quanhuyen":"82586"
   },
   {
      "id":"8258769",
      "phuongxa":"Xã Điền Hải",
      "quanhuyen":"82182"
   },
   {
      "id":"8258787",
      "phuongxa":"Phường Cam Lợi",
      "quanhuyen":"51109"
   },
   {
      "id":"8258788",
      "phuongxa":"Bình Tân",
      "quanhuyen":"70703"
   },
   {
      "id":"8258822",
      "phuongxa":"Xã Đa Phước Hội",
      "quanhuyen":"81107"
   },
   {
      "id":"8258874",
      "phuongxa":"Xã Hiệp Tùng",
      "quanhuyen":"82314"
   },
   {
      "id":"8258902",
      "phuongxa":"Sông Trầu",
      "quanhuyen":"71302"
   },
   {
      "id":"8258923",
      "phuongxa":"Phường An Lạc",
      "quanhuyen":"70147"
   },
   {
      "id":"8258983",
      "phuongxa":"Xã Thuận Hòa",
      "quanhuyen":"82586"
   },
   {
      "id":"8258991",
      "phuongxa":"Xã Định Hưng",
      "quanhuyen":"40135"
   },
   {
      "id":"8258992",
      "phuongxa":"Phường 8",
      "quanhuyen":"50901"
   },
   {
      "id":"8259022",
      "phuongxa":"Phường Tiến Thành",
      "quanhuyen":"70704"
   },
   {
      "id":"8259028",
      "phuongxa":"ĐaKia",
      "quanhuyen":"82588"
   },
   {
      "id":"8259044",
      "phuongxa":"Phường Xuân Bình",
      "quanhuyen":"71311"
   },
   {
      "id":"8258709",
      "phuongxa":"Phường Bình Thủy",
      "quanhuyen":"81518"
   },
   {
      "id":"8258747",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"71108"
   },
   {
      "id":"8258748",
      "phuongxa":"Thị Trấn Ninh Cùng",
      "quanhuyen":"82584"
   },
   {
      "id":"8258749",
      "phuongxa":"Xã An Định",
      "quanhuyen":"81102"
   },
   {
      "id":"8258762",
      "phuongxa":"Xã Vĩnh Mỹ B",
      "quanhuyen":"82108"
   },
   {
      "id":"8258814",
      "phuongxa":"Phước Hòa",
      "quanhuyen":"71108"
   },
   {
      "id":"8258815",
      "phuongxa":"Nhà Mát",
      "quanhuyen":"82101"
   },
   {
      "id":"8258897",
      "phuongxa":"Đông Sơn",
      "quanhuyen":"40103"
   },
   {
      "id":"8258904",
      "phuongxa":"Xã Thạnh Thới An",
      "quanhuyen":"82599"
   },
   {
      "id":"8258956",
      "phuongxa":"Thị Trấn Cái Tắc",
      "quanhuyen":"82587"
   },
   {
      "id":"8258969",
      "phuongxa":"Minh Đức",
      "quanhuyen":"81102"
   },
   {
      "id":"8258970",
      "phuongxa":"vĩnh phú tây",
      "quanhuyen":"82102"
   },
   {
      "id":"8258977",
      "phuongxa":"Nghĩa Hà",
      "quanhuyen":"50501"
   },
   {
      "id":"8258986",
      "phuongxa":"Xã Bình Thạnh",
      "quanhuyen":"82586"
   },
   {
      "id":"8258717",
      "phuongxa":"Long Điền",
      "quanhuyen":"82182"
   },
   {
      "id":"8258779",
      "phuongxa":"Phường Long Sơn",
      "quanhuyen":"82602"
   },
   {
      "id":"8258789",
      "phuongxa":"Phường Núi Sam",
      "quanhuyen":"82595"
   },
   {
      "id":"8258790",
      "phuongxa":"Khánh Vân",
      "quanhuyen":"82596"
   },
   {
      "id":"8258791",
      "phuongxa":"Mái Dầm",
      "quanhuyen":"82582"
   },
   {
      "id":"8258792",
      "phuongxa":"Tân Phúc",
      "quanhuyen":"71513"
   },
   {
      "id":"8258804",
      "phuongxa":"Thạnh Xuân",
      "quanhuyen":"82582"
   },
   {
      "id":"8258860",
      "phuongxa":"Phường Tân Đồng",
      "quanhuyen":"70704"
   },
   {
      "id":"8258861",
      "phuongxa":"Phường Hiệp Ninh",
      "quanhuyen":"82601"
   },
   {
      "id":"8258871",
      "phuongxa":"Xã Rạch Chéo",
      "quanhuyen":"82302"
   },
   {
      "id":"8258873",
      "phuongxa":"Xã Nhơn Nghĩa",
      "quanhuyen":"81517"
   },
   {
      "id":"8258946",
      "phuongxa":"Thị Trấn Mỹ Lương",
      "quanhuyen":"80517"
   },
   {
      "id":"8258948",
      "phuongxa":"Xã Đức Mạnh",
      "quanhuyen":"82597"
   },
   {
      "id":"8258963",
      "phuongxa":"Trại giam Xuân Lộc",
      "quanhuyen":"71313"
   },
   {
      "id":"8258974",
      "phuongxa":"Thị trấn Hòa Bình",
      "quanhuyen":"82108"
   },
   {
      "id":"8258994",
      "phuongxa":"Phường Long Hưng",
      "quanhuyen":"81505"
   },
   {
      "id":"8258995",
      "phuongxa":"Xã An Ngãi",
      "quanhuyen":"71712"
   },
   {
      "id":"8259037",
      "phuongxa":"Định Thủy",
      "quanhuyen":"81102"
   },
   {
      "id":"8258732",
      "phuongxa":"Xã Lai Hưng",
      "quanhuyen":"82592"
   },
   {
      "id":"8258810",
      "phuongxa":"An Bình",
      "quanhuyen":"81520"
   },
   {
      "id":"8258811",
      "phuongxa":"Đức Minh",
      "quanhuyen":"82597"
   },
   {
      "id":"8258931",
      "phuongxa":"Xã Long Thạnh",
      "quanhuyen":"82584"
   },
   {
      "id":"8258938",
      "phuongxa":"Long Tuyền",
      "quanhuyen":"81518"
   },
   {
      "id":"8258980",
      "phuongxa":"Xã Hương An",
      "quanhuyen":"50317"
   },
   {
      "id":"7014589",
      "phuongxa":"Trung Chánh",
      "quanhuyen":"70137"
   },
   {
      "id":"8258711",
      "phuongxa":"Bình Dương ",
      "quanhuyen":"50709"
   },
   {
      "id":"8258729",
      "phuongxa":"Tân Bình",
      "quanhuyen":"82584"
   },
   {
      "id":"8258775",
      "phuongxa":"Phường Phú Đông",
      "quanhuyen":"50911"
   },
   {
      "id":"8258780",
      "phuongxa":"Thị trấn Đất Đỏ",
      "quanhuyen":"71714"
   },
   {
      "id":"8258781",
      "phuongxa":"Xã An Nhứt",
      "quanhuyen":"71712"
   },
   {
      "id":"8258823",
      "phuongxa":"Phường Vĩnh Bảo",
      "quanhuyen":"81301"
   },
   {
      "id":"8258824",
      "phuongxa":"Phường 10",
      "quanhuyen":"80701"
   },
   {
      "id":"8258964",
      "phuongxa":"Phong Thạnh Tây B",
      "quanhuyen":"82102"
   },
   {
      "id":"8259012",
      "phuongxa":"Phường Tân Hưng",
      "quanhuyen":"81503"
   },
   {
      "id":"8259024",
      "phuongxa":"Hòa Lợi",
      "quanhuyen":"81311"
   },
   {
      "id":"8258724",
      "phuongxa":"Đạo Nghĩa",
      "quanhuyen":"60777"
   },
   {
      "id":"8258834",
      "phuongxa":"Xã An Cơ",
      "quanhuyen":"70909"
   },
   {
      "id":"8258855",
      "phuongxa":"Xã An Hiệp",
      "quanhuyen":"81917"
   },
   {
      "id":"8258896",
      "phuongxa":"Xã Tân Phước Hưng",
      "quanhuyen":"82584"
   },
   {
      "id":"8258932",
      "phuongxa":"Phường 7",
      "quanhuyen":"50911"
   },
   {
      "id":"8258958",
      "phuongxa":"Xã An Thạnh 2",
      "quanhuyen":"81918"
   },
   {
      "id":"8258959",
      "phuongxa":"Phường 5",
      "quanhuyen":"82583"
   },
   {
      "id":"8258967",
      "phuongxa":"An Thái",
      "quanhuyen":"71108"
   },
   {
      "id":"8258989",
      "phuongxa":"Xã Quý Lộc",
      "quanhuyen":"40135"
   },
   {
      "id":"8259003",
      "phuongxa":"Xã Phú Văn",
      "quanhuyen":"82588"
   },
   {
      "id":"8259021",
      "phuongxa":"Xã An Phúc",
      "quanhuyen":"82182"
   },
   {
      "id":"8259031",
      "phuongxa":"Xã Hàng Vịnh",
      "quanhuyen":"82314"
   },
   {
      "id":"8258708",
      "phuongxa":"không xác định",
      "quanhuyen":"60529"
   },
   {
      "id":"7071155",
      "phuongxa":"Long Hưng",
      "quanhuyen":"82588"
   },
   {
      "id":"8050755",
      "phuongxa":"Long Hưng",
      "quanhuyen":"80507"
   },
   {
      "id":"8258455",
      "phuongxa":"Tân Long",
      "quanhuyen":"82584"
   },
   {
      "id":"8258763",
      "phuongxa":"Xã Tân Thạnh",
      "quanhuyen":"81317"
   },
   {
      "id":"8258764",
      "phuongxa":"Xã Nguyễn Việt Khái",
      "quanhuyen":"82302"
   },
   {
      "id":"8258765",
      "phuongxa":"Thị trấn Long Điền",
      "quanhuyen":"71712"
   },
   {
      "id":"8258841",
      "phuongxa":"Hòa Mỹ",
      "quanhuyen":"82584"
   },
   {
      "id":"8258908",
      "phuongxa":"Bảo Bình",
      "quanhuyen":"71318"
   },
   {
      "id":"8258917",
      "phuongxa":"Xã Ngọc Thuận",
      "quanhuyen":"81311"
   },
   {
      "id":"8258950",
      "phuongxa":"Tân Hải",
      "quanhuyen":"82607"
   },
   {
      "id":"8258982",
      "phuongxa":"Phường 2",
      "quanhuyen":"82611"
   },
   {
      "id":"8259030",
      "phuongxa":"Thị Trấn Ngã Sáu ",
      "quanhuyen":"82582"
   },
   {
      "id":"8259039",
      "phuongxa":"Tân Tiến",
      "quanhuyen":"82608"
   },
   {
      "id":"8258718",
      "phuongxa":"Sơn Bích",
      "quanhuyen":"81305"
   },
   {
      "id":"8258719",
      "phuongxa":"Lưu Nghĩa",
      "quanhuyen":"82586"
   },
   {
      "id":"8258906",
      "phuongxa":"Xã Vĩnh Hậu",
      "quanhuyen":"82108"
   },
   {
      "id":"8258914",
      "phuongxa":"Xã Long Giao",
      "quanhuyen":"71318"
   },
   {
      "id":"8258915",
      "phuongxa":"Xã Nhơn Nghĩa A",
      "quanhuyen":"82587"
   },
   {
      "id":"8258916",
      "phuongxa":"Xã Lương Nghĩa",
      "quanhuyen":"82586"
   },
   {
      "id":"8258935",
      "phuongxa":"Xã Vị Bình",
      "quanhuyen":"82585"
   },
   {
      "id":"8258936",
      "phuongxa":"Xã Hà Bình",
      "quanhuyen":"11505"
   },
   {
      "id":"8258945",
      "phuongxa":"Đắk Wer",
      "quanhuyen":"60777"
   },
   {
      "id":"8258960",
      "phuongxa":"Xã Vĩnh Lộc A",
      "quanhuyen":"82103"
   },
   {
      "id":"8258975",
      "phuongxa":"Xã Lâm San",
      "quanhuyen":"71318"
   },
   {
      "id":"8259013",
      "phuongxa":"Phường Hòa Thuận",
      "quanhuyen":"50301"
   },
   {
      "id":"8259014",
      "phuongxa":"Xã An Trạch A",
      "quanhuyen":"82182"
   },
   {
      "id":"8131199",
      "phuongxa":"Xã Vĩnh Phú ",
      "quanhuyen":"82616"
   },
   {
      "id":"8258744",
      "phuongxa":"Xã Mỹ Bình",
      "quanhuyen":"80113"
   },
   {
      "id":"8258745",
      "phuongxa":"Xã Tăng Hòa",
      "quanhuyen":"80717"
   },
   {
      "id":"8258806",
      "phuongxa":"Xuân An",
      "quanhuyen":"71501"
   },
   {
      "id":"8258864",
      "phuongxa":"Xã Long Mỹ ",
      "quanhuyen":"71714"
   },
   {
      "id":"8258924",
      "phuongxa":"Phường Tân Xuyên",
      "quanhuyen":"82301"
   },
   {
      "id":"8258973",
      "phuongxa":"Xã Tân Lâm",
      "quanhuyen":"71707"
   },
   {
      "id":"8259002",
      "phuongxa":"Phú Trang",
      "quanhuyen":"41109"
   },
   {
      "id":"8259008",
      "phuongxa":"Đông Xuyên",
      "quanhuyen":"80501"
   },
   {
      "id":"8259015",
      "phuongxa":"Nhân Cơ",
      "quanhuyen":"60777"
   },
   {
      "id":"8259033",
      "phuongxa":"Phường An Thới",
      "quanhuyen":"81518"
   },
   {
      "id":"8259035",
      "phuongxa":"Xã Hiệp Mỹ Tây",
      "quanhuyen":"81711"
   },
   {
      "id":"8258703",
      "phuongxa":"Bình Thắng",
      "quanhuyen":"82588"
   },
   {
      "id":"8258767",
      "phuongxa":" Xã Xuân Tây",
      "quanhuyen":"71318"
   },
   {
      "id":"8258891",
      "phuongxa":"Xã Phước Thạnh",
      "quanhuyen":"80701"
   },
   {
      "id":"8258892",
      "phuongxa":"Xã Láng Dài",
      "quanhuyen":"71714"
   },
   {
      "id":"8258907",
      "phuongxa":"Xã Bảo Lâm",
      "quanhuyen":"70303"
   },
   {
      "id":"8258929",
      "phuongxa":"Thị trấn Đắk Mâm",
      "quanhuyen":"82606"
   },
   {
      "id":"8258933",
      "phuongxa":"Xã  Mỹ Quới",
      "quanhuyen":"82615"
   },
   {
      "id":"8258949",
      "phuongxa":"Sông Thao",
      "quanhuyen":"71302"
   },
   {
      "id":"8259011",
      "phuongxa":"Xã Tịnh Biên",
      "quanhuyen":"80513"
   },
   {
      "id":"8258777",
      "phuongxa":"Xã Long Hòa",
      "quanhuyen":"80509"
   },
   {
      "id":"8258778",
      "phuongxa":"Xã Hòa Chánh",
      "quanhuyen":"81327"
   },
   {
      "id":"8258807",
      "phuongxa":"Xã Việt Khái",
      "quanhuyen":"82302"
   },
   {
      "id":"8258835",
      "phuongxa":"Xã An Thới",
      "quanhuyen":"81102"
   },
   {
      "id":"8258836",
      "phuongxa":"Phường Rạch Dừa",
      "quanhuyen":"71701"
   },
   {
      "id":"8258837",
      "phuongxa":"Thị Trấn Phước Long",
      "quanhuyen":"82102"
   },
   {
      "id":"8258850",
      "phuongxa":"Xã Vĩnh Bình",
      "quanhuyen":"82108"
   },
   {
      "id":"8258856",
      "phuongxa":"Phường Thắng Nhất",
      "quanhuyen":"71701"
   },
   {
      "id":"8258857",
      "phuongxa":"Xã Phú Tâm",
      "quanhuyen":"81917"
   },
   {
      "id":"8258858",
      "phuongxa":"Phường Long Phước",
      "quanhuyen":"82600"
   },
   {
      "id":"8258865",
      "phuongxa":"Xã Phú Đông",
      "quanhuyen":"80718"
   },
   {
      "id":"8258911",
      "phuongxa":"Tân Quan",
      "quanhuyen":"82590"
   },
   {
      "id":"8258951",
      "phuongxa":"An Cư",
      "quanhuyen":"80713"
   },
   {
      "id":"8258957",
      "phuongxa":"Xã Đại Phúc",
      "quanhuyen":"81703"
   },
   {
      "id":"8258997",
      "phuongxa":"Xã Hiệp Lợi",
      "quanhuyen":"82502"
   },
   {
      "id":"8258998",
      "phuongxa":"TT Lạc Dương",
      "quanhuyen":"70305"
   },
   {
      "id":"8258999",
      "phuongxa":"Xã Hưng Hòa",
      "quanhuyen":"82592"
   },
   {
      "id":"8259000",
      "phuongxa":"Lâm Sang",
      "quanhuyen":"71313"
   },
   {
      "id":"8259027",
      "phuongxa":"Phường Xuân Trung",
      "quanhuyen":"71311"
   },
   {
      "id":"81515LB",
      "phuongxa":"Lê Bình",
      "quanhuyen":"81515"
   },
   {
      "id":"8258797",
      "phuongxa":"Phường An Bình",
      "quanhuyen":"81301"
   },
   {
      "id":"8258809",
      "phuongxa":"Cẩm Sơn",
      "quanhuyen":"81102"
   },
   {
      "id":"8258819",
      "phuongxa":"Phường Ninh Sơn",
      "quanhuyen":"82601"
   },
   {
      "id":"8258820",
      "phuongxa":"Phú Tài",
      "quanhuyen":"71501"
   },
   {
      "id":"8258843",
      "phuongxa":"Thị trấn Cù Lao Dung",
      "quanhuyen":"81918"
   },
   {
      "id":"8258852",
      "phuongxa":"Xã Thạnh Hòa",
      "quanhuyen":"82584"
   },
   {
      "id":"8258943",
      "phuongxa":"Xã Vị Tân",
      "quanhuyen":"82583"
   },
   {
      "id":"8258955",
      "phuongxa":"Tân Thành",
      "quanhuyen":"81307"
   },
   {
      "id":"8259016",
      "phuongxa":"Phường Thới Bình",
      "quanhuyen":"81520"
   },
   {
      "id":"5033388",
      "phuongxa":"Thị trấn Phú Thịnh ",
      "quanhuyen":"50326"
   },
   {
      "id":"7139986",
      "phuongxa":"Xuân Dường",
      "quanhuyen":"71318"
   },
   {
      "id":"8258733",
      "phuongxa":"Xã Sơn Bình",
      "quanhuyen":"71705"
   },
   {
      "id":"8258734",
      "phuongxa":"Xã An Thạnh Đông",
      "quanhuyen":"81918"
   },
   {
      "id":"8258735",
      "phuongxa":"Xã An Thạnh Tây",
      "quanhuyen":"81918"
   },
   {
      "id":"8258805",
      "phuongxa":"Xã An Định",
      "quanhuyen":"81903"
   },
   {
      "id":"8258941",
      "phuongxa":"Bình Trung",
      "quanhuyen":"71705"
   },
   {
      "id":"8258993",
      "phuongxa":"Xã Tân Lộc Bắc",
      "quanhuyen":"82303"
   },
   {
      "id":"8258715",
      "phuongxa":"Phường 8",
      "quanhuyen":"70125"
   },
   {
      "id":"8258716",
      "phuongxa":"Trường Chinh ",
      "quanhuyen":"82605"
   },
   {
      "id":"6030722",
      "phuongxa":"Đak Ya",
      "quanhuyen":"60305"
   },
   {
      "id":"8217711",
      "phuongxa":"Vĩnh Thạnh",
      "quanhuyen":"82102"
   },
   {
      "id":"7017701",
      "phuongxa":"Phường 6",
      "quanhuyen":"70125"
   },
   {
      "id":"8258720",
      "phuongxa":"Phường Tân Hưng Thuận",
      "quanhuyen":"70123"
   },
   {
      "id":"8258758",
      "phuongxa":"Mỹ Chánh Tây",
      "quanhuyen":"50709"
   },
   {
      "id":"8258784",
      "phuongxa":"Hương Nộn",
      "quanhuyen":"21713"
   },
   {
      "id":"8258796",
      "phuongxa":"Ngãi Đăng",
      "quanhuyen":"81102"
   },
   {
      "id":"8258808",
      "phuongxa":"Hiệp An",
      "quanhuyen":"71101"
   },
   {
      "id":"8258821",
      "phuongxa":"Thị trấn Kiên Lương",
      "quanhuyen":"81326"
   },
   {
      "id":"60509CK",
      "phuongxa":"Chư KBô",
      "quanhuyen":"60509"
   },
   {
      "id":"8258921",
      "phuongxa":"Bình Minh",
      "quanhuyen":"20501"
   },
   {
      "id":"8259001",
      "phuongxa":"Định Môn",
      "quanhuyen":"81516"
   },
   {
      "id":"8259005",
      "phuongxa":"Phường An Hòa",
      "quanhuyen":"71301"
   },
   {
      "id":"8259026",
      "phuongxa":"Xã Trung Nhứt",
      "quanhuyen":"81503"
   },
   {
      "id":"8258707",
      "phuongxa":"Phường 9",
      "quanhuyen":"70125"
   },
   {
      "id":"8258728",
      "phuongxa":"Thị xã Hương Xuân",
      "quanhuyen":"41107"
   },
   {
      "id":"8258752",
      "phuongxa":"Tân Phú",
      "quanhuyen":"82586"
   },
   {
      "id":"8258753",
      "phuongxa":"Xã Minh Diệu",
      "quanhuyen":"82108"
   },
   {
      "id":"8258754",
      "phuongxa":"An Ninh",
      "quanhuyen":"81917"
   },
   {
      "id":"8258782",
      "phuongxa":"Khánh B Đông",
      "quanhuyen":"82307"
   },
   {
      "id":"8258812",
      "phuongxa":"Hồ Đắc Kiện",
      "quanhuyen":"81917"
   },
   {
      "id":"8258813",
      "phuongxa":"Bình Phú",
      "quanhuyen":"80713"
   },
   {
      "id":"8258832",
      "phuongxa":"Xã Phước An",
      "quanhuyen":"82590"
   },
   {
      "id":"8258849",
      "phuongxa":"Phường Thắng Tam",
      "quanhuyen":"71701"
   },
   {
      "id":"8258880",
      "phuongxa":"Không xác định",
      "quanhuyen":"82602"
   },
   {
      "id":"8258881",
      "phuongxa":"Phường Long Châu",
      "quanhuyen":"82602"
   },
   {
      "id":"8258882",
      "phuongxa":"Phường Long Hưng",
      "quanhuyen":"82602"
   },
   {
      "id":"8258883",
      "phuongxa":"Thị trấn Long Bình",
      "quanhuyen":"80505"
   },
   {
      "id":"8258884",
      "phuongxa":"Phường Kim Dinh",
      "quanhuyen":"71703"
   },
   {
      "id":"8258886",
      "phuongxa":"Phường Thắng Nhì",
      "quanhuyen":"71701"
   },
   {
      "id":"8258887",
      "phuongxa":"Phường Nguyễn An Ninh",
      "quanhuyen":"71701"
   },
   {
      "id":"8258888",
      "phuongxa":"Không xác định",
      "quanhuyen":"82182"
   },
   {
      "id":"8258889",
      "phuongxa":"Không xác định",
      "quanhuyen":"82604"
   },
   {
      "id":"8258890",
      "phuongxa":"Không xác định",
      "quanhuyen":"82603"
   },
   {
      "id":"8258920",
      "phuongxa":"Đắk Ru",
      "quanhuyen":"60777"
   },
   {
      "id":"8258926",
      "phuongxa":"Phường Hưng Lợi",
      "quanhuyen":"81520"
   },
   {
      "id":"8258930",
      "phuongxa":"Xã Thuận An",
      "quanhuyen":"82597"
   },
   {
      "id":"8258966",
      "phuongxa":"Tịnh Khê",
      "quanhuyen":"50501"
   },
   {
      "id":"8258976",
      "phuongxa":"Xã Khánh Lộc",
      "quanhuyen":"82307"
   },
   {
      "id":"8258988",
      "phuongxa":"Đăk ơ",
      "quanhuyen":"82588"
   },
   {
      "id":"8259034",
      "phuongxa":"Lộc Sơn",
      "quanhuyen":"41113"
   },
   {
      "id":"8258722",
      "phuongxa":"Xã An Hải",
      "quanhuyen":"50503"
   },
   {
      "id":"8258773",
      "phuongxa":"Xã Long Hưng",
      "quanhuyen":"80507"
   },
   {
      "id":"8258786",
      "phuongxa":"Phường 1",
      "quanhuyen":"80709"
   },
   {
      "id":"8258825",
      "phuongxa":"Xã Xuân Thạnh",
      "quanhuyen":"71309"
   },
   {
      "id":"8258826",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"40513"
   },
   {
      "id":"8258827",
      "phuongxa":"Xã An Thạnh 3",
      "quanhuyen":"81918"
   },
   {
      "id":"8258829",
      "phuongxa":"Thị Trấn Vân Canh",
      "quanhuyen":"50721"
   },
   {
      "id":"8258840",
      "phuongxa":"Xã Kiến Đức",
      "quanhuyen":"60777"
   },
   {
      "id":"8258876",
      "phuongxa":"Không xác định",
      "quanhuyen":"82595"
   },
   {
      "id":"8258877",
      "phuongxa":"Xã Vĩnh Châu",
      "quanhuyen":"80503"
   },
   {
      "id":"8258878",
      "phuongxa":"Phường Vĩnh Mỹ",
      "quanhuyen":"82595"
   },
   {
      "id":"8258879",
      "phuongxa":"Phường Vĩnh Ngươn",
      "quanhuyen":"82595"
   },
   {
      "id":"8258905",
      "phuongxa":"Phường An Phú",
      "quanhuyen":"60311"
   },
   {
      "id":"8258910",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"71302"
   },
   {
      "id":"8258942",
      "phuongxa":"Xã Thới Sơn",
      "quanhuyen":"80701"
   },
   {
      "id":"8258981",
      "phuongxa":"Phường Yên Thế",
      "quanhuyen":"60301"
   },
   {
      "id":"8259023",
      "phuongxa":"Phường 1",
      "quanhuyen":"82611"
   },
   {
      "id":"7014588",
      "phuongxa":"Tây Thạnh",
      "quanhuyen":"70145"
   },
   {
      "id":"7014587",
      "phuongxa":"Tây Thạnh",
      "quanhuyen":"70145"
   },
   {
      "id":"7014586",
      "phuongxa":"Hắc Dịch",
      "quanhuyen":"71703"
   },
   {
      "id":"8258742",
      "phuongxa":"Phường Tân Thiện",
      "quanhuyen":"70704"
   },
   {
      "id":"8258750",
      "phuongxa":"Phường Long Tâm",
      "quanhuyen":"71703"
   },
   {
      "id":"8258751",
      "phuongxa":"Vĩnh Phú Đông",
      "quanhuyen":"82102"
   },
   {
      "id":"8258755",
      "phuongxa":"Phường Đống Đa",
      "quanhuyen":"60301"
   },
   {
      "id":"8258768",
      "phuongxa":"Xã Nhị Hà",
      "quanhuyen":"82593"
   },
   {
      "id":"8258770",
      "phuongxa":"Xã Vị Bình",
      "quanhuyen":"82594"
   },
   {
      "id":"8258771",
      "phuongxa":"Xã Phước Vinh",
      "quanhuyen":"70507"
   },
   {
      "id":"8258893",
      "phuongxa":"Thị Trấn Bút Sơn",
      "quanhuyen":"40143"
   },
   {
      "id":"8258912",
      "phuongxa":"Phường Long Thạnh",
      "quanhuyen":"82602"
   },
   {
      "id":"8259017",
      "phuongxa":"Xã Ngã Bảy",
      "quanhuyen":"82502"
   },
   {
      "id":"8259018",
      "phuongxa":"Phường Hưng Chiến",
      "quanhuyen":"82619"
   },
   {
      "id":"8258730",
      "phuongxa":"Bàu Trinh",
      "quanhuyen":"71705"
   },
   {
      "id":"8258731",
      "phuongxa":"An Hội",
      "quanhuyen":"81520"
   },
   {
      "id":"8258766",
      "phuongxa":"Phường Thới Hòa",
      "quanhuyen":"81505"
   },
   {
      "id":"8258772",
      "phuongxa":"Vị Đông",
      "quanhuyen":"82585"
   },
   {
      "id":"8258774",
      "phuongxa":"Xã Phú Thạnh",
      "quanhuyen":"50917"
   },
   {
      "id":"8258848",
      "phuongxa":"Xã Xuân Thắng",
      "quanhuyen":"81516"
   },
   {
      "id":"8259006",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"70704"
   },
   {
      "id":"8259020",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"81107"
   },
   {
      "id":"8259041",
      "phuongxa":"Phường Tân Xuân",
      "quanhuyen":"70704"
   },
   {
      "id":"8258710",
      "phuongxa":"Vĩnh Trạch Đông",
      "quanhuyen":"82101"
   },
   {
      "id":"8258726",
      "phuongxa":"Đài Sơn",
      "quanhuyen":"70501"
   },
   {
      "id":"8258785",
      "phuongxa":"Đông Hà",
      "quanhuyen":"71515"
   },
   {
      "id":"8258844",
      "phuongxa":"Xã An Viễn",
      "quanhuyen":"71302"
   },
   {
      "id":"8258853",
      "phuongxa":"Xã Đông Phú",
      "quanhuyen":"82582"
   },
   {
      "id":"8258854",
      "phuongxa":"Long Điền Đông A",
      "quanhuyen":"82182"
   },
   {
      "id":"8258961",
      "phuongxa":"Xã Ia Le",
      "quanhuyen":"82610"
   },
   {
      "id":"8258979",
      "phuongxa":"Tân Mỹ",
      "quanhuyen":"81113"
   },
   {
      "id":"8259009",
      "phuongxa":"Tân Thành",
      "quanhuyen":"82502"
   },
   {
      "id":"8258746",
      "phuongxa":"Phường Cam Linh",
      "quanhuyen":"51109"
   },
   {
      "id":"8258801",
      "phuongxa":"Ea Ngai",
      "quanhuyen":"60509"
   },
   {
      "id":"8258830",
      "phuongxa":"Phường 1",
      "quanhuyen":"82598"
   },
   {
      "id":"8258845",
      "phuongxa":"Xã An Thạnh",
      "quanhuyen":"81102"
   },
   {
      "id":"8258919",
      "phuongxa":"Xã Thạch Hưng",
      "quanhuyen":"40501"
   },
   {
      "id":"8258944",
      "phuongxa":"Xã Vị Tân",
      "quanhuyen":"82608"
   },
   {
      "id":"8258968",
      "phuongxa":"Đại Ân 2",
      "quanhuyen":"82599"
   },
   {
      "id":"8258984",
      "phuongxa":"Phường Tân Bình",
      "quanhuyen":"70704"
   },
   {
      "id":"8258985",
      "phuongxa":"Lộc An",
      "quanhuyen":"41113"
   },
   {
      "id":"8258723",
      "phuongxa":"Trảng Bom",
      "quanhuyen":"71302"
   },
   {
      "id":"8258756",
      "phuongxa":"Xã Phước Long Thọ",
      "quanhuyen":"71714"
   },
   {
      "id":"8258757",
      "phuongxa":"Vinh Giang",
      "quanhuyen":"41113"
   },
   {
      "id":"8258776",
      "phuongxa":"Phường Xuân An",
      "quanhuyen":"71311"
   },
   {
      "id":"8258802",
      "phuongxa":"Xã Hiếu Liêm",
      "quanhuyen":"71307"
   },
   {
      "id":"8258803",
      "phuongxa":"Đôn Châu",
      "quanhuyen":"81715"
   },
   {
      "id":"8258866",
      "phuongxa":"Phường Hưng Thạnh",
      "quanhuyen":"81515"
   },
   {
      "id":"8258868",
      "phuongxa":"Không xác định",
      "quanhuyen":"81514"
   },
   {
      "id":"8258869",
      "phuongxa":"Phường An Lạc",
      "quanhuyen":"81520"
   },
   {
      "id":"8258870",
      "phuongxa":"Thị trấn Phong Điền ",
      "quanhuyen":"81517"
   },
   {
      "id":"8258898",
      "phuongxa":"Xã Hương Trà",
      "quanhuyen":"40517"
   },
   {
      "id":"8258899",
      "phuongxa":"Thị trấn Rạch Gòi",
      "quanhuyen":"82587"
   },
   {
      "id":"8258900",
      "phuongxa":"Minh Thuận",
      "quanhuyen":"81327"
   },
   {
      "id":"8258901",
      "phuongxa":"Xã Tân Trung",
      "quanhuyen":"82311"
   },
   {
      "id":"8258913",
      "phuongxa":"Đông Phước",
      "quanhuyen":"82582"
   },
   {
      "id":"8258925",
      "phuongxa":"Xã Phước Tỉnh",
      "quanhuyen":"71712"
   },
   {
      "id":"8258928",
      "phuongxa":"Phường An Lạc A",
      "quanhuyen":"70147"
   },
   {
      "id":"8258939",
      "phuongxa":"Xã Mã Đà",
      "quanhuyen":"71307"
   },
   {
      "id":"8258940",
      "phuongxa":"Thạch Lam",
      "quanhuyen":"81918"
   },
   {
      "id":"8258962",
      "phuongxa":"Thánh Hải",
      "quanhuyen":"82307"
   },
   {
      "id":"8259029",
      "phuongxa":"Phường 2",
      "quanhuyen":"82615"
   },
   {
      "id":"8114400",
      "phuongxa":"Phước Hiệp",
      "quanhuyen":"81102"
   },
   {
      "id":"8258831",
      "phuongxa":"Phường 1",
      "quanhuyen":"82101"
   },
   {
      "id":"8258839",
      "phuongxa":"Xã Tân An Tây",
      "quanhuyen":"82313"
   },
   {
      "id":"8258842",
      "phuongxa":"Xã Sông Ray",
      "quanhuyen":"71318"
   },
   {
      "id":"8258978",
      "phuongxa":"Bình Khánh Đông",
      "quanhuyen":"81102"
   },
   {
      "id":"8258990",
      "phuongxa":"Thị trấn Cát Tiên",
      "quanhuyen":"70321"
   },
   {
      "id":"8259004",
      "phuongxa":"Phương Phú",
      "quanhuyen":"82584"
   },
   {
      "id":"8259019",
      "phuongxa":"Tân Sơn",
      "quanhuyen":"81713"
   },
   {
      "id":"8259042",
      "phuongxa":"Ia kring",
      "quanhuyen":"60301"
   },
   {
      "id":"8258714",
      "phuongxa":"Bình Hiệp",
      "quanhuyen":"82589"
   },
   {
      "id":"8258816",
      "phuongxa":"Phường An Hòa",
      "quanhuyen":"41101"
   },
   {
      "id":"8258833",
      "phuongxa":"Xã Hiệp Hưng",
      "quanhuyen":"82584"
   },
   {
      "id":"8258875",
      "phuongxa":"Xã Nhân Nghĩa",
      "quanhuyen":"71318"
   },
   {
      "id":"8258987",
      "phuongxa":"Hòa Hiệp Bắc",
      "quanhuyen":"50919"
   },
   {
      "id":"8258965",
      "phuongxa":"Nthôl Hạ",
      "quanhuyen":"70309"
   },
   {
      "id":"8258971",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"82615"
   },
   {
      "id":"7070955",
      "phuongxa":"An Phú",
      "quanhuyen":"82590"
   },
   {
      "id":"8258738",
      "phuongxa":"Xã Thanh Bình",
      "quanhuyen":"70915"
   },
   {
      "id":"8258739",
      "phuongxa":"Phường Mỹ Phú",
      "quanhuyen":"80301"
   },
   {
      "id":"8258740",
      "phuongxa":"Thị Trấn Mỏ Cày",
      "quanhuyen":"81102"
   },
   {
      "id":"8258743",
      "phuongxa":"Xã Phước Lý",
      "quanhuyen":"70143"
   },
   {
      "id":"8258794",
      "phuongxa":"Phường Phú Lợi",
      "quanhuyen":"71101"
   },
   {
      "id":"8258817",
      "phuongxa":"TT Hưng Hòa ",
      "quanhuyen":"21713"
   },
   {
      "id":"8258818",
      "phuongxa":"TT Trà Lồng ",
      "quanhuyen":"82586"
   },
   {
      "id":"8258838",
      "phuongxa":"TT Lịch Hội Thượng",
      "quanhuyen":"82599"
   },
   {
      "id":"8258846",
      "phuongxa":"Xã Thành Thới B",
      "quanhuyen":"81102"
   },
   {
      "id":"8258918",
      "phuongxa":"Xã Tài Văn",
      "quanhuyen":"82599"
   },
   {
      "id":"8259010",
      "phuongxa":"Hưng Bình",
      "quanhuyen":"60777"
   },
   {
      "id":"8259036",
      "phuongxa":"Xã Long Điền Tây",
      "quanhuyen":"82182"
   },
   {
      "id":"8259038",
      "phuongxa":"Xã Tiến Hưng",
      "quanhuyen":"70704"
   },
   {
      "id":"8258705",
      "phuongxa":"Xã An Thạnh 1",
      "quanhuyen":"81918"
   },
   {
      "id":"8258721",
      "phuongxa":"Thị trấn Tân Khai",
      "quanhuyen":"82590"
   },
   {
      "id":"8258725",
      "phuongxa":"Phường 1",
      "quanhuyen":"82591"
   },
   {
      "id":"8258737",
      "phuongxa":"Phường 4",
      "quanhuyen":"82583"
   },
   {
      "id":"8258793",
      "phuongxa":"Đạ Ròn",
      "quanhuyen":"70307"
   },
   {
      "id":"8258798",
      "phuongxa":"Xã Thanh Hải",
      "quanhuyen":"70505"
   },
   {
      "id":"8258799",
      "phuongxa":"Xã Trung An",
      "quanhuyen":"81514"
   },
   {
      "id":"8258800",
      "phuongxa":"Xã Tân Thạnh",
      "quanhuyen":"80507"
   },
   {
      "id":"8258851",
      "phuongxa":"Phường 12",
      "quanhuyen":"71701"
   },
   {
      "id":"8258894",
      "phuongxa":"Không xác định",
      "quanhuyen":"82605"
   },
   {
      "id":"8258895",
      "phuongxa":"Phường Trần Hưng Đạo",
      "quanhuyen":"82605"
   },
   {
      "id":"8258953",
      "phuongxa":"Vĩnh Hòa Phú",
      "quanhuyen":"81309"
   },
   {
      "id":"8258972",
      "phuongxa":"Vĩnh Thuận Đông",
      "quanhuyen":"82585"
   },
   {
      "id":"8259040",
      "phuongxa":"Nhơn Hòa",
      "quanhuyen":"82610"
   },
   {
      "id":"8259043",
      "phuongxa":"Xã Ngự Bình",
      "quanhuyen":"41101"
   },
   {
      "id":"8259125",
      "phuongxa":"Xã Mỹ An",
      "quanhuyen":"80517"
   },
   {
      "id":"8259141",
      "phuongxa":"Xã Bình Thành",
      "quanhuyen":"82584"
   },
   {
      "id":"8259154",
      "phuongxa":"Xã Trạm Hành",
      "quanhuyen":"70301"
   },
   {
      "id":"8259155",
      "phuongxa":"Phường Phù Đổng",
      "quanhuyen":"60301"
   },
   {
      "id":"8259261",
      "phuongxa":"Vĩnh Thắng",
      "quanhuyen":"81313"
   },
   {
      "id":"8259285",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"82629"
   },
   {
      "id":"8259343",
      "phuongxa":"Xã Thanh Đức",
      "quanhuyen":"40331"
   },
   {
      "id":"8259344",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"70707"
   },
   {
      "id":"8259045",
      "phuongxa":"Xã Thuận Hiệp",
      "quanhuyen":"82614"
   },
   {
      "id":"8259160",
      "phuongxa":"Thị trấn Thạnh An",
      "quanhuyen":"81519"
   },
   {
      "id":"8259201",
      "phuongxa":"An Long",
      "quanhuyen":"71108"
   },
   {
      "id":"8259207",
      "phuongxa":"Phường 9",
      "quanhuyen":"50911"
   },
   {
      "id":"8259208",
      "phuongxa":"Xã Vĩnh Thành",
      "quanhuyen":"81911"
   },
   {
      "id":"8259238",
      "phuongxa":"Xã Tam Phước ",
      "quanhuyen":"71712"
   },
   {
      "id":"8259216",
      "phuongxa":"Xã Thới An Đông",
      "quanhuyen":"82613"
   },
   {
      "id":"8259239",
      "phuongxa":"Xã Long Tân",
      "quanhuyen":"71714"
   },
   {
      "id":"8259240",
      "phuongxa":"Xã Lộc An",
      "quanhuyen":"71714"
   },
   {
      "id":"8259253",
      "phuongxa":"Xã Viên Bình",
      "quanhuyen":"82599"
   },
   {
      "id":"8259262",
      "phuongxa":"Xã An Trạch",
      "quanhuyen":"82182"
   },
   {
      "id":"8259268",
      "phuongxa":"Xã Bình An",
      "quanhuyen":"81326"
   },
   {
      "id":"8259282",
      "phuongxa":"Phường Ngọc Hà",
      "quanhuyen":"20101"
   },
   {
      "id":"8259342",
      "phuongxa":"long bình tân",
      "quanhuyen":"71301"
   },
   {
      "id":"8259349",
      "phuongxa":"Cây Dương",
      "quanhuyen":"82584"
   },
   {
      "id":"8259052",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"81917"
   },
   {
      "id":"8259090",
      "phuongxa":"phú long",
      "quanhuyen":"71507"
   },
   {
      "id":"8259102",
      "phuongxa":"Xã Lạc Nghiệp",
      "quanhuyen":"70307"
   },
   {
      "id":"8259149",
      "phuongxa":"Xã Phong Điền",
      "quanhuyen":"82307"
   },
   {
      "id":"8259191",
      "phuongxa":"Phước Vĩnh",
      "quanhuyen":"71108"
   },
   {
      "id":"8259251",
      "phuongxa":"Xã Cư Knia",
      "quanhuyen":"60521"
   },
   {
      "id":"8259259",
      "phuongxa":"Xã Thới Đông",
      "quanhuyen":"81514"
   },
   {
      "id":"8259283",
      "phuongxa":"Xã Tây Sơn",
      "quanhuyen":"60301"
   },
   {
      "id":"8259287",
      "phuongxa":"Thị trấn Nàng Mau",
      "quanhuyen":"82594"
   },
   {
      "id":"8259289",
      "phuongxa":"Xã Ngọc Chánh",
      "quanhuyen":"82311"
   },
   {
      "id":"8259321",
      "phuongxa":"Xã Na Mèo",
      "quanhuyen":"40111"
   },
   {
      "id":"8259056",
      "phuongxa":"Thạnh Thắng",
      "quanhuyen":"81519"
   },
   {
      "id":"8259057",
      "phuongxa":"Thạnh Quới",
      "quanhuyen":"81519"
   },
   {
      "id":"8259094",
      "phuongxa":"Mỹ Lệ",
      "quanhuyen":"80125"
   },
   {
      "id":"8259213",
      "phuongxa":"Xã Ngã Năm",
      "quanhuyen":"81915"
   },
   {
      "id":"8259229",
      "phuongxa":"Phường Tân Phú",
      "quanhuyen":"81515"
   },
   {
      "id":"8259245",
      "phuongxa":"Phường 2",
      "quanhuyen":"82598"
   },
   {
      "id":"8259246",
      "phuongxa":"Phường 3",
      "quanhuyen":"80107"
   },
   {
      "id":"8259247",
      "phuongxa":"Xã Tân Thành ",
      "quanhuyen":"82301"
   },
   {
      "id":"8259248",
      "phuongxa":"Phường 1 ",
      "quanhuyen":"82583"
   },
   {
      "id":"8259249",
      "phuongxa":"Phường 3",
      "quanhuyen":"82583"
   },
   {
      "id":"8259337",
      "phuongxa":"Phường Nhị Châu",
      "quanhuyen":"10701"
   },
   {
      "id":"8259047",
      "phuongxa":"Xã Phước Minh",
      "quanhuyen":"82588"
   },
   {
      "id":"8259062",
      "phuongxa":"Phường Hòa Long",
      "quanhuyen":"71703"
   },
   {
      "id":"8259096",
      "phuongxa":"Xã Bình Đông",
      "quanhuyen":"80703"
   },
   {
      "id":"8259205",
      "phuongxa":"Xã Vĩnh Trung",
      "quanhuyen":"82585"
   },
   {
      "id":"8259275",
      "phuongxa":"Xã Tạ An Khương Nam",
      "quanhuyen":"82311"
   },
   {
      "id":"8259350",
      "phuongxa":"Xã Cư Prong",
      "quanhuyen":"60515"
   },
   {
      "id":"8259366",
      "phuongxa":"Xã Vị Thủy",
      "quanhuyen":"82585"
   },
   {
      "id":"8259065",
      "phuongxa":"Xuân Mỹ",
      "quanhuyen":"71318"
   },
   {
      "id":"8259074",
      "phuongxa":"Việt Thắng",
      "quanhuyen":"82302"
   },
   {
      "id":"8259075",
      "phuongxa":"Hưng Lợi",
      "quanhuyen":"81911"
   },
   {
      "id":"8259076",
      "phuongxa":"Vĩnh Tân",
      "quanhuyen":"71503"
   },
   {
      "id":"8259116",
      "phuongxa":"Phường Hòa Phú",
      "quanhuyen":"71101"
   },
   {
      "id":"8259288",
      "phuongxa":"Xã Tân Dân",
      "quanhuyen":"82311"
   },
   {
      "id":"8259303",
      "phuongxa":"Xã Long Điền",
      "quanhuyen":"71712"
   },
   {
      "id":"8259322",
      "phuongxa":"IA YOK",
      "quanhuyen":"60309"
   },
   {
      "id":"8259367",
      "phuongxa":"Xã Thạnh Quý",
      "quanhuyen":"71107"
   },
   {
      "id":"8259046",
      "phuongxa":"trung thạch",
      "quanhuyen":"81514"
   },
   {
      "id":"8259079",
      "phuongxa":"Phường Đại Ân 2",
      "quanhuyen":"82599"
   },
   {
      "id":"8259108",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"81319"
   },
   {
      "id":"8259135",
      "phuongxa":"Xã Quỳnh Vinh",
      "quanhuyen":"82618"
   },
   {
      "id":"8259185",
      "phuongxa":"Xã Thới Xuân",
      "quanhuyen":"81514"
   },
   {
      "id":"8259190",
      "phuongxa":"Xã Long Hưng",
      "quanhuyen":"82623"
   },
   {
      "id":"8259297",
      "phuongxa":"Phường 2",
      "quanhuyen":"81715"
   },
   {
      "id":"8259302",
      "phuongxa":"Hiệp Mỹ Đông",
      "quanhuyen":"81711"
   },
   {
      "id":"8259374",
      "phuongxa":"Thị trấn Một Ngàn",
      "quanhuyen":"82587"
   },
   {
      "id":"8259392",
      "phuongxa":"Xã Xuân Chính",
      "quanhuyen":"11715"
   },
   {
      "id":"8259393",
      "phuongxa":"Xã Ia Drăng",
      "quanhuyen":"60317"
   },
   {
      "id":"8259109",
      "phuongxa":"Xã Trung Hòa",
      "quanhuyen":"71302"
   },
   {
      "id":"8259121",
      "phuongxa":"Phường An Hòa",
      "quanhuyen":"80303"
   },
   {
      "id":"8259122",
      "phuongxa":"Phường Nghĩa Phú",
      "quanhuyen":"50501"
   },
   {
      "id":"8259146",
      "phuongxa":"Phường 4",
      "quanhuyen":"82601"
   },
   {
      "id":"8259177",
      "phuongxa":"Xã Nâm N'Đir",
      "quanhuyen":"82606"
   },
   {
      "id":"8259264",
      "phuongxa":"Phường Thống Nhất",
      "quanhuyen":"11301"
   },
   {
      "id":"8259270",
      "phuongxa":"Xã Tam Cường",
      "quanhuyen":"21713"
   },
   {
      "id":"8259271",
      "phuongxa":"Xã Trường Long A",
      "quanhuyen":"82587"
   },
   {
      "id":"8259272",
      "phuongxa":"Xã Thắng Hải",
      "quanhuyen":"71513"
   },
   {
      "id":"8259333",
      "phuongxa":"Xã Phong Thạnh A",
      "quanhuyen":"82107"
   },
   {
      "id":"8259355",
      "phuongxa":"Thanh Nê",
      "quanhuyen":"11513"
   },
   {
      "id":"8259053",
      "phuongxa":"Phong Thạnh Đông A",
      "quanhuyen":"82604"
   },
   {
      "id":"8259071",
      "phuongxa":"Xã Đa Phước Hội",
      "quanhuyen":"82614"
   },
   {
      "id":"8259097",
      "phuongxa":"Xã Thừa Đức",
      "quanhuyen":"71318"
   },
   {
      "id":"8259153",
      "phuongxa":"Xã Phong Đông",
      "quanhuyen":"81319"
   },
   {
      "id":"8259179",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"82592"
   },
   {
      "id":"8259267",
      "phuongxa":"Xã Mỹ Bình",
      "quanhuyen":"82615"
   },
   {
      "id":"8259382",
      "phuongxa":"xã Iarong",
      "quanhuyen":"82638"
   },
   {
      "id":"8259395",
      "phuongxa":"Xã Đăk_R'La",
      "quanhuyen":"82597"
   },
   {
      "id":"8259061",
      "phuongxa":"Thị trấn Búng Tàu",
      "quanhuyen":"82584"
   },
   {
      "id":"8259136",
      "phuongxa":"Thạnh Yên",
      "quanhuyen":"81327"
   },
   {
      "id":"8259215",
      "phuongxa":"Xã Trường Xuân A",
      "quanhuyen":"81516"
   },
   {
      "id":"8259380",
      "phuongxa":"Tân Hội",
      "quanhuyen":"81102"
   },
   {
      "id":"8259127",
      "phuongxa":"Xã Quãng Phú",
      "quanhuyen":"82606"
   },
   {
      "id":"8259211",
      "phuongxa":"Xã Tân Long",
      "quanhuyen":"71108"
   },
   {
      "id":"8259231",
      "phuongxa":"Tân Tây",
      "quanhuyen":"80717"
   },
   {
      "id":"8259316",
      "phuongxa":"Xã Đại Hưng",
      "quanhuyen":"50307"
   },
   {
      "id":"8259326",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"82590"
   },
   {
      "id":"8259104",
      "phuongxa":"Phường Xuân Khánh",
      "quanhuyen":"81520"
   },
   {
      "id":"8259162",
      "phuongxa":"Vĩnh Viễn",
      "quanhuyen":"82586"
   },
   {
      "id":"8259224",
      "phuongxa":"Tân Lợi",
      "quanhuyen":"82590"
   },
   {
      "id":"8259258",
      "phuongxa":"Xã Phước Sang",
      "quanhuyen":"71108"
   },
   {
      "id":"8259277",
      "phuongxa":"Xã Xuân Quế",
      "quanhuyen":"71318"
   },
   {
      "id":"8259307",
      "phuongxa":"Xã Vĩnh Trạch",
      "quanhuyen":"82101"
   },
   {
      "id":"8259308",
      "phuongxa":"Xã Phước Tân ",
      "quanhuyen":"82623"
   },
   {
      "id":"8259309",
      "phuongxa":"Xã Bình Sơn ",
      "quanhuyen":"82623"
   },
   {
      "id":"8259310",
      "phuongxa":"Phường Phú Đô",
      "quanhuyen":"10121"
   },
   {
      "id":"8259311",
      "phuongxa":"Xã Đất Cuốc",
      "quanhuyen":"71105"
   },
   {
      "id":"8259312",
      "phuongxa":"Xã Hiếu Liêm",
      "quanhuyen":"71105"
   },
   {
      "id":"8259313",
      "phuongxa":"Xã Dương Hòa",
      "quanhuyen":"81326"
   },
   {
      "id":"8259314",
      "phuongxa":"Xã Hòa Điền",
      "quanhuyen":"81326"
   },
   {
      "id":"8259315",
      "phuongxa":"Xã Hòn Thơm",
      "quanhuyen":"81321"
   },
   {
      "id":"8259329",
      "phuongxa":"Phường 1",
      "quanhuyen":"82107"
   },
   {
      "id":"8259353",
      "phuongxa":"Đồng Thạnh",
      "quanhuyen":"80715"
   },
   {
      "id":"8259051",
      "phuongxa":"An Tân",
      "quanhuyen":"60311"
   },
   {
      "id":"8259086",
      "phuongxa":"Tân An",
      "quanhuyen":"81307"
   },
   {
      "id":"8259092",
      "phuongxa":"Xã Tân Khánh Hòa",
      "quanhuyen":"82616"
   },
   {
      "id":"8259137",
      "phuongxa":"Xã Ninh Quới A",
      "quanhuyen":"82103"
   },
   {
      "id":"8259260",
      "phuongxa":"Xã Tam Giang Đông",
      "quanhuyen":"82314"
   },
   {
      "id":"8259286",
      "phuongxa":"Phường Choe Reo",
      "quanhuyen":"60321"
   },
   {
      "id":"8259332",
      "phuongxa":"Tà Lùng",
      "quanhuyen":"20323"
   },
   {
      "id":"8259359",
      "phuongxa":"Nghĩa Bình",
      "quanhuyen":"70707"
   },
   {
      "id":"8259365",
      "phuongxa":"Tân An",
      "quanhuyen":"60311"
   },
   {
      "id":"8259243",
      "phuongxa":"Xã Thạnh Hội",
      "quanhuyen":"82627"
   },
   {
      "id":"8259266",
      "phuongxa":"Xã Hòn Nghệ",
      "quanhuyen":"81326"
   },
   {
      "id":"8259278",
      "phuongxa":"Phường Y Wang",
      "quanhuyen":"60501"
   },
   {
      "id":"8259336",
      "phuongxa":"Phường Bình Trị Đông",
      "quanhuyen":"70147"
   },
   {
      "id":"8259352",
      "phuongxa":"Phường 7",
      "quanhuyen":"80101"
   },
   {
      "id":"8259383",
      "phuongxa":"Phường Láng Tròn",
      "quanhuyen":"82107"
   },
   {
      "id":"8259098",
      "phuongxa":"Xã Vĩnh Bửu",
      "quanhuyen":"80103"
   },
   {
      "id":"8259105",
      "phuongxa":"Xuân Thiện",
      "quanhuyen":"71309"
   },
   {
      "id":"8259106",
      "phuongxa":"Nghĩa Đàng",
      "quanhuyen":"40313"
   },
   {
      "id":"8259193",
      "phuongxa":"Phường Phú Thịnh",
      "quanhuyen":"82619"
   },
   {
      "id":"8259217",
      "phuongxa":"Phú hữu A",
      "quanhuyen":"82582"
   },
   {
      "id":"8259317",
      "phuongxa":"Xã Vĩnh Thanh",
      "quanhuyen":"82102"
   },
   {
      "id":"8259054",
      "phuongxa":"Thạnh Hòa",
      "quanhuyen":"81503"
   },
   {
      "id":"8259055",
      "phuongxa":"vinh an",
      "quanhuyen":"41101"
   },
   {
      "id":"8259095",
      "phuongxa":"Đắk Mil",
      "quanhuyen":"82597"
   },
   {
      "id":"8259192",
      "phuongxa":"Xã ÊAPUK",
      "quanhuyen":"60507"
   },
   {
      "id":"8259388",
      "phuongxa":"Xã Trí Lực",
      "quanhuyen":"82303"
   },
   {
      "id":"8259212",
      "phuongxa":"Phường Thuận An",
      "quanhuyen":"81503"
   },
   {
      "id":"8259341",
      "phuongxa":"Xã Hiệp An",
      "quanhuyen":"70309"
   },
   {
      "id":"8259138",
      "phuongxa":"Phường Phú Tân",
      "quanhuyen":"71101"
   },
   {
      "id":"8259220",
      "phuongxa":"Xã Chư Pư",
      "quanhuyen":"82624"
   },
   {
      "id":"8259230",
      "phuongxa":"Phường Cái Khế",
      "quanhuyen":"81520"
   },
   {
      "id":"8259280",
      "phuongxa":"Phường Tân An",
      "quanhuyen":"81520"
   },
   {
      "id":"8259356",
      "phuongxa":"Xã Phước Ninh",
      "quanhuyen":"82593"
   },
   {
      "id":"8259068",
      "phuongxa":"khánh hội",
      "quanhuyen":"82305"
   },
   {
      "id":"8259164",
      "phuongxa":"Phường 3",
      "quanhuyen":"40903"
   },
   {
      "id":"8259175",
      "phuongxa":"Xã Hưng Phú",
      "quanhuyen":"82102"
   },
   {
      "id":"8259210",
      "phuongxa":"Xã Phước Hậu",
      "quanhuyen":"70501"
   },
   {
      "id":"8259293",
      "phuongxa":"Xã Khanh An",
      "quanhuyen":"82590"
   },
   {
      "id":"8259305",
      "phuongxa":"Xã Đông Phước A",
      "quanhuyen":"82582"
   },
   {
      "id":"8259323",
      "phuongxa":"Xã Liêu Tú",
      "quanhuyen":"82599"
   },
   {
      "id":"8259348",
      "phuongxa":"Thạnh Thới Thuận",
      "quanhuyen":"82599"
   },
   {
      "id":"8259368",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"82627"
   },
   {
      "id":"8259381",
      "phuongxa":"Xã Thạnh Hưng",
      "quanhuyen":"80703"
   },
   {
      "id":"8259391",
      "phuongxa":"Tân Kim",
      "quanhuyen":"80127"
   },
   {
      "id":"8259112",
      "phuongxa":"Xã Phú Mỹ",
      "quanhuyen":"82616"
   },
   {
      "id":"8259130",
      "phuongxa":"A Lưới",
      "quanhuyen":"41115"
   },
   {
      "id":"8259172",
      "phuongxa":"Tân Lập",
      "quanhuyen":"82621"
   },
   {
      "id":"8259174",
      "phuongxa":"vĩnh trinh",
      "quanhuyen":"81519"
   },
   {
      "id":"8259351",
      "phuongxa":"Xã Tân Đức",
      "quanhuyen":"71513"
   },
   {
      "id":"8259085",
      "phuongxa":"Vị Thắng",
      "quanhuyen":"82585"
   },
   {
      "id":"8259103",
      "phuongxa":"Xã Ninh Thạnh Lợi A",
      "quanhuyen":"82103"
   },
   {
      "id":"8259133",
      "phuongxa":"Xã Bình Xuân",
      "quanhuyen":"80715"
   },
   {
      "id":"8259158",
      "phuongxa":"Xã Cù Bị",
      "quanhuyen":"71705"
   },
   {
      "id":"8259166",
      "phuongxa":"Xã Phú Nghĩa",
      "quanhuyen":"82620"
   },
   {
      "id":"8259324",
      "phuongxa":"Xã Tân Trung",
      "quanhuyen":"80717"
   },
   {
      "id":"8259325",
      "phuongxa":"Thị Trấn Phú Thứ",
      "quanhuyen":"50917"
   },
   {
      "id":"8259339",
      "phuongxa":"Mỹ Đông",
      "quanhuyen":"70501"
   },
   {
      "id":"8259340",
      "phuongxa":"Nhuân Phú Tân",
      "quanhuyen":"82614"
   },
   {
      "id":"8259354",
      "phuongxa":"Xã Phước Mỹ",
      "quanhuyen":"71317"
   },
   {
      "id":"8259363",
      "phuongxa":"An Thạnh 1",
      "quanhuyen":"81918"
   },
   {
      "id":"8259384",
      "phuongxa":"Xã Thạnh Phú",
      "quanhuyen":"82309"
   },
   {
      "id":"8259385",
      "phuongxa":"Xã Bàn Thạch",
      "quanhuyen":"81311"
   },
   {
      "id":"8259118",
      "phuongxa":"Ninh Hiệp",
      "quanhuyen":"51105"
   },
   {
      "id":"8259187",
      "phuongxa":"Xã Đắk Sin",
      "quanhuyen":"60777"
   },
   {
      "id":"8259221",
      "phuongxa":"Phường Cam Phú",
      "quanhuyen":"51109"
   },
   {
      "id":"8259244",
      "phuongxa":"Phường 9",
      "quanhuyen":"80701"
   },
   {
      "id":"8259273",
      "phuongxa":"TT Cai Lậy",
      "quanhuyen":"82625"
   },
   {
      "id":"8259059",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"82583"
   },
   {
      "id":"8259069",
      "phuongxa":"Xà Phiên",
      "quanhuyen":"82586"
   },
   {
      "id":"8259115",
      "phuongxa":"Lương Sơn",
      "quanhuyen":"70503"
   },
   {
      "id":"8259152",
      "phuongxa":"Xã Hưng Thuận",
      "quanhuyen":"70917"
   },
   {
      "id":"8259159",
      "phuongxa":"Xã Quách Phẩm Bắc",
      "quanhuyen":"82311"
   },
   {
      "id":"8259161",
      "phuongxa":"Xã Bình Xuân",
      "quanhuyen":"80703"
   },
   {
      "id":"8259194",
      "phuongxa":"Mỹ Thái",
      "quanhuyen":"81305"
   },
   {
      "id":"8259209",
      "phuongxa":"Xã Lộc Phú",
      "quanhuyen":"70705"
   },
   {
      "id":"8259291",
      "phuongxa":"Xã Tiến Phương",
      "quanhuyen":"10133"
   },
   {
      "id":"8259296",
      "phuongxa":"Xã Vạn Hạnh",
      "quanhuyen":"71709"
   },
   {
      "id":"8259379",
      "phuongxa":"Xã Đắk Wil",
      "quanhuyen":"60521"
   },
   {
      "id":"8259394",
      "phuongxa":"Thiện Nghiệp",
      "quanhuyen":"71501"
   },
   {
      "id":"8259060",
      "phuongxa":"vĩnh bình",
      "quanhuyen":"81519"
   },
   {
      "id":"8259080",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"80718"
   },
   {
      "id":"8259083",
      "phuongxa":"Đôn Xuân",
      "quanhuyen":"81715"
   },
   {
      "id":"8259093",
      "phuongxa":"Thị Trấn Kinh Cùng",
      "quanhuyen":"82584"
   },
   {
      "id":"8259143",
      "phuongxa":"Nâm Nung",
      "quanhuyen":"82606"
   },
   {
      "id":"8259195",
      "phuongxa":"Truông Mít",
      "quanhuyen":"70907"
   },
   {
      "id":"8259222",
      "phuongxa":"Xã Cư San",
      "quanhuyen":"60517"
   },
   {
      "id":"8259078",
      "phuongxa":"Đại Thành",
      "quanhuyen":"82502"
   },
   {
      "id":"8259084",
      "phuongxa":"Hòa Mỹ Tây",
      "quanhuyen":"50917"
   },
   {
      "id":"8259129",
      "phuongxa":"Xã Thới Thạnh",
      "quanhuyen":"81514"
   },
   {
      "id":"8259178",
      "phuongxa":"Xã Mỹ Phước",
      "quanhuyen":"81305"
   },
   {
      "id":"8259228",
      "phuongxa":"Xã Trường Long Tây",
      "quanhuyen":"82587"
   },
   {
      "id":"8259242",
      "phuongxa":"Xã Vĩnh Hậu A",
      "quanhuyen":"82108"
   },
   {
      "id":"8259252",
      "phuongxa":"Xã Tân Lợi",
      "quanhuyen":"82625"
   },
   {
      "id":"8259292",
      "phuongxa":"Xã Tân Hoà",
      "quanhuyen":"82629"
   },
   {
      "id":"8259345",
      "phuongxa":"Xã Nghĩa Thắng",
      "quanhuyen":"82634"
   },
   {
      "id":"8259376",
      "phuongxa":"Xã Thạnh Phú",
      "quanhuyen":"81514"
   },
   {
      "id":"8259072",
      "phuongxa":"Phường Ninh Hiệp",
      "quanhuyen":"51105"
   },
   {
      "id":"8259073",
      "phuongxa":"Xã Vĩnh Thuận Đông",
      "quanhuyen":"82586"
   },
   {
      "id":"8259088",
      "phuongxa":"Thiện Chí",
      "quanhuyen":"80713"
   },
   {
      "id":"8259089",
      "phuongxa":"Quãng Tín",
      "quanhuyen":"60777"
   },
   {
      "id":"8259120",
      "phuongxa":"Xã Tây Hòa",
      "quanhuyen":"71302"
   },
   {
      "id":"8259123",
      "phuongxa":"Thị trấn Nghèn",
      "quanhuyen":"40511"
   },
   {
      "id":"8259134",
      "phuongxa":"Lộc Thạnh",
      "quanhuyen":"70705"
   },
   {
      "id":"8259202",
      "phuongxa":"long tâm",
      "quanhuyen":"71703"
   },
   {
      "id":"8259219",
      "phuongxa":"Xã Bàu Hàm",
      "quanhuyen":"71302"
   },
   {
      "id":"8259232",
      "phuongxa":"Phường Tân An ",
      "quanhuyen":"50303"
   },
   {
      "id":"8259233",
      "phuongxa":"Phường Cửa Đại",
      "quanhuyen":"50303"
   },
   {
      "id":"8259234",
      "phuongxa":"Phường Thanh Hà",
      "quanhuyen":"50303"
   },
   {
      "id":"8259235",
      "phuongxa":"Xã Tam Đại",
      "quanhuyen":"50326"
   },
   {
      "id":"8259274",
      "phuongxa":"Quãng Tiến",
      "quanhuyen":"71302"
   },
   {
      "id":"8259298",
      "phuongxa":"Đức Hạnh",
      "quanhuyen":"82588"
   },
   {
      "id":"8259347",
      "phuongxa":"Thị trấn Cửa Việt",
      "quanhuyen":"40907"
   },
   {
      "id":"8259101",
      "phuongxa":"Xã Kiên Bình",
      "quanhuyen":"81326"
   },
   {
      "id":"8259124",
      "phuongxa":"Xã Ân Hảo Tây",
      "quanhuyen":"50707"
   },
   {
      "id":"8259360",
      "phuongxa":"Cai Lậy",
      "quanhuyen":"82625"
   },
   {
      "id":"8259375",
      "phuongxa":"Đồng Hòa",
      "quanhuyen":"70143"
   },
   {
      "id":"8259117",
      "phuongxa":"Xã Mỹ Hội",
      "quanhuyen":"80713"
   },
   {
      "id":"8259131",
      "phuongxa":"Xã Sông Phan",
      "quanhuyen":"71513"
   },
   {
      "id":"8259167",
      "phuongxa":"Phường An Cư",
      "quanhuyen":"81520"
   },
   {
      "id":"8259188",
      "phuongxa":"Xã Xuân Bảo",
      "quanhuyen":"71318"
   },
   {
      "id":"8259255",
      "phuongxa":"Xã Thạnh Yên A",
      "quanhuyen":"81315"
   },
   {
      "id":"8259295",
      "phuongxa":"Phường Nguyễn Trãi",
      "quanhuyen":"60101"
   },
   {
      "id":"8259334",
      "phuongxa":"Ia-Phang",
      "quanhuyen":"82610"
   },
   {
      "id":"8259066",
      "phuongxa":"Phường Phú Tân",
      "quanhuyen":"81101"
   },
   {
      "id":"8259144",
      "phuongxa":"Xã Phụng Hiệp",
      "quanhuyen":"82584"
   },
   {
      "id":"8259150",
      "phuongxa":"Phường An Binh",
      "quanhuyen":"60311"
   },
   {
      "id":"8259151",
      "phuongxa":"Xã Phước Hội",
      "quanhuyen":"71714"
   },
   {
      "id":"8259269",
      "phuongxa":"Xã Tân Hải",
      "quanhuyen":"82629"
   },
   {
      "id":"8259279",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"71108"
   },
   {
      "id":"8259300",
      "phuongxa":"Bình Trị",
      "quanhuyen":"81326"
   },
   {
      "id":"8259327",
      "phuongxa":"Xã Cốc Lếu",
      "quanhuyen":"20501"
   },
   {
      "id":"8259364",
      "phuongxa":"Xã Phú Tân",
      "quanhuyen":"82582"
   },
   {
      "id":"8259048",
      "phuongxa":"Xã Liên Hà",
      "quanhuyen":"70311"
   },
   {
      "id":"8259049",
      "phuongxa":"Phường Tân Phước",
      "quanhuyen":"71501"
   },
   {
      "id":"8259050",
      "phuongxa":"Xã Phước Hưng",
      "quanhuyen":"71712"
   },
   {
      "id":"8259070",
      "phuongxa":"Hòa Khánh",
      "quanhuyen":"60501"
   },
   {
      "id":"8259139",
      "phuongxa":"Phường Phú Đức",
      "quanhuyen":"82619"
   },
   {
      "id":"8259225",
      "phuongxa":"Tân Hiệp",
      "quanhuyen":"71108"
   },
   {
      "id":"8259281",
      "phuongxa":"Xã Định Thành",
      "quanhuyen":"82182"
   },
   {
      "id":"8259299",
      "phuongxa":"Xã Việt Thắng",
      "quanhuyen":"82302"
   },
   {
      "id":"8259328",
      "phuongxa":"Xã Tân Hưng Tây",
      "quanhuyen":"82302"
   },
   {
      "id":"8259063",
      "phuongxa":"Phương Bình",
      "quanhuyen":"82584"
   },
   {
      "id":"8259110",
      "phuongxa":"Xã Trường Thắng",
      "quanhuyen":"81516"
   },
   {
      "id":"8259113",
      "phuongxa":"Xã Bù Nho",
      "quanhuyen":"82623"
   },
   {
      "id":"8259114",
      "phuongxa":"Xã Nam Du",
      "quanhuyen":"81323"
   },
   {
      "id":"8259197",
      "phuongxa":"Xã Bảo Bình",
      "quanhuyen":"71318"
   },
   {
      "id":"8259250",
      "phuongxa":"Phường Bạch Đằng Đông",
      "quanhuyen":"50105"
   },
   {
      "id":"8259257",
      "phuongxa":"Xã Phước Cát 1",
      "quanhuyen":"70321"
   },
   {
      "id":"8259099",
      "phuongxa":"Phường 3",
      "quanhuyen":"82615"
   },
   {
      "id":"8259126",
      "phuongxa":"Xã Vĩnh Ab",
      "quanhuyen":"71108"
   },
   {
      "id":"8259132",
      "phuongxa":"Xã Trung Thạnh",
      "quanhuyen":"81514"
   },
   {
      "id":"8259140",
      "phuongxa":"Xã Đức Tín",
      "quanhuyen":"71515"
   },
   {
      "id":"8259181",
      "phuongxa":"Xã Hỏa Lựu",
      "quanhuyen":"82583"
   },
   {
      "id":"8259198",
      "phuongxa":"Phường Thanh Khê Đông",
      "quanhuyen":"50103"
   },
   {
      "id":"8259199",
      "phuongxa":"Phường Bùi Thị Xuân",
      "quanhuyen":"81520"
   },
   {
      "id":"8259200",
      "phuongxa":"Xã Long Hà",
      "quanhuyen":"82623"
   },
   {
      "id":"8259265",
      "phuongxa":"Hòa Khánh Nam",
      "quanhuyen":"50109"
   },
   {
      "id":"8259346",
      "phuongxa":"Phường Liễu Giai",
      "quanhuyen":"10101"
   },
   {
      "id":"8259371",
      "phuongxa":"Xã Lóng Luông",
      "quanhuyen":"82636"
   },
   {
      "id":"8259372",
      "phuongxa":"Phường Bắc Cường",
      "quanhuyen":"20501"
   },
   {
      "id":"8259058",
      "phuongxa":"Phường 5",
      "quanhuyen":"80703"
   },
   {
      "id":"8259064",
      "phuongxa":"Xã Thạnh Tân",
      "quanhuyen":"70901"
   },
   {
      "id":"8259087",
      "phuongxa":"Xã Đăk Ta Ley",
      "quanhuyen":"60305"
   },
   {
      "id":"8259119",
      "phuongxa":"Phường Tân Phước",
      "quanhuyen":"71709"
   },
   {
      "id":"8259196",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"80521"
   },
   {
      "id":"8259204",
      "phuongxa":"Đăk Sắc",
      "quanhuyen":"82597"
   },
   {
      "id":"8259206",
      "phuongxa":"Xã Đông Hưng",
      "quanhuyen":"82309"
   },
   {
      "id":"8259218",
      "phuongxa":"Phường 8",
      "quanhuyen":"50911"
   },
   {
      "id":"8259226",
      "phuongxa":"Phường Long Thủy",
      "quanhuyen":"82600"
   },
   {
      "id":"8259227",
      "phuongxa":"Xã Khánh Thuận",
      "quanhuyen":"82305"
   },
   {
      "id":"8259306",
      "phuongxa":"Thị Xã Phú Mỹ",
      "quanhuyen":"82632"
   },
   {
      "id":"8259369",
      "phuongxa":"Xã Minh Tâm",
      "quanhuyen":"82590"
   },
   {
      "id":"8259147",
      "phuongxa":"Xã Bờ Kinh",
      "quanhuyen":"81701"
   },
   {
      "id":"8259148",
      "phuongxa":"Phường Tân Phú",
      "quanhuyen":"70704"
   },
   {
      "id":"8259284",
      "phuongxa":"Đắk Drô",
      "quanhuyen":"82606"
   },
   {
      "id":"8259319",
      "phuongxa":"Xã Hòa Mỹ",
      "quanhuyen":"82309"
   },
   {
      "id":"8259338",
      "phuongxa":"Ia Hla",
      "quanhuyen":"82610"
   },
   {
      "id":"8259370",
      "phuongxa":"Xã Bình Định Nam",
      "quanhuyen":"50315"
   },
   {
      "id":"8259077",
      "phuongxa":"Phường Ninh Thạnh",
      "quanhuyen":"82601"
   },
   {
      "id":"8259091",
      "phuongxa":"Xã Vĩnh Phước",
      "quanhuyen":"80515"
   },
   {
      "id":"8259100",
      "phuongxa":"Xã Viĩnh Phước A",
      "quanhuyen":"81313"
   },
   {
      "id":"8259157",
      "phuongxa":"Xã Đại Lào",
      "quanhuyen":"70303"
   },
   {
      "id":"8259168",
      "phuongxa":"Phường 4",
      "quanhuyen":"80709"
   },
   {
      "id":"8259170",
      "phuongxa":"Xã Xuân Trường",
      "quanhuyen":"21718"
   },
   {
      "id":"8259301",
      "phuongxa":"Xã Bình Hòa Trung",
      "quanhuyen":"82631"
   },
   {
      "id":"8259304",
      "phuongxa":"Xã Tân Hiệp",
      "quanhuyen":"82590"
   },
   {
      "id":"8259318",
      "phuongxa":"Xã Ngọc Thành ",
      "quanhuyen":"81311"
   },
   {
      "id":"8259330",
      "phuongxa":"Xã Long Điền Đông",
      "quanhuyen":"82182"
   },
   {
      "id":"8259335",
      "phuongxa":"Quãng Cát",
      "quanhuyen":"40101"
   },
   {
      "id":"8259357",
      "phuongxa":"Xã Vĩnh Hưng A",
      "quanhuyen":"82105"
   },
   {
      "id":"8259358",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"70309"
   },
   {
      "id":"8259361",
      "phuongxa":"Xã An Phú",
      "quanhuyen":"82619"
   },
   {
      "id":"8259389",
      "phuongxa":"Xã Bình Dã",
      "quanhuyen":"71705"
   },
   {
      "id":"8259390",
      "phuongxa":"Xã An Khương Đông",
      "quanhuyen":"82311"
   },
   {
      "id":"8259082",
      "phuongxa":"Phường 1",
      "quanhuyen":"82615"
   },
   {
      "id":"8259128",
      "phuongxa":"Xã Đắk Đoa",
      "quanhuyen":"60325"
   },
   {
      "id":"8259145",
      "phuongxa":"Tân Hương",
      "quanhuyen":"40319"
   },
   {
      "id":"8259163",
      "phuongxa":"Xã Đông Bình",
      "quanhuyen":"81516"
   },
   {
      "id":"8259169",
      "phuongxa":"Xã Thạnh Mỹ",
      "quanhuyen":"81519"
   },
   {
      "id":"8259236",
      "phuongxa":"Xã Phú Lợi ",
      "quanhuyen":"82616"
   },
   {
      "id":"8259237",
      "phuongxa":"Xã Vĩnh Điều ",
      "quanhuyen":"82616"
   },
   {
      "id":"8259276",
      "phuongxa":"Xã Y Wan",
      "quanhuyen":"82630"
   },
   {
      "id":"8259373",
      "phuongxa":"An Hòa",
      "quanhuyen":"80303"
   },
   {
      "id":"8259387",
      "phuongxa":"Xã Núi Voi",
      "quanhuyen":"80513"
   },
   {
      "id":"8259396",
      "phuongxa":"Xã Giang Hải",
      "quanhuyen":"41113"
   },
   {
      "id":"8259111",
      "phuongxa":"Xã Thạnh Tiến",
      "quanhuyen":"81519"
   },
   {
      "id":"8259165",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"82590"
   },
   {
      "id":"8259362",
      "phuongxa":"Xã Quỳnh Trang",
      "quanhuyen":"82635"
   },
   {
      "id":"8259377",
      "phuongxa":"Thị trấn Lăng Cô",
      "quanhuyen":"82637"
   },
   {
      "id":"8259378",
      "phuongxa":"Xã Hương Mỹ",
      "quanhuyen":"82614"
   },
   {
      "id":"8259413",
      "phuongxa":"Phường Lê Hồng Phong",
      "quanhuyen":"11101"
   },
   {
      "id":"8259416",
      "phuongxa":"Xã Ia RVê",
      "quanhuyen":"60505"
   },
   {
      "id":"8259439",
      "phuongxa":"Xã Phước Ngãi",
      "quanhuyen":"81113"
   },
   {
      "id":"8259447",
      "phuongxa":"Phước bình",
      "quanhuyen":"70917"
   },
   {
      "id":"1171515",
      "phuongxa":"Xã Ân Hòa",
      "quanhuyen":"11715"
   },
   {
      "id":"1171553",
      "phuongxa":"Xã Kim Đông",
      "quanhuyen":"11715"
   },
   {
      "id":"2010315",
      "phuongxa":"Xã Xà Phìn",
      "quanhuyen":"20103"
   },
   {
      "id":"2010517",
      "phuongxa":"Xã Cán Chu Phìn",
      "quanhuyen":"20105"
   },
   {
      "id":"2010913",
      "phuongxa":"Xã Tùng Vài",
      "quanhuyen":"20109"
   },
   {
      "id":"2011125",
      "phuongxa":"Xã Phiêng Luông",
      "quanhuyen":"20111"
   },
   {
      "id":"2011345",
      "phuongxa":"Xã Thông Nguyên",
      "quanhuyen":"20113"
   },
   {
      "id":"2011701",
      "phuongxa":"Xã Cốc Pài",
      "quanhuyen":"20117"
   },
   {
      "id":"2011739",
      "phuongxa":"Xã Tân Nam",
      "quanhuyen":"20117"
   },
   {
      "id":"2011937",
      "phuongxa":"Xã Bằng Lang",
      "quanhuyen":"20119"
   },
   {
      "id":"2012117",
      "phuongxa":"Xã Bằng Lang",
      "quanhuyen":"20121"
   },
   {
      "id":"2050529",
      "phuongxa":"Xã Tả Thàng",
      "quanhuyen":"20505"
   },
   {
      "id":"2050735",
      "phuongxa":"Xã Pa Cheo",
      "quanhuyen":"20507"
   },
   {
      "id":"2050927",
      "phuongxa":"Xã Cán Cấu",
      "quanhuyen":"20509"
   },
   {
      "id":"2090911",
      "phuongxa":"Xã Chiến Thắng",
      "quanhuyen":"20909"
   },
   {
      "id":"2091115",
      "phuongxa":"Xã Vĩnh Lại",
      "quanhuyen":"20911"
   },
   {
      "id":"2091307",
      "phuongxa":"Xã Thạch Đạn",
      "quanhuyen":"20913"
   },
   {
      "id":"2091515",
      "phuongxa":"Xã Như Khuê",
      "quanhuyen":"20915"
   },
   {
      "id":"2091555",
      "phuongxa":"Xã Xuân Dương",
      "quanhuyen":"20915"
   },
   {
      "id":"2091739",
      "phuongxa":"Xã Quan Sơn",
      "quanhuyen":"20917"
   },
   {
      "id":"2092115",
      "phuongxa":"Xã Yên Sơn",
      "quanhuyen":"20921"
   },
   {
      "id":"2051301",
      "phuongxa":"Thị trấn Sa Pa",
      "quanhuyen":"20513"
   },
   {
      "id":"2051509",
      "phuongxa":"Xã Tân Dương",
      "quanhuyen":"20515"
   },
   {
      "id":"2051717",
      "phuongxa":"Xã Mường Than",
      "quanhuyen":"20517"
   },
   {
      "id":"2051927",
      "phuongxa":"Xã Chiềng Ken",
      "quanhuyen":"20519"
   },
   {
      "id":"2070303",
      "phuongxa":"Xã Bằng Thành",
      "quanhuyen":"20703"
   },
   {
      "id":"2070347",
      "phuongxa":"Xã Nam Mẫu",
      "quanhuyen":"20703"
   },
   {
      "id":"2070901",
      "phuongxa":"Thị trấn Yến Lạc",
      "quanhuyen":"20709"
   },
   {
      "id":"2071105",
      "phuongxa":"Xã Phương Linh",
      "quanhuyen":"20711"
   },
   {
      "id":"2071317",
      "phuongxa":"Xã Nông Thịnh",
      "quanhuyen":"20713"
   },
   {
      "id":"2090103",
      "phuongxa":"Phường Tam Thanh",
      "quanhuyen":"20901"
   },
   {
      "id":"2090333",
      "phuongxa":"Xã Hùng Sơn",
      "quanhuyen":"20903"
   },
   {
      "id":"2090527",
      "phuongxa":"Xã Hoàng Việt",
      "quanhuyen":"20905"
   },
   {
      "id":"2090721",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"20907"
   },
   {
      "id":"2031900",
      "phuongxa":"Không xác định",
      "quanhuyen":"20319"
   },
   {
      "id":"2032115",
      "phuongxa":"Xã Vân Trình",
      "quanhuyen":"20321"
   },
   {
      "id":"2032325",
      "phuongxa":"Xã Nam Cao",
      "quanhuyen":"20323"
   },
   {
      "id":"2032525",
      "phuongxa":"Xã Nam Cao",
      "quanhuyen":"20325"
   },
   {
      "id":"2150517",
      "phuongxa":"Xã Phúc Chu",
      "quanhuyen":"21505"
   },
   {
      "id":"2150707",
      "phuongxa":"Xã Thần Xa",
      "quanhuyen":"21507"
   },
   {
      "id":"2150917",
      "phuongxa":"Xã Phủ Lý",
      "quanhuyen":"21509"
   },
   {
      "id":"2151305",
      "phuongxa":"Xã Phúc Lương",
      "quanhuyen":"21513"
   },
   {
      "id":"2151347",
      "phuongxa":"Xã Lục Ba",
      "quanhuyen":"21513"
   },
   {
      "id":"2151525",
      "phuongxa":"Xã Nhã Lộng",
      "quanhuyen":"21515"
   },
   {
      "id":"2110101",
      "phuongxa":"Phường Tân Quang",
      "quanhuyen":"21101"
   },
   {
      "id":"2110333",
      "phuongxa":"Xã Trùng Khánh",
      "quanhuyen":"21103"
   },
   {
      "id":"2110541",
      "phuongxa":"Xã Trung Hòa",
      "quanhuyen":"21105"
   },
   {
      "id":"2110725",
      "phuongxa":"Xã Thái Hòa",
      "quanhuyen":"21107"
   },
   {
      "id":"2110929",
      "phuongxa":"Xã Tứ Quận",
      "quanhuyen":"21109"
   },
   {
      "id":"2110967",
      "phuongxa":"Xã Thái Long",
      "quanhuyen":"21109"
   },
   {
      "id":"2111163",
      "phuongxa":"Xã Tam Đa",
      "quanhuyen":"21111"
   },
   {
      "id":"2130305",
      "phuongxa":"Phường Tân An",
      "quanhuyen":"21303"
   },
   {
      "id":"2130539",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"21305"
   },
   {
      "id":"2130909",
      "phuongxa":"Xã Chế Cu Nha",
      "quanhuyen":"21309"
   },
   {
      "id":"2131123",
      "phuongxa":"Xã Minh Quán",
      "quanhuyen":"21311"
   },
   {
      "id":"2131309",
      "phuongxa":"Xã Tích Cốc",
      "quanhuyen":"21313"
   },
   {
      "id":"2131509",
      "phuongxa":"Xã Nậm Búng",
      "quanhuyen":"21315"
   },
   {
      "id":"2131549",
      "phuongxa":"Xã Suối Bu",
      "quanhuyen":"21315"
   },
   {
      "id":"2131717",
      "phuongxa":"Xã Phình Hồ",
      "quanhuyen":"21317"
   },
   {
      "id":"2150135",
      "phuongxa":"Xã Phúc Hà",
      "quanhuyen":"21501"
   },
   {
      "id":"2150311",
      "phuongxa":"Xã Vĩnh Sơn",
      "quanhuyen":"21503"
   },
   {
      "id":"2170911",
      "phuongxa":"Xã Yển Khê",
      "quanhuyen":"21709"
   },
   {
      "id":"2170949",
      "phuongxa":"Xã Đỗ Xuyên",
      "quanhuyen":"21709"
   },
   {
      "id":"2171151",
      "phuongxa":"Xã Hùng Lô",
      "quanhuyen":"21711"
   },
   {
      "id":"2171301",
      "phuongxa":"Thị trấn Sông Thao",
      "quanhuyen":"21713"
   },
   {
      "id":"2211525",
      "phuongxa":"Xã Chu Điện",
      "quanhuyen":"22115"
   },
   {
      "id":"2211711",
      "phuongxa":"Xã Tự Lạn",
      "quanhuyen":"22117"
   },
   {
      "id":"2211935",
      "phuongxa":"Xã Yên Lư",
      "quanhuyen":"22119"
   },
   {
      "id":"2230301",
      "phuongxa":"Thị trấn Chờ",
      "quanhuyen":"22303"
   },
   {
      "id":"2230503",
      "phuongxa":"Xã Việt Thống",
      "quanhuyen":"22305"
   },
   {
      "id":"2230713",
      "phuongxa":"Xã Khắc Niệm",
      "quanhuyen":"22307"
   },
   {
      "id":"2171317",
      "phuongxa":"Xã Thụy Liễu",
      "quanhuyen":"21713"
   },
   {
      "id":"2171359",
      "phuongxa":"Xã Yên Dưỡng",
      "quanhuyen":"21713"
   },
   {
      "id":"2171707",
      "phuongxa":"Xã Thanh Uyên",
      "quanhuyen":"21717"
   },
   {
      "id":"2171735",
      "phuongxa":"Xã Dậu Dương",
      "quanhuyen":"21717"
   },
   {
      "id":"2171903",
      "phuongxa":"Xã Sơn Hùng",
      "quanhuyen":"21719"
   },
   {
      "id":"2171943",
      "phuongxa":"Xã Vinh Tiền",
      "quanhuyen":"21719"
   },
   {
      "id":"2172109",
      "phuongxa":"Xã Phương Xá",
      "quanhuyen":"21721"
   },
   {
      "id":"2172157",
      "phuongxa":"Xã Cát Trù",
      "quanhuyen":"21721"
   },
   {
      "id":"2210305",
      "phuongxa":"Thị trấn NT Yên Thế",
      "quanhuyen":"22103"
   },
   {
      "id":"2210519",
      "phuongxa":"Xã Liên Sơn",
      "quanhuyen":"22105"
   },
   {
      "id":"2210703",
      "phuongxa":"Xã Cấm Sơn",
      "quanhuyen":"22107"
   },
   {
      "id":"2210743",
      "phuongxa":"Xã Đồng Cốc",
      "quanhuyen":"22107"
   },
   {
      "id":"2210921",
      "phuongxa":"Xã Ngọc Sơn",
      "quanhuyen":"22109"
   },
   {
      "id":"2211111",
      "phuongxa":"Xã Quang Thịnh",
      "quanhuyen":"22111"
   },
   {
      "id":"2211307",
      "phuongxa":"Xã Giáo Liêm",
      "quanhuyen":"22113"
   },
   {
      "id":"2170127",
      "phuongxa":"Xã Minh Nông",
      "quanhuyen":"21701"
   },
   {
      "id":"2170519",
      "phuongxa":"Xã Bằng Doãn",
      "quanhuyen":"21705"
   },
   {
      "id":"2170743",
      "phuongxa":"Xã Minh Hạc",
      "quanhuyen":"21707"
   },
   {
      "id":"3030115",
      "phuongxa":"Xã Chiềng Cơi",
      "quanhuyen":"30301"
   },
   {
      "id":"3030519",
      "phuongxa":"Xã Pi Toong",
      "quanhuyen":"30305"
   },
   {
      "id":"3030721",
      "phuongxa":"Xã Chiềng La",
      "quanhuyen":"30307"
   },
   {
      "id":"3030763",
      "phuongxa":"Xã Co Tòng",
      "quanhuyen":"30307"
   },
   {
      "id":"3031101",
      "phuongxa":"Thị trấn Phù Yên",
      "quanhuyen":"30311"
   },
   {
      "id":"3031300",
      "phuongxa":"Không xác định",
      "quanhuyen":"30313"
   },
   {
      "id":"2230807",
      "phuongxa":"Xã Phù Khê",
      "quanhuyen":"22308"
   },
   {
      "id":"2230921",
      "phuongxa":"Xã Trạm Lộ",
      "quanhuyen":"22309"
   },
   {
      "id":"8259403",
      "phuongxa":"Ia Piơr",
      "quanhuyen":"60317"
   },
   {
      "id":"8259405",
      "phuongxa":"Xã Vĩnh Hoà",
      "quanhuyen":"51101"
   },
   {
      "id":"8259423",
      "phuongxa":"Hưng Yên Nam",
      "quanhuyen":"40337"
   },
   {
      "id":"8259436",
      "phuongxa":"Xã An Hòa Thịnh",
      "quanhuyen":"40509"
   },
   {
      "id":"2231153",
      "phuongxa":"Xã Lâm Thao",
      "quanhuyen":"22311"
   },
   {
      "id":"2250109",
      "phuongxa":"Phường Hà Tu",
      "quanhuyen":"22501"
   },
   {
      "id":"2250313",
      "phuongxa":"Phường Cẩm Tây",
      "quanhuyen":"22503"
   },
   {
      "id":"2250703",
      "phuongxa":"Xã Đồng Văn",
      "quanhuyen":"22507"
   },
   {
      "id":"2251300",
      "phuongxa":"Không xác định",
      "quanhuyen":"22513"
   },
   {
      "id":"2251309",
      "phuongxa":"Xã Điền Xá",
      "quanhuyen":"22513"
   },
   {
      "id":"2251705",
      "phuongxa":"Xã Bình Dân",
      "quanhuyen":"22517"
   },
   {
      "id":"2251919",
      "phuongxa":"Xã Sơn Dương",
      "quanhuyen":"22519"
   },
   {
      "id":"2252135",
      "phuongxa":"Xã Hồng Thái Tây",
      "quanhuyen":"22521"
   },
   {
      "id":"2252515",
      "phuongxa":"Xã Đầm Hà",
      "quanhuyen":"22525"
   },
   {
      "id":"2252731",
      "phuongxa":"Xã Cái Chiên",
      "quanhuyen":"22527"
   },
   {
      "id":"2252937",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"22529"
   },
   {
      "id":"3010513",
      "phuongxa":"Xã Bun Nưa",
      "quanhuyen":"30105"
   },
   {
      "id":"3010535",
      "phuongxa":"Xã Kan Hồ",
      "quanhuyen":"30105"
   },
   {
      "id":"3010735",
      "phuongxa":"Xã Tam Đường",
      "quanhuyen":"30107"
   },
   {
      "id":"3010915",
      "phuongxa":"Xã Phăng Sô Lin",
      "quanhuyen":"30109"
   },
   {
      "id":"3011123",
      "phuongxa":"Xã Hừa Ngài",
      "quanhuyen":"30111"
   },
   {
      "id":"3012125",
      "phuongxa":"Xã Bản Bo",
      "quanhuyen":"30121"
   },
   {
      "id":"3050109",
      "phuongxa":"Phường Tân Hòa",
      "quanhuyen":"30501"
   },
   {
      "id":"3050325",
      "phuongxa":"Xã Hào Lý",
      "quanhuyen":"30503"
   },
   {
      "id":"3050523",
      "phuongxa":"Xã Nà Mèo",
      "quanhuyen":"30505"
   },
   {
      "id":"3050535",
      "phuongxa":"Xã Mai Hịch",
      "quanhuyen":"30505"
   },
   {
      "id":"3050731",
      "phuongxa":"Xã Đông Phong",
      "quanhuyen":"30507"
   },
   {
      "id":"4010913",
      "phuongxa":"Xã Nam Tiến",
      "quanhuyen":"40109"
   },
   {
      "id":"4011301",
      "phuongxa":"Thị trấn Cành Nàng",
      "quanhuyen":"40113"
   },
   {
      "id":"4011325",
      "phuongxa":"Xã Lũng Cao",
      "quanhuyen":"40113"
   },
   {
      "id":"4011517",
      "phuongxa":"Xã Cẩm Bình",
      "quanhuyen":"40115"
   },
   {
      "id":"4011715",
      "phuongxa":"Xã Tam Văn",
      "quanhuyen":"40117"
   },
   {
      "id":"4011933",
      "phuongxa":"Xã Thành Công",
      "quanhuyen":"40119"
   },
   {
      "id":"4012141",
      "phuongxa":"Xã Kiên Thọ",
      "quanhuyen":"40121"
   },
   {
      "id":"3050921",
      "phuongxa":"Xã Nhuận Trạch",
      "quanhuyen":"30509"
   },
   {
      "id":"3051131",
      "phuongxa":"Xã Hợp Châu",
      "quanhuyen":"30511"
   },
   {
      "id":"3051313",
      "phuongxa":"Xã Quy Hậu",
      "quanhuyen":"30513"
   },
   {
      "id":"3051503",
      "phuongxa":"Xã Quý Hòa",
      "quanhuyen":"30515"
   },
   {
      "id":"3051541",
      "phuongxa":"Xã Hương Nhượng",
      "quanhuyen":"30515"
   },
   {
      "id":"3051903",
      "phuongxa":"Xã Lạc Sỹ",
      "quanhuyen":"30519"
   },
   {
      "id":"3052115",
      "phuongxa":"Xã Tây Phong",
      "quanhuyen":"30521"
   },
   {
      "id":"3070509",
      "phuongxa":"Xã Chà Cang",
      "quanhuyen":"30705"
   },
   {
      "id":"3070917",
      "phuongxa":"Xã Xinh Phình",
      "quanhuyen":"30709"
   },
   {
      "id":"3071135",
      "phuongxa":"Xã Ẳng Nưa",
      "quanhuyen":"30711"
   },
   {
      "id":"3071329",
      "phuongxa":"Xã Mường Nhà",
      "quanhuyen":"30713"
   },
   {
      "id":"3071709",
      "phuongxa":"Xã Mường Lạn",
      "quanhuyen":"30717"
   },
   {
      "id":"4010101",
      "phuongxa":"Phường Hàm Rồng",
      "quanhuyen":"40101"
   },
   {
      "id":"4010131",
      "phuongxa":"Xã Quảng Thành",
      "quanhuyen":"40101"
   },
   {
      "id":"3031537",
      "phuongxa":"Xã Dồm Cang",
      "quanhuyen":"30315"
   },
   {
      "id":"3031723",
      "phuongxa":"Xã Lóng Phiêng",
      "quanhuyen":"30317"
   },
   {
      "id":"3031937",
      "phuongxa":"Xã Quang Minh",
      "quanhuyen":"30319"
   },
   {
      "id":"4014941",
      "phuongxa":"Xã Quảng Ngọc",
      "quanhuyen":"40149"
   },
   {
      "id":"4015101",
      "phuongxa":"Thị trấn Nông Cống",
      "quanhuyen":"40151"
   },
   {
      "id":"4015325",
      "phuongxa":"Xã Phú Lâm",
      "quanhuyen":"40153"
   },
   {
      "id":"4015363",
      "phuongxa":"Xã Hải Thượng",
      "quanhuyen":"40153"
   },
   {
      "id":"4030303",
      "phuongxa":"Phường Nghi Thuỷ",
      "quanhuyen":"40303"
   },
   {
      "id":"4030523",
      "phuongxa":"Xã Quang Phong",
      "quanhuyen":"40305"
   },
   {
      "id":"4012305",
      "phuongxa":"Xã Yên Nhân",
      "quanhuyen":"40123"
   },
   {
      "id":"4012503",
      "phuongxa":"Thị trấn Bãi Trành",
      "quanhuyen":"40125"
   },
   {
      "id":"4012915",
      "phuongxa":"Xã Vĩnh Hưng",
      "quanhuyen":"40129"
   },
   {
      "id":"4013123",
      "phuongxa":"Xã Hà Ngọc",
      "quanhuyen":"40131"
   },
   {
      "id":"4013309",
      "phuongxa":"Xã Nga Thiện",
      "quanhuyen":"40133"
   },
   {
      "id":"4013503",
      "phuongxa":"Thị trấn Thống Nhất",
      "quanhuyen":"40135"
   },
   {
      "id":"4013511",
      "phuongxa":"Xã Yên Giang",
      "quanhuyen":"40135"
   },
   {
      "id":"4013553",
      "phuongxa":"Xã Hưng Hùng",
      "quanhuyen":"40135"
   },
   {
      "id":"4013733",
      "phuongxa":"Xã Xuân Quang",
      "quanhuyen":"40137"
   },
   {
      "id":"4013900",
      "phuongxa":"Không xác định",
      "quanhuyen":"40139"
   },
   {
      "id":"4013941",
      "phuongxa":"Xã Phú Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4014123",
      "phuongxa":"Xã Thiệu Hợp",
      "quanhuyen":"40141"
   },
   {
      "id":"4014303",
      "phuongxa":"Xã Hoằng Giang",
      "quanhuyen":"40143"
   },
   {
      "id":"4014381",
      "phuongxa":"Xã Hoằng Tiến",
      "quanhuyen":"40143"
   },
   {
      "id":"4014527",
      "phuongxa":"Xã Đông Văn",
      "quanhuyen":"40145"
   },
   {
      "id":"4014729",
      "phuongxa":"Xã Dân Quyền",
      "quanhuyen":"40147"
   },
   {
      "id":"4014757",
      "phuongxa":"Xã Thọ Tân",
      "quanhuyen":"40147"
   },
   {
      "id":"4014923",
      "phuongxa":"Xã Quảng Hợp",
      "quanhuyen":"40149"
   },
   {
      "id":"4031533",
      "phuongxa":"Xã Yên Na",
      "quanhuyen":"40315"
   },
   {
      "id":"4031731",
      "phuongxa":"Xã Quỳnh Thạch",
      "quanhuyen":"40317"
   },
   {
      "id":"4031761",
      "phuongxa":"Xã Quỳnh Minh",
      "quanhuyen":"40317"
   },
   {
      "id":"4031917",
      "phuongxa":"Xã Nghĩa Hợp",
      "quanhuyen":"40319"
   },
   {
      "id":"4050537",
      "phuongxa":"Xã Cương Gián",
      "quanhuyen":"40505"
   },
   {
      "id":"4050747",
      "phuongxa":"Xã Đức Lập",
      "quanhuyen":"40507"
   },
   {
      "id":"4050907",
      "phuongxa":"Xã Sơn Lĩnh",
      "quanhuyen":"40509"
   },
   {
      "id":"4050947",
      "phuongxa":"Xã Sơn Châu",
      "quanhuyen":"40509"
   },
   {
      "id":"4051119",
      "phuongxa":"Xã Gia Hanh",
      "quanhuyen":"40511"
   },
   {
      "id":"4051300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40513"
   },
   {
      "id":"4051345",
      "phuongxa":"Xã Hộ Độ",
      "quanhuyen":"40513"
   },
   {
      "id":"4031933",
      "phuongxa":"Xã Kỳ Sơn",
      "quanhuyen":"40319"
   },
   {
      "id":"4032125",
      "phuongxa":"Xã Môn Sơn",
      "quanhuyen":"40321"
   },
   {
      "id":"4032341",
      "phuongxa":"Xã Nhân Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032361",
      "phuongxa":"Xã Khánh Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032535",
      "phuongxa":"Xã Diễn Kỷ",
      "quanhuyen":"40325"
   },
   {
      "id":"4032573",
      "phuongxa":"Xã Diễn An",
      "quanhuyen":"40325"
   },
   {
      "id":"4032735",
      "phuongxa":"Xã Long Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032941",
      "phuongxa":"Xã Thái Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4033115",
      "phuongxa":"Xã Thanh Liên",
      "quanhuyen":"40331"
   },
   {
      "id":"4033157",
      "phuongxa":"Xã Thanh Hà",
      "quanhuyen":"40331"
   },
   {
      "id":"4033351",
      "phuongxa":"Xã Nghi Ân",
      "quanhuyen":"40333"
   },
   {
      "id":"4033523",
      "phuongxa":"Xã Nam Thượng",
      "quanhuyen":"40335"
   },
   {
      "id":"4033715",
      "phuongxa":"Xã Hưng Tân",
      "quanhuyen":"40337"
   },
   {
      "id":"4050117",
      "phuongxa":"Xã Đại Nài",
      "quanhuyen":"40501"
   },
   {
      "id":"8259430",
      "phuongxa":"Xã Phước Kế",
      "quanhuyen":"80127"
   },
   {
      "id":"8259437",
      "phuongxa":"Xã Bắc Sơn",
      "quanhuyen":"22509"
   },
   {
      "id":"8259438",
      "phuongxa":"Phường Nam Sơn",
      "quanhuyen":"22301"
   },
   {
      "id":"1011959",
      "phuongxa":"Xã Đông Dư",
      "quanhuyen":"10119"
   },
   {
      "id":"1012100",
      "phuongxa":"Không xác định",
      "quanhuyen":"10121"
   },
   {
      "id":"1012111",
      "phuongxa":"Phường Thượng Cát",
      "quanhuyen":"10121"
   },
   {
      "id":"1012123",
      "phuongxa":"Phường Trung Văn",
      "quanhuyen":"10121"
   },
   {
      "id":"1012700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10127"
   },
   {
      "id":"1012711",
      "phuongxa":"Phường Xuân Khanh",
      "quanhuyen":"10127"
   },
   {
      "id":"1012725",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"10127"
   },
   {
      "id":"1012905",
      "phuongxa":"Xã Phú Cường",
      "quanhuyen":"10129"
   },
   {
      "id":"1012917",
      "phuongxa":"Xã Phú Đông",
      "quanhuyen":"10129"
   },
   {
      "id":"1013319",
      "phuongxa":"Xã Phương Đình",
      "quanhuyen":"10133"
   },
   {
      "id":"1013500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10135"
   },
   {
      "id":"1013511",
      "phuongxa":"Xã Đức Giang",
      "quanhuyen":"10135"
   },
   {
      "id":"1013525",
      "phuongxa":"Xã Lại Yên",
      "quanhuyen":"10135"
   },
   {
      "id":"1010527",
      "phuongxa":"Phường Hàng Bông",
      "quanhuyen":"10105"
   },
   {
      "id":"1010701",
      "phuongxa":"Phường Nguyễn Du",
      "quanhuyen":"10107"
   },
   {
      "id":"1010711",
      "phuongxa":"Phường Phạm Đình Hồ",
      "quanhuyen":"10107"
   },
   {
      "id":"1010725",
      "phuongxa":"Phường Bách Khoa",
      "quanhuyen":"10107"
   },
   {
      "id":"1010735",
      "phuongxa":"Phường Minh Khai",
      "quanhuyen":"10107"
   },
   {
      "id":"1011303",
      "phuongxa":"Phường Nghĩa Tân",
      "quanhuyen":"10113"
   },
   {
      "id":"1011500",
      "phuongxa":"Không xác định",
      "quanhuyen":"10115"
   },
   {
      "id":"1011511",
      "phuongxa":"Xã Hiền Ninh",
      "quanhuyen":"10115"
   },
   {
      "id":"1011525",
      "phuongxa":"Xã Nam Sơn",
      "quanhuyen":"10115"
   },
   {
      "id":"1011537",
      "phuongxa":"Xã Việt Long",
      "quanhuyen":"10115"
   },
   {
      "id":"1014541",
      "phuongxa":"Xã Lê Lợi",
      "quanhuyen":"10145"
   },
   {
      "id":"1014551",
      "phuongxa":"Xã Tô Hiệu",
      "quanhuyen":"10145"
   },
   {
      "id":"1014703",
      "phuongxa":"Thị trấn Phú Xuyên",
      "quanhuyen":"10147"
   },
   {
      "id":"1014717",
      "phuongxa":"Xã Phú Túc",
      "quanhuyen":"10147"
   },
   {
      "id":"1014729",
      "phuongxa":"Xã Nam Triều",
      "quanhuyen":"10147"
   },
   {
      "id":"1033101",
      "phuongxa":"Phường Đa Phúc",
      "quanhuyen":"10331"
   },
   {
      "id":"1040000",
      "phuongxa":"Không xác định",
      "quanhuyen":"10400"
   },
   {
      "id":"1040107",
      "phuongxa":"Phường Ngô Quyền",
      "quanhuyen":"10401"
   },
   {
      "id":"1040300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10403"
   },
   {
      "id":"1040311",
      "phuongxa":"Xã Hợp Lý",
      "quanhuyen":"10403"
   },
   {
      "id":"1040705",
      "phuongxa":"Xã Yên Bình",
      "quanhuyen":"10407"
   },
   {
      "id":"1040719",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"10407"
   },
   {
      "id":"1040731",
      "phuongxa":"Xã Tân Cương",
      "quanhuyen":"10407"
   },
   {
      "id":"1040743",
      "phuongxa":"Xã Vân Xuân",
      "quanhuyen":"10407"
   },
   {
      "id":"1015127",
      "phuongxa":"Xã Đại Hưng",
      "quanhuyen":"10151"
   },
   {
      "id":"1015139",
      "phuongxa":"Xã Hợp Tiến",
      "quanhuyen":"10151"
   },
   {
      "id":"1015307",
      "phuongxa":"Xã Tiến Thắng",
      "quanhuyen":"10153"
   },
   {
      "id":"1015319",
      "phuongxa":"Xã Vạn Yên",
      "quanhuyen":"10153"
   },
   {
      "id":"1030115",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"10301"
   },
   {
      "id":"1030303",
      "phuongxa":"Phường Máy Chai",
      "quanhuyen":"10303"
   },
   {
      "id":"1030313",
      "phuongxa":"Phường Gia Viên",
      "quanhuyen":"10303"
   },
   {
      "id":"1030325",
      "phuongxa":"Phường Đổng Quốc Bình",
      "quanhuyen":"10303"
   },
   {
      "id":"1031151",
      "phuongxa":"Xã Phả Lễ",
      "quanhuyen":"10311"
   },
   {
      "id":"1031163",
      "phuongxa":"Xã Hoàng Động",
      "quanhuyen":"10311"
   },
   {
      "id":"1031501",
      "phuongxa":"Thị trấn An Lão",
      "quanhuyen":"10315"
   },
   {
      "id":"1031513",
      "phuongxa":"Xã Quang Trung",
      "quanhuyen":"10315"
   },
   {
      "id":"1031943",
      "phuongxa":"Xã Tiên Hưng",
      "quanhuyen":"10319"
   },
   {
      "id":"1032107",
      "phuongxa":"Xã Thắng Thuỷ",
      "quanhuyen":"10321"
   },
   {
      "id":"1032119",
      "phuongxa":"Xã Hùng Tiến",
      "quanhuyen":"10321"
   },
   {
      "id":"1032133",
      "phuongxa":"Xã Vinh Quang",
      "quanhuyen":"10321"
   },
   {
      "id":"1014101",
      "phuongxa":"Thị trấn Chúc Sơn",
      "quanhuyen":"10141"
   },
   {
      "id":"1014113",
      "phuongxa":"Xã Phú Nghĩa",
      "quanhuyen":"10141"
   },
   {
      "id":"1014125",
      "phuongxa":"Xã Đại Yên",
      "quanhuyen":"10141"
   },
   {
      "id":"1014135",
      "phuongxa":"Xã Nam Phương Tiến",
      "quanhuyen":"10141"
   },
   {
      "id":"1090739",
      "phuongxa":"Xã Hồng Quang",
      "quanhuyen":"10907"
   },
   {
      "id":"1090907",
      "phuongxa":"Xã Ngũ Lão",
      "quanhuyen":"10909"
   },
   {
      "id":"1090919",
      "phuongxa":"Xã Phú Thịnh",
      "quanhuyen":"10909"
   },
   {
      "id":"1090933",
      "phuongxa":"Xã Hiệp Cường",
      "quanhuyen":"10909"
   },
   {
      "id":"1110000",
      "phuongxa":"Không xác định",
      "quanhuyen":"11100"
   },
   {
      "id":"1110107",
      "phuongxa":"Phường Lương Khánh Thiện",
      "quanhuyen":"11101"
   },
   {
      "id":"1110307",
      "phuongxa":"Xã Duy Hải",
      "quanhuyen":"11103"
   },
   {
      "id":"1110319",
      "phuongxa":"Xã Trác Văn",
      "quanhuyen":"11103"
   },
   {
      "id":"1041501",
      "phuongxa":"Xã Bạch Lưu",
      "quanhuyen":"10415"
   },
   {
      "id":"1041513",
      "phuongxa":"Xã Phương Khoan",
      "quanhuyen":"10415"
   },
   {
      "id":"1041527",
      "phuongxa":"Xã Đồng Thịnh",
      "quanhuyen":"10415"
   },
   {
      "id":"1070101",
      "phuongxa":"Phường Phạm Ngũ Lão",
      "quanhuyen":"10701"
   },
   {
      "id":"1070113",
      "phuongxa":"Phường Cẩm Thượng",
      "quanhuyen":"10701"
   },
   {
      "id":"1070707",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"10707"
   },
   {
      "id":"1070719",
      "phuongxa":"Xã Tiền Tiến",
      "quanhuyen":"10707"
   },
   {
      "id":"1070731",
      "phuongxa":"Xã Thanh Xá",
      "quanhuyen":"10707"
   },
   {
      "id":"1070743",
      "phuongxa":"Xã Thanh Bính",
      "quanhuyen":"10707"
   },
   {
      "id":"1071307",
      "phuongxa":"Xã Thống Nhất",
      "quanhuyen":"10713"
   },
   {
      "id":"1071319",
      "phuongxa":"Xã Toàn Thắng",
      "quanhuyen":"10713"
   },
   {
      "id":"1071333",
      "phuongxa":"Xã Gia Lương",
      "quanhuyen":"10713"
   },
   {
      "id":"1071345",
      "phuongxa":"Xã Nhật Tân",
      "quanhuyen":"10713"
   },
   {
      "id":"1071913",
      "phuongxa":"Xã Long Xuyên",
      "quanhuyen":"10719"
   },
   {
      "id":"1071927",
      "phuongxa":"Xã Bình Minh",
      "quanhuyen":"10719"
   },
   {
      "id":"1072101",
      "phuongxa":"Thị trấn Thanh Miện",
      "quanhuyen":"10721"
   },
   {
      "id":"1072115",
      "phuongxa":"Xã Tứ Cường",
      "quanhuyen":"10721"
   },
   {
      "id":"1090211",
      "phuongxa":"Xã Tân Quang",
      "quanhuyen":"10902"
   },
   {
      "id":"1090300",
      "phuongxa":"Không xác định",
      "quanhuyen":"10903"
   },
   {
      "id":"1090337",
      "phuongxa":"Xã Nhân Hòa",
      "quanhuyen":"10903"
   },
   {
      "id":"1090361",
      "phuongxa":"Xã Ngọc Lâm",
      "quanhuyen":"10903"
   },
   {
      "id":"1090407",
      "phuongxa":"Xã Đồng Than",
      "quanhuyen":"10904"
   },
   {
      "id":"1130311",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"11303"
   },
   {
      "id":"1130323",
      "phuongxa":"Xã Liên Bảo",
      "quanhuyen":"11303"
   },
   {
      "id":"1130335",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"11303"
   },
   {
      "id":"1130511",
      "phuongxa":"Xã Mỹ Thịnh",
      "quanhuyen":"11305"
   },
   {
      "id":"1150979",
      "phuongxa":"Xã Đông Phong",
      "quanhuyen":"11509"
   },
   {
      "id":"1150991",
      "phuongxa":"Xã Đông Huy",
      "quanhuyen":"11509"
   },
   {
      "id":"1151109",
      "phuongxa":"Xã Hiệp Hòa",
      "quanhuyen":"11511"
   },
   {
      "id":"1151121",
      "phuongxa":"Xã Minh Lãng",
      "quanhuyen":"11511"
   },
   {
      "id":"1151133",
      "phuongxa":"Xã Tân Lập",
      "quanhuyen":"11511"
   },
   {
      "id":"1151500",
      "phuongxa":"Không xác định",
      "quanhuyen":"11515"
   },
   {
      "id":"8259429",
      "phuongxa":"Thị Trấn Thạch Hà",
      "quanhuyen":"40513"
   },
   {
      "id":"1151513",
      "phuongxa":"Xã Đông Xuyên",
      "quanhuyen":"11515"
   },
   {
      "id":"1151525",
      "phuongxa":"Xã Tây An",
      "quanhuyen":"11515"
   },
   {
      "id":"1151537",
      "phuongxa":"Xã Đông Lâm",
      "quanhuyen":"11515"
   },
   {
      "id":"1130939",
      "phuongxa":"Xã Nam Hải",
      "quanhuyen":"11309"
   },
   {
      "id":"1131107",
      "phuongxa":"Xã Trực Chính",
      "quanhuyen":"11311"
   },
   {
      "id":"1131121",
      "phuongxa":"Xã Trực Khang",
      "quanhuyen":"11311"
   },
   {
      "id":"1131133",
      "phuongxa":"Xã Trực Đại",
      "quanhuyen":"11311"
   },
   {
      "id":"1131301",
      "phuongxa":"Xã Xuân Thượng",
      "quanhuyen":"11313"
   },
   {
      "id":"1131719",
      "phuongxa":"Xã Nghĩa Sơn",
      "quanhuyen":"11317"
   },
   {
      "id":"1131731",
      "phuongxa":"Xã Nghĩa Tân",
      "quanhuyen":"11317"
   },
   {
      "id":"1131743",
      "phuongxa":"Xã Nghĩa Thắng",
      "quanhuyen":"11317"
   },
   {
      "id":"1131903",
      "phuongxa":"Thị trấn Cồn",
      "quanhuyen":"11319"
   },
   {
      "id":"1150315",
      "phuongxa":"Xã Quỳnh Thọ",
      "quanhuyen":"11503"
   },
   {
      "id":"1150331",
      "phuongxa":"Xã Quỳnh Hội",
      "quanhuyen":"11503"
   },
   {
      "id":"1150343",
      "phuongxa":"Xã Quỳnh Châu",
      "quanhuyen":"11503"
   },
   {
      "id":"1150355",
      "phuongxa":"Xã Đông Hải",
      "quanhuyen":"11503"
   },
   {
      "id":"1150729",
      "phuongxa":"Xã Thụy Liên",
      "quanhuyen":"11507"
   },
   {
      "id":"1150741",
      "phuongxa":"Xã Thụy Hồng",
      "quanhuyen":"11507"
   },
   {
      "id":"1150755",
      "phuongxa":"Xã Thái Giang",
      "quanhuyen":"11507"
   },
   {
      "id":"1150767",
      "phuongxa":"Xã Thái Thuần",
      "quanhuyen":"11507"
   },
   {
      "id":"1110900",
      "phuongxa":"Không xác định",
      "quanhuyen":"11109"
   },
   {
      "id":"1110911",
      "phuongxa":"Xã Liêm Phong",
      "quanhuyen":"11109"
   },
   {
      "id":"1110925",
      "phuongxa":"Xã Thanh Hải",
      "quanhuyen":"11109"
   },
   {
      "id":"1110935",
      "phuongxa":"Xã Thanh Lưu",
      "quanhuyen":"11109"
   },
   {
      "id":"2030707",
      "phuongxa":"Xã Lương Thông",
      "quanhuyen":"20307"
   },
   {
      "id":"2030901",
      "phuongxa":"Xã Hùng Quốc",
      "quanhuyen":"20309"
   },
   {
      "id":"2030913",
      "phuongxa":"Xã Lưu Ngọc",
      "quanhuyen":"20309"
   },
   {
      "id":"2031521",
      "phuongxa":"Xã Công Trừng",
      "quanhuyen":"20315"
   },
   {
      "id":"2031533",
      "phuongxa":"Xã Đề Thám",
      "quanhuyen":"20315"
   },
   {
      "id":"2031545",
      "phuongxa":"Xã Chu Trinh",
      "quanhuyen":"20315"
   },
   {
      "id":"2031705",
      "phuongxa":"Xã Quảng Hưng",
      "quanhuyen":"20317"
   },
   {
      "id":"2031717",
      "phuongxa":"Xã Phúc Sen",
      "quanhuyen":"20317"
   },
   {
      "id":"1170525",
      "phuongxa":"Xã Cúc Phương",
      "quanhuyen":"11705"
   },
   {
      "id":"1170537",
      "phuongxa":"Xã Thanh Lạc",
      "quanhuyen":"11705"
   },
   {
      "id":"1170551",
      "phuongxa":"Xã Quảng Lạc",
      "quanhuyen":"11705"
   },
   {
      "id":"1170707",
      "phuongxa":"Xã Liên Sơn",
      "quanhuyen":"11707"
   },
   {
      "id":"1170719",
      "phuongxa":"Xã Gia Thịnh",
      "quanhuyen":"11707"
   },
   {
      "id":"1171315",
      "phuongxa":"Xã Khánh Cường",
      "quanhuyen":"11713"
   },
   {
      "id":"1171327",
      "phuongxa":"Xã Khánh Ninh",
      "quanhuyen":"11713"
   },
   {
      "id":"1171339",
      "phuongxa":"Xã Khánh Hồng",
      "quanhuyen":"11713"
   },
   {
      "id":"1171509",
      "phuongxa":"Xã Hồi Ninh",
      "quanhuyen":"11715"
   },
   {
      "id":"2010501",
      "phuongxa":"Xã Mèo Vạc",
      "quanhuyen":"20105"
   },
   {
      "id":"2010513",
      "phuongxa":"Xã Giàng Chu Phìn",
      "quanhuyen":"20105"
   },
   {
      "id":"2010529",
      "phuongxa":"Xã Khâu Vai",
      "quanhuyen":"20105"
   },
   {
      "id":"2010707",
      "phuongxa":"Xã Bạch Đích",
      "quanhuyen":"20107"
   },
   {
      "id":"2010719",
      "phuongxa":"Xã Ngam La",
      "quanhuyen":"20107"
   },
   {
      "id":"2011343",
      "phuongxa":"Xã Nam Sơn",
      "quanhuyen":"20113"
   },
   {
      "id":"2011353",
      "phuongxa":"Xã Xuân Minh",
      "quanhuyen":"20113"
   },
   {
      "id":"2011509",
      "phuongxa":"Xã Thanh Thủy",
      "quanhuyen":"20115"
   },
   {
      "id":"2011521",
      "phuongxa":"Xã Lao Chải",
      "quanhuyen":"20115"
   },
   {
      "id":"2011939",
      "phuongxa":"Xã Yên Hà",
      "quanhuyen":"20119"
   },
   {
      "id":"2011949",
      "phuongxa":"Xã Tiên Kiều",
      "quanhuyen":"20119"
   },
   {
      "id":"2011963",
      "phuongxa":"Xã Đồng Tiến",
      "quanhuyen":"20119"
   },
   {
      "id":"2012107",
      "phuongxa":"Xã Bản Rịa",
      "quanhuyen":"20121"
   },
   {
      "id":"2012119",
      "phuongxa":"Xã Yên Hà",
      "quanhuyen":"20121"
   },
   {
      "id":"2050917",
      "phuongxa":"Xã Lùng Cải",
      "quanhuyen":"20509"
   },
   {
      "id":"2050929",
      "phuongxa":"Xã Nàn Xín",
      "quanhuyen":"20509"
   },
   {
      "id":"2050941",
      "phuongxa":"Xã Bản Phố",
      "quanhuyen":"20509"
   },
   {
      "id":"2050951",
      "phuongxa":"Xã Bản Liền",
      "quanhuyen":"20509"
   },
   {
      "id":"2050965",
      "phuongxa":"Xã Cốc Lầu",
      "quanhuyen":"20509"
   },
   {
      "id":"2091303",
      "phuongxa":"Thị trấn Đồng Đăng",
      "quanhuyen":"20913"
   },
   {
      "id":"2091317",
      "phuongxa":"Xã Song Giáp",
      "quanhuyen":"20913"
   },
   {
      "id":"2091331",
      "phuongxa":"Xã Mẫu Sơn",
      "quanhuyen":"20913"
   },
   {
      "id":"2091343",
      "phuongxa":"Xã Tân Thành",
      "quanhuyen":"20913"
   },
   {
      "id":"2091905",
      "phuongxa":"Xã Bính Xá",
      "quanhuyen":"20919"
   },
   {
      "id":"2091917",
      "phuongxa":"Xã Lâm Ca",
      "quanhuyen":"20919"
   },
   {
      "id":"2092101",
      "phuongxa":"Thị trấn Hữu Lũng",
      "quanhuyen":"20921"
   },
   {
      "id":"2092117",
      "phuongxa":"Xã Thiện Kỵ",
      "quanhuyen":"20921"
   },
   {
      "id":"2051527",
      "phuongxa":"Xã Bảo Hà",
      "quanhuyen":"20515"
   },
   {
      "id":"2051701",
      "phuongxa":"Thị trấn Than Uyên",
      "quanhuyen":"20517"
   },
   {
      "id":"2051715",
      "phuongxa":"Xã Pắc Ta",
      "quanhuyen":"20517"
   },
   {
      "id":"2051727",
      "phuongxa":"Xã Tà Hừa",
      "quanhuyen":"20517"
   },
   {
      "id":"2051901",
      "phuongxa":"Thị trấn Khánh Yên",
      "quanhuyen":"20519"
   },
   {
      "id":"2070343",
      "phuongxa":"Xã Yến Dương",
      "quanhuyen":"20703"
   },
   {
      "id":"2070501",
      "phuongxa":"Thị trấn Nà Phặc",
      "quanhuyen":"20705"
   },
   {
      "id":"2070515",
      "phuongxa":"Xã Thượng Quan",
      "quanhuyen":"20705"
   },
   {
      "id":"2070703",
      "phuongxa":"Xã Xuân Lạc",
      "quanhuyen":"20707"
   },
   {
      "id":"2071133",
      "phuongxa":"Xã Quang Thuận",
      "quanhuyen":"20711"
   },
   {
      "id":"2071309",
      "phuongxa":"Xã Thanh Mai",
      "quanhuyen":"20713"
   },
   {
      "id":"2071321",
      "phuongxa":"Xã Yên Cư",
      "quanhuyen":"20713"
   },
   {
      "id":"2071500",
      "phuongxa":"Không xác định",
      "quanhuyen":"20715"
   },
   {
      "id":"2090519",
      "phuongxa":"Xã Nam La",
      "quanhuyen":"20905"
   },
   {
      "id":"2090529",
      "phuongxa":"Xã Thanh Long",
      "quanhuyen":"20905"
   },
   {
      "id":"2090701",
      "phuongxa":"Thị trấn Bình Gia",
      "quanhuyen":"20907"
   },
   {
      "id":"2090715",
      "phuongxa":"Xã Thiện Thuật",
      "quanhuyen":"20907"
   },
   {
      "id":"2032309",
      "phuongxa":"Xã Quảng Lâm",
      "quanhuyen":"20323"
   },
   {
      "id":"2032321",
      "phuongxa":"Thị trấn Pác Miầu",
      "quanhuyen":"20323"
   },
   {
      "id":"2032505",
      "phuongxa":"Xã Nam Quang",
      "quanhuyen":"20325"
   },
   {
      "id":"2150727",
      "phuongxa":"Xã Dân Tiến",
      "quanhuyen":"21507"
   },
   {
      "id":"2150907",
      "phuongxa":"Xã Yên Trạch",
      "quanhuyen":"21509"
   },
   {
      "id":"2150921",
      "phuongxa":"Xã Phú Đô",
      "quanhuyen":"21509"
   },
   {
      "id":"2151100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21511"
   },
   {
      "id":"2151509",
      "phuongxa":"Xã Tân Kim",
      "quanhuyen":"21515"
   },
   {
      "id":"2151521",
      "phuongxa":"Xã Điềm Thụy",
      "quanhuyen":"21515"
   },
   {
      "id":"2151535",
      "phuongxa":"Xã Nga My",
      "quanhuyen":"21515"
   },
   {
      "id":"2151700",
      "phuongxa":"Không xác định",
      "quanhuyen":"21517"
   },
   {
      "id":"2151713",
      "phuongxa":"Xã Hồng Tiến",
      "quanhuyen":"21517"
   },
   {
      "id":"2110505",
      "phuongxa":"Xã Hồng Quang",
      "quanhuyen":"21105"
   },
   {
      "id":"2110519",
      "phuongxa":"Xã Hùng Mỹ",
      "quanhuyen":"21105"
   },
   {
      "id":"2110531",
      "phuongxa":"Xã Phú Bình",
      "quanhuyen":"21105"
   },
   {
      "id":"2110545",
      "phuongxa":"Xã Vinh Quang",
      "quanhuyen":"21105"
   },
   {
      "id":"2110700",
      "phuongxa":"Không xác định",
      "quanhuyen":"21107"
   },
   {
      "id":"8259400",
      "phuongxa":"Xuân Đài",
      "quanhuyen":"50905"
   },
   {
      "id":"8259421",
      "phuongxa":"Xã An Hòa",
      "quanhuyen":"80303"
   },
   {
      "id":"2111100",
      "phuongxa":"Không xác định",
      "quanhuyen":"21111"
   },
   {
      "id":"2111111",
      "phuongxa":"Xã Vĩnh Lợi",
      "quanhuyen":"21111"
   },
   {
      "id":"2111123",
      "phuongxa":"Xã Cấp Tiến",
      "quanhuyen":"21111"
   },
   {
      "id":"2111135",
      "phuongxa":"Xã Đồng Quý",
      "quanhuyen":"21111"
   },
   {
      "id":"2130533",
      "phuongxa":"Xã Liễu Đô",
      "quanhuyen":"21305"
   },
   {
      "id":"2130545",
      "phuongxa":"Xã Phan Thanh",
      "quanhuyen":"21305"
   },
   {
      "id":"2130707",
      "phuongxa":"Xã Lâm Giang",
      "quanhuyen":"21307"
   },
   {
      "id":"2130719",
      "phuongxa":"Xã Đông Cuông",
      "quanhuyen":"21307"
   },
   {
      "id":"2130731",
      "phuongxa":"Xã An Thịnh",
      "quanhuyen":"21307"
   },
   {
      "id":"2131153",
      "phuongxa":"Xã Văn Lãng",
      "quanhuyen":"21311"
   },
   {
      "id":"2131305",
      "phuongxa":"Xã Xuân Long",
      "quanhuyen":"21313"
   },
   {
      "id":"2131317",
      "phuongxa":"Xã Cẩm Nhân",
      "quanhuyen":"21313"
   },
   {
      "id":"2131331",
      "phuongxa":"Xã Đại Đồng",
      "quanhuyen":"21313"
   },
   {
      "id":"2131343",
      "phuongxa":"Xã Vĩnh Kiên",
      "quanhuyen":"21313"
   },
   {
      "id":"2150103",
      "phuongxa":"Phường Tân Long",
      "quanhuyen":"21501"
   },
   {
      "id":"2150113",
      "phuongxa":"Phường Đồng Quang",
      "quanhuyen":"21501"
   },
   {
      "id":"2150125",
      "phuongxa":"Phường Phú Xá",
      "quanhuyen":"21501"
   },
   {
      "id":"2150137",
      "phuongxa":"Xã Phúc Xuân",
      "quanhuyen":"21501"
   },
   {
      "id":"2171105",
      "phuongxa":"Thị trấn Phú Hộ",
      "quanhuyen":"21711"
   },
   {
      "id":"2171119",
      "phuongxa":"Xã Bảo Thanh",
      "quanhuyen":"21711"
   },
   {
      "id":"2171135",
      "phuongxa":"Xã An Đạo",
      "quanhuyen":"21711"
   },
   {
      "id":"2171201",
      "phuongxa":"Thị trấn Lâm Thao",
      "quanhuyen":"21712"
   },
   {
      "id":"2211703",
      "phuongxa":"Xã Bích Sơn",
      "quanhuyen":"22117"
   },
   {
      "id":"2211715",
      "phuongxa":"Xã Nghĩa Trung",
      "quanhuyen":"22117"
   },
   {
      "id":"2211727",
      "phuongxa":"Xã Tiên Sơn",
      "quanhuyen":"22117"
   },
   {
      "id":"2211901",
      "phuongxa":"Thị trấn Neo",
      "quanhuyen":"22119"
   },
   {
      "id":"2230509",
      "phuongxa":"Xã Nhân Hòa",
      "quanhuyen":"22305"
   },
   {
      "id":"2230521",
      "phuongxa":"Xã Phượng Mao",
      "quanhuyen":"22305"
   },
   {
      "id":"2230535",
      "phuongxa":"Xã Yên Giả",
      "quanhuyen":"22305"
   },
   {
      "id":"2230547",
      "phuongxa":"Xã Hán Quảng",
      "quanhuyen":"22305"
   },
   {
      "id":"2171511",
      "phuongxa":"Xã Trung Sơn",
      "quanhuyen":"21715"
   },
   {
      "id":"2171523",
      "phuongxa":"Xã Đồng Thịnh",
      "quanhuyen":"21715"
   },
   {
      "id":"2171701",
      "phuongxa":"Thị trấn Hưng Hoá",
      "quanhuyen":"21717"
   },
   {
      "id":"2171715",
      "phuongxa":"Xã Văn Lương",
      "quanhuyen":"21717"
   },
   {
      "id":"2171965",
      "phuongxa":"Xã Thu Ngạc",
      "quanhuyen":"21719"
   },
   {
      "id":"2171977",
      "phuongxa":"Xã Đồng Sơn",
      "quanhuyen":"21719"
   },
   {
      "id":"2172107",
      "phuongxa":"Xã Ngô Xá",
      "quanhuyen":"21721"
   },
   {
      "id":"2172117",
      "phuongxa":"Xã Phùng Xá",
      "quanhuyen":"21721"
   },
   {
      "id":"2210501",
      "phuongxa":"Thị trấn Cao Thượng",
      "quanhuyen":"22105"
   },
   {
      "id":"2210517",
      "phuongxa":"Xã An Dương",
      "quanhuyen":"22105"
   },
   {
      "id":"2210531",
      "phuongxa":"Xã Việt Ngọc",
      "quanhuyen":"22105"
   },
   {
      "id":"2210543",
      "phuongxa":"Xã Ngọc Lý",
      "quanhuyen":"22105"
   },
   {
      "id":"2210945",
      "phuongxa":"Xã Đông Lỗ",
      "quanhuyen":"22109"
   },
   {
      "id":"2211101",
      "phuongxa":"Thị trấn Vôi",
      "quanhuyen":"22111"
   },
   {
      "id":"2211113",
      "phuongxa":"Xã Nghĩa Hòa",
      "quanhuyen":"22111"
   },
   {
      "id":"2211127",
      "phuongxa":"Xã Dương Đức",
      "quanhuyen":"22111"
   },
   {
      "id":"2170525",
      "phuongxa":"Xã Tây Cốc",
      "quanhuyen":"21705"
   },
   {
      "id":"2170537",
      "phuongxa":"Xã Yên Kiện",
      "quanhuyen":"21705"
   },
   {
      "id":"2170549",
      "phuongxa":"Xã Minh Phú",
      "quanhuyen":"21705"
   },
   {
      "id":"2170705",
      "phuongxa":"Xã Hậu Bổng",
      "quanhuyen":"21707"
   },
   {
      "id":"3030511",
      "phuongxa":"Xã Mường Trai",
      "quanhuyen":"30305"
   },
   {
      "id":"3030525",
      "phuongxa":"Xã Chiềng San",
      "quanhuyen":"30305"
   },
   {
      "id":"3030701",
      "phuongxa":"Thị trấn Thuận Châu",
      "quanhuyen":"30307"
   },
   {
      "id":"3030717",
      "phuongxa":"Xã Mường é",
      "quanhuyen":"30307"
   },
   {
      "id":"3031123",
      "phuongxa":"Xã Huy Tân",
      "quanhuyen":"30311"
   },
   {
      "id":"3031135",
      "phuongxa":"Xã Tường Thượng",
      "quanhuyen":"30311"
   },
   {
      "id":"3031149",
      "phuongxa":"Xã Tân Phong",
      "quanhuyen":"30311"
   },
   {
      "id":"3031303",
      "phuongxa":"Thị trấn NT Tô Hiệu",
      "quanhuyen":"30313"
   },
   {
      "id":"2231135",
      "phuongxa":"Xã Tân Lãng",
      "quanhuyen":"22311"
   },
   {
      "id":"2231147",
      "phuongxa":"Xã Minh Tân",
      "quanhuyen":"22311"
   },
   {
      "id":"2231205",
      "phuongxa":"Xã Thái Bảo",
      "quanhuyen":"22312"
   },
   {
      "id":"2231217",
      "phuongxa":"Xã Lãng Ngâm",
      "quanhuyen":"22312"
   },
   {
      "id":"2250100",
      "phuongxa":"Không xác định",
      "quanhuyen":"22501"
   },
   {
      "id":"2250705",
      "phuongxa":"Xã Hoành Mô",
      "quanhuyen":"22507"
   },
   {
      "id":"2250901",
      "phuongxa":"Phường Ca Long",
      "quanhuyen":"22509"
   },
   {
      "id":"2250913",
      "phuongxa":"Xã Bình Ngọc",
      "quanhuyen":"22509"
   },
   {
      "id":"2250925",
      "phuongxa":"Xã Hải Tiến",
      "quanhuyen":"22509"
   },
   {
      "id":"2252105",
      "phuongxa":"Xã Bình Dương",
      "quanhuyen":"22521"
   },
   {
      "id":"2252119",
      "phuongxa":"Xã Đức Chính",
      "quanhuyen":"22521"
   },
   {
      "id":"2252133",
      "phuongxa":"Xã Hoàng Quế",
      "quanhuyen":"22521"
   },
   {
      "id":"2252300",
      "phuongxa":"Không xác định",
      "quanhuyen":"22523"
   },
   {
      "id":"3010311",
      "phuongxa":"Xã Pa Vệ Sử",
      "quanhuyen":"30103"
   },
   {
      "id":"3010325",
      "phuongxa":"Xã Mường Mô",
      "quanhuyen":"30103"
   },
   {
      "id":"3010505",
      "phuongxa":"Xã Ka Lăng",
      "quanhuyen":"30105"
   },
   {
      "id":"3010517",
      "phuongxa":"Xã Mường Mô",
      "quanhuyen":"30105"
   },
   {
      "id":"3010933",
      "phuongxa":"Xã Tủa Sín Chải",
      "quanhuyen":"30109"
   },
   {
      "id":"3011101",
      "phuongxa":"Thị trấn Mường Lay",
      "quanhuyen":"30111"
   },
   {
      "id":"3011119",
      "phuongxa":"Xã Chà Tở",
      "quanhuyen":"30111"
   },
   {
      "id":"3011129",
      "phuongxa":"Xã Mường Mơn",
      "quanhuyen":"30111"
   },
   {
      "id":"3050335",
      "phuongxa":"Xã Hiền Lương",
      "quanhuyen":"30503"
   },
   {
      "id":"3050503",
      "phuongxa":"Xã Tân Mai",
      "quanhuyen":"30505"
   },
   {
      "id":"3050515",
      "phuongxa":"Xã Piềng Vế",
      "quanhuyen":"30505"
   },
   {
      "id":"3050529",
      "phuongxa":"Xã Nà Phòn",
      "quanhuyen":"30505"
   },
   {
      "id":"4010931",
      "phuongxa":"Xã Trung Sơn",
      "quanhuyen":"40109"
   },
   {
      "id":"4011105",
      "phuongxa":"Xã Trung Hạ",
      "quanhuyen":"40111"
   },
   {
      "id":"4011300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40113"
   },
   {
      "id":"4011311",
      "phuongxa":"Xã Thành Sơn",
      "quanhuyen":"40113"
   },
   {
      "id":"4011911",
      "phuongxa":"Xã Thạch Tượng",
      "quanhuyen":"40119"
   },
   {
      "id":"4011925",
      "phuongxa":"Xã Thành Mỹ",
      "quanhuyen":"40119"
   },
   {
      "id":"4011937",
      "phuongxa":"Xã Thành Trực",
      "quanhuyen":"40119"
   },
   {
      "id":"4011949",
      "phuongxa":"Xã Thành Long",
      "quanhuyen":"40119"
   },
   {
      "id":"3051107",
      "phuongxa":"Xã  Bắc Sơn",
      "quanhuyen":"30511"
   },
   {
      "id":"3051119",
      "phuongxa":"Xã Vĩnh Tiến",
      "quanhuyen":"30511"
   },
   {
      "id":"3051133",
      "phuongxa":"Xã Vĩnh Đồng",
      "quanhuyen":"30511"
   },
   {
      "id":"3051145",
      "phuongxa":"Xã Kim Bình",
      "quanhuyen":"30511"
   },
   {
      "id":"3051157",
      "phuongxa":"Xã Nam Thượng",
      "quanhuyen":"30511"
   },
   {
      "id":"3051553",
      "phuongxa":"Xã Tân Mỹ",
      "quanhuyen":"30515"
   },
   {
      "id":"3051701",
      "phuongxa":"Thị trấn Chi Nê",
      "quanhuyen":"30517"
   },
   {
      "id":"3051715",
      "phuongxa":"Xã Liên Hòa",
      "quanhuyen":"30517"
   },
   {
      "id":"3051727",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"30517"
   },
   {
      "id":"3070713",
      "phuongxa":"Xã Hứa Ngài",
      "quanhuyen":"30707"
   },
   {
      "id":"8259410",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"70909"
   },
   {
      "id":"8259422",
      "phuongxa":"Xã Viên An",
      "quanhuyen":"82599"
   },
   {
      "id":"3070915",
      "phuongxa":"Xã Trung Thu",
      "quanhuyen":"30709"
   },
   {
      "id":"8259445",
      "phuongxa":"Phường Hòa Vinh",
      "quanhuyen":"50919"
   },
   {
      "id":"3070901",
      "phuongxa":"Thị Trấn Tủa Chùa",
      "quanhuyen":"30709"
   },
   {
      "id":"3071101",
      "phuongxa":"Thị Trấn Tuần Giáo",
      "quanhuyen":"30711"
   },
   {
      "id":"3071719",
      "phuongxa":"Thị trấn Mường Ang",
      "quanhuyen":"30717"
   },
   {
      "id":"3071911",
      "phuongxa":"Xã Chà Nưa",
      "quanhuyen":"30719"
   },
   {
      "id":"3071923",
      "phuongxa":"Xã Na Sang",
      "quanhuyen":"30719"
   },
   {
      "id":"4010100",
      "phuongxa":"Không xác định",
      "quanhuyen":"40101"
   },
   {
      "id":"3031715",
      "phuongxa":"Xã Mường Lựm",
      "quanhuyen":"30317"
   },
   {
      "id":"3031727",
      "phuongxa":"Xã Chiềng Tương",
      "quanhuyen":"30317"
   },
   {
      "id":"3031905",
      "phuongxa":"Thị trấn NT Chiềng Ve",
      "quanhuyen":"30319"
   },
   {
      "id":"3031923",
      "phuongxa":"Xã Tô Múa",
      "quanhuyen":"30319"
   },
   {
      "id":"4015109",
      "phuongxa":"Xã Tân Khang",
      "quanhuyen":"40151"
   },
   {
      "id":"4015121",
      "phuongxa":"Xã Tế Nông",
      "quanhuyen":"40151"
   },
   {
      "id":"4015133",
      "phuongxa":"Xã Minh Thọ",
      "quanhuyen":"40151"
   },
   {
      "id":"4015145",
      "phuongxa":"Xã Thăng Bình",
      "quanhuyen":"40151"
   },
   {
      "id":"4030115",
      "phuongxa":"Phường Trường Thi",
      "quanhuyen":"40301"
   },
   {
      "id":"4030127",
      "phuongxa":"Xã Nghi Phú",
      "quanhuyen":"40301"
   },
   {
      "id":"4030301",
      "phuongxa":"Phường Nghi Tân",
      "quanhuyen":"40303"
   },
   {
      "id":"4030313",
      "phuongxa":"Xã Nghi Hương",
      "quanhuyen":"40303"
   },
   {
      "id":"4030509",
      "phuongxa":"Xã Tiền Phong",
      "quanhuyen":"40305"
   },
   {
      "id":"4012513",
      "phuongxa":"Xã Cát Vân",
      "quanhuyen":"40125"
   },
   {
      "id":"4012525",
      "phuongxa":"Xã Thanh Phong",
      "quanhuyen":"40125"
   },
   {
      "id":"4012703",
      "phuongxa":"Xã Thanh Tân",
      "quanhuyen":"40127"
   },
   {
      "id":"4012715",
      "phuongxa":"Xã Xuân Thọ",
      "quanhuyen":"40127"
   },
   {
      "id":"4013319",
      "phuongxa":"Xã Nga Bạch",
      "quanhuyen":"40133"
   },
   {
      "id":"4013331",
      "phuongxa":"Xã Nga Hải",
      "quanhuyen":"40133"
   },
   {
      "id":"4013345",
      "phuongxa":"Xã Nga Liên",
      "quanhuyen":"40133"
   },
   {
      "id":"4013500",
      "phuongxa":"Không xác định",
      "quanhuyen":"40135"
   },
   {
      "id":"4013751",
      "phuongxa":"Xã Xuân Lam",
      "quanhuyen":"40137"
   },
   {
      "id":"4013763",
      "phuongxa":"Xã Xuân Tiến",
      "quanhuyen":"40137"
   },
   {
      "id":"4013775",
      "phuongxa":"Xã Xuân Minh",
      "quanhuyen":"40137"
   },
   {
      "id":"4013903",
      "phuongxa":"Xã Đồng Lộc",
      "quanhuyen":"40139"
   },
   {
      "id":"4014301",
      "phuongxa":"Thị trấn Hoằng Hóa",
      "quanhuyen":"40143"
   },
   {
      "id":"4014315",
      "phuongxa":"Xã Hoằng Kim",
      "quanhuyen":"40143"
   },
   {
      "id":"4014327",
      "phuongxa":"Xã Hoằng Cát",
      "quanhuyen":"40143"
   },
   {
      "id":"4014339",
      "phuongxa":"Xã Hoằng Quang",
      "quanhuyen":"40101"
   },
   {
      "id":"4014701",
      "phuongxa":"Thị trấn Triệu Sơn",
      "quanhuyen":"40147"
   },
   {
      "id":"4014717",
      "phuongxa":"Xã Hợp Thắng",
      "quanhuyen":"40147"
   },
   {
      "id":"4014731",
      "phuongxa":"Xã An Nông",
      "quanhuyen":"40147"
   },
   {
      "id":"4014743",
      "phuongxa":"Xã Đồng Thắng",
      "quanhuyen":"40147"
   },
   {
      "id":"4014755",
      "phuongxa":"Xã Xuân Thọ",
      "quanhuyen":"40147"
   },
   {
      "id":"4031711",
      "phuongxa":"Xã Quỳnh Thiện",
      "quanhuyen":"40317"
   },
   {
      "id":"4031723",
      "phuongxa":"Xã Quỳnh Xuân",
      "quanhuyen":"40317"
   },
   {
      "id":"4031737",
      "phuongxa":"Xã Quỳnh Diện",
      "quanhuyen":"40317"
   },
   {
      "id":"4031749",
      "phuongxa":"Xã Quỳnh Hồng",
      "quanhuyen":"40317"
   },
   {
      "id":"4050715",
      "phuongxa":"Xã Đức Vĩnh",
      "quanhuyen":"40507"
   },
   {
      "id":"4050729",
      "phuongxa":"Xã Đức Thủy",
      "quanhuyen":"40507"
   },
   {
      "id":"4050743",
      "phuongxa":"Xã Đức Thanh",
      "quanhuyen":"40507"
   },
   {
      "id":"4050755",
      "phuongxa":"Xã Đức Đồng",
      "quanhuyen":"40507"
   },
   {
      "id":"4051137",
      "phuongxa":"Xã Thuần Thiện",
      "quanhuyen":"40511"
   },
   {
      "id":"4051153",
      "phuongxa":"Xã Thượng Lộc",
      "quanhuyen":"40511"
   },
   {
      "id":"4051301",
      "phuongxa":"Thị trấn Cày",
      "quanhuyen":"40513"
   },
   {
      "id":"4051313",
      "phuongxa":"Xã Thạch Bằng",
      "quanhuyen":"40513"
   },
   {
      "id":"4032313",
      "phuongxa":"Xã Thọ Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032325",
      "phuongxa":"Xã Hồng Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032337",
      "phuongxa":"Xã Bắc Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032349",
      "phuongxa":"Xã Công Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"4032711",
      "phuongxa":"Xã Đỉnh Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032723",
      "phuongxa":"Xã Thạch Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032737",
      "phuongxa":"Xã Cao Sơn",
      "quanhuyen":"40327"
   },
   {
      "id":"4032905",
      "phuongxa":"Xã Lam Sơn",
      "quanhuyen":"40329"
   },
   {
      "id":"4033161",
      "phuongxa":"Xã Thanh Khai",
      "quanhuyen":"40331"
   },
   {
      "id":"4033173",
      "phuongxa":"Xã Thanh Lâm",
      "quanhuyen":"40331"
   },
   {
      "id":"4033309",
      "phuongxa":"Xã Nghi Lâm",
      "quanhuyen":"40333"
   },
   {
      "id":"4033321",
      "phuongxa":"Xã Nghi Thuận",
      "quanhuyen":"40333"
   },
   {
      "id":"4033719",
      "phuongxa":"Xã Hưng Thịnh",
      "quanhuyen":"40337"
   },
   {
      "id":"4033733",
      "phuongxa":"Xã Hưng Lợi",
      "quanhuyen":"40337"
   },
   {
      "id":"4033745",
      "phuongxa":"Xã Hưng Nhân",
      "quanhuyen":"40337"
   },
   {
      "id":"4033907",
      "phuongxa":"Phường Long Sơn",
      "quanhuyen":"40339"
   },
   {
      "id":"4031119",
      "phuongxa":"Xã Châu Cường",
      "quanhuyen":"40311"
   },
   {
      "id":"4031131",
      "phuongxa":"Xã Châu Lý",
      "quanhuyen":"40311"
   },
   {
      "id":"4031300",
      "phuongxa":"Không xác định",
      "quanhuyen":"40313"
   },
   {
      "id":"4031313",
      "phuongxa":"Xã Nghĩa Hiếu",
      "quanhuyen":"40313"
   },
   {
      "id":"4091707",
      "phuongxa":"Xã Triệu Nguyên",
      "quanhuyen":"40917"
   },
   {
      "id":"4091723",
      "phuongxa":"Xã A Bung",
      "quanhuyen":"40917"
   },
   {
      "id":"4110103",
      "phuongxa":"Phường Phú Bình",
      "quanhuyen":"41101"
   },
   {
      "id":"4110115",
      "phuongxa":"Phường Thuận Thành",
      "quanhuyen":"41101"
   },
   {
      "id":"4110709",
      "phuongxa":"Phường Hương Văn",
      "quanhuyen":"41107"
   },
   {
      "id":"4110723",
      "phuongxa":"Phường Hương Hồ",
      "quanhuyen":"41107"
   },
   {
      "id":"4110903",
      "phuongxa":"Xã Thuận An",
      "quanhuyen":"41109"
   },
   {
      "id":"4110917",
      "phuongxa":"Xã Phú An",
      "quanhuyen":"41109"
   },
   {
      "id":"4051533",
      "phuongxa":"Xã Cẩm Lĩnh",
      "quanhuyen":"40515"
   },
   {
      "id":"4051547",
      "phuongxa":"Xã Cẩm Thịnh",
      "quanhuyen":"40515"
   },
   {
      "id":"4051703",
      "phuongxa":"Thị trấn NT 20-4",
      "quanhuyen":"40517"
   },
   {
      "id":"4051717",
      "phuongxa":"Xã Phương Mỹ",
      "quanhuyen":"40517"
   },
   {
      "id":"4052111",
      "phuongxa":"Xã Đức Hương",
      "quanhuyen":"40521"
   },
   {
      "id":"4052123",
      "phuongxa":"Xã Hương Quang",
      "quanhuyen":"40521"
   },
   {
      "id":"4052311",
      "phuongxa":"Xã An Lộc",
      "quanhuyen":"40523"
   },
   {
      "id":"4052323",
      "phuongxa":"Xã Hộ Độ",
      "quanhuyen":"40523"
   },
   {
      "id":"4070713",
      "phuongxa":"Xã Quảng Tùng",
      "quanhuyen":"40707"
   },
   {
      "id":"4070723",
      "phuongxa":"Xã Quảng Phúc",
      "quanhuyen":"82626"
   },
   {
      "id":"4070735",
      "phuongxa":"Xã Quảng Phương",
      "quanhuyen":"40707"
   },
   {
      "id":"4070749",
      "phuongxa":"Xã Quảng Tiên",
      "quanhuyen":"82626"
   },
   {
      "id":"4071303",
      "phuongxa":"Thị trấn NT Lệ Ninh",
      "quanhuyen":"40713"
   },
   {
      "id":"4071319",
      "phuongxa":"Xã Văn Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071331",
      "phuongxa":"Xã Mỹ Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4071341",
      "phuongxa":"Xã Thanh Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4090721",
      "phuongxa":"Xã Gio Hải",
      "quanhuyen":"40907"
   },
   {
      "id":"8259425",
      "phuongxa":"Quỹ Nhất",
      "quanhuyen":"11317"
   },
   {
      "id":"4090733",
      "phuongxa":"Xã Hải Thái",
      "quanhuyen":"40907"
   },
   {
      "id":"4090903",
      "phuongxa":"Xã Cam Thành",
      "quanhuyen":"40909"
   },
   {
      "id":"4090917",
      "phuongxa":"Xã Cam Nghĩa",
      "quanhuyen":"40909"
   },
   {
      "id":"4091107",
      "phuongxa":"Xã Triệu Phước",
      "quanhuyen":"40911"
   },
   {
      "id":"5011115",
      "phuongxa":"Xã Hòa Phước",
      "quanhuyen":"50111"
   },
   {
      "id":"5011127",
      "phuongxa":"Xã Hòa Phú",
      "quanhuyen":"50111"
   },
   {
      "id":"5011503",
      "phuongxa":"Phường Hoà Phát",
      "quanhuyen":"50115"
   },
   {
      "id":"5030100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50301"
   },
   {
      "id":"5051535",
      "phuongxa":"Phường Trương Quang Trọng",
      "quanhuyen":"50501"
   },
   {
      "id":"5051713",
      "phuongxa":"Xã Hành Thịnh",
      "quanhuyen":"50517"
   },
   {
      "id":"5051900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50519"
   },
   {
      "id":"5052101",
      "phuongxa":"Thị trấn Mộ Đức",
      "quanhuyen":"50521"
   },
   {
      "id":"5070000",
      "phuongxa":"Không xác định",
      "quanhuyen":"50700"
   },
   {
      "id":"5070107",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"50701"
   },
   {
      "id":"5070119",
      "phuongxa":"Phường Trần Phú",
      "quanhuyen":"50701"
   },
   {
      "id":"5070131",
      "phuongxa":"Xã Nhơn Hải",
      "quanhuyen":"50701"
   },
   {
      "id":"5030719",
      "phuongxa":"Xã Đại Chánh",
      "quanhuyen":"50307"
   },
   {
      "id":"5030900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50309"
   },
   {
      "id":"5030911",
      "phuongxa":"Xã Điện Hồng",
      "quanhuyen":"50309"
   },
   {
      "id":"5030923",
      "phuongxa":"Xã Điện Quang",
      "quanhuyen":"50309"
   },
   {
      "id":"5031101",
      "phuongxa":"Thị trấn Nam Phước",
      "quanhuyen":"50311"
   },
   {
      "id":"5031117",
      "phuongxa":"Xã Duy Trung",
      "quanhuyen":"50311"
   },
   {
      "id":"5031917",
      "phuongxa":"Xã Quế Lưu",
      "quanhuyen":"50319"
   },
   {
      "id":"5032103",
      "phuongxa":"Xã Tiên Sơn",
      "quanhuyen":"50321"
   },
   {
      "id":"5032115",
      "phuongxa":"Xã Tiên Hiệp",
      "quanhuyen":"50321"
   },
   {
      "id":"5032129",
      "phuongxa":"Xã Tiên Lập",
      "quanhuyen":"50321"
   },
   {
      "id":"5032307",
      "phuongxa":"Xã Phước Năng",
      "quanhuyen":"50323"
   },
   {
      "id":"5033305",
      "phuongxa":"Xã Trà Nú",
      "quanhuyen":"50333"
   },
   {
      "id":"5033317",
      "phuongxa":"Xã Trà Tân",
      "quanhuyen":"50333"
   },
   {
      "id":"5033501",
      "phuongxa":"Xã Trà Leng",
      "quanhuyen":"50335"
   },
   {
      "id":"5033515",
      "phuongxa":"Xã Trà Don",
      "quanhuyen":"50335"
   },
   {
      "id":"5050549",
      "phuongxa":"Xã Bình Thanh Tây",
      "quanhuyen":"50505"
   },
   {
      "id":"5050713",
      "phuongxa":"Xã Trà Tân",
      "quanhuyen":"50507"
   },
   {
      "id":"5050725",
      "phuongxa":"Xã Trà Quân",
      "quanhuyen":"50507"
   },
   {
      "id":"5050900",
      "phuongxa":"Không xác định",
      "quanhuyen":"50509"
   },
   {
      "id":"4111505",
      "phuongxa":"Xã Hồng Vân",
      "quanhuyen":"41115"
   },
   {
      "id":"4111517",
      "phuongxa":"Xã Nhâm",
      "quanhuyen":"41115"
   },
   {
      "id":"4111529",
      "phuongxa":"Xã Hồng Thượng",
      "quanhuyen":"41115"
   },
   {
      "id":"4111541",
      "phuongxa":"Xã Hương Nguyên",
      "quanhuyen":"41115"
   },
   {
      "id":"5111707",
      "phuongxa":"Xã Cam Hải Tây",
      "quanhuyen":"51117"
   },
   {
      "id":"5111719",
      "phuongxa":"Xã Cam Thành Bắc",
      "quanhuyen":"51117"
   },
   {
      "id":"6010101",
      "phuongxa":"Phường Quang Trung",
      "quanhuyen":"60101"
   },
   {
      "id":"6010113",
      "phuongxa":"Xã Ngọk Bay",
      "quanhuyen":"60101"
   },
   {
      "id":"6011101",
      "phuongxa":"Thị trấn Đak Hà",
      "quanhuyen":"60111"
   },
   {
      "id":"6011117",
      "phuongxa":"Xã Đắkmar",
      "quanhuyen":"60111"
   },
   {
      "id":"6011307",
      "phuongxa":"Xã Sa Nhơn",
      "quanhuyen":"60113"
   },
   {
      "id":"6011501",
      "phuongxa":"Thị trấn Đắk Rve",
      "quanhuyen":"60115"
   },
   {
      "id":"5070907",
      "phuongxa":"Xã Mỹ Thắng",
      "quanhuyen":"50709"
   },
   {
      "id":"5070921",
      "phuongxa":"Xã Mỹ Hòa",
      "quanhuyen":"50709"
   },
   {
      "id":"5070933",
      "phuongxa":"Xã Mỹ Cát",
      "quanhuyen":"50709"
   },
   {
      "id":"5071109",
      "phuongxa":"Xã Vĩnh Hảo",
      "quanhuyen":"50711"
   },
   {
      "id":"5071303",
      "phuongxa":"Xã Cát Sơn",
      "quanhuyen":"50713"
   },
   {
      "id":"5071903",
      "phuongxa":"Thị trấn Diêu Trì",
      "quanhuyen":"50719"
   },
   {
      "id":"5071917",
      "phuongxa":"Xã Phước Lộc",
      "quanhuyen":"50719"
   },
   {
      "id":"5072100",
      "phuongxa":"Không xác định",
      "quanhuyen":"50721"
   },
   {
      "id":"5090000",
      "phuongxa":"Không xác định",
      "quanhuyen":"50900"
   },
   {
      "id":"5090903",
      "phuongxa":"Xã Phước Tân",
      "quanhuyen":"50909"
   },
   {
      "id":"5090917",
      "phuongxa":"Xã Sơn Định",
      "quanhuyen":"50909"
   },
   {
      "id":"5091101",
      "phuongxa":"Phường Phú Lâm",
      "quanhuyen":"50911"
   },
   {
      "id":"5091113",
      "phuongxa":"Xã Hòa Thịnh",
      "quanhuyen":"50911"
   },
   {
      "id":"5091911",
      "phuongxa":"Xã Hoà Xuân Tây",
      "quanhuyen":"50919"
   },
   {
      "id":"5110100",
      "phuongxa":"Không xác định",
      "quanhuyen":"51101"
   },
   {
      "id":"5110111",
      "phuongxa":"Phường Phương Sơn",
      "quanhuyen":"51101"
   },
   {
      "id":"5110123",
      "phuongxa":"Phường Phước Hải",
      "quanhuyen":"51101"
   },
   {
      "id":"5110545",
      "phuongxa":"Xã Ninh Hưng",
      "quanhuyen":"51105"
   },
   {
      "id":"5110701",
      "phuongxa":"Thị trấn Diên Khánh",
      "quanhuyen":"51107"
   },
   {
      "id":"5110717",
      "phuongxa":"Xã Diên Phước",
      "quanhuyen":"51107"
   },
   {
      "id":"5110731",
      "phuongxa":"Xã Diên Lộc",
      "quanhuyen":"51107"
   },
   {
      "id":"6031900",
      "phuongxa":"Không xác định",
      "quanhuyen":"60319"
   },
   {
      "id":"6031913",
      "phuongxa":"Xã Ia HLốp",
      "quanhuyen":"60319"
   },
   {
      "id":"6031927",
      "phuongxa":"Xã Ia Le",
      "quanhuyen":"60319"
   },
   {
      "id":"6032107",
      "phuongxa":"Xã Ia Sol",
      "quanhuyen":"60321"
   },
   {
      "id":"7010513",
      "phuongxa":"Phường 7",
      "quanhuyen":"70105"
   },
   {
      "id":"7010527",
      "phuongxa":"Phường 14",
      "quanhuyen":"70105"
   },
   {
      "id":"7010709",
      "phuongxa":"Phường 5",
      "quanhuyen":"70107"
   },
   {
      "id":"7011527",
      "phuongxa":"Phường 14",
      "quanhuyen":"70115"
   },
   {
      "id":"7011703",
      "phuongxa":"Phường Phước Long B",
      "quanhuyen":"769"
   },
   {
      "id":"7011713",
      "phuongxa":"Phường Phước Bình",
      "quanhuyen":"769"
   },
   {
      "id":"7011725",
      "phuongxa":"Phường Phú Hữu",
      "quanhuyen":"769"
   },
   {
      "id":"6032907",
      "phuongxa":"Xã Kim Tân",
      "quanhuyen":"60329"
   },
   {
      "id":"6033100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60331"
   },
   {
      "id":"6033113",
      "phuongxa":"Xã Ia Hiao",
      "quanhuyen":"60331"
   },
   {
      "id":"6050101",
      "phuongxa":"Phường Tân Lập",
      "quanhuyen":"60501"
   },
   {
      "id":"6051303",
      "phuongxa":"Thị trấn Quảng Phú",
      "quanhuyen":"60513"
   },
   {
      "id":"8010501",
      "phuongxa":"Thị trấn Vĩnh Hưng",
      "quanhuyen":"80105"
   },
   {
      "id":"8110129",
      "phuongxa":"Xã Phú Nhuận",
      "quanhuyen":"81101"
   },
   {
      "id":"8110305",
      "phuongxa":"Xã An Khánh",
      "quanhuyen":"81103"
   },
   {
      "id":"8110319",
      "phuongxa":"Xã Thành Triệu",
      "quanhuyen":"81103"
   },
   {
      "id":"8110333",
      "phuongxa":"Xã Hữu Định",
      "quanhuyen":"81103"
   },
   {
      "id":"1011723",
      "phuongxa":"Xã Đại Mạch",
      "quanhuyen":"10117"
   },
   {
      "id":"1011913",
      "phuongxa":"Xã Lệ Chi",
      "quanhuyen":"10119"
   },
   {
      "id":"1011951",
      "phuongxa":"Xã Đa Tốn",
      "quanhuyen":"10119"
   },
   {
      "id":"1012121",
      "phuongxa":"Xã Cổ Nhuế",
      "quanhuyen":"10121"
   },
   {
      "id":"1012325",
      "phuongxa":"Xã Thịnh Liệt",
      "quanhuyen":"10123"
   },
   {
      "id":"1012517",
      "phuongxa":"Phường Yên Nghĩa",
      "quanhuyen":"10125"
   },
   {
      "id":"1012901",
      "phuongxa":"Thị trấn Tây Đằng",
      "quanhuyen":"10129"
   },
   {
      "id":"1012953",
      "phuongxa":"Xã Ba Trại",
      "quanhuyen":"10129"
   },
   {
      "id":"1013125",
      "phuongxa":"Xã Hát Môn",
      "quanhuyen":"10131"
   },
   {
      "id":"1013317",
      "phuongxa":"Xã Liên Trung",
      "quanhuyen":"10133"
   },
   {
      "id":"1010113",
      "phuongxa":"Phường Đội Cấn",
      "quanhuyen":"10101"
   },
   {
      "id":"8259407",
      "phuongxa":"Xã Ngư Thuỷ Nam",
      "quanhuyen":"40713"
   },
   {
      "id":"8259428",
      "phuongxa":"Phường Hòa Cường Nam",
      "quanhuyen":"50101"
   },
   {
      "id":"8259432",
      "phuongxa":"Xã Tạ An Khương Đông",
      "quanhuyen":"82311"
   },
   {
      "id":"8259433",
      "phuongxa":"Mỹ Xuân",
      "quanhuyen":"82629"
   },
   {
      "id":"1010513",
      "phuongxa":"Phường Lý Thái Tổ",
      "quanhuyen":"10105"
   },
   {
      "id":"1010733",
      "phuongxa":"Phường Vĩnh Tuy",
      "quanhuyen":"10107"
   },
   {
      "id":"1010907",
      "phuongxa":"Phường Văn Chương",
      "quanhuyen":"10109"
   },
   {
      "id":"1011113",
      "phuongxa":"Phường Khương Đình",
      "quanhuyen":"10111"
   },
   {
      "id":"1011531",
      "phuongxa":"Xã Trung Giã",
      "quanhuyen":"10115"
   },
   {
      "id":"1014315",
      "phuongxa":"Xã Thanh Cao",
      "quanhuyen":"10143"
   },
   {
      "id":"1014513",
      "phuongxa":"Xã Văn Bình",
      "quanhuyen":"10145"
   },
   {
      "id":"1014557",
      "phuongxa":"Xã Minh Cường",
      "quanhuyen":"10145"
   },
   {
      "id":"1032309",
      "phuongxa":"Xã Hoàng Châu",
      "quanhuyen":"10323"
   },
   {
      "id":"1032907",
      "phuongxa":"Xã An Hoà",
      "quanhuyen":"10329"
   },
   {
      "id":"1033111",
      "phuongxa":"Phường Hải Thành",
      "quanhuyen":"10331"
   },
   {
      "id":"1040315",
      "phuongxa":"Xã Quang Yên",
      "quanhuyen":"10403"
   },
   {
      "id":"1040357",
      "phuongxa":"Xã Xuân Lôi",
      "quanhuyen":"10403"
   },
   {
      "id":"1040525",
      "phuongxa":"Xã Hướng Đạo",
      "quanhuyen":"10405"
   },
   {
      "id":"1040725",
      "phuongxa":"Xã Thổ Tang",
      "quanhuyen":"10407"
   },
   {
      "id":"1014901",
      "phuongxa":"Thị trấn Vân Đình",
      "quanhuyen":"10149"
   },
   {
      "id":"1014951",
      "phuongxa":"Xã Phù Lưu",
      "quanhuyen":"10149"
   },
   {
      "id":"1015131",
      "phuongxa":"Xã Đốc Tín",
      "quanhuyen":"10151"
   },
   {
      "id":"1015333",
      "phuongxa":"Xã Tráng Việt",
      "quanhuyen":"10153"
   },
   {
      "id":"1015709",
      "phuongxa":"Phường Tương Mai",
      "quanhuyen":"10157"
   },
   {
      "id":"1030305",
      "phuongxa":"Phường Vạn Mỹ",
      "quanhuyen":"10303"
   },
   {
      "id":"1030523",
      "phuongxa":"Phường Niệm Nghĩa",
      "quanhuyen":"10305"
   },
   {
      "id":"1031113",
      "phuongxa":"Xã Lưu Kiếm",
      "quanhuyen":"10311"
   },
   {
      "id":"1031155",
      "phuongxa":"Xã Kiền Bái",
      "quanhuyen":"10311"
   },
   {
      "id":"1031700",
      "phuongxa":"Không xác định",
      "quanhuyen":"10317"
   },
   {
      "id":"1031743",
      "phuongxa":"Xã Đoàn Xá",
      "quanhuyen":"10317"
   },
   {
      "id":"1031931",
      "phuongxa":"Xã Tiên Minh",
      "quanhuyen":"10319"
   },
   {
      "id":"1032131",
      "phuongxa":"Xã Hưng Nhân",
      "quanhuyen":"10321"
   },
   {
      "id":"1013709",
      "phuongxa":"Xã Ngọc Liệp",
      "quanhuyen":"10137"
   },
   {
      "id":"1013907",
      "phuongxa":"Xã Lại Thượng",
      "quanhuyen":"10139"
   },
   {
      "id":"1014105",
      "phuongxa":"Xã Phụng Châu",
      "quanhuyen":"10141"
   },
   {
      "id":"1090607",
      "phuongxa":"Xã Dạ Trạch",
      "quanhuyen":"10906"
   },
   {
      "id":"1090647",
      "phuongxa":"Xã Thành Công",
      "quanhuyen":"10906"
   },
   {
      "id":"1090741",
      "phuongxa":"Xã Hạ Lễ",
      "quanhuyen":"10907"
   },
   {
      "id":"1091109",
      "phuongxa":"Xã Minh Hoàng",
      "quanhuyen":"10911"
   },
   {
      "id":"1091321",
      "phuongxa":"Xã Hải Triều",
      "quanhuyen":"10913"
   },
   {
      "id":"1091517",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"10915"
   },
   {
      "id":"1110323",
      "phuongxa":"Xã Yên Nam",
      "quanhuyen":"11103"
   },
   {
      "id":"1040929",
      "phuongxa":"Xã Đại Tự",
      "quanhuyen":"10409"
   },
   {
      "id":"1041101",
      "phuongxa":"Phường Trưng Trắc",
      "quanhuyen":"10411"
   },
   {
      "id":"1041533",
      "phuongxa":"Xã Thị trấn Tam Sơn",
      "quanhuyen":"10415"
   },
   {
      "id":"1070311",
      "phuongxa":"Xã Bắc An",
      "quanhuyen":"10703"
   },
   {
      "id":"1070505",
      "phuongxa":"Xã Nam Tân",
      "quanhuyen":"10705"
   },
   {
      "id":"1070543",
      "phuongxa":"Xã An Bình",
      "quanhuyen":"10705"
   },
   {
      "id":"1070739",
      "phuongxa":"Xã Hợp Đức",
      "quanhuyen":"10707"
   },
   {
      "id":"1070903",
      "phuongxa":"Xã Minh Hòa",
      "quanhuyen":"10709"
   },
   {
      "id":"1070943",
      "phuongxa":"Xã Duy Tân",
      "quanhuyen":"10709"
   },
   {
      "id":"1071129",
      "phuongxa":"Xã Kim Tân",
      "quanhuyen":"10711"
   },
   {
      "id":"1071327",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"10713"
   },
   {
      "id":"1071517",
      "phuongxa":"Xã Ngọc Kỳ",
      "quanhuyen":"10715"
   },
   {
      "id":"1071701",
      "phuongxa":"Thị trấn Cẩm Giàng",
      "quanhuyen":"10717"
   },
   {
      "id":"1071911",
      "phuongxa":"Xã Hùng Thắng",
      "quanhuyen":"10719"
   },
   {
      "id":"1072309",
      "phuongxa":"Xã Vĩnh Hòa",
      "quanhuyen":"10723"
   },
   {
      "id":"1072349",
      "phuongxa":"Xã Quang Hưng",
      "quanhuyen":"10723"
   },
   {
      "id":"1090301",
      "phuongxa":"Thị trấn Bần Yên Nhân",
      "quanhuyen":"10903"
   },
   {
      "id":"1090423",
      "phuongxa":"Xã Trung Hòa",
      "quanhuyen":"10904"
   },
   {
      "id":"1130107",
      "phuongxa":"Phường Vị Hoàng",
      "quanhuyen":"11301"
   },
   {
      "id":"1130307",
      "phuongxa":"Xã Trung Thành",
      "quanhuyen":"11303"
   },
   {
      "id":"1150911",
      "phuongxa":"Xã Đông Sơn",
      "quanhuyen":"11509"
   },
   {
      "id":"1150951",
      "phuongxa":"Xã Minh Châu",
      "quanhuyen":"11509"
   },
   {
      "id":"1151101",
      "phuongxa":"Thị trấn Vũ Thư",
      "quanhuyen":"11511"
   },
   {
      "id":"1151151",
      "phuongxa":"Xã Vũ Vinh",
      "quanhuyen":"11511"
   },
   {
      "id":"1151325",
      "phuongxa":"Xã Quyết Tiến",
      "quanhuyen":"11513"
   },
   {
      "id":"1151367",
      "phuongxa":"Xã Quang Hưng",
      "quanhuyen":"11513"
   },
   {
      "id":"1151535",
      "phuongxa":"Xã Tây Giang",
      "quanhuyen":"11515"
   },
   {
      "id":"1130737",
      "phuongxa":"Xã Yên Khánh",
      "quanhuyen":"11307"
   },
   {
      "id":"1130913",
      "phuongxa":"Xã Nam Toàn",
      "quanhuyen":"11309"
   },
   {
      "id":"1131115",
      "phuongxa":"Xã Cát Thành",
      "quanhuyen":"11311"
   },
   {
      "id":"1131315",
      "phuongxa":"Xã Xuân Kiên",
      "quanhuyen":"11313"
   },
   {
      "id":"1131515",
      "phuongxa":"Xã Giao Hà",
      "quanhuyen":"11315"
   },
   {
      "id":"1131705",
      "phuongxa":"Xã Nghĩa Đồng",
      "quanhuyen":"11317"
   },
   {
      "id":"1131905",
      "phuongxa":"Thị trấn Thịnh Long",
      "quanhuyen":"11319"
   },
   {
      "id":"1131929",
      "phuongxa":"Xã Hải Minh",
      "quanhuyen":"11319"
   },
   {
      "id":"1131969",
      "phuongxa":"Xã Hải Chính",
      "quanhuyen":"11319"
   },
   {
      "id":"1150309",
      "phuongxa":"Xã Quỳnh Ngọc",
      "quanhuyen":"11503"
   },
   {
      "id":"1150359",
      "phuongxa":"Xã An Vinh",
      "quanhuyen":"11503"
   },
   {
      "id":"1150523",
      "phuongxa":"Xã Liên Hiệp",
      "quanhuyen":"11505"
   },
   {
      "id":"1150565",
      "phuongxa":"Xã Đông Đô",
      "quanhuyen":"11505"
   },
   {
      "id":"1150735",
      "phuongxa":"Xã Thụy Quỳnh",
      "quanhuyen":"11507"
   },
   {
      "id":"1110513",
      "phuongxa":"Xã Hoàng Tây",
      "quanhuyen":"11105"
   },
   {
      "id":"1110711",
      "phuongxa":"Xã Công Lý",
      "quanhuyen":"11107"
   },
   {
      "id":"1110903",
      "phuongxa":"Xã Liêm Chung",
      "quanhuyen":"11109"
   },
   {
      "id":"1111101",
      "phuongxa":"Thị trấn Bình Mỹ",
      "quanhuyen":"11111"
   },
   {
      "id":"2030333",
      "phuongxa":"Xã Mông Ân",
      "quanhuyen":"20303"
   },
   {
      "id":"2030521",
      "phuongxa":"Xã Tổng Cọt",
      "quanhuyen":"20305"
   },
   {
      "id":"2030907",
      "phuongxa":"Xã Quang Hán",
      "quanhuyen":"20309"
   },
   {
      "id":"2031137",
      "phuongxa":"Xã Thân Giáp",
      "quanhuyen":"20311"
   },
   {
      "id":"2031337",
      "phuongxa":"Xã Hưng Đạo",
      "quanhuyen":"20313"
   },
   {
      "id":"2031539",
      "phuongxa":"Xã Bình Dương",
      "quanhuyen":"20315"
   },
   {
      "id":"1170100",
      "phuongxa":"Không xác định",
      "quanhuyen":"11701"
   },
   {
      "id":"1170509",
      "phuongxa":"Xã Gia Sơn",
      "quanhuyen":"11705"
   },
   {
      "id":"1170553",
      "phuongxa":"Xã Phú Long",
      "quanhuyen":"11705"
   },
   {
      "id":"1170731",
      "phuongxa":"Xã Gia Trung",
      "quanhuyen":"11707"
   },
   {
      "id":"1170929",
      "phuongxa":"Xã Ninh Vân",
      "quanhuyen":"11709"
   },
   {
      "id":"1171301",
      "phuongxa":"Thị trấn Yên Ninh",
      "quanhuyen":"11713"
   },
   {
      "id":"8259440",
      "phuongxa":"Xã Nghi Sơn",
      "quanhuyen":"82640"
   },
   {
      "id":"8259426",
      "phuongxa":"Xã Hòa Chánh",
      "quanhuyen":"81319"
   },
   {
      "id":"8259406",
      "phuongxa":"Cư Elang",
      "quanhuyen":"60515"
   },
   {
      "id":"8259415",
      "phuongxa":"Xã Mong Thọ",
      "quanhuyen":"81309"
   },
   {
      "id":"8259417",
      "phuongxa":"Tịnh Thiện",
      "quanhuyen":"50509"
   },
   {
      "id":"8259442",
      "phuongxa":"Xã Thuận Nghĩa",
      "quanhuyen":"50715"
   },
   {
      "id":"8259427",
      "phuongxa":"Can Lộc",
      "quanhuyen":"40523"
   },
   {
      "id":"8259441",
      "phuongxa":"Phường Hiiệp Hạnh",
      "quanhuyen":"82601"
   },
   {
      "id":"8259409",
      "phuongxa":"Xuân Đông",
      "quanhuyen":"71318"
   },
   {
      "id":"8259418",
      "phuongxa":"Xã Thới Thạnh",
      "quanhuyen":"81516"
   },
   {
      "id":"8259449",
      "phuongxa":"Võ Thị Sáu",
      "quanhuyen":"70105"
   },
   {
      "id":"8259435",
      "phuongxa":"Xã Sông Bờ",
      "quanhuyen":"60321"
   },
   {
      "id":"4030919",
      "phuongxa":"Xã Bảo Nam",
      "quanhuyen":"40309"
   },
   {
      "id":"4031113",
      "phuongxa":"Xã Liên Hợp",
      "quanhuyen":"40311"
   },
   {
      "id":"4031359",
      "phuongxa":"Xã Nghĩa Khánh",
      "quanhuyen":"40313"
   },
   {
      "id":"4091525",
      "phuongxa":"Xã Húc",
      "quanhuyen":"40915"
   },
   {
      "id":"4091715",
      "phuongxa":"Xã Tà Long",
      "quanhuyen":"40917"
   },
   {
      "id":"4110145",
      "phuongxa":"Xã Hương Long",
      "quanhuyen":"41101"
   },
   {
      "id":"4110503",
      "phuongxa":"Xã Quảng Thái",
      "quanhuyen":"41105"
   },
   {
      "id":"4110727",
      "phuongxa":"Xã Bình Điền",
      "quanhuyen":"41107"
   },
   {
      "id":"4110939",
      "phuongxa":"Xã Vinh An",
      "quanhuyen":"41109"
   },
   {
      "id":"4111307",
      "phuongxa":"Xã Lộc An",
      "quanhuyen":"41113"
   },
   {
      "id":"4051521",
      "phuongxa":"Xã Cẩm Thăng",
      "quanhuyen":"40515"
   },
   {
      "id":"4051713",
      "phuongxa":"Xã Hương Thọ",
      "quanhuyen":"40517"
   },
   {
      "id":"4051723",
      "phuongxa":"Xã Hòa Hải",
      "quanhuyen":"40517"
   },
   {
      "id":"4051905",
      "phuongxa":"Xã Kỳ Tiến",
      "quanhuyen":"40519"
   },
   {
      "id":"4051943",
      "phuongxa":"Xã Kỳ Thọ",
      "quanhuyen":"40519"
   },
   {
      "id":"4052117",
      "phuongxa":"Xã Hương Điền",
      "quanhuyen":"40521"
   },
   {
      "id":"4070113",
      "phuongxa":"Phường Hải Đình",
      "quanhuyen":"40701"
   },
   {
      "id":"4070329",
      "phuongxa":"Xã Cao Quảng",
      "quanhuyen":"40703"
   },
   {
      "id":"4070707",
      "phuongxa":"Xã Quảng Kim",
      "quanhuyen":"40707"
   },
   {
      "id":"4070757",
      "phuongxa":"Xã Quảng Minh",
      "quanhuyen":"82626"
   },
   {
      "id":"4070917",
      "phuongxa":"Xã Hưng Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4070959",
      "phuongxa":"Xã Lý Trạch",
      "quanhuyen":"40709"
   },
   {
      "id":"4071309",
      "phuongxa":"Xã Hoa Thủy",
      "quanhuyen":"40713"
   },
   {
      "id":"4090503",
      "phuongxa":"Thị trấn Bến Quan",
      "quanhuyen":"40905"
   },
   {
      "id":"4090707",
      "phuongxa":"Xã Trung Giang",
      "quanhuyen":"40907"
   },
   {
      "id":"4090915",
      "phuongxa":"Xã Cam Chính",
      "quanhuyen":"40909"
   },
   {
      "id":"4091131",
      "phuongxa":"Xã Triệu Trung",
      "quanhuyen":"40911"
   },
   {
      "id":"4091335",
      "phuongxa":"Xã Hải Tân",
      "quanhuyen":"40913"
   },
   {
      "id":"5011109",
      "phuongxa":"Xã Hòa Phát",
      "quanhuyen":"50111"
   },
   {
      "id":"5030131",
      "phuongxa":"Xã Tam Dân",
      "quanhuyen":"50326"
   },
   {
      "id":"5030707",
      "phuongxa":"Xã Đại Hồng",
      "quanhuyen":"50307"
   },
   {
      "id":"5051705",
      "phuongxa":"Xã Hành Dũng",
      "quanhuyen":"50517"
   },
   {
      "id":"5052113",
      "phuongxa":"Xã Đức Minh",
      "quanhuyen":"50521"
   },
   {
      "id":"5052325",
      "phuongxa":"Xã Phổ Thạnh",
      "quanhuyen":"50523"
   },
   {
      "id":"5052535",
      "phuongxa":"Xã Ba Liên",
      "quanhuyen":"50525"
   },
   {
      "id":"5070121",
      "phuongxa":"Phường Lê Lợi",
      "quanhuyen":"50701"
   },
   {
      "id":"5070505",
      "phuongxa":"Xã Hoài Sơn",
      "quanhuyen":"50705"
   },
   {
      "id":"5070709",
      "phuongxa":"Xã Dak Mang",
      "quanhuyen":"50707"
   },
   {
      "id":"5030915",
      "phuongxa":"Xã Điện Phước",
      "quanhuyen":"50309"
   },
   {
      "id":"5031531",
      "phuongxa":"Xã Bình Sa",
      "quanhuyen":"50315"
   },
   {
      "id":"5031725",
      "phuongxa":"Xã Quế Minh",
      "quanhuyen":"50317"
   },
   {
      "id":"5032109",
      "phuongxa":"Xã Tiên Châu",
      "quanhuyen":"50321"
   },
   {
      "id":"5032509",
      "phuongxa":"Xã Tam Sơn",
      "quanhuyen":"50325"
   },
   {
      "id":"5032713",
      "phuongxa":"Xã Tr'Hy",
      "quanhuyen":"50327"
   },
   {
      "id":"5032917",
      "phuongxa":"Xã Za Hung",
      "quanhuyen":"50329"
   },
   {
      "id":"5033313",
      "phuongxa":"Xã Trà Bui",
      "quanhuyen":"50333"
   },
   {
      "id":"5033703",
      "phuongxa":"Xã Quế Trung",
      "quanhuyen":"50337"
   },
   {
      "id":"5050500",
      "phuongxa":"Không xác định",
      "quanhuyen":"50505"
   },
   {
      "id":"5050543",
      "phuongxa":"Xã Bình Mỹ",
      "quanhuyen":"50505"
   },
   {
      "id":"5050737",
      "phuongxa":"Xã Trà Xinh",
      "quanhuyen":"50507"
   },
   {
      "id":"8259401",
      "phuongxa":"Thị Trấn Bù Gia Mập",
      "quanhuyen":"82620"
   },
   {
      "id":"8259411",
      "phuongxa":"Xã Không xác Định",
      "quanhuyen":"82613"
   },
   {
      "id":"8259419",
      "phuongxa":"Nam Đà",
      "quanhuyen":"60529"
   },
   {
      "id":"8259420",
      "phuongxa":"Lạc An",
      "quanhuyen":"71105"
   },
   {
      "id":"8259402",
      "phuongxa":"Thi Trấn IALY",
      "quanhuyen":"60307"
   },
   {
      "id":"8259404",
      "phuongxa":"Xã Cây Gáo",
      "quanhuyen":"71302"
   },
   {
      "id":"5050935",
      "phuongxa":"Xã Tịnh Sơn",
      "quanhuyen":"50509"
   },
   {
      "id":"5051321",
      "phuongxa":"Xã Sơn Hải",
      "quanhuyen":"50513"
   },
   {
      "id":"4111500",
      "phuongxa":"Không xác định",
      "quanhuyen":"41115"
   },
   {
      "id":"4111709",
      "phuongxa":"Xã Hương Hữu",
      "quanhuyen":"41117"
   },
   {
      "id":"5010300",
      "phuongxa":"Không xác định",
      "quanhuyen":"50103"
   },
   {
      "id":"5111709",
      "phuongxa":"Xã Sơn Tân",
      "quanhuyen":"51117"
   },
   {
      "id":"6010123",
      "phuongxa":"Xã Chư Hreng",
      "quanhuyen":"60101"
   },
   {
      "id":"6010311",
      "phuongxa":"Xã Đắk Choong",
      "quanhuyen":"60103"
   },
   {
      "id":"6010713",
      "phuongxa":"Xã Tu Mơ Rông",
      "quanhuyen":"60107"
   },
   {
      "id":"6011100",
      "phuongxa":"Không xác định",
      "quanhuyen":"60111"
   },
   {
      "id":"6030113",
      "phuongxa":"Xã Trà Bá",
      "quanhuyen":"60301"
   },
   {
      "id":"6030321",
      "phuongxa":"Xã Kông Lơng Khơng",
      "quanhuyen":"60303"
   },
   {
      "id":"5070915",
      "phuongxa":"Xã Mỹ Phong",
      "quanhuyen":"50709"
   },
   {
      "id":"5071309",
      "phuongxa":"Xã Cát Khánh",
      "quanhuyen":"50713"
   },
   {
      "id":"5071513",
      "phuongxa":"Xã Tây An",
      "quanhuyen":"50715"
   },
   {
      "id":"5071717",
      "phuongxa":"Phường Nhơn Hưng",
      "quanhuyen":"50717"
   },
   {
      "id":"5072111",
      "phuongxa":"Xã Canh Hòa",
      "quanhuyen":"50721"
   },
   {
      "id":"5090131",
      "phuongxa":"Xã Bình Ngọc",
      "quanhuyen":"50911"
   },
   {
      "id":"5090517",
      "phuongxa":"Xã Xuân Thọ 1",
      "quanhuyen":"50905"
   },
   {
      "id":"5090901",
      "phuongxa":"Thị trấn Củng Sơn",
      "quanhuyen":"50909"
   },
   {
      "id":"5091137",
      "phuongxa":"Xã Hòa Xuân Tây",
      "quanhuyen":"50911"
   },
   {
      "id":"5091517",
      "phuongxa":"Thị trấn Phú Hoà",
      "quanhuyen":"50915"
   },
   {
      "id":"5110101",
      "phuongxa":"Phường Vĩnh Hải",
      "quanhuyen":"51101"
   },
   {
      "id":"5110135",
      "phuongxa":"Xã Vĩnh Lương",
      "quanhuyen":"51101"
   },
   {
      "id":"5110323",
      "phuongxa":"Xã Xuân Sơn",
      "quanhuyen":"51103"
   },
   {
      "id":"5110535",
      "phuongxa":"Xã Ninh Phú",
      "quanhuyen":"51105"
   },
   {
      "id":"5110725",
      "phuongxa":"Xã Diên An",
      "quanhuyen":"51107"
   },
   {
      "id":"5110917",
      "phuongxa":"Xã Cam Hiệp Nam",
      "quanhuyen":"51109"
   },
   {
      "id":"5111117",
      "phuongxa":"Xã Cầu Bà",
      "quanhuyen":"51111"
   },
   {
      "id":"6031725",
      "phuongxa":"Xã Ia Lâu",
      "quanhuyen":"60317"
   },
   {
      "id":"6032109",
      "phuongxa":"Xã Chư Răng",
      "quanhuyen":"60321"
   },
   {
      "id":"6032117",
      "phuongxa":"Xã  Ia MRơn",
      "quanhuyen":"60321"
   },
   {
      "id":"6032317",
      "phuongxa":"Xã Ia HDreh",
      "quanhuyen":"60323"
   },
   {
      "id":"6032523",
      "phuongxa":"Xã A Dơk",
      "quanhuyen":"60325"
   },
   {
      "id":"7010507",
      "phuongxa":"Phường 4",
      "quanhuyen":"70105"
   },
   {
      "id":"7010717",
      "phuongxa":"Phường 10",
      "quanhuyen":"70107"
   },
   {
      "id":"7010923",
      "phuongxa":"Phường 12",
      "quanhuyen":"70109"
   },
   {
      "id":"7011300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70113"
   },
   {
      "id":"7011521",
      "phuongxa":"Phường 11",
      "quanhuyen":"70115"
   },
   {
      "id":"7012103",
      "phuongxa":"Phường 2",
      "quanhuyen":"70121"
   },
   {
      "id":"7012305",
      "phuongxa":"Phường An Phú Đông",
      "quanhuyen":"70123"
   },
   {
      "id":"6032913",
      "phuongxa":"Xã Ia Ma Rơn",
      "quanhuyen":"60329"
   },
   {
      "id":"6050119",
      "phuongxa":"Phường Tân Tiến",
      "quanhuyen":"60501"
   },
   {
      "id":"6050315",
      "phuongxa":"Xã Ea Khal",
      "quanhuyen":"60503"
   },
   {
      "id":"6050513",
      "phuongxa":"Xã Ia Lốp",
      "quanhuyen":"60505"
   },
   {
      "id":"6050913",
      "phuongxa":"Xã Ea Drông",
      "quanhuyen":"60509"
   },
   {
      "id":"6051315",
      "phuongxa":"Xã Ea Tul",
      "quanhuyen":"60513"
   },
   {
      "id":"6051929",
      "phuongxa":"Xã Ea Uy",
      "quanhuyen":"60519"
   },
   {
      "id":"6052117",
      "phuongxa":"Xã Trúc Sơn",
      "quanhuyen":"60521"
   },
   {
      "id":"6052317",
      "phuongxa":"Xã Ea Bông",
      "quanhuyen":"60523"
   },
   {
      "id":"6052509",
      "phuongxa":"Xã Hòa Tân",
      "quanhuyen":"60525"
   },
   {
      "id":"6053101",
      "phuongxa":"Thị trấn Liên Sơn",
      "quanhuyen":"60531"
   },
   {
      "id":"6053303",
      "phuongxa":"Xã Đắk Búk So",
      "quanhuyen":"60533"
   },
   {
      "id":"6053703",
      "phuongxa":"Phường An Lạc",
      "quanhuyen":"60537"
   },
   {
      "id":"7030317",
      "phuongxa":"Xã Lộc Nga",
      "quanhuyen":"70303"
   },
   {
      "id":"7030711",
      "phuongxa":"Xã Quảng Lập",
      "quanhuyen":"70307"
   },
   {
      "id":"7030913",
      "phuongxa":"Xã Phú Hội",
      "quanhuyen":"70309"
   },
   {
      "id":"8010105",
      "phuongxa":"Phường 3",
      "quanhuyen":"80101"
   },
   {
      "id":"8010315",
      "phuongxa":"Xã Vĩnh Châu B",
      "quanhuyen":"80103"
   },
   {
      "id":"8010705",
      "phuongxa":"Xã Bình Hiệp",
      "quanhuyen":"80107"
   },
   {
      "id":"8010923",
      "phuongxa":"Xã Tân Bình",
      "quanhuyen":"80109"
   },
   {
      "id":"8110123",
      "phuongxa":"Xã Phú Hưng",
      "quanhuyen":"81101"
   },
   {
      "id":"8110337",
      "phuongxa":"Xã An Phước",
      "quanhuyen":"81103"
   },
   {
      "id":"7031111",
      "phuongxa":"Xã Phi Liêng",
      "quanhuyen":"70311"
   },
   {
      "id":"7031300",
      "phuongxa":"Không xác định",
      "quanhuyen":"70313"
   },
   {
      "id":"7031321",
      "phuongxa":"Xã Lộc Thành",
      "quanhuyen":"70313"
   },
   {
      "id":"7031515",
      "phuongxa":"Xã Đinh Trang Hòa",
      "quanhuyen":"70315"
   },
   {
      "id":"6052301",
      "phuongxa":"Thị trấn Buôn Trấp",
      "quanhuyen":"60523"
   },
   {
      "id":"6052503",
      "phuongxa":"Xã Dang Kang",
      "quanhuyen":"60525"
   },
   {
      "id":"6052717",
      "phuongxa":"Xã Thuận Hạnh",
      "quanhuyen":"60527"
   },
   {
      "id":"6053109",
      "phuongxa":"Xã Buôn Triết",
      "quanhuyen":"60531"
   },
   {
      "id":"6070509",
      "phuongxa":"Phường Nghĩa Trung",
      "quanhuyen":"60705"
   },
   {
      "id":"7010109",
      "phuongxa":"Phường Nguyễn Thái Bình",
      "quanhuyen":"70101"
   },
   {
      "id":"6030519",
      "phuongxa":"Xã Kon Dơng",
      "quanhuyen":"60305"
   },
   {
      "id":"6030543",
      "phuongxa":"Xã Kon Chiêng",
      "quanhuyen":"60305"
   },
   {
      "id":"6030719",
      "phuongxa":"Xã Nghĩa Hòa",
      "quanhuyen":"60307"
   },
   {
      "id":"6031111",
      "phuongxa":"Xã Song An",
      "quanhuyen":"60311"
   },
   {
      "id":"6031305",
      "phuongxa":"Xã An Trung",
      "quanhuyen":"60313"
   },
   {
      "id":"6031503",
      "phuongxa":"Xã Ia Dơk",
      "quanhuyen":"60315"
   },
   {
      "id":"6031701",
      "phuongxa":"Thị trấn Chư Prông",
      "quanhuyen":"60317"
   },
   {
      "id":"7131337",
      "phuongxa":"Xã Xuân Bảo",
      "quanhuyen":"71313"
   },
   {
      "id":"7131513",
      "phuongxa":"Xã An Phước",
      "quanhuyen":"71315"
   },
   {
      "id":"7131802",
      "phuongxa":"Sơn Đông",
      "quanhuyen":"71318"
   },
   {
      "id":"7150111",
      "phuongxa":"Phường Bình Hưng",
      "quanhuyen":"71501"
   },
   {
      "id":"7150303",
      "phuongxa":"Thị trấn Phan Rí Cửa",
      "quanhuyen":"71503"
   },
   {
      "id":"8070929",
      "phuongxa":"Xã Nhị Quý",
      "quanhuyen":"80709"
   },
   {
      "id":"8070949",
      "phuongxa":"Xã Hội Xuân",
      "quanhuyen":"82625"
   },
   {
      "id":"8071111",
      "phuongxa":"Xã Phú Kiết",
      "quanhuyen":"80711"
   },
   {
      "id":"8071305",
      "phuongxa":"Xã Hậu Mỹ Bắc A",
      "quanhuyen":"80713"
   },
   {
      "id":"8071329",
      "phuongxa":"Xã Mỹ Đức Đông",
      "quanhuyen":"80713"
   },
   {
      "id":"8071500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80715"
   },
   {
      "id":"8071520",
      "phuongxa":"TT vĩnh bình",
      "quanhuyen":"80715"
   },
   {
      "id":"8210707",
      "phuongxa":"Xã Phong Thạnh Đông",
      "quanhuyen":"82107"
   },
   {
      "id":"8210729",
      "phuongxa":"Xã Định Thành",
      "quanhuyen":"82107"
   },
   {
      "id":"8230303",
      "phuongxa":"Xã Biển Bạch Đông",
      "quanhuyen":"82303"
   },
   {
      "id":"8230507",
      "phuongxa":"Xã Khánh Tiến",
      "quanhuyen":"82305"
   },
   {
      "id":"8230709",
      "phuongxa":"Xã Khánh Bình Tây",
      "quanhuyen":"82307"
   },
   {
      "id":"7012515",
      "phuongxa":"Phường 12",
      "quanhuyen":"70125"
   },
   {
      "id":"7013307",
      "phuongxa":"Phường Tam Phú",
      "quanhuyen":"769"
   },
   {
      "id":"7013501",
      "phuongxa":"Thị trấn Củ Chi",
      "quanhuyen":"70135"
   },
   {
      "id":"7150521",
      "phuongxa":"Xã Lương Sơn",
      "quanhuyen":"71505"
   },
   {
      "id":"7150705",
      "phuongxa":"Xã Thuận Hòa",
      "quanhuyen":"71507"
   },
   {
      "id":"7150907",
      "phuongxa":"Xã Mương Mán",
      "quanhuyen":"71509"
   },
   {
      "id":"7151101",
      "phuongxa":"Xã Lạc Tánh",
      "quanhuyen":"71511"
   },
   {
      "id":"7151123",
      "phuongxa":"Xã Đức Phú",
      "quanhuyen":"71511"
   },
   {
      "id":"7151315",
      "phuongxa":"Xã Tân Xuân",
      "quanhuyen":"71513"
   },
   {
      "id":"8071529",
      "phuongxa":"Xã Tân Phú",
      "quanhuyen":"80715"
   },
   {
      "id":"8071715",
      "phuongxa":"Xã Kiểng Phước",
      "quanhuyen":"80717"
   },
   {
      "id":"8071882",
      "phuongxa":"Xã Phú Thạnh",
      "quanhuyen":"80718"
   },
   {
      "id":"8090307",
      "phuongxa":"Xã Bình Hòa Phước",
      "quanhuyen":"80903"
   },
   {
      "id":"8090500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80905"
   },
   {
      "id":"8090525",
      "phuongxa":"Xã Tân Long Hội",
      "quanhuyen":"80905"
   },
   {
      "id":"8090717",
      "phuongxa":"Xã Thành Đông",
      "quanhuyen":"80907"
   },
   {
      "id":"8230923",
      "phuongxa":"Xã Trần Thới",
      "quanhuyen":"82309"
   },
   {
      "id":"8231115",
      "phuongxa":"Xã Tân Tiến",
      "quanhuyen":"82311"
   },
   {
      "id":"8250201",
      "phuongxa":"Phường Lái Hiếu",
      "quanhuyen":"82502"
   },
   {
      "id":"8258504",
      "phuongxa":"Vĩnh Thuận Tây",
      "quanhuyen":"82585"
   },
   {
      "id":"7013503",
      "phuongxa":"Xã Phú Hòa Đông",
      "quanhuyen":"70135"
   },
   {
      "id":"7013521",
      "phuongxa":"Xã Thái Mỹ",
      "quanhuyen":"70135"
   },
   {
      "id":"7013539",
      "phuongxa":"Xã Phước Hiệp",
      "quanhuyen":"70135"
   },
   {
      "id":"7013709",
      "phuongxa":"Xã Đông Thạnh",
      "quanhuyen":"70137"
   },
   {
      "id":"7013907",
      "phuongxa":"Xã Đa Phước",
      "quanhuyen":"70139"
   },
   {
      "id":"7014107",
      "phuongxa":"Xã Phước Lộc",
      "quanhuyen":"70141"
   },
   {
      "id":"7014309",
      "phuongxa":"Xã Tam Thôn Hiệp",
      "quanhuyen":"70143"
   },
   {
      "id":"7151517",
      "phuongxa":"Xã Đức Tài",
      "quanhuyen":"71515"
   },
   {
      "id":"7151903",
      "phuongxa":"Phường Phước Lộc",
      "quanhuyen":"71519"
   },
   {
      "id":"7170115",
      "phuongxa":"Phường 8",
      "quanhuyen":"71701"
   },
   {
      "id":"7170309",
      "phuongxa":"Phường Phước Trung",
      "quanhuyen":"71703"
   },
   {
      "id":"7170523",
      "phuongxa":"Xã Nghĩa Thành",
      "quanhuyen":"71705"
   },
   {
      "id":"7170717",
      "phuongxa":"Xã Hòa Hiệp",
      "quanhuyen":"71707"
   },
   {
      "id":"8090719",
      "phuongxa":"Xã Đông Bình",
      "quanhuyen":"80907"
   },
   {
      "id":"8090900",
      "phuongxa":"Không xác định",
      "quanhuyen":"80909"
   },
   {
      "id":"8090921",
      "phuongxa":"Xã Mỹ Thạnh Trung",
      "quanhuyen":"80909"
   },
   {
      "id":"8091111",
      "phuongxa":"Xã Hựu Thành",
      "quanhuyen":"80911"
   },
   {
      "id":"8091321",
      "phuongxa":"Xã Trung Thành Đông",
      "quanhuyen":"80913"
   },
   {
      "id":"8091501",
      "phuongxa":"Xã Nguyễn Văn Thảnh",
      "quanhuyen":"80915"
   },
   {
      "id":"7014513",
      "phuongxa":"Sơn Kỳ",
      "quanhuyen":"70145"
   },
   {
      "id":"7014705",
      "phuongxa":"Bình Hưng Hoà A",
      "quanhuyen":"70147"
   },
   {
      "id":"7030100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70301"
   },
   {
      "id":"7031500",
      "phuongxa":"Không xác định",
      "quanhuyen":"70315"
   },
   {
      "id":"7031913",
      "phuongxa":"Xã Hương Lâm",
      "quanhuyen":"70319"
   },
   {
      "id":"7032109",
      "phuongxa":"Xã Nam Ninh",
      "quanhuyen":"70321"
   },
   {
      "id":"8011115",
      "phuongxa":"Xã Thuận Nghĩa Hòa",
      "quanhuyen":"80111"
   },
   {
      "id":"8011313",
      "phuongxa":"Xã Mỹ Thạnh Bắc",
      "quanhuyen":"80113"
   },
   {
      "id":"8011511",
      "phuongxa":"Xã An Ninh Tây",
      "quanhuyen":"80115"
   },
   {
      "id":"8011529",
      "phuongxa":"Xã Hựu Thạnh",
      "quanhuyen":"80115"
   },
   {
      "id":"8011719",
      "phuongxa":"Xã Nhựt Chánh",
      "quanhuyen":"80117"
   },
   {
      "id":"8011909",
      "phuongxa":"Xã Mỹ Thạnh",
      "quanhuyen":"80119"
   },
   {
      "id":"8012107",
      "phuongxa":"Xã Vĩnh Công",
      "quanhuyen":"80121"
   },
   {
      "id":"8259443",
      "phuongxa":"Phường Đoàn Kết",
      "quanhuyen":"60321"
   },
   {
      "id":"8259408",
      "phuongxa":"Phường Phúc Diễn",
      "quanhuyen":"82633"
   },
   {
      "id":"8110900",
      "phuongxa":"Không xác định",
      "quanhuyen":"81109"
   },
   {
      "id":"8110921",
      "phuongxa":"Xã Lương Hòa",
      "quanhuyen":"81109"
   },
   {
      "id":"8110941",
      "phuongxa":"Xã Hưng Lễ",
      "quanhuyen":"81109"
   },
   {
      "id":"8111300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81113"
   },
   {
      "id":"8111323",
      "phuongxa":"Xã An Bình Tây",
      "quanhuyen":"81113"
   },
   {
      "id":"8111500",
      "phuongxa":"Không xác định",
      "quanhuyen":"81115"
   },
   {
      "id":"7050000",
      "phuongxa":"Không xác định",
      "quanhuyen":"70500"
   },
   {
      "id":"7050123",
      "phuongxa":"Xã Đông Hải",
      "quanhuyen":"70501"
   },
   {
      "id":"7050315",
      "phuongxa":"Xã Phước Đại",
      "quanhuyen":"70503"
   },
   {
      "id":"7050700",
      "phuongxa":"Không xác định",
      "quanhuyen":"70507"
   },
   {
      "id":"7050721",
      "phuongxa":"Xã Phước Nam",
      "quanhuyen":"70507"
   },
   {
      "id":"7050911",
      "phuongxa":"Xã Phước Thành",
      "quanhuyen":"70509"
   },
   {
      "id":"8012300",
      "phuongxa":"Không xác định",
      "quanhuyen":"80123"
   },
   {
      "id":"8012321",
      "phuongxa":"Xã Nhựt Ninh",
      "quanhuyen":"80123"
   },
   {
      "id":"8012517",
      "phuongxa":"Xã Tân Trạch",
      "quanhuyen":"80125"
   },
   {
      "id":"8012727",
      "phuongxa":"Xã Long Phụng",
      "quanhuyen":"80127"
   },
   {
      "id":"8030117",
      "phuongxa":"Xã Mỹ Trà",
      "quanhuyen":"80301"
   },
   {
      "id":"8111503",
      "phuongxa":"Xã Phú Khánh",
      "quanhuyen":"81115"
   },
   {
      "id":"8111525",
      "phuongxa":"Xã An Quy",
      "quanhuyen":"81115"
   },
   {
      "id":"8130101",
      "phuongxa":"Phường Vĩnh Thanh Vân",
      "quanhuyen":"81301"
   },
   {
      "id":"8130509",
      "phuongxa":"Xã Mỹ Hiệp Sơn",
      "quanhuyen":"81305"
   },
   {
      "id":"8131101",
      "phuongxa":"Thị trấn Giồng Riềng",
      "quanhuyen":"81311"
   },
   {
      "id":"8131125",
      "phuongxa":"Xã  Hòa Thuận",
      "quanhuyen":"81311"
   },
   {
      "id":"7051101",
      "phuongxa":"Xã Phước Chiến",
      "quanhuyen":"70511"
   },
   {
      "id":"7070105",
      "phuongxa":"Xã Đồng Tâm",
      "quanhuyen":"70701"
   },
   {
      "id":"7070305",
      "phuongxa":"Xã Đak ơ",
      "quanhuyen":"70703"
   },
   {
      "id":"7070323",
      "phuongxa":"Xã Long Hà",
      "quanhuyen":"70703"
   },
   {
      "id":"7070703",
      "phuongxa":"Xã Đak Nhau",
      "quanhuyen":"70707"
   },
   {
      "id":"7070900",
      "phuongxa":"Không xác định",
      "quanhuyen":"70709"
   },
   {
      "id":"8030501",
      "phuongxa":"Thị trấn Sa Rài",
      "quanhuyen":"80305"
   },
   {
      "id":"8030701",
      "phuongxa":"Thị trấn Hồng Ngự",
      "quanhuyen":"80307"
   },
   {
      "id":"8030719",
      "phuongxa":"Xã Thường Lạc",
      "quanhuyen":"80307"
   },
   {
      "id":"8030900",
      "phuongxa":"Không xác định",
      "quanhuyen":"80309"
   },
   {
      "id":"8031121",
      "phuongxa":"Xã Tân Hòa",
      "quanhuyen":"80311"
   },
   {
      "id":"8031311",
      "phuongxa":"Xã Đốc Binh Kiều",
      "quanhuyen":"80313"
   },
   {
      "id":"8131313",
      "phuongxa":"Xã Vĩnh Hòa Hưng Nam",
      "quanhuyen":"81313"
   },
   {
      "id":"8131517",
      "phuongxa":"Xã Đông Yên",
      "quanhuyen":"81315"
   },
   {
      "id":"8131713",
      "phuongxa":"Xã Đông Hưng A",
      "quanhuyen":"81317"
   },
   {
      "id":"8132505",
      "phuongxa":"Phường Tô Châu",
      "quanhuyen":"81325"
   },
   {
      "id":"8150301",
      "phuongxa":"Phường Thốt Nốt",
      "quanhuyen":"81503"
   },
   {
      "id":"7070917",
      "phuongxa":"Xã Phước An",
      "quanhuyen":"70709"
   },
   {
      "id":"7070937",
      "phuongxa":"Xã Minh Long",
      "quanhuyen":"70709"
   },
   {
      "id":"7071117",
      "phuongxa":"Xã Thuận Phú",
      "quanhuyen":"70711"
   },
   {
      "id":"7090301",
      "phuongxa":"Thị trấn Tân Biên",
      "quanhuyen":"70903"
   },
   {
      "id":"7090505",
      "phuongxa":"Xã Tân Đông",
      "quanhuyen":"70905"
   },
   {
      "id":"7090701",
      "phuongxa":"Thị trấn Dương Minh Châu",
      "quanhuyen":"70907"
   },
   {
      "id":"8031525",
      "phuongxa":"Xã Mỹ Long",
      "quanhuyen":"80315"
   },
   {
      "id":"8031707",
      "phuongxa":"Xã Định Yên",
      "quanhuyen":"80317"
   },
   {
      "id":"8031723",
      "phuongxa":"Xã Hội An Đông",
      "quanhuyen":"80317"
   },
   {
      "id":"8259424",
      "phuongxa":"Xã Thanh Châu",
      "quanhuyen":"10143"
   },
   {
      "id":"8259399",
      "phuongxa":"Xã Hòa Xuân",
      "quanhuyen":"60501"
   },
   {
      "id":"8259397",
      "phuongxa":"xã Ea Sin",
      "quanhuyen":"60509"
   },
   {
      "id":"8032105",
      "phuongxa":"Xã Tân Phú Trung",
      "quanhuyen":"80321"
   },
   {
      "id":"8032301",
      "phuongxa":"Phường An Lộc",
      "quanhuyen":"80323"
   },
   {
      "id":"8050103",
      "phuongxa":"Phường Mỹ Long",
      "quanhuyen":"80501"
   },
   {
      "id":"8150331",
      "phuongxa":"Xã Phước Lộc",
      "quanhuyen":"81503"
   },
   {
      "id":"8150517",
      "phuongxa":"Phường Trường Lạc",
      "quanhuyen":"81505"
   },
   {
      "id":"8151417",
      "phuongxa":"Thị Trấn Thới Lai",
      "quanhuyen":"81514"
   },
   {
      "id":"8151819",
      "phuongxa":"Phường Trà Nóc",
      "quanhuyen":"81518"
   },
   {
      "id":"815201",
      "phuongxa":"Phường An Khánh",
      "quanhuyen":"81520"
   },
   {
      "id":"8170307",
      "phuongxa":"Xã Huyền Hội",
      "quanhuyen":"81703"
   },
   {
      "id":"7090713",
      "phuongxa":"Xã Cầu Khởi",
      "quanhuyen":"70907"
   },
   {
      "id":"7090907",
      "phuongxa":"Xã Biên Giới",
      "quanhuyen":"70909"
   },
   {
      "id":"7091100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70911"
   },
   {
      "id":"7091505",
      "phuongxa":"Xã Bàu Đồn",
      "quanhuyen":"70915"
   },
   {
      "id":"7091701",
      "phuongxa":"Thị trấn Trảng Bàng",
      "quanhuyen":"70917"
   },
   {
      "id":"7110101",
      "phuongxa":"Phường Phú Cường",
      "quanhuyen":"71101"
   },
   {
      "id":"8050126",
      "phuongxa":"Phường Bình Hòa",
      "quanhuyen":"80501"
   },
   {
      "id":"8050500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80505"
   },
   {
      "id":"8050523",
      "phuongxa":"Xã Vĩnh Hội Đông",
      "quanhuyen":"80505"
   },
   {
      "id":"8050903",
      "phuongxa":"Thị trấn Chợ Vàm",
      "quanhuyen":"80509"
   },
   {
      "id":"8050925",
      "phuongxa":"Xã Phú Bình",
      "quanhuyen":"80509"
   },
   {
      "id":"8051109",
      "phuongxa":"Xã Ô Long Vỹ",
      "quanhuyen":"80511"
   },
   {
      "id":"8051127",
      "phuongxa":"Xã Bình Đông",
      "quanhuyen":"80511"
   },
   {
      "id":"8170509",
      "phuongxa":"Xã Song Lộc",
      "quanhuyen":"81705"
   },
   {
      "id":"8170701",
      "phuongxa":"Thị trấn Cầu Kè",
      "quanhuyen":"81707"
   },
   {
      "id":"8170900",
      "phuongxa":"Không xác định",
      "quanhuyen":"81709"
   },
   {
      "id":"8171109",
      "phuongxa":"Xã Vinh Kim",
      "quanhuyen":"81711"
   },
   {
      "id":"8171300",
      "phuongxa":"Không xác định",
      "quanhuyen":"81713"
   },
   {
      "id":"8171321",
      "phuongxa":"Xã Đôn Châu",
      "quanhuyen":"81713"
   },
   {
      "id":"8171505",
      "phuongxa":"Xã Long Hữu",
      "quanhuyen":"81715"
   },
   {
      "id":"7110301",
      "phuongxa":"Phường Mỹ Phước",
      "quanhuyen":"71103"
   },
   {
      "id":"7110325",
      "phuongxa":"Xã An Lập",
      "quanhuyen":"71103"
   },
   {
      "id":"7110513",
      "phuongxa":"Phường Tân Hiệp",
      "quanhuyen":"82627"
   },
   {
      "id":"7110539",
      "phuongxa":"Xã Phú Chánh",
      "quanhuyen":"82627"
   },
   {
      "id":"7110707",
      "phuongxa":"Phường Bình Chuẩn",
      "quanhuyen":"71107"
   },
   {
      "id":"8051307",
      "phuongxa":"Xã An Phú",
      "quanhuyen":"80513"
   },
   {
      "id":"8051500",
      "phuongxa":"Không xác định",
      "quanhuyen":"80515"
   },
   {
      "id":"8051729",
      "phuongxa":"Xã Hội An",
      "quanhuyen":"80517"
   },
   {
      "id":"8051911",
      "phuongxa":"Xã Vĩnh Bình",
      "quanhuyen":"80519"
   },
   {
      "id":"8052103",
      "phuongxa":"Xã Tây Phú",
      "quanhuyen":"80521"
   },
   {
      "id":"8052123",
      "phuongxa":"Xã Thoại Giang",
      "quanhuyen":"80521"
   },
   {
      "id":"8171517",
      "phuongxa":"Xã Đông Hải",
      "quanhuyen":"81715"
   },
   {
      "id":"8190305",
      "phuongxa":"Xã Nhơn Mỹ",
      "quanhuyen":"81903"
   },
   {
      "id":"8190529",
      "phuongxa":"Xã An Thạnh 1",
      "quanhuyen":"81905"
   },
   {
      "id":"8190709",
      "phuongxa":"Xã Thuận Hưng",
      "quanhuyen":"81907"
   },
   {
      "id":"8190731",
      "phuongxa":"Xã An Ninh",
      "quanhuyen":"81907"
   },
   {
      "id":"7110729",
      "phuongxa":"Phường Vĩnh Phú",
      "quanhuyen":"71107"
   },
   {
      "id":"7110921",
      "phuongxa":"Xã Thanh An",
      "quanhuyen":"71109"
   },
   {
      "id":"7130127",
      "phuongxa":"Phường Tân Hiệp",
      "quanhuyen":"71301"
   },
   {
      "id":"7130151",
      "phuongxa":"Phường Hiệp Hòa",
      "quanhuyen":"71301"
   },
   {
      "id":"7130321",
      "phuongxa":"Xã Phú Trung",
      "quanhuyen":"71303"
   },
   {
      "id":"7130503",
      "phuongxa":"Xã Thanh Sơn",
      "quanhuyen":"71305"
   },
   {
      "id":"8070125",
      "phuongxa":"Phường Tân Long",
      "quanhuyen":"80701"
   },
   {
      "id":"8070701",
      "phuongxa":"Thị trấn Tân Hiệp",
      "quanhuyen":"80707"
   },
   {
      "id":"8070723",
      "phuongxa":"Xã Long Định",
      "quanhuyen":"80707"
   },
   {
      "id":"8070745",
      "phuongxa":"Xã Kim Sơn",
      "quanhuyen":"80707"
   },
   {
      "id":"8070913",
      "phuongxa":"Xã Mỹ Hạnh Trung",
      "quanhuyen":"80709"
   },
   {
      "id":"8190925",
      "phuongxa":"Xã Gia Hòa 1",
      "quanhuyen":"81909"
   },
   {
      "id":"8191111",
      "phuongxa":"Xã Thạnh Trị",
      "quanhuyen":"81911"
   },
   {
      "id":"8191801",
      "phuongxa":"Xã An Thạnh Nam",
      "quanhuyen":"81918"
   },
   {
      "id":"8210303",
      "phuongxa":"Thị trấn Ngan Dừa",
      "quanhuyen":"82103"
   },
   {
      "id":"8210325",
      "phuongxa":"Xã Phong Thạnh Nam",
      "quanhuyen":"82103"
   },
   {
      "id":"7130507",
      "phuongxa":"Xã Phú Vinh",
      "quanhuyen":"71305"
   },
   {
      "id":"7130700",
      "phuongxa":"Không xác định",
      "quanhuyen":"71307"
   },
   {
      "id":"7130933",
      "phuongxa":"Xã Hưng Thịnh",
      "quanhuyen":"71302"
   },
   {
      "id":"7131103",
      "phuongxa":"Phường Bảo Vinh",
      "quanhuyen":"71311"
   },
   {
      "id":"7131127",
      "phuongxa":"Xã Xuân Quế",
      "quanhuyen":"71311"
   },
   {
      "id":"7131309",
      "phuongxa":"Xã Suối Cao",
      "quanhuyen":"71313"
   },
   {
      "id":"8258761",
      "phuongxa":"Tân Lâm",
      "quanhuyen":"70315"
   },
   {
      "id":"8258937",
      "phuongxa":"Phường Vĩnh Quang",
      "quanhuyen":"81301"
   },
   {
      "id":"8258996",
      "phuongxa":"Xã Đồi 61",
      "quanhuyen":"71302"
   },
   {
      "id":"8258872",
      "phuongxa":"Phường Phú Sơn",
      "quanhuyen":"40103"
   },
   {
      "id":"8258706",
      "phuongxa":"xã Tân Lập",
      "quanhuyen":"60509"
   },
   {
      "id":"8258712",
      "phuongxa":"Phường An Phú",
      "quanhuyen":"81520"
   },
   {
      "id":"8258727",
      "phuongxa":"Xã Vĩnh Quới",
      "quanhuyen":"82615"
   },
   {
      "id":"8259032",
      "phuongxa":"Xã Vĩnh Thịnh",
      "quanhuyen":"82108"
   },
   {
      "id":"8258927",
      "phuongxa":"thị trấn Trần Đề",
      "quanhuyen":"82599"
   },
   {
      "id":"8258863",
      "phuongxa":"Xã Trung Bình",
      "quanhuyen":"82599"
   },
   {
      "id":"6077788",
      "phuongxa":"Kiên Thành",
      "quanhuyen":"60777"
   },
   {
      "id":"8258704",
      "phuongxa":"Xã Xuân Thới Đông",
      "quanhuyen":"70137"
   },
   {
      "id":"8258859",
      "phuongxa":"Xã Tân Trung",
      "quanhuyen":"81102"
   },
   {
      "id":"8258795",
      "phuongxa":"Phước Hà",
      "quanhuyen":"82593"
   },
   {
      "id":"8258947",
      "phuongxa":"Tân Phước",
      "quanhuyen":"82609"
   },
   {
      "id":"8217677",
      "phuongxa":"Phong Thạnh Tây A",
      "quanhuyen":"82102"
   },
   {
      "id":"8258847",
      "phuongxa":"Xã Phú Riềng",
      "quanhuyen":"82623"
   },
   {
      "id":"8258783",
      "phuongxa":"Tân Phú Thạnh",
      "quanhuyen":"82587"
   },
   {
      "id":"8258885",
      "phuongxa":"Xã Tân Hưng",
      "quanhuyen":"71703"
   },
   {
      "id":"8258922",
      "phuongxa":"Xã Đông Hiệp",
      "quanhuyen":"81514"
   },
   {
      "id":"8258828",
      "phuongxa":"Xã Hùng Thành",
      "quanhuyen":"40323"
   },
   {
      "id":"8258934",
      "phuongxa":"Xã Đông Hòa",
      "quanhuyen":"71302"
   },
   {
      "id":"8259007",
      "phuongxa":"Hiệp Thành",
      "quanhuyen":"82502"
   },
   {
      "id":"8070999",
      "phuongxa":"Phường 2",
      "quanhuyen":"80709"
   },
   {
      "id":"8259025",
      "phuongxa":"Long Trị",
      "quanhuyen":"82586"
   },
   {
      "id":"8258867",
      "phuongxa":"Phường Thường Thạnh",
      "quanhuyen":"81515"
   },
   {
      "id":"8258954",
      "phuongxa":"Tam Giang",
      "quanhuyen":"82314"
   },
   {
      "id":"7015558",
      "phuongxa":"Tân Hưng Thuận",
      "quanhuyen":"70113"
   },
   {
      "id":"8258736",
      "phuongxa":"Đông Thắng",
      "quanhuyen":"81514"
   },
   {
      "id":"8258903",
      "phuongxa":"Xã Thạnh Xuân",
      "quanhuyen":"82587"
   },
   {
      "id":"8259414",
      "phuongxa":"Xã Hòa Thuận",
      "quanhuyen":"81115"
   },
   {
      "id":"8259183",
      "phuongxa":"KHÔNG XÁC ĐỊNH",
      "quanhuyen":"60700"
   },
   {
      "id":"8259241",
      "phuongxa":"Xã Tân Hải ",
      "quanhuyen":"71709"
   },
   {
      "id":"8259107",
      "phuongxa":"Xã Phước Nam",
      "quanhuyen":"82593"
   },
   {
      "id":"8259189",
      "phuongxa":"Bình Tân",
      "quanhuyen":"82623"
   },
   {
      "id":"8259067",
      "phuongxa":"Vĩnh Thạnh",
      "quanhuyen":"81911"
   },
   {
      "id":"8259081",
      "phuongxa":"Xã Trường Xuân",
      "quanhuyen":"81516"
   },
   {
      "id":"8259186",
      "phuongxa":"Thị Trấn Bến Cầu",
      "quanhuyen":"70913"
   },
   {
      "id":"8259256",
      "phuongxa":"Xã Lương Tâm",
      "quanhuyen":"82586"
   },
   {
      "id":"8259386",
      "phuongxa":"Xã EA Tân",
      "quanhuyen":"60507"
   },
   {
      "id":"8259182",
      "phuongxa":"Thị trấn thuận nam",
      "quanhuyen":"71509"
   },
   {
      "id":"8259294",
      "phuongxa":"Xã Thạnh Lợi",
      "quanhuyen":"81519"
   },
   {
      "id":"8259214",
      "phuongxa":"Xã Bình Trị Đông",
      "quanhuyen":"80123"
   },
   {
      "id":"8259171",
      "phuongxa":"xÃ HÀM TÂN",
      "quanhuyen":"81713"
   },
   {
      "id":"8259142",
      "phuongxa":"Phường Cam Thuận",
      "quanhuyen":"51109"
   },
   {
      "id":"8259254",
      "phuongxa":"Xã Phước Diêm",
      "quanhuyen":"82593"
   },
   {
      "id":"8259173",
      "phuongxa":"Xã Lương Hòa A",
      "quanhuyen":"81705"
   },
   {
      "id":"8259263",
      "phuongxa":"Xã Xuân Lộc",
      "quanhuyen":"71302"
   },
   {
      "id":"8259331",
      "phuongxa":"Xã Quang Tiến",
      "quanhuyen":"60777"
   },
   {
      "id":"8259180",
      "phuongxa":"Xã Phước Long Thọ",
      "quanhuyen":"82622"
   },
   {
      "id":"8259176",
      "phuongxa":"Nâm N`Đir",
      "quanhuyen":"82606"
   },
   {
      "id":"8259290",
      "phuongxa":"Xã Thới Hòa",
      "quanhuyen":"80513"
   },
   {
      "id":"8259203",
      "phuongxa":"Xã Cư Nông",
      "quanhuyen":"60507"
   },
   {
      "id":"6051921",
      "phuongxa":"Xã Ea Hiu",
      "quanhuyen":"60519"
   },
   {
      "id":"6052521",
      "phuongxa":"Xã Hòa Sơn",
      "quanhuyen":"60525"
   },
   {
      "id":"6031117",
      "phuongxa":"Xã Yang Bắc",
      "quanhuyen":"60311"
   },
   {
      "id":"8071343",
      "phuongxa":"Xã An Thái Trung",
      "quanhuyen":"80713"
   },
   {
      "id":"8230127",
      "phuongxa":"Xã Lý Văn Lâm",
      "quanhuyen":"82301"
   },
   {
      "id":"8090905",
      "phuongxa":"Xã Tân Lộc",
      "quanhuyen":"80909"
   },
   {
      "id":"8091319",
      "phuongxa":"Xã Hiếu Phụng",
      "quanhuyen":"80913"
   },
   {
      "id":"8110341",
      "phuongxa":"Xã Giao Long",
      "quanhuyen":"81103"
   },
   {
      "id":"7031305",
      "phuongxa":"Xã Lộc Bắc",
      "quanhuyen":"70313"
   },
   {
      "id":"8011535",
      "phuongxa":"Xã Mỹ Hạnh Bắc",
      "quanhuyen":"80115"
   },
   {
      "id":"8111331",
      "phuongxa":"Xã Bảo Thạnh",
      "quanhuyen":"81113"
   },
   {
      "id":"7050707",
      "phuongxa":"Xã Phước Hậu",
      "quanhuyen":"70507"
   },
   {
      "id":"8030917",
      "phuongxa":"Xã Phú Cường",
      "quanhuyen":"80309"
   },
   {
      "id":"7070929",
      "phuongxa":"Xã Minh Lập",
      "quanhuyen":"70709"
   },
   {
      "id":"8170909",
      "phuongxa":"Xã Hiếu Trung",
      "quanhuyen":"81709"
   },
   {
      "id":"7110523",
      "phuongxa":"Xã Bình Mỹ",
      "quanhuyen":"71105"
   },
   {
      "id":"8190313",
      "phuongxa":"Xã Đại Hải",
      "quanhuyen":"81903"
   },
   {
      "id":"8070915",
      "phuongxa":"Xã Mỹ Thành Nam",
      "quanhuyen":"82625"
   },
   {
      "id":"8258909",
      "phuongxa":"Hòa An",
      "quanhuyen":"82584"
   },
   {
      "id":"8258759",
      "phuongxa":"Hưng Khánh Trung A",
      "quanhuyen":"81107"
   },
   {
      "id":"8258862",
      "phuongxa":"Xã An Minh Bắc",
      "quanhuyen":"81327"
   },
   {
      "id":"8259156",
      "phuongxa":"Xã An Linh",
      "quanhuyen":"71108"
   },
   {
      "id":"8259223",
      "phuongxa":"Xã Đại An",
      "quanhuyen":"50307"
   },
   {
      "id":"8259320",
      "phuongxa":"Xã Ân Hảo Đông",
      "quanhuyen":"50707"
   },
   {
      "id":"8259184",
      "phuongxa":"Xã Lâm Hải",
      "quanhuyen":"82314"
   },
   {
      "id":"8259448",
      "phuongxa":"Xã Đồng Lê",
      "quanhuyen":"40703"
   },
   {
      "id":"8259398",
      "phuongxa":"Xã Ea Tir",
      "quanhuyen":"60503"
   },
   {
      "id":"8259412",
      "phuongxa":"Xã Tịnh Long",
      "quanhuyen":"50509"
   },
   {
      "id":"8259431",
      "phuongxa":"Xã Long Tân",
      "quanhuyen":"82620"
   },
   {
      "id":"8259434",
      "phuongxa":"Xã Long Phú",
      "quanhuyen":"82586"
   },
   {
      "id":"8259444",
      "phuongxa":"Xã Nhân Đạo",
      "quanhuyen":"82634"
   },
   {
      "id":"6050113",
      "phuongxa":"Phường Tân Lợi",
      "quanhuyen":"60501"
   },
   {
      "id":"6053711",
      "phuongxa":"Phường Thống Nhất",
      "quanhuyen":"60537"
   },
   {
      "id":"7010100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70101"
   },
   {
      "id":"7010311",
      "phuongxa":"Phường Thủ Thiêm",
      "quanhuyen":"769"
   },
   {
      "id":"7150101",
      "phuongxa":"Phường Đức Thắng",
      "quanhuyen":"71501"
   },
   {
      "id":"7012701",
      "phuongxa":"Phường 1",
      "quanhuyen":"70127"
   },
   {
      "id":"7012735",
      "phuongxa":"Phường 18",
      "quanhuyen":"70127"
   },
   {
      "id":"7013100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70131"
   },
   {
      "id":"7013305",
      "phuongxa":"Phường Hiệp Bình Phước",
      "quanhuyen":"769"
   },
   {
      "id":"8090103",
      "phuongxa":"Phường 2",
      "quanhuyen":"80901"
   },
   {
      "id":"8231301",
      "phuongxa":"Thị trấn Rạch Gốc",
      "quanhuyen":"82313"
   },
   {
      "id":"7170300",
      "phuongxa":"Không xác định",
      "quanhuyen":"71703"
   },
   {
      "id":"8090901",
      "phuongxa":"Thị trấn Tam Bình",
      "quanhuyen":"80909"
   },
   {
      "id":"8010109",
      "phuongxa":"Phường 5",
      "quanhuyen":"80101"
   },
   {
      "id":"7031901",
      "phuongxa":"Thị trấn Đạ Tẻh",
      "quanhuyen":"70319"
   },
   {
      "id":"8012301",
      "phuongxa":"Thị trấn Tân Trụ",
      "quanhuyen":"80123"
   },
   {
      "id":"8030307",
      "phuongxa":"Phường 4",
      "quanhuyen":"80303"
   },
   {
      "id":"7070100",
      "phuongxa":"Không xác định",
      "quanhuyen":"70701"
   },
   {
      "id":"8150515",
      "phuongxa":"Phường Phước Thới",
      "quanhuyen":"81505"
   },
   {
      "id":"8170103",
      "phuongxa":"Phường 2",
      "quanhuyen":"81701"
   },
   {
      "id":"8050123",
      "phuongxa":"Phường Bình Khánh",
      "quanhuyen":"80501"
   },
   {
      "id":"7110117",
      "phuongxa":"Phường Tương Bình Hiệp",
      "quanhuyen":"71101"
   },
   {
      "id":"7110505",
      "phuongxa":"Phường Tân Phước Khánh",
      "quanhuyen":"82627"
   },
   {
      "id":"8190107",
      "phuongxa":"Phường 4",
      "quanhuyen":"81901"
   },
   {
      "id":"7111105",
      "phuongxa":"Phường Tân Đông Hiệp",
      "quanhuyen":"71111"
   },
   {
      "id":"7130129",
      "phuongxa":"Phường Tam Hiệp",
      "quanhuyen":"71301"
   },
   {
      "id":"8070111",
      "phuongxa":"Phường 2",
      "quanhuyen":"80701"
   },
   {
      "id":"8191313",
      "phuongxa":"Phường Khánh Hòa",
      "quanhuyen":"82611"
   },
   {
      "id":"7131301",
      "phuongxa":"Thị trấn Gia Ray",
      "quanhuyen":"71313"
   },
   {
      "id":"8258952",
      "phuongxa":"Thị trấn Năm Căn",
      "quanhuyen":"82314"
   },
   {
      "id":"8259446",
      "phuongxa":"Xã Cà Ná",
      "quanhuyen":"82593"
   }
]

export {
   wardsList
}

export default {
   wardsList
}