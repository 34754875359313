import { combineReducers } from 'redux';
import language from "./language";
import ThemeOptions from "./ThemeOptions";
import countries from "./countries"
import result from "./result"
import listProfile from "./listProfile"
import userInfo from "./userInfo"
import testList from "./testList"
import paymentList from "./paymentList"
import testResult from "./testResult"
import countriesList from "./countriesList"
import addressInfo from "./addressInfo"

const appReducer = combineReducers({
  countries,
  ThemeOptions,
  language,
  result,
  listProfile,
  userInfo,
  testList,
  paymentList,
  testResult,
  countriesList,
  addressInfo
});


const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_STORE') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;