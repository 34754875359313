const districtsList = [
   {
      "id":"20505",
      "quanhuyen":"Huyện Mường Khương",
      "tinhthanh":"205"
   },
   {
      "id":"20517",
      "quanhuyen":"Huyện Si Ma Cai",
      "tinhthanh":"205"
   },
   {
      "id":"20509",
      "quanhuyen":"Huyện Bắc Hà",
      "tinhthanh":"205"
   },
   {
      "id":"20511",
      "quanhuyen":"Huyện Bảo Thắng",
      "tinhthanh":"205"
   },
   {
      "id":"20515",
      "quanhuyen":"Huyện Bảo Yên",
      "tinhthanh":"205"
   },
   {
      "id":"20513",
      "quanhuyen":"Huyện Sa Pa",
      "tinhthanh":"205"
   },
   {
      "id":"20519",
      "quanhuyen":"Huyện Văn Bàn",
      "tinhthanh":"205"
   },
   {
      "id":"30700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"307"
   },
   {
      "id":"30701",
      "quanhuyen":"TP. Điện Biên Phủ",
      "tinhthanh":"307"
   },
   {
      "id":"30707",
      "quanhuyen":"Thị xã Mường Lay",
      "tinhthanh":"307"
   },
   {
      "id":"30705",
      "quanhuyen":"Huyện Mường Nhé",
      "tinhthanh":"307"
   },
   {
      "id":"30719",
      "quanhuyen":"Huyện Mường Chà",
      "tinhthanh":"307"
   },
   {
      "id":"30709",
      "quanhuyen":"Huyện Tủa Chùa",
      "tinhthanh":"307"
   },
   {
      "id":"30711",
      "quanhuyen":"Huyện Tuần Giáo",
      "tinhthanh":"307"
   },
   {
      "id":"30713",
      "quanhuyen":"Huyện Điện Biên",
      "tinhthanh":"307"
   },
   {
      "id":"30715",
      "quanhuyen":"Huyện Điện Biên Đông",
      "tinhthanh":"307"
   },
   {
      "id":"30717",
      "quanhuyen":"Huyện Mường ảng",
      "tinhthanh":"307"
   },
   {
      "id":"30100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"301"
   },
   {
      "id":"30121",
      "quanhuyen":"Huyện Tam Đường",
      "tinhthanh":"301"
   },
   {
      "id":"30105",
      "quanhuyen":"Huyện Mường Tè",
      "tinhthanh":"301"
   },
   {
      "id":"30109",
      "quanhuyen":"Huyện Sìn Hồ",
      "tinhthanh":"301"
   },
   {
      "id":"30107",
      "quanhuyen":"Huyện Phong Thổ",
      "tinhthanh":"301"
   },
   {
      "id":"30123",
      "quanhuyen":"Huyện Than Uyên",
      "tinhthanh":"301"
   },
   {
      "id":"30103",
      "quanhuyen":"TP. Lai châu",
      "tinhthanh":"301"
   },
   {
      "id":"30111",
      "quanhuyen":"Huyện Tân Uyên",
      "tinhthanh":"301"
   },
   {
      "id":"30300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"303"
   },
   {
      "id":"30301",
      "quanhuyen":"Tp. Sơn La",
      "tinhthanh":"303"
   },
   {
      "id":"30303",
      "quanhuyen":"Huyện Quỳnh Nhai",
      "tinhthanh":"303"
   },
   {
      "id":"30307",
      "quanhuyen":"Huyện Thuận Châu",
      "tinhthanh":"303"
   },
   {
      "id":"30305",
      "quanhuyen":"Huyện Mường La",
      "tinhthanh":"303"
   },
   {
      "id":"30309",
      "quanhuyen":"Huyện Bắc Yên",
      "tinhthanh":"303"
   },
   {
      "id":"30311",
      "quanhuyen":"Huyện Phù Yên",
      "tinhthanh":"303"
   },
   {
      "id":"30319",
      "quanhuyen":"Huyện Mộc Châu",
      "tinhthanh":"303"
   },
   {
      "id":"30317",
      "quanhuyen":"Huyện Yên Châu",
      "tinhthanh":"303"
   },
   {
      "id":"30313",
      "quanhuyen":"Huyện Mai Sơn",
      "tinhthanh":"303"
   },
   {
      "id":"30315",
      "quanhuyen":"Huyện Sông Mã",
      "tinhthanh":"303"
   },
   {
      "id":"30321",
      "quanhuyen":"Huyện Sốp Cộp",
      "tinhthanh":"303"
   },
   {
      "id":"21300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"213"
   },
   {
      "id":"21301",
      "quanhuyen":"TP. Yên Bái",
      "tinhthanh":"213"
   },
   {
      "id":"21303",
      "quanhuyen":"Thị xã Nghĩa Lộ",
      "tinhthanh":"213"
   },
   {
      "id":"21305",
      "quanhuyen":"Huyện Lục Yên",
      "tinhthanh":"213"
   },
   {
      "id":"21307",
      "quanhuyen":"Huyện Văn Yên",
      "tinhthanh":"213"
   },
   {
      "id":"21309",
      "quanhuyen":"Huyện Mù Căng Chải",
      "tinhthanh":"213"
   },
   {
      "id":"21311",
      "quanhuyen":"Huyện Trấn Yên",
      "tinhthanh":"213"
   },
   {
      "id":"21317",
      "quanhuyen":"Huyện Trạm Tấu",
      "tinhthanh":"213"
   },
   {
      "id":"21315",
      "quanhuyen":"Huyện Văn Chấn",
      "tinhthanh":"213"
   },
   {
      "id":"21313",
      "quanhuyen":"Huyện Yên Bình",
      "tinhthanh":"213"
   },
   {
      "id":"30500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"305"
   },
   {
      "id":"30501",
      "quanhuyen":"TP. Hòa Bình",
      "tinhthanh":"305"
   },
   {
      "id":"30503",
      "quanhuyen":"Huyện Đà Bắc",
      "tinhthanh":"305"
   },
   {
      "id":"30507",
      "quanhuyen":"Huyện Kỳ Sơn",
      "tinhthanh":"305"
   },
   {
      "id":"30509",
      "quanhuyen":"Huyện Lương Sơn",
      "tinhthanh":"305"
   },
   {
      "id":"30511",
      "quanhuyen":"Huyện Kim Bôi",
      "tinhthanh":"305"
   },
   {
      "id":"30521",
      "quanhuyen":"Huyện Cao Phong",
      "tinhthanh":"305"
   },
   {
      "id":"30513",
      "quanhuyen":"Huyện Tân Lạc",
      "tinhthanh":"305"
   },
   {
      "id":"30505",
      "quanhuyen":"Huyện Mai Châu",
      "tinhthanh":"305"
   },
   {
      "id":"30515",
      "quanhuyen":"Huyện Lạc Sơn",
      "tinhthanh":"305"
   },
   {
      "id":"30519",
      "quanhuyen":"Huyện Yên Thủy",
      "tinhthanh":"305"
   },
   {
      "id":"30517",
      "quanhuyen":"Huyện Lạc Thủy",
      "tinhthanh":"305"
   },
   {
      "id":"21500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"215"
   },
   {
      "id":"21501",
      "quanhuyen":"TP. Thái Nguyên",
      "tinhthanh":"215"
   },
   {
      "id":"21503",
      "quanhuyen":"Tp. Sông Công",
      "tinhthanh":"215"
   },
   {
      "id":"21505",
      "quanhuyen":"Huyện Định Hóa",
      "tinhthanh":"215"
   },
   {
      "id":"21511",
      "quanhuyen":"Huyện Đồng Hỷ",
      "tinhthanh":"215"
   },
   {
      "id":"21507",
      "quanhuyen":"Huyện Võ Nhai",
      "tinhthanh":"215"
   },
   {
      "id":"21513",
      "quanhuyen":"Huyện Đại Từ",
      "tinhthanh":"215"
   },
   {
      "id":"21517",
      "quanhuyen":"Huyện Phổ Yên",
      "tinhthanh":"215"
   },
   {
      "id":"21515",
      "quanhuyen":"Huyện Phú Bình",
      "tinhthanh":"215"
   },
   {
      "id":"20900",
      "quanhuyen":"Không xác định",
      "tinhthanh":"209"
   },
   {
      "id":"20901",
      "quanhuyen":"TP. Lạng Sơn",
      "tinhthanh":"209"
   },
   {
      "id":"20903",
      "quanhuyen":"Huyện Tràng Định",
      "tinhthanh":"209"
   },
   {
      "id":"20907",
      "quanhuyen":"Huyện Bình Gia",
      "tinhthanh":"209"
   },
   {
      "id":"20905",
      "quanhuyen":"Huyện Văn Lãng",
      "tinhthanh":"209"
   },
   {
      "id":"20913",
      "quanhuyen":"Huyện Cao Lộc",
      "tinhthanh":"209"
   },
   {
      "id":"20911",
      "quanhuyen":"Huyện Văn Quan",
      "tinhthanh":"209"
   },
   {
      "id":"20909",
      "quanhuyen":"Huyện Bắc Sơn",
      "tinhthanh":"209"
   },
   {
      "id":"20921",
      "quanhuyen":"Huyện Hữu Lũng",
      "tinhthanh":"209"
   },
   {
      "id":"20917",
      "quanhuyen":"Huyện Chi Lăng",
      "tinhthanh":"209"
   },
   {
      "id":"20915",
      "quanhuyen":"Huyện Lộc Bình",
      "tinhthanh":"209"
   },
   {
      "id":"20919",
      "quanhuyen":"Huyện Đình Lập",
      "tinhthanh":"209"
   },
   {
      "id":"22500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"225"
   },
   {
      "id":"22501",
      "quanhuyen":"TP. Hạ Long",
      "tinhthanh":"225"
   },
   {
      "id":"22509",
      "quanhuyen":"TP. Móng Cái",
      "tinhthanh":"225"
   },
   {
      "id":"22503",
      "quanhuyen":"TP. Cẩm Phả",
      "tinhthanh":"225"
   },
   {
      "id":"22505",
      "quanhuyen":"TP. Uông Bí",
      "tinhthanh":"225"
   },
   {
      "id":"22507",
      "quanhuyen":"Huyện Bình Liêu",
      "tinhthanh":"225"
   },
   {
      "id":"22513",
      "quanhuyen":"Huyện Tiên Yên",
      "tinhthanh":"225"
   },
   {
      "id":"22525",
      "quanhuyen":"Huyện Đầm Hà",
      "tinhthanh":"225"
   },
   {
      "id":"22527",
      "quanhuyen":"Huyện Hải Hà",
      "tinhthanh":"225"
   },
   {
      "id":"22515",
      "quanhuyen":"Huyện Ba Chẽ",
      "tinhthanh":"225"
   },
   {
      "id":"22517",
      "quanhuyen":"Huyện Vân Đồn",
      "tinhthanh":"225"
   },
   {
      "id":"22519",
      "quanhuyen":"Huyện Hoành Bồ",
      "tinhthanh":"225"
   },
   {
      "id":"22521",
      "quanhuyen":"Huyện Đông Triều",
      "tinhthanh":"225"
   },
   {
      "id":"22529",
      "quanhuyen":"Thị xã Quảng Yên",
      "tinhthanh":"225"
   },
   {
      "id":"22523",
      "quanhuyen":"Huyện Cô Tô",
      "tinhthanh":"225"
   },
   {
      "id":"22100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"221"
   },
   {
      "id":"22101",
      "quanhuyen":"TP. Bắc Giang",
      "tinhthanh":"221"
   },
   {
      "id":"22103",
      "quanhuyen":"Huyện Yên Thế",
      "tinhthanh":"221"
   },
   {
      "id":"22105",
      "quanhuyen":"Huyện Tân Yên",
      "tinhthanh":"221"
   },
   {
      "id":"22111",
      "quanhuyen":"Huyện Lạng Giang",
      "tinhthanh":"221"
   },
   {
      "id":"22115",
      "quanhuyen":"Huyện Lục Nam",
      "tinhthanh":"221"
   },
   {
      "id":"22107",
      "quanhuyen":"Huyện Lục Ngạn",
      "tinhthanh":"221"
   },
   {
      "id":"22113",
      "quanhuyen":"Huyện Sơn Động",
      "tinhthanh":"221"
   },
   {
      "id":"22119",
      "quanhuyen":"Huyện Yên Dũng",
      "tinhthanh":"221"
   },
   {
      "id":"22117",
      "quanhuyen":"Huyện Việt Yên",
      "tinhthanh":"221"
   },
   {
      "id":"21700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"217"
   },
   {
      "id":"21701",
      "quanhuyen":"TP. Việt Trì",
      "tinhthanh":"217"
   },
   {
      "id":"21703",
      "quanhuyen":"Thị xã Phú Thọ",
      "tinhthanh":"217"
   },
   {
      "id":"21705",
      "quanhuyen":"Huyện Đoan Hùng",
      "tinhthanh":"217"
   },
   {
      "id":"21707",
      "quanhuyen":"Huyện Hạ Hoà",
      "tinhthanh":"217"
   },
   {
      "id":"21709",
      "quanhuyen":"Huyện Thanh Ba",
      "tinhthanh":"217"
   },
   {
      "id":"21711",
      "quanhuyen":"Huyện Phù Ninh",
      "tinhthanh":"217"
   },
   {
      "id":"00000",
      "quanhuyen":"Không xác định",
      "tinhthanh":"000"
   },
   {
      "id":"10100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"101"
   },
   {
      "id":"10101",
      "quanhuyen":"Quận Ba Đình",
      "tinhthanh":"101"
   },
   {
      "id":"10105",
      "quanhuyen":"Quận Hoàn Kiếm",
      "tinhthanh":"101"
   },
   {
      "id":"10103",
      "quanhuyen":"Quận Tây Hồ",
      "tinhthanh":"101"
   },
   {
      "id":"10155",
      "quanhuyen":"Quận Long Biên",
      "tinhthanh":"101"
   },
   {
      "id":"10113",
      "quanhuyen":"Quận Cầu Giấy",
      "tinhthanh":"101"
   },
   {
      "id":"10109",
      "quanhuyen":"Quận Đống Đa",
      "tinhthanh":"101"
   },
   {
      "id":"10107",
      "quanhuyen":"Quận Hai Bà Trưng",
      "tinhthanh":"101"
   },
   {
      "id":"10157",
      "quanhuyen":"Quận Hoàng Mai",
      "tinhthanh":"101"
   },
   {
      "id":"10111",
      "quanhuyen":"Quận Thanh Xuân",
      "tinhthanh":"101"
   },
   {
      "id":"10115",
      "quanhuyen":"Huyện Sóc Sơn",
      "tinhthanh":"101"
   },
   {
      "id":"10117",
      "quanhuyen":"Huyện Đông Anh",
      "tinhthanh":"101"
   },
   {
      "id":"10119",
      "quanhuyen":"Huyện Gia Lâm",
      "tinhthanh":"101"
   },
   {
      "id":"10121",
      "quanhuyen":"Quận Nam Từ Liêm",
      "tinhthanh":"101"
   },
   {
      "id":"10123",
      "quanhuyen":"Huyện Thanh Trì",
      "tinhthanh":"101"
   },
   {
      "id":"10125",
      "quanhuyen":"Quận Hà Đông",
      "tinhthanh":"101"
   },
   {
      "id":"10127",
      "quanhuyen":"Thị xã Sơn Tây",
      "tinhthanh":"101"
   },
   {
      "id":"10129",
      "quanhuyen":"Huyện Ba Vì",
      "tinhthanh":"101"
   },
   {
      "id":"10131",
      "quanhuyen":"Huyện Phúc Thọ",
      "tinhthanh":"101"
   },
   {
      "id":"10133",
      "quanhuyen":"Huyện Đan Phượng",
      "tinhthanh":"101"
   },
   {
      "id":"10135",
      "quanhuyen":"Huyện Hoài Đức",
      "tinhthanh":"101"
   },
   {
      "id":"10137",
      "quanhuyen":"Huyện Quốc Oai",
      "tinhthanh":"101"
   },
   {
      "id":"10139",
      "quanhuyen":"Huyện Thạch Thất",
      "tinhthanh":"101"
   },
   {
      "id":"10141",
      "quanhuyen":"Huyện Chương Mỹ",
      "tinhthanh":"101"
   },
   {
      "id":"10143",
      "quanhuyen":"Huyện Thanh Oai",
      "tinhthanh":"101"
   },
   {
      "id":"10145",
      "quanhuyen":"Huyện Thường Tín",
      "tinhthanh":"101"
   },
   {
      "id":"10147",
      "quanhuyen":"Huyện Phú Xuyên",
      "tinhthanh":"101"
   },
   {
      "id":"10149",
      "quanhuyen":"Huyện Ứng Hòa",
      "tinhthanh":"101"
   },
   {
      "id":"10151",
      "quanhuyen":"Huyện Mỹ Đức",
      "tinhthanh":"101"
   },
   {
      "id":"10153",
      "quanhuyen":"Huyện Mê Linh",
      "tinhthanh":"101"
   },
   {
      "id":"20100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"201"
   },
   {
      "id":"20101",
      "quanhuyen":"TP. Hà Giang",
      "tinhthanh":"201"
   },
   {
      "id":"20103",
      "quanhuyen":"Huyện Đồng Văn",
      "tinhthanh":"201"
   },
   {
      "id":"20105",
      "quanhuyen":"Huyện Mèo Vạc",
      "tinhthanh":"201"
   },
   {
      "id":"20107",
      "quanhuyen":"Huyện Yên Minh",
      "tinhthanh":"201"
   },
   {
      "id":"20109",
      "quanhuyen":"Huyện Quản Bạ",
      "tinhthanh":"201"
   },
   {
      "id":"20115",
      "quanhuyen":"Huyện Vị Xuyên",
      "tinhthanh":"201"
   },
   {
      "id":"20111",
      "quanhuyen":"Huyện Bắc Mê",
      "tinhthanh":"201"
   },
   {
      "id":"20113",
      "quanhuyen":"Huyện Hoàng Su Phì",
      "tinhthanh":"201"
   },
   {
      "id":"20117",
      "quanhuyen":"Huyện Xín Mần",
      "tinhthanh":"201"
   },
   {
      "id":"20119",
      "quanhuyen":"Huyện Bắc Quang",
      "tinhthanh":"201"
   },
   {
      "id":"20121",
      "quanhuyen":"Huyện Quang Bình",
      "tinhthanh":"201"
   },
   {
      "id":"20300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"203"
   },
   {
      "id":"20301",
      "quanhuyen":"TP. Cao Bằng",
      "tinhthanh":"203"
   },
   {
      "id":"20325",
      "quanhuyen":"Huyện Bảo Lâm",
      "tinhthanh":"203"
   },
   {
      "id":"20303",
      "quanhuyen":"Huyện Bảo Lạc",
      "tinhthanh":"203"
   },
   {
      "id":"20307",
      "quanhuyen":"Huyện Thông Nông",
      "tinhthanh":"203"
   },
   {
      "id":"20305",
      "quanhuyen":"Huyện Hà Quảng",
      "tinhthanh":"203"
   },
   {
      "id":"20309",
      "quanhuyen":"Huyện Trà Lĩnh",
      "tinhthanh":"203"
   },
   {
      "id":"20311",
      "quanhuyen":"Huyện Trùng Khánh",
      "tinhthanh":"203"
   },
   {
      "id":"20319",
      "quanhuyen":"Huyện Hạ Lang",
      "tinhthanh":"203"
   },
   {
      "id":"20317",
      "quanhuyen":"Huyện Quảng Uyên",
      "tinhthanh":"203"
   },
   {
      "id":"20323",
      "quanhuyen":"Huyện Phục Hoà",
      "tinhthanh":"203"
   },
   {
      "id":"20315",
      "quanhuyen":"Huyện Hoà An",
      "tinhthanh":"203"
   },
   {
      "id":"20313",
      "quanhuyen":"Huyện Nguyên Bình",
      "tinhthanh":"203"
   },
   {
      "id":"20321",
      "quanhuyen":"Huyện Thạch An",
      "tinhthanh":"203"
   },
   {
      "id":"20700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"207"
   },
   {
      "id":"20701",
      "quanhuyen":"TP. Bắc Kạn",
      "tinhthanh":"207"
   },
   {
      "id":"20715",
      "quanhuyen":"Huyện Pác Nặm",
      "tinhthanh":"207"
   },
   {
      "id":"20703",
      "quanhuyen":"Huyện Ba Bể",
      "tinhthanh":"207"
   },
   {
      "id":"20705",
      "quanhuyen":"Huyện Ngân Sơn",
      "tinhthanh":"207"
   },
   {
      "id":"20711",
      "quanhuyen":"Huyện Bạch Thông",
      "tinhthanh":"207"
   },
   {
      "id":"20707",
      "quanhuyen":"Huyện Chợ Đồn",
      "tinhthanh":"207"
   },
   {
      "id":"20713",
      "quanhuyen":"Huyện Chợ Mới",
      "tinhthanh":"207"
   },
   {
      "id":"20709",
      "quanhuyen":"Huyện Na Rì",
      "tinhthanh":"207"
   },
   {
      "id":"21100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"211"
   },
   {
      "id":"21101",
      "quanhuyen":"TP. Tuyên Quang",
      "tinhthanh":"211"
   },
   {
      "id":"21103",
      "quanhuyen":"Huyện Nà Hang",
      "tinhthanh":"211"
   },
   {
      "id":"21105",
      "quanhuyen":"Huyện Chiêm Hóa",
      "tinhthanh":"211"
   },
   {
      "id":"21107",
      "quanhuyen":"Huyện Hàm Yên",
      "tinhthanh":"211"
   },
   {
      "id":"21109",
      "quanhuyen":"Huyện Yên Sơn",
      "tinhthanh":"211"
   },
   {
      "id":"21111",
      "quanhuyen":"Huyện Sơn Dương",
      "tinhthanh":"211"
   },
   {
      "id":"20500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"205"
   },
   {
      "id":"20501",
      "quanhuyen":"TP. Lào Cai",
      "tinhthanh":"205"
   },
   {
      "id":"20507",
      "quanhuyen":"Huyện Bát Xát",
      "tinhthanh":"205"
   },
   {
      "id":"10721",
      "quanhuyen":"Huyện Thanh Miện",
      "tinhthanh":"107"
   },
   {
      "id":"10300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"103"
   },
   {
      "id":"10301",
      "quanhuyen":"Quận Hồng Bàng",
      "tinhthanh":"103"
   },
   {
      "id":"10303",
      "quanhuyen":"Quận Ngô Quyền",
      "tinhthanh":"103"
   },
   {
      "id":"10305",
      "quanhuyen":"Quận Lê Chân",
      "tinhthanh":"103"
   },
   {
      "id":"10327",
      "quanhuyen":"Quận Hải An",
      "tinhthanh":"103"
   },
   {
      "id":"10307",
      "quanhuyen":"Quận Kiến An",
      "tinhthanh":"103"
   },
   {
      "id":"10309",
      "quanhuyen":"Quận Đồ Sơn",
      "tinhthanh":"103"
   },
   {
      "id":"10311",
      "quanhuyen":"Huyện Thuỷ Nguyên",
      "tinhthanh":"103"
   },
   {
      "id":"10329",
      "quanhuyen":"Huyện An Dương",
      "tinhthanh":"103"
   },
   {
      "id":"10315",
      "quanhuyen":"Huyện An Lão",
      "tinhthanh":"103"
   },
   {
      "id":"10317",
      "quanhuyen":"Huyện Kiến Thuỵ",
      "tinhthanh":"103"
   },
   {
      "id":"10319",
      "quanhuyen":"Huyện Tiên Lãng",
      "tinhthanh":"103"
   },
   {
      "id":"10321",
      "quanhuyen":"Huyện Vĩnh Bảo",
      "tinhthanh":"103"
   },
   {
      "id":"10323",
      "quanhuyen":"Huyện Cát Hải",
      "tinhthanh":"103"
   },
   {
      "id":"10325",
      "quanhuyen":"Huyện Bạch Long Vĩ",
      "tinhthanh":"103"
   },
   {
      "id":"10331",
      "quanhuyen":"Quận Dương Kinh",
      "tinhthanh":"103"
   },
   {
      "id":"10900",
      "quanhuyen":"Không xác định",
      "tinhthanh":"109"
   },
   {
      "id":"10901",
      "quanhuyen":"TP. Hưng Yên",
      "tinhthanh":"109"
   },
   {
      "id":"10902",
      "quanhuyen":"Huyện Văn Lâm",
      "tinhthanh":"109"
   },
   {
      "id":"10904",
      "quanhuyen":"Huyện Yên Mỹ",
      "tinhthanh":"109"
   },
   {
      "id":"10903",
      "quanhuyen":"Huyện Mỹ Hào",
      "tinhthanh":"109"
   },
   {
      "id":"10907",
      "quanhuyen":"Huyện Ân Thi",
      "tinhthanh":"109"
   },
   {
      "id":"10906",
      "quanhuyen":"Huyện Khoái Châu",
      "tinhthanh":"109"
   },
   {
      "id":"10909",
      "quanhuyen":"Huyện Kim Động",
      "tinhthanh":"109"
   },
   {
      "id":"10913",
      "quanhuyen":"Huyện Tiên Lữ",
      "tinhthanh":"109"
   },
   {
      "id":"10911",
      "quanhuyen":"Huyện Phù Cừ",
      "tinhthanh":"109"
   },
   {
      "id":"11500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"115"
   },
   {
      "id":"11501",
      "quanhuyen":"TP. Thái Bình",
      "tinhthanh":"115"
   },
   {
      "id":"11503",
      "quanhuyen":"Huyện Quỳnh Phụ",
      "tinhthanh":"115"
   },
   {
      "id":"11505",
      "quanhuyen":"Huyện Hưng Hà",
      "tinhthanh":"115"
   },
   {
      "id":"11509",
      "quanhuyen":"Huyện Đông Hưng",
      "tinhthanh":"115"
   },
   {
      "id":"11507",
      "quanhuyen":"Huyện Thái Thụy",
      "tinhthanh":"115"
   },
   {
      "id":"11515",
      "quanhuyen":"Huyện Tiền Hải",
      "tinhthanh":"115"
   },
   {
      "id":"11513",
      "quanhuyen":"Huyện Kiến Xương",
      "tinhthanh":"115"
   },
   {
      "id":"11511",
      "quanhuyen":"Huyện Vũ Thư",
      "tinhthanh":"115"
   },
   {
      "id":"11100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"111"
   },
   {
      "id":"50513",
      "quanhuyen":"Huyện Sơn Hà",
      "tinhthanh":"505"
   },
   {
      "id":"50511",
      "quanhuyen":"Huyện Sơn Tây",
      "tinhthanh":"505"
   },
   {
      "id":"50519",
      "quanhuyen":"Huyện Minh Long",
      "tinhthanh":"505"
   },
   {
      "id":"50517",
      "quanhuyen":"Huyện Nghĩa Hành",
      "tinhthanh":"505"
   },
   {
      "id":"50521",
      "quanhuyen":"Huyện Mộ Đức",
      "tinhthanh":"505"
   },
   {
      "id":"50523",
      "quanhuyen":"Thị xã Đức Phổ",
      "tinhthanh":"505"
   },
   {
      "id":"50525",
      "quanhuyen":"Huyện Ba Tơ",
      "tinhthanh":"505"
   },
   {
      "id":"50503",
      "quanhuyen":"Huyện Lý Sơn",
      "tinhthanh":"505"
   },
   {
      "id":"50700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"507"
   },
   {
      "id":"50701",
      "quanhuyen":"TP. Quy Nhơn",
      "tinhthanh":"507"
   },
   {
      "id":"50703",
      "quanhuyen":"Huyện An Lão",
      "tinhthanh":"507"
   },
   {
      "id":"50705",
      "quanhuyen":"Thị xã Hoài Nhơn",
      "tinhthanh":"507"
   },
   {
      "id":"50707",
      "quanhuyen":"Huyện Hoài Ân",
      "tinhthanh":"507"
   },
   {
      "id":"50709",
      "quanhuyen":"Huyện Phù Mỹ",
      "tinhthanh":"507"
   },
   {
      "id":"50711",
      "quanhuyen":"Huyện Vĩnh Thạnh",
      "tinhthanh":"507"
   },
   {
      "id":"50715",
      "quanhuyen":"Huyện Tây Sơn",
      "tinhthanh":"507"
   },
   {
      "id":"50717",
      "quanhuyen":"Thị xã An Nhơn",
      "tinhthanh":"507"
   },
   {
      "id":"50719",
      "quanhuyen":"Huyện Tuy Phước",
      "tinhthanh":"507"
   },
   {
      "id":"50721",
      "quanhuyen":"Huyện Vân Canh",
      "tinhthanh":"507"
   },
   {
      "id":"50900",
      "quanhuyen":"Không xác định",
      "tinhthanh":"509"
   },
   {
      "id":"50911",
      "quanhuyen":"TP. Tuy Hòa",
      "tinhthanh":"509"
   },
   {
      "id":"50905",
      "quanhuyen":"Thị xã Sông Cầu",
      "tinhthanh":"509"
   },
   {
      "id":"50903",
      "quanhuyen":"Huyện Đồng Xuân",
      "tinhthanh":"509"
   },
   {
      "id":"50907",
      "quanhuyen":"Huyện Tuy An",
      "tinhthanh":"509"
   },
   {
      "id":"50909",
      "quanhuyen":"Huyện Sơn Hòa",
      "tinhthanh":"509"
   },
   {
      "id":"50913",
      "quanhuyen":"Huyện Sông Hinh",
      "tinhthanh":"509"
   },
   {
      "id":"50901",
      "quanhuyen":"Huyện Tuy Hòa",
      "tinhthanh":"509"
   },
   {
      "id":"50915",
      "quanhuyen":"Huyện Phú Hoà",
      "tinhthanh":"509"
   },
   {
      "id":"50917",
      "quanhuyen":"Huyện Tây Hoà",
      "tinhthanh":"509"
   },
   {
      "id":"50919",
      "quanhuyen":"Thị xã Đông Hoà",
      "tinhthanh":"509"
   },
   {
      "id":"51100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"511"
   },
   {
      "id":"51101",
      "quanhuyen":"TP. Nha Trang",
      "tinhthanh":"511"
   },
   {
      "id":"51109",
      "quanhuyen":"TP. Cam Ranh",
      "tinhthanh":"511"
   },
   {
      "id":"51103",
      "quanhuyen":"Huyện Vạn Ninh",
      "tinhthanh":"511"
   },
   {
      "id":"51105",
      "quanhuyen":"Thị xã Ninh Hòa",
      "tinhthanh":"511"
   },
   {
      "id":"51111",
      "quanhuyen":"Huyện Khánh Vĩnh",
      "tinhthanh":"511"
   },
   {
      "id":"51107",
      "quanhuyen":"Huyện Diên Khánh",
      "tinhthanh":"511"
   },
   {
      "id":"51113",
      "quanhuyen":"Huyện Khánh Sơn",
      "tinhthanh":"511"
   },
   {
      "id":"51115",
      "quanhuyen":"Huyện Trường Sa",
      "tinhthanh":"511"
   },
   {
      "id":"51117",
      "quanhuyen":"Huyện Cam Lâm",
      "tinhthanh":"511"
   },
   {
      "id":"70500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"705"
   },
   {
      "id":"70501",
      "quanhuyen":"TP. Phan Rang-Tháp Chàm",
      "tinhthanh":"705"
   },
   {
      "id":"70509",
      "quanhuyen":"Huyện Bác ái",
      "tinhthanh":"705"
   },
   {
      "id":"70503",
      "quanhuyen":"Huyện Ninh Sơn",
      "tinhthanh":"705"
   },
   {
      "id":"70505",
      "quanhuyen":"Huyện Ninh Hải",
      "tinhthanh":"705"
   },
   {
      "id":"70507",
      "quanhuyen":"Huyện Ninh Phước",
      "tinhthanh":"705"
   },
   {
      "id":"70511",
      "quanhuyen":"Huyện Thuận Bắc",
      "tinhthanh":"705"
   },
   {
      "id":"71500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"715"
   },
   {
      "id":"71501",
      "quanhuyen":"TP. Phan Thiết",
      "tinhthanh":"715"
   },
   {
      "id":"71503",
      "quanhuyen":"Huyện Tuy Phong",
      "tinhthanh":"715"
   },
   {
      "id":"71505",
      "quanhuyen":"Huyện Bắc Bình",
      "tinhthanh":"715"
   },
   {
      "id":"71507",
      "quanhuyen":"Huyện Hàm Thuận Bắc",
      "tinhthanh":"715"
   },
   {
      "id":"71509",
      "quanhuyen":"Huyện Hàm Thuận Nam",
      "tinhthanh":"715"
   },
   {
      "id":"71511",
      "quanhuyen":"Huyện Tánh Linh",
      "tinhthanh":"715"
   },
   {
      "id":"71515",
      "quanhuyen":"Huyện Đức Linh",
      "tinhthanh":"715"
   },
   {
      "id":"71513",
      "quanhuyen":"Huyện Hàm Tân",
      "tinhthanh":"715"
   },
   {
      "id":"71517",
      "quanhuyen":"Huyện Phú Quí",
      "tinhthanh":"715"
   },
   {
      "id":"71519",
      "quanhuyen":"Thị xã La Gi",
      "tinhthanh":"715"
   },
   {
      "id":"60100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"601"
   },
   {
      "id":"60101",
      "quanhuyen":"Thị xã Kon Tum",
      "tinhthanh":"601"
   },
   {
      "id":"60103",
      "quanhuyen":"Huyện Đắk Glei",
      "tinhthanh":"601"
   },
   {
      "id":"60105",
      "quanhuyen":"Huyện Ngọc Hồi",
      "tinhthanh":"601"
   },
   {
      "id":"60107",
      "quanhuyen":"Huyện Đắk Tô",
      "tinhthanh":"601"
   },
   {
      "id":"60109",
      "quanhuyen":"Huyện Kon Plông",
      "tinhthanh":"601"
   },
   {
      "id":"60115",
      "quanhuyen":"Huyện Kon Rẫy",
      "tinhthanh":"601"
   },
   {
      "id":"60111",
      "quanhuyen":"Huyện Đắk Hà",
      "tinhthanh":"601"
   },
   {
      "id":"60113",
      "quanhuyen":"Huyện Sa Thầy",
      "tinhthanh":"601"
   },
   {
      "id":"60117",
      "quanhuyen":"Huyện Tu Mơ Rông",
      "tinhthanh":"601"
   },
   {
      "id":"60300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"603"
   },
   {
      "id":"60301",
      "quanhuyen":"TP. Pleiku",
      "tinhthanh":"603"
   },
   {
      "id":"60311",
      "quanhuyen":"Thị xã An Khê",
      "tinhthanh":"603"
   },
   {
      "id":"60303",
      "quanhuyen":"Huyện KBang",
      "tinhthanh":"603"
   },
   {
      "id":"60325",
      "quanhuyen":"Huyện Đăk Đoa",
      "tinhthanh":"603"
   },
   {
      "id":"60307",
      "quanhuyen":"Huyện Chư Păh",
      "tinhthanh":"603"
   },
   {
      "id":"60309",
      "quanhuyen":"Huyện Ia Grai",
      "tinhthanh":"603"
   },
   {
      "id":"11101",
      "quanhuyen":"TP. Phủ Lý",
      "tinhthanh":"111"
   },
   {
      "id":"11103",
      "quanhuyen":"Huyện Duy Tiên",
      "tinhthanh":"111"
   },
   {
      "id":"11105",
      "quanhuyen":"Huyện Kim Bảng",
      "tinhthanh":"111"
   },
   {
      "id":"11109",
      "quanhuyen":"Huyện Thanh Liêm",
      "tinhthanh":"111"
   },
   {
      "id":"11111",
      "quanhuyen":"Huyện Bình Lục",
      "tinhthanh":"111"
   },
   {
      "id":"11107",
      "quanhuyen":"Huyện Lý Nhân",
      "tinhthanh":"111"
   },
   {
      "id":"11300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"113"
   },
   {
      "id":"11301",
      "quanhuyen":"TP. Nam Định",
      "tinhthanh":"113"
   },
   {
      "id":"11305",
      "quanhuyen":"Huyện Mỹ Lộc",
      "tinhthanh":"113"
   },
   {
      "id":"11303",
      "quanhuyen":"Huyện Vụ Bản",
      "tinhthanh":"113"
   },
   {
      "id":"11307",
      "quanhuyen":"Huyện Ý Yên",
      "tinhthanh":"113"
   },
   {
      "id":"11317",
      "quanhuyen":"Huyện Nghĩa Hưng",
      "tinhthanh":"113"
   },
   {
      "id":"11309",
      "quanhuyen":"Huyện Nam Trực",
      "tinhthanh":"113"
   },
   {
      "id":"11311",
      "quanhuyen":"Huyện Trực Ninh",
      "tinhthanh":"113"
   },
   {
      "id":"11313",
      "quanhuyen":"Huyện Xuân Trường",
      "tinhthanh":"113"
   },
   {
      "id":"11315",
      "quanhuyen":"Huyện Giao Thủy",
      "tinhthanh":"113"
   },
   {
      "id":"11319",
      "quanhuyen":"Huyện Hải Hậu",
      "tinhthanh":"113"
   },
   {
      "id":"11700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"117"
   },
   {
      "id":"11701",
      "quanhuyen":"TP. Ninh Bình",
      "tinhthanh":"117"
   },
   {
      "id":"11705",
      "quanhuyen":"Huyện Nho Quan",
      "tinhthanh":"117"
   },
   {
      "id":"11707",
      "quanhuyen":"Huyện Gia Viễn",
      "tinhthanh":"117"
   },
   {
      "id":"11709",
      "quanhuyen":"Huyện Hoa Lư",
      "tinhthanh":"117"
   },
   {
      "id":"11713",
      "quanhuyen":"Huyện Yên Khánh",
      "tinhthanh":"117"
   },
   {
      "id":"11715",
      "quanhuyen":"Huyện Kim Sơn",
      "tinhthanh":"117"
   },
   {
      "id":"11711",
      "quanhuyen":"Huyện Yên Mô",
      "tinhthanh":"117"
   },
   {
      "id":"40100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"401"
   },
   {
      "id":"40101",
      "quanhuyen":"TP. Thanh Hóa",
      "tinhthanh":"401"
   },
   {
      "id":"40103",
      "quanhuyen":"Thị xã Bỉm Sơn",
      "tinhthanh":"401"
   },
   {
      "id":"40105",
      "quanhuyen":"TP. Sầm Sơn",
      "tinhthanh":"401"
   },
   {
      "id":"40107",
      "quanhuyen":"Huyện Mường Lát",
      "tinhthanh":"401"
   },
   {
      "id":"40109",
      "quanhuyen":"Huyện Quan Hóa",
      "tinhthanh":"401"
   },
   {
      "id":"40113",
      "quanhuyen":"Huyện Bá Thước",
      "tinhthanh":"401"
   },
   {
      "id":"40111",
      "quanhuyen":"Huyện Quan Sơn",
      "tinhthanh":"401"
   },
   {
      "id":"40117",
      "quanhuyen":"Huyện Lang Chánh",
      "tinhthanh":"401"
   },
   {
      "id":"40121",
      "quanhuyen":"Huyện Ngọc Lạc",
      "tinhthanh":"401"
   },
   {
      "id":"40115",
      "quanhuyen":"Huyện Cẩm Thủy",
      "tinhthanh":"401"
   },
   {
      "id":"40119",
      "quanhuyen":"Huyện Thạch Thành",
      "tinhthanh":"401"
   },
   {
      "id":"40131",
      "quanhuyen":"Huyện Hà Trung",
      "tinhthanh":"401"
   },
   {
      "id":"40129",
      "quanhuyen":"Huyện Vĩnh Lộc",
      "tinhthanh":"401"
   },
   {
      "id":"40135",
      "quanhuyen":"Huyện Yên Định",
      "tinhthanh":"401"
   },
   {
      "id":"40137",
      "quanhuyen":"Huyện Thọ Xuân",
      "tinhthanh":"401"
   },
   {
      "id":"40123",
      "quanhuyen":"Huyện Thường Xuân",
      "tinhthanh":"401"
   },
   {
      "id":"40147",
      "quanhuyen":"Huyện Triệu Sơn",
      "tinhthanh":"401"
   },
   {
      "id":"40141",
      "quanhuyen":"Huyện Thiệu Hoá",
      "tinhthanh":"401"
   },
   {
      "id":"40143",
      "quanhuyen":"Huyện Hoằng Hóa",
      "tinhthanh":"401"
   },
   {
      "id":"40139",
      "quanhuyen":"Huyện Hậu Lộc",
      "tinhthanh":"401"
   },
   {
      "id":"40133",
      "quanhuyen":"Huyện Nga Sơn",
      "tinhthanh":"401"
   },
   {
      "id":"40125",
      "quanhuyen":"Huyện Như Xuân",
      "tinhthanh":"401"
   },
   {
      "id":"40127",
      "quanhuyen":"Huyện Như Thanh",
      "tinhthanh":"401"
   },
   {
      "id":"40151",
      "quanhuyen":"Huyện Nông Cống",
      "tinhthanh":"401"
   },
   {
      "id":"40145",
      "quanhuyen":"Huyện Đông Sơn",
      "tinhthanh":"401"
   },
   {
      "id":"40149",
      "quanhuyen":"Huyện Quảng Xương",
      "tinhthanh":"401"
   },
   {
      "id":"40153",
      "quanhuyen":"Thị xã Nghi Sơn",
      "tinhthanh":"401"
   },
   {
      "id":"40300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"403"
   },
   {
      "id":"40301",
      "quanhuyen":"TP. Vinh",
      "tinhthanh":"403"
   },
   {
      "id":"40303",
      "quanhuyen":"Thị xã Cửa Lò",
      "tinhthanh":"403"
   },
   {
      "id":"40305",
      "quanhuyen":"Huyện Quế Phong",
      "tinhthanh":"403"
   },
   {
      "id":"40307",
      "quanhuyen":"Huyện Quỳ Châu",
      "tinhthanh":"403"
   },
   {
      "id":"40309",
      "quanhuyen":"Huyện Kỳ Sơn",
      "tinhthanh":"403"
   },
   {
      "id":"40315",
      "quanhuyen":"Huyện Tương Dương",
      "tinhthanh":"403"
   },
   {
      "id":"40313",
      "quanhuyen":"Huyện Nghĩa Đàn",
      "tinhthanh":"403"
   },
   {
      "id":"40311",
      "quanhuyen":"Huyện Quỳ Hợp",
      "tinhthanh":"403"
   },
   {
      "id":"40317",
      "quanhuyen":"Huyện Quỳnh Lưu",
      "tinhthanh":"403"
   },
   {
      "id":"40321",
      "quanhuyen":"Huyện Con Cuông",
      "tinhthanh":"403"
   },
   {
      "id":"40319",
      "quanhuyen":"Huyện Tân Kỳ",
      "tinhthanh":"403"
   },
   {
      "id":"40327",
      "quanhuyen":"Huyện Anh Sơn",
      "tinhthanh":"403"
   },
   {
      "id":"40325",
      "quanhuyen":"Huyện Diễn Châu",
      "tinhthanh":"403"
   },
   {
      "id":"40323",
      "quanhuyen":"Huyện Yên Thành",
      "tinhthanh":"403"
   },
   {
      "id":"40329",
      "quanhuyen":"Huyện Đô Lương",
      "tinhthanh":"403"
   },
   {
      "id":"40331",
      "quanhuyen":"Huyện Thanh Chương",
      "tinhthanh":"403"
   },
   {
      "id":"40333",
      "quanhuyen":"Huyện Nghi Lộc",
      "tinhthanh":"403"
   },
   {
      "id":"40335",
      "quanhuyen":"Huyện Nam Đàn",
      "tinhthanh":"403"
   },
   {
      "id":"40337",
      "quanhuyen":"Huyện Hưng Nguyên",
      "tinhthanh":"403"
   },
   {
      "id":"40339",
      "quanhuyen":"Huyện Thái Hoà",
      "tinhthanh":"403"
   },
   {
      "id":"40500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"405"
   },
   {
      "id":"40501",
      "quanhuyen":"TP. Hà Tĩnh",
      "tinhthanh":"405"
   },
   {
      "id":"40503",
      "quanhuyen":"Thị xã Hồng Lĩnh",
      "tinhthanh":"405"
   },
   {
      "id":"40509",
      "quanhuyen":"Huyện Hương Sơn",
      "tinhthanh":"405"
   },
   {
      "id":"40507",
      "quanhuyen":"Huyện Đức Thọ",
      "tinhthanh":"405"
   },
   {
      "id":"40521",
      "quanhuyen":"Huyện Vũ Quang",
      "tinhthanh":"405"
   },
   {
      "id":"40505",
      "quanhuyen":"Huyện Nghi Xuân",
      "tinhthanh":"405"
   },
   {
      "id":"40511",
      "quanhuyen":"Huyện Can Lộc",
      "tinhthanh":"405"
   },
   {
      "id":"40517",
      "quanhuyen":"Huyện Hương Khê",
      "tinhthanh":"405"
   },
   {
      "id":"40513",
      "quanhuyen":"Huyện Thạch Hà",
      "tinhthanh":"405"
   },
   {
      "id":"40515",
      "quanhuyen":"Huyện Cẩm Xuyên",
      "tinhthanh":"405"
   },
   {
      "id":"40519",
      "quanhuyen":"Huyện Kỳ Anh",
      "tinhthanh":"405"
   },
   {
      "id":"40523",
      "quanhuyen":"Huyện Lộc Hà",
      "tinhthanh":"405"
   },
   {
      "id":"40700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"407"
   },
   {
      "id":"40701",
      "quanhuyen":"TP. Đồng Hới",
      "tinhthanh":"407"
   },
   {
      "id":"40705",
      "quanhuyen":"Huyện Minh Hóa",
      "tinhthanh":"407"
   },
   {
      "id":"40703",
      "quanhuyen":"Huyện Tuyên Hóa",
      "tinhthanh":"407"
   },
   {
      "id":"40707",
      "quanhuyen":"Huyện Quảng Trạch",
      "tinhthanh":"407"
   },
   {
      "id":"40709",
      "quanhuyen":"Huyện Bố Trạch",
      "tinhthanh":"407"
   },
   {
      "id":"40711",
      "quanhuyen":"Huyện Quảng Ninh",
      "tinhthanh":"407"
   },
   {
      "id":"40713",
      "quanhuyen":"Huyện Lệ Thủy",
      "tinhthanh":"407"
   },
   {
      "id":"40900",
      "quanhuyen":"Không xác định",
      "tinhthanh":"409"
   },
   {
      "id":"40901",
      "quanhuyen":"TP. Đông Hà",
      "tinhthanh":"409"
   },
   {
      "id":"40903",
      "quanhuyen":"Thị xã Quảng Trị",
      "tinhthanh":"409"
   },
   {
      "id":"40905",
      "quanhuyen":"Huyện Vĩnh Linh",
      "tinhthanh":"409"
   },
   {
      "id":"40915",
      "quanhuyen":"Huyện Hướng Hóa",
      "tinhthanh":"409"
   },
   {
      "id":"40907",
      "quanhuyen":"Huyện Gio Linh",
      "tinhthanh":"409"
   },
   {
      "id":"40917",
      "quanhuyen":"Huyện Đa Krông",
      "tinhthanh":"409"
   },
   {
      "id":"40909",
      "quanhuyen":"Huyện Cam Lộ",
      "tinhthanh":"409"
   },
   {
      "id":"40911",
      "quanhuyen":"Huyện Triệu Phong",
      "tinhthanh":"409"
   },
   {
      "id":"40913",
      "quanhuyen":"Huyện Hải Lăng",
      "tinhthanh":"409"
   },
   {
      "id":"40919",
      "quanhuyen":"Huyện Đảo Cồn Cỏ",
      "tinhthanh":"409"
   },
   {
      "id":"41100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"411"
   },
   {
      "id":"41101",
      "quanhuyen":"TP. Huế",
      "tinhthanh":"411"
   },
   {
      "id":"41103",
      "quanhuyen":"Huyện Phong Điền",
      "tinhthanh":"411"
   },
   {
      "id":"41105",
      "quanhuyen":"Huyện Quảng Điền",
      "tinhthanh":"411"
   },
   {
      "id":"41109",
      "quanhuyen":"Huyện Phú Vang",
      "tinhthanh":"411"
   },
   {
      "id":"41111",
      "quanhuyen":"Thị xã Hương Thủy",
      "tinhthanh":"411"
   },
   {
      "id":"41107",
      "quanhuyen":"Thị xã Hương Trà",
      "tinhthanh":"411"
   },
   {
      "id":"41115",
      "quanhuyen":"Huyện A Lưới",
      "tinhthanh":"411"
   },
   {
      "id":"41113",
      "quanhuyen":"Huyện Phú Lộc",
      "tinhthanh":"411"
   },
   {
      "id":"41117",
      "quanhuyen":"Huyện Nam Đông",
      "tinhthanh":"411"
   },
   {
      "id":"50100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"501"
   },
   {
      "id":"50109",
      "quanhuyen":"Quận Liên Chiểu",
      "tinhthanh":"501"
   },
   {
      "id":"50103",
      "quanhuyen":"Quận Thanh Khê",
      "tinhthanh":"501"
   },
   {
      "id":"50101",
      "quanhuyen":"Quận Hải Châu",
      "tinhthanh":"501"
   },
   {
      "id":"50105",
      "quanhuyen":"Quận Sơn Trà",
      "tinhthanh":"501"
   },
   {
      "id":"50107",
      "quanhuyen":"Quận Ngũ Hành Sơn",
      "tinhthanh":"501"
   },
   {
      "id":"50111",
      "quanhuyen":"Huyện Hòa Vang",
      "tinhthanh":"501"
   },
   {
      "id":"50113",
      "quanhuyen":"Huyện Hoàng Sa",
      "tinhthanh":"501"
   },
   {
      "id":"50115",
      "quanhuyen":"Quận Cẩm Lệ",
      "tinhthanh":"501"
   },
   {
      "id":"50300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"503"
   },
   {
      "id":"50301",
      "quanhuyen":"TP. Tam Kỳ",
      "tinhthanh":"503"
   },
   {
      "id":"50303",
      "quanhuyen":"TP. Hội An",
      "tinhthanh":"503"
   },
   {
      "id":"50327",
      "quanhuyen":"Huyện Tây Giang",
      "tinhthanh":"503"
   },
   {
      "id":"50329",
      "quanhuyen":"Huyện Đông Giang",
      "tinhthanh":"503"
   },
   {
      "id":"50307",
      "quanhuyen":"Huyện Đại Lộc",
      "tinhthanh":"503"
   },
   {
      "id":"50309",
      "quanhuyen":"Thị xã Điện Bàn",
      "tinhthanh":"503"
   },
   {
      "id":"50311",
      "quanhuyen":"Huyện Duy Xuyên",
      "tinhthanh":"503"
   },
   {
      "id":"50317",
      "quanhuyen":"Huyện Quế Sơn",
      "tinhthanh":"503"
   },
   {
      "id":"50331",
      "quanhuyen":"Huyện Nam Giang",
      "tinhthanh":"503"
   },
   {
      "id":"50323",
      "quanhuyen":"Huyện Phước Sơn",
      "tinhthanh":"503"
   },
   {
      "id":"50319",
      "quanhuyen":"Huyện Hiệp Đức",
      "tinhthanh":"503"
   },
   {
      "id":"50315",
      "quanhuyen":"Huyện Thăng Bình",
      "tinhthanh":"503"
   },
   {
      "id":"50321",
      "quanhuyen":"Huyện Tiên Phước",
      "tinhthanh":"503"
   },
   {
      "id":"50333",
      "quanhuyen":"Huyện Bắc Trà My",
      "tinhthanh":"503"
   },
   {
      "id":"50335",
      "quanhuyen":"Huyện Nam Trà My",
      "tinhthanh":"503"
   },
   {
      "id":"50325",
      "quanhuyen":"Huyện Núi Thành",
      "tinhthanh":"503"
   },
   {
      "id":"50326",
      "quanhuyen":"Huyện Phú Ninh",
      "tinhthanh":"503"
   },
   {
      "id":"50337",
      "quanhuyen":"Huyện Nông Sơn",
      "tinhthanh":"503"
   },
   {
      "id":"50500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"505"
   },
   {
      "id":"50501",
      "quanhuyen":"TP. Quảng Ngãi",
      "tinhthanh":"505"
   },
   {
      "id":"50505",
      "quanhuyen":"Huyện Bình Sơn",
      "tinhthanh":"505"
   },
   {
      "id":"50507",
      "quanhuyen":"Huyện Trà Bồng",
      "tinhthanh":"505"
   },
   {
      "id":"50527",
      "quanhuyen":"Huyện Tây Trà",
      "tinhthanh":"505"
   },
   {
      "id":"50509",
      "quanhuyen":"Huyện Sơn Tịnh",
      "tinhthanh":"505"
   },
   {
      "id":"50515",
      "quanhuyen":"Huyện Tư Nghĩa",
      "tinhthanh":"505"
   },
   {
      "id":"21715",
      "quanhuyen":"Huyện Yên Lập",
      "tinhthanh":"217"
   },
   {
      "id":"21721",
      "quanhuyen":"Huyện Cẩm Khê",
      "tinhthanh":"217"
   },
   {
      "id":"21713",
      "quanhuyen":"Huyện Tam Nông",
      "tinhthanh":"217"
   },
   {
      "id":"21712",
      "quanhuyen":"Huyện Lâm Thao",
      "tinhthanh":"217"
   },
   {
      "id":"21719",
      "quanhuyen":"Huyện Thanh Sơn",
      "tinhthanh":"217"
   },
   {
      "id":"21718",
      "quanhuyen":"Huyện Thanh Thuỷ",
      "tinhthanh":"217"
   },
   {
      "id":"21717",
      "quanhuyen":"Huyện Tân Sơn",
      "tinhthanh":"217"
   },
   {
      "id":"10400",
      "quanhuyen":"Không xác định",
      "tinhthanh":"104"
   },
   {
      "id":"10401",
      "quanhuyen":"TP. Vĩnh Yên",
      "tinhthanh":"104"
   },
   {
      "id":"10411",
      "quanhuyen":"TP. Phúc Yên",
      "tinhthanh":"104"
   },
   {
      "id":"10403",
      "quanhuyen":"Huyện Lập Thạch",
      "tinhthanh":"104"
   },
   {
      "id":"10405",
      "quanhuyen":"Huyện Tam Dương",
      "tinhthanh":"104"
   },
   {
      "id":"10414",
      "quanhuyen":"Huyện Tam Đảo",
      "tinhthanh":"104"
   },
   {
      "id":"10413",
      "quanhuyen":"Huyện Bình Xuyên",
      "tinhthanh":"104"
   },
   {
      "id":"10409",
      "quanhuyen":"Huyện Yên Lạc",
      "tinhthanh":"104"
   },
   {
      "id":"10407",
      "quanhuyen":"Huyện Vĩnh Tường",
      "tinhthanh":"104"
   },
   {
      "id":"10415",
      "quanhuyen":"Huyện Sông Lô",
      "tinhthanh":"104"
   },
   {
      "id":"22300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"223"
   },
   {
      "id":"22301",
      "quanhuyen":"TP. Bắc Ninh",
      "tinhthanh":"223"
   },
   {
      "id":"22303",
      "quanhuyen":"Huyện Yên Phong",
      "tinhthanh":"223"
   },
   {
      "id":"22305",
      "quanhuyen":"Huyện Quế Võ",
      "tinhthanh":"223"
   },
   {
      "id":"22307",
      "quanhuyen":"Huyện Tiên Du",
      "tinhthanh":"223"
   },
   {
      "id":"22308",
      "quanhuyen":"Thị xã Từ Sơn",
      "tinhthanh":"223"
   },
   {
      "id":"22309",
      "quanhuyen":"Huyện Thuận Thành",
      "tinhthanh":"223"
   },
   {
      "id":"22312",
      "quanhuyen":"Huyện Gia Bình",
      "tinhthanh":"223"
   },
   {
      "id":"22311",
      "quanhuyen":"Huyện Lương Tài",
      "tinhthanh":"223"
   },
   {
      "id":"10700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"107"
   },
   {
      "id":"10701",
      "quanhuyen":"TP. Hải Dương",
      "tinhthanh":"107"
   },
   {
      "id":"10703",
      "quanhuyen":"TP. Chí Linh",
      "tinhthanh":"107"
   },
   {
      "id":"10705",
      "quanhuyen":"Huyện Nam Sách",
      "tinhthanh":"107"
   },
   {
      "id":"10709",
      "quanhuyen":"Huyện Kinh Môn",
      "tinhthanh":"107"
   },
   {
      "id":"10711",
      "quanhuyen":"Huyện Kim Thành",
      "tinhthanh":"107"
   },
   {
      "id":"10707",
      "quanhuyen":"Huyện Thanh Hà",
      "tinhthanh":"107"
   },
   {
      "id":"10717",
      "quanhuyen":"Huyện Cẩm Giàng",
      "tinhthanh":"107"
   },
   {
      "id":"10719",
      "quanhuyen":"Huyện Bình Giang",
      "tinhthanh":"107"
   },
   {
      "id":"10713",
      "quanhuyen":"Huyện Gia Lộc",
      "tinhthanh":"107"
   },
   {
      "id":"10715",
      "quanhuyen":"Huyện Tứ Kỳ",
      "tinhthanh":"107"
   },
   {
      "id":"10723",
      "quanhuyen":"Huyện Ninh Giang",
      "tinhthanh":"107"
   },
   {
      "id":"80503",
      "quanhuyen":"TP. Châu Đốc",
      "tinhthanh":"805"
   },
   {
      "id":"80505",
      "quanhuyen":"Huyện An Phú",
      "tinhthanh":"805"
   },
   {
      "id":"80507",
      "quanhuyen":"Huyện Tân Châu",
      "tinhthanh":"805"
   },
   {
      "id":"80509",
      "quanhuyen":"Huyện Phú Tân",
      "tinhthanh":"805"
   },
   {
      "id":"80513",
      "quanhuyen":"Huyện Tịnh Biên",
      "tinhthanh":"805"
   },
   {
      "id":"80515",
      "quanhuyen":"Huyện Tri Tôn",
      "tinhthanh":"805"
   },
   {
      "id":"80517",
      "quanhuyen":"Huyện Chợ Mới",
      "tinhthanh":"805"
   },
   {
      "id":"80519",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"805"
   },
   {
      "id":"80521",
      "quanhuyen":"Huyện Thoại Sơn",
      "tinhthanh":"805"
   },
   {
      "id":"81300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"813"
   },
   {
      "id":"81301",
      "quanhuyen":"TP. Rạch Giá",
      "tinhthanh":"813"
   },
   {
      "id":"81325",
      "quanhuyen":"TP. Hà Tiên",
      "tinhthanh":"813"
   },
   {
      "id":"81326",
      "quanhuyen":"Huyện Kiên Lương",
      "tinhthanh":"813"
   },
   {
      "id":"81305",
      "quanhuyen":"Huyện Hòn Đất",
      "tinhthanh":"813"
   },
   {
      "id":"81307",
      "quanhuyen":"Huyện Tân Hiệp",
      "tinhthanh":"813"
   },
   {
      "id":"81309",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"813"
   },
   {
      "id":"81311",
      "quanhuyen":"Huyện Giồng Riềng",
      "tinhthanh":"813"
   },
   {
      "id":"81313",
      "quanhuyen":"Huyện Gò Quao",
      "tinhthanh":"813"
   },
   {
      "id":"81315",
      "quanhuyen":"Huyện An Biên",
      "tinhthanh":"813"
   },
   {
      "id":"81317",
      "quanhuyen":"Huyện An Minh",
      "tinhthanh":"813"
   },
   {
      "id":"81319",
      "quanhuyen":"Huyện Vĩnh Thuận",
      "tinhthanh":"813"
   },
   {
      "id":"81321",
      "quanhuyen":"TP. Phú Quốc",
      "tinhthanh":"813"
   },
   {
      "id":"81323",
      "quanhuyen":"Huyện Kiên Hải",
      "tinhthanh":"813"
   },
   {
      "id":"81327",
      "quanhuyen":"Huyện U Minh Thượng",
      "tinhthanh":"813"
   },
   {
      "id":"81500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"815"
   },
   {
      "id":"81520",
      "quanhuyen":"Quận Ninh Kiều",
      "tinhthanh":"815"
   },
   {
      "id":"81505",
      "quanhuyen":"Quận Ô Môn",
      "tinhthanh":"815"
   },
   {
      "id":"81518",
      "quanhuyen":"Quận Bình Thuỷ",
      "tinhthanh":"815"
   },
   {
      "id":"81515",
      "quanhuyen":"Quận Cái Răng",
      "tinhthanh":"815"
   },
   {
      "id":"81503",
      "quanhuyen":"Quận Thốt Nốt",
      "tinhthanh":"815"
   },
   {
      "id":"81519",
      "quanhuyen":"Huyện Vĩnh Thạnh",
      "tinhthanh":"815"
   },
   {
      "id":"81514",
      "quanhuyen":"Huyện Cờ Đỏ",
      "tinhthanh":"815"
   },
   {
      "id":"81517",
      "quanhuyen":"Huyện Phong Điền",
      "tinhthanh":"815"
   },
   {
      "id":"81516",
      "quanhuyen":"Huyện Thới Lai",
      "tinhthanh":"815"
   },
   {
      "id":"82500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"825"
   },
   {
      "id":"82583",
      "quanhuyen":"TP. Vị Thanh",
      "tinhthanh":"825"
   },
   {
      "id":"82587",
      "quanhuyen":"Huyện Châu Thành A",
      "tinhthanh":"825"
   },
   {
      "id":"82582",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"825"
   },
   {
      "id":"82584",
      "quanhuyen":"Huyện Phụng Hiệp",
      "tinhthanh":"825"
   },
   {
      "id":"82585",
      "quanhuyen":"Huyện Vị Thuỷ",
      "tinhthanh":"825"
   },
   {
      "id":"82586",
      "quanhuyen":"Huyện Long Mỹ",
      "tinhthanh":"825"
   },
   {
      "id":"82502",
      "quanhuyen":"Thị xã Ngã Bảy",
      "tinhthanh":"825"
   },
   {
      "id":"81900",
      "quanhuyen":"Không xác định",
      "tinhthanh":"819"
   },
   {
      "id":"81901",
      "quanhuyen":"TP. Sóc Trăng",
      "tinhthanh":"819"
   },
   {
      "id":"81903",
      "quanhuyen":"Huyện Kế Sách",
      "tinhthanh":"819"
   },
   {
      "id":"81907",
      "quanhuyen":"Huyện Mỹ Tú",
      "tinhthanh":"819"
   },
   {
      "id":"81918",
      "quanhuyen":"Huyện Cù Lao Dung",
      "tinhthanh":"819"
   },
   {
      "id":"81905",
      "quanhuyen":"Huyện Long Phú",
      "tinhthanh":"819"
   },
   {
      "id":"81909",
      "quanhuyen":"Huyện Mỹ Xuyên",
      "tinhthanh":"819"
   },
   {
      "id":"81915",
      "quanhuyen":"Huyện Ngã Năm",
      "tinhthanh":"819"
   },
   {
      "id":"81911",
      "quanhuyen":"Huyện Thạnh Trị",
      "tinhthanh":"819"
   },
   {
      "id":"81913",
      "quanhuyen":"Huyện Vĩnh Châu",
      "tinhthanh":"819"
   },
   {
      "id":"81917",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"819"
   },
   {
      "id":"82100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"821"
   },
   {
      "id":"82101",
      "quanhuyen":"Tp. Bạc Liêu",
      "tinhthanh":"821"
   },
   {
      "id":"82103",
      "quanhuyen":"Huyện Hồng Dân",
      "tinhthanh":"821"
   },
   {
      "id":"82102",
      "quanhuyen":"Huyện Phước Long",
      "tinhthanh":"821"
   },
   {
      "id":"82105",
      "quanhuyen":"Huyện Vĩnh Lợi",
      "tinhthanh":"821"
   },
   {
      "id":"82107",
      "quanhuyen":"Thị xã Giá Rai",
      "tinhthanh":"821"
   },
   {
      "id":"82182",
      "quanhuyen":"Huyện Đông Hải",
      "tinhthanh":"821"
   },
   {
      "id":"82108",
      "quanhuyen":"Huyện Hòa Bình",
      "tinhthanh":"821"
   },
   {
      "id":"82300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"823"
   },
   {
      "id":"82301",
      "quanhuyen":"TP. Cà Mau",
      "tinhthanh":"823"
   },
   {
      "id":"82305",
      "quanhuyen":"Huyện U Minh",
      "tinhthanh":"823"
   },
   {
      "id":"82303",
      "quanhuyen":"Huyện Thới Bình",
      "tinhthanh":"823"
   },
   {
      "id":"82307",
      "quanhuyen":"Huyện Trần Văn Thời",
      "tinhthanh":"823"
   },
   {
      "id":"82309",
      "quanhuyen":"Huyện Cái Nước",
      "tinhthanh":"823"
   },
   {
      "id":"82311",
      "quanhuyen":"Huyện Đầm Dơi",
      "tinhthanh":"823"
   },
   {
      "id":"82314",
      "quanhuyen":"Huyện Năm Căn",
      "tinhthanh":"823"
   },
   {
      "id":"82302",
      "quanhuyen":"Huyện Phú Tân",
      "tinhthanh":"823"
   },
   {
      "id":"82313",
      "quanhuyen":"Huyện Ngọc Hiển",
      "tinhthanh":"823"
   },
   {
      "id":"60305",
      "quanhuyen":"Huyện Mang Yang",
      "tinhthanh":"603"
   },
   {
      "id":"60313",
      "quanhuyen":"Huyện Kông Chro",
      "tinhthanh":"603"
   },
   {
      "id":"60315",
      "quanhuyen":"Huyện Đức Cơ",
      "tinhthanh":"603"
   },
   {
      "id":"60317",
      "quanhuyen":"Huyện Chư Prông",
      "tinhthanh":"603"
   },
   {
      "id":"60319",
      "quanhuyen":"Huyện Chư Sê",
      "tinhthanh":"603"
   },
   {
      "id":"60327",
      "quanhuyen":"Huyện Đăk Pơ",
      "tinhthanh":"603"
   },
   {
      "id":"60329",
      "quanhuyen":"Huyện Ia Pa",
      "tinhthanh":"603"
   },
   {
      "id":"60321",
      "quanhuyen":"Thị xã Ayun Pa",
      "tinhthanh":"603"
   },
   {
      "id":"60323",
      "quanhuyen":"Huyện Krông Pa",
      "tinhthanh":"603"
   },
   {
      "id":"60331",
      "quanhuyen":"Huyện Phú Thiện",
      "tinhthanh":"603"
   },
   {
      "id":"60500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"605"
   },
   {
      "id":"60501",
      "quanhuyen":"TP. Buôn Ma Thuột",
      "tinhthanh":"605"
   },
   {
      "id":"60503",
      "quanhuyen":"Huyện Ea H'leo",
      "tinhthanh":"605"
   },
   {
      "id":"60505",
      "quanhuyen":"Huyện Ea Súp",
      "tinhthanh":"605"
   },
   {
      "id":"60511",
      "quanhuyen":"Huyện Buôn Đôn",
      "tinhthanh":"605"
   },
   {
      "id":"60513",
      "quanhuyen":"Huyện Cư M'gar",
      "tinhthanh":"605"
   },
   {
      "id":"60509",
      "quanhuyen":"Huyện Krông Búk",
      "tinhthanh":"605"
   },
   {
      "id":"60507",
      "quanhuyen":"Huyện Krông Năng",
      "tinhthanh":"605"
   },
   {
      "id":"60515",
      "quanhuyen":"Huyện Ea Kar",
      "tinhthanh":"605"
   },
   {
      "id":"60517",
      "quanhuyen":"Huyện M'Đrắk",
      "tinhthanh":"605"
   },
   {
      "id":"60525",
      "quanhuyen":"Huyện Krông Bông",
      "tinhthanh":"605"
   },
   {
      "id":"60519",
      "quanhuyen":"Huyện Krông Pắc",
      "tinhthanh":"605"
   },
   {
      "id":"60523",
      "quanhuyen":"Huyện Krông A Na",
      "tinhthanh":"605"
   },
   {
      "id":"60531",
      "quanhuyen":"Huyện Lắk",
      "tinhthanh":"605"
   },
   {
      "id":"60535",
      "quanhuyen":"Huyện Cư Kuin",
      "tinhthanh":"605"
   },
   {
      "id":"60537",
      "quanhuyen":"Thị xã Buôn Hồ",
      "tinhthanh":"605"
   },
   {
      "id":"60700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"607"
   },
   {
      "id":"60701",
      "quanhuyen":"Huyện Đắk Glong",
      "tinhthanh":"607"
   },
   {
      "id":"60521",
      "quanhuyen":"Huyện Cư Jút",
      "tinhthanh":"607"
   },
   {
      "id":"60527",
      "quanhuyen":"Huyện Đắk Mil",
      "tinhthanh":"605"
   },
   {
      "id":"60529",
      "quanhuyen":"Huyện Krông Nô",
      "tinhthanh":"605"
   },
   {
      "id":"60703",
      "quanhuyen":"Huyện Đắk Song",
      "tinhthanh":"607"
   },
   {
      "id":"60533",
      "quanhuyen":"Huyện Đắk R'Lấp",
      "tinhthanh":"605"
   },
   {
      "id":"60705",
      "quanhuyen":"TP. Gia Nghĩa",
      "tinhthanh":"607"
   },
   {
      "id":"60707",
      "quanhuyen":"Huyện Tuy Đức",
      "tinhthanh":"607"
   },
   {
      "id":"70300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"703"
   },
   {
      "id":"70301",
      "quanhuyen":"TP. Đà Lạt",
      "tinhthanh":"703"
   },
   {
      "id":"70303",
      "quanhuyen":"TP. Bảo Lộc",
      "tinhthanh":"703"
   },
   {
      "id":"70305",
      "quanhuyen":"Huyện Lạc Dương",
      "tinhthanh":"703"
   },
   {
      "id":"70311",
      "quanhuyen":"Huyện Lâm Hà",
      "tinhthanh":"703"
   },
   {
      "id":"70307",
      "quanhuyen":"Huyện Đơn Dương",
      "tinhthanh":"703"
   },
   {
      "id":"70309",
      "quanhuyen":"Huyện Đức Trọng",
      "tinhthanh":"703"
   },
   {
      "id":"70315",
      "quanhuyen":"Huyện Di Linh",
      "tinhthanh":"703"
   },
   {
      "id":"70317",
      "quanhuyen":"Huyện Đạ Huoai",
      "tinhthanh":"703"
   },
   {
      "id":"70319",
      "quanhuyen":"Huyện Đạ Tẻh",
      "tinhthanh":"703"
   },
   {
      "id":"70321",
      "quanhuyen":"Huyện Cát Tiên",
      "tinhthanh":"703"
   },
   {
      "id":"70323",
      "quanhuyen":"Huyện Đam Rông",
      "tinhthanh":"703"
   },
   {
      "id":"70700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"707"
   },
   {
      "id":"70704",
      "quanhuyen":"TP. Đồng Xoài",
      "tinhthanh":"707"
   },
   {
      "id":"70703",
      "quanhuyen":"Huyện Phước Long",
      "tinhthanh":"707"
   },
   {
      "id":"70705",
      "quanhuyen":"Huyện Lộc Ninh",
      "tinhthanh":"707"
   },
   {
      "id":"70711",
      "quanhuyen":"Huyện Đồng Phú",
      "tinhthanh":"707"
   },
   {
      "id":"70715",
      "quanhuyen":"Huyện Bù Đốp",
      "tinhthanh":"707"
   },
   {
      "id":"70709",
      "quanhuyen":"Huyện Hớn Quản",
      "tinhthanh":"707"
   },
   {
      "id":"70701",
      "quanhuyen":"Huyện Đồng Phú",
      "tinhthanh":"707"
   },
   {
      "id":"70707",
      "quanhuyen":"Huyện Bù Đăng",
      "tinhthanh":"707"
   },
   {
      "id":"70713",
      "quanhuyen":"Huyện Chơn Thành",
      "tinhthanh":"707"
   },
   {
      "id":"70900",
      "quanhuyen":"Không xác định",
      "tinhthanh":"709"
   },
   {
      "id":"70901",
      "quanhuyen":"Thị xã Tây Ninh",
      "tinhthanh":"709"
   },
   {
      "id":"70903",
      "quanhuyen":"Huyện Tân Biên",
      "tinhthanh":"709"
   },
   {
      "id":"70905",
      "quanhuyen":"Huyện Tân Châu",
      "tinhthanh":"709"
   },
   {
      "id":"70907",
      "quanhuyen":"Huyện Dương Minh Châu",
      "tinhthanh":"709"
   },
   {
      "id":"70909",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"709"
   },
   {
      "id":"70911",
      "quanhuyen":"Thị xã Hòa Thành",
      "tinhthanh":"709"
   },
   {
      "id":"70915",
      "quanhuyen":"Huyện Gò Dầu",
      "tinhthanh":"709"
   },
   {
      "id":"70913",
      "quanhuyen":"Huyện Bến Cầu",
      "tinhthanh":"709"
   },
   {
      "id":"70917",
      "quanhuyen":"Thị xã Trảng Bàng",
      "tinhthanh":"709"
   },
   {
      "id":"71100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"711"
   },
   {
      "id":"71101",
      "quanhuyen":"TP. Thủ Dầu Một",
      "tinhthanh":"711"
   },
   {
      "id":"71109",
      "quanhuyen":"Huyện Dầu Tiếng",
      "tinhthanh":"711"
   },
   {
      "id":"71103",
      "quanhuyen":"Thị xã Bến Cát",
      "tinhthanh":"711"
   },
   {
      "id":"71108",
      "quanhuyen":"Huyện Phú Giáo",
      "tinhthanh":"711"
   },
   {
      "id":"71105",
      "quanhuyen":"Huyện Bắc Tân Uyên",
      "tinhthanh":"711"
   },
   {
      "id":"71111",
      "quanhuyen":"TP. Dĩ An",
      "tinhthanh":"711"
   },
   {
      "id":"71107",
      "quanhuyen":"TP. Thuận An",
      "tinhthanh":"711"
   },
   {
      "id":"71300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"713"
   },
   {
      "id":"71301",
      "quanhuyen":"TP. Biên Hòa",
      "tinhthanh":"713"
   },
   {
      "id":"71311",
      "quanhuyen":"TP. Long Khánh",
      "tinhthanh":"713"
   },
   {
      "id":"71303",
      "quanhuyen":"Huyện Tân Phú",
      "tinhthanh":"713"
   },
   {
      "id":"71307",
      "quanhuyen":"Huyện Vĩnh Cửu",
      "tinhthanh":"713"
   },
   {
      "id":"71305",
      "quanhuyen":"Huyện Định Quán",
      "tinhthanh":"713"
   },
   {
      "id":"71302",
      "quanhuyen":"Huyện Trảng Bom",
      "tinhthanh":"713"
   },
   {
      "id":"71309",
      "quanhuyen":"Huyện Thống Nhất",
      "tinhthanh":"713"
   },
   {
      "id":"71318",
      "quanhuyen":"Huyện Cẩm Mỹ",
      "tinhthanh":"713"
   },
   {
      "id":"71315",
      "quanhuyen":"Huyện Long Thành",
      "tinhthanh":"713"
   },
   {
      "id":"71313",
      "quanhuyen":"Huyện Xuân Lộc",
      "tinhthanh":"713"
   },
   {
      "id":"71700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"717"
   },
   {
      "id":"71701",
      "quanhuyen":"TP. Vũng Tàu",
      "tinhthanh":"717"
   },
   {
      "id":"71703",
      "quanhuyen":"TP. Bà Rịa",
      "tinhthanh":"717"
   },
   {
      "id":"71705",
      "quanhuyen":"Huyện Châu Đức",
      "tinhthanh":"717"
   },
   {
      "id":"71707",
      "quanhuyen":"Huyện Xuyên Mộc",
      "tinhthanh":"717"
   },
   {
      "id":"71712",
      "quanhuyen":"Huyện Long Điền",
      "tinhthanh":"717"
   },
   {
      "id":"71714",
      "quanhuyen":"Huyện Đất Đỏ",
      "tinhthanh":"717"
   },
   {
      "id":"71709",
      "quanhuyen":"Thị xã Phú Mỹ",
      "tinhthanh":"717"
   },
   {
      "id":"71713",
      "quanhuyen":"Huyện Côn Đảo",
      "tinhthanh":"717"
   },
   {
      "id":"70100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"701"
   },
   {
      "id":"70101",
      "quanhuyen":"Quận 1",
      "tinhthanh":"701"
   },
   {
      "id":"70123",
      "quanhuyen":"Quận 12",
      "tinhthanh":"701"
   },
   {
      "id":"70133",
      "quanhuyen":"Quận Thủ Đức",
      "tinhthanh":"701"
   },
   {
      "id":"70117",
      "quanhuyen":"Quận 9",
      "tinhthanh":"701"
   },
   {
      "id":"70125",
      "quanhuyen":"Quận Gò Vấp",
      "tinhthanh":"701"
   },
   {
      "id":"70129",
      "quanhuyen":"Quận Bình Thạnh",
      "tinhthanh":"701"
   },
   {
      "id":"70127",
      "quanhuyen":"Quận Tân Bình",
      "tinhthanh":"701"
   },
   {
      "id":"70145",
      "quanhuyen":"Quận Tân Phú",
      "tinhthanh":"701"
   },
   {
      "id":"70131",
      "quanhuyen":"Quận Phú Nhuận",
      "tinhthanh":"701"
   },
   {
      "id":"70103",
      "quanhuyen":"Quận 2",
      "tinhthanh":"701"
   },
   {
      "id":"70105",
      "quanhuyen":"Quận 3",
      "tinhthanh":"701"
   },
   {
      "id":"70119",
      "quanhuyen":"Quận 10",
      "tinhthanh":"701"
   },
   {
      "id":"70121",
      "quanhuyen":"Quận 11",
      "tinhthanh":"701"
   },
   {
      "id":"70107",
      "quanhuyen":"Quận 4",
      "tinhthanh":"701"
   },
   {
      "id":"70109",
      "quanhuyen":"Quận 5",
      "tinhthanh":"701"
   },
   {
      "id":"70111",
      "quanhuyen":"Quận 6",
      "tinhthanh":"701"
   },
   {
      "id":"70115",
      "quanhuyen":"Quận 8",
      "tinhthanh":"701"
   },
   {
      "id":"70147",
      "quanhuyen":"Quận Bình Tân",
      "tinhthanh":"701"
   },
   {
      "id":"70113",
      "quanhuyen":"Quận 7",
      "tinhthanh":"701"
   },
   {
      "id":"70135",
      "quanhuyen":"Huyện Củ Chi",
      "tinhthanh":"701"
   },
   {
      "id":"70137",
      "quanhuyen":"Huyện Hóc Môn",
      "tinhthanh":"701"
   },
   {
      "id":"70139",
      "quanhuyen":"Huyện Bình Chánh",
      "tinhthanh":"701"
   },
   {
      "id":"70141",
      "quanhuyen":"Huyện Nhà Bè",
      "tinhthanh":"701"
   },
   {
      "id":"70143",
      "quanhuyen":"Huyện Cần Giờ",
      "tinhthanh":"701"
   },
   {
      "id":"80100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"801"
   },
   {
      "id":"80101",
      "quanhuyen":"TP. Tân An",
      "tinhthanh":"801"
   },
   {
      "id":"80103",
      "quanhuyen":"Huyện Tân Hưng",
      "tinhthanh":"801"
   },
   {
      "id":"80105",
      "quanhuyen":"Huyện Vĩnh Hưng",
      "tinhthanh":"801"
   },
   {
      "id":"80107",
      "quanhuyen":"Thị Xã Kiến Tường",
      "tinhthanh":"801"
   },
   {
      "id":"80109",
      "quanhuyen":"Huyện Tân Thạnh",
      "tinhthanh":"801"
   },
   {
      "id":"80111",
      "quanhuyen":"Huyện Thạnh Hóa",
      "tinhthanh":"801"
   },
   {
      "id":"80113",
      "quanhuyen":"Huyện Đức Huệ",
      "tinhthanh":"801"
   },
   {
      "id":"80115",
      "quanhuyen":"Huyện Đức Hòa",
      "tinhthanh":"801"
   },
   {
      "id":"80117",
      "quanhuyen":"Huyện Bến Lức",
      "tinhthanh":"801"
   },
   {
      "id":"80119",
      "quanhuyen":"Huyện Thủ Thừa",
      "tinhthanh":"801"
   },
   {
      "id":"80123",
      "quanhuyen":"Huyện Tân Trụ",
      "tinhthanh":"801"
   },
   {
      "id":"80125",
      "quanhuyen":"Huyện Cần Đước",
      "tinhthanh":"801"
   },
   {
      "id":"80127",
      "quanhuyen":"Huyện Cần Giuộc",
      "tinhthanh":"801"
   },
   {
      "id":"80121",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"801"
   },
   {
      "id":"80700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"807"
   },
   {
      "id":"80701",
      "quanhuyen":"TP. Mỹ Tho",
      "tinhthanh":"807"
   },
   {
      "id":"80703",
      "quanhuyen":"Thị xã Gò Công",
      "tinhthanh":"807"
   },
   {
      "id":"80705",
      "quanhuyen":"Huyện Tân Phước",
      "tinhthanh":"807"
   },
   {
      "id":"80713",
      "quanhuyen":"Huyện Cái Bè",
      "tinhthanh":"807"
   },
   {
      "id":"80709",
      "quanhuyen":"Thị Xã Cai Lậy",
      "tinhthanh":"807"
   },
   {
      "id":"80707",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"807"
   },
   {
      "id":"80711",
      "quanhuyen":"Huyện Chợ Gạo",
      "tinhthanh":"807"
   },
   {
      "id":"80715",
      "quanhuyen":"Huyện Gò Công Tây",
      "tinhthanh":"807"
   },
   {
      "id":"80717",
      "quanhuyen":"Huyện Gò Công Đông",
      "tinhthanh":"807"
   },
   {
      "id":"80718",
      "quanhuyen":"Tân Phú Đông",
      "tinhthanh":"807"
   },
   {
      "id":"81100",
      "quanhuyen":"Không xác định",
      "tinhthanh":"811"
   },
   {
      "id":"81101",
      "quanhuyen":"Tp. Bến Tre",
      "tinhthanh":"811"
   },
   {
      "id":"81103",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"811"
   },
   {
      "id":"81105",
      "quanhuyen":"Huyện Chợ Lách",
      "tinhthanh":"811"
   },
   {
      "id":"81102",
      "quanhuyen":"Huyện Mỏ Cày Nam",
      "tinhthanh":"811"
   },
   {
      "id":"81109",
      "quanhuyen":"Huyện Giồng Trôm",
      "tinhthanh":"811"
   },
   {
      "id":"81111",
      "quanhuyen":"Huyện Bình Đại",
      "tinhthanh":"811"
   },
   {
      "id":"81113",
      "quanhuyen":"Huyện Ba Tri",
      "tinhthanh":"811"
   },
   {
      "id":"81115",
      "quanhuyen":"Huyện Thạnh Phú",
      "tinhthanh":"811"
   },
   {
      "id":"81107",
      "quanhuyen":"Huyện Mỏ Cày Bắc",
      "tinhthanh":"811"
   },
   {
      "id":"81700",
      "quanhuyen":"Không xác định",
      "tinhthanh":"817"
   },
   {
      "id":"81701",
      "quanhuyen":"Tp.Trà Vinh",
      "tinhthanh":"817"
   },
   {
      "id":"81703",
      "quanhuyen":"Huyện Càng Long",
      "tinhthanh":"817"
   },
   {
      "id":"81707",
      "quanhuyen":"Huyện Cầu Kè",
      "tinhthanh":"817"
   },
   {
      "id":"81709",
      "quanhuyen":"Huyện Tiểu Cần",
      "tinhthanh":"817"
   },
   {
      "id":"81705",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"817"
   },
   {
      "id":"81711",
      "quanhuyen":"Huyện Cầu Ngang",
      "tinhthanh":"817"
   },
   {
      "id":"81713",
      "quanhuyen":"Huyện Trà Cú",
      "tinhthanh":"817"
   },
   {
      "id":"81715",
      "quanhuyen":"Huyện Duyên Hải",
      "tinhthanh":"817"
   },
   {
      "id":"80900",
      "quanhuyen":"Không xác định",
      "tinhthanh":"809"
   },
   {
      "id":"80901",
      "quanhuyen":"TP. Vĩnh Long",
      "tinhthanh":"809"
   },
   {
      "id":"80903",
      "quanhuyen":"Huyện Long Hồ",
      "tinhthanh":"809"
   },
   {
      "id":"80905",
      "quanhuyen":"Huyện Mang Thít",
      "tinhthanh":"809"
   },
   {
      "id":"80913",
      "quanhuyen":"Huyện Vũng Liêm",
      "tinhthanh":"809"
   },
   {
      "id":"80909",
      "quanhuyen":"Huyện Tam Bình",
      "tinhthanh":"809"
   },
   {
      "id":"80907",
      "quanhuyen":"Thị xã Bình Minh",
      "tinhthanh":"809"
   },
   {
      "id":"80911",
      "quanhuyen":"Huyện Trà Ôn",
      "tinhthanh":"809"
   },
   {
      "id":"80915",
      "quanhuyen":"Huyện Bình Tân",
      "tinhthanh":"809"
   },
   {
      "id":"80300",
      "quanhuyen":"Không xác định",
      "tinhthanh":"803"
   },
   {
      "id":"80301",
      "quanhuyen":"TP. Cao Lãnh",
      "tinhthanh":"803"
   },
   {
      "id":"80303",
      "quanhuyen":"TP.Sa Đéc",
      "tinhthanh":"803"
   },
   {
      "id":"80305",
      "quanhuyen":"Huyện Tân Hồng",
      "tinhthanh":"803"
   },
   {
      "id":"80307",
      "quanhuyen":"Huyện Hồng Ngự",
      "tinhthanh":"803"
   },
   {
      "id":"80309",
      "quanhuyen":"Huyện Tam Nông",
      "tinhthanh":"803"
   },
   {
      "id":"80313",
      "quanhuyen":"Huyện Tháp Mười",
      "tinhthanh":"803"
   },
   {
      "id":"80315",
      "quanhuyen":"Huyện Cao Lãnh",
      "tinhthanh":"803"
   },
   {
      "id":"80311",
      "quanhuyen":"Huyện Thanh Bình",
      "tinhthanh":"803"
   },
   {
      "id":"80317",
      "quanhuyen":"Huyện Lấp Vò",
      "tinhthanh":"803"
   },
   {
      "id":"80319",
      "quanhuyen":"Huyện Lai Vung",
      "tinhthanh":"803"
   },
   {
      "id":"80321",
      "quanhuyen":"Huyện Châu Thành",
      "tinhthanh":"803"
   },
   {
      "id":"80323",
      "quanhuyen":"TP. Hồng Ngự",
      "tinhthanh":"803"
   },
   {
      "id":"80500",
      "quanhuyen":"Không xác định",
      "tinhthanh":"805"
   },
   {
      "id":"80501",
      "quanhuyen":"TP. Long Xuyên",
      "tinhthanh":"805"
   },
   {
      "id":"82601",
      "quanhuyen":"TP. Tây Ninh",
      "tinhthanh":"709"
   },
   {
      "id":"82612",
      "quanhuyen":"Thị Trấn Ngã Sáu",
      "tinhthanh":"825"
   },
   {
      "id":"82605",
      "quanhuyen":"TP. Kon Tum",
      "tinhthanh":"601"
   },
   {
      "id":"82609",
      "quanhuyen":"Tân Bình",
      "tinhthanh":"709"
   },
   {
      "id":"82594",
      "quanhuyen":"Huyện Vị Thủy",
      "tinhthanh":"815"
   },
   {
      "id":"82599",
      "quanhuyen":"Huyện Trần Đề",
      "tinhthanh":"819"
   },
   {
      "id":"82603",
      "quanhuyen":"TP. Bạc Liêu",
      "tinhthanh":"821"
   },
   {
      "id":"82604",
      "quanhuyen":"Thị xã Giá Rai",
      "tinhthanh":"821"
   },
   {
      "id":"82597",
      "quanhuyen":"Huyện Đắk Mil",
      "tinhthanh":"607"
   },
   {
      "id":"82602",
      "quanhuyen":"Thị xã Tân Châu",
      "tinhthanh":"805"
   },
   {
      "id":"82613",
      "quanhuyen":"Huyện Bình Thủy",
      "tinhthanh":"815"
   },
   {
      "id":"82600",
      "quanhuyen":"Thị Xã Phước Long",
      "tinhthanh":"707"
   },
   {
      "id":"82611",
      "quanhuyen":"Thị Xã Vĩnh Châu",
      "tinhthanh":"819"
   },
   {
      "id":"60777",
      "quanhuyen":"Đắk R'Lấp",
      "tinhthanh":"607"
   },
   {
      "id":"82608",
      "quanhuyen":"Thành phố Vị Thanh",
      "tinhthanh":"825"
   },
   {
      "id":"82592",
      "quanhuyen":"Huyện Bàu Bàng",
      "tinhthanh":"711"
   },
   {
      "id":"82589",
      "quanhuyen":"Kiến Tường",
      "tinhthanh":"801"
   },
   {
      "id":"82593",
      "quanhuyen":"Huyện Thuận Nam",
      "tinhthanh":"705"
   },
   {
      "id":"82595",
      "quanhuyen":"TP. Châu Đốc",
      "tinhthanh":"805"
   },
   {
      "id":"82596",
      "quanhuyen":"Tam Điệp",
      "tinhthanh":"117"
   },
   {
      "id":"82606",
      "quanhuyen":"Huyện Krông Nô",
      "tinhthanh":"607"
   },
   {
      "id":"82610",
      "quanhuyen":"Huyện Chư Pưh",
      "tinhthanh":"603"
   },
   {
      "id":"82588",
      "quanhuyen":"Bù Gia Mập",
      "tinhthanh":"707"
   },
   {
      "id":"82590",
      "quanhuyen":"Huyện Hớn Quản",
      "tinhthanh":"707"
   },
   {
      "id":"82591",
      "quanhuyen":"Thị xã Vũ Châu",
      "tinhthanh":"819"
   },
   {
      "id":"82598",
      "quanhuyen":"Thị xã Kiến Tường",
      "tinhthanh":"801"
   },
   {
      "id":"82607",
      "quanhuyen":"Phú Tân",
      "tinhthanh":"823"
   },
   {
      "id":"82616",
      "quanhuyen":"Huyện Giang Thành",
      "tinhthanh":"813"
   },
   {
      "id":"82631",
      "quanhuyen":"Huyện Mộc Hóa",
      "tinhthanh":"801"
   },
   {
      "id":"82615",
      "quanhuyen":"Thị Xã Ngã Năm",
      "tinhthanh":"819"
   },
   {
      "id":"82618",
      "quanhuyen":"Huyện Hoàng Mai",
      "tinhthanh":"403"
   },
   {
      "id":"82623",
      "quanhuyen":"Huyện Phú Riềng",
      "tinhthanh":"707"
   },
   {
      "id":"82628",
      "quanhuyen":"Thị xã Long Mỹ",
      "tinhthanh":"None"
   },
   {
      "id":"82620",
      "quanhuyen":"Huyện Bù Gia Mập",
      "tinhthanh":"707"
   },
   {
      "id":"82621",
      "quanhuyen":"Mộc Hóa",
      "tinhthanh":"801"
   },
   {
      "id":"82630",
      "quanhuyen":"Huyện Y WAN",
      "tinhthanh":"605"
   },
   {
      "id":"82634",
      "quanhuyen":"Huyện Đắk R'lấp",
      "tinhthanh":"607"
   },
   {
      "id":"82636",
      "quanhuyen":"Huyện Vân Hồ",
      "tinhthanh":"303"
   },
   {
      "id":"82614",
      "quanhuyen":"Huyện Mỏ Cày",
      "tinhthanh":"811"
   },
   {
      "id":"82617",
      "quanhuyen":"Huyện Đắc Đoa",
      "tinhthanh":"603"
   },
   {
      "id":"82638",
      "quanhuyen":"Chư Pưh",
      "tinhthanh":"603"
   },
   {
      "id":"82627",
      "quanhuyen":"Thị xã Tân Uyên ",
      "tinhthanh":"711"
   },
   {
      "id":"82619",
      "quanhuyen":"Thị xã Bình Long",
      "tinhthanh":"707"
   },
   {
      "id":"82624",
      "quanhuyen":"Huyện Ia Rong",
      "tinhthanh":"603"
   },
   {
      "id":"82625",
      "quanhuyen":"Huyện Cai Lậy ",
      "tinhthanh":"807"
   },
   {
      "id":"82632",
      "quanhuyen":"Xã Mỹ Xuân",
      "tinhthanh":"717"
   },
   {
      "id":"82622",
      "quanhuyen":"Huyện Long Đức",
      "tinhthanh":"717"
   },
   {
      "id":"82629",
      "quanhuyen":"Huyện Tân Thành",
      "tinhthanh":"717"
   },
   {
      "id":"82633",
      "quanhuyen":"Quận Bắc Từ Liêm ",
      "tinhthanh":"101"
   },
   {
      "id":"82635",
      "quanhuyen":"Thị Xã Hoàng Mai",
      "tinhthanh":"403"
   },
   {
      "id":"82637",
      "quanhuyen":"Huyện Phú Lộc",
      "tinhthanh":"411"
   },
   {
      "id":"82639",
      "quanhuyen":"Thị Trấn Thạch Hà",
      "tinhthanh":"405"
   },
   {
      "id":"769",
      "quanhuyen":"Thành Phố Thủ Đức",
      "tinhthanh":"701"
   },
   {
      "id":"82640",
      "quanhuyen":"Huyện Tĩnh Gia",
      "tinhthanh":"401"
   },
   {
      "id":"21509",
      "quanhuyen":"Huyện Phú Lương",
      "tinhthanh":"215"
   },
   {
      "id":"22109",
      "quanhuyen":"Huyện Hiệp Hòa",
      "tinhthanh":"221"
   },
   {
      "id":"10915",
      "quanhuyen":"Huyện Văn Giang",
      "tinhthanh":"109"
   },
   {
      "id":"50713",
      "quanhuyen":"Huyện Phù Cát",
      "tinhthanh":"507"
   },
   {
      "id":"11703",
      "quanhuyen":"TP. Tam Điệp",
      "tinhthanh":"117"
   },
   {
      "id":"80511",
      "quanhuyen":"Huyện Châu Phú",
      "tinhthanh":"805"
   },
   {
      "id":"70313",
      "quanhuyen":"Huyện Bảo Lâm",
      "tinhthanh":"703"
   },
   {
      "id":"71317",
      "quanhuyen":"Huyện Nhơn Trạch",
      "tinhthanh":"713"
   },
   {
      "id":"82626",
      "quanhuyen":"Thị xã Ba Đồn ",
      "tinhthanh":"407"
   }
]

export {
   districtsList
}

export default {
   districtsList
}